/** @format */

import { useUI } from "ui";

// The global settings dialog component (this uses UI and is accessibly secured-only)
import Dialog from "../../../components/Dialog";

// Intermediary component to handle duplicate imports from billing content/security
export default function ProblemDialog(props) {
  const { opened } = useUI();
  return <Dialog {...props} opened={opened("billing/dialog")} from="settings" />;
}
