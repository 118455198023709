/** @format */
import template from "@Workspace/Usable/Features/Designer/config/templates/spread.json";
import trims from "@Workspace/Usable/Features/Designer/config/trims/sizes.json";
import { Box, Button, Card, Divider, Grid } from "@mui/material";
import Cdn from "cdn";
import key from "key";
import { useLocales } from "locales";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";

export default function Trims({ setValue, next, values }) {
  const { t } = useLocales();

  /* Trims */
  const [selected, setSelected] = useState(null);
  const [moreTrims, setMoreTrims] = useState(true);

  const getSpreads = (trim) => {
    return new Array(trim.range[0] / 2).fill(template).map((s) => ({ ...s, id: nanoid() }));
  };

  const getOrderedTrims = () => {
    let orderedTrims = [...trims];

    orderedTrims.sort((a, b) => {
      // Then sort by popularity
      return a.available === b.available ? 0 : a.available ? -1 : 1;
    });

    return orderedTrims.map((trim) => ({ ...trim, spreads: getSpreads(trim) }));
  };

  const onSelect = (trim) => {
    setSelected(trim.slug);
    setValue("trim", trim);
    next();
  };

  useEffect(() => {
    if (values?.trim && values.trim != selected) {
      setSelected(values.trim.slug);
    }
  }, [values?.trim]);

  return (
    <Card
      sx={{
        marginTop: "50px",
        marginBottom: "200px",
      }}
      elevation={0}
    >
      <h3 style={{ textAlign: "center" }}>
        {t("whatSizeBook")}{" "}
        <Box sx={{ padding: "5px", fontSize: "12px", textAlign: "center" }}>{t("onboardSetupNotes")}</Box>
      </h3>
      <Box sx={{ margin: "40px 0 20px" }}>
        <Grid container alignItems="center" justifyContent="center" spacing={5}>
          {getOrderedTrims()
            .slice(0, moreTrims ? trims.length - 1 : 6)
            .map((trim, i) => {
              // Get original dimensions
              const [width, height] = trim.dimensions;

              // Determine scale factor
              const maxDimension = Math.max(width, height);
              const scaleFactor = 100 / maxDimension;

              // Calculate scaled dimensions
              let scaledWidth = width * scaleFactor;
              let scaledHeight = height * scaleFactor;

              if (i < 3) {
                scaledWidth *= 2.5;
                scaledHeight *= 2.5;
              }

              return (
                <React.Fragment {...key("onboard", "design", "slugs", trim, i)}>
                  <Grid
                    item
                    xs={i < 3 ? 4 : 3}
                    sx={{
                      display: "flex",
                      flexDirection: "column", // This was corrected from 'direction' to 'flexDirection'
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "10px",
                        width: `${scaledWidth}px`,
                        height: `${scaledHeight}px`,
                        borderRadius: "3px",
                        border: "1px solid rgba(12,12,12,.3)",
                        position: "relative",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)", // This adds a small drop shadow
                        opacity: selected == trim.slug || trim.available ? 1 : 0.1,
                        backgroundImage: `url(${Cdn(
                          "https://us-central.cdn.made.live//thumbnail_children_s_book_characters_4717739092/thumbnail_children_s_book_characters_4717739092.png?width=1728&height=864"
                        )})`,
                        cursor: trim.available ? "pointer" : "inherit",
                        backgroundColor: "#FAFAfA",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        ":hover": { opacity: trim.available ? 0.8 : 0.1 },
                      }}
                      onClick={trim.available ? () => onSelect(trim) : () => {}}
                    >
                      <Box
                        sx={{
                          fontSize: i < 3 ? "24px" : "8px",
                          textAlign: "center",
                          position: "absolute",
                          top: "10px",
                          width: "100%",
                          fontWeight: "bold",
                          opacity: 0.6,
                        }}
                        component="span"
                      >
                        {t("title")}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "8px",
                          textAlign: "center",
                          position: "absolute",
                          bottom: "10px",
                          width: "100%",
                          fontWeight: "bold",
                          opacity: 0.5,
                        }}
                        component="span"
                      >
                        {t("authorNameShort")}
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ opacity: trim.available ? 1 : 0.3 }}>
                      <Box sx={{ fontSize: "10px", marginTop: "10px" }}>{trim.name}</Box>
                      <Box sx={{ fontSize: "10px" }}>
                        {trim.range.join("-")} {t("pages")}.
                      </Box>
                      <Box sx={{ fontSize: "10px" }}>
                        <Box component="span">{t("availableFrom")}</Box>
                        {trim.partners &&
                          trim.partners.map((partner, j) => (
                            <Box
                              sx={{ textTransform: "uppercase", marginLeft: "3px", fontWeight: "bold" }}
                              component="span"
                              {...key("onboard", "design", "partners", trim.slug, partner, j)}
                            >
                              {partner}
                            </Box>
                          ))}
                        .
                      </Box>
                    </Box>
                  </Grid>
                  {i == 2 && (
                    <Grid item xs={12} {...key("onboard", "design", "divider")}>
                      <Box sx={{ padding: "10px 20%", textAlign: "center" }}>
                        <Divider />
                        <Box sx={{ padding: "5%" }}>{t("Coming Soon")}</Box>
                      </Box>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
        </Grid>
      </Box>
      {!moreTrims && getOrderedTrims().length > 6 && (
        <Box sx={{ textAlign: "center", padding: "60px" }}>
          <Button onClick={() => setMoreTrims(true)}>{t("more")}</Button>
        </Box>
      )}
    </Card>
  );
}
