/** @format */

import { Box, Button, Grid, Popover, useTheme } from "@mui/material";

import { useCms } from "cms";
import DynamicIcon from "dynamicicon";
import { useLocales } from "locales";
import { useState } from "react";
import { useUI } from "ui";

const Menu = ({ items, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { link } = useCms(); //navigate,
  const { t } = useLocales();
  const theme = useTheme();
  const { colorInvert } = props;
  const { close } = useUI();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ padding: "0 20px" }}>
      <Box onClick={handleClick} role="button" sx={{ color: colorInvert ? "white" : theme.palette.primary.main }}>
        <DynamicIcon icon="fa-bars" size="lg" />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          ".MuiPaper-root": {
            maxWidth: 250,
            padding: 2,
            marginTop: 2,
            borderRadius: "3px",
            borderTop: `3px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <Grid container spacing={0.5} role="presentation">
          {items.map((p, i) => (
            <Grid item key={i} xs={12}>
              <Button
                component="a"
                href={p.href}
                onClick={(event) => {
                  link(event, p.href);
                  close();
                }}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  color: theme.palette.text.primary,
                  fontWeight: 400,
                }}
              >
                {t(p.title)}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Box>
  );
};

export default Menu;
