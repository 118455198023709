/** @format */

export default class Library {
  constructor(props) {
    let workspace = new (class Workspace {
      constructor(props) {
        this.broadcast = props.broadcast;
        this.ui = props.ui;
        this.user = props.user;
      }
      feature = (props) => {
        try {
          const { user, workspace, feature, billing } = props;
          const { billed } = billing;

          // Check if this user is logged in
          if (!user || !user.authenticated || !user.id || !workspace?.owner?.id) return false;

          // Check if this user is an admin
          if (user.admin()) return true;

          // console.log(Date.now(), , feature, "~22");
          return billed.feature(feature) || false;
        } catch (error) {
          console.error(error);
        }
        return false;
      };
      project = (props) => {
        const { user, workspace, billing } = props;

        // For teams login
        // const teams = workspace.data.feature("teams");

        // Capture errors for good measure
        try {
          // # TODO Make sure the person has access to teams (this will need to be updated)
          function team(data, id) {
            for (let key in data) {
              if (Array.isArray(data[key])) {
                let found = data[key].find((obj) => obj.id === id);
                if (found) {
                  return true;
                }
              }
            }
            return false;
          }

          // If admin we are able to see the project
          if (user.admin()) return true;

          // Check if this user is logged in
          if (!user || !user.authenticated || !user.id || !workspace?.owner?.id)
            return { authenticated: false, code: "user" };

          // Check if the user is suspected
          if (user.suspended) return { authenticated: false, code: "user_suspended" };

          // Make sure billing is setup (for migrating out of beta)
          if (billing.status.get("missing")) return { authenticated: false, code: "billing_setup" };

          // Check to see if billing has begun (this can be removed after beta)
          if (billing.enabled()) {
            // Use billing status function for additional per-status logic (expiry dates, grace period, etc)

            // Trialing: The subscription is currently in a trial period (so no security check needed)
            // Circumstance: When you offer a free trial period before the actual billing starts, and the customer has signed up during this period.

            // Active: The subscription is active, and the customer is being billed regularly (no check needed)
            // Circumstance: This is the normal state for a subscription where payments are being made on time and there are no issues.

            // Inactive: The subscription is still in beta mode and hasn't had updated payment information
            // Circumstance: User was in initial beta and needs to now select a plan
            if (billing.status.get("inactive")) return { authenticated: false, code: "billing_inactive" }; // pass a security error code to billing

            // Incomplete: The subscription creation attempt has started, but there was a problem setting up payment and the first invoice is still pending.
            // Circumstance: If the initial payment attempt fails, for example, due to insufficient funds or an expired card, the subscription will be in this state.
            if (billing.status.get("incomplete")) return { authenticated: false, code: "billing_incomplete" };

            // Incomplete Expired: The subscription creation attempt has failed, and Stripe has stopped trying to collect a payment.
            // Circumstance: After the initial payment attempt fails and after retrying for a configured period without success, the subscription moves to this state. It typically indicates that the card was declined multiple times.
            if (billing.status.get("incomplete_expired"))
              return { authenticated: false, code: "billing_incomplete_expired" };

            // Past Due: The payment for the subscription is overdue, but Stripe is still trying to collect it.
            // Circumstance: When a recurring payment fails and Stripe's automatic retries are still ongoing, but it hasn't been able to collect the payment yet.
            if (billing.status.get("past_due")) return { authenticated: false, code: "billing_past_due" };

            // Cancelled: The subscription has been terminated by the merchant or the customer.
            // Circumstance: Either the customer decided to cancel their subscription, or the merchant manually canceled it in the Stripe dashboard or via API.
            if (billing.status.get("canceled")) return { authenticated: false, code: "billing_canceled" };

            // Unpaid: Payments for the subscription have failed after all retry attempts, and the subscription has been marked as unpaid. It's different from incomplete_expired because it refers to payments after the first one.
            // Circumstance: When Stripe has exhausted all retry attempts for a recurring payment and couldn't collect the amount due. Depending on your settings in Stripe, this can lead to the suspension of service or access for the customer.
            if (billing.status.get("unpaid")) return { authenticated: false, code: "billing_unpaid" };
          }

          // Check if this user is the owner of the project
          if (workspace.owner.id(user.id)) return true;

          // Check if this user has team access
          if (!team({ viewers: teams?.viewers, contributors: teams?.contributors }, user.id))
            return { authenticated: false, code: "teams_inactive" };

          // Just doesn't have access
          return { authenticated: false, code: "user_missing" };

          // If we've had any errors output then and return false
        } catch (error) {
          console.error(error);
        }
        return false;
      };
    })(props);

    const policies = { workspace: { feature: workspace.feature, project: workspace.project } };
    const aliases = { feature: policies.workspace.feature, project: policies.workspace.project };
    return { aliases: aliases, policies: policies };
  }
}
