/** @format */

// Start the tour
function start() {
  if (!this.steps.length) return;

  if (this.state.type == "timer") {
    this.setState({ complete: false });
    this.steps.forEach((step) => {
      this.timers.push(setTimeout(() => this.setState({ name: step.name }), step.from));
      this.timers.push(setTimeout(() => this.setState({ name: null }), step.to - 10));
    });
    this.timers.push(
      setTimeout(() => {
        this.setState({ complete: true, percent: 100 });
        clearInterval(this.progress);
      }, this.timer)
    );
    this.timers.push(setTimeout(() => this.stop(), this.timer + 10000));

    this.progress = setInterval(() => this.getProgress(), 100);

    this.setState({ running: true });
  } else {
    this.setState({ running: true, name: this.steps[0].name, created: true });
  }
}

export { start };
