/** @format */
import { Component as ReactComponent, createContext, useContext } from "react";

import { withBroadcast } from "broadcast";
import { withUI } from "ui";
import { withUnit } from "unit";
import { withUser } from "user";

import Secure from "../components/Component";

import Library from "../libraries";

const SecurityContext = createContext({});

class Security extends ReactComponent {
  constructor(props) {
    super(props);

    let { aliases, policies } = new Library(props);

    this.aliases = aliases;
    this.policies = policies;

    this.unit = this.props.unit.new(this.constructor.name);
  }

  secure = (props) => {
    const { policy = null, rule = null } = props;
    try {
      // If we haven't passed a policy or rule it's secured
      if (
        policy == null ||
        rule == null ||
        this.policies[policy] == undefined ||
        this.policies[policy][rule] == undefined
      )
        return false;

      // Return the security check
      return this.policies[policy][rule]({ ...this.props, ...props });
    } catch (e) {
      return false;
    }
  };

  render() {
    return (
      <SecurityContext.Provider value={this.secure}>
        <Secure {...this.props} secure={this.secure}>
          {this.props.children}
        </Secure>
      </SecurityContext.Provider>
    );
  }
}

const useSecurity = () => {
  return useContext(SecurityContext);
};

const withSecurity = (Component) => {
  return function ContextualComponent(props) {
    return <SecurityContext.Consumer>{(state) => <Component {...props} security={state} />}</SecurityContext.Consumer>;
  };
};

export default withUnit(withBroadcast(withUI(withUser(Security))));
export { useSecurity, withSecurity };
