/** @format */

export function query(query = false, limit = 10) {
  const { pages } = this.props.cms;
  if (query == false) return [];
  if (query == true) return pages;
  return this.document
    .search(query, limit)
    .map((result) => pages.filter((page) => page.slug == result)[0])
    .slice(0, limit);
}
