/** @format */
import Cookies from "cookies";
import config from "../../config/index.json" assert { type: "json" };

/**
 * Returns the locale if it matches the provided locale, otherwise returns the current locale or the default locale.
 * @param {string} locale - The locale to check against.
 * @returns {string} - The matched locale or the current locale or the default locale.
 */
export function getLocale(locale) {
  return locale ? this.state.locale == locale : this.state.locale || config.default;
}

/**
 * Sets the locale and updates the state accordingly.
 * @param {string} locale - The locale to set.
 * @returns {Promise<boolean>} - A promise that resolves to true if the locale is supported and set successfully, or false if the locale is not supported.
 * @throws {Error} - If an error occurs while setting the locale.
 */
export function setLocale(locale) {
  return new Promise((resolve, reject) => {
    try {
      if (this.supported.includes(locale)) {
        Cookies.set("locale", locale);
        this.setState({ locale }, () => resolve(true));
      } else {
        resolve(false); // Locale not supported
      }
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * Retrieves the supported locales.
 * @returns {Array} The array of supported locales.
 */
export function getLocales() {
  return config.supported;
}
