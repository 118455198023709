/** @format */

export async function addDialog(slug, multi = false) {
  return new Promise((resolve, reject) => {
    try {
      if (!this.state.settings.dialogs[slug]) {
        this.state.settings.dialogs[slug] = { open: false, props: {} };
        if (!multi)
          this.setState({
            ...this.state,
            settings: { ...this.state.settings, dialogs: this.state.settings.dialogs },
          });
      }
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

export async function addDialogs(slugs) {
  return new Promise((resolve, reject) => {
    if (!Array.isArray(slugs)) return reject("There was a problem loading dialog menus.");
    let promises = [];
    slugs.forEach((slug) => {
      promises.push(addDialog.call(this, slug, true, "~38"));
    });
    this.setState({
      ...this.state,
      settings: { ...this.state.settings, dialogs: this.state.settings.dialogs },
    });
    Promise.allSettled(promises)
      .then(() => resolve(true))
      .catch((_) => {});
  });
}

export async function openDialog(dialog, props) {
  if (this.opened(dialog)) return;

  let { dialogs, menus } = this.state.settings;

  Object.keys(menus).forEach((i) => (menus[i] = false));

  dialogs.confirm = confirm;
  dialogs.alert = alert;

  if (!dialogs[dialog]) dialogs[dialog] = { open: false, props: {} };

  dialogs[dialog].open = true;
  dialogs[dialog].props = props;

  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, dialogs: dialogs, menus: menus },
    },
  });
}

export async function closeDialogs() {
  let { dialogs } = this.state.settings;
  Object.keys(dialogs).forEach((key) => {
    if (key != "confirm" && key != "alert") dialogs[key] = { open: false, props: {} };
  });
  dialogs.confirm = confirm;
  dialogs.alert = alert;
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, dialogs: dialogs },
    },
  });
}
