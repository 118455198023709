/** @format */

export function openConfirm(payload) {
  if (this.opened("confirm")) return;
  // this.close("~14");
  let { dialogs } = this.state.settings;
  dialogs.confirm = payload;
  dialogs.confirm.open = true;
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, dialogs: dialogs },
    },
  });
}

export function closeConfirm() {
  let { dialogs } = this.state.settings;
  dialogs.confirm = {};
  dialogs.confirm.open = false;
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, dialogs: dialogs },
    },
  });
}
