/** @format */
import { Box, Button, LinearProgress } from "@mui/material";
import { useErrors } from "errors";
import { useLocales } from "locales";
import { useEffect, useState } from "react";
import { post } from "slack";
import { Panels, useUI } from "ui";
import { Functional } from "unit";
import { useUser } from "user";

import { Create } from "@Workspace/Libraries/Project";

// Detect if they can use their device as expected
import Detect from "./components/Detect";

// All of the onboarding components
import Checkout from "./components/Checkout";
import Complete from "./components/Complete";
import Register from "./components/Register";
import Start from "./components/Start";
import Styles from "./components/Styles";
import Themes from "./components/Themes";
import Trims from "./components/Trims";

export default function Onboarding(props) {
  const ui = useUI();
  const { t } = useLocales();
  const user = useUser();
  const errors = useErrors();

  const [values, setValues] = useState(null);
  const [projectId, setProjectId] = useState(null);

  // Get the version
  const version = props?.version || 5;

  const { opt } = props;

  // Set the unit for
  const unit = Functional.unit(`onboarding/version${version}`);

  // To count the time on each step
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    // Set the start time when the component mounts
    setStartTime(Date.now());

    // Restart from beginning on mount
    ui.tab.set({ [`onboarding/version${version}`]: 1 });

    // Set that we start registration
    unit.report({
      method: "useEffect",
      event: "onboardStarted",
      payload: { name: `Version ${version}`, location: window.location.href, opt, version },
    });
  }, []);

  const percent = Math.floor((ui.tab.value(`onboarding/version${version}`) / 6) * 100);

  const next = () => {
    const currentStep = ui.tab.value(`onboarding/version${version}`);
    ui.tab.set({ [`onboarding/version${version}`]: currentStep + 1 });

    unit.report({
      method: "next",
      event: "nextOnboardStep",
      payload: { step: currentStep + 1, percent: percent, version, opt },
    });

    if (startTime) {
      const duration = Date.now() - startTime; // Duration in milliseconds

      unit.report({
        method: "next",
        event: "timeOnboardStep",
        payload: { step: currentStep, duration: duration, version, opt },
      });
    }

    // Reset the startTime for the next interval
    setStartTime(Date.now());
  };

  const previous = () => {
    const currentStep = ui.tab.value(`onboarding/version${version}`);
    ui.tab.set({ [`onboarding/version${version}`]: currentStep - 1 });

    // Log to the unit listened
    unit.report({
      method: "next",
      event: "previousOnboardStep",
      payload: { step: currentStep + 1, version, opt },
    });
  };

  const setValue = (key, value) => {
    unit.report({
      method: "setValue",
      props: { [key]: value },
      message: "Saved setting.",
      event: "onboardSetValue",
      payload: { field: key, version, opt },
    });
    setValues((prevState) => ({ ...prevState, ...{ [key]: value } }));
  };

  // This at the top (of onboarding) to highlight that this can flexibly be placed
  const onComplete = () => {
    // Capture missing information so we don't run into a problem here
    return Create({
      // exit: fail.right.here,
      userId: user.uid,
      title: values?.title || "My Great Book",
      credits: values?.credits || "",
      trim: values?.trim?.slug || "203203HC",
      fonts: values?.theme?.fonts || ["Bubblegum Sans", "Gloria Hallelujah", "Sacramento", "Satisfy", "Courgette"],
      colors: values?.theme?.colors || ["#031926", "#468189", "#9DBEBB", "#ABC7CC", "#76AB7B", "#8A1919", "#EBA82B"],
      style: values?.style || "sketch",
    })
      .then((projectId) => {
        // Create the project
        setProjectId(projectId);

        // Go to the next step
        next();

        // Let Slack know
        post({
          channel: "subscriptions",
          message: "Woooo hoo! New subscription!! " + user.email.get() + " subscribed.",
        });

        // Log to the unit listener
        unit.report({
          method: "next",
          event: "subscriptionCreated",
          payload: { projectId: projectId, name: `Version ${version}`, location: window.location.href, version, opt },
        });
      })
      .catch((error) => {
        errors.error(t("errorCreatingCheckout"), error, "~136");
      });
  };

  // Add some additional props to the components
  props = { ...props, next, previous, setValue, values };

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: `calc(100vh - 90px)`, // Subtracting the height of the top bar
          paddingBottom: "10%",
        }}
      >
        <Detect />
        <Panels slug={`onboarding/version${version}`} history={true}>
          <Start {...props} />
          <Trims {...props} />
          <Themes {...props} />
          <Styles {...props} />
          <Register {...props} {...values} />
          <Checkout {...props} onComplete={onComplete} />
          <Complete {...props} projectId={projectId} />
        </Panels>
        {ui.tab.value(`onboarding/version${version}`) > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
              position: "fixed",
              bottom: 0,
              left: 0,
              padding: "20px",
              backgroundColor: "#FEFEFE",
              opacity: 1,
              zIndex: 1300,
              width: "100vw",
              borderTop: "1px solid #ABC7CC",
              boxShadow: "0 -4px 8px rgba(0, 0, 0, 0.1)",
            }}
            spacing={5}
          >
            <Box sx={{ textAlign: "center", marginRight: "20px" }}>
              <Button
                onClick={() => {
                  unit.report({
                    method: "back",
                    props: { step: ui.tab.value(`onboarding/version${version}`) - 1 },
                    message: "Moved back to step number " + ui.tab.value(`onboarding/version${version}`) - 1,
                  });
                  previous();
                }}
                color="secondary"
                size="small"
                variant="outlined"
              >
                {t("back")}
              </Button>
            </Box>
            <Box>
              <LinearProgress
                variant="determinate"
                color="info"
                sx={{ height: "15px", borderRadius: "3px", width: "540px", margin: "auto" }}
                value={percent}
              />
            </Box>
            <Box style={{ marginLeft: "20px" }}>{percent}%</Box>
          </Box>
        )}
      </Box>
    </>
  );
}
