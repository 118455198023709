/** @format */
import { Box } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "firemade";
import { Suspense, lazy, useEffect, useState } from "react";
import { useUser } from "user";

let Components = {
  active: lazy(() => import("./components/Active")),
  canceled: lazy(() => import("./components/Canceled")),
  incomplete: lazy(() => import("./components/Incomplete")),
  unpaid: lazy(() => import("./components/Unpaid")),
  unregistered: lazy(() => import("./components/Unregistered")),
  fallback: lazy(() => import("./components/Fallback")),
};

export default function ProjectListener({
  location,
  unregistered = null,
  incomplete = null,
  active = null,
  canceled = null,
  unpaid = null,
  fallback = null,
  ...rest
}) {
  const user = useUser();
  const [projectId, setProjectId] = useState(false);
  const billingStatus = user.billing("status") || "active";

  const checkExists = async () => {
    return new Promise(async (resolve) => {
      const docs = await getDocs(query(collection(db, "projects"), where("userId", "==", user.id)));
      if (!docs.empty) {
        docs.forEach((doc) => {
          setProjectId(doc.id);
          return resolve(doc.id);
        });
      } else {
        setProjectId(false);
        return resolve(false);
      }
    });
  };

  useEffect(() => {
    if (user.id != null) {
      checkExists().then((project) => {
        setProjectId(project);
      });
    }
  }, [user.id]);

  let Component;

  try {
    if (user.admin()) {
      Component = active || Components.active;
    } else if (!projectId) {
      Component = fallback || Components.fallback;
    } else {
      switch (billingStatus) {
        case "unregistered":
          Component = unregistered || Components.unregistered;
          break;
        case "incomplete":
        case "incomplete_expired":
          Component = incomplete || Components.incomplete;
          break;
        case "active":
        case "inactive":
        case "trialing":
          Component = active || Components.active;
          break;
        case "past_due":
        case "unpaid":
          Component = unpaid || Components.unpaid;
          break;
        case "canceled":
          Component = canceled || Components.canceled;
          break;
        default:
          Component = fallback || Components.fallback;
          break;
      }
    }
  } catch (e) {
    return null;
  }

  return (
    <Box marginLeft={0} marginRight={2}>
      <Suspense fallback={null}>
        <Component {...rest} userId={user.id} projectId={projectId} />
      </Suspense>
    </Box>
  );
}
