/** @format */
import { Box, Button, Hidden, Link, Stack, TextField } from "@mui/material";
import { useLocales } from "locales";

export default function EmailOnly({ setEmail, disabled, working, send, email, setLastName, setFirstName }) {
  const { t, url } = useLocales();

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <TextField
          sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
          disabled={disabled}
          placeholder={t("enterYourFirstName")}
          onKeyUp={({ target }) => setFirstName(target.value)}
          multiline={true}
          fullWidth={true}
        />
        <Hidden smDown>
          <TextField
            sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
            disabled={disabled}
            placeholder={t("enterYourLastName")}
            onKeyUp={({ target }) => setLastName(target.value)}
            multiline={true}
            fullWidth={true}
          />
        </Hidden>
      </Stack>
      <TextField
        sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
        disabled={disabled}
        placeholder={t("enterYourEmailAddress")}
        onKeyUp={({ target }) => setEmail(target.value)}
        multiline={true}
        fullWidth={true}
      />
      <Stack direction="row" spacing={5} justifyContent="space-between" alignItems="center">
        <Box sx={{ fontSize: "12px" }}>
          <Hidden smDown>{t("unsubscribeDisclaimer")}</Hidden>
          <Link href={url("pages/company/privacy")} target="_blank" sx={{ marginLeft: "4px" }}>
            {t("privacyPolicy")}
          </Link>
          .
        </Box>
        <Box sx={{ textAlign: "right" }}>
          {!working && (
            <Button
              variant="contained"
              disabled={!email}
              onClick={() => send(["interested_in_newsletter"])}
              color="success"
              size="large"
            >
              {t("subscribe")}
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
