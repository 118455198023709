/** @format */

export function openConfirm(props, from = "~3") {
  const { ui, t, errors } = this.props;

  let params = {};

  if (ui.dialogs.confirm.open) return;

  const exit = () => {
    errors.warning(t("errorConfirmMessage"), "Missing message for confirm dialog", "~11");
    return true;
  };

  if (!props.message || !props.confirm) return exit;

  params.title = t(props.title) || t("confirm");
  params.message = t(props.message) || t("confirm");

  params.dismiss =
    typeof props.cancel == "function"
      ? { onClick: props.cancel, text: t("cancel") }
      : props.cancel == null
      ? true
      : props.cancel;
  params.confirm = typeof props.confirm == "function" ? { onClick: props.confirm, text: t("confirm") } : null;

  if (params.confirm == null) return exit();

  if (params.confirm == false) delete params.confirm;
  if (params.dismiss == false) delete params.dismiss;

  if (params.size == null) params.size = "small";

  ui.confirm.open(params);
}

export function closeConfirm(from = "~38") {
  const { ui } = this.props;
  ui.confirm.close();
}
