/** @format */
import { Alert } from "@mui/material";
import { useLocales } from "locales";
import { exception } from "sentry";

export default function MissingErrorComponent() {
  const { t } = useLocales();
  exception("Missing component.");
  return <Alert severity="warning">{t("missingError")}</Alert>;
}
