/** @format */

import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { auth } from "firemade";

export default function reauthenticateWithEmail(emailAddress, password) {
  return new Promise((resolve, reject) => {
    if (!auth.currentUser) {
      return reject("auth/user-not-found");
    }

    const credential = EmailAuthProvider.credential(emailAddress, password);

    reauthenticateWithCredential(auth.currentUser, credential)
      .then((response) => {
        resolve(response.user);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
