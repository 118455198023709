/** @format */
import isSSR from "isssr";

/**
 * Datattrib class for setting and getting data attributes on HTML elements.
 * @class
 */
export default new (class Datattrib {
  set = ({ id = null, name = null, value = null }) => {
    if (isSSR) return;
    (id == null ? document.body : document.getElementById(id)).setAttribute("data-" + name, value);
  };
  get = ({ id = null, name = null, value = null }) => {
    if (isSSR) return;
    (id == null ? document.body : document.getElementById(id)).getAttribute("data-" + name, value);
  };
})();
