/** @format */

function stop() {
  clearInterval(this.progress);
  this.timers.forEach((timer) => {
    clearTimeout(timer);
  });
  this.setState({ running: false, name: null });
}

export { stop };
