/** @format */
import { Editor, Element, Range } from "slate";
import { Functional } from "unit";

const unit = Functional.unit("manuscript/mark");

export function isMarkActive(format, from = "~7") {
  unit.report({
    method: "isMarkActive",
    test: "If a mark is active it displays differently than unselected marks.",
    message: "Checks if a mark button is active.",
    from: from,
    steps: ["From Manuscript", "Click style button (bold, italic)", "Confirm button is selected or not as appropriate"],
    group: "mark",
  });

  try {
    if (!this.editor.loaded()) return;

    const marks = Editor.marks(this.editors[this.state.spread.number]);
    return marks ? marks[format] === true : false;
  } catch (error) {
    console.error(error, "~23");
  }
}

export function toggleMark(format, from = "~27") {
  unit.report({
    method: "toggleMark",
    message: "Toggles a mark (style button) button",
    analyze: true,
    action: true,
    test: "When clicking a mark button the appropriate style should be applied and the display color should change.",
    from: from,
    steps: ["From Manuscript", "Click style button (bold, italic)", "Confirm styling applies"],
    group: "mark",
  });

  if (!this.editor.loaded()) return;

  const isActive = this.mark.active(format);

  if (isActive) {
    Editor.removeMark(this.editors[this.state.spread.number], format);
  } else {
    Editor.addMark(this.editors[this.state.spread.number], format, true);
  }

  this.focus.set("~49");
}

export function isMarkAvailable(mark, from = "~52") {
  unit.report({
    method: "isMarkAvailable",
    test: "Checks if a mark button should be enabled or disabled.",
    message: "Mark availability depends on the current text selection and its context.",
    from: from,
    steps: ["From Manuscript", "Check for text selection and its context"],
    group: "mark",
  });

  if (!this.editor.loaded()) return;

  try {
    const editor = this.editors[this.state.spread.number];
    const { selection } = editor;

    // Check if there's a selection
    if (!selection || Range.isCollapsed(selection)) {
      return false; // No text is selected, or the selection is collapsed
    }

    // We can't add bold to an h1
    if (mark == "bold") {
      // Find the block type for the current selection
      const [blockEntry] = Editor.nodes(editor, {
        at: selection,
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "heading-one",
      });

      // Check if the current block is a 'heading-one'
      if (blockEntry) {
        return false; // Current block is 'heading-one', so the requested mark should not be available
      }
    }

    // Mark is available
    return true;
  } catch (_) {}
}
