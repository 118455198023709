/** @format */
import { Box, Button, Grid, Link, Stack, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withConstraints } from "constraints";
import { withErrors } from "errors";
import { withLocales } from "locales";
import { withPerforming } from "performing";
import { Component as ReactComponent } from "react";
import { withSnackbar } from "snackbar";
import { withUI } from "ui";
import { withUnit } from "unit";
import { withUser } from "../../context";

import AuthProviders from "../AuthProviders";

const StyledBox = styled(Box)(() => ({
  "& .item": {
    padding: 0,
  },
  "& .label": {
    fontWeight: "600",
  },
}));

const initialState = {
  emailAddress: "",
  password: "",
};

class SignIn extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.unit = this.props.unit.new(this.constructor.name);
    this.small = props.small || false;
  }

  getSignInButton = () => {
    const { emailAddress, password } = this.state;
    const { t, disabled = false } = this.props;

    if (!emailAddress || !password) return null;

    return (
      <Button color="success" disabled={disabled} variant="contained" onClick={() => this.signInWithEmail()}>
        {t("signIn")}
      </Button>
    );
  };

  signInWithEmail = () => {
    const { performing, ui, snackbar, t, user, errors } = this.props;
    const { emailAddress, password } = this.state;
    // const { constraints, validate } = this.props.constraints;

    // const errors = validate(
    //   {
    //     emailAddress: emailAddress,
    //     password: password,
    //   },
    //   {
    //     emailAddress: constraints.emailAddress,
    //     password: constraints.password,
    //   }
    // );

    performing.set.updating("updating", "~95");

    user.authentication
      .signInWithEmail(emailAddress, password)
      .then(() => {
        performing.set.updating("success", "~100");
        ui.dialog.close("signin");
        snackbar.open(t("signedIn"));
      })
      .catch((code) => {
        errors.error(t(code), code, "~117");
      })
      .finally(() => {
        performing.set.loading(false, "~120");
      });
  };

  sendSignInLinkToEmail = () => {
    const { user, errors, performing, snackbar } = this.props;
    const { emailAddress } = this.state;
    // const { constraints, validate } = this.props.constraints;

    // const errors = validate({ emailAddress: emailAddress }, { emailAddress: constraints.emailAddress });

    user.authentication
      .signInWithEmail(emailAddress)
      .then(() => {
        snackbar.open(this.props.t("linkSent"));
      })
      .catch((code) => {
        errors.error(t(code), code, "~154");
      })
      .finally(() => {
        performing.set.loading(false, "~157");
      });
  };

  signInWithAuthProvider = (provider) => {
    const { user, performing, t, snackbar, ui, errors } = this.props;

    performing.set.updating("updating", "~178");

    user.authentication
      .signInWithAuthProvider(provider)
      .then(() => {
        snackbar.open(t("welcome"));
        performing.set.updating("success", "~189");
        ui.dialog.close("signin");
      })
      .catch((code) => {
        errors.error(true, code, "~199");
        // ui.dialog.close("signin");
        return;
      })
      .finally(() => {
        performing.set.loading(false, "~204");
      });
  };

  onKeyDown = (event) => {
    const { emailAddress, password } = this.state;

    if (!emailAddress && !password) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") this.signInWithEmail();
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;
    this.setState({ emailAddress: emailAddress });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;
    this.setState({ password: password });
  };

  render() {
    const { emailAddress, errors } = this.state;
    const { t, ui, disabled, small, user } = this.props;

    if (user.authenticated) return <></>;
    return (
      <StyledBox sx={{ marginTop: "5px" }}>
        <Grid container direction="column" spacing={1}>
          <Grid item className="item">
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Box className="label">{t("enterEmail")}</Box>
            </Stack>
          </Grid>
          <Grid item xs className="item">
            <TextField
              autoComplete="email"
              disabled={disabled}
              error={!!(errors && errors.emailAddress)}
              fullWidth
              required
              type="email"
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={this.handleEmailAddressChange}
            />
          </Grid>
          <Grid item xs className="item" style={{ marginTop: "10px" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Box component={"div"} className="label">
                {t("enterPassword")}
              </Box>
              <Link style={{ marginBottom: "5px" }} onClick={() => ui.dialog.open("forgot")}>
                {t("forgotPassword")}
              </Link>
            </Stack>
          </Grid>
          <Grid item xs className="item">
            <TextField
              autoComplete="current-password"
              disabled={disabled}
              error={!!(errors && errors.password)}
              fullWidth
              required
              type="password"
              sx={{ bakgroundColor: "#FFF" }}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={this.handlePasswordChange}
              onKeyDown={this.onKeyDown}
            />
          </Grid>

          <Grid item style={{ textAlign: "right" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Box sx={{ margin: "20px 0px", textAlign: small ? "center" : "left" }}>
                <AuthProviders
                  gutterBottom
                  disabled={disabled}
                  onAuthProviderClick={this.signInWithAuthProvider}
                  tooltip="Login or register"
                  small={this.small}
                />
              </Box>
              {!small && <>{emailAddress && <>{this.getSignInButton()}</>}</>}
            </Stack>
          </Grid>
        </Grid>
        {small && <Box sx={{ textAlign: "right" }}>{emailAddress && <>{this.getSignInButton()}</>}</Box>}
      </StyledBox>
    );
  }
}

export default withUser(
  withConstraints(withUnit(withPerforming(withSnackbar(withLocales(withUI(withErrors(SignIn)))))))
);
