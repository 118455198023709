/** @format */
import { Editor, Transforms } from "slate";
import { Functional } from "unit";

// Blocks might be going away entirely

const unit = Functional.unit("manuscript/buttons");

export function toggleBlock(block, from = "~9") {
  unit.report({
    method: "toggleBlock",
    message: "Toggles a block button",
    analyze: true,
    action: true,
    test: "When clicking a block button the appropriate alignment should be applied and the display color should change.",
    steps: ["From Manuscript", "Click alignment button (bold, italic)", "Confirm alignment styling applies"],
    group: "block",
  });

  if (!this.editor.loaded()) return;

  const isActive = isBlockActive.call(this, block);

  Transforms.setNodes(this.editors[this.state.spread.number], {
    type: isActive ? "paragraph" : block,
  });

  this.focus.set("~28");
}

export function isBlockActive(block, from = "~31") {
  unit.report({
    method: "isBlockActive",
    test: "If a block is active it displays differently than unselected marks.",
    message: "Checks if a block button is active.",
    steps: ["From Manuscript", "Click alignment button", "Confirm alignment button is selected as appropriate"],
    from: from,
  });

  if (!this.editor.loaded()) return;

  try {
    const [match] = Editor.nodes(this.editors[this.state.spread.number], {
      match: (n) => n.type === block,
    });
    return !!match;
  } catch (error) {
    return false;
  }
}

export function isBlockAvailable(block, from = "~52") {
  unit.report({
    method: "isBlockAvailable", // Updated the method name to reflect its purpose
    test: "Checks if a block button should be enabled or disabled.",
    message: "Block availability depends on the context of the current selection.",
    from: from,
    steps: ["From Manuscript", "Check the context of the current selection"],
    group: "block", // Changed group to "block" as it's more appropriate here
  });

  try {
    const editor = this.editors[this.state.spread.number];
    const { selection } = editor;

    if (!selection) {
      return false; // No selection means no context to apply a block
    }

    // Additional logic can be implemented here based on specific block types
    // For example, disallowing certain block types in specific contexts
    // ...

    return true; // By default, allow block type changes
  } catch (error) {
    console.error(error, "~76");
    return false;
  }
}
