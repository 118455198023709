/** @format */
import BlogGridBig from "@Web/Blocks/BlogGridBig";
import { Box, Typography } from "@mui/material";
import { useLocales } from "locales";

const SimilarStories = (props) => {
  const { t } = useLocales();
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={"h6"} gutterBottom>
            {t("similarStories")}
          </Typography>
          <Typography color={"text.secondary"}>{t("similarStoriesSubtitle")}</Typography>
        </Box>
      </Box>
      <BlogGridBig {...props} />
    </Box>
  );
};

export default SimilarStories;
