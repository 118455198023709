/** @format */
import { AppBar, Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Component as ReactComponent } from "react";

import { withCms } from "cms";
import { withLocales } from "locales";
import Logo from "logo";
import { withTour } from "tour";
import { withUI } from "ui";

import isSSR from "isssr";

import Right from "./components/Right";

const StyleBox = styled(Box)(() => ({
  "&.touring, & .touring": {
    backgroundColor: "transparent !important",
    zIndex: 1600,
  },
}));

class Top extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = { border: 0 };
  }
  scroll = () => {
    if (!isSSR) this.setState({ border: document.documentElement.scrollTop / 60 });
  };
  componentDidMount() {
    window.addEventListener("scroll", this.scroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroll);
  }

  onClick = () => {
    const { cms, ui, t } = this.props;

    if (cms.section("app")) {
      ui.confirm.open({
        title: t("confirmLeaveTitle"),
        text: t("confirmLeaveBody"),
        dismiss: {
          onClick: () => {
            ui.confirm.close();
          },
          text: t("cancel"),
        },
        confirm: {
          onClick: () => {
            if (!isSSR) document.location.href = "/";
          },
          text: t("continue"),
        },
      });

      // Go where we need to go
    } else {
      if (!isSSR) document.location.href = "/";
    }
  };

  render() {
    const { cms } = this.props;

    return (
      <StyleBox>
        <AppBar
          sx={{
            boxShadow: "0px -1px 5px 0px rgba(12,12,12," + this.state.border + ")",
            zIndex: 1260,
            backgroundColor: cms.section("app") ? "#FAFAFA" : "transparent",
            borderRadius: 0,
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0} data-place="app-topbar">
            <Box sx={{ zIndex: 1258, marginLeft: cms.section("app") ? "10px" : 0 }}>
              <Logo
                {...this.props}
                zoom={cms.section("app") ? 1 : 0.5}
                colorInvert={this.props.ui.mode.get("dark")}
                link={true}
                onClick={this.onClick}
              />
            </Box>
            {this.props.children}
            <Right {...this.props} />
          </Stack>
        </AppBar>
      </StyleBox>
    );
  }
}

export default withUI(withTour(withCms(withLocales(Top))));
