/** @format */
import { Box } from "@mui/material";
import { TinyError } from "errors";
import key from "key";
import { Component as ReactComponent, createElement as createReactElement } from "react";
import { withWorkspace } from "workspace/src/context"; // # TODO this is because of tests

class Workers extends ReactComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <TinyError>
        {Object.keys(this.props.workspace.workers).map((worker, i) => (
          <Box {...key("workspace", "workers", i)}>{createReactElement(this.props.workspace.workers[worker])}</Box>
        ))}
      </TinyError>
    );
  }
}
export default withWorkspace(Workers);
