/** @format */

import { Box, Button, Card } from "@mui/material";
import { useLocales } from "locales";
import React from "react";
import { useUI } from "ui";

const Login = ({ thread }) => {
  // get the locales
  const { t } = useLocales();

  // get the locales
  const { dialog } = useUI();

  // Sign into the site
  const signIn = () => {
    dialog.open("signin");
  };

  return !thread ? (
    <Box sx={{ padding: "20px" }}>
      <Card sx={{ padding: "20px" }}>
        {t("signInCommunity")}

        <Box sx={{ textAlign: "right", marginTop: "20px" }}>
          <Button variant="contained" color="success" onClick={signIn}>
            {t("signIn")}
          </Button>
        </Box>
      </Card>
    </Box>
  ) : (
    <Box sx={{ padding: "20px" }}>
      <Button variant="contained" size="small" color="success" onClick={signIn}>
        {t("signIn")}
      </Button>
    </Box>
  );
};

export default Login;
