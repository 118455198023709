/** @format */

import { auth } from "firemade";

export default function signInAnonymously() {
  return new Promise((resolve, reject) => {
    if (auth.currentUser) {
      resolve("User logged in.");
      return;
    }

    signInAnonymously(auth)
      .then((value) => {
        resolve(value.user);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
