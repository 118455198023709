/**
 * Sends a chat message.
 *
 * @format
 * @param {Object} options - The options for sending the message.
 * @param {string|null} options.message - The message content. Default is null.
 * @param {string} options.template - The message template. Default is "message".
 * @param {Object} options.props - Additional properties for the message. Default is an empty object.
 * @param {Array} options.attachments - The message attachments. Default is an empty array.
 * @param {string} options.userId - The ID of the user sending the message. Default is the ID of the current user.
 * @returns {Promise<boolean>} - A promise that resolves to true if the message is sent successfully, or false otherwise.
 */

import { stamp } from "dateml";
import { doc, setDoc } from "firebase/firestore";
import { db } from "firemade";
import cloneDeep from "lodash/cloneDeep.js";
import uniq from "lodash/uniq";
import { nanoid } from "nanoid";

export function sendMessage({ message = null, template = "message", meta = {} }) {
  const { user } = this.props;

  return new Promise(async (resolve, reject) => {
    try {
      // Get the chat index
      let index = this.chats.index();

      // Make sure we have one
      if (index == -1) return false;

      // Get the chat we're working on
      let chat = cloneDeep(this.state.chats[index]);

      // Lets not resubmit this
      delete chat.id;

      // Attach the message to the chat
      chat.users.push(user.id);
      chat.users = uniq(chat.users);

      // Change the seen status
      chat.seen = uniq([this.props.user.id]);

      // A place to store the attachments
      let attachments = [];

      // Save the attachment
      if (this.state.attachments.length > 0) attachments = await this.attachments.save();

      // Now setup the message
      chat.messages.push(
        stamp({
          id: nanoid(),
          userId: user.id,
          message,
          template,
          meta,
          attachments: attachments,
        })
      );

      // #TODO: Add the attachments to the message
      const stamped = stamp({ ...chat });
      delete stamped.updated;

      // Now add to the messages
      setDoc(doc(db, "chat", this.state.id), stamped, { merge: true });

      // Set that there are no more attachments
      this.setState({ attachments: [] });

      resolve(true);
    } catch (error) {
      this.onError(error);
      reject(error);
    }
  });
}

/**
 * Retrieves the active messages from the chat context.
 * @returns {Object} The active messages object.
 */
export function getMessages() {
  // Get the index of the active chat
  let index = this.chats.index();

  // Check if there's no active chat
  if (index === -1) {
    return [];

    // Otherwise, process the active chat
  } else {
    // Copy the active chat and media state
    return this.state.chats[index].messages;
  }
}
