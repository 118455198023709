/** @format */
import { Box, Typography } from "@mui/material";

import { TinyError } from "errors";

import Form from "getresponse";

import { useLocales } from "locales";

const BottomNewsletter = ({ colorInvert = false, from = "contact" }) => {
  const { t } = useLocales();
  return (
    <TinyError>
      <Box>
        <Box marginBottom={4}>
          <Typography
            fontWeight={700}
            variant={"h4"}
            color={colorInvert ? "white" : "text.secondary"}
            align={"center"}
            gutterBottom
          >
            {t("bottomNewsletterDelivered")}
          </Typography>
          <Typography variant={"h6"} component={"p"} color={colorInvert ? "white" : "text.secondary"} align={"center"}>
            {t("bottomNewsletterWeekly")}
          </Typography>
        </Box>
        <Box maxWidth={600} margin={"0 auto"}>
          <Form layout={from} campaignId={null} />
        </Box>
      </Box>
    </TinyError>
  );
};

export default BottomNewsletter;
