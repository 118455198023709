/** @format */
import { TinyError } from "errors";
import { withUser } from "user";
import { useSecurity } from "../../context";

// Default access denied component
import AccessDenied from "./components/UserSuspended";

const Component = (props) => {
  // Get the user
  const { policy, rule, children, fallback: Fallback } = props;

  // Get the secure function
  const secure = useSecurity();

  // The default to secure is no access
  let response = { authenticated: false, code: null };

  try {
    // Make sure we passed a rule and policy
    if (policy && rule) {
      // Check if this section is authenticated
      response = secure(props);

      // Simple auth
      if (typeof response == "boolean") response = { authenticated: response, code: null };

      // Complex auth
      if (typeof response == "object") {
        response = { authenticated: response?.authenticated || false, code: response?.code || null };
      }

      if (response.authenticated) return <>{children}</>;
    }
  } catch (e) {}

  // if we've sent a fallback and we're supposed to hide the component, do it
  if (Fallback) {
    return (
      <TinyError>
        <Fallback {...props} {...response} />
      </TinyError>
    );
  }

  return (
    <TinyError>
      <AccessDenied {...props} {...response} />
    </TinyError>
  );
};

export default withUser(Component);
