/** @format */
import {
  Alert,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  Stack,
  TextField,
} from "@mui/material";
import DynamicIcon from "dynamicicon";
import { TinyError } from "errors";
import { Likes } from "likesml";
import { useLocales } from "locales";
import { Avatar, Name } from "profiles";
import { useRef, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import TimeAgo from "timeagoml";
import { useUser } from "user";

// The replies
import Replies from "./components/Replies";
import Reply from "./components/Reply";

export default function (props) {
  const {
    message,
    userId,
    timestamp,
    id,
    docId,
    onDelete,
    onEdit,
    edited,
    replies,
    likes = false,
    onClick = null,
    showControls = true,
    approved = true,
    flagged = false,
    topical = true,
    showTitle = false,
    showFlagged = true,
    showTopical = true,
    showApproved = true,
    title = null,
    messages = [],
    messageAvatar = 50,
    hover,
  } = props;

  // Get the locales
  const { t } = useLocales();

  // Get the user
  const user = useUser();

  // State to manage edit mode
  const [isEditing, setIsEditing] = useState(false);

  // State to enabling
  const [reply, setReply] = useState(false);

  // State to hold the edited message temporarily
  const [editedMessage, setEditedMessage] = useState(message);

  // State and ref for handling the Popper
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const iconButtonRef = useRef(null);

  const handleClick = () => {
    setAnchorEl(iconButtonRef.current);
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Popper style
  const popperStyle = {
    zIndex: 1,
    marginTop: "5px",
  };

  // Handle enabling edit mode
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Handle the submission of the edited message
  const handleEditSubmit = () => {
    onEdit(id, editedMessage); // Pass the id and the new message to the onEdit function
    setIsEditing(false); // Exit editing mode
  };

  // Handle canceling the edit
  const handleCancelEdit = () => {
    setEditedMessage(message); // Reset the edited message to the original
    setIsEditing(false); // Exit editing mode
  };

  // Handle the onclick
  const handleOnClick = () => {
    if (onClick) onClick(id);
  };

  const Warnings = () => {
    // Flagged offensive/spam
    if (showFlagged && flagged)
      return (
        <Alert severity="error" sx={{ fontSize: "12px" }}>
          {t("messageFlagged")}
        </Alert>
      );

    // Not-topical
    // if (showTopical && !topical)
    //   return (
    //     <Alert severity="warning" sx={{ fontSize: "12px" }}>
    //       {t("messageTopical")}
    //     </Alert>
    //   );

    // Not-topical
    if (showApproved && !approved)
      return (
        <Alert severity="warning" sx={{ fontSize: "12px" }}>
          {t("messageNotApproved")}
        </Alert>
      );

    // No warnings
    return null;
  };

  // Don't show messages that are problematic
  if ((flagged || !topical || !approved) && user?.id != userId) {
    return null;
  }

  // We don't want editing after there are replies
  const hasReplies = messages.some((message) => message.replyId == id);

  // The style
  let sx = {};

  // Add the sx stuff
  if (onClick) sx = { ...sx, cursor: "pointer" };

  // If hover
  sx = hover ? { ...sx, opacity: 0.8, ":hover": { opacity: 1 } } : { opacity: 1 };

  return (
    <TinyError>
      <Box sx={{ padding: "2.5% 0", opacity: 0.5 }}>
        <Divider />
      </Box>
      <Warnings />
      <Box onClick={handleOnClick} sx={sx}>
        <Stack
          direction="row"
          spacing={3}
          sx={{ margin: "2.5% 0", opacity: flagged || !topical || !approved ? 0.9 : 1 }}
        >
          <Box sx={{ width: `${messageAvatar}px` }}>
            <Avatar size={messageAvatar} id={userId} />
          </Box>
          <Box className="message user" sx={{ display: "flex", flexGrow: 1 }}>
            {isEditing ? (
              <TextField
                fullWidth
                multiline
                variant="outlined"
                value={editedMessage}
                onChange={(e) => setEditedMessage(e.target.value)}
                rows={10}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      type="submit"
                      color="success"
                      onClick={handleEditSubmit}
                      disabled={editedMessage.length < 20}
                    >
                      <DynamicIcon icon={"fa-paper-plane"} />
                    </IconButton>
                  ),
                }}
              />
            ) : (
              <Box
                sx={{
                  whiteSpace: "pre-line",
                  minWidth: "100%",
                }}
              >
                <Stack direction="row" sx={{ fontWeight: "bold" }} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" alignItems="center" spacing={2} sx={{ fontSize: "0.8em" }}>
                    <Name userId={userId} />
                    <Box sx={{ opacity: 0.5, fontWeight: "normal", fontSize: ".5em" }}>
                      <TimeAgo timestamp={timestamp} />

                      {edited && (
                        <Box component="span" sx={{ marginLeft: "5px" }}>
                          - {t("edited")}
                        </Box>
                      )}
                    </Box>
                  </Stack>

                  {showControls && userId == user.id && (
                    <Box sx={{ minWidth: "60px", textAlign: "right" }}>
                      {!isEditing ? (
                        <>
                          <IconButton ref={iconButtonRef} onClick={handleClick}>
                            <DynamicIcon icon={"fa-ellipsis"} size="xs" />
                          </IconButton>
                          <Popper open={open} anchorEl={anchorEl} style={popperStyle}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <Paper>
                                {approved && !flagged && topical && !hasReplies && (
                                  <MenuItem
                                    onClick={() => {
                                      handleEditClick(id, message);
                                      handleClose();
                                    }}
                                    sx={{ fontSize: ".8em" }}
                                  >
                                    {t("edit")}
                                  </MenuItem>
                                )}
                                <MenuItem
                                  onClick={() => {
                                    onDelete(id);
                                    handleClose();
                                  }}
                                  sx={{ fontSize: ".8em" }}
                                >
                                  {t("remove")}
                                </MenuItem>
                              </Paper>
                            </ClickAwayListener>
                          </Popper>
                        </>
                      ) : (
                        <IconButton onClick={handleCancelEdit} size="small">
                          <DynamicIcon icon={"fa-times"} size="xs" />
                        </IconButton>
                      )}
                    </Box>
                  )}
                </Stack>
                <Box sx={{ fontSize: ".9em", margin: "2.5% 0 5%", fontWeight: "light" }}>
                  {showTitle && title && title}
                  {showTitle && !title && (
                    <LinesEllipsis text={message} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
                  )}
                  {!showTitle && message}
                </Box>
                {likes && (
                  <Likes
                    slug={`discussion-messageId-${message.id}`}
                    enabled={true}
                    variant="text"
                    color="secondary"
                    down={{ visible: false }}
                    up={{ visible: true, text: null, icon: true }}
                  />
                )}
                {replies && (
                  <TinyError>
                    <Replies {...props} />

                    {user.authenticated && (
                      <>
                        {!reply ? (
                          <Box sx={{ textAlign: "center", paddingTop: "40px" }}>
                            <Button
                              size="small"
                              color="secondary"
                              onClick={() => setReply(!reply)}
                              variant={reply ? "contained" : "text"}
                            >
                              <Box>
                                <DynamicIcon icon="fa-comment-alt-lines" size="sm" />
                              </Box>
                              <Box sx={{ marginLeft: "10px" }}>{t("reply")}</Box>
                            </Button>
                          </Box>
                        ) : (
                          <>
                            <Reply {...props} setReply={setReply} />
                          </>
                        )}
                      </>
                    )}
                  </TinyError>
                )}
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </TinyError>
  );
}
