/** @format */
import { signOut } from "firebase/auth";
import { auth } from "firemade";

export default async function signOutComponent() {
  return await new Promise((resolve, reject) => {
    if (!auth.currentUser) return reject("userNotLoggedIn");
    signOut(auth)
      .then((value) => {
        resolve(value);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
