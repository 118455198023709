/** @format */

function getFiltered(props = this.state.filters) {
  let {
    name = null,
    attachedTo = [],
    collections = [],
    tags = [],
    meta = {},
    search = null,
    id = null,
    userId = null,
  } = props;

  // These filters are the authority when set in state
  if (this.state.filters.search != null) {
    search = this.state.filters.search;
  }
  if (this.state.filters.collections.length > 0) {
    collections = this.state.filters.collections;
  }
  if (this.state.filters.tags.length > 0) {
    tags = this.state.filters.tags;
  }
  if (Object.keys(this.state.filters.meta).length > 0) {
    meta = this.state.filters.meta;
  }

  try {
    let media = [];

    if (name) {
      if (!this.state.queries[name]) return [];
      media = [...this.state.queries[name]];
    } else {
      // Concatenate all media from all queries if no name is provided
      for (let queryName in this.state.queries) {
        media = [...media, ...this.state.queries[queryName]];
      }
    }

    // Remove duplicates
    media = Object.values(media.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {}));

    if (id) media = media.filter((m) => m.id == id);

    if (userId) media = media.filter((m) => m.userId == userId);

    if (search && search.length > 0) {
      media = media.filter((m) => m.tags.some((n) => n.includes(search.toLowerCase())));
    }

    if (collections.length > 0) {
      media = media.filter((m) => m.collections.some((r) => collections.includes(r)));
    }

    if (tags.length > 0) {
      media = media.filter((m) => m.tags.some((r) => tags.includes(r)));
    }

    if (attachedTo.length > 0) {
      media = media.filter((m) => m.attachedTo.some((r) => attachedTo.includes(r)));
    }

    if (Object.keys(meta).length > 0) {
      Object.keys(meta).forEach((m) => {
        media = media.filter((n) => n.meta[m] !== "undefined" && meta[m] === n.meta[m]);
      });
    }

    return media;
  } catch (error) {
    console.error(error);
    if (this.props.errors) this.props.errors.error(this.props.t("unexpectedError"), error, "~97");
    return [];
  }
}
export { getFiltered };
