/** @format */

import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useLocales } from "locales";
import React from "react";

// Styled table cell
const StyledTableCell = styled(TableCell)(() => ({
  fontWeight: "bold",
}));

// Main Checkout Table component
const Totals = ({ selectedPackage, discount, tax }) => {
  const { t } = useLocales();

  if (!tax) tax = 0;

  // Work with integers (cents)
  const priceInCents = Math.round(selectedPackage.price * 100);
  const taxInCents = Math.round(tax * 100);
  const discountInCents = discount ? Math.round(priceInCents * discount.discount) : 0;

  const totalInCents = priceInCents - discountInCents + taxInCents;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="checkout table">
        {/* Table Header */}
        <TableBody>
          <TableRow>
            <StyledTableCell>{t("item")}</StyledTableCell>
            <StyledTableCell align="right">{t("priceDollar")}</StyledTableCell>
          </TableRow>

          {/* Single Line Item */}
          <TableRow>
            <TableCell sx={{ textTransform: "capitalize" }}>{selectedPackage.planId.replace("_", " - ")}</TableCell>
            <TableCell align="right">{selectedPackage.price.toFixed(2)}</TableCell>
          </TableRow>

          <TableRow sx={{ opacity: 0.25 }}>
            <StyledTableCell>{t("tax")}</StyledTableCell>
            <TableCell align="right">{tax.toFixed(2)}</TableCell>
          </TableRow>

          {discount && (
            <TableRow sx={{ opacity: 0.25 }}>
              <StyledTableCell>{t("discount")}</StyledTableCell>
              <TableCell align="right">-{(selectedPackage.price * discount.discount).toFixed(2)}</TableCell>
            </TableRow>
          )}
        </TableBody>

        {/* Footer */}
        <TableFooter>
          <TableRow sx={{ backgroundColor: "rgba(12,12,12,.025)" }}>
            <StyledTableCell sx={{ fontSize: "14px" }}>{t("total")}</StyledTableCell>
            <TableCell align="right">
              <Typography variant="h6">${(totalInCents / 100).toFixed(2)}</Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default Totals;
