/** @format */
import { logEvent } from "..";
import { debug, events } from "../config/index.json";

// Get the event from unit
export default function report({ event, payload: properties = {} }) {
  try {
    // Check if the event is one we log to mixpanel
    if (events.includes(event)) {
      // Output some logs if we need to
      if (debug) console.log("GTAGS:", `Adding ${event} to log.`);

      // Push the event to the throttled logger
      logEvent({ event, properties: properties });

      // All good in the hood
      return;
    }
    // Handle errors
  } catch (error) {
    console.error(error);
    // if (debug) console.error("GTAGS:", error);
  }
}
