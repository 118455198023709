/** @format */

export function setReady(stage) {
  this.setState((prevState) => ({
    ...prevState,
    ready: [...prevState.ready, stage],
  }));
}

export function getReady(stage = "all") {
  return this.state.ready.includes(stage);
}
