/** @format */
import { TinyError } from "errors";
export default function (props) {
  return (
    <TinyError>
      <Author {...props} />
    </TinyError>
  );
}

export function Author(props) {
  if (!props?.author) return null;
  const { name } = props.author;
  return <>{name}</>;
}
