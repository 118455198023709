/** @format */

import { Button, InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Stack } from "@mui/system";
import axios from "axios";
import { useLocales } from "locales";
import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "snackbar";
import { useUser } from "user";

import countries from "../../../../../../config/countries.json";

const Address = ({
  setTax,
  setWorking,
  discount,
  selectedPackage,
  working,
  geo,
  country,
  setCountry,
  postalCode,
  setPostalCode,
  optIn,
}) => {
  const { t } = useLocales();
  const { open } = useSnackbar();
  const { meta } = useUser();

  // Ready (for trying to get geo, in the event it times out)
  const [ready, setReady] = useState(false);

  // Sort the countries
  const sortedCountries = useMemo(() => {
    // Separate US and CA
    const topCountries = countries.filter((country) => ["US", "CA"].includes(country.code));

    // Sort the rest alphabetically by 'name'
    const otherCountries = countries
      .filter((country) => !["US", "CA"].includes(country.code))
      .sort((a, b) => a.name.localeCompare(b.name));

    return [...topCountries, ...otherCountries];
  }, [countries]);

  const handleApplyTax = () => {
    setWorking(true);
    // Apply coupon code logic here
    axios
      .post("/api/billing/subscriptions/tax", {
        country: country,
        postalCode: postalCode,
        promoCode: discount?.id || null,
        planId: selectedPackage.planId,
      })
      .then((response) => {
        const { data } = response.data;

        // Lets move them along and do the rest afterwards
        setTax(data.tax_amount);

        // Save some additional meta information here

        // Get the fully qualified country name
        const userCountry = countries.filter(({ code }) => code === country)[0];

        // Update the users meta
        meta.set("postalCode", postalCode);
        meta.set("country", userCountry.name);
      })
      .catch(() => {
        open(t("weCouldntDetermineYourLocation"), "warning");
        setTax(null);
      })
      .finally(() => setWorking(false));
  };

  // Set up timer for geo timeout (in case of network disruption)
  useEffect(() => {
    const timerId = setTimeout(() => {
      setReady(true);
    }, 5000);
    setWorking(true);

    return () => clearTimeout(timerId);
  }, [working]);

  // Now apply the country to the text box if possible
  useEffect(() => {
    if (geo?.country) {
      setCountry(geo.country);
      setWorking(false);
      setReady(true);
    } else {
      setWorking(true);
    }
  }, [geo]);

  if (!ready) return null;

  return (
    <Stack direction="row" spacing={2}>
      <Autocomplete
        freeSolo
        id="country-selector"
        options={sortedCountries}
        defaultValue={sortedCountries.find((c) => c.code === country) || null}
        getOptionLabel={(option) => option.name}
        sx={{ width: "200px" }}
        onChange={(_, newValue) => {
          setCountry(newValue?.code); // Use two-letter country code
        }}
        disabled={working}
        renderInput={(params) => <TextField {...params} label={t("country")} />}
      />
      <Box>
        {country && (
          <TextField
            label={t("postalCodeOrZip")}
            variant="outlined"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder={t("enterPostalCodeOrZip")}
            fullWidth
            disabled={working}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    sx={{ minWidth: "80px" }}
                    size="small"
                    color="success"
                    variant="contained"
                    onClick={handleApplyTax}
                    disabled={postalCode?.length < 4 || working}
                  >
                    {!optIn ? t("apply") : t("finish")}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default Address;
