/** @format */
import Container from "@Web/Container";
import { Box, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import cdn from "cdn";
import { useLocales } from "locales";
import { useEffect } from "react";

const Hero = () => {
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll(".jarallax");
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import("jarallax");
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  const { t } = useLocales();

  return (
    <Box
      className={"jarallax"}
      data-jarallax
      data-speed="0.2"
      position={"relative"}
      minHeight={{ xs: 400, sm: 500, md: 600 }}
      display={"flex"}
      alignItems={"center"}
      marginTop={-13}
      paddingTop={13}
      id="agency__portfolio-item--js-scroll"
      data-testid="hero-container"
    >
      <Box
        className={"jarallax-img"}
        data-lazybg={cdn(
          "https://us-central.cdn.made.live/about_us_8a242c32a1/about_us_8a242c32a1.png?updated_at=2023-02-16T22:29:00.318Zhttps://us-central.cdn.made.live/connection_abbdfe4d66/connection_abbdfe4d66.png?updated_at=2023-02-16T22:26:09.059Z"
        )}
        sx={{
          position: "absolute",
          objectFit: "cover",
          /* support for plugin https://github.com/bfred-it/object-fit-images */
          fontFamily: "object-fit: cover;",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha("#161c2d", 0.4),
          zIndex: 1,
        }}
      />
      <Container position={"relative"} zIndex={2}>
        <Box marginBottom={4}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              fontWeight: 900,
              color: "common.white",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {t("aboutUsTitle")}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.primary"
            sx={{
              textAlign: "center",
              color: "common.white",
            }}
          >
            {t("aboutUsSubtitle")}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
