/** @format */

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useCms } from "cms";
import { useLocales } from "locales";

const UserSuspended = () => {
  const { t } = useLocales();
  const { navigate } = useCms();

  return (
    <Box>
      <Dialog
        open={true}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        transitionDuration={0}
      >
        <DialogTitle id="alert-dialog-slide-title">{t("userSuspendedHeader")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("userSuspendedBody", { emailAddress: "support@made.live" })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/en-us/pages/home")} variant="contained" color="error">
            {t("exit")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserSuspended;
