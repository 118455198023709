/** @format */

import { auth } from "firemade";

export function getProvided() {
  return ["google.com", "facebook.com", "apple.com"].includes(auth.currentUser?.providerData[0]?.providerId);
}

export function getProvider(value = null) {
  try {
    if (value) {
      return auth.currentUser?.providerData?.[0]?.[value] || null;
    }
    return auth.currentUser?.providerData?.[0] || null;
  } catch (e) {
    return null;
  }
}

export function getProviders(providerId) {
  try {
    if (!providerId) return auth.currentUser?.providerData || [];
    return (auth.currentUser?.providerData || []).filter((provider) => provider.providerId === providerId)[0] || [];
  } catch (e) {
    return [];
  }
}
