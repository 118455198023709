/** @format */

import { useBilling } from "@Workspace/Billing";
import { useWorkspace } from "@Workspace/Context";
import key from "key";
import { useLocales } from "locales";
import React, { Suspense, useCallback, useMemo } from "react";
import { useUI } from "ui";

export default function Features() {
  const ui = useUI();
  const workspace = useWorkspace();
  const { t } = useLocales();
  const { close } = workspace.assist;

  const billing = useBilling();

  let pack = "lite";

  try {
    pack = billing.package.name();
  } catch (_) {}

  const getSortedMenuFeatures = useCallback(
    (features, menuFeatures) => {
      // Map through features to adjust priority based on pack
      const adjustedFeatures = Object.values(features).map((feature) => {
        // If pack is 'lite' and the feature is 'query', set its priority to 3
        if (pack === "lite" && feature.slug === "query") {
          return { ...feature, priority: 3 };
        }
        return feature;
      });

      // Filter features that are not included in the menu, have a priority, and are visible
      const filteredFeatures = adjustedFeatures.filter(
        (feature) => menuFeatures.hasOwnProperty(feature.slug) && feature.hasOwnProperty("priority") && feature.visible
      );

      // Sort the features based on their priority
      const sortedFeatures = filteredFeatures.sort((a, b) => a.priority - b.priority);

      return sortedFeatures;
    },
    [pack]
  );

  const availableFeatures = useMemo(
    () => getSortedMenuFeatures(workspace.ui.features, workspace.ui.menus.features),
    [getSortedMenuFeatures, workspace.ui.features, workspace.ui.menus.features]
  );

  const Component = useCallback(
    (props) => {
      const { slug } = props;
      if (!workspace.ui.menus.features[slug]) return <></>;
      return (
        <Suspense fallback={null}>{React.createElement(workspace.ui.menus.features[slug], { ...props })}</Suspense>
      );
    },
    [workspace.ui.menus.features]
  );

  // Clicked the button from the left-hand side
  const select = useCallback(
    (feature) => {
      // Close anything that might be open
      ui.menu.close("workspace.features");

      // Attempt to get the kb article
      const kb = workspace.features[feature]?.kb;

      // We need a confirm button
      let props = { confirm: true };

      // if we have the knowledge base, use it
      if (kb) {
        props = { ...props, kb };
      }

      // Select the feature (which is a wrapper dialog)
      workspace.feature.select({ feature, props: props });
    },
    [ui.menu, workspace.features, workspace.feature]
  );

  return (
    <>
      {availableFeatures.map((feature, i) => (
        <Component
          {...key("workspace", "menu", "top", "features", i)}
          {...feature}
          title={t(feature.title)}
          onClick={() => {
            close();
            select(feature.slug);
          }}
          disabled={!feature.enabled}
        />
      ))}
    </>
  );
}
