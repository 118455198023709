/** @format */
import { auth } from "firemade";

export default function reauthenticationRequired() {
  try {
    const user = auth.currentUser;

    if (!user) {
      throw new Error("auth/user-not-found");
    }

    const lastSignInTime = new Date(user.metadata.lastSignInTime).getTime();
    const currentTime = new Date().getTime();

    return currentTime - lastSignInTime > 300000; // 300000 authenticate every five minutes
  } catch (e) {
    // Should it default to true?
    return false;
  }
}
