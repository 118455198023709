/** @format */

import { nanoid } from "nanoid";
import { Functional } from "unit";
import ISBNTemplate from "../../config/templates/isbn.json";

const unit = Functional.unit("designer/isbn");

export function addISBNFromElements(props, from = "~9") {
  unit.report({
    method: "addISBNFromElements",
    from: from,
  });

  const { workspace } = this.props;

  return new Promise((resolve, reject) => {
    try {
      const { top, left } = props;

      const width = 400;
      const height = 300;

      const { width: editorWidth, height: editorHeight } = this.editor.style();

      let imgTop, imgLeft;

      // Center the image horizontally and position it based on the top mouse event
      imgTop = Math.max(50, Math.min(top - height / 2, editorHeight - height - 50));
      imgLeft = Math.max(50, Math.min(left - width / 2, editorWidth - width - 50));

      const described = workspace.languages.get().reduce((acc, language) => {
        acc[language.code] = null;
        return acc;
      }, {});

      const element = {
        ...ISBNTemplate,
        id: nanoid(),
        described: described,
        style: [
          {
            ...ISBNTemplate.style[0],
            width: width * workspace.zoom.get(),
            height: height * workspace.zoom.get(),
            left: imgLeft,
            top: imgTop,
          },
        ],
      };

      this.element.add(element).then(() => {
        resolve();
      });
    } catch (error) {
      console.error(error, "~56");
      reject(error);
    }
  });
}
