/** @format */

// # TODO - This component is no longer needed as the security component handles these problems

import { Functional } from "unit";

const unit = Functional.unit("workspace/access");

export function openAccess(props, from = "~9") {
  let testId = unit.report({
    method: "setupDesks",
    message: "Dialogs should load without error",
    from: from,
  });
  const { ui, performing, snackbar, t } = this.props;
  const { reason } = props;
  try {
    if (ui.dialogs.access) {
      ui.dialog.open("access", { ...props, from: from });
      performing.set.loading(false, "~20");
    } else {
      snackbar.open(t(reason), "error", 5000);
    }
    unit.passed({ testId: testId });
  } catch (error) {
    const { errors, t } = this.props;
    unit.failed({ testId: testId, error: error }, from);
    errors.error(t("unexpectedError"), error, "~28");
  }
}

export function closeAccess(from = "~32") {
  let testId = unit.report({
    method: "closeAccess",
    from: from,
  });
  const { ui } = this.props;
  try {
    unit.passed({ testId: testId }, from);
    ui.dialog.close();
  } catch (error) {
    const { errors, t } = this.props;
    unit.failed({ testId: testId, error: error }, from);
    errors.error(t("unexpectedError"), error, "~44");
  }
}
