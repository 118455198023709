/** @format */

export default async function forget(observer) {
  await new Promise((resolve, reject) => {
    try {
      if (observer) observer();
      resolve(true);
    } catch (error) {
      reject(error.code);
    }
  });
}
