/** @format */
import SmartButtons from "@Workspace/Components/SmartButtons";
import { withThumb } from "@Workspace/Components/Thumb/context";
import { withBroadcast } from "broadcast";
import Cookies from "cookies";
import { withErrors } from "errors";
import { withLocales } from "locales";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { withMedia } from "media";
import { withPerforming } from "performing";
import { Component as ReactComponent, createContext, useContext } from "react";
import { withUI } from "ui";
import { withUser } from "user";
import units from "../config/units.json";

/* Ready */
import { getReady, setReady } from "./helpers/Ready";

/* Setup */
import setupDesigner from "./helpers/Setup";

/* Editor */
import {
  editorReady,
  editorUpdated,
  getEditorNumber,
  getEditorStyle,
  refreshEditor,
  registerEditor,
  selectEditor,
  setupEditor,
  sizeEditor,
  unregisterEditor,
} from "./helpers/Editor";

/* Get the dimensions of the editor */
import { deleteSavedDimensions, getSavedDimensions, setSavedDimensions } from "./helpers/Dimensions";

/* Trims */
import { getTrim, setTrim } from "./helpers/Trim";

/* Undo/redo */
import { canDo, createUndo, orderUndos, redo, undo } from "./helpers/Do";

/* The units */
import { getSegment, setSegment, setUnits } from "./helpers/Units";

/* Buttons configuration */
import ButtonConfig from "./helpers/Buttons";

/* Styling of the controls */

/* Moving */
import { setMovingOff, setMovingOn } from "./helpers/Moving";

/* Label */
import { hideElementLabel, setElementLabel, showElementLabel } from "./helpers/Label";

/* Update Project */

import { saveProject, syncProject, updateProject } from "./helpers/Project";

/* Spread */
import {
  addSpread,
  deleteSpread,
  getSpreadName,
  getSpreadNumber,
  getSpreadReady,
  getSpreads,
  setSpreadByDirection,
  setSpreadByNumber,
  setSpreadOrder,
} from "./helpers/Spread"; // setupSpreads,

/* Element */
import {
  addElement,
  deleteElement,
  getElement,
  getElementProps,
  getElementReady,
  getElementWarnings,
  getElements,
  getElementsReady,
} from "./helpers/Element";

/* Tool */
import { getTool, setTool, toggleTool } from "./helpers/Tool";

/* Select/Edit */
import {
  deslectElements,
  getSelectedElement,
  getSelectedElementType,
  getSelectedElements,
  getSelectedIndex,
  hasSelected,
  refreshElements,
  setSelectedElement,
  setSelectedElements,
} from "./helpers/Edit";

/* Locked */
import { getElementLocked, setElementLocked, toggleElementLocked } from "./helpers/Locked";

/* Style */
import {
  getElementResetable,
  getElementRotation,
  getElementStyle,
  getStructuredTransform,
  resetElementStyle,
  resetElementTransform,
  saveElementStyle,
  setElementStyle,
} from "./helpers/Style";

/* Controls */
import {
  getControlStyle,
  getControlVisible,
  getHover,
  setControlsMenuClose,
  setControlsMenuOpen,
  setControlsMenuToggle,
  setHover,
} from "./helpers/Controls";

/* Frames */
import { getFrame, getFrames, setFrame } from "./helpers/Frame";

/* Layers */
import { getElementLayer, getElementLayers, setElementLayer } from "./helpers/Layer";

/* Text */
import {
  addTextElement,
  calculateTextHeight,
  getElementTextStyle,
  getTextSize,
  onTextEndEditing,
  onTextStartEditing,
  setTextSize,
} from "./helpers/Text.js";

/* Image */
import {
  addImageFromElements,
  addImageFromPaste,
  getImageId,
  getImageStyle,
  getImageUrl,
  getMediaId,
  getTransparentImage,
  isTransparentImage,
  removeTransparentImage,
  setTransparentImage,
} from "./helpers/Image.js";

/* Background */
import { getBackground, setBackground } from "./helpers/Background.js";

/* Moveable - for doing certain things ON moveable events */
import { getMoveable, setMoveable } from "./helpers/Moveable.js";

/* Drag */
import { onDrag, onDragGroup } from "./helpers/Drag.js";

/* Resize */
import { onResize, onResizeGroup } from "./helpers/Resize.js";

/* Rotate */
import { onRotate, onRotateGroup } from "./helpers/Rotate.js";

/* Warp */
import { onWarp } from "./helpers/Warp.js";

/* Flip */
import { setElementFlip } from "./helpers/Flip.js";

/* Text/Fonts */
import {
  getColors,
  getFamilies,
  getFontAlign,
  getFontColor,
  getFontFamily,
  getFontSize,
  setColors,
  setFamilies,
  setFontAlign,
  setFontColor,
  setFontFamily,
  setFontSize,
} from "./helpers/Font"; // setupFonts,

/* Sketch */
import { addElementSketch, getSketchColor, getSketchSize, setSketchColor, setSketchSize } from "./helpers/Sketch";

/* Resize */
import { setTheme } from "./helpers/Theme.js";

/* Dropping */
import { getDroppableStyle, onDrop } from "./helpers/Drop.js";

/* Capturing thumbnails */
import { saveSpreadThumb, setupThumbs } from "./helpers/Thumbs";

/* Dynamic Elements */
import { addISBNFromElements } from "./helpers/ISBN";

import { addHumanMadeFromElements } from "./helpers/HumanMade";

import { addCopyrightFromElements } from "./helpers/Copyright";

import { addBlurbFromElements } from "./helpers/Blurb";

import { addBioFromElements } from "./helpers/Bio";

import { addShapeFromElements } from "./helpers/Shape";

// Set settings
import { getSettings, setSettings, toggleSettings } from "./helpers/Settings";

/* Tear it all down */
import { teardown } from "./helpers/Teardown";

const DesignerContext = createContext({});

class Designer extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new(this.constructor.name);

    this.reset();

    if (!Cookies.get("workspace-designer-units-value")) Cookies.set("workspace-designer-units-value", "metric");
    if (!Cookies.get("workspace-designer-units-segment")) Cookies.set("workspace-designer-units-segment", "mm");

    // # TODO this is remove cms from cookies for the moment
    if (Cookies.get("workspace-designer-units-segment") == "cm") Cookies.set("workspace-designer-units-segment", "mm");

    const spread = () => {
      const parsedValue = parseInt(Cookies.get("workspace-designer-spread"), 10);
      if (isNaN(parsedValue)) return 0;
      return parsedValue;
    };

    // Moveable defaults
    this.edit = {
      selectable: true,
      draggable: true,
      resizable: true,
      rotatable: true,
      warpable: false,
      snappable: true,
    };

    this.state = {
      // Data from the project
      data: this.props.data || {},

      // The undos
      dos: [],

      // Ready stages
      ready: [],

      // When the editor is sized
      sized: false,

      // Forces reload without changing the data
      revised: null,

      // Metric or imperial
      units: {
        value: Cookies.get("workspace-designer-units-value") || "metric",
        segment: Cookies.get("workspace-designer-units-segment") || "mm",
        values: units,
      },

      // Moveable
      moveable: null,

      // When moving
      moving: false,

      // Scales based on dimensions
      scale: 1,

      // Dimensions of spread, etc.
      dimensions: {
        spread: { width: 0, height: 0 },
        guides: { width: 0, height: 0 },
        droppable: { width: 0, height: 0 },
      },

      // Guides for the spreads and the elements
      guides: {
        horizontal: [],
        vertical: [],
      },

      // Rulers
      rulers: {
        horizontal: [0, 0],
        vertical: [0, 0],
      },

      // The snaps
      snaps: {
        horizontal: [],
        vertical: [],
      },

      // Where elements are bound to
      bounds: { top: 0, bottom: 0, left: 0, right: 0 },

      // Overlays to show safe areas
      mattes: { margins: [], bleeds: [] },

      // Spread data (added as object in case it needs to be expanded)
      spread: {
        number: spread(),
      },

      // Frame number for style (frames used for animation)
      frame: {
        number: 0,
      },

      // Currently selected elements
      elements: [],

      // Groups (this will group items together)
      groups: [],

      // Default editing availability
      edit: this.edit,

      // Designer view settings
      settings: {
        guides: true,
        warnings: true,
        borders: true,
        rulers: true,
        snaps: true,
        margins: false,
        bleeds: false,
        barcode: false,
        layers: false,
        // ticks: true,
      },

      // Main tool being used (text/image/other)
      tool: "select",

      // Show/hide label
      label: false,

      // Controls
      controls: false,

      // Sketching
      sketch: {
        size: 10,
        color: "#121212",
      },

      // Junk?
      color: "#FAFAFA",

      // When writing (I feel this is duplicated)
      writing: false,
    };

    // Ready
    this.ready = {
      set: setReady.bind(this),
      get: getReady.bind(this),
    };

    // Feature (storyboard, illustrator, animator, interactivator)
    this.feature = this.props.workspace.feature.selected();

    // Do
    this.dos = {
      undo: undo.bind(this),
      redo: redo.bind(this),
      create: createUndo.bind(this),
      order: orderUndos.bind(this),
      can: canDo.bind(this),
    };

    // the overall project functions
    this.project = {
      save: saveProject.bind(this),
      update: updateProject.bind(this),
      sync: syncProject.bind(this),
    };

    // The editor, moveable, selectable
    this.editor = {
      setup: setupEditor.bind(this),
      size: sizeEditor.bind(this),
      register: registerEditor.bind(this),
      unregister: unregisterEditor.bind(this),
      select: selectEditor.bind(this),
      resize: sizeEditor.bind(this),
      style: getEditorStyle.bind(this),
      refresh: refreshEditor.bind(this),
      ready: editorReady.bind(this),
      updated: editorUpdated.bind(this),
      number: getEditorNumber.bind(this),
      dimensions: {
        set: setSavedDimensions.bind(this),
        get: getSavedDimensions.bind(this),
        delete: deleteSavedDimensions.bind(this),
      },
    };

    // Droppable
    this.droppable = {
      style: getDroppableStyle.bind(this),
    };

    // Trim
    this.trim = {
      set: setTrim.bind(this),
      get: getTrim.bind(this),
    };

    // Spread
    this.spread = {
      ready: getSpreadReady.bind(this) || false,
      add: addSpread.bind(this),
      delete: deleteSpread.bind(this),
      select: setSpreadByNumber.bind(this),
      elements: getElements.bind(this),
      name: getSpreadName.bind(this),
      number: getSpreadNumber.bind(this),
      direction: setSpreadByDirection.bind(this),
      order: {
        set: setSpreadOrder.bind(this),
      },
    };

    // Spreads
    this.spreads = {
      get: getSpreads.bind(this),
    };

    // Frame
    this.frame = {
      get: getFrame.bind(this),
      set: setFrame.bind(this),
    };

    // Frames
    this.frames = {
      get: getFrames.bind(this),
    };

    // Tool
    this.tool = {
      set: setTool.bind(this),
      get: getTool.bind(this),
      toggle: toggleTool.bind(this),
    };

    // Moving
    this.moving = {
      on: setMovingOn.bind(this),
      off: setMovingOff.bind(this),
    };

    // Label
    this.label = {
      set: setElementLabel.bind(this),
      show: showElementLabel.bind(this),
      hide: hideElementLabel.bind(this),
    };

    // Controls
    this.controls = {
      style: getControlStyle.bind(this),
      visible: getControlVisible.bind(this),
      hover: {
        get: getHover.bind(this),
        set: setHover.bind(this),
      },
      menu: {
        open: setControlsMenuOpen.bind(this),
        close: setControlsMenuClose.bind(this),
        toggle: setControlsMenuToggle.bind(this),
      },
    };

    // Settings # TODO old?
    this.settings = {
      get: getSettings.bind(this),
      set: setSettings.bind(this),
      toggle: toggleSettings.bind(this),
    };

    // Thumbs
    this.thumb = { generate: setupThumbs.bind(this) };

    // Units
    this.units = {
      set: setUnits.bind(this),
      segment: {
        set: setSegment.bind(this),
        get: getSegment.bind(this),
      },
    };

    /* Helps determine ux when moving/rotating (mostly controls) */
    this.moveable = {
      get: getMoveable.bind(this),
      set: setMoveable.bind(this),
    };

    this.element = {
      add: addElement.bind(this),
      get: getElement.bind(this),
      type: getSelectedElementType.bind(this),
      ready: getElementReady.bind(this),
      has: hasSelected.bind(this), // boolean getselected
      props: getElementProps.bind(this),
      select: setSelectedElement.bind(this),
      deselect: deslectElements.bind(this),
      selected: getSelectedElement.bind(this),
      index: getSelectedIndex.bind(this),
      refresh: refreshElements.bind(this),
      warnings: getElementWarnings.bind(this),
      reset: resetElementStyle.bind(this),
      resetable: getElementResetable.bind(this),
      delete: deleteElement.bind(this),
      locked: {
        get: getElementLocked.bind(this),
        set: setElementLocked.bind(this),
        toggle: toggleElementLocked.bind(this),
      },
      style: {
        get: getElementStyle.bind(this),
        set: setElementStyle.bind(this),
        save: saveElementStyle.bind(this),
        image: getImageStyle.bind(this),
        text: getElementTextStyle.bind(this),
        rotation: getElementRotation.bind(this),
        transform: getStructuredTransform.bind(this),
      },
      text: {
        text: getElementTextStyle.bind(this),
        editing: {
          start: onTextStartEditing.bind(this),
          end: onTextEndEditing.bind(this),
        },
        size: {
          set: setTextSize.bind(this),
          calculate: calculateTextHeight.bind(this),
          get: getTextSize.bind(this),
        },
      },
      image: {
        add: addImageFromElements.bind(this),
        paste: addImageFromPaste.bind(this),
        style: getImageStyle.bind(this),
        url: getImageUrl.bind(this),
        id: getImageId.bind(this),
        mediaId: getMediaId.bind(this),
        variations: {
          transparent: {
            set: setTransparentImage.bind(this),
            get: getTransparentImage.bind(this),
            is: isTransparentImage.bind(this),
            restore: removeTransparentImage.bind(this),
          },
        },
      },
      background: {
        set: setBackground.bind(this),
        get: getBackground.bind(this),
      },
      elements: {
        isbn: addISBNFromElements.bind(this),
        humanmade: addHumanMadeFromElements.bind(this),
        shape: addShapeFromElements.bind(this),
        copyright: addCopyrightFromElements.bind(this),
        blurb: addBlurbFromElements.bind(this),
        bio: addBioFromElements.bind(this),
      },
    };

    // Elements
    this.elements = {
      ready: getElementsReady.bind(this),
      get: getSelectedElements.bind(this),
      select: setSelectedElements.bind(this),
      deselect: deslectElements.bind(this),
      refresh: refreshElements.bind(this),
      selected: getSelectedElements.bind(this),
    };

    // Fonts
    this.families = {
      get: getFamilies.bind(this),
      set: setFamilies.bind(this),
    };

    // Colors
    this.colors = {
      get: getColors.bind(this),
      set: setColors.bind(this),
    };

    // Edit
    this.edit = {
      ...this.state.edit,
      ...{
        reset: resetElementTransform.bind(this),

        // Select start/end

        drag: {
          element: onDrag.bind(this),
          group: onDragGroup.bind(this),
        },

        // Resize
        resize: {
          element: onResize.bind(this),
          group: onResizeGroup.bind(this),
        },

        // rotate start/end
        rotate: {
          element: onRotate.bind(this),
          group: onRotateGroup.bind(this),
        },

        // warp start/end
        warp: onWarp.bind(this),

        flip: setElementFlip.bind(this),

        // Layer
        layer: {
          set: {
            up: setElementLayer.bind(this, "up"),
            down: setElementLayer.bind(this, "down"),
          },
          get: getElementLayer.bind(this),
        },

        layers: {
          get: getElementLayers.bind(this),
        },

        // The text being edited
        text: { add: addTextElement.bind(this) },

        // Font
        font: {
          style: getElementTextStyle.bind(this),
          color: {
            set: setFontColor.bind(this),
            get: getFontColor.bind(this),
          },
          family: {
            set: setFontFamily.bind(this),
            get: getFontFamily.bind(this),
          },
          size: {
            set: setFontSize.bind(this),
            get: getFontSize.bind(this),
          },
          align: {
            set: setFontAlign.bind(this),
            get: getFontAlign.bind(this),
          },
        },
      },
    };

    // Sketch
    this.sketch = {
      size: {
        set: setSketchSize.bind(this),
        get: getSketchSize.bind(this),
      },
      color: {
        set: setSketchColor.bind(this),
        get: getSketchColor.bind(this),
      },
      add: addElementSketch.bind(this),
    };

    // Theme
    this.theme = {
      set: setTheme.bind(this),
    };

    // Debounce the updating
    this.debounceUpdate = debounce(this.project.save, 300);

    // Debounce the undos (mostly for style, dragging)
    this.debounceCreateUndo = debounce(this.dos.create, 300);

    // Debouce Editor resizing
    this.debouncedResize = debounce(this.editor.size, 300);

    // An interval for synching teamwork
    this.syncInterval = null;
  }

  reset = () => {
    this.editors = [];
  };

  /* Revisions if helpul */

  revised = (value = null) => {
    this.setState({ revised: value || Date.now() });
  };

  /* Cloned data */

  data = (from = "~713") => {
    this.unit.report({
      method: "data",
      message: "Getting data.",
      from: from,
    });
    return cloneDeep(this.state.data);
  };

  /* Thumb (snap) */

  snap = (number) => {
    const { thumb } = this.props;
    return new Promise((resolve, reject) => {
      try {
        if (thumb)
          thumb.snap(number || this.state.spread.number).then((response) => {
            saveSpreadThumb.call(this, this.state.spread.number, response, "~730").then((response) => {
              resolve(response);
            });
          });
      } catch (error) {
        console.error(error, "~735");
        return reject(error);
      }
    });
  };

  /* Droppable (for broadcast) */

  onDropped = onDrop.bind(this);

  /* React */

  componentDidMount = () => {
    const { performing, errors, t } = this.props;

    setupDesigner
      .call(this)
      .then(() => {
        window.addEventListener("resize", this.debouncedResize);

        // this isn't great and will likely cause problems when updating.
        if (!this.syncInterval) this.syncInterval = setInterval(this.syncProject, 30000);

        performing.set.loading(false, "~766");
      })
      .catch((error) => {
        errors.error(t(error), error, "~769");
      });
  };

  componentWillUnmount() {
    try {
      teardown.call(this, "~781");
    } catch (_) {}
  }

  componentDidUpdate() {
    const { broadcast } = this.props;

    try {
      if (broadcast) broadcast.listen(this, "~793");
    } catch (error) {
      console.error(error, "~795");
    }
  }

  render() {
    const context = {
      ready: this.ready,
      data: this.props.data,
      revised: this.state.revised,
      dimensions: this.state.dimensions,
      sized: this.state.sized,
      feature: this.feature,

      /* Dos */
      dos: this.dos,

      // Project controller
      project: this.project,

      /* Editor controller */
      editor: this.editor,

      /* Dropping elements */
      droppable: this.droppable,

      /* Trim sizing */
      trim: this.trim,

      /* The spread */
      spread: this.spread,

      /* Spreads (for listing) */
      spreads: this.spreads,

      /* Frame selector */
      frame: this.frame,

      /* Frames (animation) */
      frames: this.frames,

      /* Select/Text Tool */
      tool: this.tool,

      /* Moving */
      moving: {
        is: this.state.moving,
        ...this.moving,
      },

      /* Label */
      label: { ...this.label, text: this.state.label },

      /* Controls */
      controls: {
        ...this.controls,
        hovering: this.state.controls,
      },

      /* Settings */
      settings: this.settings,

      /* Thumbs */
      thumb: this.thumb,

      /* Guides */
      guides: { ...this.state.guides, show: this.state.settings.guides },

      /* Metric/Imperial */
      units: {
        ...this.units,
        value: this.state.units.value,
        metric: this.state.units.value == "metric",
        values: this.state.units.values,
      },

      /* Moveable */
      moveable: this.moveable,

      /* Element */
      element: this.element,

      /* Elements */
      elements: this.elements,

      /* Fonts and families */
      families: this.families,
      colors: this.colors,

      /* Selected/Editing */
      edit: {
        ...this.edit,
        ...this.state.edit,
      },

      /* Sketching */
      sketch: this.sketch,

      /* Theme */
      theme: this.theme,
    };
    return (
      <>
        <DesignerContext.Provider
          value={{ ...this.state, ...context, buttons: SmartButtons(ButtonConfig.bind(this), this) }}
        >
          {this.props.children}
        </DesignerContext.Provider>
      </>
    );
  }
}

const withDesigner = (Component) => {
  return function ContextualComponent(props) {
    return <DesignerContext.Consumer>{(state) => <Component {...props} designer={state} />}</DesignerContext.Consumer>;
  };
};

const useDesigner = () => {
  const context = useContext(DesignerContext);
  return context;
};

export default withPerforming(withUser(withThumb(withUI(withBroadcast(withLocales(withErrors(withMedia(Designer))))))));
export { useDesigner, withDesigner };
