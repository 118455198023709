/** @format */
import Cookies from "cookies";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
} from "firebase/auth";

export default function signInWithAuthProvider(provider) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!provider?.scopes) throw error("unexpectedError");

      const auth = getAuth();
      let authProvider = null;

      if (provider.id == "google.com") authProvider = new GoogleAuthProvider();
      if (provider.id == "facebook.com") authProvider = new FacebookAuthProvider();
      if (provider.id == "twitter.com") authProvider = new TwitterAuthProvider();
      if (provider.id == "apple.com") authProvider = new OAuthProvider("apple.com");

      if (!authProvider) return reject("unexpectedError");

      // if (provider.id !== "apple.com") {
      provider.scopes.forEach((scope) => {
        authProvider.addScope(scope);
      });
      // }

      signInWithPopup(auth, authProvider)
        .then(({ user }) => {
          if (!user) return reject("unexpectedError");
          Cookies.remove("emailAddress");
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error.code);
        });
    } catch (error) {
      console.error(e);
      reject(error);
    }
  });
}
