/** @format */
import { Checkout } from "@Workspace/Billing";
import { Card } from "@mui/material";
import { useLocales } from "locales";

export default function CheckoutComponent(props) {
  const { t } = useLocales();
  return (
    <Card elevation={0}>
      <h3 style={{ textAlign: "center" }}>{t("letsFinishUp")}</h3>
      <Checkout {...props} />
    </Card>
  );
}
