/** @format */
import isSSR from "isssr";

import config from "../../config/index.json" assert { type: "json" };

/**
 * Sets the meta information for a page.
 * @param {Object} page - The page object containing the meta information.
 */
export function setMeta(page, found = true) {
  if (isSSR) return;

  // We really don't want bad page data breaking the entire app
  try {
    const locale = this.props.locales?.locale?.get() || "en-us";

    // Get the meta
    if (!page?.meta) page.meta = config.defaultMeta;

    // Merge thumbnail (doc) into image (strapi/social)
    let meta = { ...page.meta, thumbnail: page.meta.image };

    // Update the document title
    document.title = meta.title || "";

    // Now set the canonical URL if we set it dynamically
    const canonical = meta.canonicalURL || window.location.href;

    // Update canonical URL
    let canonicalTag = document.querySelector("link[rel='canonical']");
    if (canonicalTag) canonicalTag.setAttribute("href", canonical);

    // Update the debug meta help page
    let nameTag = document.querySelector(`meta[name="name"]`);
    if (nameTag) nameTag.setAttribute("content", page.name);

    // The meta information we want to update
    const metaKeys = ["description", "image", "thumbnail", "keywords", "url", "title"];

    // Add the canonical to the meta
    meta = { ...meta, url: canonical, canonical };

    // Loop through metaKeys and update corresponding meta tags
    metaKeys.forEach((key) => {
      // For standard meta tags
      let metaTag = document.querySelector(`meta[name="${key}"]`);
      if (metaTag) {
        metaTag.setAttribute("content", meta[key] || "");
      }

      // For Open Graph (Facebook) meta tags
      metaTag = document.querySelector(`meta[property="og:${key}"]`);
      if (metaTag) {
        metaTag.setAttribute("content", meta[key] || "");
      }

      // For Twitter meta tags
      metaTag = document.querySelector(`meta[name="twitter:${key}"]`);
      if (metaTag) {
        metaTag.setAttribute("content", meta[key] || "");
      }

      // ---- Languages ----

      const baseUrl = window.location.origin;

      // Assuming the locale code is always at the start of the path after the initial '/'
      const headElement = document.querySelector("head");

      // Update the lang attribute in the <html> tag
      document.documentElement.setAttribute("lang", locale);

      // remove the old hreflangs
      document.querySelectorAll('link[rel="alternate"][hreflang]').forEach((tag) => headElement.removeChild(tag));

      // get the current locales
      this.props.locales.locales
        .get()
        .filter((locale) => locale.enabled)
        .map((locale) => locale.code)
        .forEach((locale) => {
          // Ensure the regular expression matches case-insensitively and right from the start
          const newPath = window.location.pathname.replace(/^\/[a-z]{2}-[a-z]{2}\//i, `/${locale}/`);

          const linkTag = document.createElement("link");
          linkTag.rel = "alternate";
          linkTag.hreflang = locale;
          linkTag.href = `${baseUrl}${newPath}`; // Adjust URL replacement as needed
          headElement.appendChild(linkTag);
        });

      // Set pages are missing or not
      if (found === false) {
        try {
          const metaTag = document.createElement("meta");
          metaTag.name = "robots";
          metaTag.content = "noindex";
          document.head.appendChild(metaTag);
        } catch (_) {}
      } else {
        try {
          document.head.removeChild(metaTag);
        } catch (_) {}
      }
    });
  } catch (_) {
    console.log(_);
  }
}
