/** @format */

import { Box, Divider } from "@mui/material";
import React from "react";

import Main from "@Web/Layouts/Main";

import { withCms } from "cms";
import { withLocales } from "locales";
import { withRouter } from "router";
import { withUI } from "ui";

// Lazy load the components
import Blog from "./components/Blog";
import CTA from "./components/CTA";
import FAQ from "./components/FAQ";
import Features from "./components/Features";
import FreeTrial from "./components/FreeTrial";
import Hero from "./components/Hero";
import MobileGallery from "./components/MobileGallery";
import Partner from "./components/Partner";
import Resources from "./components/Resources";
import Review from "./components/Review";
import Time from "./components/Time";

import FooterBadges from "@Web/Blocks/FooterBadges";

const Home = (props) => {
  return (
    <Box>
      <Hero {...props} />
      <CTA {...props} />
      <Main>
        <Box position={"relative"}>
          <Time {...props} />
          <MobileGallery {...props} />
          <Features {...props} />
          <FAQ {...props} width={4} length={9} />
          <FreeTrial {...props} />
          <Partner {...props} />
          <Blog {...props} />
          <Review {...props} />
          <Resources {...props} />
        </Box>
        <Divider />
        <Box sx={{ padding: "2.5% 0" }}>
          <FooterBadges {...props} />
        </Box>
      </Main>
    </Box>
  );
};

export default withLocales(withUI(withCms(withRouter(Home))));
