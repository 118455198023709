/** @format */
import { detect } from "detect-browser";

import { nanoid } from "nanoid";
import { Component as ReactComponent, createContext, useContext } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";

import Cookies from "cookies";
import { withErrors } from "errors";
import isSSR from "isssr";
import { withLocales } from "locales";
import { withUI } from "ui";
import { withUnit } from "unit";

import Dialogs from "../components/Dialogs";

import Authentication from "./libraries/Authentication";
import Data from "./libraries/Data";

// User
import { getUser, setUser } from "./helpers/User";

// Name
import { getName, setName } from "./helpers/Name";

// Email
import { getEmail, setEmail } from "./helpers/Email";

// Meta
import { getMeta, setMeta, toggleMeta } from "./helpers/Meta";

// Token
import { getToken, setToken } from "./helpers/Token";

// Avatar
import { getAvatar, removeAvatar, setAvatar } from "./helpers/Avatar";

// Authentication providers
import { getProvided, getProvider, getProviders } from "./helpers/Provider";

// Permissions
import { getPermissions, setPermissions } from "./helpers/Permissions";

// Reauthentication
import { getReauthenticationProvider, getRequired, openReauthentication } from "./helpers/Reauthentication";

// Url
import { getUrl } from "./helpers/Url";

// Admin
import { isAdmin } from "./helpers/Admin";

// Billing
import { getBilling } from "./helpers/Billing";

// Sentry
import { setSentry } from "./helpers/Sentry";

const browser = detect();
const UserContext = createContext({});

const defaultUserState = {
  uid: null,
  ready: false,
  displayName: "",
  sessionId: isSSR ? null : Cookies.get("sessionId"),
  photoURL: null,
  admin: false,
  permissions: [],
  anonymous: false,
  browser: browser,
  sentry: false, // this will associate a user with a sentry error
};

if (!Cookies.get("sessionId")) Cookies.set("sessionId", nanoid());

class User extends ReactComponent {
  constructor(props) {
    super(props);
    try {
      this.unit = this.props.unit.new(this.constructor.name);

      this.defaultUserState = defaultUserState;

      this.state = defaultUserState;

      this.ready = false;

      // Create a connection helper
      this.connection = new Data({ onUpdated: this.onUpdated });

      this.data = {
        get: getUser.bind(this),
        set: {
          user: setUser.bind(this),
        },
      };

      this.user = {
        get: getUser.bind(this),
        set: setUser.bind(this),
      };

      this.email = {
        get: getEmail.bind(this),
        set: setEmail.bind(this),
      };

      this.permissions = {
        get: getPermissions.bind(this),
        set: setPermissions.bind(this),
      };

      this.name = {
        get: {
          first: getName.bind(this, "firstName"),
          last: getName.bind(this, "lastName"),
          full: getName.bind(this, "displayName"),
          display: getName.bind(this, "displayName"),
          initials: getName.bind(this, "initials"),
        },
        set: {
          first: setName.bind(this, "firstName"),
          last: setName.bind(this, "lastName"),
          display: setName.bind(this, "displayName"),
        },
      };

      this.meta = {
        set: setMeta.bind(this),
        get: getMeta.bind(this),
        toggle: toggleMeta.bind(this),
      };

      // Checks if we need authentication
      this.reauthentication = {
        required: getRequired.bind(this),
        open: openReauthentication.bind(this),
        check: openReauthentication.bind(this),
        provider: getReauthenticationProvider.bind(this),
      };

      // Tokens are used to secure accounts and updated hourly
      this.token = {
        get: getToken.bind(this),
        set: setToken.bind(this),
      };

      this.provided = getProvided.bind(this);
      this.provider = getProvider.bind(this);
      this.providers = getProviders.bind(this);

      this.avatar = {
        get: getAvatar.bind(this),
        set: setAvatar.bind(this),
        remove: removeAvatar.bind(this),
      };

      // Get the billing information (not sure this is supposed to be here)
      this.billing = getBilling.bind(this);

      // The user's url (for sharing and profiles)
      this.url = getUrl.bind(this);

      // Admin accounts (set in the database)
      this.admin = isAdmin.bind(this);

      // Linking the user with sentry
      this.sentry = setSentry.bind(this);
    } catch (error) {
      this.onError(error, "~230");
    }
  }

  connect = () => {
    this.authentication = new Authentication({
      onAuthenticated: this.onAuthenticated,
      onDeauthenticated: this.onDeauthenticated,
    }).observe();

    // Reauthentating user
    this.reauthenticate = {
      email: this.authentication.reauthenticateWithEmail,
      provider: this.authentication.reauthenticateWithProvider,
    };

    // Set the password
    this.password = {
      set: this.authentication.setPassword,
    };
  };

  disconnect = () => {
    // Forget the auth observer
    this.authentication.forget();

    // Disconnect the auth observer
    this.connection.disconnect();
  };

  /* Events */

  onAuthenticated = ({ user: thisUser = null }) => {
    if (thisUser.isAnonymous) {
      Cookies.set("anonymousUserId", thisUser.uid);
    } else {
      this.setState({
        uid: thisUser.uid,
        displayName: thisUser.displayName,
        photoURL: thisUser.photoURL,
        security: thisUser.metadata,
        ready: true,
      });
      this.connection.connect(thisUser, "~252").then(() => {
        this.user.set(thisUser, true, "~253");
      });
    }
    if (this.props.onAuthenticated) this.props.onAuthenticated();
  };

  onDeauthenticated = () => {
    this.setState({ ...defaultUserState, ready: true });
    if (this.props.onDeauthenticated) this.props.onDeauthenticated();
  };

  onUpdated = (user) => {
    this.ready = true;
    if (user == null) return;
    try {
      this.user.set(user, false, "~268");
      if (this.props.onUpdated) this.props.onUpdated();
    } catch (error) {
      const { errors, t } = this.props;
      errors.error(t("unexpectedError"), error, "~272");
    }
  };

  onConnected = () => {
    this.ready = true;
    if (this.props.onConnected) this.props.onConnected();
  };

  onDisconnected = () => {
    if (this.props.onConnected) this.props.onDisconnected();
  };

  onError = (message) => {
    const { errors, onError } = this.props;
    if (errors.error) errors.error(true, message);
    if (onError) onError();
  };

  authenticated() {
    return this.state.uid != null;
  }

  // Setup authentication observer after component is mounted
  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    try {
      this.disconnect();
    } catch (_) {}
  }

  componentDidUpdate() {
    // this will make sure sentry is updated
    this.sentry();
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          ready: this.ready,
          ...this.state,
          id: this.state.uid,
          authenticated: this.state.uid != null,
          authentication: this.authentication,
          permissions: this.permissions,
          reauthentication: this.reauthentication,
          admin: this.admin,
          provided: this.provided,
          provider: this.provider,
          providers: this.providers,
          avatar: this.avatar,
          password: this.password,
          url: this.url,
          token: this.token,
          meta: this.meta,
          name: this.name,
          email: this.email,
          data: this.data,
          user: this.user,
          billing: this.billing,
        }}
      >
        {this.props.children}
        {!isSSR && (
          <BrowserRouter>
            <WrappedDialogs {...this.props} />
          </BrowserRouter>
        )}
      </UserContext.Provider>
    );
  }
}

const WrappedDialogs = (props) => {
  let navigate = useNavigate();
  return <Dialogs navigate={navigate} {...props} />;
};

const useUser = () => {
  return useContext(UserContext);
};

const withUser = (Component) => {
  return function ContextualComponent(props) {
    return <UserContext.Consumer>{(state) => <Component {...props} user={state} />}</UserContext.Consumer>;
  };
};

export default withUnit(withErrors(withUI(withLocales(User))));
export { useUser, withUser };
