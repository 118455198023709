/** @format */
import { Component as ReactComponent } from "react";
import { exception } from "sentry";

class Tiny extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error) {
    this.setState({
      error: true,
    });
    try {
      exception(error);
    } catch (_) {}
  }

  render() {
    const { children, fallback } = this.props;
    if (this.state.error) return <>{fallback && fallback}</>;
    return children ? children : <></>;
  }
}

export default Tiny;
