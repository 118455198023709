/** @format */
import { Box, Button, Link, Stack, TextField } from "@mui/material";
import { useLocales } from "locales";

export default function Blog({ setEmail, disabled, send, email, working }) {
  const { t, url } = useLocales();

  return (
    <Stack direction="column" spacing={5}>
      <TextField
        disabled={disabled}
        placeholder={t("enterYourEmailAddress")}
        onKeyUp={({ target }) => setEmail(target.value)}
        multiline={true}
        fullWidth={true}
        sx={{ ml: 1, flex: 1, padding: 0, margin: 0, borderRadius: "3px" }}
      />
      <Stack direction="row" spacing={5}>
        <Box sx={{ fontSize: "12px" }}>
          {t("unsubscribeDisclaimer")}
          <Link href={url("pages/company/privacy")} target="_blank" sx={{ marginLeft: "4px" }}>
            {t("privacyPolicy")}
          </Link>
          .
        </Box>
        {!working && (
          <Box sx={{ textAlign: "right" }}>
            <Button variant="contained" disabled={!email} onClick={() => send()} color="success" size="large">
              {t("subscribe")}
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
