/** @format */

import { Box, Card, IconButton, styled } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { Working } from "performing";
import React from "react";
import { useUser } from "user";

// Custom styled Card component
const StyledCard = styled(Card)(() => ({
  zIndex: 1262,
  position: "fixed",
  bottom: "80px",
  border: "none",
  padding: 0,
  margin: 0,
  backgroundColor: "transparent",
  textAlign: "center",
  width: "calc(20vw - 10px)",
}));

// Custom styled Card component
const StyledBox = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode == "dark" ? theme.palette.info.dark : theme.palette.info.light,
  color: theme.palette.mode == "dark" ? "black" : "black",
  borderRadius: "3px",
  position: "relative",
  padding: "20px",
  textAlign: "left",
  fontSize: "12px",
  width: "100%",
  zIndex: 1400,
}));

export default function Tips({ workspace }) {
  if (workspace.assist.opened() || !workspace.tips.opened()) return null;

  const { meta } = useUser();

  const text = workspace.tips.text.get() || null;
  const working = workspace.tips.working.get();

  return (
    <StyledCard elevation={2} sx={{ left: "60px" }}>
      <StyledBox>
        <IconButton
          sx={{ fontSize: "14px", top: "-5px", right: "-5px" }}
          variant="close"
          size="small"
          onClick={() => meta.toggle("assistMute")}
        >
          <DynamicIcon icon="fa-times" />
        </IconButton>
        {text}
        {working && (
          <Box sx={{ opacity: 0.1, paddingTop: text ? "20px" : "10px", width: "100%" }}>
            <Working />
          </Box>
        )}
      </StyledBox>
    </StyledCard>
  );
}
