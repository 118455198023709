/** @format */

import { sendEmailVerification } from "firebase/auth";
import { auth } from "firemade";

export default function sendVerificationEmail() {
  return new Promise((resolve, reject) => {
    const user = auth.currentUser;

    if (!user) return reject("notLoggedIn");

    sendEmailVerification(user)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
