/** @format */
import { Box, LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(() => ({
  "&.box": {
    display: "flex",
    alignItems: "left",
    padding: "10px",
  },
  "& .percent": {
    fontSize: "10px",
    minWidth: "35px",
  },
}));

export default function linear(props) {
  let value = Math.round(props.value);
  let color = "primary";
  if (value < 33) color = "secondary";
  if (value > 32 && value < 66) color = "warning";
  if (value > 65 && value < 90) color = "primary";
  if (value > 89) color = "success";
  return (
    <StyledBox className="box">
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} color={color} />
      </Box>
      <Box className="percent">{`${value}%`}</Box>
    </StyledBox>
  );
}
