/** @format */
import { ReactEditor } from "slate-react";
import { Functional } from "unit";

const unit = Functional.unit("manuscript/editor");

export function editorsLoaded(from = "~7") {
  unit.report({
    method: "editorsLoaded",
    message: "Manuscript editor or editors are loaded.",
    test: "Editors should load visibly correctly with text placed apporpriately.",
    from: from,
    passed: true,
  });
  return this.editors.length > 0;
}

export function registerEditor(spread, editor, from = "~18") {
  unit.report({
    method: "registerEditor",
    message: "Adding spreadbreak from speedmenu.",
    analyze: true,
    action: true,
    test: "When a user inserts new spread a new spread should be available.",
    from: from,
    steps: ["From manuscript", "Add page break", "Command+Enter or Right Click > Page Break"],
  });
  this.editors[spread] = editor;
  // if (spread == 0) this.selectSpread(spread);
}

export function unregisterEditor(from = "~32") {
  unit.report({
    method: "registerSpread",
    message: "Delete spread break from speedmenu.",
    analyze: true,
    action: true,
    test: "When a user delete a new spread, the new spread is entered.",
    from: from,
    steps: ["From manuscript", "Select delete spread from menu", "Spread should be removed from screen"],
  });
  this.editors.forEach((i) => {
    delete this.editors[i];
  });
}

export function selectEditor(spread, hash = false, from = "~47") {
  let testId = unit.report({
    method: "selectEditor",
    message: "Selecting editor should take a screenshot.",
    analyze: true,
    action: true,
    test: "Thumbnail should be generated when editor is selected.",
    steps: ["From manuscript", "Select editor", "Thumbnail should be updated in spread menu"],
    from: from,
  });
  if (hash) history.pushState(null, null, "#" + spread); // document.location.href = "#" + spread;

  try {
    unit.passed({
      testId: testId,
    });
    // thumb.snap(spread);
  } catch (error) {
    console.error(error, "~65");
    unit.failed({
      testId: testId,
    });
  }
  this.setState({ spread: { number: spread } });
}

export function setEditor(from = "~73") {
  unit.report({
    method: "setEditor",
    message: "Updates and editor value.",
    analyze: true,
    action: true,
    from: from,
  });
  console.warn("Missing set editor.");
}

export function getEditor(from = "~84") {
  unit.report({
    method: "getEditor",
    message: "Gets the editor value.",
    analyze: true,
    action: true,
    from: from,
  });
  return this.editors[this.state.spread.number];
}

export function focusEditor() {
  // Restore focus back to the editor
  const editor = this.editors[this.state.spread.number];
  ReactEditor.focus(editor);
}

export function getEditorFocused() {
  // Restore focus back to the editor
  const editor = this.editors[this.state.spread.number];
  return ReactEditor.isFocused(editor);
}
