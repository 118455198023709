/** @format */

export default class Library {
  constructor(props) {
    let right = new (class Right {
      constructor(props) {
        this.ui = props.ui;
      }
      open = () => {
        this.ui.menu.open("right");
      };
      close = () => {
        this.ui.menu.close("right");
      };
    })(props);

    let workspace = new (class Workspace {
      constructor(props) {
        this.props = props;
        this.broadcast = props.broadcast;
        this.ui = props.ui;
        this.user = props.user;
      }
      assist = (id) => {
        this.broadcast.send({
          to: "Expanded",
          method: "assist",
          props: id,
          from: "Workspace",
        });
      };
      checklist = (id) => {
        this.broadcast.send({
          to: "Expanded",
          method: "checklist",
          props: id,
          from: "Workspace",
        });
      };
      kb = (slug) => {
        this.broadcast.send({
          to: "Workspace",
          method: "ask",
          props: slug,
          from: "Workspace",
        });
      };
    })(props);

    let manuscript = new (class Mansucript {
      constructor(props) {
        this.broadcast = props.broadcast;
        this.ui = props.ui;
        this.user = props.user;
      }
      wordcount = () => {
        try {
          this.ui.menu.open("assist/expanded");
          this.broadcast.send({
            to: "Expanded",
            method: "say",
            props: "manuscript/wordcount",
            from: "Mansucript",
          });
        } catch (_) {}
      };
    })(props);

    let pcn = new (class Pcn {
      constructor(props) {
        this.broadcast = props.broadcast;
        this.ui = props.ui;
        this.user = props.user;
      }
      open = () => {
        this.ui.dialog.open("feature", {
          feature: "registrations",
          title: "projectSettings",
          confirm: {
            text: "finished",
          },
        });
        this.ui.tab.set({ "workspace/registrations": 2 });
      };
    })(props);

    let isbn = new (class Isbn {
      constructor(props) {
        this.ui = props.ui;
      }
      open = () => {
        this.ui.dialog.open("feature", {
          feature: "registrations",
          title: "projectSettings",
          confirm: {
            text: "finished",
          },
        });
        this.ui.tab.set({ "workspace/registrations": 0 });
      };
    })(props);

    let project = new (class Project {
      constructor(props) {
        this.broadcast = props.broadcast;
        this.ui = props.ui;
        this.user = props.user;
      }
      open = (tab = 0) => {
        this.ui.dialog.open("feature", {
          feature: "project",
          title: "projectSettings",
          dialog: "project",
          confirm: {
            text: "finished",
          },
        });
        this.ui.tab.set({ "workspace/project": tab });
      };
    })(props);

    let query = new (class Query {
      constructor(props) {
        this.broadcast = props.broadcast;
        this.ui = props.ui;
        this.user = props.user;
      }
      open = (tab = 0) => {
        this.ui.dialog.open("feature", {
          feature: "query",
          title: "featureQueryTitle",
          dialog: "query",
          confirm: {
            text: "finished",
          },
        });
        // this.ui.tab.set({ "workspace/project": tab });
      };
    })(props);

    let pcip = new (class Pcip {
      constructor(props) {
        this.broadcast = props.broadcast;
        this.ui = props.ui;
        this.user = props.user;
      }
      open = () => {
        this.ui.dialog.open("feature", {
          feature: "registrations",
          title: "projectSettings",
          confirm: {
            text: "finished",
          },
        });
        this.ui.tab.set({ "workspace/registrations": 1 });
      };
    })(props);

    let registrations = new (class Registrations {
      constructor(props) {
        this.broadcast = props.broadcast;
        this.ui = props.ui;
        this.user = props.user;
      }
      open = (tab = 0) => {
        this.ui.dialog.open("feature", {
          feature: "registrations",
          title: "registrationsTitle",
          dialog: "registrations",
          confirm: {
            text: "finished",
          },
        });
        this.ui.tab.set({ "workspace/registrations": tab });
      };
    })(props);

    const components = {
      right: { open: right.open, close: right.close },
      workspace: { assist: workspace.assist, checklist: workspace.checklist, kb: workspace.kb },
      manuscript: { wordcount: manuscript.wordcount },
      pcn: { open: pcn.open },
      isbn: { open: isbn.open },
      project: { open: project.open },
      query: { open: query.open },
      pcip: { open: pcip.open },
      registrations: { open: registrations.open },
    };
    return { components: components };
  }
}
