/** @format */

import { Button } from "@mui/material";

import { useLocales } from "locales";
import { useRouter } from "router";

export default function ({ id }) {
  const { t } = useLocales();
  const { navigate } = useRouter();
  const { url } = useLocales();

  return (
    <Button variant="contained" size="small" color="success" onClick={() => navigate(url("/people/" + id))}>
      {t("view")}
    </Button>
  );
}
