/** @format */

import Config from "@Workspace/config";

/**
 * Sets up the checklist.
 *
 * @returns {any} The result of the calculateChecklist function.
 */
export default function setupChecklist() {
  return calculateChecklist.call(this);
}

/**
 * Calculates the checklist progress and updates the state.
 * @returns {Promise<boolean>} A promise that resolves to true if the calculation is successful.
 */
export function calculateChecklist() {
  return new Promise((resolve, reject) => {
    try {
      // Initialize counters and data structures
      let initialCount = { total: 0, complete: 0, nonOptionalTotal: 0, nonOptionalComplete: 0 };
      let count = {
        checklist: { ...initialCount },
        feature: { ...initialCount },
      };
      let features = {};
      let checklist = {};
      let time = [0, 0];

      // Get the available features and sort them by priority
      Object.values(Config.features)
        .sort((a, b) => a.priority - b.priority)
        .filter((feature) => feature.enabled === true)
        .forEach((feature) => {
          try {
            features[feature.slug] = {
              feature: Config.features[feature.slug],
              checklist: Config.checklist[feature.slug],
              steps: {},
            };
          } catch (error) {
            // Log error if feature setup fails
            console.error(`Error setting up feature ${feature.slug}:`, error);
          }
        });

      // Process each feature
      Object.keys(features).forEach((feature) => {
        // Initialize feature checklist
        checklist[feature] = {
          slug: feature,
          ...features[feature].checklist,
          ...{
            progress: {
              complete: 0,
              percent: 0,
              remaining: 0,
              total: Object.keys(features[feature].checklist).length,
              nonOptionalTotal: 0,
              nonOptionalComplete: 0,
            },
            time: [0, 0],
          },
        };

        let check = {
          completed: false,
          enabled: false,
        };

        count.feature = { ...initialCount };

        let validation = false;
        let featureTime = [0, 0];

        // Process each step in the feature
        Object.keys(checklist[feature].steps).forEach((step) => {
          if (checklist[feature].steps[step] == undefined) return;
          try {
            // Determine if step is completed
            if (features[feature].checklist.steps[step] === true) {
              check.completed = true;
            } else if (features[feature].checklist.steps[step].enabled) {
              const complete = features[feature].checklist.steps[step].complete;

              validation = "";
              if (!complete) {
                check.completed = true;
              } else {
                if (typeof complete == "boolean") {
                  return complete;
                } else if (typeof complete == "string") {
                  // Handle different types of completion checks
                  if (complete.startsWith("step.")) {
                    validation = "features." + feature + "." + complete.replace("step.", "checklist.");
                  } else if (complete.startsWith("data.")) {
                    validation = "features." + feature + "." + complete;
                  } else if (complete.startsWith("project.")) {
                    validation = complete;
                  } else if (complete == "shared") {
                    validation = "shared.includes('" + feature + "')";
                  } else if (complete == "kb") {
                    validation = "kb.includes('" + features[feature].checklist.steps[step].kb + "')";
                  }
                } else {
                  return false;
                }

                check.completed = this.checklist.step.completed(validation.replace(/^\./, "")) || false;
              }
            }
          } catch (error) {
            console.warn(`Error processing step ${step} in feature ${feature}:`, error);
          }

          check.enabled = true;

          if (!this.features[feature].checklist) this.features[feature].checklist = {};

          // Set up step details
          const isOptional = features[feature].checklist.steps[step].optional || false;
          checklist[feature].steps[step] = {
            slug: step,
            completed: check.completed || isOptional,
            enabled: check.enabled,
            complete: features[feature].checklist.steps[step].complete,
            optional: isOptional,
            checklist: feature,
            manual:
              features[feature].checklist.steps[step].complete &&
              features[feature].checklist.steps[step].complete.includes(`step.${step}`),
            visible:
              features[feature].checklist.steps[step].visible != null
                ? features[feature].checklist.steps[step].visible
                : true,
            title: features[feature].checklist.steps[step].title,
            actions: features[feature].checklist.steps[step].actions || [],
            kb: features[feature].checklist.steps[step].kb || null,
            excerpt: features[feature].checklist.steps[step].excerpt || null,
            priority: features[feature].checklist.steps[step].priority,
            number: 0,
            time: features[feature].checklist.steps[step].time || [0, 0],
            note: features[feature].checklist.steps[step].note || null,
          };

          // Update time calculations
          featureTime[0] += features[feature].checklist.steps[step].time[0];
          featureTime[1] += features[feature].checklist.steps[step].time[1];
          time[0] += features[feature].checklist.steps[step].time[0];
          time[1] += features[feature].checklist.steps[step].time[1];

          // Update counters
          if (check.enabled) {
            count.checklist.total++;
            count.feature.total++;
            if (!isOptional) {
              count.checklist.nonOptionalTotal++;
              count.feature.nonOptionalTotal++;
            }
          }

          if (check.completed) {
            count.feature.complete++;
            count.checklist.complete++;
            if (!isOptional) {
              count.checklist.nonOptionalComplete++;
              count.feature.nonOptionalComplete++;
            }
          }
        });

        // Calculate feature progress
        checklist[feature].progress = {
          total: count.feature.total,
          complete: count.feature.complete,
          remaining: count.feature.nonOptionalTotal - count.feature.nonOptionalComplete,
          percent:
            count.feature.nonOptionalTotal > 0
              ? Math.floor((count.feature.nonOptionalComplete / count.feature.nonOptionalTotal) * 100)
              : 100,
          nonOptionalTotal: count.feature.nonOptionalTotal,
          nonOptionalComplete: count.feature.nonOptionalComplete,
        };
        checklist[feature].time = featureTime;
      });

      // Nest features (if applicable)
      Object.values(checklist).forEach((feature) => {
        if (checklist[feature.nest]) {
          const parentFeature = checklist[feature.nest];
          parentFeature.steps = { ...parentFeature.steps, ...feature.steps };

          // Update parent feature progress
          parentFeature.progress.total += feature.progress.total;
          parentFeature.progress.complete += feature.progress.complete;
          parentFeature.progress.nonOptionalTotal += feature.progress.nonOptionalTotal;
          parentFeature.progress.nonOptionalComplete += feature.progress.nonOptionalComplete;
          parentFeature.progress.remaining =
            parentFeature.progress.nonOptionalTotal - parentFeature.progress.nonOptionalComplete;
          parentFeature.progress.percent =
            parentFeature.progress.nonOptionalTotal > 0
              ? Math.floor((parentFeature.progress.nonOptionalComplete / parentFeature.progress.nonOptionalTotal) * 100)
              : 100;

          // Update parent feature time
          parentFeature.time[0] += feature.time[0];
          parentFeature.time[1] += feature.time[1];

          // Remove nested feature from top level
          delete checklist[feature.slug];
        }
      });

      // Calculate overall progress
      let progress = {
        total: count.checklist.total,
        complete: count.checklist.complete,
        remaining: count.checklist.nonOptionalTotal - count.checklist.nonOptionalComplete,
        percent:
          count.checklist.nonOptionalTotal > 0
            ? Math.floor((count.checklist.nonOptionalComplete / count.checklist.nonOptionalTotal) * 100)
            : 100,
        nonOptionalTotal: count.checklist.nonOptionalTotal,
        nonOptionalComplete: count.checklist.nonOptionalComplete,
        checklist: {},
      };

      // Assign numbers to steps
      let numbers = 1;
      Object.keys(checklist).forEach((feature) => {
        Object.entries(checklist[feature].steps)
          .sort((a, b) => a[1].priority - b[1].priority)
          .forEach((step) => {
            checklist[feature].steps[step[0]].number = numbers;
            numbers++;
          });
      });

      // Update state with new checklist data
      this.setState((prevState) => ({
        ...prevState,
        checklist: {
          progress: progress,
          time: time,
          features: checklist,
        },
      }));

      resolve(true);
    } catch (error) {
      console.error("Error in calculateChecklist:", error);
      reject(error);
    }
  });
}

/**
 * Recalculates the checklist.
 */
export function recalculateChecklist() {
  calculateChecklist.call(this);
}

/**
 * Retrieves the checklist or a specific feature from the state.
 *
 * @param {string|null} feature - The name of the feature to retrieve. If null, the entire checklist will be returned.
 * @returns {Array|Object} - The checklist or the specified feature object.
 */
export function getChecklist(feature = null) {
  try {
    if (feature == null) return this.state.checklist;

    if (feature != null && this.state.checklist.features[feature]) return this.state.checklist.features[feature];
  } catch (error) {
    this.errors.error(this.props.t("unexpectedError"), error, "~239");

    return [];
  }
}

/**
 * Retrieves the progress of a feature or the overall project progress.
 *
 * @param {string} [feature] - The feature for which to retrieve the progress. If not provided, the overall project progress will be returned.
 * @returns {number} - The progress value of the specified feature or the overall project progress.
 */
export function getProgress(feature = null) {
  try {
    if (feature == null) {
      return this.state.checklist.progress;
    } else if (feature != null && this.state.checklist.features[feature]) {
      return this.state.checklist.features[feature].progress;
    } else {
      this.errors.error(true, "It looks like there was an error getting progress for this project.", "~260");

      return 0;
    }
  } catch (error) {
    const { errors } = this.props;

    errors.warn(true, error);

    return 0;
  }
}

/**
 * Retrieves the flattened checklist.
 * @returns {Array} The flattened checklist.
 */
export function getChecklistFlat() {
  try {
    let tmp = Object.keys(this.state.checklist.features)
      .map((feature) =>
        Object.keys(this.state.checklist.features[feature].steps).map(
          (step) => this.state.checklist.features[feature].steps[step]
        )
      )
      .flat(1);
    return tmp;
  } catch (error) {
    //this.errors.error(true, error, "~319");
    return [];
  }
}

/**
 * Retrieves the time associated with a checklist feature.
 *
 * @param {string|null} feature - The feature to retrieve the time for. If null, the overall checklist time is returned.
 * @returns {Array<number>} - An array representing the time associated with the feature. The first element is the hours and the second element is the minutes.
 */
export function getChecklistTime(feature = null) {
  if (feature == null) return this.state.checklist.time;
  try {
    return this.state.checklist.features[feature].time;
  } catch (e) {
    return [0, 0];
  }
}

/**
 * Retrieves the open state of the checklist from the component's state.
 *
 * @returns {boolean} The open state of the checklist.
 */
export function getChecklistOpen() {
  return this.state.checklist.open;
}

/**
 * Sets the checklist open state.
 *
 * @param {boolean} open - The new open state of the checklist.
 */
export function setChecklistOpen(open) {
  this.setState((prevState) => ({
    ...prevState,
    checklist: {
      ...prevState.checklist,
      open: open,
    },
  }));
}

/**
 * Toggles the 'open' state of the checklist in the component's state.
 *
 * This function updates the component's state by inverting the current 'open' value
 * of the checklist property.
 *
 * @function toggleChecklist
 * @this {React.Component} The React component instance.
 */
export function toggleChecklistOpen() {
  this.setState((prevState) => ({
    ...prevState,
    checklist: {
      ...prevState.checklist,
      open: !prevState.checklist.open,
    },
  }));
}
