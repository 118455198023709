/** @format */
import { Box, Button, Typography } from "@mui/material";
import { useCms } from "cms";
import { useLocales } from "locales";

const Application = () => {
  const { t } = useLocales();
  const { navigate } = useCms();
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Box>
          <Typography fontWeight={700} variant={"h5"} gutterBottom>
            {t("interested")}
          </Typography>
          <Typography>{t("hitUsUp")}</Typography>
        </Box>
        <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Button variant="contained" color="primary" size="large" onClick={() => navigate("pages/company/contact")}>
            {t("apply")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Application;
