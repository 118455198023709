/** @format */
import Config from "@Workspace/config";
import capitalize from "capitalize";

import { Functional } from "unit";

const unit = Functional.unit("workspace/tour");

export default async function setupTour(from = "~9") {
  let testId = unit.report({
    method: "setupTour",
    message: "Tour should run correctly",
    from: from,
  });

  return new Promise(async (resolve, reject) => {
    try {
      // Create an array of import promises
      const importPromises = Config.ui.tour.map((feature) =>
        import(`../../components/Features/components/${capitalize(feature)}/tour/index.js`)
      );

      // Wait for all dynamic imports to resolve
      const importedModules = await Promise.all(importPromises);

      // Assign the imported modules to workspace.tours
      importedModules.forEach((module, index) => {
        this.tours[Config.ui.tour[index]] = module.default;
      });

      unit.passed({ testId: testId });
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error });
      reject(error);
    }
  });
}

export function startTour(feature, props = {}) {
  const { errors } = this.props;
  return new Promise(async (resolve, reject) => {
    try {
      this.tours[feature](this, props);
      resolve(true);
    } catch (error) {
      errors.error("There was a problem starting the tour.", `${error} ${feature} tour is missing.`, "~47");
      reject(error);
    }
  });
}

export function getTours() {
  return Object.keys(this.tours);
}
