/** @format */

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "firemade";

export default function signInWithEmail(emailAddress, password) {
  return new Promise(async (resolve, reject) => {
    if (!emailAddress || !password) return reject("missingFields");
    if (auth.currentUser) return reject("alreadyLoggedIn");
    signInWithEmailAndPassword(auth, emailAddress, password) // or is it getAuth?
      .then((response) => {
        resolve(response.user);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
