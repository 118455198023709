/** @format */

import { PureComponent, createContext, useContext } from "react";

import { withLocales } from "locales";
import { withSnackbar } from "snackbar";

// Helpers
import { Error } from "./helpers/Error";
import { Warning } from "./helpers/Warning";

const ErrorsContext = createContext({});

class ErrorsComponent extends PureComponent {
  constructor(props) {
    super(props);

    const { t } = this.props.locales;

    this.unexpectedError = t("unexpectedError") || "There was an unexpected error.";

    this.unexpectedWarning = t("unexpectedWarning") || "There was a non-serious error.";

    this.error = Error.bind(this);

    this.warning = Warning.bind(this);
  }

  render() {
    return (
      <ErrorsContext.Provider
        {...this.props}
        value={{ ...this.state, error: this.error, warn: this.warning, warning: this.warning }}
      >
        {this.props.children}
      </ErrorsContext.Provider>
    );
  }
}

const withErrors = (Component) => {
  return function ContextualComponent(props) {
    return <ErrorsContext.Consumer>{(state) => <Component {...props} errors={state} />}</ErrorsContext.Consumer>;
  };
};

const useErrors = () => {
  return useContext(ErrorsContext);
};

export default withSnackbar(withLocales(ErrorsComponent));
export { useErrors, withErrors };
