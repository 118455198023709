/** @format */

import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { Box, Divider, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { withCms } from "cms";
import React from "react";

// Lazy load the components
import FeaturedArticles from "./components/FeaturedArticles";
import Hero from "./components/Hero";
import LatestStories from "./components/LatestStories";
import MostViewedArticles from "./components/MostViewedArticles";

const BlogNewsroom = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Main colorInvert={true}>
      <Hero />
      <Box bgcolor={"thirdly.main"}>
        <Container>
          <LatestStories {...props} start={1} end={4} width={4} orderBy="createdAt.desc" />
        </Container>
      </Box>
      <Container>
        <FeaturedArticles {...props} start={5} end={8} width={4} orderBy="createdAt.desc" />
      </Container>
      <Box bgcolor={"thirdly.main"}>
        <Container>
          <Grid container spacing={isMd ? 4 : 0}>
            <Grid item xs={12}>
              <MostViewedArticles {...props} start={9} end={12} width={4} orderBy="createdAt.desc" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth={800} paddingY={"0 !important"}>
        <Divider />
      </Container>
    </Main>
  );
};

export default withCms(BlogNewsroom);
