/** @format */

import { updateProfile } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, storage } from "firemade";

export default function setAvatar(avatar) {
  return new Promise((resolve, reject) => {
    const avatarFileTypes = ["image/gif", "image/jpeg", "image/png"];

    // User is no longer logged in
    if (!auth.currentUser.uid) return reject("notAuthenticated");

    // No new image was set
    if (!avatar) return reject("noAvatarSelected");

    // Image is no a valid file type
    if (!avatarFileTypes.includes(avatar.type)) return reject("invalidFileType");

    // Image is too large
    if (avatar.size > 20 * 1024 * 1024) return reject("imageTooLarge");

    const storageRef = ref(storage, auth.currentUser.uid);

    // Set custom metadata, including cache control
    const metadata = {
      cacheControl: "public, max-age=31536000",
    };

    uploadBytes(storageRef, avatar, metadata)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then(async (url) => {
            updateProfile(auth.currentUser, { photoURL: url })
              .then(() => {
                return resolve(url);
              })
              .catch((error) => {
                throw error;
              });
          })
          .catch((error) => {
            return reject(error);
          });
      })
      .catch((error) => {
        return reject(error);
      });
  });
}
