/** @format */

import { Alert } from "@mui/material";
import { useLocales } from "locales";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";

const DetectDevice = () => {
  const { t } = useLocales();
  if (isMobile || isTablet) {
    return (
      <Alert severity="warning" sx={{ fontSize: ".8em" }}>
        {t("detectProblemOnboarding")}
      </Alert>
    );
  }

  return null;
};

export default DetectDevice;
