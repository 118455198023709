/** @format */
import { Box, Divider } from "@mui/material";
import React from "react";

import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";

import { useLocales, withLocales } from "locales";

// Dynamically import the Monitor and Notices components as named exports
import { Monitor, Notices } from "monitor";

const Status = () => {
  const { t } = useLocales();

  return (
    <Main>
      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      >
        <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: "95%" }}>
          <Box
            justifyContent={"center"}
            flexDirection={{ textAlign: "center", padding: "10% 15%", minHeight: "500px" }}
            position={"relative"}
          >
            <h1>{t("monitorHeading")}</h1>
            <h5>{t("monitorDescription")}</h5>
            <Box sx={{ padding: "0 2.5%" }}>
              <Divider />
              <Notices />
            </Box>
            <Box marginTop="2.5%">
              <Monitor />
            </Box>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default withLocales(Status);
