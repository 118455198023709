/** @format */
import { Box, Card, Divider, Tab, Tabs } from "@mui/material";
import { useCms } from "cms";
import { TinyError } from "errors";
import key from "key";
import React, { useEffect, useState } from "react";

// Get the block
import Block from "../../../Block";

export default function ({ text, slugs }) {
  // get the page from the cms
  const { pages } = useCms();

  // A place to store the block
  const [blocks, setBlocks] = useState([]);

  // The tabs
  const [selectedTab, setSelectedTab] = useState(0);

  // Load the blocks into the window
  const loadBlock = (slug) => {
    // Merge all the block into a single block
    const page = pages.find((page) => page.slug == slug);

    // We don't have anything to show
    if (!page?.blocks) return;

    // Set the block
    setBlocks(page.blocks);
  };

  // Can changes to the tabs
  const handleChange = (_, newValue) => {
    // Set the selected tab
    setSelectedTab(newValue);
  };

  // On initial load, show the content
  useEffect(() => {
    loadBlock(slugs[0]);
  }, [pages]);

  // Return the component
  return (
    <Box>
      {text}
      <Divider sx={{ margin: "40px 0 10px 0px" }} />
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ marginTop: "20px", marginBottom: "20px" }}
      >
        {slugs.map((slug) => (
          <Tab
            key={slug}
            sx={{ fontSize: "12px", fontWeight: "bold" }}
            label={<>{slug.split("/").at(-1)}</>}
            onClick={() => loadBlock(slug)}
          />
        ))}
      </Tabs>
      {blocks.length > 0 && (
        <Card className="blocks" variant="outlined">
          {blocks.map((block) => (
            <Box className="block" {...key("assist2", "template", "multi", "more", "block", block.id)}>
              <TinyError>
                <Block {...block} />
              </TinyError>
            </Box>
          ))}
        </Card>
      )}
    </Box>
  );
}
