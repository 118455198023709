/** @format */
import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";
import { ComponentError } from "errors";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";
import { withCapture } from "../../context";
import CapturePlugin from "../Plugin";

const StyleBox = styled(Box)(({ theme }) => ({
  "&.reporter": {
    maxWidth: "400px",
    overflowY: "scroll",
  },
  "&.reporter .title": {
    fontSize: "16px",
    fontWeight: "bold",
    margin: "10px 0",
  },
  "&.reporter .description": {
    fontSize: "14px",
  },
  "&.reporter .actions": {
    fontSize: "12px",
    backgroundColor: "#EEEEEE44",
    borderTop: "1px solid #12121211",
    padding: "10px",
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  "& .label": {
    margin: "5px 0px",
    fontSize: "11px",
  },
  "& .label.bold": {
    fontSize: "12px",
    fontWeight: "bold",
  },
  "& .startOver": {
    width: "100%",
    backgroundColor: theme.palette.mode == "light" ? "#fafafa" : "#121212",
    position: "fixed",
    borderTop: "1px solid #DDD",
    padding: 10,
    bottom: 0,
    zIndex: 1700,
  },
}));

class Reporter extends ReactComponent {
  constructor(props) {
    super(props);
    this.unit = this.props.unit.new("Bugs");
    this.initialState = {
      showInstructions: true,
      showCapture: false,
      selectForums: false,
      showPublic: false,
      showTickets: false,
      showStartOver: false,
    };
    this.state = this.initialState;
  }

  startOver = () => {
    this.props.capture.clear();
    this.setState(this.initialState);
  };

  showInstructions = () => {
    this.setState({
      showCapture: false,
      selectForums: false,
      showInstructions: true,
      showStartOver: true,
    });
  };

  showCapture = () => {
    this.setState({
      showCapture: true,
      selectForums: false,
      showInstructions: false,
      showStartOver: true,
    });
  };

  continueWithMedia = () => {
    this.setState({
      showCapture: false,
      selectForums: true,
      showInstructions: false,
      showStartOver: false,
    });
  };

  showPublic = () => {
    this.setState({
      showCapture: false,
      selectForums: false,
      showInstructions: false,
      showPublic: true,
      showTickets: false,
      showStartOver: false,
    });
  };

  showTickets = () => {
    this.setState({
      showCapture: false,
      selectForums: false,
      showInstructions: false,
      showPublic: false,
      showTickets: true,
      showStartOver: false,
    });
  };

  enableCapture = () => {
    capture.enable();
    this.props.ui.menu.close();
  };

  onAdd = () => {
    console.log("missing");
  };

  onReply = () => {
    console.log("missing");
  };

  onEdit = () => {
    console.log("missing");
  };

  onTag = () => {
    console.log("missing");
  };

  componentDidMount() {
    if (this.props.capture.queued.length > 0) this.continueWithMedia();
  }
  render() {
    const { capture, t } = this.props;
    return (
      <StyleBox className="reporter">
        <Collapse in={this.state.showInstructions} sx={{ margin: "0 10px" }}>
          <Box className="title">{t("howItWorksTitle")}</Box>
          <Box className="description">{t("howItWorksDescription")}</Box>
        </Collapse>
        <Collapse in={this.state.showCapture} sx={{ margin: "0 10px" }}>
          <Box className="title">{t("captureTitle")}</Box>
          <Typography className="description">{t("captureDescription")}</Typography>
          <Box sx={{ marginBottom: "100px" }}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ margin: "20px 0" }}>
              <Button variant="tab" onClick={() => this.continueWithMedia("video")}>
                <DynamicIcon icon="fa-desktop" />
                <label>{t("video")}</label>
              </Button>
              <Button variant="tab" onClick={() => this.continueWithMedia("picture")}>
                <DynamicIcon icon="fa-image" />
                <label>{t("picture")}</label>
              </Button>
              <Button variant="tab">
                <DynamicIcon icon={"fa-upload"} onClick={() => this.continueWithMedia("upload")} />
                <label>{t("updload")}</label>
              </Button>
            </Stack>
          </Box>
        </Collapse>
        <Collapse in={this.state.selectForums}>
          <Typography className="title">{t("shareBugTitle")}</Typography>
          {capture.queued.length > 0 && <CapturePlugin {...this.props} />}
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Button variant="tab" onClick={() => this.showPublic()}>
              <DynamicIcon icon={"fa-circle-exclamation"} />
              <label>{t("public")}</label>
            </Button>
            <Button variant="tab" onClick={() => this.showTickets()}>
              <DynamicIcon icon={"fa-ticket"} />
              <label>{t("private")}</label>
            </Button>
          </Stack>
        </Collapse>
        <Collapse in={this.state.showPublic}>
          <ComponentError>
            {/*<Rosetta
                  {...this.props}
                  from="problems"
                  label="What seems to be the problem?"
                  placeholder="Please describe in detail what's happening..."
                  question="Have you experienced this?"
                  noMessages="Start a conversation..."
                  showTags={true}
                  showTextField={true}
                  compact={true}
                  onLoaded={this.onLoaded}
                  onError={this.onError}
                  onAdd={this.onAdd}
                  onReply={this.onReply}
                  onEdit={this.onEdit}
                  onTag={this.onTag}
                />*/}
          </ComponentError>
        </Collapse>
        {/*Tickets*/}
        <Collapse in={this.state.showTickets}>
          <ComponentError>
            {/*<Rosetta
                  {...this.props}
                  from="tickets"
                  label="Tickets Give You 1-on-1 Access"
                  placeholder="Tickets can only be seen by Made Live Staff."
                  question="Has this conversation been helpful?"
                  noMessages="Let us know how we can help..."
                  showTags={true}
                  userId={this.props.user.id}
                  showTextField={true}
                  compact={true}
                  onLoaded={this.onLoaded}
                  onError={this.onError}
                  onAdd={this.onAdd}
                  onReply={this.onReply}
                  onEdit={this.onEdit}
                  onTag={this.onTag}
                />*/}
          </ComponentError>
        </Collapse>
        <Stack direction="row" className="actions" justifyContent="flex-end" alignItems="center" spacing={2}>
          {this.state.showInstructions && (
            <Button color="success" variant="contained" size="small" onClick={this.showCapture}>
              {t("continue")}
            </Button>
          )}
          {!this.state.showInstructions && (
            <Button color="primary" size="small" variant="outlined" onClick={this.startOver}>
              {t("startOver")}
            </Button>
          )}
          {/* {capture.queued.length == 0 && this.state.capture != "" && this.state.capture != "upload" && (
            <Button
              color={capture.enabled != true ? "success" : "primary"}
              size="small"
              variant="contained"
              onClick={() => this.enableCapture()}
            >
              {capture.enabled != true ? <>{t("enable")}</> : <>{t("continue")}</>}
            </Button>
          )} */}
        </Stack>
      </StyleBox>
    );
  }
}

export default withCapture(withLocales(Reporter));
