/** @format */

const pages = {
  product: [
    {
      title: "topbarFeatures",
      href: "pages/product/features",
    },
    {
      title: "topbarPricing",
      href: "pages/product/pricing",
    },
    {
      title: "topbarHelp",
      href: "pages/help",
    },
    {
      title: "topbarKB",
      href: "pages/kb",
    },
    {
      title: "topbarFAQ",
      href: "pages/faq",
    },
    {
      title: "topbarRN",
      href: "pages/rn",
    },
  ],
  company: [
    {
      title: "topbarAbout",
      href: "pages/company/about",
    },
    {
      title: "topbarCareers",
      href: "pages/company/careers",
    },
    {
      title: "topbarContact",
      href: "pages/company/contact",
    },
    {
      title: "topbarPartners",
      href: "pages/company/partners",
    },
    {
      title: "topbarPress",
      href: "pages/press",
    },
  ],
  account: [
    {
      title: "topbarAccountSettings",
      href: "pages/account",
    },
    {
      title: "topbarAccountSecurity",
      href: "pages/account/security",
    },
    {
      title: "topbarNotifications",
      href: "pages/account/notifications",
    },
    {
      title: "topbarBilling",
      href: "pages/account/billing",
    },
    {
      title: "Sign up",
      href: "pages/account/signup",
    },
    {
      title: "Sign in ",
      href: "pages/account/signin",
    },
    {
      title: "topbarPasswordReset",
      href: "pages/account/password",
    },
  ],
  community: [
    {
      title: "Discussions",
      href: "discussions",
    },
    {
      title: "People",
      href: "people",
    },
  ],
  blog: [
    {
      title: "Blog",
      href: "pages/blog",
    },
  ],
  secondary: [
    {
      title: "Error 404: Cover",
      href: "pages/not-found",
    },
  ],
};

export default pages;
