/** @format */

import Link from "@mui/material/Link";
import { useLocales } from "locales";
import React from "react";

function IubendaLink() {
  const { t } = useLocales();
  return (
    <Link href="#" className="iubenda-cs-preferences-link" aria-label={t("yourPrivacySettings")}>
      {t("yourPrivacySettings")}
    </Link>
  );
}

export default IubendaLink;
