/** @format */

import { Functional } from "unit";

const unit = Functional.unit("Designer");

export function setFrame(number = 0, from = "~7") {
  unit.report({
    method: "setFrame",
    payload: text,
    test: "Sets the frame to edit.",
    from: from,
  });
  try {
    this.setState({ frame: { ...this.state.frame, number: number } });
  } catch (_) {}
}

export function getFrame(number = null, from = "~19") {
  unit.report({
    method: "getFrame",
    test: "Gets the current frame either return the selected or comparing it",
    from: from,
  });
  try {
    if (number == null) return this.state.frame.number;
    return this.state.frame.number == number;
  } catch (_) {}
}

export function getFrames(spread = null, from = "~31") {
  unit.report({
    method: "getFrame",
    test: "Gets the current frame either return the selected or comparing it",
    from: from,
  });
  try {
    const data = this.data("~38");
    spread = spread || this.state.spread.number;
    if (!data?.spreads[spread]?.style) return [];
    return data.spreads[spread].style;
  } catch (_) {}
}
