/** @format */
import { Box, Button, Link, Stack, TextField } from "@mui/material";
import { useLocales } from "locales";

export default function Features({ setEmail, disabled, send, email }) {
  const { t, url } = useLocales();

  return (
    <Stack direction="column" spacing={5}>
      <h3 style={{ textAlign: "center", margin: 0, padding: 0 }}>{t("stayUpToDate")}</h3>
      <Stack direction="row" spacing={2}>
        <TextField
          sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
          disabled={disabled}
          placeholder={t("enterYourFirstName")}
          onKeyUp={({ target }) => setFirstName(target.value)}
          multiline={true}
          fullWidth={true}
        />
        <TextField
          sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
          disabled={disabled}
          placeholder={t("enterYourEmailAddress")}
          onKeyUp={({ target }) => setEmail(target.value)}
          multiline={true}
          fullWidth={true}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={5}>
        <Box sx={{ fontSize: "12px" }}>
          {t("unsubscribeDisclaimer")}
          <Link href={url("pages/company/privacy")} target="_blank" sx={{ marginLeft: "4px" }}>
            {t("privacyPolicy")}
          </Link>
          .
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Button variant="contained" onClick={() => send()} color="success" size="large" disabled={!email}>
            {t("subscribe")}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}
