/** @format */
import { Box, Divider, IconButton, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import { useCms } from "cms";
import DynamicIcon from "dynamicicon";
import isSSR from "isssr";

// Lazy loading block components
import Author from "@Web/Blocks/Author";
import CmsAvatar from "@Web/Blocks/Avatar";
import Bio from "@Web/Blocks/Bio";
import BlogContent from "@Web/Blocks/BlogContent";
import Comments from "@Web/Blocks/Comments";

const Content = () => {
  const theme = useTheme();
  const { page } = useCms();

  return (
    <Box>
      <Box
        data-component="article"
        sx={{
          a: { color: theme.palette.secondary.main, fontWeight: "bold" },
        }}
      >
        <article>
          <BlogContent {...page} />
        </article>
      </Box>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"}>
        <Stack direction="row" display={"flex"} alignItems={"top"} spacing={3}>
          <Box sx={{ minWidth: "60px" }}>
            <CmsAvatar sx={{ width: 60, height: 60 }} variant="rounded" page={page} />
          </Box>
          <Box>
            <Box fontWeight={600}>
              <Box component="div" sx={{ fontSize: "1.2em" }}>
                <Author page={page} />
              </Box>
              <Box component="div" sx={{ fontSize: ".8em", maxWidth: "50%" }}>
                <Bio page={page} />
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"to"} sx={{ width: "200px" }}>
            <Box marginLeft={0.5}>
              <IconButton
                aria-label="Facebook"
                target="blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${isSSR ? "" : window.location.pathname}`}
              >
                <DynamicIcon icon="fa-facebook" />
              </IconButton>
              <IconButton
                href={`https://twitter.com/intent/tweet?text=${isSSR ? "" : window.location.pathname}`}
                target="blank"
                aria-label="Twitter"
              >
                <DynamicIcon icon="fa-twitter" />
              </IconButton>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <Comments />
    </Box>
  );
};

export default Content;
