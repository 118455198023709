/** @format */
import { doc, getDoc } from "firebase/firestore";
import { db } from "firemade";

/**
 * Fetches user data from Firestore based on the provided ID.
 * If the user data is found, it updates the state and returns the data.
 * If the user data is not found, it returns a placeholder.
 * If there is an error in fetching the user data, it returns a placeholder.
 *
 * @param {string} id - The ID of the user to fetch.
 * @returns {Promise<Object>} - A promise that resolves to the user data or a placeholder.
 */
export async function fetchUser(id) {
  try {
    // Fetch user data from Firestore
    const userDocRef = doc(db, "users", id);
    const userDocSnap = await getDoc(userDocRef);

    // Fetch profile data from Firestore
    const profileDocRef = doc(db, "profiles", id);
    const profileDocSnap = await getDoc(profileDocRef);

    let profileData = {};

    if (profileDocSnap.exists()) {
      // Profile data found, add it to the profileData object
      profileData = profileDocSnap.data();
    }

    if (userDocSnap.exists()) {
      // User data found, update the state and return the data
      const userData = { ...userDocSnap.data(), id: userDocSnap.id, ready: true, profile: profileData };

      this.setState((prevState) => ({
        users: [...prevState.users, userData],
        revised: Date.now(),
      }));
    }
  } catch (_) {
    console.log(_, id, "~30");
  }
}

/**
 * Fetches user data by ID. First checks if the user data is already in the state.
 * If not, fetches from Firestore and updates the state.
 *
 * @param {string} id - The ID of the user to fetch.
 * @returns {Promise<Object>} - A promise that resolves to the user data.
 */
export function getUser(id) {
  // Check if the user is already in the state
  const existingUser = this.state.users.find((user) => user.id === id);

  if (existingUser) {
    // User is found in the state, return it
    return existingUser;

    // We'll get this
  } else {
    // Fetch the user
    fetchUser.call(this, id);

    // Return the default placeholder for the moment
    return this.default;
  }
}
