/** @format */
import ActionsBar from "@Workspace/Components/ActionsBar";
import { Draggable } from "@Workspace/Components/Draggable";
import { useWorkspace } from "@Workspace/Context";
import { IconButton } from "@mui/material";
import DynamicIcon from "dynamicicon";

function Dnd(props) {
  const { children, allowDnD, element, onDrop, onDragStart, onDragEnd } = props;

  // This could be more flexible
  const { id, type, url } = element;

  if (!allowDnD) {
    return <>{children}</>;
  } else {
    return (
      <Draggable
        onDrop={(location) => onDrop({ ...location, type: type, id: id, url: url })}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        {children}
      </Draggable>
    );
  }
}

function Element(props) {
  const {
    children,
    element,
    allowEdit = false,
    allowDelete = false,
    showActions = false,
    allowDnD = true,
    disabled = false,
  } = props;
  const { drag } = useWorkspace();

  const editMedia = () => {
    // console.log("This is not done yet.");
  };

  const deleteMedia = () => {
    // console.log("This is not done yet.");
  };

  if (!children) return <></>;

  return (
    <Dnd allowDnD={allowDnD} onDrop={drag.drop} onDragStart={drag.start} onDragEnd={drag.end} element={element}>
      {children}
      {showActions && (
        <ActionsBar>
          {allowEdit && (
            <IconButton
              size="small"
              disabled={disabled}
              color="info"
              sx={{
                fontSize: 10,
              }}
              onClick={() => editMedia(element.id)}
            >
              <DynamicIcon icon="fa-cog" />
            </IconButton>
          )}
          {allowDelete && (
            <IconButton
              size="small"
              disabled={disabled}
              color="secondary"
              sx={{
                fontSize: 10,
              }}
              onClick={() => deleteMedia(element.id)}
            >
              <DynamicIcon icon="fa-trash" />
            </IconButton>
          )}
        </ActionsBar>
      )}
    </Dnd>
  );
}

export default Element;
