/** @format */

import { Alert, AlertTitle, Box, Stack } from "@mui/material";
import { format, subMinutes } from "date-fns";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "firemade"; // Assuming you have initialized and exported the Firestore instance as "db"
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";
import TimeAgo from "react-timeago";

const NoticesComponent = () => {
  const [notices, setNotices] = useState([]);
  const { t } = useLocales();

  useEffect(() => {
    const noticesRef = collection(db, "notices");

    const unsubscribe = onSnapshot(noticesRef, (snapshot) => {
      const noticesData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setNotices(noticesData);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const renderAlerts = () => {
    if (notices.length === 0) {
      const now = new Date();
      const timestamp = subMinutes(now, 28);
      const nominalTimestamp = format(timestamp, "yyyy-MM-dd HH:mm:ss");

      return (
        <Alert severity="success">
          <AlertTitle sx={{ textAlign: "left", fontSize: "14px" }}>{t("noNotices")}</AlertTitle>
          <Stack direction="row" spacing={2} sx={{ fontSize: "12px", marginTop: "10px" }}>
            <Box>
              <strong>{t("status")}</strong>: {t("allSystemsGo")}
            </Box>
            <Box>
              <strong>{t("lastUpdated")}</strong>: <TimeAgo date={nominalTimestamp} live={false} />
            </Box>
          </Stack>
        </Alert>
      );
    }

    return notices.map((notice) => {
      let severity = "error";

      if (notice.status === "investigating") {
        severity = "warning";
      } else if (notice.status === "resolved") {
        severity = "success";
      }

      const updatedTimestamp = format(notice.timestamp.updated, "yyyy-MM-dd HH:mm:ss");

      return (
        <Alert key={notice.id} severity={severity} sx={{ textAlign: "left" }}>
          <AlertTitle sx={{ textAlign: "left", fontSize: "14px" }}>{notice.message}</AlertTitle>
          <Stack direction="row" spacing={2} sx={{ fontSize: "12px", marginTop: "10px" }}>
            <Box>
              <strong>{t("status")}</strong>: {t(notice.status)}
            </Box>
            <Box>
              <strong>{t("lastUpdated")}</strong>: <TimeAgo date={updatedTimestamp} live={false} />
            </Box>
          </Stack>
        </Alert>
      );
    });
  };

  return <div>{renderAlerts()}</div>;
};

export default NoticesComponent;
