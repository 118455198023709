/** @format */
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";
import { ComponentError } from "errors";
import { Component as ReactComponent, cloneElement } from "react";

const Root = styled("div")(() => ({
  "& .closeLeft": {
    opacity: ".8",
    position: "absolute",
    left: "577px",
    top: window.innerHeight / 2 - 20,
    ":hover": {
      opacity: 1,
    },
  },
  "& .closeRight": {
    opacity: ".8",
    position: "absolute",
    right: "575px",
    top: window.innerHeight / 2 - 20,
    ":hover": {
      opacity: 1,
    },
  },
  "& .panel": {
    overflowY: "visible",
    borderRadius: "3px",
  },
}));

class TabPanel extends ReactComponent {
  constructor(props) {
    super(props);
    this.depth = /(null|undefined|^$)/.test(this.props.depth) ? 0 : this.props.depth + 1;
    this.panelWidth = /(null|undefined|^$)/.test(this.props.panelWidth) ? 520 - 20 : this.panelWidth - 20;
    this.panelPadding = /(null|undefined|^$)/.test(this.props.hasTabs) ? "20px 10px" : "10px";
  }

  panelWithProps = (children) => {
    if (this.depth == 0) {
      return cloneElement(children, {
        ...this.props,
        ...{ depth: this.depth, panelWidth: this.panelWidth },
      });
    } else {
      return <div>{children}</div>;
    }
  };

  render() {
    const { ui } = this.props;
    const depth = this.props.depth || 0;

    return (
      <ComponentError>
        <Root>
          <div
            role="tabpanel"
            className="panel"
            data-side={this.props.side}
            data-depth={this.props.depth}
            style={
              depth == 0
                ? {
                    backgroundColor: "rgba(0,0,0,.25)",
                    padding: this.panelPadding,
                    minHeight: 100 - this.depth * 40 + "vh",
                    width: this.panelWidth + "px",
                  }
                : {
                    backgroundColor: this.depth > 3 ? "transparent" : "rgba(0,0,0,.25)",
                    padding: this.panelPadding,
                    minHeight: 100 - this.depth * 40 + "vh",
                    width: this.panelWidth + "px",
                  }
            }
            hidden={!this.props.visible}
          >
            <>
              {depth == 0 && (
                <>
                  {this.props.side == "left" && (
                    <IconButton className="closeLeft" size="large" onClick={ui.drawers.close}>
                      <DynamicIcon icon={"fa-chevron-double-left"} />
                    </IconButton>
                  )}
                  {this.props.side == "right" && (
                    <IconButton className="closeRight" size="large" onClick={ui.drawers.close}>
                      <DynamicIcon icon={"fa-chevron-double-right"} />
                    </IconButton>
                  )}
                </>
              )}
              {this.props.children && this.panelWithProps(this.props.children)}
            </>
          </div>
        </Root>
      </ComponentError>
    );
  }
}

export default TabPanel;
