/** @format */

export function getSettings(setting = null) {
  try {
    return setting != null ? this.state.settings[setting] : this.state.settings;
  } catch (e) {
    return this.state.settings;
  }
}

export function setSettings(setting, value) {
  try {
    this.setState((prevState) => ({
      settings: {
        ...prevState.settings,
        [setting]: value,
      },
    }));
  } catch (e) {}
}

export function toggleSettings(setting) {
  try {
    this.setState((prevState) => ({
      settings: {
        ...prevState.settings,
        [setting]: !prevState.settings[setting],
      },
    }));
  } catch (e) {}
}
