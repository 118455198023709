/**
 * Sets the application's locale and redirects the user to the localized URL.
 *
 * This function is responsible for changing the application's current locale
 * and then navigating the user to the corresponding URL for the newly set locale.
 * It assumes the presence of a `locales` prop containing `set` and `url` functions,
 * where `set` is used to change the locale, and `url` generates the appropriate URL
 * based on the current page's slug.
 *
 * @format
 * @param {string} locale - The locale to switch to (e.g., "en", "fr").

 */

export function localize(locale) {
  // Destructure `set` and `url` methods from the `locales` prop.
  const { set, url } = this.props.locales;

  // Set the new locale and then navigate to the corresponding localized URL.
  set(locale).then(() => {
    // Redirect the user to the URL generated for the current page's slug in the new locale.
    document.location.href = url(this.state.page.slug);
  });
}
