/** @format */
import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "firemade";
import isSSR from "isssr";

import config from "../../config/index.json" assert { type: "json" };

import { setMeta } from "./Meta";

/**
 * Cleans the given URL path.
 *
 * @param {string} url - The URL path to be cleaned.
 * @param {string} [from="~22"] - The source of the URL path.
 * @returns {string} - The cleaned URL path.
 */
function cleanPath(url) {
  let path = url;

  try {
    if (path.includes("?")) path = path.split("?"[0]);
    if (config.home.includes(path)) path = config.home[0];
  } catch (error) {
    path = config.home[0];
  }

  return path;
}

/**
 * Retrieves the page data from the CMS server.
 *
 */
export async function getPage() {
  if (isSSR) return;

  // A place to keep the pages safe
  let pages = [];

  // Remember if this page has been found or not
  let found = true;

  // Wrap it up for safety
  try {
    // If it's a CSR request, clean the path using document.location.pathname
    const finalPath = cleanPath.call(this, document.location.pathname);

    // Get the current document
    let q = query(collection(db, "pages"), where("path", "==", finalPath));

    // Get the docs
    let qs = await getDocs(q);

    qs.forEach((doc) => pages.push(doc.data()));

    let page = pages[0] || null;

    // We're missing the page
    if (!page) {
      // Tell meta we havn't
      found = false;

      // Get the missing page
      page = await getMissing.call(this, finalPath);
    }

    // We can't even find the missing page
    if (!page) {
      this.error();

      this.setState({ ready: { ...this.state.ready, page: true, pages: [page] } });

      return;
    }

    // Set the meta immediately
    setMeta.call(this, page, found);

    // Set the state
    this.setState(
      {
        page: page || {},
        ready: { ...this.state.ready, page: true },
      },
      () => {
        getAllData.call(this);
      }
    );
  } catch (error) {
    console.log(error);
    this.error();
  }
}

/**
 * Retrieves a missing page from the database.
 * @returns {Promise<Object|null>} The missing page object, or null if not found.
 */
export async function getMissing() {
  // store the page
  let page = null;

  // Find all the pages
  let pages = [];

  // the missing path
  const missing = cleanPath.call(this, "/" + this.locale + config.missing);

  // Check if we have a state of all pages first
  if (this.state.pages.length > 0) {
    // Loop through the pages
    this.state.pages.forEach((page) => {
      if (page.path == missing) {
        pages.push(page);
      }
    });

    // We already have it, so return it
    if (pages.length > 0) {
      return pages[0];
    }
  } else {
    // Get the current document
    const q = query(collection(db, "pages"), where("path", "==", missing));

    // Get the docs
    const qs = await getDocs(q);

    // Loop through the pages
    qs.forEach((doc) => pages.push(doc.data()));

    // get the page
    page = pages[0] || null;
  }

  // Return it
  return page;
}

/**
 * Retrieves the full site content by fetching all custom content types and site data.
 *
 * This method is responsible for initiating the fetching of both custom content types
 * and general site data. It leverages the `getCustom` and `getSite` functions,
 * presumably defined elsewhere, applying them with the current context's `siteUpdates`
 * method. The calls are made with specific reference strings for tracking or debugging purposes.
 */
export function getAllData() {
  // Get all the pages
  getSite.call(this, "~113");

  // Get all the custom content types
  getCustom.call(this, "~114");
}

/**
 * Retrieves site data from Cms.
 * @returns {Promise<Array>} An array of pages.
 * @throws {Error} If there is an error retrieving the data.
 */
export async function getSite() {
  try {
    const qp = query(collection(db, "pages"), where("locale", "==", this.locale));
    const ps = await getDocs(qp);

    let pages = [];

    ps.forEach((doc) => {
      pages.push(doc.data());
    });

    this.setState({
      pages,
      ready: { ...this.state.ready, site: true },
    });

    return pages;
  } catch (error) {
    this.error(error);
  }
}

/**
 * Fetches documents from specified collections and applies an update function to each document.
 *
 * This function iterates over a predefined list of collections, fetching all documents
 * from each and applying a site update function to them.
 *
 * @param {Function} siteUpdates - A function to be called with each document's data.
 *                                 Expected to take the collection name, document data,
 *                                 and a reference string as arguments.
 */
export async function getCustom() {
  // Use Promise.all to wait for all collections to be processed
  await Promise.all(
    config.custom.map(async (customCollection) => {
      let querySnapshot = await getDocs(query(collection(db, customCollection)));

      let custom = [];

      // Add each of the custom docs to the custom thins
      querySnapshot.forEach((doc) => {
        custom.push(doc.data());
      });

      // Get the latest updates
      let update = { ...this.state.collection };

      // Updte the custom collection
      update[customCollection] = custom;

      // Update the state customer
      this.setState({
        ready: { ...this.state.ready, custom: true, site: true },
        custom: update,
      });
    })
  );
}
