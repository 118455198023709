/** @format */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ComponentError } from "errors";
import { Component as ReactComponent, cloneElement } from "react";

const Root = styled(Box)(() => ({
  "& .panel": {
    overflowY: "visible",
    borderRadius: "3px",
  },
}));

class DialogPanel extends ReactComponent {
  constructor(props) {
    super(props);
  }

  panelWithProps = (children) => {
    return cloneElement(children, {
      ...this.props,
    });
  };

  render() {
    return (
      <ComponentError>
        <Root>
          <div
            role="tabpanel"
            className="panel"
            data-side={this.props.side}
            data-depth={this.props.depth}
            hidden={!this.props.visible}
          >
            <>{this.props.children && this.panelWithProps(this.props.children)}</>
          </div>
        </Root>
      </ComponentError>
    );
  }
}

export default DialogPanel;
