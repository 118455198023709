/** @format */
import { differenceInDays } from "date-fns";
import { auth } from "firemade";

// # TODO adding last password change would be a good feature here.
export default function getSecurityRating() {
  const user = auth.currentUser;

  if (!user || !user.metadata) return null;

  let creationTime = user.metadata.creationTime;

  if (!creationTime) return 0;

  // Convert creationTime to a Date object
  const creationDate = new Date(creationTime);

  let securityRating = 0;

  // if (userData && userData.lastPasswordChange) {
  //   let lastPasswordChange = userData.lastPasswordChange;

  //   if (lastPasswordChange) {
  //     if (differenceInDays(lastPasswordChange, lastPasswordChange) >= 365.242199) {
  //       securityRating = 50;
  //     } else {
  //       securityRating = 100;
  //     }
  //   }
  // } else {
  if (differenceInDays(new Date(), creationDate) >= 365.242199) {
    securityRating = 50;
  } else {
    securityRating = 100;
  }
  //}

  return securityRating;
}
