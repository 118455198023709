/** @format */

export function onRotate(event) {
  try {
    if (!event?.target) return;

    const transform = this.element.style.transform(event.transform, "~17");

    this.element.style.set(
      {
        event: event,
        label: event.rotate.toFixed(1) + "deg",
        style: {
          transform: { rotate: transform.rotate },
        },
      },
      "~27"
    );
  } catch (error) {
    console.error(error, "~30");
  }
}

export function onRotateGroup(event) {
  try {
    if (!event?.events) return;

    event.events.forEach((event) => {
      const transform = this.element.style.transform(event.drag.transform, "~44");

      let adjustedXY = {};

      try {
        const elementLeft = parseFloat(event.target.style.left.replace("px", ""));
        const elementTop = parseFloat(event.target.style.top.replace("px", ""));

        const rotatedLeft = transform.translate[0];
        const rotatedRight = transform.translate[1];

        adjustedXY = { left: elementLeft + rotatedLeft, top: elementTop + rotatedRight };
      } catch (e) {}

      this.element.style.set(
        {
          event: event,
          label: event.rotate.toFixed(1) + "deg",
          style: {
            ...adjustedXY,
            ...{
              transform: { rotate: transform.rotate },
            },
          },
        },
        "~69"
      );
    });
  } catch (_) {}
}
