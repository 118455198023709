/** @format */

import Badge from "@mui/material/Badge";
import { styled } from "@mui/system";
import { useUser } from "user";
import { useNotifications } from "../../";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    borderRadius: "3px",
    marginTop: "3px",
  },
}));

export default function ({ children }) {
  // Make sure we're logged in
  const { authenticated } = useUser();

  // Get the notifications
  const { mark, unseen, unfollowed } = useNotifications();

  // if not logged in, we don't need a badge
  if (!authenticated) return children;

  // A default onClick
  let onClick = () => {};

  // Reassign the click to set as seen
  if (unseen > 0) {
    onClick = mark.seen;
  }

  return (
    <StyledBadge
      badgeContent={unseen == 0 && unfollowed > 0 ? unfollowed : unseen}
      color="error"
      variant={unseen == 0 && unfollowed > 0 ? "dot" : "standard"}
      onClick={onClick}
    >
      {children}
    </StyledBadge>
  );
}
