/** @format */

import { doc, setDoc } from "firebase/firestore";

function update(payload) {
  const { id } = payload; // Extract id from the payload

  try {
    return new Promise(async (resolve) => {
      // Iterate through each key (query) in the queries object
      for (const query in this.state.queries) {
        const mediaArray = this.state.queries[query];
        const mediaIndex = mediaArray.findIndex((media) => media.id === id);

        // If a match is found, merge the payload into the matched media object and update the state
        if (mediaIndex > -1) {
          const updatedMedia = { ...mediaArray[mediaIndex], ...payload };

          // Update the media in the state
          this.setState((prevState) => ({
            queries: {
              ...prevState.queries,
              [query]: [
                ...prevState.queries[query].slice(0, mediaIndex),
                updatedMedia,
                ...prevState.queries[query].slice(mediaIndex + 1),
              ],
            },
          }));

          // Update the media in the database
          const docRef = doc(this.connection, id);
          await setDoc(docRef, updatedMedia);
        }
      }

      resolve(payload);
    });
  } catch (error) {
    this.onError(error);
    return false;
  }
}

export { update };
