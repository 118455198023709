/** @format */
import { Fade } from "@mui/material";

/**
 * Opens a snackbar with the specified message and type.
 * @param {string} message - The message to display in the snackbar.
 * @param {string} [type="default"] - The type of the snackbar. Defaults to "default".
 */
function open(message, type = "default") {
  const { performing } = this.props;
  try {
    // Close any performing indicators
    performing.set.updating(null, "~13");

    this.props.enqueueSnackbar(message, {
      TransitionComponent: Fade,
      variant: type,
      preventDuplicate: true,
      sx: { right: "200px !important" },
    });
  } catch (_) {}
}

export { open };
