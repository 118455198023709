/** @format */
import { Box, Grid, Hidden, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Text from "@Web/Blocks/Text";
import cdn, { alt } from "cdn";

const placeholder =
  "https://us-central.cdn.made.live/connection_abbdfe4d66/connection_abbdfe4d66.png?updated_at=2023-02-16T22:26:09.059Z";

const Story1 = (props) => {
  const theme = useTheme();
  return (
    <Box style={{ textAlign: "left" }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography component={"h2"} fontWeight={700}>
            <Text {...props} number={1} />
          </Typography>

          <Typography component={"div"} color={"text.secondary"} fontWeight={400}>
            <Text {...props} number={2} />
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              src={cdn(placeholder)}
              alt={alt(placeholder)}
              role="img"
              sx={{
                objectFit: "cover",
                border: `1px solid #12121211`,
                borderRadius: "3px",
                maxHeight: 360,
                filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
              }}
              component="img"
              loading="lazy"
              height={1}
              width={1}
            />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default Story1;
