/** @format */

import { format, parseISO } from "date-fns";
import { TinyError } from "errors";
import React from "react";
import TimeAgo from "react-timeago";

/**
 * A wrapper for the TimeAgo component that accepts ISO date strings.
 *
 * @param {string} isoDate - An ISO-formatted date string.
 */
const IsoTimeAgo = ({ timestamp, field = "created", ...rest }) => {
  let isoDate;

  // Get the isoDate prop lexiably
  if (typeof timestamp === "string" || typeof timestamp === "number") {
    isoDate = timestamp;
  } else if (typeof timestamp === "object") {
    // We might just pass in a raw timestamp object
    if (timestamp.timestamp) timestamp = timestamp.timestamp;

    if (timestamp[field]) {
      isoDate = timestamp[field];
    } else if (timestamp.created) {
      isoDate = timestamp.created;
    } else if (timestamp.updated) {
      isoDate = timestamp.updated;
    }
  }

  // We didn't get an appropriate date
  if (!isoDate) return null;

  // Parse the ISO date string into a JavaScript Date object
  const date = typeof isoDate == "number" ? format(isoDate, "MMM dd, yyyy HH:mm:ss") : parseISO(isoDate);

  // Fallback error catching
  if (!date) return null;

  // Render the TimeAgo component with the parsed date (number converts old timestamp format)
  return (
    <TinyError>
      <TimeAgo date={date} {...rest} />
    </TinyError>
  );
};

export default IsoTimeAgo;
export { IsoTimeAgo as TimeAgo };
