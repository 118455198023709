/** @format */
import { useEffect } from "react";
import { debug } from "./config";

import isSSR from "isssr";

const lazybg = () => {
  const log = (...args) => {
    if (debug) console.log(...args);
  };

  useEffect(() => {
    log("Observed connecting...");

    let observer;

    try {
      // Check if window and document are available
      if (isSSR) {
        log("Window or Document is not available.");
        return;
      }
      // Check for IntersectionObserver support
      if ("IntersectionObserver" in window) {
        function handleIntersection(entries) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Item has crossed our observation threshold - load src from data-lazybg
              entry.target.style.backgroundImage = "url('" + entry.target.dataset.lazybg + "')";
              log("Element is in view. Background image loaded.");
              // Job done for this item - no need to watch it!
              observer.unobserve(entry.target);
            }
          });
        }

        const elements = document.querySelectorAll("[data-lazybg]");

        observer = new IntersectionObserver(handleIntersection, { rootMargin: "100px" });

        elements.forEach((element) => {
          observer.observe(element);
        });

        log("Observed connected.");
        log(`Observing ${elements.length} elements.`);
      } else {
        log("IntersectionObserver not supported. Loading all images.");
        // No interaction support? Load all background images automatically
        const elements = document.querySelectorAll("[data-lazybg]");
        elements.forEach((element) => {
          element.style.backgroundImage = "url('" + element.dataset.lazybg + "')";
        });
      }
    } catch (_) {}
    return () => {
      log("Observer disconnecting...");
      try {
        observer.disconnect();
        log("Observer disconnected.");
      } catch (e) {
        log("No observer to disconnect.");
      }
    };
  }, []);

  return null;
};

export default lazybg;
