/** @format */

import { auth } from "firemade";

export default function deleteAccount() {
  return new Promise((resolve, reject) => {
    if (!auth.currentUser) {
      reject(new Error("No current user"));
      return;
    }
    auth.currentUser
      .delete()
      .then((value) => {
        resolve(value);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
