/** @format */

import { Box, IconButton } from "@mui/material";
import { Comments } from "discussion";
import DynamicIcon from "dynamicicon";
import { useLocales } from "locales";
import { useRouter } from "router";

function Thread({ thread, category }) {
  // Wrap the url in localization
  const { url } = useLocales();

  // Get the navigation (use router to skip page checking...)
  const { navigate } = useRouter();

  // Do stuff with this comment
  const exit = () => {
    navigate(url(`/community/${category}`));
  };

  // Make sure we have a thread here
  if (!thread) return null;

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", zIndex: 12800, right: "-5px", top: "-25px" }}>
        <IconButton variant="close" size="small" onClick={exit} role="button">
          <DynamicIcon icon="fa-times" />
        </IconButton>
      </Box>
      <Comments
        slug={`discussion-${category}`}
        enabled={true}
        placeholder="whatDoYouWantToShare"
        moderate={true}
        analyze={true}
        comment={false}
        replies={true}
        messageId={thread}
        messageAvatar={30}
        replyAvatar={30}
        link={`/community/${category}`}
      />
    </Box>
  );
}

export default Thread;
