/** @format */
import { Button, DialogActions, DialogContent } from "@mui/material";
import { useLocales } from "locales";

export default function MissingComponent(props) {
  const { t } = useLocales();
  const { disabled } = props;
  return (
    <>
      <DialogContent sx={{ padding: "20px", minWidth: "300px" }}>{t("missingProjectErrorMessage")}</DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" disabled={disabled} onClick={() => (document.location.href = "/")}>
          {t("backToSafety")}
        </Button>
      </DialogActions>
    </>
  );
}
