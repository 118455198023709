/** @format */

import { Functional } from "unit";

const unit = Functional.unit("Designer");

export function setElementFlip(direction, from = "~7") {
  unit.report({
    method: "setElementFlip",
    message: "Put this back in.",
    payload: direction,
    from: from,
  });

  try {
    const {
      index,
      spread = this.state?.spread?.number || 0,
      frame = this.state.frame.number || 0,
    } = this.element.selected();
    const data = this.data("~21");

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) return exit("Missing element or element style.", "~23");

    const { scale } = data.spreads[spread].elements[index].style[frame].transform;
    const newScale = [...scale]; // create a copy to avoid modifying the original array

    // Switch on the direction to determine which axis to flip
    switch (direction) {
      case "horizontal":
        // Flip the x scale
        newScale[0] = newScale[0] * -1;
        break;
      case "vertical":
        // Flip the y scale
        newScale[1] = newScale[1] * -1;
        break;
    }

    data.spreads[spread].elements[index].style[frame].transform.scale = newScale;

    this.project
      .update(
        {
          data: data,
          note: "Flipped element.",
          ai: 0,
        },
        "~49"
      )
      .then(() => {
        // this.refreshElements();
      })
      .catch((error) => {
        throw error;
      });
  } catch (_) {
    return; // Could add some error/feedback handling here.
  }
}
