/** @format */
import { Box, Button, Grid, Link, Popover, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useCms } from "cms";
import DynamicIcon from "dynamicicon";
import { useLocales } from "locales";
import { useEffect, useState } from "react";

const Links = ({ title, id, items, colorInvert = false, elevated = false }) => {
  const theme = useTheme();
  const { link } = useCms(); //navigate,
  const { t } = useLocales();

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const [activeLink, setActiveLink] = useState("");
  const hasActiveLink = () => items.find((i) => i.href === activeLink);
  const linkColor = colorInvert && !elevated ? "common.white" : "#12545c";

  if (items.length == 0) return <></>;

  return (
    <Box>
      {items.length == 1 ? (
        <Link
          component={"a"}
          href={items[0].href}
          onClick={(event) => {
            link(event, items[0].href);
          }}
          sx={{ textDecoration: "none" }}
          fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
          color={linkColor}
        >
          {title}
        </Link>
      ) : (
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            aria-describedby={id}
            sx={{ cursor: "pointer", color: linkColor }}
            onClick={(e) => handleClick(e, id)}
          >
            <Typography
              fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
              color={linkColor}
              sx={{ marginRight: "10px" }}
            >
              {title}
            </Typography>
            <DynamicIcon
              icon="fa-chevron-down"
              sx={{
                marginLeft: theme.spacing(1 / 4),
                width: 16,
                height: 16,
                transform: openedPopoverId === id ? "rotate(180deg)" : "none",
                color: linkColor,
              }}
            />
          </Box>
          <Popover
            elevation={3}
            id={id}
            open={openedPopoverId === id}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              ".MuiPaper-root": {
                maxWidth: items.length > 12 ? 350 : 250,
                padding: 2,
                marginTop: 2,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomRightRadius: 8,
                borderBottomLeftRadius: 8,
                borderTop: `3px solid ${theme.palette.primary.main}`,
              },
            }}
          >
            <Grid container spacing={0.5}>
              {items.map((p, i) => (
                <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
                  <Button
                    component={"a"}
                    href={p.href}
                    onClick={(event) => {
                      link(event, p.href);
                      handleClose();
                    }}
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      color: activeLink === p.href ? theme.palette.primary.main : theme.palette.text.primary,
                      backgroundColor: activeLink === p.href ? alpha(theme.palette.primary.main, 0.1) : "transparent",
                      fontWeight: activeLink === p.href ? 600 : 400,
                    }}
                  >
                    {t(p.title)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default Links;
