/** @format */
import { Box, Button, DialogActions, Grid, Stack, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withLocales } from "locales";
import { withPerforming } from "performing";
import { useState } from "react";

const StyledBox = styled(Box)(() => ({
  "& .item": {
    padding: 0,
  },
  "& .label": {
    fontWeight: "600",
  },
}));

function EmailPassword(props) {
  const { t, ui, disabled, performing, user, errors, callback } = props;

  const [password, setPassword] = useState("");

  const reauthenticateWithEmail = () => {
    performing.set.updating("updating", "~22");

    user.authentication
      .reauthenticateWithEmail(user.email.get(), password)
      .then(() => {
        // Set that we're working
        performing.set.updating("success", "~28");

        // Close the reauthentication dialog
        ui.confirm.close();

        // Let the calling function know we're good to go
        callback && callback(true);
      })
      .catch((code) => {
        // Output the errors
        errors.error(t(code), code, "~38");

        // Let the caller know it went poorly
        callback && callback(false);
      })
      .finally(() => {
        performing.set.loading(false, "~44");
      });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Check if we should submit
  const onKeyDown = (event) => {
    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) return;

    if (event.key === "Enter") reauthenticateWithEmail();
  };

  return (
    <StyledBox sx={{ marginTop: "5px" }}>
      <Grid container direction="column" spacing={1} sx={{ padding: "2.5% 10%" }}>
        <Grid item xs className="item" style={{ marginTop: "10px" }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box component={"div"} className="label">
              {t("enterPassword")}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs className="item">
          <TextField
            size="small"
            autoComplete="current-password"
            disabled={disabled}
            fullWidth
            required
            type="password"
            sx={{ backgroundColor: "#FFF" }}
            variant="outlined"
            InputLabelProps={{ required: false }}
            onChange={handlePasswordChange}
            onKeyDown={onKeyDown}
          />
        </Grid>

        <Grid item style={{ textAlign: "right" }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box sx={{ margin: "20px 0px", textAlign: "left" }}></Box>
          </Stack>
        </Grid>
      </Grid>
      <DialogActions>
        <Button color="success" variant="contained" disabled={!password} onClick={reauthenticateWithEmail}>
          {t("continue")}
        </Button>
      </DialogActions>
    </StyledBox>
  );
}

export default withPerforming(withLocales(EmailPassword));
