/** @format */
import { Box, Button, Hidden, Link, Stack, TextField } from "@mui/material";
import { useLocales } from "locales";

export default function Partners({ setEmail, setFirstName, send, working, disabled, email, setMessage }) {
  const { t, url } = useLocales();

  return (
    <Stack direction="column" spacing={5}>
      {/* <h4 style={{ margin: 0, padding: 0 }}>{t("contactUs")}</h4> */}
      <Stack direction="column" spacing={2}>
        <TextField
          sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
          disabled={disabled}
          placeholder={t("enterYourFirstName")}
          onKeyUp={({ target }) => setFirstName(target.value)}
          multiline={true}
          fullWidth={true}
        />
        <TextField
          sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
          disabled={disabled}
          placeholder={t("enterYourEmailAddress")}
          onKeyUp={({ target }) => setEmail(target.value)}
          multiline={true}
          fullWidth={true}
        />
        <TextField
          sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
          disabled={disabled}
          rows={8}
          placeholder={t("enterYourMessage")}
          onKeyUp={({ target }) => setMessage(target.value)}
          multiline={true}
          fullWidth={true}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={5}>
        <Box sx={{ fontSize: "12px" }}>
          <Hidden smDown>{t("unsubscribeDisclaimer")}</Hidden>
          <Link href={url("pages/company/privacy")} target="_blank" sx={{ marginLeft: "4px" }}>
            {t("privacyPolicy")}
          </Link>
          .
        </Box>
        <Box sx={{ textAlign: "right" }}>
          {!working && (
            <Button
              variant="contained"
              onClick={() => send(["potential_partner"])}
              color="success"
              size="large"
              disabled={!email}
            >
              {t("subscribe")}
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
