/** @format */

// THIS COMPONENT NEEDS TO BE REWRITTEN

import { doc, getDoc } from "firebase/firestore"; // , arrayUnion, setDoc, updateDoc
import { db } from "firemade";
import { Functional } from "unit";

const unit = Functional.unit("workspace/ai");

// # TODO this could save that a collection exists rather than read/write

export default function setupAi(from = "~11") {
  const { errors, t } = this.props;
  return new Promise((resolve, reject) => {
    unit.report({
      method: "setupAi",
      from: from,
      message: "Sets up AI logging interval.",
    });
    try {
      this.aiInterval = setInterval(() => {
        // Replace the following line with whatever AI-related task you want to run every 3 seconds
        saveAi.call(this, "~22");
      }, 3000);
      resolve(true);
    } catch (error) {
      errors.error(t("errorAi"), error, "~26");
      reject(error);
    }
  });
}

export function logAi({ ai, note }, from = "~32") {
  unit.report({
    method: "logAi",
    from: from,
    message: "Logs some ai information.",
  });
  try {
    if (ai !== null && note !== null)
      this.setState({
        ai: [...this.state.ai, { timestamp: { created: Date.now(), updated: Date.now() }, ai, note }],
      });
  } catch (e) {
    console.error(e);
  }
}

export function saveAi(from = "~48") {
  unit.report({
    method: "saveAi",
    from: from,
    message: "Saves AI logs.",
  });

  if (this.state.ai.length > 0) {
    const docRef = doc(db, "logsProjectAi", this.id);

    getDoc(docRef)
      .then((docSnapshot) => {
        try {
          if (docSnapshot.exists()) {
            // Document exists, update it
            // return updateDoc(docRef, {
            //   aiRecords: arrayUnion(...this.state.ai),
            // });
          } else {
            // Document doesn't exist, create it
            // return setDoc(docRef, {
            //   aiRecords: this.state.ai,
            // });
          }
        } catch (e) {}
      })
      .then(() => {
        this.setState({ ai: [] });
      });
  }
}

export function teardownAi(from = "~78") {
  unit.report({
    method: "teardownAi",
    from: from,
    message: "Tears down AI logging interval.",
  });

  if (aiInterval) {
    clearInterval(this.aiInterval);
    this.aiInterval = null;
  }
}
