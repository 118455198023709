/** @format */
import { Alert, Box } from "@mui/material";
import axios from "axios";
import { useConstraints } from "constraints";
import Cookies from "cookies";
import { TinyError, useErrors } from "errors";
import { useLocales } from "locales";
import { Working } from "performing";
import { useEffect, useState } from "react";
import { useUser } from "user";

import { Functional } from "unit";

// All the different forms use the same logic
import Blog from "./components/Blog";
import Careers from "./components/Careers";
import Contact from "./components/Contact";
import EmailOnly from "./components/EmailOnly";
import Feature from "./components/Feature";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Partners from "./components/Partners";
import RightMenu from "./components/RightMenu";

const unit = Functional.unit("getresponse");

const subscribe = async (payload) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post("/api/getresponse", payload)
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(function (error) {
          console.error(error, "~36");
          throw error;
        });

      // Log the event
      unit.report({
        method: "cancel",
        event: "newsletterSubscribed",
      });
    } catch (error) {
      reject(error);
    }
  });
};

export default function Form({ from = "hero", tags = [], disabled = false }) {
  const { t } = useLocales();
  const { error } = useErrors();
  const { constraints, validate } = useConstraints();

  const [sent, setSent] = useState(false);
  const [failed, setFailed] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [working, setWorking] = useState(false);

  const user = useUser();

  useEffect(() => {
    if (user) {
      setEmail(user.email.get());
    }
  }, [user]);

  const send = async (tags = tags) => {
    const errors = validate({
      email: constraints.emailAddress,
    });

    if (errors) {
      error(errors.email[0]);
      return;
    }

    setWorking(true);

    try {
      await subscribe({
        name: (firstName + " " + lastName).trim() || "",
        email: email,
        message: message,
        tags: tags,
      })
        .then(() => {
          Cookies.set("GetResponse", Date.now());
          setSent(true);
        })
        .catch(() => {
          setFailed(true);
        });
    } catch (e) {
      error(e);
    }

    setWorking(false);
  };

  if (sent)
    return (
      <Box sx={{ margin: "20px 0 0 0" }}>
        <Alert severity="success">{t("getResponseSent")}</Alert>
      </Box>
    );
  if (failed)
    return (
      <Box sx={{ margin: "20px 0 0 0" }}>
        <Alert severity="error">{t("getResponseError")}</Alert>
      </Box>
    );

  const props = {
    setEmail,
    setFirstName,
    setLastName,
    setMessage,
    email,
    firstName,
    lastName,
    message,
    disabled,
    working,
    send,
  };

  return (
    <TinyError>
      <Box sx={{ marginTop: "20px" }}>
        {from == "hero" && <EmailOnly {...props} />}

        {from == "footer" && <Footer {...props} />}

        {from == "about" && <Footer {...props} />}

        {from == "careers" && <Careers {...props} />}

        {from == "contact" && <Contact {...props} />}

        {from == "features" && <Features {...props} />}

        {from == "partners" && <Partners {...props} />}

        {from == "blog" && <Blog {...props} />}

        {from == "right_menu" && <RightMenu {...props} />}

        {from == "feature" && <Feature {...props} />}

        {working && (
          <Box sx={{ marginTop: "30px" }}>
            <Working />
          </Box>
        )}
      </Box>
    </TinyError>
  );
}
