/** @format */
import { Box, Card, Divider, Stack, styled } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { TinyError } from "errors";
import { useLocales } from "locales";
import React from "react";
import { Avatar } from "user";

// Here's our templates
import AssistChecklist from "./components/AssistChecklist";
import AssistImage from "./components/AssistImage";
import AssistMessage from "./components/AssistMessage";
import AssistMultiple from "./components/AssistMultiple";
import AssistReject from "./components/AssistReject";
import AssistSingle from "./components/AssistSingle";

// Templates
const Templates = {
  "assist/message": AssistMessage,
  "assist/image": AssistImage,
  "assist/single": AssistSingle,
  "assist/multiple": AssistMultiple,
  "assist/checklist": AssistChecklist,
  "assist/reject": AssistReject,
};

const debug = false;

const StyledTemplate = styled(Box)(({ theme }) => ({
  "&": {
    width: "100%",
  },
  "& .message": {
    color: theme.palette.mode == "light" ? "rgba(18,18,18,.5)" : "rgba(250,250,250,.7)",
    whiteSpace: "pre-line",
    marginTop: 0,
  },
  "& .message.user": {
    textAlign: "left",
    fontSize: ".8em",
  },
  "& .message.assistant": {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: ".8em",
  },
  "& .blocks": {
    backgroundColor: theme.palette.mode == "light" ? "rgba(18,18,18,.012)" : "rgba(250,250,250,.012)",
    padding: "5px 20px",
    margin: "20px 0",
    fontSize: ".8em",
    h2: {
      fontSize: "1.2em",
    },
    hr: {
      margin: "10% 30%",
      opacity: 0.1,
    },
    img: {
      width: "20vw",
      marginLeft: "2.5vw",
    },
  },
}));

// Create a user text component
const UserText = ({ message }) => {
  return (
    <>
      <Stack direction="row" spacing={3}>
        <Box sx={{ minWidth: "40px" }}>
          <Avatar size={25} />
        </Box>
        <Box className="message user">{message}</Box>
      </Stack>{" "}
      <Box sx={{ margin: "5% 40%", opacity: 0.5 }}>
        <Divider />
      </Box>
    </>
  );
};

// Create an assistnat text component
const AssistantText = ({ message }) => {
  const { t } = useLocales();
  return (
    <Stack direction="row" spacing={3}>
      <Box sx={{ minWidth: "40px" }}>
        <DynamicIcon size={40} icon="fa-sparkles" title={t("ai")} />
      </Box>
      <Box className="message assistant">{t(message)}</Box>
    </Stack>
  );
};

export default function (message) {
  // Get the message template
  const MessageTemplate = Templates[message.template];

  // IF there's a problem with tasking, we'll skip it
  if (!MessageTemplate) return null;

  // Set some design ideas based on the user
  const isUser = message.role === "user";

  return (
    <TinyError>
      <Box sx={{ margin: "10px 0" }}>
        <Card
          sx={{
            padding: "10px",
            marginRight: "10px",
            backgroundColor: "transparent",
          }}
          elevation={0}
        >
          <Stack
            direction={isUser ? "column" : "row"}
            justifyContent={isUser ? "center" : "flex-start"}
            alignItems="flex-start"
            spacing={2}
          >
            <StyledTemplate>
              <MessageTemplate
                {...message}
                text={isUser ? <UserText {...message} /> : <AssistantText {...message} />}
              />
            </StyledTemplate>
          </Stack>
        </Card>
        <Box sx={{ margin: "20px 0", display: debug ? "block" : "none" }}>
          <Box>
            <small>Intent: {message.intent}</small>
          </Box>
          <Box>
            <small>Template: {message.template}</small>
          </Box>
          <Box>
            <small>Slugs: {JSON.stringify(message.slugs)}</small>
          </Box>
          <Box>
            <small>Attachments: {JSON.stringify(message.attachments)}</small>
          </Box>
          <Box>
            <small>Actions: {JSON.stringify(message.actions)}</small>
          </Box>
          <Box>
            <small>Meta: {JSON.stringify(message.meta)}</small>
          </Box>
        </Box>
      </Box>
      {!isUser && (
        <Box sx={{ margin: "10% 20%", opacity: 0.5 }}>
          <Divider />
        </Box>
      )}
    </TinyError>
  );
}
