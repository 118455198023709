/** @format */
import BlogGridBig from "@Web/Blocks/BlogGridBig";
import { Box, Typography } from "@mui/material";
import { useLocales } from "locales";

const FeaturedArticles = (props) => {
  const { t } = useLocales();
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={"h4"} gutterBottom>
            {t("featuredStories")}
          </Typography>
          <Typography color={"text.secondary"}>{t("featuredStories")}</Typography>
        </Box>
      </Box>

      <BlogGridBig {...props} />
    </Box>
  );
};

export default FeaturedArticles;
