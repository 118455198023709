/** @format */
import { CircularProgress, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

const ProgressWrapper = ({
  type = "linear",
  variant = "indeterminate",
  size = null,
  height = null,
  value = null,
  sx = {},
}) => {
  const [calculatedValue, setCalculatedValue] = useState(0);

  // Set different default sizes based on type
  const defaultSize = type === "circular" ? 40 : 3;

  // Merge size and height into a single effective size
  let effectiveSize = size || height || defaultSize;

  // Add 'px' suffix if it's a numeric value
  if (!isNaN(effectiveSize) && !String(effectiveSize).includes("px")) {
    effectiveSize = `${effectiveSize}px`;
  }

  // Update height in sx if the type is linear
  if (type === "linear") {
    sx = { ...sx, height: effectiveSize };
  }

  useEffect(() => {
    let timer;
    if (value === null) {
      const startTime = Date.now();
      const duration = 3600000; // 1 hour in milliseconds
      let percent;

      timer = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;

        // Logarithmic function for slower progression
        percent = (99 * Math.log(elapsedTime + 1)) / Math.log(duration + 1);

        setCalculatedValue(Math.min(percent, 99)); // Limit to 99%
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [value]);

  let normalizedValue = value;
  if (value === null) {
    normalizedValue = calculatedValue;
  }

  const linearStyles = {
    width: "100%",
    ...(variant === "indeterminate" && {
      animation: "colorBarRotation 2s linear infinite",
      "& > span:nth-of-type(2)": {
        "@keyframes colorBarRotation": {
          "20%": { backgroundColor: "#468189" },
          "40%": { backgroundColor: "#9DBEBB" },
          "60%": { backgroundColor: "#F4E9CD" },
          "80%": { backgroundColor: "#77ACA2" },
        },
      },
    }),
  };

  const circularStyles = {
    "& .MuiCircularProgress-circle": {
      animation: "colorCircleRotation 2s linear infinite",
      "@keyframes colorCircleRotation": {
        "0%": { color: "#031926" },
        "20%": { color: "#468189" },
        "40%": { color: "#9DBEBB" },
        "60%": { color: "#F4E9CD" },
        "80%": { color: "#77ACA2" },
      },
    },
  };

  return (
    <>
      {type === "circular" ? (
        <CircularProgress
          size={effectiveSize}
          variant={variant}
          value={normalizedValue}
          sx={{ ...sx, ...circularStyles }}
        />
      ) : (
        <LinearProgress variant={variant} value={normalizedValue} sx={{ ...sx, ...linearStyles }} />
      )}
    </>
  );
};

export default ProgressWrapper;
