/** @format */

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Functional } from "unit";
import { useUser } from "user";

import isSSR from "isssr";

const unit = Functional.unit("router/listener");

// This will listen to events from the router and dispatch them to MixPanel or another analytics service
export default function Listener() {
  const location = useLocation();
  const user = useUser();

  let name;

  // Going to try and get the page name
  try {
    if (!isSSR) return;

    // Get the meta element
    const metaElement = document.querySelector('meta[name="name"]');

    // Get the content attribute
    if (metaElement) {
      name = metaElement.getAttribute("content");
    }
  } catch (e) {}

  // Report page view when location changes
  useEffect(() => {
    // Dispatch events to unit
    unit.report({
      method: "Listener",
      message: "Page view has been triggered.",
      test: "Changing route should log an event to MixPanel and Analytics/Tags.",
      event: "pageView",
      payload: { location: location.pathname, name: name },
    });
  }, [location]);

  // Setup the user
  useEffect(() => {
    // Dispatch events to unit
    unit.report({
      method: "Listener",
      message: "Set the user.",
      test: "Should set the user when the user changes.",
      event: "setUser",
      payload: { userId: user.uid, email: user.email.get(), displayName: user.name.get.display() },
    });
  }, [user.uid]);

  return null;
}
