/** @format */
import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { withCms } from "cms";

import { About, CompanyValues, Hero, Jobs } from "./components";

const CareerListing = (props) => {
  const theme = useTheme();
  return (
    <Main colorInvert={false}>
      <Box style={{ marginTop: "100px" }}>
        <Hero />
        <Container>
          <CompanyValues {...props} />
        </Container>
        <Container>
          <Divider />
        </Container>
        <Container sx={{ marginTop: 0, paddingTop: 0 }}>
          <About {...props} />
        </Container>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path fill={theme.palette.info.main} d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
        </Box>
      </Box>
      <Box bgcolor={"alternate.main"}>
        <Container maxWidth={1000}>
          <Jobs {...props} />
        </Container>
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path fill={theme.palette.background.paper} d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
        </Box>
      </Box>
    </Main>
  );
};

export default withCms(CareerListing);
