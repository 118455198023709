/** @format */

export function setupButtons(config = {}) {
  return new Promise((resolve, reject) => {
    try {
      if (typeof config !== "function") return reject("Misconfiguration: 'config' must be a function.");

      // Should be good to update the state
      this.setState(
        (prevState) => ({
          ...prevState,
          config: {
            ...prevState.config,
            buttons: config,
          },
        }),
        () => {
          // set that the buttons are ready
          this.ready.set("buttons");

          // Tell the world everything is good
          return resolve(true);
        }
      );
    } catch (e) {
      return reject(e);
    }
  });
}

export function setupFeature(feature, config = {}) {
  return new Promise((resolve, reject) => {
    try {
      // What we passed in was bad news bears
      if (!feature || typeof config !== "object")
        return reject('Misconfiguration: "feature" and "config" are required.');

      // Setup the feature
      this.setState(
        (prevState) => ({
          ...prevState,
          config: {
            ...prevState.config,
            [feature]: config,
          },
        }),
        () => {
          // Set that buttons are ready
          this.ready.set("features");

          // Set that everything is ready
          this.ready.set("all");

          return resolve(true);
        }
      );
    } catch (e) {
      return reject(e);
    }
  });
}
