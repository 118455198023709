/** @format */
import { exception } from "sentry";

export function Error(message = null, error = null) {
  const { snackbar } = this.props;
  try {
    if (error !== null) console.error(error);
    if (!message) return;
    if (message === true) message = this.unexpectedError;
    snackbar.open(message, "error");
  } catch (error) {
    console.error(error);
  }
  try {
    exception(error);
  } catch (_) {}
}
