/** @format */

export default function getProfileCompletion(fields) {
  if (!fields) {
    return null;
  }

  fields = [fields.photoURL, fields.firstName, fields.lastName, fields.email, fields.email && fields.emailVerified];

  if (!fields) {
    return null;
  }

  let profileCompletion = 0;

  fields.forEach((field) => {
    if (field) {
      profileCompletion += 100 / fields.length;
    }
  });

  return Math.floor(profileCompletion);
}
