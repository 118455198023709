/** @format */

function getFilter(props = null) {
  if (props === null) {
    const response = Object.entries(this.state.filters).reduce(
      (res, [key, value]) =>
        value !== null &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === "object" && Object.keys(value).length === 0)
          ? Object.assign(res, { [key]: value })
          : res,
      {}
    );

    return Object.keys(response).length > 0 ? response : false;
  }

  if (typeof props == "string") props = { tags: [props], collections: [props] };

  const { name = null, type = null, collections = [], tags = [], attachedTo = [], userId = null, meta = {} } = props;
  const currentFilter = { ...this.state.filters };

  // Check name
  if (name && currentFilter.name === name) return true;

  // Check type
  if (type && currentFilter.type === type) return true;

  // Check collections
  if (collections.length && collections.some((coll) => currentFilter.collections.includes(coll))) return true;

  // Check tags
  if (tags.length && tags.some((tag) => currentFilter.tags.includes(tag))) return true;

  // Attached tags
  if (attachedTo.length && attachedTo.some((attached) => currentFilter.attachedTo.includes(attached))) return true;

  // Check userId
  if (userId && currentFilter.userId === userId) return true;

  // Check meta
  for (let key in meta) {
    if (currentFilter.meta.hasOwnProperty(key) && currentFilter.meta[key] === meta[key]) return true;
  }

  // If none of the checks returned true, then none of the filter's fields match the current state's filter
  return false;
}

function setFilter(props = {}) {
  let { name = null, collections = [], tags = [], attachedTo = [], userId = null, search = null, meta = {} } = props;

  // Convert single strings into arrays for collections, tags and attachedTo
  if (typeof collections === "string") collections = [collections];
  if (typeof tags === "string") tags = [tags];
  if (typeof attachedTo === "string") attachedTo = [attachedTo];

  this.setState((prevState) => {
    // Overwrite name, userId and search if they are different
    let newState = {
      ...prevState,
      filters: {
        ...prevState.filters,
        ...(name !== null && name !== prevState.filters.name && { name }),
        ...(userId !== null && userId !== prevState.filters.userId && { userId }),
        ...(search !== null &&
          search !== prevState.filters.search && {
            search: search !== "" ? search : null,
          }),
      },
    };

    // Function to toggle arrays
    const toggleArray = (oldArray, newArray) => {
      newArray.forEach((item) => {
        const index = oldArray.indexOf(item);
        if (index !== -1) {
          oldArray.splice(index, 1);
        } else {
          oldArray.push(item);
        }
      });
      return oldArray;
    };

    // Function to toggle meta
    const toggleMeta = (oldMeta, newMeta) => {
      for (let key in newMeta) {
        if (oldMeta[key] === newMeta[key]) {
          delete oldMeta[key];
        } else {
          oldMeta[key] = newMeta[key];
        }
      }
      return oldMeta;
    };

    // Handle collections, tags, attachedTo and meta
    newState.filters.collections = toggleArray(newState.filters.collections, collections);
    newState.filters.tags = toggleArray(newState.filters.tags, tags);
    newState.filters.attachedTo = toggleArray(newState.filters.attachedTo, attachedTo);
    newState.filters.meta = toggleMeta(newState.filters.meta, meta);

    return newState;
  });
}

function clearFilter(clearFields = {}) {
  // Create default clear object (if a field is set to true, it will be cleared)
  const defaultClear = {
    name: false,
    collections: false,
    tags: false,
    attachedTo: false,
    userId: false,
    search: false,
    meta: false,
  };

  // Combine default with provided fields to clear
  const fieldsToClear = { ...defaultClear, ...clearFields };

  this.setState((prevState) => {
    // Create a new state with each filter selectively cleared
    let newState = { ...prevState };
    for (let field in fieldsToClear) {
      if (fieldsToClear[field]) {
        // Clear filter field
        if (Array.isArray(prevState.filters[field])) {
          // If it's an array, set to empty array
          newState.filters[field] = [];
        } else if (typeof prevState.filters[field] === "object") {
          // If it's an object, set to empty object
          newState.filters[field] = {};
        } else {
          // Else set to null
          newState.filters[field] = null;
        }
      }
    }
    return newState;
  });
}

export { clearFilter, getFilter, setFilter };
