/** @format */
import { Box, Card, Hidden } from "@mui/material";
import Gallery from "@Web/Blocks/Gallery";

export default function MobileGallery() {
  return (
    <Hidden mdUp>
      <Box sx={{ margin: "10% 0" }}>
        <Card
          sx={{
            margin: "10% 5%",
          }}
        >
          <Gallery number={1} height={400} />
        </Card>
      </Box>
    </Hidden>
  );
}
