/** @format */
import { Component as ReactComponent, Suspense, lazy } from "react";
import { withUnit } from "unit";

const AlertComponent = lazy(() => import("./components/Alert"));
const ConfirmComponent = lazy(() => import("./components/Confirm"));

class Dialogs extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new("UI");
  }
  componentDidMount() {
    this.props.ui.add.dialogs(["alert", "confirm"], "~30");
  }

  render() {
    return (
      <>
        <Suspense fallback={null}>
          <AlertComponent {...this.props} />
        </Suspense>
        <Suspense fallback={null}>
          <ConfirmComponent {...this.props} />
        </Suspense>
      </>
    );
  }
}

export default withUnit(Dialogs);
