/**
 * This component is responsible for checking if the app is in maintenance mode or not.
 * If the app is in maintenance mode, it displays a loading screen with a progress bar.
 * If the app is not in maintenance mode, it renders its children.
 *
 * @format
 * @param {Object} props - React props object
 * @param {ReactNode} props.children - The children to be rendered if the app is not in maintenance mode
 * @returns {ReactNode} - The loading screen with progress bar if the app is in maintenance mode, otherwise the children
 */

/** @format */
import React, { useEffect, useState } from "react";

import { doc, onSnapshot } from "firebase/firestore";

import Cookies from "cookies";
import { db } from "firemade";
import { useLocales } from "locales";
import { LoadingScreen } from "performing";
import { useSnackbar } from "snackbar";

import config from "./config/index.json";

import isSSR from "isssr";

const Maintenance = ({ children, force }) => {
  const [isMaintenance, setIsMaintenance] = useState(force ?? false);
  const [progressPercent, setProgressPercent] = useState(0);
  const { open } = useSnackbar();
  const { t } = useLocales();

  useEffect(() => {
    let timer;

    if (isMaintenance) {
      const startTime = Date.now();
      const duration = 3600000; // 1 hour in milliseconds
      let percent;

      timer = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;

        // Logarithmic function for slower progression
        percent = (99 * Math.log(elapsedTime + 1)) / Math.log(duration + 1);

        setProgressPercent(Math.min(percent, 99)); // Limit to 99%
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isMaintenance]);

  useEffect(() => {
    let unsubscribe;

    unsubscribe = onSnapshot(doc(db, "maintenance", config.docId), (doc) => {
      const data = doc.data();
      const maintenance = data ? data.on : false;

      if (isMaintenance && !maintenance) {
        Cookies.set("maintenance", true);
        if (!isSSR) document.location.reload();
      } else if (!isMaintenance && maintenance) {
        setIsMaintenance(true);
      }
    });

    if (Cookies.get("maintenance")) {
      Cookies.remove("maintenance");
      open(t("maintenanceReload"), "success");
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [isMaintenance]);

  return isMaintenance ? (
    <span data-testid="maintenance-mode">
      <LoadingScreen
        location="maintenance"
        open={true}
        progress={{
          percent: progressPercent,
        }}
        title={t("maintenanceMode")}
      />
    </span>
  ) : (
    children
  );
};

export default Maintenance;
