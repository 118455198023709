/** @format */
import Video from "@Web/Blocks/Video";
import { Box } from "@mui/material";
import { useLocales } from "locales";

export default function Hero() {
  const { t } = useLocales();
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
        mt: { xs: 10, sm: 10, md: 0 },
      }}
    >
      <Box sx={{ minHeight: "100%" }}>
        <Video
          number={1}
          thumb="https://us-central.cdn.made.live/Made_Live_You_Tube_Thumbnail_1_9a254ade8e/Made_Live_You_Tube_Thumbnail_1_9a254ade8e.webp"
          buttonText={t("watchVideo")}
          button={{
            color: "success",
            variant: "contained",
            sx: { fontSize: "1em", marginTop: "40vw" },
          }}
        />
      </Box>
    </Box>
  );
}
