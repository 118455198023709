/** @format */
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
// import { styled } from "@mui/material/styles";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";

// Get the styles
import styles from "@Workspace/Features/Generate/config/styles.json";

export default function Styles({ setValue, next, values }) {
  const { t } = useLocales();

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (values?.style != selected) {
      setSelected(slug);
    }
  }, [values?.theme]);

  const onSelect = (style) => {
    setSelected(style);
    setValue("style", style);
    next();
  };

  return (
    <Box sx={{ padding: "0px 10px" }}>
      <h3 style={{ textAlign: "center" }}>
        {t("pickYourStyleHead")}
        <Box sx={{ padding: "5px", fontSize: "12px", textAlign: "center" }}>{t("pickYourStyleSub")}</Box>
      </h3>
      <Grid container spacing={2}>
        {Object.entries(styles).map(([key, value]) => (
          <Grid item key={key} xs={4} sx={{ opacity: ".7", ":hover": { opacity: 1 } }} onClick={() => onSelect(key)}>
            <Card sx={{ width: "100%", opacity: 1, ":hover": { opacity: 1 } }}>
              <CardActionArea>
                <CardMedia component="img" height="240" image={value.image} alt={key} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: "bold" }}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ minHeight: "140px" }}>
                    {value.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
