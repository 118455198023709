/** @format */
import { useWorkspace } from "@Workspace/Context";
import Drag from "@Workspace/Usable/Features/Elements/components/Drag";
import { Box, Card, CardMedia, Grid, Skeleton, useTheme } from "@mui/material";
import key from "key";
import React from "react";

export default function ({ meta, attachments, text }) {
  // Get the workspace
  const workspace = useWorkspace();

  // Get the them
  const theme = useTheme();

  // Get the feature to enable drag/drop
  const feature = workspace.feature.selected();

  // width adjusted for screen size
  const widthAdjusted = window.innerWidth * 0.25;

  // Base dimensions
  const baseWidth = widthAdjusted;
  const baseHeight = widthAdjusted;

  // Default dimensions
  let width = 1024;
  let height = 1024;

  // Calculate scale factor based on meta dimensions
  let scaleFactor = 1; // Default scale factor

  if (meta.size) {
    const [metaWidth, metaHeight] = meta.size.split("x").map(Number);
    width = metaWidth;
    height = metaHeight;

    // Calculate scale factor (preserve aspect ratio)
    const widthScale = baseWidth / width;
    const heightScale = baseHeight / height;

    // scale the factor
    scaleFactor = Math.min(widthScale, heightScale); // Use the smaller scale to preserve aspect ratio
  }

  // Scale the images to fit
  const scaledWidth = width * scaleFactor;
  const scaledHeight = height * scaleFactor;

  // Setup dnd for images on storyboard only
  let dnd = {};

  if (feature == "storyboard") {
    dnd = {
      onDrop: workspace.drag.drop,
      onDragStart: workspace.drag.start,
      onDragEnd: workspace.drag.end,
    };
  }

  return (
    <Box>
      {text}
      <Box sx={{ margin: "20px" }}>
        {meta.working ? (
          <Skeleton
            variant="rounded"
            width={scaledWidth}
            height={scaledHeight}
            sx={{ minHeight: `${scaledHeight}px`, backgroundColor: theme.palette.info.light }}
          />
        ) : (
          <Grid container spacing={2}>
            {attachments.map((attachment, i) => (
              <Grid {...key("assist2", "template", "image", i)} item xs={12 / attachments.length}>
                <Card elevation={5} sx={{ width: scaledWidth / attachments.length }}>
                  {feature == "storyboard" ? (
                    <Drag {...dnd} element={attachment} {...key("workspace", "assist", "image", "dnd", attachment.id)}>
                      <CardMedia sx={{ height: scaledHeight / attachments.length }} image={attachment.url} />
                    </Drag>
                  ) : (
                    <CardMedia sx={{ height: scaledHeight / attachments.length }} image={attachment.url} />
                  )}
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
