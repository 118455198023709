/** @format */
import { Box, Card, CardContent, CardMedia, Grid, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Cdn from "cdn";
import { useCms } from "cms";
import { useMemo } from "react";
import LinesEllipsis from "react-lines-ellipsis";

import { Link } from "react-router-dom";

import { TinyError } from "errors";

import Author from "../Author";
import BlogDate from "../BlogDate";

let placeholder = Cdn(
  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_out_of_head_into_hands_f41a4d9269/thumbnail_out_of_head_into_hands_f41a4d9269.png?width=1536&height=1536"
);

export default function BlogListMedium({ start = 1, end = 4, width, orderBy = "createdAt.desc" }) {
  const theme = useTheme();
  const {
    pages,
    navigate,
    url,
    ready: { site },
  } = useCms();

  // Adjust start/end to 0-based index and extract orderField and direction
  const [orderField, ascDesc] = orderBy.split(".");

  // Memoize the filtered and sorted articles to avoid recalculating on every render
  const articles = useMemo(() => {
    return pages
      .filter((page) => page.section.toLowerCase() === "blog")
      .sort((a, b) =>
        ascDesc === "asc"
          ? new Date(a[orderField]) - new Date(b[orderField])
          : new Date(b[orderField]) - new Date(a[orderField])
      )
      .slice(start - 1, end - 1);
  }, [pages, start, end, orderField, ascDesc]);

  // Early return if there are no articles
  if (articles.length === 0) return null;

  return (
    <Grid container spacing={4} data-block="blog-list-small">
      {articles.map((item, i) => (
        <Grid key={i} item xs={12}>
          <TinyError>
            {!site ? (
              <Skeleton sx={{ minHeight: "100px" }} />
            ) : (
              <Link
                to={url(item.slug)}
                onClick={() => navigate(item.slug)}
                style={{ textDecoration: "none", color: "#12545c" }}
                aria-label={item.title}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  boxShadow={0}
                  display={"flex"}
                  flexDirection={{ xs: "column", md: "row" }}
                  sx={{ backgroundImage: "none", bgcolor: "transparent", cursor: "pointer" }}
                >
                  <Card>
                    <CardMedia
                      component="img"
                      height="100"
                      src={Cdn(item.meta.image, "small") || placeholder}
                      loading="lazy"
                      alt="Paella dish"
                    />
                    <CardContent sx={{ padding: 1, "&:last-child": { paddingBottom: 1 } }}>
                      <LinesEllipsis
                        component="span"
                        text={item.excerpt}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                      <Box marginY={1 / 4}>
                        <Typography variant={"caption"} color={"text.secondary"}>
                          <Author {...item} /> - <BlogDate {...item} />
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Link>
            )}
          </TinyError>
        </Grid>
      ))}
    </Grid>
  );
}
