/** @format */
import { withWorkspace } from "@Workspace/Context";
import { Box } from "@mui/material";
import { PageError } from "errors";
import { Functional, withUnit } from "unit";

const unit = Functional.unit("Desk");

function Feature(props) {
  const { workspace } = props;
  const { desk, feature } = workspace.settings;
  const { desks } = workspace.ui;

  let Component = desks[desk].component;

  if (!Component) return <></>;

  return (
    <PageError>
      <Box onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <Component {...props} feature={feature} desk={desk} data={workspace.data.feature(feature)} />
      </Box>
    </PageError>
  );
}

export default withWorkspace(withUnit(Feature));
