/** @format */
import { useCms } from "cms";
import { useLocales } from "locales";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useRouter } from "router";

// Import the different versions of the onboarding
import V5S from "./components/Version5";
import V5C from "./components/Version5/components/Complete";

// Setup defaults
const defaultVersion = "5";
const defaultOpt = "opt-in";
const defaultTrials = ["7", "14", "28", "90"];

const Onboarding = (props) => {
  const { pages, meta } = useCms();
  const { url } = useLocales();
  const { params } = useRouter();

  const { version, opt, trial } = params;

  useEffect(() => {
    meta.set({ title: null });
  }, [pages]);

  // Helper function to validate and set default parameters
  const getValidatedParams = () => {
    const validVersion = version || defaultVersion;
    const validOpt = opt || defaultOpt;
    const validTrial = defaultTrials.includes(trial) ? trial : defaultTrials[0];

    return { validVersion, validOpt, validTrial };
  };

  const { validVersion, validOpt, validTrial } = getValidatedParams();

  // Redirect if any parameter is missing
  if (!version || !opt || !trial) {
    return <Navigate to={url(`pages/onboarding/${validVersion}/${validOpt}/${validTrial}`)} replace />;
  }

  // Combine the props
  const checkoutProps = { ...props, ...params, trial: validTrial };

  // Check which version we're on
  if (validVersion === "5") {
    return validOpt !== "complete" ? <V5S {...checkoutProps} /> : <V5C {...checkoutProps} />;
  }

  return <Navigate to="/" replace />;
};

export default Onboarding;
