/** @format */
import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { Divider } from "@mui/material";
import { withCms } from "cms";
import { withLocales } from "locales";
import { Application, Contact, Gallery, Hero, Story1, Story2, Team } from "./components";

const About = (props) => {
  return (
    <Main colorInvert={true}>
      <Hero {...props} />
      <Container>
        <Story1 {...props} />
      </Container>
      <Container paddingTop={"0 !important"}>
        <Story2 {...props} />
      </Container>
      <Container maxWidth={800} paddingY={"0 !important"}>
        <Divider />
      </Container>
      <Container>
        <Team {...props} />
      </Container>
      <Container>
        <Contact {...props} />
      </Container>
      <Container>
        <Gallery {...props} />
      </Container>
      <Container maxWidth={800} paddingTop={"0 !important"}>
        <Application {...props} />
      </Container>
    </Main>
  );
};

export default withLocales(withCms(About));
