/** @format */

import { Box, Button, Stack } from "@mui/material";
import { MD5 } from "crypto-js";
import DynamicIcon from "dynamicicon";
import { TinyError } from "errors";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "firemade";
import { useLocales } from "locales";
import { useEffect, useState } from "react";
import { useUI } from "ui";
import { useUser } from "user";

const Likes = ({
  callback = () => {},
  down = { visible: true, text: null, icon: true, sx: { minWidth: "24px" } },
  up = { visible: true, text: "likesUp", icon: true, sx: { minWidth: "24px" } },
  color = "secondary",
  variant = "text",
  size = "small",
  slug,
  enabled = true,
}) => {
  // Get the locales
  const { t } = useLocales();

  // Get the ui
  const ui = useUI();

  // Get the user id
  const { id } = useUser();

  // The state to manage is this likes are on or off
  const [liked, setLiked] = useState(false);

  // Ready
  const [ready, setReady] = useState(false);

  // This is the doc id we're looking for
  const likeDocId = id && slug ? MD5(id + slug).toString() : null;

  // Load the docs
  useEffect(() => {
    const fetchLikeStatus = async () => {
      if (!likeDocId || !enabled) return;

      // Check if exists
      const docRef = doc(db, "likes", likeDocId);

      // Try it up
      try {
        const docSnap = await getDoc(docRef);

        // Make sure we have a doc for this one
        if (docSnap.exists()) {
          setLiked(docSnap.data().liked);

          // If not, not liked, just null
        } else {
          setLiked(null); // Default to not liked if the document doesn't exist
        }

        // Set that this component is ready
        setReady(true);
      } catch (error) {}
    };

    fetchLikeStatus();
  }, [likeDocId, enabled]);

  // Set that we're liked
  const setLike = async (like) => {
    // If we're not logged in, lets log int
    if (!id) {
      ui.confirm.open({
        title: t("likesLoginTitle"),
        text: t("likesLoginBody"),
        dismiss: {
          onClick: () => {
            ui.confirm.close();
          },
          text: t("cancel"),
        },
        confirm: {
          onClick: () => {
            ui.dialog.open("signin");
          },
          text: t("continue"),
        },
      });
      // return it
      return;
    }

    // Capture problems
    try {
      // Create a reference for a new like document
      const likeRef = doc(db, "likes", likeDocId);

      // Set the document
      await setDoc(
        likeRef,
        {
          liked: like,
          slug: slug,
          userId: id,
        },
        { merge: true }
      );
    } catch (e) {}

    // Set that we're good
    setLiked(like);

    // Make sure we have a callback
    try {
      callback(like);
    } catch (e) {}

    // setLike function
  };

  return (
    <TinyError>
      {enabled && ready && (
        <Stack direction="row" spacing={1}>
          {down.visible && (
            <Button
              color={color}
              size={size}
              onClick={() => setLike(liked === false ? null : false)}
              variant={liked === false ? "contained" : variant}
              sx={down?.sx || { minWidth: "24px" }}
            >
              {down.icon && <DynamicIcon icon="fa-thumbs-down" size="sm" />}
              {down.text && <Box sx={{ marginLeft: down.icon ? "10px" : 0 }}>{t("likesDown")}</Box>}
            </Button>
          )}
          {up.visible && (
            <Button
              color={color}
              size={size}
              onClick={() => setLike(liked === true ? null : true)}
              variant={liked === true ? "contained" : variant}
              sx={up?.sx || { minWidth: "24px" }}
            >
              {up.icon && <DynamicIcon icon="fa-thumbs-up" size="sm" />}
              {up.text && <Box sx={{ marginLeft: up.icon ? "10px" : 0 }}>{t("likesUp")}</Box>}
            </Button>
          )}
        </Stack>
      )}
    </TinyError>
  );
};

export default Likes;
export { Likes };
