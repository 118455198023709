/** @format */
import { Box } from "@mui/material";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";
import Loader from "../Loader";

import { exception } from "sentry";

class DialogComponent extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error) {
    this.setState({ error: true });
    try {
      exception(error);
    } catch (_) {}
  }

  componentDidMount() {
    const { error } = this.props;
    if (error) {
      this.setState({ error: true });
    }
  }

  render() {
    const { children, t, show, message, label, error, log, from } = this.props;

    if (log && (message || error)) console.error(this.props.error || message, from || "~38");

    if (this.state.error || show) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{
            width: "80%",
            minHeight: "100px",
            padding: "5% 0 20%",
            margin: { xs: "20px", sm: "20px" },
            flexGrow: 1,
            alignItems: "center",
          }}
        >
          <Loader width={200} from="~57" />
          <Box sx={{ fontSize: "12px", textAlign: "center" }}>
            {message || label ? message || label : t("unexpectedError")}
          </Box>
        </Box>
      );
    }

    return children;
  }
}

export default withLocales(DialogComponent);
