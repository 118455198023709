/**
 * Encodes the given payload using base64 encoding.
 * If the payload is an array, it is sorted and then encoded.
 * If the payload is an object, its keys are sorted, the object is reduced to a new object with sorted keys, and then encoded.
 *
 * @format
 * @param {Array|Object} payload - The payload to be encoded.
 * @param {string} [from="~8"] - The source of the payload.
 * @returns {string} The encoded payload.
 */

/** @format */

export function Sign(payload) {
  try {
    if (Array.isArray(payload)) payload = JSON.stringify(payload.sort());
    if (typeof payload == Object)
      payload = JSON.stringify(
        payload
          .keys(payload)
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = payload[key];

            return accumulator;
          }, {})
      );
    return base64.encode(payload);
  } catch (error) {
    return base64.encode({ error: error });
  }
}

/**
 * Decodes the given payload using base64 decoding.
 * @param {string} payload - The payload to be decoded.
 * @param {string} [from="~37"] - The source of the payload.
 * @returns {string} The decoded payload.
 */
export function Unsign(payload) {
  try {
    return base64.decode(payload);
  } catch (error) {
    return base64.encode({ error: error });
  }
}
