/** @format */
import { Box, IconButton, Stack, TextField } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { useState } from "react";
import { Avatar } from "user";

export default function ({ onReply, id, setReply }) {
  // Set the reply message
  const [replyMessage, setReplyMessage] = useState("");

  // Fire up the response
  const handleReplyMessage = () => {
    // Set that we're replying
    onReply(id, replyMessage);

    // Clear the message
    setReplyMessage("");

    // Close the reply box
    setReply(false);
  };

  return (
    <Stack direction="row" sx={{ margin: "5% 0" }} spacing={3}>
      <Box sx={{ minWidth: "40px" }}>
        <Avatar size={30} />
      </Box>
      <TextField
        fullWidth
        multiline
        variant="outlined"
        value={replyMessage}
        onChange={(e) => setReplyMessage(e.target.value)}
        rows={5}
        InputProps={{
          endAdornment: (
            <IconButton type="submit" color="success" onClick={handleReplyMessage} disabled={replyMessage.length < 20}>
              <DynamicIcon icon={"fa-paper-plane"} />
            </IconButton>
          ),
        }}
      />
    </Stack>
  );
}
