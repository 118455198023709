/** @format */
import { Box, Grow, IconButton, Tooltip, useTheme } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { ComponentError, TinyError } from "errors";
import { useLocales } from "locales";
import { Icon } from "logo";
import ReactDOM from "react-dom";
import { useUser } from "user";

// Bubbles and assist
import Assist from "./components/Assist";
import Tips from "./components/Tips";

const Portal = ({ children, containerId }) => {
  const container = document.getElementById(containerId);
  return container ? ReactDOM.createPortal(children, container) : null;
};

export default function (props) {
  const { t } = useLocales();
  const theme = useTheme();
  const { workspace } = props;
  const { meta } = useUser();
  const assistOpen = workspace.assist.opened();

  return (
    <>
      <Grow in={assistOpen}>
        <Box
          sx={{
            position: "fixed",
            top: "80px",
            right: "auto",
            left: "60px",
            zIndex: 1260,
            maxWidth: "33vw",
            height: "calc(100vh - 90px)",
            backgroundColor: "transparent",
            overflow: "hidden",
            border: "1px solid",
            borderColor: theme.palette.mode == "light" ? "rgba(12,12,12,.12)" : "rgba(250,250,250,.12)",
            borderRadius: "3px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          }}
          data-component="assist-component"
        >
          <ComponentError>
            <Assist {...props} />
          </ComponentError>
        </Box>
      </Grow>

      <TinyError>
        <Tips {...props} />
      </TinyError>

      <Portal containerId="assist-button-portal">
        <Box sx={{ position: "relative" }}>
          <Tooltip title={t("muteNotifications")} placement="left">
            <Box
              sx={{
                color: meta.get("assistMute") ? theme.palette.error.main : theme.palette.info.main,
                left: "30px",
                fontSize: "12px",
                width: "20px",
                height: "20px",
                padding: 0,
                margin: 0,
                cursor: "pointer",
                position: "absolute",
                zIndex: 10,
              }}
              onClick={(e) => {
                e.stopPropagation();
                meta.toggle("assistMute");
              }}
            >
              <DynamicIcon icon="fa-bell" />
            </Box>
          </Tooltip>
          <Tooltip title={t("Assist")} placement="left">
            <IconButton
              variant="tab"
              onClick={workspace.assist.toggle}
              sx={{ width: "40px", height: "40px", padding: 0, margin: 0, marginTop: "5px" }}
            >
              <Box sx={{ width: "20px" }}>
                <Icon />
              </Box>
            </IconButton>
          </Tooltip>
        </Box>
      </Portal>
    </>
  );
}
