/** @format */
import { Box } from "@mui/material";
import BottomNewsletter from "@Web/Blocks/BottomNewsletter";

const FooterNewsletter = (props) => {
  return (
    <Box>
      <BottomNewsletter {...props} />
    </Box>
  );
};

export default FooterNewsletter;
