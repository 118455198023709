/** @format */
import { Box, Card, CardContent, CardMedia, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Cdn from "cdn";
import { useCms } from "cms";
import React, { useMemo } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";

import { TinyError } from "errors";
import Author from "../Author";
import AvatarComponent from "../Avatar";
import BlogDate from "../BlogDate";

let placeholder = Cdn(
  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_out_of_head_into_hands_f41a4d9269/thumbnail_out_of_head_into_hands_f41a4d9269.png?width=1536&height=1536"
);

const StyledDescription = styled(Box)(() => ({
  "&.text": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    lineClamp: 3,
    webkitLineClamp: 3,
    webkitBoxOrient: "vertical",
    boxOrient: "vertical",
  },
}));

export default function BlogGridBig({ start = 1, end = 4, width = 4, orderBy = "createdAt.desc" }) {
  const theme = useTheme();
  const {
    pages,
    navigate,
    url,
    ready: { site },
  } = useCms();

  // Adjust start/end to 0-based index and extract orderField and direction
  const [orderField, ascDesc] = orderBy.split(".");

  // Memoize the filtered and sorted articles to avoid recalculating on every render
  const articles = useMemo(() => {
    return pages
      .filter((page) => page.section.toLowerCase() === "blog")
      .sort((a, b) =>
        ascDesc === "asc"
          ? new Date(a[orderField]) - new Date(b[orderField])
          : new Date(b[orderField]) - new Date(a[orderField])
      )
      .slice(start - 1, end - 1);
  }, [pages, start, end, orderField, ascDesc]);

  // Early return if there are no articles
  if (articles.length === 0) return null;

  return (
    <Grid container spacing={4} data-block="blog-grid-big">
      {articles.map((item, i) => (
        <Grid item xs={12} md={width} key={i}>
          <Link
            to={url(item.slug)}
            onClick={() => navigate(item.slug)}
            style={{ textDecoration: "none", color: "#12545c" }}
            aria-label={item.title}
          >
            <TinyError>
              <Box
                flexGrow={1}
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  cursor: "pointer",
                  "&:hover": {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                {!site ? (
                  <Skeleton sx={{ minHeight: "400px" }} />
                ) : (
                  <Box
                    component={Card}
                    width={1}
                    height={1}
                    boxShadow={0}
                    sx={{ bgcolor: "transparent", backgroundImage: "none", height: "100%" }} // Add height: "100%"
                  >
                    <CardMedia
                      image={Cdn(item.meta.image, "small") || placeholder}
                      loading="lazy"
                      title={item.title}
                      sx={{
                        height: { xs: 300, md: 360 },
                        position: "relative",
                        borderRadius: "3px",
                        filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                      }}
                    />
                    {/* This is one that won't flex height */}
                    <Box
                      width={"90%"}
                      margin={"0 auto"}
                      display={"flex"}
                      flexDirection={"column"}
                      flexGrow={1} // Add flexGrow property
                      boxShadow={1}
                      bgcolor={"background.paper"}
                      position={"relative"}
                      zIndex={3}
                      sx={{ transform: "translateY(-30px)", borderRadius: "3px", height: "50%" }} // Add height: "100%"
                    >
                      <Box component={CardContent} position={"relative"}>
                        <Typography variant={"h6"} gutterBottom>
                          {item.title}
                        </Typography>
                        <StyledDescription component="div" color="text.secondary" className="text">
                          <LinesEllipsis text={item.excerpt} maxLine="4" ellipsis="..." trimRight basedOn="letters" />
                        </StyledDescription>
                      </Box>
                      <Box flexGrow={1} />
                      <Box padding={2} display={"flex"} flexDirection={"column"}>
                        <Box marginBottom={2}>
                          <Divider />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                          <Box display={"flex"} alignItems={"center"}>
                            <AvatarComponent {...item} />
                            <Typography color={"text.secondary"} style={{ marginLeft: "10px" }}>
                              <Author {...item} />
                            </Typography>
                          </Box>
                          <Typography color={"text.secondary"}>
                            <BlogDate {...item} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </TinyError>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}
