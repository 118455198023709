/** @format */
import ReviewsList from "@Web/Blocks/ReviewsList";
import Container from "@Web/Container";
import { useLocales } from "locales";

const Review = (props) => {
  const { t } = useLocales();
  return (
    <Container>
      <h2 align="center">{t("whatUsersSaying")}</h2>
      <ReviewsList {...props} number={1} />
    </Container>
  );
};

export default Review;
