/** @format */
import { useBilling } from "@Workspace/Billing";
import { Alert, Box, Button, Divider, IconButton, TextField, alpha, useTheme } from "@mui/material";
import axios from "axios";
import { useCms } from "cms";
import DynamicIcon from "dynamicicon";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "firemade";
import key from "key";
import { useLocales } from "locales";
import { Working } from "performing";
import React, { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Tip, useTour } from "tour";
import { useUI } from "ui";
import { useUser } from "user";

// The main template we want
import Template from "./components/Template";

// Export the Template function
export default function ({ workspace, performing }) {
  // All the states we need
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [sensor, setSensor] = useState(false);
  const [working, setWorking] = useState(false);

  const { t } = useLocales();
  const { ready } = useCms();
  const billing = useBilling();
  const ui = useUI();
  const tour = useTour();

  const theme = useTheme();

  // get the package name
  const packageName = billing.package.name();

  // Get the style from the generate feature
  const generate = workspace.data.feature("generate");

  // Get the style we want to use
  const style = generate?.style || "sketch";

  // Get the selected feature
  const feature = workspace.feature.selected();

  // Paging messages
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = -10; // Adjust this number as needed

  // Whether or not this is open (to move messages to the bottom)
  const opened = workspace.assist.opened();

  // get the user id
  const user = useUser();

  // Scroll to the bottom
  const messagesEndRef = useRef(null);

  // Move to the bottom of the window
  const moveBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Load the docs
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "assist", user.id), (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Update the state or whatever is necessary with finalMessages
        setMessages(docSnapshot.data().messages || []);

        // Move to the bottom
        moveBottom();
      }
    });

    // This doesn't look right
    setTimeout(() => {
      enableSensor(true);
    }, 3000);

    return () => unsubscribe();
  }, []);

  // When new messages come in scroll to the bottom
  useEffect(() => {
    // Scroll to the bottom every time messages change
    moveBottom();
  }, [messages, opened]);

  // Load more messages
  const loadPrevious = (visibility) => {
    // Load
    if (visibility == true) {
      // Set the current page
      setCurrentPage(currentPage + 1);

      try {
        setTimeout(() => {
          // get the message id to scroll to
          const elementId = `assist-message-${messages.slice(currentPage * messagesPerPage)[0].timestamp}`;
          const element = document.getElementById(elementId);

          if (element) {
            element.scrollIntoView();
          }
        }, 100);
      } catch (_) {}
    }
  };

  // Enable the top sensor
  const enableSensor = (visibility) => {
    // Set the top sensor
    if (visibility == true) setSensor(true);
  };

  // Say something quickly (it will be lost on update)
  const say = (message) => {
    // Add a temporary message
    setMessages((messages) => [...messages, message]);
  };

  // Handle the submission function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    // Localize working, because there's a lot of other performing logic going on in the app
    setWorking(true);

    // Add a temporary message
    say({ message: newMessage, template: "assist/message", role: "user", meta: {} });

    // Deal with errors one select
    try {
      await axios.post(
        "/api/workspace/assist",
        {
          prompt: newMessage,
          userId: user.id,
          token: workspace.token(),
          style,
          feature,
          run: true,
        },
        {
          timeout: 120000, // Timeout in milliseconds (5000ms = 5 seconds)
        }
      );

      setNewMessage("");

      // Set that we're good to go here
      setWorking(false);
    } catch (error) {
      // Add a temporary message
      say({
        message: t("assistFriendlyError"),
        template: "assist/message",
        role: "assist",
        meta: {},
      });

      // Working
      setWorking(false);

      // We're no longer working
      performing.set.updating("error", "~171");
    }
  };

  // Setup generate
  const setupGenerate = () => {
    // First move to the storyboard
    workspace.feature.select({ feature: "storyboard" });

    // Select the feature (which is a wrapper dialog)
    setTimeout(
      () =>
        workspace.feature.select({ feature: "storyboard", element: "dialog", component: "setup", props: { tab: 4 } }),
      1000
    );
  };

  // Wait for content to load
  if (!ready.site) return <Working />;

  // Show dnd warning
  const showDndWarning = feature != "storyboard" && messages.find((message) => message.intent == "image") != undefined;

  // return the component
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 90px)",
        opacity: tour.running ? 0.25 : 1,
        backgroundColor: theme.palette.mode == "dark" ? alpha("#121212", 0.8) : "#fafeff",
      }}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: theme.palette.mode == "dark" ? alpha("#121212", 0.9) : "#f8fbfb",
          minHeight: "34px",
          padding: "4px",
          textAlign: "right",
        }}
      >
        <IconButton size="small" onClick={workspace.assist.close}>
          <Box sx={{ fontSize: "16px" }}>
            <DynamicIcon icon="fa-times" />
          </Box>
        </IconButton>
      </Box>
      <Divider />
      {packageName != "lite" ? (
        <>
          {showDndWarning && (
            <>
              <Alert severity="info">{t("cantDndHere")}</Alert>
            </>
          )}
          {!generate?.style && (
            <>
              <Alert
                severity="warning"
                action={
                  <Button color="inherit" size="small" variant="contained" onClick={setupGenerate}>
                    {t("setup")}
                  </Button>
                }
              >
                {t("setupGenerateStyle")}
              </Alert>
            </>
          )}
        </>
      ) : (
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" variant="contained" onClick={() => ui.dialog.open("billing/dialog")}>
              {t("upgrade")}
            </Button>
          }
        >
          {t("upgradeForMoreGenereate")}
        </Alert>
      )}

      <Divider />

      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          padding: "8px",
          borderRadius: "3px",
          flex: "1 1 auto",
          paddingTop: "10px",
        }}
      >
        <>
          {/* Endable the sensor to find previously loaded messages */}
          {sensor && (
            <VisibilitySensor onChange={loadPrevious} partialVisibility={true}>
              <Box>{currentPage * messagesPerPage * -1 < messages.length && <Working />}</Box>
            </VisibilitySensor>
          )}

          {/* Loop through the messages */}
          {messages.slice(currentPage * messagesPerPage).map((message, index) => (
            <Box {...key("assist2", "images", index)} id={`assist-message-${message.timestamp}`}>
              <Template {...message} />
            </Box>
          ))}
        </>

        {/* Scrolling to the end */}
        <Box ref={messagesEndRef} />
      </Box>

      {/* The working indicator */}
      <Box sx={{ minHeight: "10px" }}>{working && <Working />}</Box>

      {/* The text input */}
      <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", mt: 1, padding: "8px" }}>
        <Tip title={t("tourAssist")} name="assist" tour={tour.name} placement="left">
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t("whatCanIHelpYouWith")}
            value={newMessage}
            sx={{
              marginTop: "10px",
              backgroundColor: theme.palette.mode == "dark" ? "#121212" : "#FFF",
            }}
            onChange={(e) => setNewMessage(e.target.value)}
            disabled={working}
          />
        </Tip>
      </Box>
    </Box>
  );
}
