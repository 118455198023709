/**
 * Text
 *
 * @format
 */
import { nanoid } from "nanoid";
import { Frame } from "scenejs";

import { getNormalizedStyle, mergeStyles } from "./Style";

import TextTemplate from "../../config/templates/text.json";

/**
 * Adds a new text element to the workspace.
 *
 * This function generates a new text element with a unique ID, positions it
 * randomly within the editor, and initializes it with default properties.
 * It then adds the element to the workspace and triggers the text editing mode.
 *
 * @returns {Promise<void>} A promise that resolves when the text element has been added and initialized.
 *
 * @throws {Error} If an unexpected error occurs during the process.
 */
export function addTextElement() {
  const { t, workspace, errors } = this.props;

  // Generate random offset between -200 and 200
  const randomOffset = (max = 200) => Math.floor(Math.random() * (max + max + 1)) - max;

  return new Promise((resolve, reject) => {
    try {
      // A unique id for the element
      const id = nanoid();

      // The language this text is in
      const language = workspace.language.get();

      // The dimenions we want the element (this could be moved into the template file)
      const { width, height } = TextTemplate.style[0];

      // Get the editor width so we can place the element in the center
      const { width: editorWidth, height: editorHeight } = this.editor.style();

      // Center the element
      const textLeft = parseInt((editorWidth - width) / 2) + randomOffset();
      const textHeight = parseInt((editorHeight - height) / 2) + randomOffset();

      const element = {
        ...TextTemplate,
        id: id,
        font: { ...TextTemplate.font, family: this.families.get()[0] },
        text: { [language]: t("defaultDesignerText") },
        style: [
          {
            ...TextTemplate.style[0],
            width: width,
            height: height,
            left: textLeft,
            top: textHeight,
          },
        ],
      };

      // Add the element and select it
      this.element.add(element).then(() => {
        this.element.select({ id });

        // Use querySelector to select the element by data-text-id
        // const elementNode = document.querySelector(`[data-text-id="${id}"]`);

        // if (elementNode) {
        //   // Create a synthetic event object
        //   const event = { target: elementNode };

        //   // Programmatically trigger the start editing function
        //   onTextStartEditing.call(this, event);

        //   // // Select all text in the element
        //   setTimeout(() => {
        //     selectAllText.call(this, elementNode);
        //   }, 10);
        // }

        resolve();
      });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~67");
      reject(error);
    }
  });
}

/**
 * Selects all text within a given HTML element.
 *
 * @param {HTMLElement} element - The HTML element whose text should be selected.
 */
export function selectAllText(element) {
  // Ensure the element is editable
  element.contentEditable = true;

  // Focus the element
  element.focus();

  // Create a range
  const range = document.createRange();

  // Select the entire contents of the element
  range.selectNodeContents(element);

  // Get the current selection
  const selection = window.getSelection();

  // Remove any existing selections
  selection.removeAllRanges();

  // Add our range to the selection
  selection.addRange(range);
}

/**
 * Generates a CSS style object for an element's text based on provided properties.
 *
 * @param {Object} props - The properties object containing style information.
 * @param {Object} props.font - The font properties of the element.
 * @param {string} props.font.family - The font family.
 * @param {string|number} props.font.size - The font size, which can be a string or number.
 * @param {string} props.font.color - The font color.
 * @param {string} [props.font.align] - The text alignment, defaults to "left" if not provided.
 * @returns {Object} A CSS style object for the element's text.
 */
export function getElementTextStyle(props) {
  const exit = () => {
    return new Frame({ display: "none" }).toCSSObject();
  };

  const makeNumeric = (value) => {
    const cleaned = value.toString().replace(/[^0-9.-]/g, "");
    const num = parseFloat(cleaned);
    return isNaN(num) ? 32 : num;
  };

  try {
    const font = this.element.props(props)?.font;

    if (!font) return exit("Missing element.");

    return new Frame({
      fontFamily: font.family,
      fontSize: makeNumeric(font.size),
      color: font.color,
      whiteSpace: "pre-line",
      textAlign: font.align || "left",
      position: "relative",
      outline: "none",
    }).toCSSObject();
  } catch (error) {
    return exit(error);
  }
}

/**
 * Handles the start of text editing by making the target element contentEditable,
 * adding an "editing" class, focusing the element, and setting the caret position
 * based on the click event.
 *
 * @param {Event} event - The event object from the click or focus event.
 * @returns {boolean} - Returns false if an error occurs.
 */
export function onTextStartEditing(event) {
  try {
    event.target.contentEditable = true;
    event.target.classList.add("editing");
    event.target.focus();

    // Get the click position
    const clickX = event.clientX;
    const clickY = event.clientY;

    // Create a range from the click position
    const range = document.caretRangeFromPoint(clickX, clickY);

    if (range) {
      // Create a selection
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }

    this.setState({ writing: true });
  } catch (e) {
    console.error(e);
    return false;
  }
}

/**
 * Handles the end of text editing event.
 *
 * @param {Event} event - The event object from the text editing action.
 * @this {Object} - The context in which the function is called, expected to have `props`, `data`, `state`, `project`, `tool`, and `setState` properties.
 *
 * @property {Object} this.props - The properties passed to the component.
 * @property {Object} this.props.workspace - The workspace object containing language information.
 * @property {Object} this.props.performing - The performing object used to set updating status.
 * @property {Function} this.data - Function to retrieve data by key.
 * @property {Object} this.state - The state of the component.
 * @property {Object} this.state.spread - The spread object containing number information.
 * @property {Object} this.project - The project object used to update data.
 * @property {Function} this.project.update - Function to update the project data.
 * @property {Object} this.tool - The tool object used to set the current tool.
 * @property {Function} this.tool.set - Function to set the current tool.
 * @property {Function} this.setState - Function to update the component state.
 *
 * @returns {boolean|void} - Returns false if an error occurs, otherwise void.
 */
export function onTextEndEditing(event) {
  const { workspace, performing } = this.props;

  event.stopPropagation();

  try {
    event.target.contentEditable = false;
    event.target.classList.remove("editing");

    let data = this.data("~151");

    const spread = this.state?.spread?.number || 0;
    const index = parseInt(event.target.getAttribute("data-index"));
    const language = workspace.language.get();

    if (!data?.spreads[spread]?.elements[index]) return;

    data.spreads[spread].elements[index].text[language] = event.target.textContent;

    // Update the book
    this.project
      .update({
        data: data,
        note: "Updated text.",
        ai: 0,
      })
      .then(() => {
        this.tool.set("select");

        this.setState({ writing: false });

        // setTimeout(() => this.editor.refresh(), 100);
      })
      .catch(() => {
        performing.set.updating("error", "~175");
      });
  } catch (error) {
    console.log(error, "~185");
    return false;
  }
}

/**
 * Retrieves the size of a text element based on the provided properties.
 *
 * @param {Object} props - The properties object.
 * @param {number} props.index - The index of the element.
 * @param {string} props.id - The ID of the element.
 * @returns {Object|undefined} An object containing the height and width of the text element, or undefined if the element does not exist.
 * @returns {number} returns.height - The height of the text element.
 * @returns {number} returns.width - The width of the text element, with a minimum value of 300.
 */
export function getTextSize(props) {
  try {
    const { index, id } = props;
    const data = this.data("~240");
    const spread = this.state?.spread?.number || 0;
    const frame = this.state?.frame?.number || 0;

    // Make sure the element exists
    if (!data?.spreads?.[spread]?.elements?.[index]?.style?.[frame]) return;

    // get the moveable element
    const element = document.getElementById(id);

    // Get the text element wrapped inside
    const text = element.querySelector('[data-group-type="text"]');

    // Get the height from it
    let { offsetHeight: height, offsetWidth: width } = text;

    // Limit the width of the text
    if (width < 300) width = 300;

    return { height, width };
  } catch (_) {}
}

/**
 * Calculates and updates the height of text elements within the current spread.
 *
 * This function iterates over the elements in the current spread and updates the
 * height and width of text elements based on their content. It also updates the
 * corresponding style in the state.
 *
 * @function
 * @name calculateTextHeight
 *
 * @throws {Error} Logs any errors encountered during the process.
 */
export function calculateTextHeight() {
  try {
    // Query all text elements directly
    const textElements = document.querySelectorAll('[data-group-type="text"]');

    textElements.forEach((textElement) => {
      const domElement = textElement.closest(".designer-element");

      if (domElement) {
        // Check if the element is ready (you may need to adjust this based on your setup)
        if (!this.element.ready(textElement)) {
          return;
        }

        // Use mergeStyles to get the current merged style
        const currentMergedStyle = mergeStyles.call(this, domElement, false);

        // Update only width and height based on content
        const { offsetWidth: width, offsetHeight: height } = textElement;

        // Create the new style for this text element
        const newStyle = {
          ...currentMergedStyle,
          width,
          height,
        };

        // Update the DOM element style for immediate visual feedback
        Object.assign(domElement.style, getNormalizedStyle.call(this, newStyle));

        // Save the new style
        this.element.style.save(domElement, newStyle);
      }
    });
  } catch (e) {
    console.error("Error in calculateTextHeight:", e);
  }
}

// Deprecated. Use calculateTextHeight instead.
export function setTextSize(props) {
  try {
    const { index, id } = props;
    const data = this.data("~196");
    const spread = this.state?.spread?.number || 0;
    const frame = this.state?.frame?.number || 0;

    // Make sure the element exists
    if (!data?.spreads?.[spread]?.elements?.[index]?.style?.[frame]) return;

    // get the moveable element
    const element = document.getElementById(id);

    // Get the text element wrapped inside
    const text = element.querySelector('[data-group-type="text"]');

    // Get the height from it
    const { offsetWidth: width, offsetHeight: height } = text;

    // Merge the new height and width
    data.spreads[spread].elements[index].style[frame] = {
      ...data.spreads[spread].elements[index].style[frame],
      height,
      width,
    };

    // Do this quickly
    element.style = data.spreads[spread].elements[index].style[frame];
    element.style.height = `${height}px`;
    element.style.width = `${width}px`;

    // Update the state
    this.setState({ data: data });
  } catch (_) {}
}
