/** @format */
import React, { Component as ReactComponent } from "react";
import { RouterProvider, createBrowserRouter, useLocation, useNavigate, useParams } from "react-router-dom";

import { FatalError } from "errors";
import { withLocales } from "locales";
import { withUnit } from "unit";

import isSSR from "isssr";

import routes from "../libraries/index.js";

class Router extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new(this.constructor.name);

    const { paramize } = this.props.locales;

    this.localized = routes.map((route) => ({ ...route, path: paramize(route.path) }));
  }

  render() {
    return (
      <FatalError>
        <RouterProvider router={createBrowserRouter(this.localized)} />
      </FatalError>
    );
  }
}

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location, navigate, params;
    if (!isSSR) {
      location = useLocation();
      navigate = useNavigate();
      params = useParams();
    } else {
      location = () => {};
      navigate = () => {};
      params = {};
    }
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
};

// Custom useRouter hook
const useRouter = () => {
  let location, navigate, params;
  if (!isSSR) {
    location = useLocation();
    navigate = useNavigate();
    params = useParams();
  } else {
    location = () => {};
    navigate = () => {};
    params = {};
  }
  return { location, navigate, params };
};

export default withUnit(withLocales(Router));
export { routes, useRouter, withRouter };
