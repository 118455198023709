/** @format */

export function setElementLabel(text) {
  try {
    const { ui } = this.props;
    ui.close();
    this.setState({ label: text, moveable: true });
  } catch (_) {}
}

export function showElementLabel() {
  try {
    this.setState({ label: null });
  } catch (_) {}
}

export function hideElementLabel() {
  try {
    this.setState({ label: null, moveable: false });
  } catch (_) {}
}
