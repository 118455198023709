/** @format */
import { Avatar, Box, Card, CardContent, Grid, Hidden, ListItemText, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocales } from "locales";

const mock = [
  {
    name: "Karen Richard",
    title: "CEO",
    avatar:
      "https://us-central.cdn.made.live//thumbnail_Karen_headshot_color_authentic_trustworthy_c2aa96a7c8/thumbnail_Karen_headshot_color_authentic_trustworthy_c2aa96a7c8.jpg",
    about: "I am an ambitious workaholic, but apart from that, pretty simple person.",
  },
  {
    name: "Jesse Richard",
    title: "CTO",
    avatar:
      "https://us-central.cdn.made.live//thumbnail_secta_ai_012_7b5e4531a4/thumbnail_secta_ai_012_7b5e4531a4.jpg?2023-11-07T19:06:14.375Z",
    about: "Code tells a story as enchanting as the books it helps to build.",
  },
];

const Team = () => {
  const theme = useTheme();
  const { t } = useLocales();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography fontWeight={700} variant={"h2"} align={"center"}>
          {t("ourTeam")}
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch ">
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i} style={{ flex: "1 0 25%" }}>
            <Box
              component={Card}
              boxShadow={2}
              sx={{
                minHeight: "100%",
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <CardContent>
                <Box component={Avatar} src={item.avatar} height={80} width={80} />
                <Box marginTop={4}>
                  <ListItemText primary={item.name} secondary={item.title} />
                  <Hidden smDown>
                    <Typography variant={"subtitle2"} color={"text.secondary"}>
                      {item.about}
                    </Typography>
                  </Hidden>
                  {/* <Box marginTop={4}>
                    <IconButton size={"small"} color={"primary"}>
                      <DynamicIcon icon={fa-facebook} />
                    </IconButton>
                    <IconButton size={"small"} color={"primary"}>
                      <GitHubIcon />
                    </IconButton>
                    <IconButton size={"small"} color={"primary"}>
                      <TwitterIcon icon={fa-facebook} />
                    </IconButton>
                  </Box> */}
                </Box>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
