/** @format */
import { stamp } from "dateml";
import { addDoc, collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "firemade";
import uniq from "lodash/uniq";

/**
 * Starts a chat session with the specified users.
 * @param {Array} users - An array of user IDs to start the chat session with.
 * @returns {string} - The ID of the chat session.
 */
export function startChart(users) {
  const { errors } = this.props;
  return new Promise(async (resolve, reject) => {
    try {
      const { user } = this.props;

      // Make sure we have a user
      if (!user.id) return reject("No user ID found");

      // Construct the new user
      const addUser = typeof users == "string" ? [users] : users;

      // Create a list of users
      const chatUsers = uniq([user.id, ...addUser]).sort();

      if (chatUsers.length == 1) return reject("Cannot start a chat with yourself");

      // Check if a chat with these exact users already exists
      const q = query(collection(db, "chat"), where("users", "==", chatUsers));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // set the chat
        this.setState({ id: querySnapshot.docs[0].id });

        // Chat already exists, return the existing chat's ID
        return resolve(querySnapshot.docs[0].id);
      }

      // Chat doesn't exist, create a new chat document
      const newChat = stamp({
        users: chatUsers,
        messages: [],
        seen: [],
        typing: [],
        name: null,
      });

      const docRef = await addDoc(collection(db, "chat"), newChat);

      // set the chat
      this.setState({ id: docRef.id });

      // Resolve it
      return resolve(docRef.id);
    } catch (e) {
      errors.error(true, e, "~58");
      reject(e);
    }
  });
}

/**
 * Selects a chat with another user.
 *
 * @param {string} id - The ID of the chat to select.
 * @returns {void}
 */
export function selectChat(id) {
  try {
    this.setState({ id: id }, () => {
      setChatSeen.call(this);
    });
  } catch (_) {}
}

/**
 * Exits the chat.
 * @param {string} [from="~80"] - The source of the exit action.
 */
export function exitChat() {
  try {
    this.setState({ id: null });
  } catch (_) {}
}

/**
 * Checks for active chat and returns the chat object.
 * @param {string} from - The ID of the chat.
 * @returns {Object} - The chat object with the following properties:
 *   - name: The name of the chat.
 *   - ready: A boolean indicating if the chat is ready.
 *   - error: A boolean indicating if there was an error.
 *   - messages: An array of chat messages.
 */
export function getActiveChat() {
  if (this.state.id) {
    return this.state.id;
  } else {
    return null;
  }
}

/**
 * Finds the index of the chat with the specified id in the state's chats array.
 * @returns {number} The index of the chat, or -1 if not found.
 */
export function getChatIndex() {
  let index = this.state.chats.findIndex((chat) => chat.id == this.state.id);
  return index;
}

/**
 * Renames a chat group.
 *
 * @param {string} name - The new name for the chat group.
 * @param {string} [from="~118"] - The ID of the user initiating the rename operation.
 * @returns {Promise<void>} - A promise that resolves when the chat group has been renamed.
 */
export async function setChatName(name) {
  try {
    // get the chat index
    let index = getChatIndex.call(this);

    // if the chat index is -1, return
    if (index == -1) return;

    // Update the state
    setDoc(
      doc(db, "chat", this.state.id),
      stamp({
        name: name,
      }),
      { merge: true }
    );

    // this.send({
    //   message: "Renamed chat " + name,
    //   template: "renamed",
    //   userId: false,
    // });
  } catch (_) {
    console.log(_);
  }
}

/**
 * Sets the chat name based on the provided name and user profiles.
 * @param {string} name - The name of the chat.
 * @returns {string} - The generated chat name or "Error" if an error occurs.
 */
export function getChatName() {
  const { profiles } = this.props;
  try {
    const chat = this.state.chats.find((chat) => chat.id == this.state.id);

    return (
      chat?.name ||
      chat.users
        .map((userId) => profiles.name.first(userId))
        .join(", ")
        .slice(0, 100)
    );
  } catch (_) {
    return "Chat";
  }
}

/**
 * Sets the status to "followed".
 */
export function followed() {
  this.status("followed");
}

/**
 * Checks if the given message is unfollowed by the user.
 * @param {Object} message - The message object.
 * @returns {boolean} - Returns true if the message is unfollowed, false otherwise.
 */
export function unfollowed(message) {
  return !message.followed.includes(this.props.user.id);
}

/**
 * Retrieves the active messages from the chat context.
 * @returns {Object} The active messages object.
 */
export function getActiveMessages() {
  // get the latest index
  let index = getChatIndex.call(this);

  // if the chat index is -1, return
  if (index == -1) {
    return [];
  } else {
    let chat = { ...this.state.chats[index] };
    let media = [...this.state.media];
    let messages = [...chat.messages];
    let last = { user: null, time: 0 };
    messages.forEach((message, i) => {
      messages[i].mine = message.userId == this.props.user.id;
      messages[i].merge = {
        user: message.userId == last.user,
        time: Date.now() - 60000 < message.timestamp,
      };
      last = { user: message.userId, time: message.timestamp };
      if (message.media) {
        if (this.props.media && this.props.media.connected && !this.state.media.includes(message.id)) {
          this.props.media.query({
            name: "chat-message-" + message.id,
            attachedTo: message.id,
          });
          media.push(message.id);
        }
        messages[i].attachments = this.props.media.getFiltered({
          name: "chat-message-" + message.id,
        });
      }
    });

    uniq(media);

    if (media.length != this.state.media.length) this.setState({ media: media });

    return {
      name: null,
      ...chat,
      ...{ ready: true, error: false },
    };
  }
}

/**
 * Updates the status of a chat.
 * @param {string} type - The type of status to update.
 * @returns {boolean} - Returns false if the chat status was updated, otherwise undefined.
 */
export function setChatStatus(type) {
  try {
    let index = getChatIndex.call(this);
    if (index == -1) return;
    let chat = this.state.chats[index];
    if (chat[type].includes(this.props.user.id)) return false;

    delete chat.id;

    chat.users.push(this.props.user.id);
    chat[type].push(this.props.user.id);

    uniq(chat.users);
    uniq(chat.followed);
    uniq(chat.seen);

    setDoc(
      doc(db, "chat", this.state.id),
      {
        ...chat,
        timestamp: {
          updated: Date.now(),
        },
      },
      { merge: true }
    );
  } catch (_) {}
}

/**
 * Marks a message as seen.
 * @param {string} [from="~271"] - The sender of the message.
 * @returns {void}
 */
export function setChatSeen() {
  setChatStatus.call("seen");
}

/**
 * Checks if a message has been seen or not.
 * @param {object} message - The message object to check.
 * @param {string} [from="~281"] - The ID of the user who sent the message.
 * @returns {boolean} - Returns true if the message has not been seen, false otherwise.
 */
export function getChatSeen({ id }) {
  const { user } = this.props;
  try {
    const seen = this.state.chats.find((chat) => chat.id == id).seen.includes(user.id);
    return seen;
  } catch (e) {
    return false;
  }
}

/**
 * Retrieves the chat list from the component's state.
 * @returns {Array} The chat list.
 */
export function getChatList() {
  try {
    return this.state.chats || [];
  } catch (e) {
    return [];
  }
}

/**
 * Retrieves the users of the current chat.
 * @returns {Array} The array of users in the chat.
 */
export function getChatUsers() {
  try {
    // get the index of the chat
    let index = getChatIndex.call(this);

    // if the chat index is -1, return
    if (index == -1) return [];

    // Here are the users
    return this.state.chats[index].users;
  } catch (e) {
    return [];
  }
}
