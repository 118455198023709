/** @format */
import TopMenu from "@App/Menus/Top";
import { SmartButton } from "@Workspace/Components/SmartButtons";
import { Box, Divider, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withActions } from "actions";
import { ComponentError, TinyError } from "errors";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";
import { Tip, withTour } from "tour";
import { withUI } from "ui";

const StyledStack = styled(Stack)(({ theme }) => ({
  "&.menu.top": {
    position: "fixed",
    width: "100vw",
    top: 0,
    textAlign: "center",
    backgroundColor: theme.palette.mode == "light" ? "#FAFAFA" : "#646464",
    zIndex: 0,
    minBlockSize: "fit-content",
    transform: "translate(-50%)",
    left: "50%",
    minWidth: "calc(100vw - 112px)",
  },

  "&.touring": {
    backgroundColor: "transparent",
    zIndex: 1257,
  },
}));

class Top extends ReactComponent {
  render() {
    const { children, buttons, workspace, t, tour } = this.props;
    if (!workspace.ready) return <></>;
    return (
      <ComponentError>
        <TopMenu>
          <Tip title={t("tourMenu")} name="menu" tour={tour.name} placement="bottom">
            <StyledStack direction="row" justifyContent="center" alignItems="center" className="menu top">
              <ComponentError>
                <Box sx={{ minWidth: "500px" }}>{children}</Box>
              </ComponentError>
              <Divider orientation="vertical" sx={{ opacity: 0.2, height: "56px" }} flexItem />
              <TinyError>
                <SmartButton buttons={buttons} label={t("undo")} icon="fa-undo" slug="undo" />
              </TinyError>
              <TinyError>
                <SmartButton buttons={buttons} label={t("redo")} icon="fa-redo" slug="redo" />
              </TinyError>
              <TinyError>
                <SmartButton buttons={buttons} label={t("share")} icon="fa-share" slug="share" />
              </TinyError>
            </StyledStack>
          </Tip>
        </TopMenu>
      </ComponentError>
    );
  }
}

export default withLocales(withActions(withUI(withTour(Top))));
