/** @format */

import isSSR from "isssr";
import { lazy, Suspense } from "react";

const Workspace = lazy(() => import("../index.jsx"));
const Profiles = lazy(() => import("../components/Profiles/index.jsx"));

const Container = ({ children }) => <Suspense fallback={null}>{children}</Suspense>;

const routes = !isSSR
  ? [
      {
        path: "app/workspace",
        element: (
          <Container>
            <Workspace />
          </Container>
        ),
        children: [
          {
            path: ":workspaceId",
            element: (
              <Container>
                <Workspace />
              </Container>
            ),
            children: [
              {
                path: ":desk",
                element: (
                  <Container>
                    <Workspace />
                  </Container>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "app/profiles/:profileId",
        element: (
          <Container>
            <Profiles />
          </Container>
        ),
      },
    ]
  : [
      {
        path: "app/workspace",
        element: <></>,
        children: [
          {
            path: ":workspaceId",
            element: <></>,
            children: [
              {
                path: ":desk",
                element: <></>,
              },
            ],
          },
        ],
      },
      {
        path: "app/profiles/:profileId",
        element: <></>,
      },
    ];

export default routes;
