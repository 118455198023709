/** @format */

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { useLocales } from "locales";
import React from "react";

import isSSR from "isssr";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Reject = () => {
  const { t } = useLocales();

  const deleteCookies = (prefix) => {
    if (isSSR) return;

    // Get all cookies
    const allCookies = document.cookie.split(";");

    // Loop through each cookie
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i].trim();

      // Check if the cookie starts with the specified prefix
      if (cookie.includes(prefix)) {
        // Split the cookie into name and value
        const [cookieName] = cookie.split("=");

        // Set the cookie's expiration date to a past date to delete it
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

        location.reload();
      }
    }
  };

  return (
    <Box>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        transitionDuration={0}
      >
        <DialogTitle id="alert-dialog-slide-title">{t("rejectAgreementTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{t("rejectAgreementBody")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteCookies("_iub")} variant="contained" color="warning">
            {t("fix")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Reject;
