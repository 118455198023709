/** @format */

import { Box, Button, Popper, useTheme } from "@mui/material";
import DynamicIcon from "dynamicicon";
import isSSR from "isssr";
import React, { useState } from "react";
import { url } from "../../config/index.json";

import { Functional } from "unit";

const unit = Functional.unit("getresponse");

const Chat = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  if (isSSR) return null;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    // Log the event
    unit.report({
      method: "chatOpened",
      event: "chatOpened",
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <Box sx={{ zIndex: 2000 }}>
      <Button
        aria-describedby={id}
        type="button"
        color="success"
        variant="contained"
        onClick={handleClick}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 2000,
          minHeight: "60px",
          backgroundColor: theme.palette.success.main,
          "&:hover": {
            backgroundColor: theme.palette.success.dark,
          },
        }}
        data-testid="chat-button"
      >
        <DynamicIcon icon="fa-comments" />
      </Button>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="top-end" style={{ zIndex: 2000000 }}>
        <Box
          sx={{
            bgcolor: "background.paper",
            zIndex: 2000000,
            width: {
              xs: "90vw",
              sm: "80vw",
              md: "30vw",
            },
            height: "calc(110vh - 240px)",
            border: `1px solid ${theme.palette.info.main}`,
            borderRadius: "5px",
            overflow: "hidden",
            marginBottom: "20px",
          }}
        >
          <iframe
            src={url}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            title="Your Iframe Title"
          ></iframe>
        </Box>
      </Popper>
    </Box>
  );
};

export default Chat;
