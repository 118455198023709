/** @format */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import key from "key";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";

import packages from "../../../../config/packages.json";
import NestedPricingTable from "../../libraries/PricingTables/nested";
import PaymentForm from "./components/PaymentForm";

const Checkout = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { t } = useLocales();

  const optIn = props?.opt == "opt-in" ? true : false;

  const [pricingOption, setPricingOption] = useState("yearly");

  const [selectedPackage, setSelectedPackage] = useState(null);

  const handleClick = (_, newPricingOption) => {
    setPricingOption(newPricingOption);
  };

  // New function to set selected package
  const handlePackageSelect = (pkg) => {
    setSelectedPackage(selectedPackage === pkg ? null : pkg);
  };

  const featuresByPackage = NestedPricingTable({ duplicate: true, packages: ["lite", "premium", "pro"] });

  // For no payment we automatically select yearly
  useEffect(() => {
    if (!optIn) return;
    setPricingOption("yearly");
    setSelectedPackage("premium");
  }, [optIn]);

  return (
    <Box sx={{ paddingBottom: "2.5%" }}>
      <Box sx={{ textAlign: "center" }} component="h5">
        {t("optOutTrailLength", { days: props.trial })}
      </Box>
      {!selectedPackage && (
        <Box sx={{ paddingBottom: "1.5%" }}>
          <Box display={"flex"} justifyContent={"center"}>
            <ToggleButtonGroup color="primary" value={pricingOption} exclusive onChange={handleClick}>
              <ToggleButton
                value="monthly"
                size={isMd ? "small" : "small"}
                sx={
                  {
                    // backgroundColor:
                    //   pricingOption === "monthly" ? `${theme.palette.primary.main} !important` : "transparent",
                    // border: `1px solid ${theme.palette.primary.main}`,
                  }
                }
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 700,
                    //color: pricingOption !== "yearly" ? "common.white" : "text.primary",
                  }}
                >
                  {t("monthly")}
                </Typography>
              </ToggleButton>
              <ToggleButton
                value="yearly"
                size={isMd ? "small" : "small"}
                sx={
                  {
                    // backgroundColor:
                    //   pricingOption === "yearly" ? `${theme.palette.primary.main} !important` : "transparent",
                    // border: `1px solid ${theme.palette.primary.main}`,
                  }
                }
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 700,
                    // color: pricingOption === "yearly" ? "common.white" : "text.primary",
                  }}
                >
                  {t("yearly")}
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      )}
      <Box padding={{ padding: "0 5%" }}>
        {!optIn ? (
          <Grid container spacing={4}>
            {Object.keys(featuresByPackage)
              .slice(0, 2)
              .map((i, k) => {
                // Filtering logic added here
                if (selectedPackage && selectedPackage !== i) return null;

                const item = packages[i];

                if (item.monthly.enabled != true || item.yearly.enabled != true) return;

                return (
                  <Grid item xs={12} md={6} {...key("pricing", "main", i)}>
                    <Card
                      raised={k == 1 ? true : false}
                      height={1}
                      display={"flex"}
                      sx={{ marginTop: i == 1 ? 0 : "10px" }}
                    >
                      <CardContent
                        sx={{
                          padding: 4,
                          position: "relative",
                        }}
                      >
                        <Box marginBottom={2}>
                          <Typography
                            variant={"h1"}
                            style={{
                              textTransform: "capitalize",
                              margin: "10px 10px 10px 0",
                              textAlign: "center",
                              fontWeight: 700,
                            }}
                            gutterBottom
                          >
                            {t(i)}
                          </Typography>
                          <Box sx={{ padding: "30px 10px" }}>
                            <Typography color={"text.secondary"} fontSize="12px" textAlign="center">
                              {t(`${i}Description`)}
                            </Typography>
                          </Box>
                          <Stack direction="row" justifyContent="center" alignItems={"baseline"} paddingBottom={5}>
                            <Typography variant={"h3"} fontWeight={700} sx={{ marginBottom: "0px" }}>
                              $
                              {pricingOption === "yearly"
                                ? ((item.yearly.price / 12) * item.yearly.discount).toFixed(2)
                                : (item.monthly.price * item.yearly.discount).toFixed(2)}
                            </Typography>
                            <Typography
                              variant={"subtitle1"}
                              color={"text.secondary"}
                              sx={{ fontSize: "10px" }}
                              fontWeight={700}
                            >
                              {pricingOption === "yearly" ? (
                                <>
                                  /{t(".mo")} <small>{t("paidAnnually")}</small>
                                </>
                              ) : (
                                <>/{t("monthly")}</>
                              )}
                            </Typography>
                          </Stack>
                          <CardActions sx={{ textAlign: "center", padding: 0 }}>
                            {!selectedPackage ? (
                              <Button
                                size={"large"}
                                disabled={k > 1}
                                color="success"
                                variant={"contained"}
                                onClick={() => {
                                  handlePackageSelect(i);
                                }}
                              >
                                {t("getStarted")}
                              </Button>
                            ) : (
                              <Button size="small" onClick={() => handlePackageSelect(i)}>
                                {t("switchPackage")}
                              </Button>
                            )}
                          </CardActions>
                        </Box>
                      </CardContent>
                      <Box flexGrow={1} />
                    </Card>
                  </Grid>
                );
              })}
            {selectedPackage && (
              <Grid item xs={12} md={6}>
                <PaymentForm selectedPackage={packages[selectedPackage][pricingOption]} {...props} optIn={optIn} />
              </Grid>
            )}
          </Grid>
        ) : (
          <Box sx={{ width: "80%", marginLeft: "10%" }}>
            {selectedPackage && pricingOption && (
              <PaymentForm selectedPackage={packages[selectedPackage][pricingOption]} {...props} optIn={optIn}>
                <>
                  <Divider />
                  <Box sx={{ padding: "5% 0" }}>
                    <Box component="span">{t("optInDescription", { days: props.trial })}</Box>
                    <Box sx={{ marginTop: "10px", fontWeight: "bold" }}>{t("happyCreating")}</Box>
                  </Box>
                </>
              </PaymentForm>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Checkout;
