/** @format */
import { auth } from "firemade";

export default function reauthenticationProvider() {
  try {
    const user = auth.currentUser;

    if (!user) {
      throw new Error("auth/user-not-found");
    }

    const providerData = user.providerData[0];
    return providerData.providerId;
  } catch (e) {
    console.error(e.message);
    return "unknown";
  }
}
