/** @format */

import { setUser } from "@sentry/react";

// This will merge the user into sentry so we have more information
export function setSentry() {
  // set that we already did this
  if (this.state.sentry) return;

  // Make sure we have a user
  if (this.state.uid && this.state.email) {
    // Setting the user
    setUser({
      id: this.state.uid,
      email: this.state.email,
    });
    // Set that we did this properly
    this.setState({ sentry: true });
  }
}
