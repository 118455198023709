/** @format */
import Config from "@Workspace/config";

// The packages available from the
export function getPackages() {
  return Config.packages;
}

// # TODO - This is an artifact
export function setPackages(packages) {}
