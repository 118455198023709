/** @format */
import { Component as ReactComponent, createContext, useContext } from "react";
import { withUnit } from "unit";
import Controls from "../components/Controls";
import config from "../config/index.json";

// Add tours is
import { add } from "./helpers/Add";

// Clear the tour
import { clear } from "./helpers/Clear";

// Start the tour
import { start } from "./helpers/Start";

// Stop the tour
import { stop } from "./helpers/Stop";

// Complete the tour
import { complete } from "./helpers/Complete";

// Next step
import { next } from "./helpers/Next";

const TourContext = createContext({});
class Tour extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new(this.constructor.name);

    this.state = {
      ready: true,
      running: false,
      type: config.type, //stepper, timer
      name: null,
      controls: false,
      complete: false,
      progress: 0,
      percent: 0,
    };

    this.timer = 0;
    this.steps = [];
    this.timers = [];
    this.progress = null;

    // Add new steps to tour
    this.add = add.bind(this);

    // Clear the tour
    this.clear = clear.bind(this);

    // Start the tour
    this.start = start.bind(this);

    // Stop the tour
    this.stop = stop.bind(this);

    // Complete
    this.complete = complete.bind(this);

    // Next step
    this.next = next.bind(this);
  }

  // Setup the tour

  setup = () => {
    this.setState({ controls: true, ready: true });
  };

  // When there is a timer on

  timer = (name) => {
    this.name = name;
    this.add.to = (to) => {
      this.steps.push({ name: this.name, from: this.from, to: to });
      if (to > this.timer) this.timer = to;
    };
    this.add.from = (from) => {
      this.from = from;
      return this.add;
    };
    this.setState({ type: timer, created: true }, () => {
      this.setState({ ready: true });
    });
    return this.add;
  };

  getProgress = () => {
    this.setState({
      progress: this.state.progress + 100,
      percent: (this.state.progress / this.timer) * 100,
    });
  };

  /* Mounting, updating */
  componentWillUnmount() {
    this.stop();
  }

  componentDidMount() {
    this.setup();
  }

  render() {
    const context = {
      // merge in the state
      ...this.state,

      name: this.state.name,

      // Timed running
      start: this.start,
      stop: this.stop,

      // Add tour step
      add: this.add,

      // Clear all steps
      clear: this.clear,
      steps: this.steps, // # TODO don't know if we need this

      // Next and previous
      next: this.next,

      //! Not sure if this is defined
      previous: this.previous,

      // When we're complete with the tour
      complete: this.complete,
    };
    return (
      <>
        {this.state.ready ? (
          <TourContext.Provider {...this.props} value={context}>
            {this.props.children}
            {this.state.running && <Controls {...this.props} tour={context} />}
          </TourContext.Provider>
        ) : (
          <>{this.props.children}</>
        )}
      </>
    );
  }
}

const withTour = (Component) => {
  return function ContextualComponent(props) {
    return <TourContext.Consumer>{(state) => <Component {...props} tour={state} />}</TourContext.Consumer>;
  };
};

const useTour = () => {
  return useContext(TourContext);
};

export default withUnit(Tour);
export { useTour, withTour };
