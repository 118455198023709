/**
 * Firebase configuration and initialization module.
 *
 * @format
 * @module firebase
 */
import isSSR from "isssr";

import {
  isSupported as analyticsIsSupported,
  getAnalytics,
  logEvent as logEventComponent,
  setUserId as setUserIdComponent,
} from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import development from "./config/development.json" assert { type: "json" };
import production from "./config/production.json" assert { type: "json" };

const config = import.meta.env.MODE === "production" ? production : development;
const app = initializeApp(config);
const auth = getAuth(app);

let analytics;

const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  useFetchStreams: false, // and this line
});

const storage = getStorage(app);

// Only initialize Analytics in supported environments
analyticsIsSupported().then((isSupported) => {
  if (isSupported) {
    analytics = getAnalytics(app);
  } else {
    // Provide a mock or minimal implementation for unsupported environments
    analytics = {
      logEvent: () => {},
      setUserId: () => {},
    };
  }
});

const logEvent = (event, params) => {
  if (!isSSR) logEventComponent(analytics, event, params);
};

const setUserId = (id) => {
  if (!isSSR) setUserIdComponent(analytics, id);
};

export { analytics, app, auth, db, logEvent, setUserId, storage };
