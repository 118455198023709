/** @format */

import { Functional } from "unit";

const unit = Functional.unit("Data");

export function updateSimple(from = "~7") {
  return new Promise((resolve, reject) => {
    try {
      unit.report({
        method: "updateSimple",
        message: "Updating simple",
        from: from,
      });
      resolve(true);
    } catch (error) {
      reject(error, "~17");
    }
  });
}
