/** @format */

/**
 * Converts a date to a UTC ISO string.
 * @param {Date|number|string} date - The date to convert. It can be a Date object, a timestamp, or a string representation of a date.
 * @returns {string} The UTC ISO string representation of the date.
 */
function toUtcIsoString() {
  // Get the current time in UTC directly
  const now = new Date();
  const isoString = now.toISOString();

  return isoString;
}

/**
 * Creates a timestamp object with the current time as both created and updated values.
 *
 * @returns {Object} Timestamp object with created and updated times.
 */
function created(constructed = false) {
  const nowIso = toUtcIsoString();

  // create a reusable timestamp object
  const timestamp = {
    created: nowIso,
    updated: nowIso,
  };

  // Check if we're using a constructed timestamp
  return constructed
    ? {
        timestamp: timestamp,
      }
    : timestamp;
}

/**
 * Updates the 'updated' field of a timestamp object to the current time.
 *
 * @param {Object} previousTimestamp - The previous timestamp object.
 * @returns {Object} Timestamp object with updated 'updated' time.
 */
function updated(previousTimestamp, constructed = false) {
  const nowIso = toUtcIsoString();

  const timestamp = {
    ...previousTimestamp.timestamp,
    updated: nowIso,
  };

  return constructed
    ? {
        ...previousTimestamp,
        timestamp: timestamp,
      }
    : timestamp;
}

/**
 * Stamps an object with a timestamp.
 *
 * @param {Object} object - The object to stamp
 * @returns {Object} The object that is stamped
 */
function stamp(object = {}) {
  if (!object.timestamp) {
    object = { ...object, ...created(true) };
  } else {
    object = { ...object, ...updated(object.timestamp) };
  }
  return object;
}

/**
 * Returns the current time in ISO 8601 format.
 *
 * @returns {string} The current time in ISO 8601 format.
 */
function now() {
  return toUtcIsoString();
}

// Exporting the functions to use in other files
export { created, now, stamp, updated };
