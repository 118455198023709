/** @format */

export function getEmail() {
  return this.state.email;
}

export function setEmail(email) {
  return new Promise((resolve, reject) => {
    try {
      // Basic email validation regex pattern
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      // Double check to make sure this is a valid email
      if (!emailPattern.test(email)) return reject("invalidEmailProvided");

      this.authentication
        .setEmail(email)
        .then((email) => {
          this.setState({
            email: email,
          });
          resolve(email);
        })
        .catch((error) => {
          return reject(error);
        });
    } catch (e) {
      return reject(error);
    }
  });
}
