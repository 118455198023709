/** @format */
import React from "react";
import Reauthentication from "../../components/Reauthentication";

// Check if user needs requires
export function getRequired() {
  return this.authentication.reauthenticationRequired();
}

// Let the user know that they need to reauthenticate, then open the component
export function openReauthentication(callback) {
  const { ui, t, errors } = this.props;

  // Check if we need to reauthenticate change this to a function
  if (this.authentication.reauthenticationRequired()) {
    // open the confirmation confirmation (as a confirm so no dialogs are closed)
    ui.confirm.open({
      title: t("reauthenticationTitle"),
      body: React.createElement(Reauthentication, { user: this, ...this.props }),
      props: { callback },
    });
  } else if (callback) {
    try {
      callback();
    } catch (e) {
      errors.error(true, e, ~"28");
    }
  }
}

// # TODO - Return this - this is way overkill - this component can be very streamlined
export function getReauthenticationProvider() {
  return this.authentication.reauthenticationProvider();
}
