/** @format */
import Config from "@Workspace/config";
import capitalize from "capitalize";
import React from "react";

import { Functional } from "unit";

const unit = Functional.unit("workspace/feature");

// Setup

export default async function setupFeatures(from = "~12") {
  return new Promise((resolve, reject) => {
    try {
      document.body.setAttribute("data-feature", this.state.settings.feature);
      this.ui.features = Config.features;
      this.ui.desks = {};
      let ordered = Object.keys(this.ui.features).sort(
        (a, b) => this.ui.features[a].priority - this.ui.features[b].priority
      );
      ordered.forEach((feature) => {
        if (Config.usable.features[feature]) this.ui.features[feature].usable = Config.usable.features[feature];
      });
      Object.keys(Config.ui).forEach((type) => {
        if (type == "relations") {
          if (!this.ui.parents) this.ui.parents = {};
          Object.keys(Config.ui.relations.parents).forEach((parent) => {
            if (!this.ui.parents[parent]) this.ui.parents[parent] = [];
            Config.ui.relations.parents[parent].forEach((child) => {
              this.ui.parents[parent].push({
                ...Config.features[child],
                path: "../../components/Features/components/" + capitalize(child) + "/index.jsx",
                name: capitalize(child),
              });
            });
          });
        } else if (type == "boards" || type == "widgets" || type == "dialogs") {
          Object.keys(Config.ui[type]).forEach((component) => {
            if (!this.ui[type]) this.ui[type] = {};
            Config.ui[type][component].forEach(() => {
              Config.ui[type][component].forEach((board) => {
                if (!this.ui[type][component]) this.ui[type][component] = {};
                this.ui[type][component][board] = React.lazy(() =>
                  import(
                    `../../components/Features/components/${capitalize(component)}/${type}/${capitalize(
                      board
                    )}/index.jsx`
                  )
                );
              });
            });
          });
        } else if (type == "menus") {
          if (!Config.menus) Config.menus = {};
          if (!this.ui.menus) this.ui.menus = {};
          Object.keys(Config.ui.menus).forEach((menu) => {
            Config.ui.menus[menu].forEach((feature) => {
              let component = feature.charAt(0).toUpperCase() + feature.slice(1);
              let place = menu.charAt(0).toUpperCase() + menu.slice(1);
              if (!this.ui.menus[menu]) {
                Config.menus[menu] = {};
                this.ui.menus[menu] = {};
              }
              if (!this.ui.menus[menu][feature]) {
                Config.menus[menu][feature] = this;
                this.ui.menus[menu][feature] = {};
              }
              this.ui.menus[menu][feature] = React.lazy(() =>
                import(`../../components/Features/components/${component}/menus/${place}/index.jsx`)
              );
            });
          });
        }
      });
      Object.keys(Config.features).forEach((feature) => {
        this.features[feature] = Config.features[feature];
      });
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

// Select

// The features, the element (desk, dialog, board, widget), the component (if applicable, the component name in the requisite directory), props (for confirm etc)
export function selectFeature(params, from = "~95") {
  let testId = unit.report({
    method: "selectFeature",
    message: "Will change the desk and menus if the feature is not simple a widget or board.",
    test: "When using the feature (popup) menu in the bottom right, clicking the feature you wish to access should do accordingly. All features should be tested.",
    from: from,
    group: "selectFeature",
  });

  const { performing, errors } = this.props;

  try {
    let { feature, element = null, component = null, props = {} } = params;

    if (!feature) throw "No featured selected.";

    // Close the checklist if it's open
    this.checklist.open.set(false);

    feature = feature.toLowerCase();

    if (!element) {
      const lookup = ["desks", "dialogs", "boards", "widgets"];

      for (let i = 0; i < lookup.length; i++) {
        if (this.ui[lookup[i]] && this.ui[lookup[i]][feature]) {
          element = lookup[i].slice(0, -1);
          break;
        }
      }
    }

    if (this.ui.desks && this.ui.desks[feature] && element == "desk") {
      if (feature == this.state.settings.desk) return;

      performing.set.loading(true, "~137");

      // Close the tips if they're open
      this.tips.close();

      setTimeout(() => {
        this.desk.set(feature, {
          feature: feature,
          title: this.features[feature].title,
          data: this.features[feature].data,
          features: this.ui.features || {},
        });
        setTimeout(() => performing.set.loading(false, "~149"), 500);
      }, 500);

      return;
    }

    if (this.ui.dialogs && this.ui.dialogs[feature] && element == "dialog") {
      let dialogId = unit.report({
        method: "selectFeature",
        message: "Opening dialog for feature (if dialog only).",
        test: "When selecting a feature that has a dialog window the window should be displayed.",
        steps: ["From a book", "Select the features menu (bottom left)", "Select Project."],
        from: from,
        group: "selectFeature",
        event: "selectFeature",
        payload: { feature },
      });
      let dialog = null;

      if (component) {
        dialog = component;
      } else {
        dialog = feature;
      }

      if (dialog) {
        // If we've passed an object, lets start there
        let dialogProps = typeof props == "object" ? props : {};

        // Add the confirmation button if needed (true is the shorthand)
        if (props === true || props?.confirm == true)
          dialogProps = {
            ...dialogProps,
            confirm: {
              text: "finished",
            },
          };

        // Add the cancel button if needed
        if (props?.cancel == true)
          dialogProps = {
            ...dialogProps,
            cancel: {
              text: "cancel",
            },
          };

        // Add the cancel button if needed
        if (props?.acknowledge == true)
          dialogProps = {
            ...dialogProps,
            acknowledge: {
              text: "cancel",
            },
          };

        this.dialog.open("feature", {
          ...component,
          feature: feature,
          dialog: dialog,
          title: capitalize(dialog) || capitalize(this.features[feature].title),
          data: this.data.feature(feature),
          features: this.features || {},
          ...dialogProps,
        });
        unit.passed({ testId: dialogId }, from);
        return;
      }
    }

    if (this.ui.boards && this.ui.boards[feature] && element == "board") {
      let boardId = unit.report({
        method: "selectFeature",
        message: "Features employing boards should show boards when selected.",
        steps: [
          "From a book",
          "Select Storyboard or Illustrate from features menus.",
          "Click (images from bottom menu)",
        ],
        from: from,
        group: "selectFeature",
        event: "selectFeature",
        payload: { feature },
      });

      let board = null;

      if (component) {
        board = component;
      } else {
        dialog = feature;
      }

      if (board) {
        // If we've passed an object, lets start there
        let boardProps = typeof props == "object" ? props : {};

        try {
          this.board.open(feature, {
            feature: feature,
            board: board,
            title: capitalize(board),
            data: this.features[feature].data,
            features: this.ui.features || {},
            component: component,
            ...boardProps,
          });
        } catch (e) {
          console.error(e, "~257");
        }
        unit.passed({ testId: boardId }, from);
        return;
      }
    }

    if (this.ui.widgets && this.ui.widgets[feature] && element == "widget") {
      let widgetId = unit.report({
        method: "selectFeature",
        message: "Opening widges for feature (if dialog only).",
        from: from,
        group: "selectFeature",
        event: "selectFeature",
        payload: { feature },
      });
      this.widget.set({ feature, component, props });
      unit.passed({ testId: widgetId }, from);
      return;
    }
  } catch (error) {
    unit.failed({ testId: testId }, from);
    errors.error(true, error, "~279");
    return;
  }
}

export function getFeature(feature = null, from = "~284") {
  unit.report({
    method: "getFeature",
    from: from,
  });
  return this.features[feature];
}

export function getFeatureValue(value, from = "~292") {
  unit.report({
    method: "getFeatureValue",
    from: from,
    artifact: true,
  });
  return this.features[this.state.feature][value] || "";
}

export function getFeatureList(from = "~301") {
  unit.report({
    method: "getFeatureList",
    from: from,
    message: "Feature list should be shown in the feature menu in the bottom right and possibly elsewhere",
    test: "User should see a list of features available to them based on the package selected or purchased.",
    steps: [
      "From workspace",
      "Open feature menu",
      "Open features menu in bottom left",
      "Confirm all features are displayed properly",
    ],
  });
  return this.ui;
}

export function getFeatureData(feature = null, from = "~317") {
  unit.report({
    method: "getFeatureData",
    from: from,
    message: "Returns the proper data for the feature selected.",
    test: "The feature selected by the use should show the data that the user haas saved in the past.",
    steps: ["From workspace", "Assure data loads correctly", "Changes are saved during update"],
  });
  try {
    if (!this.features) return null;
    if (!feature) {
      let tmp = {};
      Object.keys(this.state.project.features).forEach((feature) => {
        tmp[feature] = this.state.project.features[feature].data;
      });
      return tmp;
    }
    if (this.state.project.features[feature] && this.state.project.features[feature].data) {
      return this.state.project.features[feature].data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error, "~340");
    return null;
  }
}

// This looks like it should involve billing stuff
export function getFeatureAvailable(feature = null, from = "~346") {
  unit.report({
    method: "getFeatureAvailable",
    message: "Checking if this feature is available to the user.",
    test: "Select different package to make some features unavailable to user.",
    from: from,
    steps: [
      "From workspace",
      "Select the features menu from bottom right",
      "Select add features from bottom",
      "Assure the feature is added without drama",
    ],
  });
  return true;
}

export function getFeatureSelected(feature = null, from = "~362") {
  unit.report({
    method: "getFeatureSelected",
    message: "Checking if this feature is available to the user.",
    from: from,
  });
  if (feature !== null) return this.state.settings.feature == feature;
  return this.state.settings.feature;
}

export function getFeatures() {
  return this.features;
}
