/**
 * From (media/admin)
 *
 * @format
 */

export function getFrom(from) {
  this.unit.report({
    method: "getFrom",
  });
  try {
    if (!from) return this.state.from;
    return from == this.state.from;
  } catch (e) {
    return false;
  }
}

export function setFrom(from = "media") {
  this.unit.report({
    method: "setFrom",
  });
  this.setState({ from: from });
}
