/** @format */
import { useMemo } from "react";

import { Box, Divider, LinearProgress, Typography } from "@mui/material";

import { useWorkspace } from "@Workspace/Context";
import { useLocales } from "locales";

import Steps from "./components/Steps";

export default function Feature(props) {
  // Get the languages
  const { t } = useLocales();
  const workspace = useWorkspace();

  const { feature } = props;

  const progress = useMemo(() => workspace.progress(feature.slug), [workspace, feature.slug]);

  /**
   * Determines the color based on the progress percentage of a feature.
   *
   * @returns {string} - Returns one of the following color strings:
   *   - "info" if the progress is less than 33%
   *   - "secondary" if the progress is less than 66%
   *   - "primary" if the progress is less than 99%
   *   - "success" if the progress is 99% or more
   */
  const color = useMemo(() => {
    if (progress.percent < 33) return "info";
    if (progress.percent < 66) return "secondary";
    if (progress.percent < 99) return "primary";
    return "success";
  }, [progress.percent]);

  // return it
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Box component="h5" sx={{ marginTop: "0px" }}>
          {t(feature.title)}
        </Box>
        <Box
          sx={{
            display: "flex",
            marginBottom: "20px",
            alignItems: "center",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              sx={{ height: "10px", borderRadius: "3px" }}
              variant="determinate"
              color={color}
              value={progress.percent || 0}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${progress.percent || 0}%`}</Typography>
          </Box>
        </Box>
      </Box>
      <Steps {...props} />
      <Box sx={{ padding: "5%" }}>
        <Divider />
      </Box>
    </>
  );
}
