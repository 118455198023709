/** @format */

export default function Layout(grid) {
  let images = [];

  if (grid.length == 1) {
    images = [
      {
        rows: 2,
        cols: 3,
      },
    ];
  } else if (grid.length == 2) {
    images = [
      {
        rows: 1,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
    ];
  } else if (grid.length == 3) {
    images = [
      {
        rows: 2,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
    ];
  } else if (grid.length == 4) {
    images = [
      {
        rows: 1,
        cols: 3,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
    ];
  } else if (grid.length == 5) {
    images = [
      {
        rows: 1,
        cols: 3,
      },
      {
        rows: 1,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 2,
      },
    ];
  } else if (grid.length == 6) {
    images = [
      {
        rows: 1,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 4,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
    ];
  } else if (grid.length == 7) {
    images = [
      {
        rows: 1,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
    ];
  } else if (grid.length == 8) {
    images = [
      {
        rows: 2,
        cols: 1,
      },
      {
        rows: 1,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 2,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 3,
      },
    ];
  } else if (grid.length == 9) {
    images = [
      {
        rows: 3,
        cols: 1,
      },
      {
        rows: 1,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 2,
        cols: 1,
      },
      {
        rows: 2,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 2,
      },
    ];
  } else if (grid.length == 10) {
    images = [
      {
        rows: 3,
        cols: 1,
      },
      {
        rows: 1,
        cols: 2,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 2,
        cols: 1,
      },
      {
        rows: 2,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
      {
        rows: 1,
        cols: 1,
      },
    ];
  }
  try {
    grid.forEach((image, i) => {
      images[i].src = image;
    });
  } catch (erroe) {}
  return images;
}
