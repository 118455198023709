/** @format */
import Cookies from "cookies";

// Default zeroed out position
const zeroed = { x: 0, y: 0 };

// Set the cookie based on the feature
export function cookie() {
  return "workspace-draggable-" + this.feature.selected() + "-" + this.id;
}

export function getRepositionAvailable() {
  try {
    return this.state.reposition;
  } catch (_) {
    return false;
  }
}

export function setRepositionAvailable(state = true) {
  try {
    if (state !== this.state.reposition) this.setState({ reposition: state });
  } catch (_) {}
}

export function getReposition() {
  try {
    // console.log(Cookies.get(cookie(workspace)), "~28");
    return this?.state?.position || zeroed;
    // return JSON.parse(Cookies.get(cookie.call(this))) || this?.state?.position || zeroed;
  } catch (_) {
    return this?.state?.position || zeroed;
  }
}

export function setReposition(_, { x, y }) {
  try {
    Cookies.set(cookie.call(this), JSON.stringify({ x: x, y: y }));
    this.setState({ position: { x: x, y: y } });
  } catch (_) {
    console.error(_, "~41");
  }
}

export function resetReposition() {
  try {
    Cookies.set(cookie.call(this), JSON.stringify(zeroed));
  } catch (_) {}
}
