/** @format */

// import throttle from "lodash/throttle";
import connect from "./helpers/connect";
import disconnect from "./helpers/disconnect";
import getProfileCompletion from "./helpers/getProfileCompletion";
import setUser from "./helpers/setUser";

export default class Data {
  constructor({ onUpdated }) {
    this.onUpdated = onUpdated;
    //this.setUser = throttle(this.setUserThrottled, 100);
    return this;
  }

  connect = (user) => {
    return new Promise((resolve) => {
      if (user.uid) {
        this.connection = connect(user, this.onUpdated)
          .then(() => resolve(true))
          .catch((error) => {
            throw error;
          });
        return this.connection;
      } else {
        throw error;
      }
    });
  };

  disconnect = () => {
    disconnect(this.connection);
  };

  setUser = setUser;

  // setUserThrottled = (user, create = false) => {
  //   return setUser(user, create);
  // };

  getProfileCompletion = getProfileCompletion;
}
