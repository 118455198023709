/** @format */

import { Badge, Box, List, ListItem, ListItemAvatar, ListItemText, alpha, styled } from "@mui/material";
import classnames from "classnames";
import parse from "html-react-parser";
import key from "key";
import { useLocales } from "locales";
import { useProfiles } from "profiles";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import Timeago from "timeagoml";
import { Avatar as UserAvatar } from "user";
import { useChat } from "../../../../../../context";

/**
 * A styled component that represents a chat box.
 *
 * @component
 */
const StyleBox = styled(Box)(({ theme }) => ({
  "& .chat": {
    cursor: "pointer",
    backgroundColor: alpha(theme.palette.info.main, 0.05),
    ":hover": {
      backgroundColor: alpha(theme.palette.info.main, 0.075),
    },
  },
  "& .chat.highlight": {
    backgroundColor: alpha(theme.palette.info.main, 0.1),
    ":hover": {
      backgroundColor: alpha(theme.palette.info.main, 0.2),
    },
  },
}));

/**
 * Renders a list of chats.
 *
 * @returns {JSX.Element} The Chats component.
 */
const Chats = () => {
  const chat = useChat();
  const profiles = useProfiles();
  const { t } = useLocales();
  const { chats } = chat;

  return (
    <StyleBox className={classnames(chat.id ? "selected" : "")}>
      <List>
        {chats.list().map((c, i) => (
          <ListItem
            {...key("chat", "chats", i)}
            className={classnames("chat", !chats.seen.get(c) && "highlight")}
            onClick={() => chats.select(c.id)}
          >
            <ListItemAvatar className="avatars" style={{ margin: 10 }}>
              {c.users.length === 1 ? (
                <UserAvatar profileId={c.users[0]} variant="rounded" showInitials={true} />
              ) : (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <UserAvatar
                      profileId={c.users[1]}
                      sx={{ width: 22, height: 22 }}
                      variant="square"
                      showInitials={true}
                    />
                  }
                >
                  <UserAvatar profileId={c.users[0]} variant="rounded" showInitials={true} />
                </Badge>
              )}
            </ListItemAvatar>
            <ListItemText
              component="div"
              style={{ margin: 10 }}
              primary={<Box className="names">{c.users.map((userId) => profiles.name.first(userId)).join(", ")}</Box>}
              secondary={
                <>
                  <Box sx={{ padding: "10px 0 20px" }}>
                    {c.messages.length > 0 ? (
                      <LinesEllipsis
                        component="span"
                        text={parse(c.messages.at(-1).message)}
                        maxLine="3"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    ) : (
                      <Box>{t("nothingSentYet")}</Box>
                    )}
                  </Box>
                  <Box sx={{ fontSize: ".6em" }}>
                    <Timeago timestamp={c.messages.at(-1)?.timestamp?.updated} />
                  </Box>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </StyleBox>
  );
};

export default Chats;
