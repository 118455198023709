/**
 * Process
 *
 * @format
 */

async function process({ mediaId, files, fx = "tr" }) {
  const media = await this.getMedia(mediaId);

  return new Promise(async (resolve, reject) => {
    try {
      const newMedia = {
        ...media,
        ...{
          files: files,
          save: false,
        },
      };

      this.upload(newMedia).then((url) => {
        this.update({
          ...media,
          processed: {
            ...media.processed,
            [fx]: {
              complete: true,
              messages: ["Complete."],
              url: url,
            },
          },
        }).then(() => {
          resolve({ id: mediaId, url: url });
        });
      });
    } catch (error) {
      reject(error);
    }
  });
}

export { process };
