/** @format */

import { Functional } from "unit";

import Trims from "../../config/trims/sizes.json";

const unit = Functional.unit("Project");

export function setTrim(props, from = "~9") {
  unit.report({
    method: "setTrim",
    payload: props,
    from: from,
    test: "Set the trim size.",
  });

  const { workspace, performing } = this.props;
  const data = this.data();

  // Set that we're reloading the stuff
  performing.set.loading(true);

  try {
    return new Promise(async (resolve) => {
      // Update this project
      await this.project.update({ data: { ...data, trim: props }, ai: 0, note: "Updated trim." }, "~26");

      // Delete the old dimensions
      await this.editor.dimensions.delete(workspace.id);

      // Restart the editor setup
      await this.editor.setup().then(() => {
        // resize the new editor with the new setup information
        this.editor.resize();
      });

      // Loading
      performing.set.loading(false);

      // Let the world know we did good stuff here
      performing.set.updating("success");

      // Good to go
      resolve(true);
    });
  } catch (error) {
    const { errors, t } = this.props;
    performing.set.loading(false);
    errors.open(t("unexpectedError"), error, "~49");
    reject(error);
  }
}

export function getTrim() {
  try {
    return Trims.filter((trim) => trim.slug === this.state.data.trim)[0];
  } catch (_) {
    return null;
  }
}
