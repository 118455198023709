/**
 * A module for handling cookies using localStorage.
 *
 * @format
 * @module Cookies
 */
import isSSR from "isssr";

/**
 * Sets a cookie value in localStorage.
 * @function
 * @param {string} key - The key to set the cookie value for.
 * @param {string} value - The value to set for the cookie.
 * @returns {string} The value that was set for the cookie.
 */
const Cookies = {
  set: function (key, value) {
    if (isSSR) return;
    localStorage[key] = value;
    return localStorage[key];
  },

  /**
   * Gets a cookie value from localStorage.
   * @function
   * @param {string} key - The key to get the cookie value for.
   * @param {any} [defaultValue=undefined] - The default value to return if the cookie is not found.
   * @param {boolean} [silent=true] - Whether to throw an error if the cookie is not found.
   * @returns {any} The value of the cookie, or the default value if the cookie is not found.
   * @throws {string} If the cookie is not found and silent is false.
   */
  get: function (key, defaultValue = undefined, silent = true) {
    if (isSSR) return;

    var value = localStorage[key] || defaultValue;

    if (!silent && !value) throw key + " not found in localStorage";

    return value;
  },

  /**
   * Sets an object as a cookie value in localStorage.
   * @function
   * @param {string} key - The key to set the cookie value for.
   * @param {object} value - The object to set as the cookie value.
   * @returns {string} The value that was set for the cookie.
   */
  setObject: function (key, value) {
    if (isSSR) return;
    localStorage[key] = JSON.stringify(value);
    return localStorage[key];
  },

  /**
   * Gets an object cookie value from localStorage.
   * @function
   * @param {string} key - The key to get the cookie value for.
   * @param {object} [defaultValue={}] - The default value to return if the cookie is not found.
   * @param {boolean} [silent=true] - Whether to throw an error if the cookie is not found.
   * @returns {object} The object value of the cookie, or the default value if the cookie is not found.
   * @throws {string} If the cookie is not found and silent is false, or if there is an error parsing the cookie value.
   */
  getObject: function (key, defaultValue = {}, silent = true) {
    if (isSSR) return;
    value = this.get(key, JSON.stringify(defaultValue), silent);

    try {
      return JSON.parse(value);
    } catch (e) {
      if (!silent) throw "Error in parsing value";
    }
  },

  /**
   * Clears all cookies from localStorage.
   * @function
   * @returns {void}
   */
  clear: function () {
    if (isSSR) return;
    return localStorage.clear();
  },

  /**
   * Removes a cookie from localStorage.
   * @function
   * @param {string} key - The key to remove the cookie for.
   * @returns {void}
   */
  remove: function (key) {
    if (isSSR) return;
    return localStorage.removeItem(key);
  },
};

try {
  if (global) {
    global.localStorage = Cookies;
    global.sessionStorage = Cookies;
  }
} catch (e) {}

export default Cookies;
