/** @format */

import { nanoid } from "nanoid";
import { Functional } from "unit";
import BioTemplate from "../../config/templates/bio.json";

const unit = Functional.unit("designer/bio");

export function addBioFromElements(props) {
  unit.report({
    method: "addBioFromElements",
    payload: props,
    action: true,
    analyze: true,
  });

  const { t, errors } = this.props;

  return new Promise((resolve, reject) => {
    try {
      const id = nanoid();
      const { top, left } = props;

      let family = this.families.get()?.[0];

      if (!family) family = "Comic";

      const element = {
        ...BioTemplate,
        id: id,
        font: { ...BioTemplate.font, family },
        style: [
          {
            ...BioTemplate.style[0],
            width: this.dimensions.interior.width * 0.4,
            height: 150,
            left: left,
            top: top,
          },
        ],
      };
      this.element.add(element).then(() => {
        this.element.select({ id });

        // calculate the size
        this.element.text.size.calculate();

        resolve();
      });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~47");
      reject(error);
    }
  });
}
