/** @format */

import { styled } from "@mui/material/styles";
import { useDrop } from "react-dnd";

const StyledDroppable = styled("div")(({ canDrop }) => ({
  backgroundColor: canDrop ? "rgba(118, 171, 123, 0.5)" : "rgba(118, 171, 123, 0.2)",
  border: canDrop ? "2px solid rgba(118, 171, 123, 1)" : "2px solid rgba(118, 171, 123, 0.8)",
}));

const Droppable = ({ children, style }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "box",
    drop: () => ({ name: "Droppable" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <StyledDroppable ref={drop} role="cell" canDrop={canDrop} isOver={isOver} style={style}>
      {children}
    </StyledDroppable>
  );
};

export default Droppable;
