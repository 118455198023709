/** @format */
import { Box, Button, Grid, Popover, Typography } from "@mui/material";
import { withCms } from "cms";
import DynamicIcon from "dynamicicon";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";
import { withRouter } from "router";
import { withUI } from "ui";

class Locales extends ReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      element: null,
    };
  }

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  toggle = (event) => {
    this.setState({ open: !this.state.open, element: event.currentTarget });
  };

  select = (locale) => {
    const { localize } = this.props.cms;
    localize(locale, "~41");
    this.close();
  };

  render() {
    const { theme, colorInvert, elevated, t } = this.props;
    const { locales } = this.props.locales;
    const disabled = false;
    const linkColor = !disabled ? (colorInvert && !elevated ? "#EEEEEE" : "#12545c") : "#DDD";
    return (
      <Box sx={{ marginLeft: "20px" }}>
        <Button
          disabled={false}
          component={"div"}
          role="button"
          display={"flex"}
          aria-describedby="locales"
          sx={{ cursor: "pointer" }}
          onClick={(e) => this.toggle(e)}
        >
          <Typography fontWeight={this.state.open ? 700 : 400} sx={{ color: linkColor }}>
            <DynamicIcon icon={"fa-language"} />
          </Typography>
        </Button>
        <Popover
          elevation={3}
          anchorEl={this.state.element}
          open={this.state.open}
          onClose={this.close}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            ".MuiPaper-root": {
              width: "160px",
              padding: "5px",
              marginTop: "40px",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 3,
              borderBottomLeftRadius: 3,
              borderTop: `3px solid ${theme.palette.primary.main}`,
              fontSize: 12,
            },
          }}
        >
          <Grid container spacing={0}>
            {locales
              .get()
              .filter((locale) => locale.visible)
              .map((locale, i) => (
                <Grid item key={i} xs={locales.get().length > 12 ? 6 : 12}>
                  <Button
                    component={"a"}
                    onClick={() => this.select(locale.code)}
                    disabled={!locale.enabled}
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      color: "#000",
                      fontWeight: locale.selected ? 600 : 200,
                    }}
                  >
                    {t(locale.name)}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Popover>
      </Box>
    );
  }
}

export default withRouter(withUI(withLocales(withCms(Locales))));
