/**
 * Logo component that displays the company logo with optional wordmark, title, label, and progress.
 *
 * @format
 * @param {Object} props - The props object.
 * @param {boolean} [props.colorInvert=false] - Whether to invert the color of the logo.
 * @param {number} [props.zoom] - The zoom level of the logo.
 * @param {string} [props.link] - The link to redirect to when the logo is clicked.
 * @param {boolean} [props.wordmark=false] - Whether to display the wordmark.
 * @param {string} props.progress.status - The status of progress.
 * @returns {JSX.Element} - The Logo component.
 */

import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import classnames from "classnames";
import colors from "mlcolors";
import { useEffect, useState } from "react";

import isSSR from "isssr";

// All the assets we need
import Dark from "./assets/images/dark/logo.png";
import Light from "./assets/images/light/logo.png";
import Png from "./assets/images/logo.png";
import { ReactComponent as Svg } from "./assets/images/logo.svg";

import Icon from "./components/Icon";

// import hexRgb from "hex-rgb";
// import LottieLogo from "./assets/lottie.json";
// import Lottie from "lottie-react";

const Wordmark = styled(Box)(() => ({
  "&.logo.wordmark": {
    cursor: "pointer",
  },
  "&.logo .made": {
    color: colors.secondary.main,
    display: "inline",
    textDecoration: "none !important",
    fontFamily: "Comfortaa, sans-serif !important",
    marginLeft: ".1em", // standard is .05em
  },
  "&.logo .made.invert": {
    color: "#FFF",
  },
  "&.logo .live": {
    color: colors.primary.main,
    display: "inline",
    textDecoration: "none !important",
    marginLeft: ".05em",
    fontFamily: "Comfortaa, sans-serif !important",
  },
  "&.logo .live.invert": {
    color: "#FFF",
  },
  "&.logo .label, &.logo .title": { minHeight: "60px", maxWidth: "50vw" },
  "&.logo .image": { minHeight: "100px" },
  "&.logo .progress": { fontSize: "9px" },
}));

// Function to replace colors in the Lottie object
// const replaceColor = (lottieObject, targetColor, replacementColor) => {
//   const lottieString = JSON.stringify(lottieObject);

//   // Converting colors to string format for replacement
//   const targetColorString = JSON.stringify(targetColor);
//   const replacementColorString = JSON.stringify(replacementColor);

//   // Replacing the target color with the replacement color
//   const updatedLottieString = lottieString.split(targetColorString).join(replacementColorString);

//   return JSON.parse(updatedLottieString);
// };

// Function to convert hex to Lottie's RGBA format
// function lottieRgb(hexColor) {
//   const { red, green, blue, alpha } = hexRgb(hexColor, { format: "object" });
//   return [red / 255, green / 255, blue / 255, alpha ?? 1]; // Ensure alpha is set if undefined
// }

// The logo component
const Logo = ({ colorInvert = false, zoom, link = true, onClick = null, wordmark, hover = true }) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  // Wait until the font is loaded before showing hte log
  useEffect(() => {
    if (document?.fonts) {
      Promise.all([document.fonts.load("normal 16px Comfortaa")])
        .then(() => {
          setFontsLoaded(true);
        })
        .catch((error) => {
          throw error;
        });
    }
  }, []);

  if (!isSSR && !document?.fonts) return null;

  // Memoize the newColor calculation to prevent recalculation on every render
  // const newColor = useMemo(() => {
  //   return lottieRgb(colorInvert ? colors.white.main : colors.secondary.main, { format: "array" });
  // }, [colorInvert]); // Only recompute if colorInvert changes

  // Memoize the colorizedLogo calculation
  // const ColorizedLogo = useMemo(() => {
  //   return replaceColor(LottieLogo, [1, 1, 1, 1], newColor);
  // }, [newColor]); // Only recompute if newColor changes

  // I we're linking (default yes, there is a link)
  if (link && !onClick) {
    onClick = () => {
      if (!isSSR) document.location.href = "/";
    };
  } else if (!onClick) {
    onClick = () => {};
  }

  try {
    return (
      <Wordmark
        onClick={onClick}
        className="logo"
        role="presentation"
        sx={{
          textDecoration: "none",
          cursor: link ? "pointer" : "default",
          opacity: hover ? 0.5 : 1,
          ":hover": { cursor: "pointer", opacity: 1 },
        }}
        data-testid="logo"
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ zoom: zoom ? zoom * 100 + "%" : "100%" }}
        >
          <Box sx={{ width: "40px" }}>
            <Box
              component="img"
              alt="Made Live Logo"
              src={isSSR ? "https://us-central.cdn.made.live/logo.png" : colorInvert ? Light : Dark}
              sx={{ width: "70%" }}
            />
            {/* <Lottie animationData={ColorizedLogo} /> */}
          </Box>
          {wordmark && (
            <Box style={{ marginLeft: 0, visibility: fontsLoaded ? "visible" : "hidden" }}>
              <Box component="h2" className={classnames("made", colorInvert ? "invert" : "")}>
                made
              </Box>
              <Box component="h2" className={classnames("live", colorInvert ? "invert" : "")}>
                live
              </Box>
            </Box>
          )}
        </Stack>
      </Wordmark>
    );
  } catch (_) {
    return null;
  }
};

export default Logo;
export { Dark, Icon, Light, Png, Svg };
