/** @format */
import setupAi from "./AI";
import setupChecklist from "./Checklist";
import setupDesks from "./Desk";
import setupDialogs from "./Dialog";
import setupFeatures from "./Feature";
import setupIntro from "./Intro";
import setupLanguage from "./Language";
import setupProject from "./Project";
import setupShare from "./Share";
import setupTour from "./Tour";
import setupUsable from "./Usable";
import setupWidgets from "./Widget";
import setupWorkers from "./Workers";
import setupZoom from "./Zoom";

import { Functional } from "unit";

const unit = Functional.unit("Setup");

export default async function setupWorkspace(from = "~21") {
  const { performing, errors, t, ui, cms } = this.props;
  const ready = this.ready;

  // Start with some progress
  performing.set.progress({
    percent: 3,
    title: "Loading your book...",
  });

  // Add the access dialog immediately
  ui.add.dialogs(["access"]);

  // Move all the important features to be setup into a proper order
  return await new Promise(async (resolve) => {
    try {
      // Billing next (with access to features, dialogs, and project)
      let testId = unit.report({
        method: "setupProject",
        test: "Make sure the project loads as anticipated when creating or logging back in.",
        message: "Setup entire project/workspace to make sure we're good to go",
        from: "~42",
      });

      // Temporarily add the meta title
      try {
        cms.meta.set({ title: null });
      } catch (_) {}

      await setupProject
        .call(this, "~46")
        .then(() => {
          ready.set("project");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          unit.failed({ testId: testId, error: error });
          errors.error(t("errorSetup"), error, "~53");
          throw error;
        });

      performing.set.progress({
        percent: 6,
        title: "Setting up features.",
      });

      // Setup the features
      testId = unit.report({
        method: "setupFeatures",
        test: "Features error capture loading.",
        message: "setupFeatures error wrapper loading from /context/helpers/setup.js.",
        from: "~67",
      });
      await setupFeatures
        .call(this, "~70")
        .then(() => {
          ready.set("features");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          unit.failed({ testId: testId, error: error });
          errors.error(t("errorFeatures"), error, "~77");
          throw error;
        });

      performing.set.progress({
        percent: 12,
        title: "Loading dialogs.",
      });

      // Setup dialogs
      testId = unit.report({
        method: "setupDialogs",
        test: "If no error regarding dialogs not loading correctly is displayed, this feature passes.",
        message: "setupDialogs error wrapper loaded successfully.",
        passed: true,
        from: "~92",
      });
      await setupDialogs
        .call(this, "~95")
        .then(() => {
          ready.set("dialogs");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          unit.failed({ testId: testId, error: error });
          errors.error(t("errorDialogs"), error, "~102");
          throw error;
        });

      // Project
      unit.report({
        method: "setupProject",
        message: "setupProject loaded without error.",
        passed: true,
        from: "~111",
      });

      performing.set.progress({
        percent: 15,
        title: "Setting up components.",
      });

      // All is good now, we can setup the rest of the project
      testId = unit.report({
        method: "setupUsable",
        test: "If no error regarding usable not loading correctly is displayed, this feature passes.",
        message: "setupUsable error wrapper loaded successfully.",
        passed: true,
        testId: testId,
        from: "~126",
      });
      await setupUsable
        .call(this, "~129")
        .then(() => {
          ready.set("usable");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorDesks"), error, "~135");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      // The desks, menus, boards
      testId = unit.report({
        method: "setupDesks",
        test: "Desks error capture loading.",
        message: "setupDesks error wrapper loading from /context/helpers/setup.js.",
        from: "~145",
      });

      performing.set.progress({
        percent: 18,
        title: "Arranging usable desks.",
      });

      await setupDesks
        .call(this, "~154")
        .then(() => {
          ready.set("desks");
          ready.set("menus");
          ready.set("boards");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorDesks"), error, "~162");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      // Workers
      testId = unit.report({
        method: "setupAi",
        test: "AI logging should be completed.",
        message: "setupAi error wrapper loaded successfully.",
        passed: true,
        from: "~173",
        testId: testId,
      });

      performing.set.progress({
        percent: 21,
        title: "Setup AI logging.",
      });

      await setupAi
        .call(this, "~183")
        .then(() => {
          ready.set("ai");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorAi"), error, "~189");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      // Workers
      testId = unit.report({
        method: "setupWorkers",
        test: "If no error regarding desks not loading correctly is displayed, this feature passes.",
        message: "setupDesks error wrapper loaded successfully.",
        passed: true,
        from: "~200",
        testId: testId,
      });

      performing.set.progress({
        percent: 22,
        title: "Loading workers.",
      });

      await setupWorkers
        .call(this, "~210")
        .then(() => {
          ready.set("workers");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorWorkers"), error, "~216");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      // Widgets (chat, teams, notes)
      testId = unit.report({
        method: "setupWidgets",
        test: "Check if notes or teams works as expected.",
        message: "Check if notes or teams works as expected.",
        from: "~226",
      });

      performing.set.progress({
        percent: 24,
        title: "Loading widgets.",
      });

      await setupWidgets
        .call(this, "~235")
        .then(() => {
          ready.set("widgets");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          unit.failed({ testId: testId, error: error });
          errors.error(t("errorWidgets"), error, "~242");
          throw error;
        });

      // Zoom for the desk
      testId = unit.report({
        method: "setupZoom",
        test: "Zoom error capture loading.",
        message: "setupZoom error wrapper loading from /context/helpers/setup.js.",
        from: "~251",
      });

      performing.set.progress({
        percent: 27,
        title: "Setting up zoom.",
      });

      await setupZoom
        .call(this, "~260")
        .then(() => {
          ready.set("zoom");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorZoom"), error, "~266");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      // Language (locales) support
      testId = unit.report({
        method: "setupLanguage",
        test: "Language error capture loading.",
        message: "setupLanguage error wrapper loading from /context/helpers/setup.js.",
        from: "~276",
      });

      performing.set.progress({
        percent: 30,
        title: "Setting up languages.",
      });

      await setupLanguage
        .call(this, "~285")
        .then(() => {
          ready.set("language");
          ready.set("context");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorLanguage"), error, "~292");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      // Checklist
      testId = unit.report({
        method: "setupChecklist",
        test: "Checklist error capture loading.",
        message: "setupChecklist error wrapper loading from /context/helpers/setup.js.",
        from: "~302",
      });

      performing.set.progress({
        percent: 33,
        title: "setting up checklist.",
      });

      await setupChecklist
        .call(this, "~311")
        .then(() => {
          ready.set("checklist");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorChecklist"), error, "~317");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      performing.set.progress({
        percent: 36,
        title: "Setting up sharing.",
      });

      // Sharing
      testId = unit.report({
        method: "setupShare",
        test: "Checklist error capture loading.",
        message: "setupShare error wrapper loading from /context/helpers/setup.js.",
        from: "~332",
      });
      await setupShare
        .call(this, "~335")
        .then(() => {
          ready.set("share");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorShare"), error, "~341");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      // Tour
      testId = unit.report({
        method: "setupTour",
        test: "Tour error capture loading.",
        message: "setupTour error wrapper loading from /context/helpers/setup.js.",
        from: "~351",
      });

      performing.set.progress({
        percent: 42,
        title: "Setting up tours.",
      });

      await setupTour
        .call(this, from)
        .then(() => {
          ready.set("tour");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorTour"), error, "~366");
          unit.failed({ testId: testId, error: error });
          throw error;
        });

      // Intros (desk features)
      testId = unit.report({
        method: "setupIntro",
        test: "Intro error capture loading.",
        message: "setupIntro error wrapper loading from /context/helpers/setup.js.",
        from: "~376",
      });
      performing.set.progress({
        percent: 45,
        title: "Setting up intro.",
      });

      await setupIntro
        .call(this, from)
        .then(() => {
          ready.set("intro");
          unit.passed({ testId: testId });
        })
        .catch((error) => {
          errors.error(t("errorIntro"), error, "~390");
          unit.failed({ testId: testId, error: error });
          throw error;
        });
      ready.set("all");
      resolve(true);
    } catch (error) {
      throw error;
    }
  });
}
