/** @format */

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "firemade";
import { nanoid } from "nanoid";
import React from "react";

// Helper functions
const generateRandomFilename = () => nanoid();

function extractExtension(filename) {
  let ext = /(?:\.([^.]+))?$/.exec(filename);
  return ext && ext[0] ? ext[0] : "";
}

// FirebaseFileUploader Component
class FirebaseFileUploader extends React.Component {
  uploadTasks = [];

  componentWillUnmount() {
    this.cancelRunningUploads();
  }

  cancelRunningUploads() {
    this.uploadTasks.forEach((task) => {
      if (task.snapshot.state === "running") {
        task.cancel();
      }
    });
    this.uploadTasks = [];
  }

  startUpload(file) {
    const { onUploadStart, storageRefPath, metadata, randomizeFilename, filename } = this.props;

    let filenameToUse = filename
      ? typeof filename === "function"
        ? filename(file)
        : filename
      : randomizeFilename
      ? generateRandomFilename()
      : file.name;

    if (!extractExtension(filenameToUse)) {
      filenameToUse += extractExtension(file.name);
    }

    const fileRef = ref(storage, `${storageRefPath}/${filenameToUse}`);
    const uploadTask = uploadBytesResumable(fileRef, file, metadata);

    if (onUploadStart) {
      onUploadStart(file, uploadTask);
    }

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        if (this.props.onProgress) {
          this.props.onProgress(progress, uploadTask);
        }
      },
      (error) => {
        if (this.props.onUploadError) {
          this.props.onUploadError(error, uploadTask);
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (this.props.onUploadSuccess) {
            this.props.onUploadSuccess(downloadURL, uploadTask);
          }
        });
      }
    );

    this.uploadTasks.push(uploadTask);
  }

  removeTask(taskToRemove) {
    this.uploadTasks = this.uploadTasks.filter((task) => task !== taskToRemove);
  }

  handleFileSelection = (event) => {
    Array.from(event.target.files).forEach((file) => this.startUpload(file));
  };

  render() {
    const { hidden, as: Input = "input", ...props } = this.props;
    const inputStyle = hidden
      ? {
          width: "0.1px",
          height: "0.1px",
          opacity: 0,
          overflow: "hidden",
          position: "absolute",
          zIndex: -1,
          ...props.style,
        }
      : props.style;

    return <Input type="file" onChange={this.handleFileSelection} style={inputStyle} />;
  }
}

// CustomUploadButton Component
const FileUploader = ({ style, className, children, htmlFor, ...inputProps }) => {
  const buttonStyle = { cursor: "pointer", ...style };

  return (
    <label style={buttonStyle} className={className} htmlFor={htmlFor}>
      {children}
      <FirebaseFileUploader hidden {...inputProps} />
    </label>
  );
};

export default FileUploader;
