/** @format */

import { Functional } from "unit";
const unit = Functional.unit("Manuscript");

export function setSetting(type, value, from = "~6") {
  unit.report({
    method: "setSettings",
    from: from,
    test: "Changing any setting should be reflected in the manuscript editor.",
    message: "Change manuscript settings.",
    passed: true,
  });
  if (this.state.spread.settings) this.state.settings[type] = value;
}

export function getSetting(type = null, from = "~17") {
  unit.report({
    method: "getSetting",
    message: "Redoing from click.",
    test: "Settings that have been set should remain even after a page reload.",
    from: from,
  });
  if (!type) return this.state.settings;
  return this.state.settings[type];
}

export function toggleSetting(type, from = "~28") {
  unit.report({
    method: "toggleSetting",
    message: "Redoing from click.",
    test: "When clicking a tag checkbox, the settings toggle on and off.",
    from: from,
    steps: ["From manuscript", "Click settings button", "Confirm settings are toggled on/off"],
  });
  let settings = { ...this.state.settings };
  settings[type] = !this.state.settings[type];
  this.setState({ settings: settings });
}
