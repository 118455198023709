/** @format */
import { Box, Button, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocales } from "locales";
import { nanoid } from "nanoid";
import { useState } from "react";
import { useTour } from "../../context";

const StyledTooltip = styled(Tooltip)(() => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#FFF",
  },
  "&.dark": {
    backgroundColor: "#97BD94",
    color: "#FFF",
    "& .MuiButton-root": {
      backgroundColor: "#97BD94",
      color: "#FFF",
    },
    "& .MuiTooltip-tooltip": {
      backgroundColor: "#97BD94 !important",
      color: "#FF0000",
    },
  },
  "& .unpad": {
    backgroundColor: "#FF0",
  },
  "& .unpad > div": {
    padding: 0,
  },
}));

export default function Tip(props) {
  const [tips, setTips] = useState([]);
  const { t } = useLocales();
  const { running, next, stop, complete } = useTour();
  const { tour, title, placement } = props;
  const name = props.name || tour.name || nanoid();

  let { highlight } = props;

  if (!title) return <></>;

  const open = name == tour ? true : null;

  if (highlight === undefined) highlight = true;

  return (
    <StyledTooltip
      tour={tour}
      arrow={false}
      open={open || false}
      placement={placement}
      disableHoverListener={tips.includes(name)}
      onClose={() => {
        !open ? setTips([...tips, name]) : () => {};
      }}
      title={
        <Box>
          <Box sx={{ padding: "20px" }}>{title}</Box>
          {running && (
            <Box
              sx={{
                textAlign: "right",
                borderTop: "1px solid #12121211",
                padding: "5px",
                backgroundColor: "#EEEEEE11",
              }}
            >
              {/* {!complete && ( */}
              <Button color="warning" size="small" variant="outlined" onClick={stop} sx={{ marginRight: "10px" }}>
                {t("exitTour")}
              </Button>
              {/* )} */}
              <Button color="success" size="small" variant="contained" onClick={next}>
                {!complete() ? <>{t("next")}</> : <>{t("done")}</>}
              </Button>
            </Box>
          )}
        </Box>
      }
      PopperProps={{
        className: "unpad",
        sx: {
          "&": {
            zIndex: 4000,
          },
          "& .MuiTooltip-tooltip": {
            backgroundColor: "#FFF",
            color: "#121212",
            fontSize: "14px",
            boxShadow:
              "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.06)",
          },
          "& .MuiTooltip-arrow": {
            color: "#FFF",
            fontSize: "14px",
            padding: "20px",
          },
          "MuiTooltip-tooltip": {
            backgroundColor: "#FF0",
          },
          "> div": { padding: 0, margin: 0 },
        },
      }}
      className={open && highlight ? "dark" : ""}
    >
      {props.children}
    </StyledTooltip>
  );
}
