/** @format */
import { Box } from "@mui/material";
import { TinyError } from "errors";
import key from "key";
import { Component as ReactComponent, Suspense } from "react";
import { withWorkspace } from "workspace";

class Widgets extends ReactComponent {
  render() {
    const { workspace } = this.props;

    // Returns an object in the format {feature:{component1:{...},component2:{...}}}
    const activeWidgets = workspace.widget.get();

    return (
      <Box data-component="widget">
        {Object.keys(workspace.widgets).map((feature) =>
          // // Loop through each feature and its components to see if the widget is active
          Object.keys(workspace.widgets[feature]).map((component) => {
            const Component = workspace.widgets[feature][component];
            let props = { workspace };
            try {
              props = { ...props, ...activeWidgets[feature][component].props };
            } catch (_) {}
            return (
              <TinyError {...key("workspace", "widgets", "dynamic", feature, component)}>
                <Suspense fallback={null}>
                  <Component {...props} />
                </Suspense>
              </TinyError>
            );
          })
        )}
      </Box>
    );
  }
}

export default withWorkspace(Widgets);
