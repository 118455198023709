/** @format */
// # TODO - this could be moved to dialogs (for consistency)

import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";
import { DialogError, TinyError } from "errors";
import key from "key";
import { withLocales } from "locales";
import { Loader } from "performing";
import { Component as ReactComponent, Suspense, cloneElement, lazy } from "react";
import { Panels, Tab, Tabs, withUI } from "ui";
import { withUnit } from "unit";
import { withUser } from "user";

import { defaultStatus } from "../../config/index.json";

// This provides an absolute fallback (edgecase)
import { AccessDenied } from "security";

// The other components
const Subscription = lazy(() => import("../Subscription"));
const Cards = lazy(() => import("../Cards"));
const Invoices = lazy(() => import("../Invoices"));
const Cancel = lazy(() => import("../Cancel"));
const Restart = lazy(() => import("../Restart"));

// Billing problems

// Migrate from beta to billing
const Update = lazy(() => import("../Update")); // pdate billing information
const Activate = lazy(() => import("../Activate")); // Activate a subscription

// Tab them out
const SubscriptionPanel = {
  key: "subscription",
  icon: "fa-money-bill",
  disabled: false,
  component: <Subscription />,
};

const CardsPanel = {
  key: "cards",
  icon: "fa-credit-card",
  disabled: false,
  component: <Cards />,
};

const InvoicesPanel = {
  key: "invoices",
  icon: "fa-receipt",
  disabled: false,
  component: <Invoices />,
};

const CancelPanel = {
  key: "cancel",
  icon: "fa-door-open",
  disabled: false,
  component: <Cancel />,
};

const RestartPanel = {
  key: "restart",
  icon: "fa-play",
  disabled: false,
  component: <Restart />,
};

// Updating from beta

const UpdatePanel = {
  key: "update",
  icon: "fa-money-bill",
  disabled: false,
  component: <Update />,
};

const ActivatePanel = {
  key: "inactive",
  icon: "fa-money-bill",
  disabled: false,
  component: <Activate />,
};

const StyledDialog = styled(Dialog)(() => ({ "&": { minWidth: "800px" } }));

class BillingDialog extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new("billing/dialog");

    this.tabs = [];
    this.state = { ready: false, status: null };

    // tracking error code changes (to force a rest)
    this.code = null;
  }

  componentDidUpdate() {
    const { ui, code, user, from } = this.props;

    if (!user) return;

    // ["incomplete", "incomplete_expired", "trialing", "active", "past_due", "canceled", "unpaid"]

    const status = user?.billing("status") || defaultStatus;

    let cancelPending = false;

    // Check if we have a pending cancellation
    try {
      cancelPending = user?.billing("timestamp")?.canceled?.at > 0;
    } catch (_) {}

    // Check if the billing was ever setup (beta migration)
    if (code && code == "billing_setup") {
      this.tabs = [UpdatePanel];

      // Check if the subscription was ever paid for (beta migration)
    } else if ((code && code == "billing_inactive") || status == "inactive") {
      this.tabs = [ActivatePanel];

      // If the user paused their billing (offline)
    } else if (code && code == "billing_paused") {
      this.tabs = [SubscriptionPanel, CardsPanel, InvoicesPanel];

      // Any other problems
    } else if (
      (code &&
        [
          "billing_incomplete",
          "billiing_incomplete_expired",
          "billing_past_due",
          "billing_unpaid",
          "billing_canceled",
          "billing_canceled_active", // custom to make active cancellations still work
        ].includes(code)) ||
      status == "canceled" ||
      cancelPending
    ) {
      this.tabs = [RestartPanel, CardsPanel, InvoicesPanel];

      // There are no billing problems, show all
    } else {
      this.tabs = [SubscriptionPanel, CardsPanel, InvoicesPanel, CancelPanel];
    }

    // we're ready to go
    if (!this.state.ready) this.setState({ ready: true, status: status });

    // This will re-render the object
    if (this.state.status != status) {
      ui.tab.set({ "billing/dialog": 0 });
      this.setState({ status: status });
    }

    // Check if tabs have changed at all
    if (from == "problem" && code && code != this.code) {
      this.forceUpdate();
      this.code = code;
    }
  }

  render() {
    // Opened and from are passed from the intermidiary components
    const { ui, user, t, disabled = false, opened, from } = this.props;

    // An absolute fallback in the event the user is not logged in
    if (!user?.authenticated) return <AccessDenied {...this.props} code="user_missing" />;

    return (
      <StyledDialog maxWidth="md" open={opened} onClose={ui.dialog.close}>
        <DialogTitle sx={{ marginBottom: 0 }}>
          <span>{t("billing")}</span>
          {from == "settings" && (
            <IconButton variant="close" disabled={disabled} onClick={ui.dialog.close} size="small">
              <DynamicIcon icon="fa-times" />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ padding: 0, minHeight: "200px", minWidth: "300px" }}>
          {!this.state.ready ? (
            <Box sx={{ padding: "10%", minWidth: "300px", minHeight: "300px" }}>
              <Loader label={<small>{t("oneMomentPlease")}</small>} />
            </Box>
          ) : (
            <Box sx={{ minHeight: from == "settings" ? "500px" : "auto" }}>
              {this.tabs.length > 1 && (
                <>
                  <TinyError>
                    <Tabs slug="billing/dialog">
                      {this.tabs.map((tab, index) => (
                        <Tab
                          {...key("billing", "dialog", "tab", index)}
                          slug="billing/dialog"
                          variant="button"
                          size="small"
                          key={tab.key}
                          icon={tab.icon}
                          label={<label>{t(tab.key)}</label>}
                          disabled={tab.disabled}
                          index={index}
                        />
                      ))}
                    </Tabs>
                  </TinyError>
                  <Divider />
                </>
              )}
              <Box sx={{ padding: "20px" }}>
                <DialogError>
                  <Panels slug="billing/dialog">
                    {this.tabs.map((tab, index) => (
                      <TinyError {...key("billing", "dialog", "panel", index)}>
                        <Suspense fallback={null}>{cloneElement(tab.component, { ...this.props })}</Suspense>
                      </TinyError>
                    ))}
                  </Panels>
                </DialogError>
              </Box>
            </Box>
          )}
        </DialogContent>
      </StyledDialog>
    );
  }
}

export default withUnit(withUser(withUI(withLocales(BillingDialog))));
