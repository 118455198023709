/** @format */
import Text from "@Web/Blocks/Text";
import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { Box, Hidden } from "@mui/material";
import { withCms } from "cms";
import Form from "getresponse";
import { Hero, Partners } from "./components";

const PortfolioMasonry = (props) => {
  return (
    <Main colorInvert={true}>
      <Hero />
      <Container>
        <Partners />
      </Container>
      <Box style={{ margin: "2.5% 10% 10%" }}>
        <Hidden smDown>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box
              flexGrow={1}
              flexBasis={{ xs: "100%", sm: "48%" }}
              marginBottom={{ xs: 2, sm: 0 }}
              paddingRight={{ xs: 0, sm: 5 }}
            >
              <Text {...props} />
            </Box>
            <Box flexGrow={1} flexBasis={{ xs: "100%", sm: "48%" }}>
              <Form from="partners" />
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Text {...props} />
          <Form from="partners" />
        </Hidden>
      </Box>
    </Main>
  );
};

export default withCms(PortfolioMasonry);
