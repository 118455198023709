/** @format */
import TextGrid from "@Web/Blocks/TextGrid";
import Container from "@Web/Container";
import { Box } from "@mui/material";
import { withLocales } from "locales";

const FAQ = (props) => {
  const { t } = props;
  return (
    <Container className="gradient invert" style={{ textAlign: "center", padding: "0 2%" }}>
      <h2 style={{ padding: "2% 0" }}>{t("homeFaq")}</h2>
      <Box sx={{ width: "100%" }} data-testid="">
        <TextGrid {...props} number={1} />
      </Box>
    </Container>
  );
};

export default withLocales(FAQ);
