/** @format */

import cloneDeep from "lodash/cloneDeep";
import { Functional } from "unit";
import Blank from "../../assets/images/blank.png";

const unit = Functional.unit("Manuscript");

export async function setupSpreads(from = "~9") {
  let testId = unit.report(
    {
      method: "setupButtons",
      from: from,
      analyze: true,
      action: true,
      test: "Manuscript spreads should be setup properly.",
      steps: ["From manuscript", "Confirm spreads load as expected"],
    },
    "~19"
  );
  return new Promise((resolve, reject) => {
    try {
      unit.passed({ testId: testId });
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error });
      reject(error);
    }
  });
}

export function addSpread(from = "~32") {
  let testId = unit.report(
    {
      method: "getSpreads",
      test: "A list of spreads available with thumbnail should be accessible from the spreads menu button at the bottom.",
      from: from,
      message: "Adding a spread should simple add a spreadbreak.",
      steps: ["From manuscript", "Click com+enter", "New spread should be added and selected"],
    },
    "~41"
  );
  try {
    const { workspace } = this.props;

    let d = cloneDeep(this.props.data);

    let e = [
      {
        type: "paragraph",
        children: [{ text: "Start with your first great idea..." }],
      },
    ];

    d.spreads.push(e);
    d.thumbs.push({ url: Blank });

    workspace.update.feature(
      {
        feature: "manuscript",
        data: d,
        note: "Added new page",
        performing: true,
        ai: 0,
      },
      "~66"
    );

    setTimeout(() => {
      document.location.href = "#" + (d.spreads.length - 1);
    }, 100);

    unit.passed({
      testId: testId,
      from: "~75",
    });
  } catch (error) {
    unit.failed({
      testId: testId,
      error: error,
      from: "~81",
    });
    this.props.onError(
      "It seems we are having problems with this feature at the moment. Please allow us some time to fix this feature.",
      error,
      "~86"
    );
  }
}

export function getSpreadName(index, from = "~91") {
  unit.report({
    method: "name",
    message: "Should return the proper spread name.",
    test: "The spreads popup from the bottom menu should show the proper name of the spreads in the action bar.",
    from: from,
  });
  let spread = index != null ? index : this.state.spread.number;
  if (spread == 0) {
    return "Front & Back";
  } else if (spread == 1) {
    return "Inside Jacket ";
  } else {
    return "Spread " + (spread + 1);
  }
}

export function getSpreadNumber(index, from = "~108") {
  unit.report({
    method: "getSpreadNumber",
    from: from,
    message: "A proper spread number should be returned.",
    test: "The spreads popup from the bottom menu should show the proper name of the spreads in the action bar.",
    steps: ["From mansucript", "Open spreads menu", "Confirm spread numbers display and are correct"],
  });
  return index;
}

export function selectSpread(spread, from = "~119") {
  unit.report({
    method: "selectSpread",
    test: "When attempting to select a spread, the proper spread is displayed.",
    message: "Selecting a spread from the bottom spread menu should move the user to the proper spread",
    steps: ["From mansucript", "Open spreads menu", "Select a spread", "Confirm manuscript goes to correct spread"],
    from: from,
  });
  return this.selectEditor(spread);
}

export function setSpread(direction, from = "~130") {
  unit.report({
    method: "setSpread",
    test: "When a spread is selected the manuscript goes to the appropriate place.",
    message:
      "Selecting a spread from the left right button in the bottom menu should move the user to the proper spread",
    steps: ["From mansucript", "Select next/prev spread", "Confirm manuscript goes to correct spread"],
    from: from,
  });

  if (direction == "next") selectSpread(this.state.spread.number + 1);
  if (direction == "previous") selectSpread(this.state.spread.number - 1);
}

export function orderSpread(direction, from = "~144") {
  // Get the spreads
  let project = { ...this.state.project };
  let spreads = [...this.state.features.spreads[this.state.desk]];
  var to = from;

  unit.report({
    method: "orderSpread",
    message: "Adding spread.",
    payload: this.props.desk,
    test: "When ordering a spread it should be reflected on the desktop local and remotely in real time.",
    steps: ["From mansucript", "Select spreads menu", "Drag spreads to order", "Confirm ordering is saved with reload"],
    from: from,
  });

  if (direction == "up") to = from - 1;
  if (direction == "down") to = from + 1;

  let a, b;

  [a, b] = [spreads[from], spreads[to]];
  [spreads[from], spreads[to]] = [b, a];
  project.spreads[this.state.desk] = spreads;

  this.updateFeature(
    {
      project: project,
      from: "orderSpread",
      note: "Ordered spreads.",
      ai: 0,
    },
    "~175"
  );
}

export function spreadAdd() {
  return "Error";
}

export function deleteSpread(spread, confirm = false, from = "~183") {
  const { ui, broadcast } = this.props;
  if (!confirm) {
    unit.report({
      method: "deleteSpreadConfirm",
      action: true,
      analyze: true,
      from: from,
      payload: { spread: spread + "" },
      test: "When a spread is selected to be deleted a confirmation window should open. It should be closable with the X or cancel button.",
      steps: ["From mansucript", "Select spreads menu", "Delete spread", "Confirm spread is deleted with reload"],
    });
    // Open the confirm dialog
    ui.ui.confirm.open(
      {
        title: "Delete Spread",
        text: "Are you sure you wish to delete this spread?",
        dismiss: {
          onClick: () => ui.confirm.close(),
          text: "Cancel",
        },
        confirm: {
          onClick: () => this.spread.delete(spread, true),
          text: "Delete",
        },
      },
      "~209"
    );
  } else {
    unit.report({
      method: "deleteSpreadComplete",
      action: true,
      analyze: true,
      from: from,
      payload: { spread: spread + "" },
      test: "When a spread is selected to be deleted the page should be removed and reflected locally/remotely immediately.",
    });
    broadcast.send(
      {
        to: "Designer",
        method: "deleteSpread",
        props: spread,
        from: this.constructor.name,
      },
      "~227"
    );
  }
}

export function listSpreads(from = "~232") {
  unit.report(
    {
      method: "listSpreads",
      test: "A list of spreads available with thumbnail should be accessible from the spreads menu button at the bottom.",
      message: "Spreads should be listed in various places including the bottom popup.",
      from: from,
      steps: ["From mansucript", "Select spreads menu", "Confirm spreads are listed"],
    },
    "~241"
  );
  return this.props.data.spreads;
}

export function getSpreads(from = "~246") {
  unit.report(
    {
      method: "getSpreads",
      test: "A list of spreads available with thumbnail should be accessible from the spreads menu button at the bottom.",
      from: from,
      message: "Spreads should be listed in various places including the bottom popup.",
      passed: true,
    },
    "~255"
  );
  return this.props.data.spreads;
}
