/** @format */

import { Box } from "@mui/material";
import { useCms } from "cms";
import React, { useMemo } from "react";

import { TinyError } from "errors";

import parse from "html-react-parser";

import TextAction from "../TextAction";
import TextIcon from "../TextIcon";

export default function (props) {
  return (
    <TinyError>
      <Text {...props} />
    </TinyError>
  );
}

export function Text(props) {
  const {
    page: { blocks },
  } = useCms();

  // Memoize the calculation of Text and tmp based on props.number
  const { Text } = useMemo(() => {
    if (!blocks?.length) return { Text: null };
    const Text = blocks.filter((block) => block.type == "Text")[props.number - 1];

    return { Text };
  }, [props.number, blocks]);

  if (!Text) {
    return null;
  }

  return (
    <>
      <TextIcon {...Text} />
      <Box
        sx={{
          textAlign: {
            xs: "center",
            sm: "center",
            md: "left",
            lg: "left",
            xl: "left",
          },
        }}
      >
        {parse(Text.text)}
      </Box>
      <TextAction {...Text} />
    </>
  );
}
