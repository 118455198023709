/** @format */

import { Functional } from "unit";

import { setupUndos } from "./Do";
import { setupEditor } from "./Editor";
import { setupFonts } from "./Font";
import { setupKeypress } from "./Keypress";
import { setupPaste } from "./Paste";
import { setupSpreads } from "./Spread";
import { setupThumbs } from "./Thumbs";

const unit = Functional.unit("Designer");

export default async function setupDesigner(from = "~15") {
  unit.report({
    from: "~17",
    method: "setupDesigner",
    message: "Setting up feature from " + from + ".",
    test: "Workspace should begin to load showing progress as each individual component loads.",
  });
  const { performing, workspace } = this.props;
  const { ready } = this;

  // Set the zoom
  workspace.zoom.set(60);

  try {
    performing.set.progress({
      percent: 50,
      title: "Setting up the editor.",
    });

    // Setup keypress
    await setupEditor
      .call(this, "~36")
      .then(() => {
        unit.report({
          method: "errorSetupEditor",
          message: "Setting up error from this.",
          test: "Editor should load as expected.",
          from: "~42",
        });
        ready.set("editor");
      })
      .catch(() => {
        throw "errorSetupEditor";
      });

    performing.set.progress({
      percent: 56,
      title: "Setting up keyboard.",
    });

    // Setup keypress
    await setupKeypress
      .call(this, "~57")
      .then(() => {
        unit.report({
          method: "setupKeypress",
          message: "Setup the keypress successfully.",
          test: "A number of keypress event handlers should be accessible.",
          from: "~63",
        });
        ready.set("keypress");
      })
      .catch(() => {
        throw "errorSetupKeypress";
      });

    performing.set.progress({
      percent: 58,
      title: "Setting up fonts.",
    });

    // Setup the fonts
    await setupFonts
      .call(this, "~78")
      .then(() => {
        unit.report({
          method: "setupFonts",
          message: "Setup the fonts successfully.",
          test: "A number of fonts should be accessible.",
          from: "~84",
        });
        ready.set("fonts");
      })
      .catch(() => {
        throw "errorSetupFonts";
      });

    performing.set.progress({
      percent: 62,
      title: "Setting up spreads.",
    });

    // Setup the spreads
    await setupSpreads
      .call(this, "~99")
      .then(() => {
        unit.report({
          method: "setupDesigner:setupSpreads",
          message: "Setup the spreads successfully.",
          test: "The book should be ready to go now.",
        });
        ready.set("spreads");
        ready.set("guides");
      })
      .catch((error) => {
        throw "errorSetupSpreads";
      });

    performing.set.progress({
      percent: 68,
      title: "Setting up undos.",
    });

    // Undos
    await setupUndos
      .call(this, "~120")
      .then(() => {
        unit.report({
          method: "setupUndos",
          message: "Setup the undoes successfully.",
          test: "The book should be ready to go now.",
        });
      })
      .catch(() => {
        throw "errorSetupUndos";
      });

    performing.set.progress({
      percent: 74,
      title: "Setting up copy & paste.",
    });

    // Paste
    await setupPaste
      .call(this, "~139")
      .then(() => {
        unit.report({
          method: "setupPaste",
          message: "Setup the paste caputre.",
          test: "The book should be ready to go now.",
        });
      })
      .catch(() => {
        throw "errorSetupPaste";
      });

    performing.set.progress({
      percent: 80,
      title: "Setting up thumbs.",
    });

    // Thumbs
    await setupThumbs
      .call(this, "~158")
      .then(() => {
        unit.report({
          method: "setupThumbs",
          message: "Setting up the thumbs for the designers.",
          test: "Check to make sure the thumbnails for the spreads are working.",
        });
        ready.set("thumbs");
      })
      .catch(() => {
        throw "errorSetupThumbs";
      });

    ready.set("all");
  } catch (error) {
    throw error;
  }
}
