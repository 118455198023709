/** @format */

import axios from "axios";
import Report, { Setup } from "./units/report.js";

/**
 * Posts a message to a Slack channel.
 * @async
 * @param {Object} options - The options for the message.
 * @param {string} options.channel - The name of the channel to post the message to.
 * @param {string} options.message - The message to post.
 * @param {string} [options.from="unknown"] - The name of the sender.
 * @returns {Promise<string>} A promise that resolves with a success message if the message was sent successfully.
 * @throws {Error} If there was an error sending the message.
 */
const post = ({ channel = "signups", message }) => {
  return new Promise(async (resolve, reject) => {
    if (!message) reject('Missing "message" parameter.');

    try {
      const response = await axios.post(`/api/slack/post`, {
        channel: channel,
        message: message,
      });

      if (response.status !== 200) reject("Server returned an error.");

      resolve("Message sent successfully.");
    } catch (error) {
      reject(`Error sending message to Slack: ${error}`);
    }
  });
};

/**
 * Posts a message to a Slack channel with additional options.
 * @async
 * @param {Object} options - The options for the message.
 * @param {string} options.channel - The name of the channel to post the message to.
 * @param {string} options.message - The message to post.
 * @param {boolean} [options.mine=false] - Whether the message is from the current user.
 * @param {string} [options.type="default"] - The type of message.
 * @param {string} [options.replyTo] - The ID of the message to reply to.
 * @param {string} [options.media] - The URL of the media to attach to the message.
 * @returns {Promise<string>} A promise that resolves with a success message if the message was sent successfully.
 * @throws {Error} If there was an error sending the message.
 */
const channel = ({ channel, title, message, mine, type, replyTo, media }) => {
  return new Promise(async (resolve, reject) => {
    if (!channel || !message) reject("Missing channel or message.");

    try {
      const response = await axios.post(`/api/slack/channel/post`, {
        channel: channel,
        title: title,
        message: message,
        mine: mine,
        type: type,
        replyTo: replyTo,
        media: media,
      });

      if (response.status !== 200) reject("Server returned an error.");

      resolve("Message sent successfully.");
    } catch (error) {
      reject("Error sending message to Slack.");
    }
  });
};

export { Report, Setup, channel, post };
