/** @format */

import { teardownKeypress } from "./Keydown";

export async function teardown() {
  teardownKeypress.call(this);

  // Reset the editor
  this.reset();

  // # Has, looks like this is not needed or should move into teardown
  // this.props.buttons.leave(this);
}
