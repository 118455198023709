/** @format */
import config from "@Workspace/Usable/Features/Designer/config/themes.json";
import { Box, Button, Card, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useErrors } from "errors";
import Fonts from "fonts";
import key from "key";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";

const ThemeBox = styled(Box)(() => ({
  border: "1px solid rgba(12,12,12,.2)",
  borderRadius: "3px",
  marginBottom: "20px",
  minHeight: "240px",
}));

const ColorBox = styled(Box)(({ bgcolor }) => ({
  width: "100%",
  height: "40px",
  backgroundColor: bgcolor,
  margin: "1px",
}));

// Function to convert HEX color to HSV
const hexToHsv = (hex) => {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let d = max - min;
  let h;
  let s = max === 0 ? 0 : d / max;
  let v = max;

  switch (max) {
    case min:
      h = 0;
      break;
    case r:
      h = g - b + d * (g < b ? 6 : 0);
      h /= 6;
      break;
    case g:
      h = b - r + d * 2;
      h /= 6;
      break;
    case b:
      h = r - g + d * 4;
      h /= 6;
      break;
  }

  return { h: h * 360, s: s * 100, v: v * 100 };
};

// Function to get hue from a HSV color
const getHue = (hexColor) => {
  const hsvColor = hexToHsv(hexColor);
  return hsvColor.h;
};

export default function Themes({ setValue, next, values }) {
  const { t } = useLocales();
  const { warn } = useErrors();

  const [selected, setSelected] = useState(null);

  const [loadedFonts, setLoadedFonts] = useState([]);
  const [errorFonts, setErrorFonts] = useState([]);

  const [moreThemes, setMoreThemes] = useState(true);

  const allFonts = Array.from(new Set(config.flatMap((theme) => theme.fonts)));

  useEffect(() => {
    Fonts(allFonts)
      .then(() => {
        setLoadedFonts(allFonts);
      })
      .catch((error) => {
        warn(t("troubleGettingFonts"), error, "~84");
        setErrorFonts(allFonts);
      });
  }, []);

  useEffect(() => {
    if (values?.theme && values.theme.slug != selected) {
      setSelected(values.theme.slug);
    }
  }, [values?.theme]);

  const onSelect = (theme) => {
    setSelected(theme.slug);
    setValue("theme", theme);
    next();
  };

  return (
    <Card sx={{ padding: "0px 10px" }} elevation={0}>
      <h3 style={{ textAlign: "center" }}>
        {t("startWithColorAndFonts")}
        <Box sx={{ padding: "5px", fontSize: "12px", textAlign: "center" }}>{t("onboardSetupNotes")}</Box>
      </h3>
      <Grid container spacing={2}>
        {config.slice(0, moreThemes ? config.length : 3).map((theme, index) => {
          const sortedColors = [...theme.colors].sort((a, b) => getHue(a) - getHue(b));

          return (
            <Grid
              {...key("onboard", "design", "themes", "theme", theme, index)}
              item
              xs={4}
              onClick={() => onSelect(theme)}
              sx={{
                cursor: "pointer",
                opacity: selected == theme.slug ? 1 : 0.6,
                ":hover": { opacity: 1 },
              }}
            >
              <ThemeBox key={theme.name}>
                <Typography
                  variant="h6"
                  style={{
                    padding: "10px",
                    fontFamily: loadedFonts.includes(theme.fonts[0]) ? theme.fonts[0] : "inherit",
                  }}
                >
                  {theme.name}
                </Typography>
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "row", padding: "10px" }}>
                  <Box sx={{ width: "50%" }}>
                    <Grid container spacing={0}>
                      {[1, 2, 3, 4].map((_, index) => (
                        <Grid
                          {...key("onboard", "design", "themes", "theme", "grid", theme, index)}
                          item
                          container
                          spacing={0}
                        >
                          {sortedColors
                            .slice((index * (index + 1)) / 2, ((index + 1) * (index + 2)) / 2)
                            .map((color, i) => (
                              <Grid
                                {...key("onboard", "design", "themes", "theme", "color", theme, color, index)}
                                item
                                xs
                              >
                                <ColorBox bgcolor={color} />
                              </Grid>
                            ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box
                    sx={{ marginLeft: "40px", display: "flex", flexDirection: "column", alignItems: "left", flex: 1 }}
                  >
                    {theme.fonts.map((font) => (
                      <Box {...key("onboard", "design", "themes", "theme", "font", font)}>
                        {errorFonts.includes(font) ? (
                          <Typography variant="subtitle1">{t("errorLoadingFont")}</Typography>
                        ) : loadedFonts.includes(font) ? (
                          <Typography style={{ fontFamily: font, fontSize: "18px" }}>{font}</Typography>
                        ) : (
                          <Skeleton variant="rectangular" width="100%" height="100%" />
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
                {/* <Box sx={{ textAlign: "right", marginTop: "10px", padding: "10px" }}>
                  <Button
                    variant={selected == index ? "contained" : "outlined"}
                    color="success"
                    size="small"
                    onClick={() => onSelect(index)}
                  >
                    {t("select")}
                  </Button>
                </Box> */}
              </ThemeBox>
            </Grid>
          );
        })}
      </Grid>
      {!moreThemes && (
        <Box sx={{ textAlign: "center", padding: "20px" }}>
          <Button onClick={() => setMoreThemes(true)}>{t("more")}</Button>
        </Box>
      )}
    </Card>
  );
}
