/** @format */

// Open the annotator with stuff
export function openAnnotator(annotation) {
  this.setState({ annotator: annotation });
}

// Close the annotator
export function closeAnnotator() {
  this.setState({ annotator: null });
}
