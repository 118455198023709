/** @format */
import isSSR from "isssr";
import SafeEval from "safeeval";
export default function Action({ navigate }, action) {
  if (isSSR) return;
  if (/\(.*\)/.test(action)) {
    SafeEval(action, "~5");
  } else if (action.includes("#")) {
    document.location.href = action;
  } else {
    navigate(action);
  }
}
