/** @format */

import { onboard } from "@Workspace/config";
import spread from "@Workspace/Usable/Features/Designer/config/templates/spread.json";
import sizes from "@Workspace/Usable/Features/Designer/config/trims/sizes.json";
import Cookies from "cookies";
import { doc, setDoc } from "firebase/firestore"; // Import getDoc
import { db } from "firemade";
import { nanoid } from "nanoid";

export default function ({
  title = "Untitled",
  credits = "Anonymous",
  trim = "203203HC",
  spreads,
  fonts,
  colors,
  userId,
  style,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      // Basic stuff
      onboard.id = nanoid();
      onboard.userId = userId; // Use the user's ID as the onboard ID
      onboard.token = nanoid();
      onboard.revisionId = nanoid();
      onboard.sessionId = Cookies.get("sessionId") || nanoid();
      onboard.features.project.data.title = title;
      onboard.features.project.data.credits = credits;

      // Find the size object that matches the trim slug
      const size = sizes.find((size) => size.slug === trim);

      // If size is found, get the first element of the range array
      let rangeStart = null;

      if (size) {
        rangeStart = size.range[0];
      }

      // Enforce a minimum of 12 if there is a problem
      if (rangeStart < 12) {
        rangeStart = 12;
      }

      // If spreads is not defined and rangeStart is found, create spreads array with rangeStart copies of spread
      if (rangeStart != null) {
        spreads = new Array(rangeStart).fill(spread);
      }

      // Set the trim sizes
      onboard.features.storyboard.data.trim = trim;
      onboard.features.storyboard.data.fonts = fonts;
      onboard.features.storyboard.data.colors = colors;
      onboard.features.storyboard.data.spreads = spreads;

      // Assign the style for sketches or illustrations
      onboard.features.generate.data.style = style || "sketch";

      // Timestamp
      onboard.timestamp = { created: Date.now(), updated: Date.now() };

      // Use setDoc with the merge option set to true
      await setDoc(doc(db, "projects", onboard.id), onboard, { merge: true });

      resolve(onboard.id);
    } catch (e) {
      reject(e);
    }
  });
}
