/** @format */
import { Avatar, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";
import { useUser } from "../../context";

export default function AvatarComponent({ size = 24, name = null, sx = {} }) {
  const user = useUser();
  const theme = useTheme();

  if (!user?.authenticated) return null;

  const avatar = user.avatar.get();

  sx = { ...{ bgcolor: theme.palette.info.main, width: size + "px", height: size + "px" }, ...sx };

  if (name != null) name = name == true ? user?.name?.get?.first() || user?.name?.get?.initials() : name;

  return (
    <Box justifyContent={"center"}>
      <Avatar color="info" src={avatar} sx={sx} variant="rounded" alt={name} data-testid="user-avatar">
        <DynamicIcon icon="fa-user" size="2xs" />
      </Avatar>
      {name && (
        <Box sx={{ padding: "4px 0px 0px 0px", fontSize: "10px", minHeight: "13px" }}>
          <label>{name || " "}</label>
        </Box>
      )}
    </Box>
  );
}
