/** @format */
import axios from "axios";
import { withErrors } from "errors";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "firemade";
import { withLocales } from "locales";
import { withProfiles } from "profiles";
import { Component as ReactComponent, createContext, useContext } from "react";
import { withUnit } from "unit";
import { withUser } from "user";

// Helpers
import { markFollowed, markSeen } from "./helpers/Mark";

// The notification context
const NotificationsContext = createContext({});

/**
 * Notifications component.
 * @component
 */
class Notifications extends ReactComponent {
  /**
   * Constructor for the component.
   * @param {Object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.unit = this.props.unit.new(this.constructor.name);
    this.state = {
      ready: false,
      notifications: [],
      unfollowed: 0,
      unseen: 0,
    };

    //Unsubscribing from the notifications collection in the database and ready for changes.
    this.unsubscribe = () => {};

    // The connection status
    this.connected = false;

    // The mark from the helpers
    this.mark = {
      seen: markSeen.bind(this),
      followed: markFollowed.bind(this),
    };
  }

  /**
   * Connects to the notifications collection in the database and listens for changes.
   * Updates the state with new notifications and their counts.
   * @returns {Promise<boolean>} A promise that resolves to true if the connection is successful.
   */
  connect = () => {
    // create
    return new Promise(async (resolve, reject) => {
      // Make sure we're authenticated and not connected
      if (this.connected || !this.props.user.authenticated) return resolve(false);

      // Wrap this or saveness
      try {
        // Subscribe to the notifications collection
        this.unsubscribe = onSnapshot(
          query(
            collection(db, "notifications"),
            where("userId", "==", this.props.user.id),
            orderBy("timestamp.created", "desc")
          ),
          (snapshot) => {
            // Map over the document changes and construct new notifications array
            const newNotifications = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));

            // You can also perform filtering and counting here if necessary
            let unseen = newNotifications.filter((notification) => notification.seen === false).length;
            let unfollowed = newNotifications.filter((notification) => notification.followed === false).length;

            // Set the state with the new notifications array
            this.setState({
              notifications: newNotifications,
              unseen: unseen,
              unfollowed: unfollowed,
            });
          }
        );

        // Get the ready
        this.setState({ ready: true });

        // set that we're connected, so we don't connect again
        this.connected = true;

        // tell the world
        return resolve(true);
      } catch (error) {
        // return an error if we passed on in
        this.onError(error);

        // tell the world
        return reject(error, "~113");
      }
    });
  };

  /**
   * Disconnects from the notification service.
   */
  disconnect = () => {
    if (this.unsubscribe && typeof this.unsubscribe === "function") {
      // unsubscribe
      this.unsubscribe();

      // set that we're not connect
      this.connected = false;

      // set that we're
      this.setState({ ready: false });
    }
  };

  /* Handlers */

  /**
   * Handles the onConnected event.
   *
   * @param {string} [from="~139"] - The source of the event.
   */
  onConnected = () => {
    // if connected with a callback add it
    if (this.props.onConnected) this.props.onConnected();
  };

  /**
   * Handles error messages.
   * @param {Object} error - The error object.
   * @param {string} error.message - The error message.
   * @param {string} [error.from="~159"] - The source of the error.
   */
  onError = ({ message }) => {
    if (this.props.errors.error) this.props.errors.error(this.props.t("unexpectedError"), message);
  };

  /**
   * Adds a notification to the collection.
   * @param {Object} params - Parameters for the notification.
   * @returns {Promise<DocumentReference>} A promise that resolves with the DocumentReference of the newly added document.
   */
  notify = ({
    userId,
    title,
    message,
    action,
    link,
    icon,
    seen = false,
    followed = false,
    sent = new Date(),
    formats,
    attachments,
    meta,
  }) => {
    // get the user token
    const { token } = this.props.user;

    return new Promise(async (resolve, reject) => {
      try {
        // get the current user token (to allow or sharing)
        const userToken = token.get();

        // Make sure the user is provided
        if (!userId) return reject(new Error("No user provided."));

        // Make sure we have a title or a message
        if (!title && !message) return reject(new Error("No message or title provided."));

        // Make sure we have an action or a link
        if (!action && !link) return reject(new Error("No action or link provided."));

        // Send it to the notication endpoint (which can do the heavy lifting)
        await axios.post("/api/notify", {
          token: userToken,
          userId,
          title,
          message,
          action,
          link,
          icon,
          seen,
          followed,
          sent,
          formats,
          attachments,
          meta,
        });

        // Resolve the promise with the document reference
        resolve(true);
      } catch (error) {
        this.onError({ message: error.message });
        reject(error);
      }
    });
  };

  /**
   * Called immediately before a component is unmounted and destroyed.
   * Disconnects from firebase and terminates the connection to the notifications server.
   */
  componentWillUnmount() {
    try {
      // disconnect this
      this.disconnect();
    } catch (error) {}
  }

  /**
   * Lifecycle method called after the component has been mounted.
   */
  componentDidMount() {
    if (this.props.user.authenticated) this.connect();
  }

  render() {
    return (
      <NotificationsContext.Provider
        value={{
          ...this.state,
          notify: this.notify,
          mark: this.mark,
        }}
      >
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}

const withNotifications = (Component) => {
  return function ContextualComponent(props) {
    return (
      <NotificationsContext.Consumer>
        {(state) => <Component {...props} notifications={state} />}
      </NotificationsContext.Consumer>
    );
  };
};

const useNotifications = () => {
  return useContext(NotificationsContext);
};

export default withUnit(withUser(withProfiles(withErrors(withLocales(Notifications)))));
export { useNotifications, withNotifications };
