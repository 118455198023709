/** @format */
import { styled } from "@mui/material/styles";
import { ComponentError } from "errors";
import { Component as ReactComponent, cloneElement } from "react";

const Root = styled("div")(() => ({
  "& .pagePanel": {
    overflowY: "scroll",
    borderRadius: "3px",
    backgroundColor: "rgba(12,12,12,.01)",
    minHeight: "100%",
    width: "100%",
    border: "1px solid rgba(12,12,12,.12)",
  },
}));

class Page extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new("Panel");
  }
  panelWithProps = (children) => {
    return cloneElement(children, {
      ...this.props,
    });
  };

  render() {
    return (
      <Root>
        <ComponentError>
          <div
            role="tabpanel"
            className="pagePanel"
            data-side={this.props.side}
            data-depth={this.props.depth}
            style={{
              padding: this.panelPadding,
            }}
            hidden={!this.props.visible}
          >
            {this.props.children && this.panelWithProps(this.props.children)}
          </div>
        </ComponentError>
      </Root>
    );
  }
}

export default Page;
