/** @format */

import BlogGridBig from "@Web/Blocks/BlogGridBig";
import Container from "@Web/Container";
import { useLocales } from "locales";

const Blog = (props) => {
  const { t } = useLocales();
  return (
    <Container data-testid="home-blog">
      <h2 align="center">{t("homeBlog")}</h2>
      <BlogGridBig {...props} start={1} end={4} width={4} />
    </Container>
  );
};

export default Blog;
