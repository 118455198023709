/** @format */
import { Box } from "@mui/material";
import Cdn from "cdn";

import { TinyError } from "errors";

const mock = [
  "https://us-central.cdn.made.live//thumbnail_20220413_Ni_B_Corporate_Member_Badge27_1_1_8b294c7851/thumbnail_20220413_Ni_B_Corporate_Member_Badge27_1_1_8b294c7851.png?2023-09-20T20:58:57.886Z",
  "https://us-central.cdn.made.live//thumbnail_Volition_transparent_logo_da14eecc42/thumbnail_Volition_transparent_logo_da14eecc42.png?2023-04-06T19:47:26.027Z",
  // "https://us-central.cdn.made.live//thumbnail_Discovery_Foundation_Stacked_Logo_RGB_f6bd733429/thumbnail_Discovery_Foundation_Stacked_Logo_RGB_f6bd733429.png?2023-04-06T20:15:30.583Z",
  "https://us-central.cdn.made.live//thumbnail_BDO_logo_svg_870d8d3fb5/thumbnail_BDO_logo_svg_870d8d3fb5.png?2023-04-06T19:45:41.563Z",
  "https://us-central.cdn.made.live//Lawson_Lundell_b6c4d928ef/Lawson_Lundell_b6c4d928ef.svg?2023-04-06T19:47:35.535Z",
  "https://us-central.cdn.made.live//thumbnail_2018_AO_Logo_horizontal_1bc3c7ce83/thumbnail_2018_AO_Logo_horizontal_1bc3c7ce83.png?2023-04-06T19:47:47.935Z",
  "https://us-central.cdn.made.live//thumbnail_1024px_Stripe_Logo_revised_2016_svg_2435dff6e0/thumbnail_1024px_Stripe_Logo_revised_2016_svg_2435dff6e0.png?2023-09-20T21:19:58.510Z",
  //"https://us-central.cdn.made.live//thumbnail_AL_Li_partner_badge_109x185_563424ee3e/thumbnail_AL_Li_partner_badge_109x185_563424ee3e.png?2023-09-20T22:05:00.144Z",
];

const Partners = () => {
  return (
    <TinyError>
      <Box display="flex" flexWrap="wrap" justifyContent={"center"} marginTop={2} marginBottom={2}>
        {mock.map((item, i) => (
          <Box maxWidth={{ xs: 80, sm: 90 }} marginTop={{ xs: 1 }} marginRight={{ xs: 3, sm: 6, md: 12 }} key={i}>
            <Box
              component="img"
              loading="lazy"
              height={1}
              width={1}
              src={Cdn(item)}
              alt="Made Live Partner"
              sx={{
                objectFit: "contain", // Maintain aspect ratio
                filter: "grayscale(100%)",
              }}
            />
          </Box>
        ))}
      </Box>
    </TinyError>
  );
};

export default Partners;
