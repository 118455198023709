/** @format */
import { Box, Drawer } from "@mui/material";
import { SidebarNav } from "./components";

const Sidebar = ({ pages, open, variant, onClose }) => {
  return (
    <Box data-testid="sidebar">
      <Drawer
        anchor="left"
        onClose={() => onClose()}
        open={open}
        variant={variant}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: 280,
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            padding: 1,
          }}
        >
          <SidebarNav pages={pages} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
