/** @format */
import config from "../../config/index.json" assert { type: "json" };

export function getLocalizedUrl(path) {
  if (!path || path == "" || path == "/") return "";

  const localeCode = this.state.locale || config.default; // Assuming locales.get() returns the required locale code

  // Check if the path starts with a locale code using regex pattern matching
  const matches = path.match(/^\/[a-z]{2}-[a-z]{2}\//);
  if (matches) {
    // If a locale code is found at the beginning of the path, replace it with the new one
    path = path.replace(matches[0], `/${localeCode}/`);
  } else {
    // If no locale code is found at the beginning, prepend the new locale code
    path = `/${localeCode}/${path}`;
  }

  // Ensure there are no double forward slashes
  path = path.replace(/\/{2,}/g, "/");

  // Remove any trailing forward slashes
  path = path.replace(/\/$/, "");

  return path;
}

export function getParamizedUrl(path) {
  if (!path || path == "" || path == "/") return "";

  const localeCode = this.state.locale || config.default; // Assuming locales.get() returns the required locale code

  // Check if the path starts with a locale code using regex pattern matching
  const matches = path.match(/^\/[a-z]{2}-[a-z]{2}\//);
  if (matches) {
    // If a locale code is found at the beginning of the path, replace it with the new one
    path = path.replace(matches[0], `/${localeCode}/`);
  } else {
    // If no locale code is found at the beginning, prepend the new locale code
    path = `/:locale/${path}`;
  }

  // Ensure there are no double forward slashes
  path = path.replace(/\/{2,}/g, "/");

  // Remove any trailing forward slashes
  path = path.replace(/\/$/, "");

  return path;
}
