/** @format */

import cloneDeep from "lodash/cloneDeep";
import { Functional } from "unit";

const unit = Functional.unit("Manuscript");

export function updateManuscript(data, note = "", from = "~8") {
  return new Promise((resolve, reject) => {
    try {
      this.props.workspace.update.feature(
        {
          feature: "manuscript",
          data: data,
          performing: false,
          ai: 0,
          note: note || "Updated manuscript.",
        },
        from
      );
      resolve(true);
    } catch (error) {
      this.props.onError("It seems we are having a problem updating the manuscript at the moment.");
      reject(error);
    }
  });
}

export function deserializeToCountWords(slateData) {
  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    return words.filter((word) => word.length > 0).length;
  };

  const countWordsInNode = (node) => {
    let wordCount = 0;
    if (node.type === "paragraph") {
      const text = node.children.map((child) => child.text).join(" ");
      wordCount += countWords(text);
    }
    if (node.children) {
      node.children.forEach((child) => (wordCount += countWordsInNode(child)));
    }
    return wordCount;
  };

  let wordCount = 0;
  slateData.forEach((block) => {
    wordCount += countWordsInNode(block);
  });

  return wordCount;
}

export function deserializeToPlainText(slateData) {
  const lineSeparator = "\n";

  const processNode = (node) => {
    let plainText = "";
    if (node.type === "paragraph" || node.type === "heading-one") {
      const text = node.children.map((child) => child.text).join("");
      plainText += text + lineSeparator;
    }
    if (node.children) {
      node.children.forEach((child) => (plainText += processNode(child)));
    }
    return plainText;
  };

  let plainText = "";
  slateData.forEach((block) => {
    plainText += processNode(block);
  });

  return plainText;
}

export function updateDraft(data) {
  try {
    let fresh = cloneDeep(this.props.data);
    fresh.draft = data;
    fresh.wordcount = deserializeToCountWords(data);
    fresh.plaintext = deserializeToPlainText(data);
    updateManuscript.call(this, fresh, null, "~107");
  } catch (error) {
    this.props.onError("It seems we are having a problem updating the manuscript at the moment.");
  }
}

export function updateLayouts(data) {
  try {
    const { workspace } = this.props;

    let d = cloneDeep(this.props.data);
    let e = cloneDeep(data);

    d.spreads[manuscript.state.spread.number] = e;

    workspace.update.feature(
      {
        feature: "manuscript",
        data: d,
        note: "Added new page",
        performing: true,
        ai: 0,
      },
      "~139"
    );
  } catch (error) {
    this.props.onError(
      "It seems we are having problems with this feature at the moment. Please allow us some time to fix this feature.",
      error,
      "~147"
    );
  }
}

export function clearManuscript(from = "~152") {
  unit.report({
    method: "clearManuscript",
    test: "When clearing the manuscript it should be entirely cleared and reduced to only one page.",
    message: "Clears a manuscript. Useful for when using a template.",
    artifact: true,
    from: from,
  });
  const { performing } = this.props;
  performing.set.loading(false, "~161");
}
