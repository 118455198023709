/** @format */
import html2canvas from "html2canvas";
import isSSR from "isssr";

/**
 * Captures a snapshot of the current page
 * @param {string} message - Optional message to display after capturing
 * @returns {Promise} Resolves when snapshot is captured and added to media
 */
export async function snapshot(message = null) {
  this.setState({ snapping: true });
  const { errors, t, snackbar } = this.props;
  return await new Promise(async (resolve, reject) => {
    try {
      if (!isSSR) {
        // Capture the entire body as an image
        const canvas = await html2canvas(document.body);
        const imgData = canvas.toDataURL("image/png");
        addMedia.call(this, imgData);
        if (message) snackbar.open(message, "info");
        resolve();
      }
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~22");
      return reject(error);
    }
    this.setState({ snapping: false });
  });
}

/**
 * Adds a new item to the media array
 * @param {*} media - The media item to add
 */
export async function addMedia(media) {
  this.setState((prevState) => ({
    media: [...prevState.media, media],
  }));
  this.props.performing.set.updating("success", "~38");
}

/**
 * Clears all media items
 */
export function clear() {
  this.setState({ media: [] });
}

/**
 * Removes a media item at the specified index
 * @param {number} index - The index of the item to remove
 */
export function remove(index) {
  let media = this.state.media;
  if (index >= 0 && index < media.length) {
    media.splice(index, 1);
  }
  this.setState({ media: media });
}
