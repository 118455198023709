/** @format */
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import capitalize from "capitalize";
import DynamicIcon from "dynamicicon";
import key from "key";
import { useProfiles } from "profiles";

import { useEffect, useState } from "react";

import { useUser } from "user";

import { useUI } from "ui";

import { useLocales } from "locales";

import { useSnackbar } from "snackbar";

import Avatar from "../Avatar";
import Name from "../Name";
import ViewButton from "../ViewButton";

const Socials = [
  { icon: "fa-facebook", field: "facebook" },
  { icon: "fa-linkedin", field: "linkedin" },
  { icon: "fa-instagram", field: "instagram" },
  { icon: "fa-envelope", field: "email" },
];

{
  /* <StyledSocials>
            <IconButton color="secondary">
              <DynamicIcon icon="fa-facebook" />
            </IconButton>
          </StyledSocials>
          <StyledSocials>
            <IconButton color="secondary">
              <DynamicIcon icon="fa-linkedin" />
            </IconButton>
          </StyledSocials>
          <StyledSocials>
            <IconButton color="secondary">
              <DynamicIcon icon="fa-instagram" />
            </IconButton>
          </StyledSocials>
          <StyledSocials>
            <IconButton color="secondary">
              <DynamicIcon icon="fa-envelope" />
            </IconButton>
          </StyledSocials> */
}

const StyledSocials = styled(Box)(() => ({
  "&": {
    width: "10%",
  },
}));

const StyledMetrics = styled(Box)(() => ({
  "&": {
    width: "25%",
    textAlign: "center",
    "& .small": { opacity: 0.5, fontWeight: "bold", fontSize: ".8em", padding: "5px" },
    "& .large": { opacity: 1, fontWeight: "bold", fontSize: "1.2em" },
  },
}));

export default function User({
  id,
  showButton = true,
  showBanner = true,
  showAvatar = true,
  showEditing = false,
  avatarSize = 80,
}) {
  const profiles = useProfiles();
  const user = useUser();
  const { t } = useLocales();
  const { open } = useSnackbar();
  const { dialog } = useUI();
  const theme = useTheme();

  const { update } = profiles;

  const [ready, setReady] = useState(false);
  const [profile, setProfile] = useState({});
  const [editing, setEditing] = useState(false);

  const [updates, setUpdates] = useState({});

  // This is not great and should be removed
  const socialValue = (field) => {
    if (field == "facebook") return updates.facebook;
    if (field == "linkedin") return updates.linkedin;
    if (field == "instagram") return updates.instagram;
    if (field == "email") return updates.email;
    return "";
  };

  // Add to the current updates
  const onUpdate = (field, value) => {
    setUpdates({ ...updates, [field]: value });
  };

  // Validation function
  const validateUpdates = () => {
    // expertise must have one value
    if (!updates.expertise || updates.expertise.length === 0) {
      open(t("profileExpertiseFailed"), "warning");
      return false;
    }

    // intro must be filled in
    if (!updates.intro || updates.intro.trim() === "") {
      open(t("profileIntroFailed"), "warning");
      return false;
    }

    // Each of the socials must have a valid url OR be entirely empty.
    for (let social of Socials) {
      const value = updates[social.field];
      if (value && !/^https?:\/\/[^ ]+$/.test(value)) {
        open(`${capitalize(social.field)} ${t("profileSocialFailed")}`, "warning");
        return false;
      }
    }

    return true;
  };

  // Apply the updates
  const applyUpdate = () => {
    // Validate the updates
    if (!validateUpdates()) {
      return;
    }

    // Save the updates
    update(updates);

    // Close that we're editing
    setEditing(false);
  };

  useEffect(() => {
    const response = profiles.get(id);
    if (response.ready) {
      // Set that this component is ready
      setReady(true);

      // Set the profile
      setProfile(response);

      // Set the updates
      setUpdates(response.profile);
    }
  }, [profiles.users]);

  // If the process is not ready, show a skeleton
  if (!ready)
    return (
      <Box sx={{ minHeight: "400px" }}>
        <Skeleton width={300} height={400} />
      </Box>
    );

  return (
    <Card sx={{ position: "relative" }}>
      {editing && <Alert severity="info">{t("profileEditNameAvatar")}</Alert>}
      {showEditing && user.id == id && (
        <Box sx={{ textAlign: "right", padding: "5px" }}>
          <IconButton sx={{ fontSize: "1em" }} onClick={() => setEditing(!editing)}>
            {!editing ? <DynamicIcon icon="fa-edit" /> : <DynamicIcon icon="fa-times" />}
          </IconButton>
        </Box>
      )}
      {showBanner && (
        <CardMedia
          sx={{ height: 140, backgroundColor: alpha(theme.palette.info.main, 0.25) }}
          image={profile.photoURL || "http://"}
        />
      )}
      {showAvatar && (
        <Box
          sx={{
            marginTop: showBanner ? "-40px" : "20px",
            fontWeight: "bold",
            cursor: editing ? "pointer" : "auto",
          }}
          onClick={editing ? () => dialog.open("settings") : () => {}}
        >
          <Avatar {...profile} size={avatarSize} link={true} />
        </Box>
      )}
      <CardContent sx={{ marginTop: "20px", textAlign: "center" }}>
        <Box
          variant="h3"
          component="div"
          sx={{
            fontWeight: "bold",
            cursor: editing ? "pointer" : "auto",
            textDecoration: editing ? "underline" : "auto",
          }}
          onClick={editing ? () => dialog.open("settings") : () => {}}
        >
          <Name {...profile} />
        </Box>
        {editing ? (
          <Autocomplete
            multiple
            options={["Author", "Illustrator", "Editor", "Designer", "Reader"]}
            value={updates.expertise || []}
            onChange={(_, value) => {
              onUpdate("expertise", value);
            }}
            renderInput={(params) => <TextField {...params} />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="contained"
                  sx={{ borderRadius: "3px" }}
                  color="info"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            sx={{ paddingTop: "10px" }}
          />
        ) : Array.isArray(updates.expertise) ? (
          <Typography variant="h7" component="div" sx={{ opacity: !editing ? 0.5 : 1 }}>
            {updates.expertise.join(", ")}
          </Typography>
        ) : (
          ""
        )}
        <Box sx={{ minHeight: "120px", padding: "20px 0px" }} variant="body2" color="text.secondary">
          {editing ? (
            <TextField
              multiline
              rows={5}
              sx={{ width: "100%" }}
              value={updates.intro}
              onChange={(event) => {
                onUpdate("intro", event.target.value);
              }}
              inputProps={{
                maxLength: 255,
              }}
            />
          ) : (
            updates.intro
          )}
        </Box>

        {!editing ? (
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            {Socials.map((social, index) => (
              <StyledSocials {...key("profiles", "socials", "view", index)}>
                <IconButton
                  color="secondary"
                  disabled={!updates[social.field]}
                  onClick={() => window.open(updates[social.field])}
                >
                  <DynamicIcon icon={social.icon} />
                </IconButton>
              </StyledSocials>
            ))}
          </Stack>
        ) : (
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            {Socials.map((social, index) => (
              <TextField
                {...key("profiles", "socials", "edit", index)}
                sx={{ width: "100%" }}
                defaultValue={socialValue(social.field)}
                onChange={(event) => {
                  onUpdate(social.field, event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DynamicIcon icon={social.icon} />
                    </InputAdornment>
                  ),
                }}
              />
            ))}
          </Stack>
        )}

        {/* <Box sx={{ padding: "20px 0", opacity: 0.5 }}>
          <Divider />
        </Box>

        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <StyledMetrics>
            <Box className="small">Followers</Box>
            <Box className="large">100</Box>
          </StyledMetrics>
          <StyledMetrics>
            <Box className="small">Followers</Box>
            <Box className="large">100</Box>
          </StyledMetrics>
          <StyledMetrics>
            <Box className="small">Followers</Box>
            <Box className="large">100</Box>
          </StyledMetrics>
        </Stack> */}

        {editing && (
          <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ textAlign: "right", padding: "20px 0" }}>
            <Button onClick={() => setEditing(false)}>{t("cancel")}</Button>
            <Button color="success" variant="contained" onClick={applyUpdate}>
              {t("apply")}
            </Button>
          </Stack>
        )}
      </CardContent>
      <Box sx={{ padding: "20px 0 0", opacity: 0.5 }}>
        <Divider />
      </Box>
      {showButton && (
        <CardActions sx={{ textAlign: "right" }}>
          <ViewButton {...profile} />
        </CardActions>
      )}
    </Card>
  );
}
