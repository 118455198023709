/** @format */

export function getPermissions(permission) {
  return permission ? this.state.permissions.includes(permission) : this.state.permissions;
}

export function setPermissions(permissions) {
  // # TODO - there's no need for this here
  // this.setState({ permissions: permissions });
}
