/** @format */
import { addDoc, collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "firemade";
import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";
import Units from "../../config/units.json";

// The place we save (cache) the dimensions
const projectDimensions = "projectDimensions";

// Log it
let log = [];

// The uncalcated baseline dimensions we need
export const Uncalculated = {
  center: { x: 0, y: 0 },
  scale: { x: 0, y: 0 },
  height: 0,
  width: 0,
  guides: { vertical: [], horizontal: [] },
  snaps: { vertical: [], horizontal: [] },
  mattes: { margins: [], bleeds: [] },
  bounds: { top: 0, bottom: 0, left: 0, right: 0 },
  rulers: { vertical: [0, 0], horizontal: [0, 0] },
};

// convert mm to px
const convert = (px) => {
  return Number(((px * Units.metric.calc) / 10).toFixed(4));
};

// Calculate each number
const calculate = (obj) => {
  let newObj = Array.isArray(obj) ? [] : {}; // Create a new array or object to hold the converted values
  for (let key in obj) {
    if (typeof obj[key] === "number") {
      newObj[key] = convert(obj[key]); // Convert the number
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      newObj[key] = calculate(obj[key]); // Recursively apply the function to objects or arrays
    } else {
      newObj[key] = obj[key]; // Copy other values as is
    }
  }
  return newObj;
};

// Remove each zeroed number
const removeZeroed = (obj) => {
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      for (let i = obj.length - 1; i >= 0; i--) {
        if (obj[i].width === 0 || obj[i].height === 0) {
          obj.splice(i, 1);
        } else {
          removeZeroed(obj[i]);
        }
      }
    } else {
      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          if (typeof obj[prop] === "object") {
            removeZeroed(obj[prop]);
          }
        }
      }
    }
  }
  return obj;
};

// Calculate the dimensions with the space (interior/exterior), trim size, spreads (for page range)
const calculateDimensions = (space, trim, spreads) => {
  let uncalculated = cloneDeep(Uncalculated);

  // Spine calculations
  let spine = trim[space].spine
    ? (trim[space].spine.find((spine) => spreads >= spine.range[0] && spreads <= spine.range[1]) || {})?.width || 0
    : 0;

  // Width calculations
  uncalculated.width = trim.dimensions[0] * 2 + trim.interior.bleeds[1] + trim.interior.bleeds[3] + spine;

  // Height calculations
  uncalculated.height = trim.dimensions[1] + trim.interior.bleeds[0] + trim.interior.bleeds[2];

  // Center calculations
  uncalculated.center.x = uncalculated.width / 2;
  uncalculated.center.y = uncalculated.height / 2;

  // Calculate the ruler offers
  uncalculated.rulers = {
    horizontal: [trim[space].bleeds[0], trim[space].bleeds[2]],
    vertical: [trim[space].bleeds[1], trim[space].bleeds[3]],
  };

  // Vertical guides
  try {
    uncalculated.guides.vertical = uniq(
      [
        // Left bleed guide
        trim[space].bleeds[3],

        // Left margin guide is margin + bleed
        trim[space].bleeds[3] + trim[space].margins[3],

        // Right gutter (if exists) is center - spine / 2 - margin
        trim[space].gutters[0] ? uncalculated.center.x - (spine > 0 ? spine / 2 : 0) - trim[space].gutters[0] : 0,

        // Add the center line on interiors
        space == "interior" ? uncalculated.center.x : 0,

        // Add the
        ...(space == "exterior"
          ? [uncalculated.center.x - (spine > 0 ? spine / 2 : 0), uncalculated.center.x + (spine > 0 ? spine / 2 : 0)]
          : []),

        // Right gutter (if exists) is center - spine / 2 - margin
        trim[space].gutters[0] ? uncalculated.center.x + (spine > 0 ? spine / 2 : 0) + trim[space].gutters[1] : 0,

        // Right margin guide is margin - bleed
        uncalculated.width - trim[space].bleeds[1] - trim[space].margins[1],

        // Right bleed guide
        uncalculated.width - trim[space].bleeds[1],
      ].filter((v) => v > 0)
    );
  } catch (error) {
    throw error;
  }

  // Horizontal guides
  try {
    uncalculated.guides.horizontal = uniq(
      [
        // Top bleed
        trim[space].bleeds[0],

        // Top margin guide is margin + bleed
        trim[space].margins[0] + trim[space].bleeds[0],

        // Bottom margin guide is margin - bleed
        uncalculated.height - trim[space].margins[2] - trim[space].bleeds[2],

        // Bottom bleed guide
        uncalculated.height - trim[space].bleeds[2],
      ].filter((v) => v > 0)
    );
  } catch (error) {
    log.push(error);
  }

  // Bounds
  try {
    uncalculated.bounds = {
      // Top bound is top margin + bleed
      top: trim[space].bleeds[0] + trim[space].margins[0],

      // Right bound is right bleed - margin
      right: uncalculated.width - trim[space].bleeds[1] - trim[space].margins[1],

      // Bottom bound is bottom bleed - margin
      bottom: uncalculated.height - trim[space].bleeds[2] - trim[space].margins[2],

      // Left bound is left margin + bleed
      left: trim[space].bleeds[3] + trim[space].margins[3],
    };
  } catch (error) {
    log.push(error);
  }

  // Bleed mattes
  try {
    uncalculated.mattes.bleeds = [
      //top
      { left: 0, top: 0, width: uncalculated.width, height: trim[space].bleeds[0] },
      // right outter
      {
        left: uncalculated.width - trim[space].bleeds[1],
        top: trim[space].bleeds[0],
        width: trim[space].bleeds[3],
        height: uncalculated.height - trim[space].bleeds[0] - trim[space].bleeds[2],
      },
      // center
      false
        ? {
            left: uncalculated.center.x - trim[space].bleeds[1],
            top: trim[space].bleeds[1],
            width: trim[space].bleeds[1] * 2,
            height: uncalculated.height - trim[space].bleeds[1] * 2,
          }
        : {},
      // bottom
      {
        left: 0,
        top: uncalculated.height - trim[space].bleeds[1],
        width: uncalculated.width,
        height: trim[space].bleeds[2],
      },
      // left outter
      {
        left: 0,
        top: trim[space].bleeds[0],
        width: trim[space].bleeds[3],
        height: uncalculated.height - trim[space].bleeds[0] - trim[space].bleeds[2],
      },
    ];
  } catch (error) {
    log.push(error);
  }

  // Margins Mattes
  try {
    uncalculated.mattes.margins = [
      // top (spread)
      {
        left: trim[space].bleeds[3],
        top: trim[space].bleeds[0],
        width: uncalculated.width - trim[space].bleeds[1] - trim[space].bleeds[3],
        height: trim[space].margins[0],
      },
      // right (spread)
      {
        left: uncalculated.width - trim[space].bleeds[1] - trim[space].margins[1],
        top: trim[space].bleeds[0],
        width: trim[space].margins[1],
        height: uncalculated.height - trim[space].margins[0] - trim[space].margins[2],
      },
      // bottom (spread)
      {
        left: trim[space].bleeds[3],
        top: uncalculated.height - trim[space].bleeds[2] - trim[space].margins[2],
        width: uncalculated.width - trim[space].bleeds[1] - trim[space].bleeds[3],
        height: trim[space].margins[2],
      },
      // left (spread)
      {
        left: trim[space].bleeds[3],
        top: trim[space].bleeds[0],
        width: trim[space].margins[3],
        height: uncalculated.height - trim[space].bleeds[0] - trim[space].bleeds[2],
      },
    ];

    // left (gutter)
    if (trim[space].gutters[0])
      uncalculated.mattes.margins.push({
        left: uncalculated.center.x - spine / 2 - trim[space].gutters[0],
        top: trim[space].bleeds[0] + trim[space].margins[0],
        width: trim[space].gutters[0],
        height:
          uncalculated.height -
          trim[space].bleeds[0] -
          trim[space].bleeds[2] -
          trim[space].margins[0] -
          trim[space].margins[2],
      });

    // right (gutter)
    if (trim[space].gutters[1])
      uncalculated.mattes.margins.push({
        left: uncalculated.center.x + spine / 2,
        top: trim[space].bleeds[0] + trim[space].margins[0],
        width: trim[space].gutters[1],
        height:
          uncalculated.height -
          trim[space].bleeds[0] -
          trim[space].bleeds[2] -
          trim[space].margins[0] -
          trim[space].margins[2],
      });
  } catch (error) {
    console.log(error);
    log.push(error);
  }

  // Barcode Mattes
  try {
    uncalculated.mattes.barcode = trim[space].barcode
      ? [
          {
            top: trim[space].bleeds[0] + trim[space].barcode.top,
            left: trim[space].bleeds[3] + trim[space].barcode.left,
            width: trim[space].barcode.width,
            height: trim[space].barcode.height,
          },
        ]
      : [];
  } catch (error) {
    console.log(error);
    log.push(error);
  }

  // Vertical snaps
  try {
    uncalculated.snaps.vertical = uniq(
      [
        // Left bleed guide
        trim[space].bleeds[3],

        // Left margin guide is margin + bleed
        trim[space].bleeds[3] + trim[space].margins[3],

        // Right gutter (if exists) is center - spine / 2 - margin
        trim[space].gutters[0] ? uncalculated.center.x - (spine > 0 ? spine / 2 : 0) - trim[space].gutters[0] : 0,

        // Add the center line on interiors
        space == "interior" ? uncalculated.center.x : 0,

        // Add the
        ...(space == "exterior"
          ? [uncalculated.center.x - (spine > 0 ? spine / 2 : 0), uncalculated.center.x + (spine > 0 ? spine / 2 : 0)]
          : []),

        // Right gutter (if exists) is center - spine / 2 - margin
        trim[space].gutters[0] ? uncalculated.center.x + (spine > 0 ? spine / 2 : 0) + trim[space].gutters[1] : 0,

        // Right margin guide is margin - bleed
        uncalculated.width - trim[space].bleeds[1] - trim[space].margins[1],

        // Right bleed guide
        uncalculated.width - trim[space].bleeds[1],
      ].filter((v) => v > 0)
    );
  } catch (error) {
    console.log(error, "~312");
    log.push(error);
  }

  // Horizontal snaps
  try {
    uncalculated.snaps.horizontal = uniq(
      [
        // Top bleed
        trim[space].bleeds[0],

        // Top margin guide is margin + bleed
        trim[space].margins[0] + trim[space].bleeds[0],

        // Bottom margin guide is margin - bleed
        uncalculated.height - trim[space].margins[2] - trim[space].bleeds[2],

        // Bottom bleed guide
        uncalculated.height - trim[space].bleeds[2],
      ].filter((v) => v > 0)
    );
  } catch (error) {
    log.push(error);
  }

  // Remove any mattes that are zero height, width
  uncalculated.mattes = removeZeroed(uncalculated.mattes);

  return uncalculated;
};

// Get the dimensions
export function Dimensions(trim, spreads) {
  let uncalculated = {
    exterior: calculateDimensions("exterior", trim, spreads),
    interior: calculateDimensions("interior", trim, spreads),
  };
  let calculated = { exterior: calculate(uncalculated.exterior), interior: calculate(uncalculated.interior) };

  // Calculate sizes
  calculated = calculate(uncalculated);

  return calculated;
}

// Get the dimensions that have already been saved (to save time)
export async function getSavedDimensions(feature) {
  // Get the workspace
  const { workspace } = this.props;

  // Check if we've saved the project dimensions before
  const projectDimensionsCollection = collection(db, projectDimensions);

  // Query to check if a document with the same workspace.project.id and feature already exists
  const q = query(
    projectDimensionsCollection,
    where("projectId", "==", workspace.project.id),
    where("feature", "==", feature)
  );

  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const docData = querySnapshot.docs[0].data();
    this.dimensions = docData.dimensions;
    return docData.dimensions;
  } else {
    return null;
  }
}

// Save the dimensions if calculated (to save time)
export async function setSavedDimensions(dimensions) {
  const { workspace } = this.props;
  const feature = workspace.feature.selected();

  const projectDimensionsCollection = collection(db, projectDimensions);

  // Query to check if a document with the same workspace.project.id and feature already exists
  const q = query(
    projectDimensionsCollection,
    where("projectId", "==", workspace.project.id),
    where("feature", "==", feature)
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    // If no such document exists, add a new one
    await addDoc(projectDimensionsCollection, {
      projectId: workspace.project.id,
      feature: feature,
      dimensions: dimensions,
    });
  }
}

// Delete the dimensions (when change the trim size)
export async function deleteSavedDimensions(projectId) {
  try {
    // Reference to the collection
    const collRef = collection(db, projectDimensions);

    // Create a query against the collection for the projectId
    const q = query(collRef, where("projectId", "==", projectId));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Go through all the documents returned and delete them
    const deletePromises = [];

    querySnapshot.forEach((docSnapshot) => {
      // Add the delete operation to the array of promises
      deletePromises.push(deleteDoc(doc(db, projectDimensions, docSnapshot.id)));
    });

    // Wait for all deletes to complete
    await Promise.all(deletePromises);
  } catch (_) {}
}
