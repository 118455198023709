/** @format */
import { Backdrop, Box, CssBaseline, Hidden } from "@mui/material";
import { Component as ReactComponent } from "react";

import { exception } from "sentry";

import { fallbackErrorMessage } from "../../config/index.json";
import Loader from "../Loader";

class FatalErrorComponent extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError() {
    return { error: null };
  }

  componentDidCatch(error) {
    try {
      // Check if error is an object and has a message property, otherwise assume it's a string
      const errorMessage = typeof error === "object" && error.message ? error.message : error;
      this.setState({ error: `${errorMessage} thrown to fatal.` });

      exception(error);
    } catch (_) {}
  }

  componentDidMount() {
    const { error, from } = this.props;
    if (error && from) {
      // Check if error is an object and has a message property, otherwise assume it's a string
      const errorMessage = typeof error === "object" && error.message ? error.message : error;
      this.setState({ error: `${errorMessage} passed to fatal ${from}.` });

      exception(error);
    }
  }

  render() {
    const { children } = this.props;

    if (this.state.error) {
      return (
        <>
          <CssBaseline />
          <Backdrop open={true} className="backdrop" sx={{ zIndex: 2000, backgroundColor: "#FFF", opacity: "1" }}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="center"
              alignItems="center"
              gap={2}
              sx={{ width: { xs: "100%", sm: "50%" }, margin: { xs: "20px", sm: "20px" } }}
            >
              <Hidden smDown>
                <Loader width={200} from="~57" />
              </Hidden>
              <Box>
                <Box component="h1" display="flex" justifyContent="left" margin="0px">
                  <Box component="span" color="#468189">
                    made
                  </Box>
                  <Box component="span" color="#9dbebb">
                    live
                  </Box>
                </Box>
                <Box component="div" margin="10px 0px" fontSize="11px">
                  {fallbackErrorMessage}
                </Box>
                <Box sx={{ fontSize: "10px" }}>{this.state.error}</Box>
              </Box>
            </Box>
          </Backdrop>
        </>
      );
    }

    return children;
  }
}

export default FatalErrorComponent;
