/** @format */
import { Box, CircularProgress, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";
import { Component as ReactComponent, createContext, useContext } from "react";
import { withUnit } from "unit";

// import { isbot } from "isbot";

import isSSR from "isssr";

import LoadingScreen from "../components/LoadingScreen";

// Checking the state (this should be refactored so each state is a file)
import { isLoading, isPerforming, isUpdating, isWorking } from "./helpers/Is";

// Set (as per the above, refactoring to setters/getters would be ideal)
import { setLoading, setProgress, setUpdating, setWorking } from "./helpers/Set";

// Clearing performing
import { clear } from "./helpers/Clear";

const PerformingContext = createContext({});

const StyledIndicators = styled(Box)(({ theme }) => ({
  ".update": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    bottom: "80px",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "3px",
    height: 40,
    width: 40,
    zIndex: 5000,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
  },
  "& .success": {
    backgroundColor: theme.palette.success.main,
  },
  "& .updating": {
    backgroundColor: theme.palette.info.dark,
  },
  "& .warning": {
    backgroundColor: theme.palette.warning.main,
  },
  "& .error": {
    backgroundColor: theme.palette.error.main,
    color: "#FFF",
  },
  "& .icon": {
    fontSize: "20px",
  },
  "& #performance-grace": { opacity: 0 },
}));

let defaultState = {
  // Hides the screen entirely
  loading: false, // !isbot(navigator.userAgent),

  // Applys a transparency and disabled screen
  working: false,

  // runs updating indicator in the background
  updating: null,

  // Shows error in the indicator
  error: false,

  // show warning in indicator
  warning: false,

  // shows success in indicator
  success: true,

  // Shows progress in loading, working
  progress: { percent: 0, title: null },
};

class Performing extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new(this.constructor.name);

    this.state = defaultState;

    this.is = {
      performing: isPerforming.bind(this),
      disabled: isPerforming.bind(this),
      loading: isLoading.bind(this),
      working: isWorking.bind(this),
      updating: isUpdating.bind(this),
    };

    this.set = {
      loading: setLoading.bind(this),
      working: setWorking.bind(this),
      updating: setUpdating.bind(this),
      progress: setProgress.bind(this),
    };

    this.isLoadingScreen = () => {
      return isLoading.call(this) || isWorking.call(this);
    };

    this.clear = clear.bind(this);
  }

  onError = (error) => {
    if (this.props.errors.error) this.props.errors.error(this.props.t("unexpectedError"), error);
  };

  render() {
    return (
      <PerformingContext.Provider value={{ ...this.state, is: this.is, set: this.set }}>
        {this.props.children}
        {!isSSR && (
          <>
            <LoadingScreen
              from="~122"
              location="performing"
              open={this.isLoadingScreen}
              progress={this.state.progress}
              title={this.state.progress.title}
              {...this.props}
              {...this.state}
            />
            <StyledIndicators>
              {this.is.updating("updating") && (
                <Fade in={true}>
                  <Box className="update updating">
                    <CircularProgress size="20px" style={{ margin: "5px" }} />
                  </Box>
                </Fade>
              )}
              {this.is.updating("success") && (
                <Fade in={true}>
                  <Box className="update success">
                    <DynamicIcon icon={"fa-check"} className="check" />
                  </Box>
                </Fade>
              )}
              {this.is.updating("warning") && (
                <Fade in={true}>
                  <Box className="update warning">
                    <DynamicIcon icon={"fa-triangle-exclamation"} className="check" />
                  </Box>
                </Fade>
              )}
              {this.is.updating("error") && (
                <Fade in={true}>
                  <Box className="update error">
                    <DynamicIcon icon={"fa-circle-exclamation"} className="check" />
                  </Box>
                </Fade>
              )}
            </StyledIndicators>
          </>
        )}
      </PerformingContext.Provider>
    );
  }
}

const withPerforming = (Component) => {
  return function ContextualComponent(props) {
    return (
      <PerformingContext.Consumer>
        {(state) => <Component {...props} performing={state} disabled={state.is.disabled()} />}
      </PerformingContext.Consumer>
    );
  };
};

const usePerforming = () => {
  return useContext(PerformingContext);
};

export default withUnit(Performing);
export { usePerforming, withPerforming };
