/** @format */
import BlogDate from "@Web/Blocks/BlogDate";
import Container from "@Web/Container";
import { Avatar, Box, ListItemText, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Cdn from "cdn";
import { useEffect, useRef } from "react";

import { useCms } from "cms";

let placeholder = Cdn(
  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_out_of_head_into_hands_f41a4d9269/thumbnail_out_of_head_into_hands_f41a4d9269.png?width=1536&height=1536"
);

const Hero = () => {
  const { page } = useCms();

  const { meta, author, title, excerpt, id } = page;

  // For updating the image
  const imgRef = useRef(null); // Create a ref to store the DOM element

  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll(".jarallax");
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import("jarallax");
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.style.backgroundImage = `url(${meta?.image || placeholder})`;
    }
  }, [id]);

  return (
    <Box
      className={"jarallax"}
      data-jarallax
      data-speed="0.2"
      position={"relative"}
      minHeight={{ xs: 400, sm: 500, md: 600 }}
      display={"flex"}
      marginTop={-13}
      paddingTop={13}
      alignItems={"center"}
      id="agency__portfolio-item--js-scroll"
    >
      <Box
        ref={imgRef}
        className={"jarallax-img"}
        data-lazybg={meta?.image || placeholder}
        sx={{
          position: "absolute",
          objectFit: "cover",
          fontFamily: "object-fit: cover;",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha("#161c2d", 0.6),
          zIndex: 1,
        }}
      />
      <Container position={"relative"} zIndex={2}>
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 600,
              color: "common.white",
              marginBottom: 2,
              fontSize: "2em",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              color: "common.white",
              marginBottom: 2,
            }}
          >
            {excerpt}
          </Typography>
          <Box display={"flex"} alignItems={"center"} sx={{ marginTop: "20px" }}>
            <Avatar
              variant="rounded"
              sx={{ width: 80, height: 80, marginRight: 2 }}
              src={author?.image || placeholder}
            />
            <ListItemText
              sx={{ margin: 0, fontSize: "1.2em" }}
              primary={author?.name || null}
              secondary={<BlogDate {...page} />}
              primaryTypographyProps={{
                variant: "h6",
                sx: { color: "common.white" },
              }}
              secondaryTypographyProps={{
                sx: { color: alpha("#ffffff", 0.8) },
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
