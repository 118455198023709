/** @format */
// import Text from "@Web/Blocks/Text";
import { Box, useTheme } from "@mui/material";
import { withCms } from "cms";
import { PageError } from "errors";
import { getPrivacyPolicy } from "iubenda";
import { Working } from "performing";
import { useEffect, useState } from "react";

const Content = () => {
  const [content, setContent] = useState("");
  const [error, setError] = useState(false);
  const { palette } = useTheme();
  useEffect(() => {
    getPrivacyPolicy()
      .then((response) => {
        setContent(response);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  return (
    <Box sx={{ paddingRight: "40px" }} data-testid="iubenda-privacy">
      {!content ? (
        <Box sx={{ padding: "20% 0", textAlign: "center", a: { color: "#121212", fontWeight: "bold" } }}>
          {!error ? <Working type="circular" size="100" /> : <PageError />}
        </Box>
      ) : (
        <Box sx={{ a: { color: palette.success.main, fontWeight: "bold" } }}>{content}</Box>
      )}
    </Box>
  );
};

export default withCms(Content);
