/** @format */
import { Component as ReactComponent, Suspense, lazy } from "react";
import { withUI } from "ui";
import { withUnit } from "unit";

const SignUp = lazy(() => import("./components/SignUp"));
const SignIn = lazy(() => import("./components/SignIn"));
const SignOut = lazy(() => import("./components/SignOut"));
const Settings = lazy(() => import("./components/Settings"));
const Forgot = lazy(() => import("./components/Forgot"));

class Dialogs extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new("User");
  }

  componentDidMount() {
    this.props.ui.add.dialogs(["signin", "settings", "signout", "signup", "forgot"], "~44");
  }

  render() {
    return (
      <>
        <Suspense fallback={null}>
          <SignUp {...this.props} />
        </Suspense>
        <Suspense fallback={null}>
          <SignIn {...this.props} />
        </Suspense>
        <Suspense fallback={null}>
          <SignOut {...this.props} />
        </Suspense>
        <Suspense fallback={null}>
          <Settings {...this.props} />
        </Suspense>
        <Suspense fallback={null}>
          <Forgot {...this.props} />
        </Suspense>
      </>
    );
  }
}

export default withUI(withUnit(Dialogs));
