/**
 * Menus
 *
 * @format
 */

export function openMenu(menu, close = true) {
  if (this.opened(menu)) return;
  if (close) this.close("~18");
  let { menus } = this.state.settings;
  menus[menu] = true;
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, menus: menus },
    },
  });
}

export function toggleMenu(menu) {
  let { menus } = this.state.settings;
  if (menus[menu] === "undefined") menus[menu] = false;
  menus[menu] = !menus[menu];
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, menus: menus },
    },
  });
}

export function menuOpened(menu) {
  let { menus } = this.state.settings;
  if (!menus[menu]) return false;
  return menus[menu];
}

export function closeMenus() {
  let { menus } = this.state.settings;
  Object.keys(menus).forEach((i) => (menus[i] = false));
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, menus: menus },
    },
  });
}
