/**
 * Element locking
 *
 * @format
 */

import { Functional } from "unit";

const unit = Functional.unit("designer/locked");

export function getElementLocked(from = "~11") {
  unit.report({
    method: "getElementLocked",
    message: "Checks if an element is locked.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  try {
    const { index } = this.element.selected();
    const spread = this.state.spread.number;
    const data = this.data("~21");

    const locked = data?.spreads[spread]?.elements[index]?.locked || false;

    return locked;
  } catch (_) {
    return false;
  }
}

export function setElementLocked(locked = true, from = "~31") {
  unit.report({
    method: "setElementLocked",
    message: "Sets the selected element locked.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  try {
    const { index, id } = this.element.selected();
    const spread = this.state.spread.number;
    const data = this.data("~41");

    data.spreads[spread].elements[index].locked = locked;

    // Triggered when locking (remove from selected)
    if (locked) {
      const selectedElements = [...this.state.elements];

      // Remove element from state elements
      const newElements = selectedElements.filter((element) => element.id !== id);

      // Select the new elements
      this.setState({ elements: newElements });
    }

    this.setState({ data: data });
  } catch (_) {}
}

export function toggleElementLocked(from = "~60") {
  unit.report({
    method: "toggleElementLocked",
    message: "Toggles and element lock.",
    test: "Clicking on any element should select it.",
    from: from,
  });
  try {
    setElementLocked.call(this, !getElementLocked.call(this));
  } catch (_) {}
}
