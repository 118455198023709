/** @format */
import BlogListSmall from "@Web/Blocks/BlogListSmall";
import { Box, Card, Divider, Typography } from "@mui/material";
import { useLocales } from "locales";

const SidebarArticles = (props) => {
  const { t } = useLocales();
  return (
    <Box component={Card} variant={"outlined"} padding={2}>
      <Typography
        variant="h6"
        data-aos={"fade-up"}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        {t("relatedArticles")}
      </Typography>
      <Box sx={{ marginBottom: "10px" }}>
        <Divider />
      </Box>
      <BlogListSmall {...props} />
    </Box>
  );
};

export default SidebarArticles;
