/** @format */
import { Box, Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ContactCard = () => {
  const theme = useTheme();

  return (
    <Box component={Card} boxShadow={0} border={`1px solid ${theme.palette.divider}`}>
      <Box padding={{ xs: 2, sm: 3 }}>
        <Typography
          sx={{
            fontWeight: "700",
          }}
          gutterBottom
        >
          How can you contact us about this notice?
        </Typography>
        <Typography
          variant={"body2"}
          color={"text.secondary"}
          sx={{
            marginBottom: 2,
          }}
        >
          If you have any questions or concerns about the privacy policy please contact us.
        </Typography>
        <Typography>
          hello@made.live
          <br />
          2933 30th Ave
          <br />
          Vernon, BC
          <br />
          Canada
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactCard;
