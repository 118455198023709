/** @format */
import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import DynamicIcon from "dynamicicon";
import { TinyError } from "errors";
import key from "key";
import { useLocales } from "locales";
import React from "react";
import { useRouter } from "router";
import { Avatar as UserAvatar, useUser } from "user";

import Login from "./components/Login";

const Categories = ({ features, thread, category }) => {
  // Wrap the url in localization
  const { t, url } = useLocales();

  // Get the navigation (use router to skip page checking...)
  const { navigate } = useRouter();

  // Check if we're authenticated
  const { authenticated } = useUser();

  return (
    <Box>
      <TinyError>
        {category && (
          <>
            <Box sx={{ width: "100%", padding: "20px" }}>
              {authenticated ? <UserAvatar size={!thread ? 200 : 60} /> : <Login thread={thread} />}
            </Box>
            <Divider />
          </>
        )}

        {!category ? (
          <Box sx={{ margin: "5%" }}>
            <Grid container spacing={5} justifyContent="center" alignItems="center">
              {features.map((feature, featureIndex) => (
                <Grid
                  item
                  xs={3}
                  key={featureIndex}
                  onClick={() => {
                    navigate(url(`/community/${feature.slug}`));
                  }}
                  selected={category === feature.slug}
                  sx={{ textAlign: "center", cursor: "pointer" }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "info.main",
                      color: "white",
                      width: 64,
                      height: 64,
                      ":hover": {
                        bgcolor: "primary.main",
                      },
                    }}
                  >
                    <TinyError>
                      <Box
                        component={"svg"}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <DynamicIcon icon={feature.icon || "fa-bullseye"} />
                      </Box>
                    </TinyError>
                  </Avatar>
                  <Box sx={{ padding: "5%" }}>{feature.title}</Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <List>
            <ListItem sx={{ margin: 0, padding: 0 }}>
              <Tooltip title={t("home")} placement="right" disableHoverListener={!thread}>
                <ListItemButton
                  onClick={() => {
                    navigate(url(`/community`));
                  }}
                  selected={!category}
                  sx={{ justifyContent: "center" }}
                >
                  <ListItemIcon>
                    <Avatar
                      sx={{
                        bgcolor: "primary.main",
                        color: "white",
                        width: 40,
                        height: 40,
                      }}
                    >
                      <TinyError>
                        <Box
                          component={"svg"}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          width={24}
                          height={24}
                        >
                          <DynamicIcon icon="fa-house" />
                        </Box>
                      </TinyError>
                    </Avatar>
                  </ListItemIcon>
                  {!thread && <ListItemText primary={t("home")} />}
                </ListItemButton>
              </Tooltip>
            </ListItem>
            <Divider />
            {features.map((feature, featureIndex) => (
              <ListItem
                {...key("kb", "entries", "releasedFeatures", feature.title, featureIndex)}
                sx={{ margin: 0, padding: 0 }}
              >
                <Tooltip title={feature.title} placement="right" disableHoverListener={!thread}>
                  <ListItemButton
                    onClick={() => {
                      navigate(url(`/community/${feature.slug}`));
                    }}
                    selected={category === feature.slug}
                    sx={{ justifyContent: "center" }}
                  >
                    <ListItemIcon>
                      <Avatar
                        sx={{
                          bgcolor: "info.main",
                          color: "white",
                          width: 40,
                          height: 40,
                        }}
                      >
                        <TinyError>
                          <Box
                            component={"svg"}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <DynamicIcon icon={feature.icon || "fa-bullseye"} />
                          </Box>
                        </TinyError>
                      </Avatar>
                    </ListItemIcon>
                    {!thread && <ListItemText primary={feature.title} secondary={feature.subTitle || ""} />}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        )}
      </TinyError>
    </Box>
  );
};

export default Categories;
