/** @format */

import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useLocales } from "locales";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";

const DetectDevice = () => {
  const { t } = useLocales();
  if (isMobile || isTablet) {
    return (
      <Dialog open={true} aria-labelledby="device-warning-dialog-title">
        <DialogTitle id="offline-dialog-title">{t("deviceProblemTitle")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{t("deviceProblemMessage")}</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return null; // Don't render anything if it's not a mobile or tablet
};

export default DetectDevice;
