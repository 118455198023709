/** @format */
import { styled } from "@mui/material/styles";
import { SnackbarProvider, useSnackbar } from "notistack";
import { PureComponent, createContext, useContext } from "react";
import config from "../config/index.json";

import { withPerforming } from "performing";

import { close } from "./helpers/Close";
import { open } from "./helpers/Open";

const SnackbarContext = createContext({});

const StyledSnackbarProvider = styled(SnackbarProvider)(() => ({
  containerRoot: {
    bottom: 200,
    zIndex: 17000,
  },
  "& .SnackbarContainer-root": {
    right: "200px !important",
    zIndex: 17000,
  },
  "& .SnackbarContent-root": {
    minWidth: "0px !important",
    padding: "3px 5px !important",
  },
  "&.SnackbarItem-contentRoot": {
    backgroundColor: "#031926 !important",
    color: "#FFFFFF !important",
  },
  "&.SnackbarItem-variantSuccess": {
    backgroundColor: "#76ab7b !important",
    color: "#121212 !important",
  },
  "&.SnackbarItem-variantWarning": {
    backgroundColor: "#eba82b !important",
    color: "#121212 !important",
  },
  "&.SnackbarItem-variantError": {
    backgroundColor: "#8a1919 !important",
    color: "#FFFFFF !important",
  },
  "&.SnackbarItem-variantDefault": {
    backgroundColor: "#abc7cc !important",
    color: "#121212 !important",
  },
  "& .SnackbarItem-message": {
    fontSize: "1em",
    padding: "7.5px",
  },
}));

class SnackbarComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.open = open.bind(this);
    this.close = close.bind(this);
    this.dismiss = close.bind(this);
  }

  render() {
    return (
      <SnackbarContext.Provider
        value={{
          open: this.open,
          close: this.close,
          dismiss: this.dismiss,
        }}
      >
        <>{this.props.children}</>
      </SnackbarContext.Provider>
    );
  }
}

const WithNotistack = (props) => {
  return <SnackbarComponent {...useSnackbar()} {...props} />;
};

const SnackbarWrapper = (props) => {
  return (
    <StyledSnackbarProvider {...config} {...props}>
      <WithNotistack {...props} />
    </StyledSnackbarProvider>
  );
};

const useThisSnackbar = () => {
  let Snackbar = useContext(SnackbarContext);
  return Snackbar;
};

const withSnackbar = (Component) => (props) => {
  return <SnackbarContext.Consumer>{(state) => <Component {...props} snackbar={state} />}</SnackbarContext.Consumer>;
};

export default withPerforming(SnackbarWrapper);
export { useThisSnackbar as useSnackbar, withSnackbar };
