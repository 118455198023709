/** @format */

export function getName(field = "displayName") {
  if (!this.authenticated()) return null;

  let tmp = "";

  if (!this.state?.meta) return null;

  let { firstName, lastName } = this.state.meta;

  if (this.state.displayName && field == "displayName") {
    tmp = this.state.displayName + " ";
  } else {
    if (typeof this.state.displayName == "string") {
      if (!firstName || (!lastName && this.state.displayName && this.state.displayName.includes(" "))) {
        if (!firstName) firstName = this.state.displayName.split(" ")[0];
        if (!lastName) lastName = this.state.displayName.split(" ")[1];
      }
    }

    if (firstName && (field == "fullName" || field == "initials" || field == "firstName")) tmp += firstName + " ";

    if (lastName && (field == "fullName" || field == "initials" || field == "lastName")) tmp += lastName + " ";

    if (firstName && field == "initials") tmp = firstName.charAt(0);

    if (lastName && field == "initials") tmp += lastName.charAt(0);
  }

  return tmp.trim();
}

export function setName(field, value) {
  return new Promise((resolve, reject) => {
    try {
      this.setState(
        (prevState) => ({
          meta: {
            ...prevState.meta,
            [field]: value,
          },
        }),
        () => {
          this.user.set(this.state, true);
          resolve(true);
        }
      );
    } catch (e) {
      reject(e);
    }
  });
}
