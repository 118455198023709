/** @format */
import { updateProfile } from "firebase/auth";
import { deleteObject, ref } from "firebase/storage";
import { auth, storage } from "firemade";

export default function removeAvatar() {
  return new Promise((resolve, reject) => {
    if (!auth.currentUser) return reject("youreNotLoggedIn");
    deleteObject(ref(storage, auth.currentUser.uid));
    updateProfile(auth.currentUser, { photoURL: "" })
      .then(() => {
        return resolve(true);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
