/** @format */

import { Functional } from "unit";

const unit = Functional.unit("designer/theme");

export function setTheme(props, from = "~7") {
  unit.report({
    method: "setTheme",
    payload: props,
    from: from,
    test: "Setting the colors and fonts for the project",
  });

  if (!props) return;

  try {
    const data = this.data("~18");

    this.project.update(
      {
        data: { ...data, ...props },
        note: "Updated theme.",
        from: "~24",
        ai: 0,
      },
      "~27"
    );
  } catch (error) {
    console.error(error, "~30");
  }
}
