/** @format */

// /** @format */
// import { lazy } from "react";

// const Reader = lazy(() => import("../index.jsx"));

// const routes = [
//   {
//     path: "reader",
//     element: <Reader />,
//     children: [
//       {
//         path: ":projectId",
//         element: <Reader />,
//         children: [
//           {
//             path: ":token",
//             element: <Reader />,
//             children: [
//               {
//                 path: ":dimensions",
//                 element: <Reader />,
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];

// export default routes;

const routes = [];
export default routes;
