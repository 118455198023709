/** @format */

import { Box, Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useWorkspace } from "@Workspace/Context";
import { ComponentError, TinyError } from "errors";
import { useLocales } from "locales";
import React, { useState } from "react";
import { useTour } from "tour";
import { useUI } from "ui";
import Feature from "./components/Feature";
import Limited from "./components/Limited";

import { Panels, Tab, Tabs } from "ui";

const StyledBox = styled(Box)(() => ({
  "&": { maxHeight: "100%" },
}));

const Checklist = ({ show: initialShow = "all", limit: initialLimit = 999 }) => {
  const [show, setShow] = useState(initialShow);
  const [limit] = useState(initialLimit);

  const workspace = useWorkspace();
  const { t } = useLocales();
  const tour = useTour();
  const ui = useUI();

  /**
   * Retrieves the list of enabled features from the workspace checklist.
   *
   * @returns {string[]} An array of enabled feature keys.
   */
  const enabledFeatures = () => {
    try {
      if (!workspace.checklist.get) return [];
      return Object.entries(workspace.checklist.get().features)
        .filter(([_, value]) => value.enabled)
        .map(([key]) => key);
    } catch (e) {
      return [];
    }
  };

  const enabledFeaturesList = enabledFeatures();

  return (
    <StyledBox>
      {show === "all" ? (
        <ComponentError>
          <TinyError>
            <Tabs slug="checklist/features">
              {enabledFeaturesList.map((f, i) => {
                const feature = workspace.feature.get(f);
                return (
                  <Tab
                    slug="checklist/features"
                    variant="button"
                    size="small"
                    key={`feature-tab-${feature.slug}-${i}`}
                    icon={feature.icon}
                    label={t(feature.title)}
                    id={`feature-tab-${i}`}
                    aria-controls={`feature-tabpanel-${i}`}
                    index={i}
                  />
                );
              })}
            </Tabs>
            <Box sx={{ margin: "10px 10px 40px 10px", opacity: 0.5 }}>
              <Divider />
            </Box>
          </TinyError>
          <Panels slug="checklist/features">
            {enabledFeaturesList.map((feature, i) => (
              <TinyError key={`feature-${feature}-${i}`}>
                <Feature feature={workspace.checklist.get(feature)} />
              </TinyError>
            ))}
          </Panels>
        </ComponentError>
      ) : (
        <>
          <TinyError>
            <Limited workspace={workspace} t={t} tour={tour} ui={ui} limit={limit} />
          </TinyError>
          {show !== "all" && (
            <>
              <Divider />
              <Box sx={{ padding: "5%", textAlign: "right" }}>
                <Button variant="contained" color="success" onClick={() => setShow("all")}>
                  {t("showAll")}
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </StyledBox>
  );
};

export default Checklist;
