/** @format */
import { Backdrop, Box, LinearProgress, Stack, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Cookies from "cookies";
import { TinyError } from "errors";
import Logo from "logo";

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  "&": {
    padding: "5%",
    backgroundColor:
      theme.palette.mode == "dark" || Cookies.get("mode") == "dark" ? "rgba(12,12,12,1)" : "rgba(255,255,255,1)",
  },
  "& .title, & .label": { display: "block", margin: "0px" },
}));

const LoadingScreen = (props) => {
  // Get the theme
  const theme = useTheme();

  // Check for the load screen size
  const isSmScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  // Set the size of the zoom
  let zoomValue = isSmScreen ? 1 : 2;

  // Set the launch time and percent
  let launchTime = 0;
  let percent = 0;

  // Set the title
  const title = props.title || props.label || null;

  let open = false;

  // # TODO - this looks a little hacky
  try {
    if (typeof props?.open === "function") open = props?.open();
    if (typeof props?.open === "boolean") open = props?.open;
  } catch (e) {}

  // Attempt to set the launch time and percent of progress
  try {
    launchTime = props.loading ? 0 : 500;
    percent = props.progress.percent;
  } catch (e) {}

  return (
    <TinyError>
      <StyledBackdrop open={open || false} transitionDuration={launchTime} from={props.from}>
        <Stack direction="column">
          {open && (
            <Logo
              wordmark={true}
              zoom={zoomValue}
              link={false}
              hover={false}
              colorInvert={Cookies.get("mode") == "dark"}
            />
          )}
          <Box sx={{ padding: { xs: "10% 10%", sm: "10% 25%" } }}>
            {percent != 0 && (
              <LinearProgress
                variant="determinate"
                value={percent}
                color="info"
                sx={{ height: "10px", borderRadius: "3px" }}
              />
            )}
          </Box>
          {title && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              {title}
            </Box>
          )}
        </Stack>
      </StyledBackdrop>
    </TinyError>
  );
};

export default LoadingScreen;
