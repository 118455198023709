/** @format */
import { withBilling } from "@Workspace/Billing";
import SmartButtons from "@Workspace/Components/SmartButtons";
import { withThumb } from "@Workspace/Components/Thumb/context";
import { withUI } from "UI";
import { withErrors } from "errors";
import { withLocales } from "locales";
import { withPerforming } from "performing";
import { Component as ReactComponent, createContext, useContext } from "react";
import { withUser } from "user";

/* Helpers */

// Setup
import { setupManuscript } from "./helpers/Setup";

// Teardown on unmount
import { teardown } from "./helpers/Teardown";

// When the manuscript it ready to go
import { getReady, setReady } from "./helpers/Ready";

// Buttons
import { ButtonConfig } from "./helpers/Buttons";

// Marks (all styling now)
import { isMarkActive, isMarkAvailable, toggleMark } from "./helpers/Mark";

// Manuscript controls
import { clearManuscript, updateDraft, updateLayouts, updateManuscript } from "./helpers/Data";

// Dos
import { canDo, redo, undo } from "./helpers/Do";

// Keep the carat (cursor) sae
import { getCarat, restoreCarat, setCarat } from "./helpers/Carat";

// Get the tips

// Editor controls
import {
  editorsLoaded,
  focusEditor,
  getEditor,
  getEditorFocused,
  registerEditor,
  selectEditor,
  setEditor,
  unregisterEditor,
} from "./helpers/Editors";

// The settings for the manuscript editor
import { getSetting, setSetting, toggleSetting } from "./helpers/Settings";

// Inserts (tags, markers) dialog
import { getNextType, getPreviousType, getSiblingType, isDocumentEmpty, isFirst, isLast } from "./helpers/Inserts";

// Inserts (tags, markers)
import {
  canInsertCharacter,
  canInsertComment,
  canInsertImagery,
  closeAnnotations,
  deleteCharacter,
  deleteComment,
  deleteImagery,
  getCharacterFocused,
  getCommentFocused,
  getImageryFocused,
  insertAnnotation,
  insertCharacter,
  insertComment,
  insertImagery,
  openAnnotations,
  setCharacterFocused,
  setCommentFocused,
  setImageryFocused,
  updateAnnotation,
} from "./helpers/Annotations";

// Supports a component for editing annotations
import { closeAnnotator, openAnnotator } from "./helpers/Annotator";

// Markers dialog
import {
  canInsertBookmark,
  canInsertBreak,
  deleteBookmark,
  deleteBreak,
  insertBookmark,
  insertBreak,
} from "./helpers/Markers";

// -- Artifacts -- //

// Marks (all styling now)
import { isBlockActive, isBlockAvailable, toggleBlock } from "./helpers/Block";

// Used for multi-spread option (potential artifact)
import { countPages, countReadtime, countSpreads, countWords } from "./helpers/Count";

// This is moved into an adapters folder to keep the core seperate from adaptive classes.
import { completeTransfer, confirmTransfer, reviewTransfer } from "../adapters/Transfer";

// Used for multi-spread option (potential artifact)

// Another artifact (multi vs single - single is known as draft mode)
import { getMode, setMode, toggleMode } from "./helpers/Modes";

// Artifact of draft/spread mode
import {
  addSpread,
  deleteSpread,
  getSpreadName,
  listSpreads,
  orderSpread,
  setSpread,
  spreadAdd,
} from "./helpers/Spreads";

const ManuscriptContext = createContext({});

class Manuscript extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new(this.constructor.name);

    this.unit.report({
      method: "constructor",
      message: "Manusript constructed from context /Designed/context/index.jsx",
      passed: true,
      from: "~133",
    });

    this.state = {
      data: this.props.data,
      ready: [],

      // multispread view (future)
      mode: "draft",

      // A spread (is for multispread future option)
      spread: {
        number: parseInt(window.location.hash.replace("#", "")) || 0,
      },

      // Settings for views
      settings: {
        // Annotations
        characters: true,
        imagery: true, // these are big, so we wanna keep them small
        comments: true,

        // Markers
        breaks: true,
        bookmarks: true,
      },

      // opens the annotations popup
      annotations: null,

      // Open the annotation editor
      annotator: null,

      // Deal with the focus on the character tag
      focused: null,

      // When comments are updated, the observer needs to be notified
      observer: null,

      // Helps with workcounts etc
      counts: {
        page: 0,
        spread: 0,
        word: 0,
      },

      // A place to keep the carat save
      carat: null,

      // Artifact
      updated: false,
    };

    // # TODO - resets and sets up
    this.reset();

    // Lets us know when ready
    this.ready = {
      get: getReady.bind(this),
      set: setReady.bind(this),
    };

    // Manages the editor
    this.editor = {
      set: setEditor.bind(this),
      get: getEditor.bind(this),
      register: registerEditor.bind(this),
      unregister: unregisterEditor.bind(this),
      select: selectEditor.bind(this),
      loaded: editorsLoaded.bind(this),
    };

    // Updates the project
    this.update = {
      draft: updateDraft.bind(this),
      layout: updateLayouts.bind(this),
    };

    // Manages focus
    this.focus = { set: focusEditor.bind(this), get: getEditorFocused.bind(this) };

    // Manages the carat (cursor)
    this.carat = { set: setCarat.bind(this), get: getCarat.bind(this), restore: restoreCarat.bind(this) };

    // Manage the insert protocol
    this.inserts = {
      empty: isDocumentEmpty.bind(this),
      first: isFirst.bind(this),
      last: isLast.bind(this),
      previous: getPreviousType.bind(this),
      next: getNextType.bind(this),
      sibling: getSiblingType.bind(this),
    };

    this.annotations = {
      open: openAnnotations.bind(this),
      close: closeAnnotations.bind(this),
      insert: insertAnnotation.bind(this), // a more robust feature to assist witha annotations popup
      update: updateAnnotation.bind(this),
      character: {
        insert: insertCharacter.bind(this),
        delete: deleteCharacter.bind(this),
        can: canInsertCharacter.bind(this),
        focus: {
          set: setCharacterFocused.bind(this),
          get: getCharacterFocused.bind(this),
        },
      },
      imagery: {
        insert: insertImagery.bind(this),
        delete: deleteImagery.bind(this),
        can: canInsertImagery.bind(this),
        focus: {
          set: setImageryFocused.bind(this),
          get: getImageryFocused.bind(this),
        },
      },
      comment: {
        insert: insertComment.bind(this),
        delete: deleteComment.bind(this),
        can: canInsertComment.bind(this),
        focus: {
          set: setCommentFocused.bind(this),
          get: getCommentFocused.bind(this),
        },
      },
    };

    // Support editing annotations (yeah, I made up this word, so what?)
    this.annotator = {
      open: openAnnotator.bind(this),
      close: closeAnnotator.bind(this),
    };

    // Markers
    this.markers = {
      bookmark: {
        insert: insertBookmark.bind(this),
        delete: deleteBookmark.bind(this),
        can: canInsertBookmark.bind(this),
      },
      break: {
        insert: insertBreak.bind(this),
        delete: deleteBreak.bind(this),
        can: canInsertBreak.bind(this),
      },
    };

    // Delete inserts
    this.delete = {
      character: deleteCharacter.bind(this),
      comment: deleteComment.bind(this),
      bookmark: deleteBookmark.bind(this),
      break: deleteBreak.bind(this),
    };

    // Transfer the manuscript to the designer
    this.transfer = {
      confirm: confirmTransfer.bind(this),
      review: reviewTransfer.bind(this),
      complete: completeTransfer.bind(this),
    };

    // Changes/gets the settings
    this.settings = {
      get: getSetting.bind(this),
      set: setSetting.bind(this),
      toggle: toggleSetting.bind(this),
    };

    // Marks (b, u, i) - dom styles (marks)
    this.mark = {
      available: isMarkAvailable.bind(this),
      active: isMarkActive.bind(this),
      toggle: toggleMark.bind(this),
    };

    // Blocks (h1s, etc) - dom elements
    this.block = {
      available: isBlockAvailable.bind(this),
      active: isBlockActive.bind(this),
      toggle: toggleBlock.bind(this),
    };

    // Tags (probably should be inserts)
    this.tag = {
      get: getSetting.bind(this),
      set: setSetting.bind(this),
      toggle: toggleSetting.bind(this),
    };

    // Dos
    this.canDo = canDo.bind(this);
    this.undo = undo.bind(this);
    this.redo = redo.bind(this);

    // Old or future (probably an artifact)
    this.preview = {
      toggle: toggleSetting.bind(this),
    };

    // Old or future multi-spread option
    this.mode = {
      set: setMode.bind(this),
      get: getMode.bind(this),
      toggle: toggleMode.bind(this),
    };

    this.spread = {
      add: spreadAdd.bind(this),
      set: setSpread.bind(this),
      select: selectEditor.bind(this),
      delete: deleteSpread.bind(this),
      list: listSpreads.bind(this),
      name: getSpreadName.bind(this),
    };

    this.spread = {
      ready: editorsLoaded.bind(this),
      get: listSpreads.bind(this),
      set: setSpread.bind(this),
      select: selectEditor.bind(this),
      add: addSpread.bind(this),
      order: orderSpread.bind(this),
    };

    this.counts = {
      page: countPages.bind(this),
      spread: countSpreads.bind(this),
      word: countWords.bind(this),
      read: countReadtime.bind(this),
    };

    // Artifact

    this.updateManuscript = updateManuscript.bind(this);

    this.clearManuscript = clearManuscript.bind(this);

    this.updateDraft = updateDraft.bind(this);

    this.updateLayouts = updateLayouts.bind(this);

    this.clear = clearManuscript.bind(this);
  }

  // Resets the editor
  reset = () => {
    this.editors = [];
    this.spreads = this.props.data;
  };

  /* -------- REACT ---------*/

  componentDidMount = () => {
    this.unit.report({
      method: "componentDidMount",
      steps: [],
      test: "Manuscript should be viewable if that is the feature selected. Menus should show and buttons should work.",
      message: "Manuscript did mount from context.",
      from: "~393",
    });

    const { errors, t, performing, workspace } = this.props;

    if (workspace.ready.get("all")) {
      setupManuscript
        .call(this, "~400")
        .then(() => {
          performing.set.progress({
            percent: 100,
            title: "Enjoy!",
          });
          performing.set.loading(false, "~406");
        })
        .catch((e) => {
          errors.error(t("unexpectedError"), e, "~409");
        });
    }
  };

  componentWillUnmount = () => {
    try {
      teardown.call(this);
    } catch (error) {
      console.log(error);
    }
  };

  /* ------- FUNCTIONS -------*/

  render() {
    const context = {
      data: this.props.data,
      editor: this.editor,
      update: this.update,
      focus: this.focus, // focusing the editor
      focused: this.state.focused, // the character that is focused on
      spread: { ...this.spread, number: this.state.spread.number },
      spreads: this.spreads,
      counts: this.counts,
      inserts: { ...this.inserts },
      annotations: { ...this.annotations, opened: this.state.annotations },
      annotator: { ...this.annotator, opened: this.state.annotator },
      markers: this.markers,
      delete: this.delete,
      transfer: this.transfer,
      carat: this.carat,
      observer: this.state.observer,
      tools: {
        settings: this.settings,
        mark: this.mark,
        block: this.block,
        tag: this.tag,
        mode: this.mode,
        preview: this.preview,
        clear: this.clear,
      },
    };

    return (
      <ManuscriptContext.Provider
        {...this.props}
        value={{
          ...this.state,
          ...context,
          // # TODO - smart buttons needs some more thinking
          buttons: SmartButtons(ButtonConfig.bind(this), this),
        }}
      >
        {this.ready.get("all") && this.props.children}
      </ManuscriptContext.Provider>
    );
  }
}

const withManuscript = (Component) => {
  return function ContextualComponent(props) {
    return (
      <ManuscriptContext.Consumer>{(state) => <Component {...props} manuscript={state} />}</ManuscriptContext.Consumer>
    );
  };
};

const useManuscript = () => {
  return useContext(ManuscriptContext);
};

export default withPerforming(withUser(withThumb(withErrors(withLocales(withUI(withBilling(Manuscript)))))));
export { useManuscript, withManuscript };
