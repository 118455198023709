/** @format */
import { withErrors } from "errors";
import { withLocales } from "locales";
import { withPerforming } from "performing";
import { Component as ReactComponent, createContext, useContext } from "react";
import { withSnackbar } from "snackbar";
import { withUser } from "user";

import "../assets/css/Capture.css";

import { clear, remove, snapshot } from "./helpers/Media";

const CaptureContext = createContext({});

class Capture extends ReactComponent {
  constructor(props) {
    super(props);

    this.interval = null;
    this.poll = 0;
    this.blobUrl = null;

    this.state = {
      snapping: false,
      media: [],
    };

    this.snapshot = snapshot.bind(this);
    this.remove = remove.bind(this);
    this.clear = clear.bind(this);
  }

  reset = () => {
    this.setState({
      media: [],
    });
  };

  onError = (error) => {
    this.reset();
    const { errors } = this.props;
    try {
      errors.error(this.props.t("unexpectedError"), error, "~93");
    } catch (_) {}
  };

  componentWillUnmount() {
    try {
      this.reset();
    } catch (_) {}
  }

  render() {
    const enabled = this.props.user.meta.get("capture");
    return (
      <CaptureContext.Provider
        value={{
          ...this.state,
          enabled,
          snapshot: this.snapshot,
          remove: this.remove,
          clear: this.clear,
        }}
      >
        {this.props.children}
      </CaptureContext.Provider>
    );
  }
}

const withCapture = (Component) => {
  return function ContextualComponent(props) {
    return <CaptureContext.Consumer>{(state) => <Component {...props} capture={state} />}</CaptureContext.Consumer>;
  };
};

const useCapture = () => {
  return useContext(CaptureContext);
};

export default withPerforming(withSnackbar(withErrors(withLocales(withUser(Capture)))));
export { useCapture, withCapture };
