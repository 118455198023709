/** @format */
import { Box } from "@mui/material";
import { TinyError } from "errors";
import key from "key";
import React from "react";

// Import all the components
import FAQGrid from "@Web/Blocks/FAQGrid";
import FAQList from "@Web/Blocks/FAQList";
import Image from "@Web/Blocks/Image";
import ImageGrid from "@Web/Blocks/ImageGrid";
import KBGrid from "@Web/Blocks/KBGrid";
import KBList from "@Web/Blocks/KBList";
import PressGrid from "@Web/Blocks/PressGrid";
import PressList from "@Web/Blocks/PressList";
import ReviewsList from "@Web/Blocks/ReviewsList";
import Text from "@Web/Blocks/Text";
import TextGrid from "@Web/Blocks/TextGrid";
import TextList from "@Web/Blocks/TextList";

const Component = (props) => {
  const { type } = props;
  if (type == "Text") return <Text {...props} />;
  if (type == "TextList") return <TextList {...props} />;
  if (type == "TextGrid") return <TextGrid {...props} />;
  if (type == "Image") return <Image {...props} />;
  if (type == "ImageGrid") return <ImageGrid {...props} />;
  if (type == "FAQGrid") return <FAQGrid {...props} />;
  if (type == "FAQList") return <FAQList {...props} />;
  if (type == "KBGrid") return <KBGrid {...props} />;
  if (type == "KBList") return <KBList {...props} />;
  if (type == "PressGrid") return <PressGrid {...props} />;
  if (type == "PressList") return <PressList {...props} />;
  if (type == "ReviewsList") return <ReviewsList {...props} />;
  return null;
};

export default function (props) {
  return (
    <TinyError>
      <BlogContent {...props} />
    </TinyError>
  );
}

export function BlogContent(props) {
  const { blocks } = props;

  if (!blocks || !Array.isArray(blocks)) return <></>;

  let components = {};

  return (
    <Box paddingX={{ xs: 0, sm: 4, md: 2 }} {...key}>
      {blocks.map((block, i) => {
        if (!components[block.type]) components[block.type] = 0;
        components[block.type]++;
        return (
          <Box {...key("cms", "blog", "content", block.type, i)}>
            <TinyError>
              <Component {...props} type={block.type} number={components[block.type]} />
            </TinyError>
          </Box>
        );
      })}
    </Box>
  );
}
