/** @format */
import { useMediaQuery } from "@mui/material";
import { alpha, createTheme } from "@mui/material/styles";
import { css } from "@mui/system";
import { deepmerge } from "@mui/utils";

// Import the global palette colors
import colors from "mlcolors";

// # TODO Should be removed
// import helpers from "./helpers/";

const fontFamily = "Comfortaa, sans-serif";

const autofillStyles = css({
  root: { fontFamily: fontFamily },
  "&:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 100px #FFF inset",
    WebkitTextFillColor: "rgba(255,255,255,.8)",
  },
});

// This is the most important button in the universe
const tabButton = {
  padding: 9,
  fontSize: 16,
  border: 0,
  borderRadius: 3,
  minWidth: 60,
  maxHeight: 58,
  height: 58,
  minHeight: 58,
  backgroundColor: "rgba(255,255,255,.1)",
  flexDirection: "column",
  margin: "1px",
  "& label": {
    fontSize: 10,
    padding: "5px 5px 0px 5px",
  },
  "&.MuiButton-root.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26)",
  },
  "> *": {
    cursor: "pointer",
  },
};

// This is the most important button in the universe
const tabButtonSmall = {
  padding: 4,
  fontSize: 18,
  border: 0,
  borderRadius: 3,
  width: "40px",
  minWidth: "40px",
  height: "40px",
  backgroundColor: "rgba(255,255,255,.1)",
  flexDirection: "column",
  margin: "1px",
  "& label": {
    display: "none",
  },
  "&.MuiButton-root.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26)",
  },
  "> *": {
    cursor: "pointer",
  },
};

const theme = {
  /* List the individual components */

  typography: {
    fontFamily: fontFamily,
  },

  zIndex: {
    appBar: 1254,
    workspaceToolbar: 1255,
    drawer: 1256,
    workspaceTabs: 1257,
    adminTabs: 1257,
    modal: 2001,
    backdrop: 1600,
    snackbar: 17000,
    tour: 1259,
  },

  /* Overrides for components */
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // "@font-face": {
        //   fontFamily: fontFamily,
        //   fontStyle: "normal",
        //   fontDisplay: "swap",
        //   fontWeight: "400",
        // },
        label: {
          fontFamily: fontFamily,
        },
        input: {
          fontFamily: fontFamily,
        },
        textarea: {
          fontFamily: fontFamily,
        },
        select: {
          fontFamily: fontFamily,
        },
        button: {
          fontFamily: fontFamily,
        },
        password: {
          fontFamily: fontFamily,
        },
        li: {
          paddingBottom: "15px",
        },
        body: {
          fontSize: "inherit",
        },
        body1: {
          fontSize: "inherit",
        },
        blockquote: {
          backgroundColor: "#EEE",
          borderLeft: "10px solid #121212",
          padding: 20,
          margin: "10px 0",
          borderRadius: "3px",
        },
        "& .SnackbarItem-variantSuccess": {
          backgroundColor: "#409944",
        },
        "& .panelTabs": {
          maxHeight: "37.5px",
          minHeight: "37.5px",
          height: "37.5px",
        },
        "& .dialogTabs": {
          maxHeight: "60px",
          minHeight: "60px",
          height: "60px",
        },
        ".heroPad": {
          marginRight: "5%",
          marginLeft: "5%",
          maxWidth: "90%",
        },
        ".TopNav *, .TopBar *": {
          fontFamily: "Comfortaa, sans-serif",
        },
        ".wordmark .made": {
          color: colors.secondary.main,
        },
        ".wordmark .live": {
          color: colors.primary.main,
        },
        "& .web.gradient": {
          backgroundColor: "#77aca2",
          backgroundImage: `linear-gradient(120deg, #fff 0%, ${colors.secondary.main} 100%);`,
        },
        "& .web.gradient.invert": {
          backgroundColor: "rgb(157,190,187)",
          backgroundImage:
            "linear-gradient(121deg, rgba(157,190,187,1) 0%, rgba(70,129,137,1) 10%, rgba(3,25,38,1) 52%);",
        },
        ".MuiButton-containedPrimary:hover, .MuiButton-containedSeconary:hover, .MuiButton-containedSuccess:hover": {
          backgroundColor: "#121212",
          color: "#FFF",
        },
        ".transparent": {
          background: "repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 10px, #c7c7c7 10px, #c7c7c7 20px)",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
        },
        h1: {
          fontSize: "2em",
          fontWeight: 700,
        },
        h2: {
          fontSize: "1.75em",
          fontWeight: 300,
        },
        h3: {
          fontSize: "1.5em",
        },
        h4: {
          fontSize: "1.25em",
        },
        h5: {
          fontSize: "1.0em",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        ...autofillStyles,
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          display: "block",
        },
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba (12,12,12,.5)",
          margin: "auto auto",
          borderRadius: "3px",
        },
        colorDefault: {
          backgroundColor: "rgba (12,12,12,.5)",
        },
        square: {
          borderRadius: "3px",
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
        },
      },
    },

    MuiButton: {
      variants: [
        {
          props: { variant: "tab" },
          style: {
            ...tabButton,
            color: "#121212",
            "&.Mui-selected": { backgroundColor: "rgba(12,12,12,.08)", color: "#000", cursor: "pointer" },
          },
        },
        {
          props: { variant: "small-tab" },
          style: {
            ...tabButtonSmall,
            color: "#121212",
            "&.Mui-selected": { backgroundColor: "rgba(12,12,12,.08)", color: "#000", cursor: "pointer" },
          },
        },
        {
          props: { variant: "logo" },
          style: {
            ...tabButton,
            width: 85,
            maxWidth: 85,
            textAlign: "center",
            marginRight: 5,
          },
        },
        {
          props: { variant: "speed" },
          style: {
            margin: "5px",
            borderRadius: "2px",
            borderColor: "rgba(0,0,0,.32)",
            color: "rgba(0,0,0,.5)",
            fontSize: "11px",
            minHeight: "31px",
            backgroundColor: "rgba(0,0,0,.12)",
            ":hover": {
              backgroundColor: "rgba(0,0,0,.32)",
            },
            ". *": {
              fontSize: "11px",
            },
          },
        },
        {
          props: { variant: "speedLeft" },
          style: {
            margin: "5px",
            borderRadius: "5px",
            borderColor: "rgba(0,0,0,.32)",
            color: "rgba(0,0,0,.5)",
            fontSize: "11px",
            minHeight: "31px",
            backgroundColor: "rgba(0,0,0,.12)",
            marginRight: "0px",
            borderTopLeftRadius: "3px",
            borderBottomLeftRadius: "3px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            ":hover": {
              backgroundColor: "rgba(0,0,0,.32)",
            },
            ". *": {
              fontSize: "11px",
            },
          },
        },
        {
          props: { variant: "speedRight" },
          style: {
            margin: "5px",
            borderRadius: "5px",
            borderColor: "rgba(0,0,0,.32)",
            color: "rgba(0,0,0,.5)",
            fontSize: "11px",
            minHeight: "31px",
            backgroundColor: "rgba(0,0,0,.12)",
            marginLeft: "0px",
            borderLeft: "1px solid rgba(255,255,255,.25)",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderTopRightRadius: "3px",
            borderBottomRightRadius: "3px",
            ":hover": {
              backgroundColor: "rgba(0,0,0,.32)",
            },
            ". *": {
              fontSize: "11px",
            },
          },
        },
        {
          props: { variant: "speedCenter" },
          style: {
            margin: "5px",
            borderRadius: "5px",
            borderColor: "rgba(0,0,0,.32)",
            color: "rgba(0,0,0,.5)",
            fontSize: "11px",
            minHeight: "31px",
            backgroundColor: "rgba(0,0,0,.12)",
            marginLeft: "0px",
            marginRight: "0px",
            borderLeft: "1px solid rgba(255,255,255,.25)",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            ":hover": {
              backgroundColor: "rgba(0,0,0,.32)",
            },
            ". *": {
              fontSize: "11px",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 3,
          boxShadow: "none",
        },
      },
    },

    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          '&[class*="variantInfo"]': {
            backgroundColor: "red",
          },
          backgroundColor: colors.primary.main,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "60px",
          minHeight: "auto",
          maxHeight: "60px",
          overflow: "hidden",
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          color: colors.black.main,
        },
      },
    },

    MuiTooltip: {
      variants: [
        {
          props: { variant: "tour" },
          style: {
            backgroundColor: "rgba(255,255,255,.8)",
            color: colors.black.main,
          },
        },
      ],
    },

    MuiTab: {
      variants: [
        {
          props: { variant: "button" },
          style: {
            ...tabButton,
            ...{
              minWidth: "90px",
              "&.Mui-selected": {
                backgroundColor: "rgba(12,12,12,.04)",
              },
            },
          },
        },
      ],
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
      variants: [
        {
          props: { variant: "close" },
          style: {
            position: "absolute",
            right: 1,
            top: 1,
            margin: "7px 7px",
            borderRadius: "3px",
          },
        },
      ],
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          minWidth: "400px",
          borderRight: "1px solid #111111",
        },
      },
    },

    MuiDivider: {
      variants: [
        {
          props: { variant: "vertical" },
          style: {
            display: "none",
          },
        },
      ],
    },

    MuiList: {
      variants: [
        {
          props: { variant: "top" },
          style: {
            marginTop: "58px",
            padding: "0px",
          },
        },
        {
          props: { variant: "bottom" },
          style: {
            position: "fixed",
            bottom: "0px",
            padding: "0px",
            width: "400px",
          },
        },
      ],
      styleOverrides: {
        root: {
          paddingTop: "0px",
          paddingBottom: "0px",
          width: "100%",
        },
      },
    },

    MuiAccordian: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255,255,255,.8)",
          zIndex: 2000,
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "10px",
          fontSize: "1em",
          marginBottom: "20px",
          borderBottom: "1px solid #12121211",
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.primary.main,
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
  },

  palette: {
    ...{
      background: {
        default: "#FFFFFF",
      },
      common: {
        black: colors.black.main,
        white: colors.white.main,
        gray: colors.gray.main,
      },
    },
    ...colors, // import the brand colors
  },
};

const light = createTheme(
  deepmerge(
    { ...theme },
    {
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: colors.black.main,
            },
          },
        },
      },

      /* Palette stuff */
      palette: {
        mode: "light",
      },

      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(255,255,255,1)",
            zIndex: 2000,
          },
        },
      },

      LoadingScreen: {
        backgroundColor: colors.black.white,
        minHeight: "100vh",
        minWidth: "100vw",
      },
    }
  )
);

const dark = createTheme(
  deepmerge(
    { ...theme },
    {
      components: {
        MuiButton: {
          variants: [
            {
              props: { variant: "tab" },
              style: {
                ...tabButton,
                color: "#FFF",
                ...{
                  "&.Mui-selected": {
                    backgroundColor: "rgba(255,255,255,.08)",
                  },
                },
              },
            },
            {
              props: { variant: "small-tab" },
              style: {
                ...tabButtonSmall,
                color: "#FFF",
                ...{
                  "&.Mui-selected": {
                    backgroundColor: "rgba(255,255,255,.08)",
                  },
                },
              },
            },
          ],
        },
        MuiTab: {
          variants: [
            {
              props: { variant: "button" },
              style: {
                ...tabButton,
                ...{
                  minWidth: "90px",
                  "&.Mui-selected": {
                    backgroundColor: "rgba(255, 255, 255, 0.27)",
                  },
                },
              },
            },
          ],
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              borderRadius: 0,
              backgroundColor: "#646464",
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              color: colors.black.white,
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            thumb: {
              backgroundColor: "#FFF",
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            scrollButtons: {
              color: colors.black.white,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              // Apply the override only for dark mode
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            },
          },
        },
      },

      /* Palette stuff */

      palette: {
        mode: "dark",
        background: {
          default: "#646464",
        },
        paper: {
          backgroundColor: colors.black.main,
          color: colors.black.white,
        },
      },

      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: colors.black.main,
            zIndex: 2000,
          },
        },
      },

      LoadingScreen: {
        backgroundColor: colors.black.main,
      },
    }
  )
);

const shadows = (themeMode = "light") => {
  const rgb = themeMode === "light" ? "#8c98a4" : colors.black.main;

  return [
    "none",
    `0 3px 6px 0 ${alpha(rgb, 0.25)}`,
    `0 12px 15px ${alpha(rgb, 0.8)}`,
    `0 6px 24px 0 ${alpha(rgb, 0.125)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
    `0 10px 40px 10px ${alpha(rgb, 0.175)}`,
  ];
};

const withMediaQuery =
  (queries = []) =>
  (Component) =>
  (props) => {
    const mediaQueries = {};
    queries.forEach((q) => {
      mediaQueries[q[0]] = useMediaQuery(q[1]);
    });
    return <Component mediaQueries={mediaQueries} {...props} />;
  };

export default theme;
export { dark, light, shadows, withMediaQuery };
