/** @format */
import { updateEmail } from "firebase/auth";
import { auth } from "firemade";

export default function setEmail(newEmail) {
  return new Promise((resolve, reject) => {
    // Check if the user is authenticated
    if (!auth.currentUser) {
      return reject("auth/user-not-found");
    }

    updateEmail(auth.currentUser, newEmail)
      .then(() => {
        // Email updated successfully
        return resolve(newEmail);
      })
      .catch((error) => {
        // Handle the error, you can return the error code or message as needed
        return reject(error.code || error.message || "An error occurred while updating email");
      });
  });
}
