/** @format */
import { withErrors } from "errors";
import { withLocales } from "locales";
import { PureComponent, createContext, useContext } from "react";
import { withUnit } from "unit";
import { withUser } from "user";

// Get the user first
import { getUser } from "./helpers/User";

// The the vectore (all other use get ield)
import { getField } from "./helpers/Field";

// Name
import { getEmail } from "./helpers/Email";

// Name formats
import { getDisplayName, getFirstName, getInitials, getLastName } from "./helpers/Name";

// Avatar
import { getAvatar } from "./helpers/Avatar";

// Update the profile
import { updateProfile } from "./helpers/Update";

// Attachment management (delete, paste, upload)
import { uploadImage } from "./helpers/Images";

const ProfilesContext = createContext({});

class Profiles extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ready: true,
      revised: 0,
      users: [],
      uploaded: false,
    };

    // Default user
    this.default = {
      id: null,
      displayName: "",
      email: null,
      photoURL: null,
      meta: {},
      ready: false,
    };

    this.unit = this.props.unit.new(this.constructor.name);

    this.getUser = getUser.bind(this);

    // This should be refactored

    this.getField = getField.bind(this);

    this.getEmail = getEmail.bind(this);

    this.getInitials = getInitials.bind(this);

    this.getLastName = getLastName.bind(this);

    this.getFirstName = getFirstName.bind(this);

    this.displayName = getDisplayName.bind(this);

    this.getAvatar = getAvatar.bind(this);

    // Set custom values
    this.update = updateProfile.bind(this);

    // Attachments
    this.images = {
      upload: uploadImage.bind(this),
    };

    // for unsubscribing from user
    this.unsubscribe = () => {};

    // Make sure we only connect once
    this.connected = false;
  }

  connect = () => {
    if (this.connected) return;
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ ready: true });
        this.connected = true;
        return resolve(this.connection);
      } catch (error) {
        this.setState({ connected: false, connecting: false });
        this.disconnect();
        this.onError(error);
        return reject(error);
      }
    });
  };

  disconnect = () => {
    this.setState({ connected: false, connecting: false });
  };

  onError = (error) => {
    if (this.props.errors.error) this.props.errors.error(this.props.t("unexpectedError"), error);
  };

  queue = async (id) => {
    if (!this.queued.map((queued) => queued.id).includes(id)) {
      this.queued.push({
        id: id,
        queued: true,
        loading: false,
        loaded: false,
        failed: false,
      });
      this.setState({
        users: [...this.state.users, ...[{ ...this.placeholder, id: id }]],
      });
      this.query();
    }
  };

  haveUser = (id, ready = null) => {
    if (ready == true) {
      return this.state.users.findIndex((user) => user.id == id && user.ready == true) != -1;
    } else {
      return this.state.users.findIndex((user) => user.id == id) != -1;
    }
  };

  getUserIndex = (id) => {
    return this.state.users.findIndex((user) => user.id == id);
  };

  getField = (id, field) => {
    try {
      return this.getUser(id)[field];
    } catch (error) {
      return null;
    }
  };

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    this.disconnect();
  }

  render() {
    return (
      <ProfilesContext.Provider
        value={{
          ...this.state,
          get: this.getUser,
          email: this.getEmail,
          name: {
            first: this.getFirstName,
            last: this.getLastName,
            initials: this.getInitials,
            display: this.getDisplayName,
            full: this.getDisplayName,
          },
          avatar: this.getAvatar,
          update: this.update,
          images: this.images,
        }}
      >
        {this.props.children}
      </ProfilesContext.Provider>
    );
  }
}

const withProfiles = (Component) => {
  return function ContextualComponent(props) {
    return <ProfilesContext.Consumer>{(state) => <Component {...props} profiles={state} />}</ProfilesContext.Consumer>;
  };
};

const useProfiles = () => {
  return useContext(ProfilesContext);
};

export default withUnit(withUser(withErrors(withLocales(Profiles))));
export { useProfiles, withProfiles };
