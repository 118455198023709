/** @format */

/** @format */
import { CardMedia } from "@mui/material";
import { alt } from "cdn";
import { useCms } from "cms";
import key from "key";
import React, { useMemo } from "react";
import Carousel from "react-material-ui-carousel";

import { TinyError } from "errors";

export default function (props) {
  return (
    <TinyError>
      <Gallery {...props} />
    </TinyError>
  );
}

export function Gallery({ number }) {
  const {
    page: { blocks },
  } = useCms();

  // Memoize the calculation of Text and tmp based on props.number
  const { Gallery } = useMemo(() => {
    if (!blocks?.length) return { Gallery: null };
    const Gallery = blocks.filter((block) => block.type == "Gallery")[number - 1];

    return { Gallery };
  }, [number, blocks]);

  if (!Gallery?.images || Gallery.images.length == 0) return null;

  return (
    <Carousel duration={2000} indicators={false}>
      {Gallery.images.map((image) => (
        <CardMedia
          role="img"
          {...key("web", "blocks", "gallery", image)}
          component="img"
          image={image}
          alt={alt(image)}
        />
      ))}
    </Carousel>
  );
}
