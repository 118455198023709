/**
 * Collections
 *
 * @format
 */

function hasCollection(name = null) {
  return this.getCollections(name).length > 0 || false;
}

function getCollections(name = null) {
  let result = [];

  if (!name) {
    for (const name in this.state.queries) {
      this.state.queries[name].forEach((item) => {
        try {
          if (Array.isArray(item.collections)) {
            for (const element of item.collections) {
              if (element) result.push(element);
            }
          }
        } catch (e) {}
      });
    }
  } else if (this.state.queries[name]) {
    this.state.queries[name].forEach((item) => {
      try {
        if (Array.isArray(item.collections)) {
          for (const element of item.collections) {
            if (element) result.push(element);
          }
        }
      } catch (e) {}
    });
  }

  // Count the occurrences of each collection
  let collectionCounts = result.reduce((counts, collection) => {
    counts[collection] = (counts[collection] || 0) + 1;
    return counts;
  }, {});

  // Sort collections by popularity and alphabetically
  result.sort((a, b) => {
    let countDiff = collectionCounts[b] - collectionCounts[a];
    if (countDiff === 0) {
      // If collections have the same count, sort them alphabetically
      return a.localeCompare(b);
    } else {
      // Otherwise, sort by popularity
      return countDiff;
    }
  });

  // Remove duplicates
  result = [...new Set(result)];

  return result;
}

function collectionCount(name = null) {
  try {
    return this.getCollections(name).length || 0;
  } catch (error) {
    return 0;
  }
}

function hasCollections(name = null) {
  try {
    return this.getCollections(name).length > 0;
  } catch (_) {
    return false;
  }
}

export { collectionCount, getCollections, hasCollection, hasCollections };
