/** @format */
import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useProfiles } from "../../context";

export default function Name({ id, format = "first" }) {
  // get the profile context
  const profiles = useProfiles();

  const [ready, setReady] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const response = profiles.get(id);
    if (response.ready) {
      // Set that this component is ready
      setReady(true);

      // Set the profile
      setProfile(response);
    }
  }, [profiles.users]);

  // get the name when ready
  const getName = () => {
    if (format) return profiles.name.first(id);

    if (initials) return profiles.name.initials(id);

    return profiles.name.display(id);
  };

  // Wait until we have a name
  if (!ready) return <Skeleton width={200} height={40} />;

  // If we don't have a name, show nothing
  return <Box>{getName()}</Box>;
}
