/**
 * This file exports a React component called UnitComponent, along with several helper functions and objects.
 *
 * The UnitComponent is a context provider that exposes a set of functions to its children components through a context object.
 * These functions are used to report, pass or fail tests for a given unit.
 *
 * The SetupComponent is a helper object that provides a function to set up the testing environment by creating a master object that manages the tests.
 *
 * The FunctionalComponent is a helper object that provides a function to create a unit object with methods to report, pass or fail tests for a given unit.
 *
 * The withUnitComponent is a higher-order component that wraps a given component and passes the unit context object as a prop.
 *
 * This file also imports several dependencies, including camelcase, nanoid, and React.
 *
 * @format
 */

/** @format */
import camelCase from "camelcase";
import { nanoid } from "nanoid";
import { Component as ReactComponent, createContext } from "react";
import defaultConfig from "./config/index.json";
import createMaster from "./libraries/master";

const UnitContext = createContext({});

// eslint-disable-next-line
let config = defaultConfig;
let master = { report: () => {}, passed: () => {}, failed: () => {} };

const SetupComponent = new (class AwaitClass {
  units({ config: localeConfig, units }) {
    // Create units array if units single
    if (!Array.isArray(units)) units = [units];

    // Merge locale config with default config
    if (localeConfig) config = localeConfig;

    return new Promise(async (resolve) => {
      try {
        master = createMaster(units);
        resolve(true);
      } catch (error) {
        console.error(error);
        resolve(error);
      }
    });
  }
})();

const Trigger = {
  report: (unit, payload, from = "~54") => {
    try {
      if (!payload?.method) return;
      let event = camelCase(unit.replaceAll("/", " ") + " " + payload.method.replaceAll("/", " "));
      master.report({ unit: unit, event, ...payload }, from);
    } catch (e) {
      console.error(e, from);
    }
    return nanoid();
  },
  passed: (unit, { testId }, from = "~64") => {
    try {
      master.passed({ unit: unit, testId: testId }, from);
    } catch (e) {
      console.error(e, from);
    }
    return testId;
  },
  failed: (unit, { testId }, from = "~72") => {
    try {
      master.failed({ unit: unit, testId: testId }, from);
    } catch (e) {
      console.error(e, from);
    }
    return testId;
  },
};

const FunctionalComponent = {
  unit: (unit) => {
    return {
      unit: unit,
      report: (props, from) => Trigger.report(unit, props, from),
      passed: (props, from) => Trigger.passed(unit, props, from),
      failed: (props, from) => Trigger.failed(unit, props, from),
    };
  },
};

class UnitComponent extends ReactComponent {
  constructor(props) {
    super(props);
    this.unit = {
      new: (unit) =>
        new (class Unit {
          constructor(unit) {
            if (unit) this.unit = unit;
          }
          report = (props, from) => Trigger.report(this.unit, props, from);
          passed = (props, from) => Trigger.passed(this.unit, props, from);
          failed = (props, from) => Trigger.failed(this.unit, props, from);
        })(unit),
    };
  }
  render() {
    return <UnitContext.Provider value={this.unit}>{this.props.children}</UnitContext.Provider>;
  }
}

const withUnitComponent = (Component) => {
  return function ContextualComponent(props) {
    return (
      <UnitContext.Consumer>
        {(state) => {
          return state ? <Component {...props} unit={state} /> : <></>;
        }}
      </UnitContext.Consumer>
    );
  };
};

export default UnitComponent;
export { FunctionalComponent as Functional, SetupComponent as Setup, withUnitComponent as withUnit };
