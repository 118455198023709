/** @format */
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Text from "@Web/Blocks/Text";
import cdn, { alt } from "cdn";

const placeholder =
  "https://us-central.cdn.made.live/about_us_8a242c32a1/about_us_8a242c32a1.png?updated_at=2023-02-16T22:29:00.318Zhttps://us-central.cdn.made.live/connection_abbdfe4d66/connection_abbdfe4d66.png?updated_at=2023-02-16T22:26:09.059Z";

const Story2 = (props) => {
  const theme = useTheme();

  return (
    <Box style={{ textAlign: "left" }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box
            src={cdn(placeholder)}
            alt={alt(placeholder)}
            sx={{
              objectFit: "cover",
              border: `1px solid #12121211`,
              borderRadius: "3px",
              maxHeight: 360,
              filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
            }}
            component="img"
            loading="lazy"
            height={1}
            width={1}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component={"h2"} fontWeight={700}>
            <Text {...props} number={3} />
          </Typography>
          <Typography component={"div"} color={"text.secondary"} fontWeight={400}>
            <Text {...props} number={4} />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story2;
