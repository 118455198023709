/** @format */
import AvatarMenu from "@App/Menus/Top/components/Right";
import { Box, Button, Hidden } from "@mui/material";
import { TinyError } from "errors";
import { useLocales, withLocales } from "locales";
import Logo from "logo";
import ProjectListener from "projectlistener";
import React, { useEffect, useState } from "react";
import { useRouter } from "router";
import { shadows, withUI } from "ui";
import { withUser } from "user";

import { isSSR } from "isssr";

// Lazy load the components
import Links from "./components/Links";
import Locales from "./components/Locales";
import Menu from "./components/Menu";

const CheckHide = (props) => {
  return isSSR ? <>{props.children}</> : <Hidden {...props}>{props.children}</Hidden>;
};

const Topbar = (props) => {
  const { bgcolor, user, ui } = props;
  const { t, url } = useLocales();
  const { navigate } = useRouter();
  const header = props.header !== undefined ? props.header : true;
  const [elevate, setElevate] = useState(false);

  const localize = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object") {
        // If the value is an object, call the function recursively
        localize(obj[key]);
      } else if (key === "href") {
        // If the key is 'href', replace its value with the url() pattern
        obj[key] = url(obj[key]);
      }
    });
    return obj; // Return the modified object
  };

  const {
    product: productPages,
    company: companyPages,
    blog: blogPages,
    community: communityPages,
  } = localize(props.pages);

  useEffect(() => {
    // Deal with elevating the top menu
    try {
      const trigger = () => {
        if (elevate == false && window.scrollY > 40) {
          setElevate(true);
        } else if (elevate == true && window.scrollY < 40) {
          setElevate(false);
        }
      };
      window.addEventListener("scroll", trigger, { passive: true });
    } catch (e) {}
  }, [elevate]);

  return (
    <Box
      display={"flex"}
      data-testid="topbar"
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
      style={{ position: "fixed", top: "0", width: "100%", zIndex: "1200", padding: "2px" }}
      sx={{
        backgroundColor: elevate ? bgcolor || "#FAFAFA" : "transparent",
        transition: "background-color 200ms linear; boxShadow: 200ms linear; ",
        boxShadow: elevate ? shadows()[1] : "none",
      }}
      className="TopBar"
    >
      <Box
        display={"flex"}
        onClick={() => (isSSR ? () => {} : (document.location.href = "/"))}
        component="span"
        title="Made Live"
        width={{ xs: 100, md: 120, marginLeft: ".5em" }}
        data-place="web-topbar"
      >
        <Logo zoom={1} wordmark={true} {...props} colorInvert={elevate ? false : props.colorInvert} />
      </Box>
      {header && (
        <Box sx={{ display: "flex" }} alignItems={"center"}>
          <CheckHide mdUp>
            <Menu {...props} items={[...productPages, ...companyPages, ...blogPages]} />
          </CheckHide>
          <CheckHide mdDown>
            <Box marginLeft={4}>
              <Links
                elevated={elevate}
                title={t("topbarProduct")}
                id={"product-pages"}
                items={productPages}
                {...props}
              />
            </Box>
            <Box marginLeft={4}>
              <Links
                elevated={elevate}
                title={t("topbarCompany")}
                id={"company-pages"}
                items={companyPages}
                {...props}
              />
            </Box>
            <TinyError>
              {user.authenticated && user.meta.get("beta") && (
                <Box marginLeft={4}>
                  <Links
                    elevated={elevate}
                    title={t("topbarCommunity")}
                    id={"community-pages"}
                    items={communityPages}
                    {...props}
                  />
                </Box>
              )}
            </TinyError>
            <Box marginLeft={4}>
              <Links elevated={elevate} title={t("topbarBlog")} id={"blog-pages"} items={blogPages} {...props} />
            </Box>
            {user?.authenticated && user?.meta?.get("beta") && (
              <Box marginLeft={2}>
                <Locales colorInvert={elevate ? false : props.colorInvert} elevate={elevate} />
              </Box>
            )}
            {!user.id ? (
              <>
                <Box marginLeft={4} marginRight={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    component="a"
                    target="blank"
                    onClick={() => navigate(url("pages/onboarding"))}
                  >
                    {t("topbarSignUp")}
                  </Button>
                </Box>
                <Box marginRight={2}>
                  <Button variant="contained" color="primary" component="a" onClick={() => ui.dialog.open("signin")}>
                    {t("topbarSignIn")}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box marginLeft={4}>
                  <ProjectListener location="topbar" />
                </Box>
                <Box marginRight={2}>
                  <AvatarMenu />
                </Box>
              </>
            )}
          </CheckHide>
        </Box>
      )}
    </Box>
  );
};

export default withUI(withUser(withLocales(withUI(Topbar))));
