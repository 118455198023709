/** @format */
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { app } from "firemade";
import { nanoid } from "nanoid";
import { environment } from "version";

import buckets from "../../config/buckets.json";

/**
 * Paste an attachment from the clipboard.
 *
 * @param {Blob} blob - The blob object representing the attachment.
 * @returns {Promise<void>} - A promise that resolves when the attachment is pasted.
 */
export async function pasteAttachment(blob) {
  let tmp = this;
  try {
    // Create a FileReader to read the data from the blob
    var reader = new FileReader();

    // Read the content of the blob (which is the pasted data) as a data URL
    reader.readAsDataURL(blob);

    // Define what happens on reader load end
    reader.onloadend = function () {
      // Get the base64 data from the reader
      var base64data = reader.result;

      // Create an object to save the attachment information
      let save = {
        id: nanoid(), // Generate a unique ID for the attachment
        type: "image", // Set the type of the attachment
        blob: blob, // Store the original blob
        url: base64data, // Store the base64 representation of the image
        title: "Pasted from clipboard.", // A default title for the attachment
        extension: blob.type.split("/")[1], // Extract the extension from the blob type
      };

      // Create a new array of attachments and add the new attachment
      let attachments = [...tmp.state.attachments];
      attachments.push(save);

      // Update the state with the new list of attachments
      tmp.setState({ attachments: attachments });
    };
  } catch (error) {
    // Error handling
    if (this.props.errors.error) this.props.errors.error("There was a problem pasting the media.", error, "~48");
  }
}

/**
 * Uploads files asynchronously.
 * @param {FileList} files - The list of files to upload.
 * @returns {Promise<boolean>} - A promise that resolves to true if the upload is successful.
 */
export async function uploadAttachment(files) {
  // Set that we're uploading something
  this.setState({ uploading: true });

  return new Promise((resolve, reject) => {
    try {
      // Create a new array of attachments and add the new attachment
      let attachments = [...this.state.attachments];

      Object.keys(files).forEach((key, i) => {
        let file = files[key];
        let extension = file.name.split(".").pop(); // Extract the file extension from the file name

        let reader = new FileReader();
        reader.readAsDataURL(file); // Convert the file to a data URL

        reader.onloadend = () => {
          // Create an object to save the attachment information
          let save = {
            type: "image",
            blob: file, // Use the file as a blob directly
            url: reader.result, // Base64 representation of the image
            title: file.name,
            extension: extension,
          };

          attachments.push(save);

          // Update the state with the new list of attachments
          this.setState({ attachments: attachments });
        };
      });

      this.setState({ uploading: false });

      resolve(true);
    } catch (error) {
      if (this.props.errors.error) this.props.errors.error(error);

      this.setState({ uploading: false });
      reject(error);
    }
  });
}

/**
 * Saves attachments to a Firebase storage bucket and updates their URLs.
 * @returns {Promise<void>} A promise that resolves when all attachments are saved.
 */
export async function saveAttachments() {
  const { errors } = this.props;
  const storage = getStorage(app, buckets[environment]);

  // Function to convert base64 to blob
  const base64ToBlob = async (base64, contentType) => {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  // Process each attachment
  const attachmentPromises = this.state.attachments.map(async (attachment) => {
    if (attachment.url.startsWith("data:")) {
      // Base64 encoded image
      const base64String = attachment.url.split(",")[1];
      const contentType = attachment.url.match(/data:([^;]+);base64,/)[1];
      const blob = await base64ToBlob(base64String, contentType);

      // Continue with upload process
      const uniqueName = `${nanoid()}.${attachment.extension}`;
      const storageRef = ref(storage, uniqueName);

      try {
        const snapshot = await uploadBytes(storageRef, blob);
        const url = await getDownloadURL(snapshot.ref);
        return url;
      } catch (error) {
        return null; // Return null on error
      }
    } else {
      // Already uploaded
      return attachment;
    }
  });

  try {
    const updatedAttachments = await Promise.all(attachmentPromises);
    const filteredAttachments = updatedAttachments.filter((att) => att !== null); // Filter out null values

    return filteredAttachments; // Return filtered attachments
  } catch (error) {
    errors.error(true, error, "~164");
    return [];
  }
}

/**
 * Clears the attachments attachments.
 */
export function clearAttachments() {
  this.setState({ attachments: [] });
}

/**
 * Deletes an attachment based on its type and ID.
 *
 * @param {string} type - The type of the attachment.
 * @param {string} id - The ID of the attachment.
 */
export function deleteAttachment(id) {
  try {
    this.setState((prevState) => ({
      attachments: prevState.attachments.filter((queue) => queue.id !== id),
    }));
  } catch (_) {}
}

/**
 * Checks if there are any attachments queued for upload.
 * @returns {boolean} True if there are attachments queued, false otherwise.
 */
export function hasAttachments() {
  try {
    return this.state.attachments.length > 0;
  } catch (_) {
    return false;
  }
}

/**
 * Retrieves the attachment list from the component's state.
 * @returns {Array} The attachment list.
 */
export function getAttachmentList() {
  try {
    return this.state.attachments || [];
  } catch (_) {
    return [];
  }
}
