/** @format */
import { Box, ImageList, ImageListItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alt } from "cdn";
import { useCms } from "cms";
import React, { useMemo } from "react";

import Layout from "./helpers/Layout";

import { TinyError } from "errors";

export default function (props) {
  return (
    <TinyError>
      <ImageGrid {...props} />
    </TinyError>
  );
}

export function ImageGrid(props) {
  const { page } = useCms();
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  // Memoize the computation of grids and images based on props and number
  const { grids, images } = useMemo(() => {
    const grids = page.blocks.filter((block) => block.type === "ImageGrid");

    const number = props.number - 1 || 0;
    const images = grids.length > 0 && grids[number] ? Layout(grids[number].images) : [];

    return { grids, images };
  }, [page.blocks, props.number]);

  // Early return if there are no grids or the specified grid number doesn't exist
  if (grids.length === 0 || !images.length) return null;

  return (
    <Box>
      <ImageList role="list-items" variant="quilted" cols={3} rowHeight={isMd ? 300 : 200} gap={isMd ? 16 : 4}>
        {images.map((item, i) => (
          <ImageListItem key={i} cols={item.cols} rows={item.rows}>
            <Box
              component="img"
              role="img"
              loading="lazy"
              height={"100%"}
              width={"100%"}
              src={item.src}
              alt={alt(item.src || placeholder)}
              style={{
                objectFit: "cover",
                filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                cursor: "poiner",
                borderRadius: 3,
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}
