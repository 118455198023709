/** @format */
import Container from "@Web/Container";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Cdn from "cdn";
import { withLocales } from "locales";

const Hero = (props) => {
  const theme = useTheme();
  const { t } = props;
  return (
    <Box
      position={"relative"}
      data-lazybg={Cdn(
        "https://us-central.cdn.made.live//thumbnail_partnership_program_8509141f34/thumbnail_partnership_program_8509141f34.png?updated_at=2023-02-21T23:51:54.354Z"
      )}
      data-testid="hero-container"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: -13,
        paddingTop: 13,
        "&:after": {
          position: "absolute",
          content: '" "',
          width: "100%",
          height: "100%",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          background: "#161c2d",
          opacity: 0.6,
        },
      }}
    >
      <Container
        zIndex={3}
        position={"relative"}
        minHeight={{ xs: 300, sm: 400, md: 600 }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <Box marginBottom={2}>
            <Typography
              variant="h1"
              align={"center"}
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              {t("ourPartnersHeader")}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h4"
              align={"center"}
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              {t("ourPartnersSubheader")}
            </Typography>
          </Box>
        </Box>
      </Container>
      <Box
        component={"svg"}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        width={1}
        marginBottom={-1}
        position={"relative"}
        zIndex={2}
      >
        <path fill={"rgba(250,250,250)"} d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
      </Box>
    </Box>
  );
};

export default withLocales(Hero);
