/** @format */

import { Functional } from "unit";
const unit = Functional.unit("workspace/board");

export function openBoard(feature, props = {}, from = "~6") {
  const { board } = props;
  let testId = unit.report({
    method: "openBoard",
    payload: { dialog: board },
    test: "A draggable board should be available to use here.",
    steps: [
      "From storyboard or illustrate",
      "Click media button in bottom menu",
      "A media board should open up",
      "Use should be able to drag media board",
    ],
  });
  try {
    let boards = { ...this.state.settings.boards };

    if (!boards[feature]) boards[feature] = {};
    if (!boards[feature][board]) {
      boards[feature][board] = {
        slug: board,
        props: props,
        opened: true,
        x: 600,
        y: 20,
        width: 300,
        height: 500,
        minimized: false,
      };
      this.setState({
        settings: {
          ...this.state.settings,
          ...{ boards: boards },
        },
      });
    } else {
      boards[feature][board].opened = true;
      this.setState({
        settings: {
          ...this.state.settings,
          ...{ boards: boards },
        },
      });
    }
    unit.passed({ testId: testId });
  } catch (error) {
    console.error(error, "~51");
    unit.passed({ testId: testId, error: error });
    this.props.errors.error(this.props.t("unexpectedError"), error, "~53");
  }
}

export function closeBoard(feature, board = null, from = "~57") {
  let testId = unit.report({
    method: "closeBoard",
    test: "When a board is expected to close, it does so without issue and all menu options are available.",
    steps: [
      "From storyboard or illustrate",
      "Click media button in bottom menu",
      "A media board should open up",
      "Use should be able to drag media board",
    ],
  });
  try {
    let boards = { ...this.state.settings.boards };

    if (!boards[feature]) return;

    if (!board && boards[feature][feature]) {
      boards[feature][feature].opened = false;
    } else if (!board && !boards[feature][feature] && Object.keys(boards[feature][feature]).length > 0) {
      boards[feature][Object.keys(obj)[0]].opened = false;
    } else if (board && boards[feature][board]) {
      boards[feature][board].opened = false;
    } else {
      return;
    }

    this.setState((prevState) => {
      return {
        settings: {
          ...prevState.settings,
          boards: boards,
        },
      };
    });

    unit.passed({ testId: testId });
  } catch (error) {
    unit.failed({ testId: testId, error: error });
    this.props.errors.error(this.props.t("unexpectedError"), error, "~95");
  }
}

export function minimizeBoard(feature, board, from = "~99") {
  let testId = unit.report({
    method: "minimizeBoard",
    test: "Shrink a board",
    steps: [
      "From storyboard or illustrate",
      "Click media button in bottom menu",
      "A media board should open up",
      "Use should be able to drag media board",
    ],
  });
  try {
    let boards = { ...this.state.settings.boards };
    if (!board && boards[feature][feature]) {
      boards[feature][feature].minimized = !boards[feature][feature].minimized;
    } else if (!board && !boards[feature][feature] && Object.keys(boards[feature][feature]).length > 0) {
      boards[feature][Object.keys(obj)[0]].minimized = !boards[feature][Object.keys(obj)[0]].minimized;
    } else if (board && boards[feature][board]) {
      boards[feature][board].minimized = !boards[feature][board].minimized;
    }

    this.setState((prevState) => {
      return {
        settings: {
          ...prevState.settings,
          boards: boards,
        },
      };
    });

    unit.passed({ testId: testId });
  } catch (error) {
    const { errors } = this.props;
    unit.failed({ testId: testId, error: error });
    errors.error(true, error, "~133");
  }
}

export function isOpenBoard(feature, board, from = "~137") {
  try {
    unit.report({
      method: "isOpenBoard",
    });
    const boards = { ...this.state.settings.boards };
    return boards[feature] && boards[feature][board].opened;
  } catch (error) {
    this.props.errors.error(this.props.t("unexpectedError"), error, "~145");
  }
}

export function getBoards(feature = null) {
  return feature == null ? this.state.settings.boards : this.state.settings.boards[feature] || null;
}
