/** @format */

import { Alert, Box, Card, Divider, styled } from "@mui/material";
import React from "react";

import { ComponentError } from "errors";
import { useLocales } from "locales";
import { Loader } from "performing";
import { useProfiles } from "profiles";
import { useUser } from "user";
import { useChat } from "../../../../context";

// The individual components
import Chats from "./components/Chats";
import Header from "./components/Header";
import Input from "./components/Input";
import Messages from "./components/Messages";
import Users from "./components/Users";

/**
 * Represents a styled card component.
 *
 * @component
 */
const StyledCard = styled(Card)(() => ({
  "&": {
    overflow: "scroll",
    minHeight: "calc(100vh - 120px)",
    minWidth: "30vw",
  },
}));

/**
 * Renders the body of the chat window.
 *
 * @returns {JSX.Element} The rendered body component.
 */
const Window = () => {
  const chat = useChat();
  const { t } = useLocales();
  const profiles = useProfiles();
  const { authenticated } = useUser();

  // If we're not ready yet
  if (!chat.ready) return <Loader label={t("loading...")} width={60} />;

  return (
    <ComponentError>
      <StyledCard sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Header />
        {!authenticated ? (
          <Alert severity="info">{t("youNeedToBeLoggedIn")}</Alert>
        ) : chat.chats.list().length === 0 ? (
          <>
            <Alert severity="info">{t("youHaventSpokenYet")}</Alert>
            <Divider />
            <Box
              sx={{
                maxHeight: "calc(100% - 0px)",
                top: "100px",
                width: "100%",
                position: "fixed",
                overflow: "scroll",
              }}
            >
              <Users />
            </Box>
          </>
        ) : chat.id == null ? (
          <>
            <Chats chat={chat} t={t} profiles={profiles} />
          </>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Box
              sx={{
                maxHeight: "calc(100% - 290px)",
                top: "220px",
                width: "100%",
                position: "fixed",
                overflow: "scroll",
              }}
            >
              <Messages chat={chat} profiles={profiles} />
            </Box>
            <Input chat={chat} t={t} profiles={profiles} />
          </Box>
        )}
      </StyledCard>
    </ComponentError>
  );
};

export default Window;
