/**
 * This module exports a function that reports a payload to the console.
 *
 * @format
 * @module report
 */

/** @format */

import Console from "../";
import { enabled } from "../config";

let units = {};

/**
 * Reports a payload to the console.
 * @param {Object} payload - The payload to report.
 */
export function report(payload) {
  if (!enabled || !payload.unit || !payload.method) return;
  if (!units[payload.unit]) units[payload.unit] = new Console(payload.unit);
  units[payload.unit].report(payload);
  return;
}

export default report;
