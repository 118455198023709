/**
 * Text
 *
 * @format
 */
import { Functional } from "unit";
import SketchTemplate from "../../config/templates/sketch.json";

const unit = Functional.unit("designer/text");

/* New */

export function getSketchSize(size, from) {
  unit.report({
    method: "getSketchSize",
    message: "gets the sketch size.",
    analyze: true,
    action: true,
    from: from,
  });
  return size ? this.state.sketch.size == size : this.state.sketch.size;
}

export function setSketchSize(size, from) {
  unit.report({
    method: "setSketchSize",
    message: "Sets the sketch size.",
    analyze: true,
    action: true,
    from: from,
  });
  try {
    return this.setState({ sketch: { ...this.state.sketch, size: size } });
  } catch (_) {}
}

export function getSketchColor(color, from) {
  unit.report({
    method: "getSketchColor",
    message: "Sets the sketch color.",
    analyze: true,
    action: true,
    from: from,
  });
  return color ? this.state.sketch.color == color : this.state.sketch.color;
}

export function setSketchColor(color, from) {
  unit.report({
    method: "setSketchColor",
    message: "Sets the sketch color.",
    analyze: true,
    action: true,
    from: from,
  });
  try {
    return this.setState((prevState) => {
      return { ...prevState, sketch: { ...prevState.sketch, color: color } };
    });
  } catch (_) {}
}

/* Add */

export function addElementSketch(props, from = "~65") {
  unit.report({
    method: "addElementSketch",
    payload: event,
    action: true,
    analyze: true,
    test: "Create a sketch image element from .",
    from: from,
  });
  const { t, errors } = this.props;
  return new Promise((resolve, reject) => {
    try {
      const { left, top, width, height, url, id } = props;

      const element = {
        ...SketchTemplate,
        id: id,
        url: url,
        style: [
          {
            ...SketchTemplate.style[0],
            width: width,
            height: height,
            left: left,
            top: top,
          },
        ],
      };

      // console.log(element);
      resolve(id);
      this.element.add(element).then(() => {
        resolve(id);
      });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~100");
      reject(error);
    }
  });
}
