/** @format */
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "firemade";

export default function connect(user, onUpdated) {
  return new Promise(async (resolve, reject) => {
    try {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        if (!doc.exists()) {
          onUpdated(null);
        } else {
          onUpdated({ ...user, ...doc.data() }, "~12");
        }
        resolve(true);
      });
    } catch (error) {
      reject(error);
    }
  });
}
