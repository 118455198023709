/** @format */
import { Component as ReactComponent, createContext, useContext } from "react";

import { collection, getDocs } from "firebase/firestore";

import { withBroadcast } from "broadcast";
import { db } from "firemade";
import { Sign } from "sign";
import { withUI } from "ui";
import UrlPattern from "url-pattern";
import { withUser } from "user";

import Library from "./libraries";

const ActionsContext = createContext({});

class Actions extends ReactComponent {
  constructor(props) {
    super(props);
    let { aliases, components } = new Library(props);
    this.aliases = aliases;
    this.components = components;
    this.day = 86400;
    this.link = new UrlPattern("/actions(/:signature)");
    this.links = {};
  }

  connect = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await getDocs(collection(db, "actions"));
        querySnapshot.forEach((doc) => {
          this.links[change.doc.id] = doc.data();

          this.checkLink("~49");
        });
        resolve(true);
      } catch (error) {
        this.disconnect();
        return reject(error, "~58");
      }
    });
  };

  disconnect = () => {};

  ready = () => {};

  actions = (component = null) => {
    if (!component) return;

    try {
      let tmp = this.components[component.toLowerCase()] || {};
      return tmp;
    } catch (_) {}
  };

  checkLink = () => {
    try {
      let has = this.link.match(document.location.pathname);
      if (has && has.signature) this.runLink(Sign.unsign(has.signature, "checkLink"));
    } catch (_) {}
  };

  runLink = (payload) => {
    try {
      this.aliases[payload.alias](payload.props);
    } catch (_) {}
  };

  createLink = (props = {}) => {
    return new Promise((resolve, reject) => {
      try {
        if (!props.alias) return;

        let payload = { alias: props.alias, props: {}, user: {}, expires: Date.now() + 3650 * this.day };

        if (props && props.expires) {
          if (typeof payload.expires === "boolean" && payload.expires === false) {
            payload.expires = Date.now() + 3650 * this.day;
          } else if (typeof payload.expires === "boolean" && payload.expires === true) {
            payload.expires = Date.now() + 7 * this.day;
          } else if (typeof payload.expires === "number") {
            payload.expires = Date.now() + parseInt(7, 10) * this.day;
          }
          try {
            delete props.expires;
          } catch (error) {
            console.error(error, "~176");
          }
        }

        if (props && props.user) {
          payload.user = {};
          if (props.user.to) payload.user.to = props.user.to;
          if (props.user.from) payload.user.from = props.user.from;
          delete props.expires;
        }

        if (props.props) payload.props = props.props;

        let signature = Sign.sign(payload);

        if (
          Object.keys(this.links).length == 0 ||
          !Object.values(this.links).filter((link) => link.signature == signature)
        ) {
          this.connection.add({ ...{ signature: signature }, ...{ expires: payload.expires } }).then((doc) => {
            resolve(doc.id);
          });
        }
      } catch (_) {
        reject(error, "~214");
      }
    });
  };

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    try {
      this.disconnect();
    } catch (_) {}
  }

  render() {
    return (
      <ActionsContext.Provider
        value={{
          functions: (component) => this.actions(component),
          run: {
            action: this.components,
            alias: this.aliases,
            link: (id) => this.runLink(id),
          },
          create: {
            action: this.createAction,
            link: (alias, props) => this.createLink(alias, props),
          },
        }}
      >
        {this.props.children}
      </ActionsContext.Provider>
    );
  }
}

const useActions = () => {
  return useContext(ActionsContext);
};

const withActions = (Component) => {
  return function ContextualComponent(props) {
    return (
      <ActionsContext.Consumer>
        {(state) => (
          <>
            <Component {...props} actions={state} action={state.run.action} />
          </>
        )}
      </ActionsContext.Consumer>
    );
  };
};

export default withBroadcast(withUI(withUser(Actions)));
export { useActions, withActions };
