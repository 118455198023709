/** @format */

import { mergeStyles } from "./Style";

/**
 * Sets the moving state to true and merges styles for the target element(s).
 *
 * @param {Object} params - The parameters object.
 * @param {HTMLElement} [params.target] - The target element to apply the merged styles to (for single element).
 * @param {HTMLElement[]} [params.targets] - The target elements to apply the merged styles to (for group).
 */
export function setMovingOn({ target, targets }) {
  if (this.state.writing) return;

  const applyMergedStyle = (element) => {
    const mergedStyle = mergeStyles.call(this, element, true);
    Object.assign(element.style, mergedStyle);
  };

  try {
    if (targets && targets.length) {
      targets.forEach(applyMergedStyle);
    } else if (target) {
      applyMergedStyle(target);
    } else {
      return;
    }

    if (!this.state.moving) {
      this.setState({ moving: true });
    }
  } catch (e) {}
}

/**
 * Sets the moving state to off and updates the element's style(s).
 *
 * @param {Object} params - The parameters object.
 * @param {HTMLElement} [params.target] - The target element (for single element).
 * @param {HTMLElement[]} [params.targets] - The target elements (for group).
 */
export function setMovingOff({ target, targets }) {
  if (this.state.writing) return;

  const saveMergedStyle = (element) => {
    const mergedStyle = mergeStyles.call(this, element, false);
    this.element.style.save(element, mergedStyle);
  };

  try {
    if (this.state.moving) {
      if (targets && targets.length) {
        targets.forEach((element) => saveMergedStyle(element));
      } else if (target) {
        saveMergedStyle(target);
      } else {
        return;
      }

      this.setState({ moving: false });
    }
  } catch (e) {}
}
