/** @format */
import { Box, Button, Stack, TextField } from "@mui/material";
import { useLocales } from "locales";

export default function Features({ setEmail, disabled, send, working, setMessage }) {
  const { t } = useLocales();

  return (
    <Stack direction="column" spacing={1} sx={{ marginLeft: "10px" }}>
      <h4 style={{ margin: "5px 10px 10px 0px" }}>{t("featureRequests")}</h4>
      <strong>{t("emailAddress")}</strong>
      <TextField
        sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
        disabled={disabled}
        placeholder={t("enterYourEmailAddress")}
        onKeyUp={({ target }) => setEmail(target.value)}
        multiline={true}
        fullWidth={true}
      />
      <strong>{t("enterYourFeatureDescription")}</strong>
      <TextField
        sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
        disabled={disabled}
        placeholder={t("enterYourFeatureDescription")}
        onKeyUp={({ target }) => setMessage(target.value)}
        multiline={true}
        fullWidth={true}
        rows={12}
      />
      {!working && (
        <Stack direction="row" justifyContent="flex-end" spacing={5}>
          <Box sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              disabled={false && value.length < 10}
              onClick={() => send()}
              color="success"
              size="large"
            >
              {t("send")}
            </Button>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
