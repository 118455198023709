/** @format */
import { updatePassword } from "firebase/auth";
import { auth } from "firemade";

export default function setPassword(password) {
  return new Promise((resolve, reject) => {
    // User is no longer logged in
    if (!auth.currentUser.uid) return reject("notAuthenticated");

    updatePassword(auth.currentUser, password)
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        return reject(error.code);
      });
  });
}
