/** @format */
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";

import { TinyError } from "errors";

export default function (props) {
  return (
    <TinyError>
      <TextAction {...props} />
    </TinyError>
  );
}

export function TextAction(props) {
  const { icon } = props;
  let theme = useTheme();
  return (
    <>
      {icon && (
        <Box sx={{ textAlign: "center", padding: "2%" }} data-testid="TextIcon">
          <DynamicIcon size="2x" icon={icon} color={theme.palette.secondary.main} />
        </Box>
      )}
    </>
  );
}
