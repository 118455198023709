/** @format */

import { Functional } from "unit";
import { setupKeydown } from "./Keydown";
import { setupSpreads } from "./Spreads";

const unit = Functional.unit("Manuscript");

export async function setupManuscript(from = "~9") {
  const { workspace } = this.props;

  let testId = null;
  unit.report({
    method: "setupManuscript",
    message: "Setting up desk from " + from + ".",
    test: "Workspace should begin to load showing progress as each individual component loads.",
    from: from,
  });
  const { performing } = this.props;

  // Set the appopriate zoom
  workspace.zoom.set(50);

  performing.set.progress({
    percent: 10,
    title: "Setting up this.",
  });

  return await new Promise(async (resolve, reject) => {
    try {
      testId = unit.report({
        method: "setupSpreads",
        test: "Spreads should load without errors.",
        message: "setupProject error wrapper loading from /Usable/setup.js.",
        from: "~35",
      });

      await setupSpreads("~38")
        .then(() => {
          unit.report(
            {
              method: "setupSpreads",
              message: "Spreads setup successfully.",
              passed: true,
              testId: testId,
            },
            "~47"
          );
          this.ready.set("spreads");
          performing.set.progress({
            percent: 80,
            title: "Setting up spreads.",
          });
          resolve(true);
        })
        .catch((e) => {
          unit.failed(
            {
              testId: testId,
            },
            "~61"
          );
          return reject({
            from: "~64",
            e: e,
            message: "Unable to setup undo, redo buttons.",
            fatal: true,
          });
        });

      await setupKeydown
        .call(this, "~72")
        .then(() => {
          unit.report(
            {
              method: "setupKeydown",
              message: "Spreads setup successfully.",
              passed: true,
              testId: testId,
            },
            "~81"
          );
          this.ready.set("keydown");
          performing.set.progress({
            percent: 80,
            title: "Setting up hotkeys.",
          });
          resolve(true);
        })
        .catch((e) => {
          unit.failed(
            {
              testId: testId,
            },
            "~95"
          );
          return reject({
            from: "~98",
            e: e,
            message: "Unable to setup undo, redo buttons.",
            fatal: true,
          });
        });
    } catch (error) {
      unit.failed(
        {
          testId: testId,
          error: error,
        },
        "~110"
      );
      reject(error);
    }
    this.ready.set("all");
  });
}
