/** @format */
import { Box, styled } from "@mui/material";
import { TinyError } from "errors";
import key from "key";
import { Working } from "performing";
import React, { useEffect, useRef, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import isSSR from "isssr";

import Message from "./components/Message";

const StyledTemplate = styled(Box)(({ theme }) => ({
  "&": {
    width: "100%",
  },
  "& .message": {
    color: theme.palette.mode == "light" ? "rgba(18,18,18,.5)" : "rgba(250,250,250,.7)",
    whiteSpace: "pre-line",
    marginTop: 0,
  },
}));

/**
 * Renders the messages component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.chat - The chat object.
 * @param {Array} props.profiles - The profiles array.
 * @returns {JSX.Element} The rendered component.
 */
export default function ({ chat, profiles }) {
  // Get the messages
  const messages = chat.messages();

  // The ref to scroll to the bottom on change
  const messagesEndRef = useRef(null);

  const [sensor, setSensor] = useState(false);

  // Paging messages
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = -10; // Adjust this number as needed

  // Move to the bottom of the window
  const moveBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Enable the top sensor
  const enableSensor = (visibility) => {
    // Set the top sensor
    if (visibility == true) setSensor(true);
  };

  // Load more messages
  const loadPrevious = (visibility) => {
    // Load
    if (visibility == true) {
      // Set the current page
      setCurrentPage(currentPage + 1);

      if (isSSR) return;

      try {
        setTimeout(() => {
          // get the message id to scroll to
          const elementId = `chat-message-${messages.slice(currentPage * messagesPerPage)[0].timestamp.created}`;
          const element = document.getElementById(elementId);

          if (element) {
            element.scrollIntoView();
          }
        }, 100);
      } catch (_) {}
    }
  };

  // When new messages come in scroll to the bottom
  useEffect(() => {
    // Scroll to the bottom every time messages change
    moveBottom();
  }, [messages]);

  // Wait a few seconds before enabling the sensor
  useEffect(() => {
    // This doesn't look right
    setTimeout(() => {
      enableSensor(true);
    }, 3000);
  }, []);

  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      {/* Endable the sensor to find previously loaded messages */}
      {sensor && (
        <VisibilitySensor onChange={loadPrevious} partialVisibility={true}>
          <Box>{currentPage * messagesPerPage * -1 < messages.length && <Working />}</Box>
        </VisibilitySensor>
      )}
      {messages.slice(currentPage * messagesPerPage).map((message, index) => (
        <TinyError {...key("chat", "messages", "message", index)}>
          <StyledTemplate id={`chat-message-${message.timestamp.created}`}>
            <Message chat={chat} profiles={profiles} message={message} />
          </StyledTemplate>
        </TinyError>
      ))}
      {/* Scrolling to the end */}
      <Box ref={messagesEndRef} />
    </Box>
  );
}
