/** @format */

import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { withCms } from "cms";
import React from "react";

// Lazy load the components
import SidebarNewsletter from "@Web/Blocks/SidebarNewsletter";
import Content from "./components/Content";
import FooterNewsletter from "./components/FooterNewsletter";
import Hero from "./components/Hero";
import SidebarArticles from "./components/SidebarArticles";
import SimilarStories from "./components/SimilarStories";

const StyledArticle = styled(Box)(({ theme }) => ({
  "& blockquote": {
    margin: "60px 0",
    borderLeft: theme.palette.secondary.main + " 5px solid",
    p: {
      marginBottom: "10px",
    },
  },
  p: {
    marginBottom: "40px",
  },
}));

const BlogArticle = (props) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Main colorInvert={true}>
      <Box>
        <Hero {...props} />
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <StyledArticle>
                <Content {...props} />
              </StyledArticle>
            </Grid>
            <Grid item xs={12} md={4}>
              {isMd && (
                <Box marginBottom={4}>
                  <SidebarArticles {...props} orderBy="createdAt.desc" start={9} end={12} />
                </Box>
              )}
              <SidebarNewsletter {...props} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor={"thirdly.main"}>
        <Container>
          <SimilarStories {...props} orderBy="createdAt.desc" start={12} end={15} width={4} />
        </Container>
        <Container>
          <FooterNewsletter {...props} />
        </Container>
      </Box>
      <Box
        component={"svg"}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          marginBottom: -1,

          width: 1,
        }}
      >
        <path fill={theme.palette.background.paper} d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
      </Box>
    </Main>
  );
};

export default withCms(BlogArticle);
