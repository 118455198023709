/** @format */

import { Functional } from "unit";

const unit = Functional.unit("Keypress");

// Keypress wrapper
function keyPress(event) {
  const handler = this.keypressHandlers[event.key];
  if (handler) {
    handler(event.shiftKey);
  }
}

// Setup & teardown

export function setupKeypress(from = "~17") {
  unit.report({
    method: "setupKeypress",
    message: "Setting up keypresses.",
    tests: "Keypresses should register now.",
    from: from,
  });

  return new Promise((resolve, reject) => {
    try {
      // Setup an easy method to key mapping
      this.keypressHandlers = {
        ArrowLeft: keypressLeft.bind(this),
        ArrowRight: keypressRight.bind(this),
        ArrowUp: keypressUp.bind(this),
        ArrowDown: keypressDown.bind(this),
        Escape: keypressEsc.bind(this),
      };

      document.addEventListener("keydown", keyPress.bind(this));
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

export function teardownKeypress(from = "~44") {
  unit.report({
    method: "teardownKeypress",
    message: "Tearing down keypresses.",
    tests: "Keypresses should be unregistered now.",
    from: from,
  });
  try {
    document.removeEventListener("keydown", keyPress.bind(this));
  } catch (error) {
    reject(error);
  }
}

// Event handlers
function boundMove(direction, shiftKeyPressed, from = "~59") {
  unit.report({
    method: "boundMove",
    message: "Moving the selected element.",
    tests:
      "The selected element should move smoothly and the final position should be saved to the db. Confirmed with reload.",
    from: from,
  });
  // Helper function to parse a style value as a float
  function parseStyleValue(value) {
    return parseFloat(value);
  }

  try {
    if (this.state.writing) return;

    const selectedElement = this.element.selected("~75");

    if (!selectedElement) return;

    const increment = shiftKeyPressed ? 10 : 1; // Use a larger increment if the shift key is pressed

    // Inside your boundMove function
    const width = parseStyleValue(selectedElement.style.width);
    const height = parseStyleValue(selectedElement.style.height);
    const top = parseStyleValue(selectedElement.style.top);
    const left = parseStyleValue(selectedElement.style.left);

    // And do the same when fetching bounds from this.state.bounds
    const boundsTop = parseStyleValue(this.state.bounds.top);
    const boundsRight = parseStyleValue(this.state.bounds.right);
    const boundsBottom = parseStyleValue(this.state.bounds.bottom);
    const boundsLeft = parseStyleValue(this.state.bounds.left);

    let adjustedTop = top;
    let adjustedLeft = left;

    switch (direction) {
      case "left":
        adjustedLeft = Math.max(boundsLeft, left - increment);
        break;
      case "right":
        adjustedLeft = Math.min(boundsRight - width, left + increment);
        break;
      case "up":
        adjustedTop = Math.max(boundsTop, top - increment);
        break;
      case "down":
        adjustedTop = Math.min(boundsBottom - height, top + increment);
        break;
    }

    this.element.style.set(
      {
        event: { target: selectedElement },
        style: {
          left: adjustedLeft,
          top: adjustedTop,
        },
      },
      "~119"
    );
    this.editors[this.editor.number()].refs.moveable.current.updateRect();
  } catch (error) {
    console.error(error, "~123");
  }
}

export function keypressLeft(shiftKeyPressed, from = "~127") {
  unit.report({
    method: "keypressLeft",
    message: "Keypressed left.",
    from: from,
  });
  try {
    boundMove.call(this, "left", shiftKeyPressed, "~134");
  } catch (_) {}
}

export function keypressRight(shiftKeyPressed, from = "~138") {
  unit.report({
    method: "keypressRight",
    message: "Keypressed right.",
    from: from,
  });
  try {
    boundMove.call(this, "right", shiftKeyPressed, "~145");
  } catch (_) {}
}

export function keypressUp(shiftKeyPressed, from = "~149") {
  unit.report({
    method: "keypressUp",
    message: "Keypressed up.",
    from: from,
  });
  try {
    boundMove.call(this, "up", shiftKeyPressed, "~156");
  } catch (_) {}
}

export function keypressDown(shiftKeyPressed, from = "~160") {
  unit.report({
    method: "keypressDown",
    message: "Keypressed down.",
    from: from,
  });
  try {
    boundMove.call(this, "down", shiftKeyPressed, "~167");
  } catch (_) {}
}

export function keypressEsc(from = "~171") {
  unit.report({
    method: "keypressEsc",
    message: "Keypressed escape.",
    from: from,
  });
  try {
    this.element.deselect();
  } catch (_) {}
}
