/** @format */

// Go to the next step

export function next() {
  let index = this.steps.findIndex((step) => step.name == this.state.name);
  if (this.steps[index].callback) this.steps[index].callback();
  if (!this.steps[index + 1]) {
    this.stop();
  } else {
    this.setState({ name: this.steps[index + 1].name });
  }
}
