/** @format */
import { Box, Button, Card } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { useErrors } from "errors";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";
import { useUser } from "user";

const Stripe = ({
  discount,
  onComplete,
  setWorking,
  working,
  selectedPackage,
  tax,
  country,
  postalCode,
  optIn,
  trial,
  opt,
}) => {
  const { t } = useLocales();
  const user = useUser();
  const errors = useErrors();
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const token = user.token.get();

  const handleCardChange = (event) => {
    setCardComplete(event.complete);
  };

  // Opt Out submission
  const handleOptOut = async (event) => {
    event.preventDefault();

    setWorking(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      errors.error(t("error"), error, "~42");
    } else {
      axios
        .post("/api/billing/subscriptions/complete", {
          token: token,
          paymentMethodId: paymentMethod.id,
          promoCode: discount?.id || null,
          planId: selectedPackage.planId,
          tax: tax,
          country: country,
          postalCode: postalCode,
          opt: opt,
          trial: parseInt(trial),
        })
        .then(() => {
          // Complete the checkout by creating the project
          onComplete();
        })
        .catch((e) => {
          errors.error(t("stripeCheckoutError"), e, "~59");
        })
        .finally(() => {
          setWorking(false);
        });
    }
  };

  // Opt In submission
  const handleOptIn = async () => {
    setWorking(true);

    axios
      .post("/api/billing/subscriptions/complete", {
        token: token,
        planId: selectedPackage.planId,
        tax: tax,
        country: country,
        postalCode: postalCode,
        opt: opt,
        trial: parseInt(trial),
      })
      .then(() => {
        // Complete the checkout by creating the project
        onComplete();
      })
      .catch((e) => {
        errors.error(t("stripeCheckoutError"), e, "~59");
      })
      .finally(() => {
        setWorking(false);
      });
  };

  // autosubmission (for opt-in)

  useEffect(() => {
    if (!optIn) return;
    handleOptIn();
  }, [optIn]);

  if (optIn) return;

  return (
    <form onSubmit={handleOptOut}>
      <Box mb={2}>
        <Card sx={{ padding: "20px" }}>
          <CardElement
            onChange={handleCardChange}
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  iconColor: "#121212",
                  color: "#121212",
                  fontWeight: "500",
                  fontFamily: "Comfortaa",
                  fontSize: "18px",
                  fontSmoothing: "antialiased",
                  ":-webkit-autofill": {
                    color: "#121212",
                    backgroundColor: "#fff",
                  },
                  "::placeholder": {
                    color: "rgba(12,12,12,.5)",
                  },
                },
                invalid: {
                  iconColor: "#990000",
                  color: "#990000",
                },
              },
            }}
          />
        </Card>
      </Box>
      <Box textAlign="right">
        <Button type="submit" variant="contained" color="success" size="large" disabled={working || !cardComplete}>
          {t("finishAndPay")}
        </Button>
      </Box>
    </form>
  );
};

export default Stripe;
