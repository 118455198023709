/** @format */
import { Box } from "@mui/material";
import Cdn, { alt } from "cdn";
import { useCms } from "cms";
import { useMemo } from "react";

import { TinyError } from "errors";

let placeholder = Cdn(
  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_out_of_head_into_hands_f41a4d9269/thumbnail_out_of_head_into_hands_f41a4d9269.png?width=1536&height=1536"
);

export default function (props) {
  return (
    <TinyError>
      <Image {...props} />
    </TinyError>
  );
}

export function Image(props) {
  const {
    page: { blocks },
  } = useCms();

  // Memoize the calculation of Text and tmp based on props.number
  const { Image } = useMemo(() => {
    if (!blocks?.length) return { Image: null };
    const Image = blocks.filter((block) => block.type == "Image")[props.number - 1];

    return { Image };
  }, [props.number, blocks]);

  if (!Image) {
    return null;
  }

  try {
    return (
      <Box
        component="img"
        loading="lazy"
        src={Image.url || placeholder}
        alt={Image.alt || Image.name || alt(Image.url || placeholder)}
        role="img"
        sx={{
          objectFit: "cover",
          borderRadius: "3px",
          border: "0px",
          width: "calc(100% - 80px)",
          margin: "40px",
        }}
      />
    );
  } catch (_) {
    return null;
  }
}
