/** @format */

import Config from "@Workspace/config";
import { Functional } from "unit";

const unit = Functional.unit("workspace/usable");

export default async function setupUsable(from = "~8") {
  let testId = unit.report({
    method: "setupUsable",
    message: "Setup usable components",
    from: from,
  });
  return new Promise((resolve, reject) => {
    try {
      Object.keys(Config.usable.config).forEach((usable) => {
        if (!this.desks[usable]) this.desks[usable] = {};
        this.desks[usable] = Config.usable.config[usable].transformOrigin;
      });
      Config.usable.context.forEach((usable) => {
        this.contexts[usable] = [];
      });
      Object.keys(Config.usable.features).forEach((key) => {
        this.contexts[Config.usable.features[key]].push(key);
      });
      unit.passed({ testId: testId });
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error });
      reject(error);
    }
  });
}
