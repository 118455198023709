/** @format */
import isEqual from "lodash/isEqual";
import { Functional } from "unit";

const unit = Functional.unit("Project");

// Saves finally to the project throttled
export function saveProject(data, from) {
  const { workspace, errors, t } = this.props;
  try {
    workspace.update
      .feature({ feature: workspace.feature.selected(), data: data, ai: 0, note: "Updated designer." }, from)
      .then(() => {
        try {
          // # TODO account for a race condition
          setTimeout(() => this.snap(), 200);
        } catch (e) {
          console.warn(e, "~18");
        }
      });
  } catch (error) {
    errors.error(t("unexpectedError"), error, "~22");
  }
}

export function updateProject({ data, undo = true }, from = "~26") {
  unit.report({
    method: "updateProject",
    test: "Updated project.",
    from: from,
  });
  try {
    if (undo) this.dos.create("~33");
    // # TODO We might not need a second state here.
    return new Promise((resolve) => {
      this.setState({ data: data, revised: Date.now() }, async () => {
        await this.debounceUpdate(data, "~37");
        resolve(true);
      });
    });
  } catch (error) {
    const { errors, t } = this.props;
    errors.error(t("unexpectedError"), error, "~43");
  }
}

// This should be removed for teamwork.
export function syncProject() {
  const { data } = this.props;
  if (!isEqual(data, this.state.data)) {
    this.setState({ data: data });
  }
}
