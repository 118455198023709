/**
 * Loads fonts asynchronously and returns a promise that resolves when all fonts are loaded. * @param {string[]} fonts - An array of font family names to load.
 *
 * @format
 * @param {number} [retryCount=0] - The number of times to retry loading fonts if an error occurs.
 * @returns {Promise<boolean>} - A promise that resolves to true when all fonts are loaded.
 */

import isSSR from "isssr";

import config from "./config/index.json";

const loadedFonts = new Set();

const loadFonts = (fonts, retryCount = 0) => {
  if (isSSR) return;

  return new Promise((resolve, reject) => {
    try {
      const fontFamilies = fonts.filter((font) => !loadedFonts.has(font)).join("|");

      if (!fontFamilies) {
        resolve(true); // All fonts already loaded
        return;
      }

      const link = document.createElement("link");

      link.href = `/api/fonts?fonts=${encodeURIComponent(fontFamilies)}`;
      link.rel = "stylesheet";

      link.onload = function () {
        // Add the loaded fonts to the loadedFonts set
        fonts.forEach((font) => loadedFonts.add(font));
        resolve(true);
      };

      link.onerror = function (error) {
        if (retryCount < config.retries) {
          // Optionally, retry loading fonts after a delay
          setTimeout(() => loadFonts(fonts, retryCount + 1).catch(() => {}), config.retry);
        } else {
          console.warn("Reached maximum retries for loading fonts.");
        }

        reject(error);
      };

      document.head.appendChild(link);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export default loadFonts;
