/** @format */
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { app } from "firemade";
import { nanoid } from "nanoid";
import { environment } from "version";

import buckets from "../../config/buckets.json";

/**
 * Uploads and saves files asynchronously.
 * @param {FileList} files - The list of files to upload.
 * @returns {Promise<Array>} - A promise that resolves to an array of URLs if the upload is successful.
 */
export async function uploadImage(field, files) {
  console.log(field, files, "~18");

  // Set that we're uploading something
  this.setState({ uploading: true });

  const storage = getStorage(app, buckets[environment]);

  // Function to convert base64 to blob
  const base64ToBlob = async (base64, contentType) => {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  // Process each file
  const filePromises = Object.keys(files).map(async (key) => {
    let file = files[key];
    let extension = file.name.split(".").pop(); // Extract the file extension from the file name

    let reader = new FileReader();
    reader.readAsDataURL(file); // Convert the file to a data URL

    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        // Base64 encoded image
        const base64String = reader.result.split(",")[1];
        const contentType = reader.result.match(/data:([^;]+);base64,/)[1];
        const blob = await base64ToBlob(base64String, contentType);

        // Continue with upload process
        const uniqueName = `${nanoid()}.${extension}`;
        const storageRef = ref(storage, uniqueName);

        try {
          const snapshot = await uploadBytes(storageRef, blob);
          const url = await getDownloadURL(snapshot.ref);
          resolve(url);
        } catch (error) {
          reject(error);
        }
      };
    });
  });

  try {
    const urls = await Promise.all(filePromises);

    this.setState({ uploading: false });
    return urls;
  } catch (error) {
    if (this.props.errors.error) this.props.errors.error(error);
    this.setState({ uploading: false });
    return [];
  }
}
