/** @format */
import isSSR from "isssr";

/**
 * Retrieves the current section based on the URL path.
 * @param {string} section - The section to compare with the current section.
 * @returns {boolean|string} - Returns `true` if the current section matches the provided section, `false` if it doesn't match, or the current section if no section is provided.
 */
export function getSection(section) {
  let pathSection;

  if (isSSR) return "web";

  try {
    pathSection = document.location.href.split("/").at(4);
  } catch (_) {
    pathSection = "web";
  }
  return section ? pathSection == section : pathSection || false;
}
