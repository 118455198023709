/** @format */
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withActions } from "actions";
import { CaptureControls } from "capture";
import { ComponentError } from "errors";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";
import { withUser } from "user";

const StyledBox = styled(Box)(({ theme }) => ({
  "&.menu.bottom": {
    position: "fixed",
    bottom: 0,
    width: "100%",
    textAlign: "center",
    transform: "translateZ(0px)",
    flexGrow: 1,
    padding: 5,
    zIndex: 1,
  },

  "&.touring": {
    backgroundColor: "transparent",
    zIndex: 1257,
  },

  "& .button": {
    margin: "5px",
    borderRadius: "5px",
    borderColor: "rgba(0,0,0,.12)",
    color: "rgba(0,0,0,.5)",
    backgroundColor: theme.palette.mode == "light" ? "#FFF" : "#222",
    ":hover": {
      backgroundColor: theme.palette.mode == "light" ? "#FFF" : "#222",
    },
  },

  "& .button.Mui-disabled": {
    border: "1px solid " + theme.palette.primary + " !important",
  },
}));

class Bottom extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = { border: 0 };
    this.unit = this.props.unit.new(this.constructor.name);
  }

  render() {
    const { children, workspace, t } = this.props;

    const { assist } = workspace;

    const opened = assist.opened();

    if (!workspace.ready.get("all")) return <></>;

    return (
      <ComponentError>
        <StyledBox className="menu bottom">
          <Stack direction="row" spacing={0} justifyContent="space-around" alignItems="center">
            <Box sx={{ width: "33vw" }}>&nbsp;</Box>
            <ComponentError>
              <Stack
                sx={{
                  width: "33vw",
                  marginLeft: !opened ? 0 : "33vw",
                }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                {children && children}
              </Stack>
            </ComponentError>
            <Box sx={{ width: "33vw", textAlign: "right" }}></Box>
          </Stack>
          <CaptureControls message={t("snapshotMessage")} />
        </StyledBox>
      </ComponentError>
    );
  }
}

export default withActions(withLocales(withUser(Bottom)));
