/** @format */

// Setup

export default async function setupZoom() {
  return await new Promise((resolve, reject) => {
    try {
      setZoom.call(this, "70", "~18");
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

// Set the zoom
export function setZoom(delta) {
  var zoom = 0.6;

  try {
    if (typeof delta == "string") {
      if (delta == "in" && this.zoom.zooms[this.zoom.zooms.indexOf(this.state.settings.zoom) + 1])
        zoom = this.zoom.zooms[this.zoom.zooms.indexOf(this.state.settings.zoom) + 1];
      if (delta == "out" && this.zoom.zooms[this.zoom.zooms.indexOf(this.state.settings.zoom) - 1])
        zoom = this.zoom.zooms[this.zoom.zooms.indexOf(this.state.settings.zoom) - 1];
      if (delta == "in" && delta == "out") zoom = this.zoom.zooms[this.zoom.zooms.indexOf(delta)];
    } else {
      zoom = delta / 100;
    }

    this.setState({
      settings: { ...this.state.settings, ...{ zoom: zoom } },
    });
  } catch (error) {
    console.error(error, "~54");
  }
}

export function getZoom(from = "~58") {
  return this.state.settings.zoom;
}

// Check if we can zoom a certain way (for tools availabilty)
export function canZoom(delta, from = "~69") {
  return true;

  // let index = this.zoom.zooms.indexOf(this.state.settings.zoom);
  // // let zoom = this.zoom.zooms[ index ];

  // if (delta == "in") return this.zoom.zooms[index + 1] !== undefined;
  // if (delta == "out") return this.zoom.zooms[index - 1] !== undefined;
}
