/** @format */

export function getCancelPending() {
  // Check if we have a pending cancellation
  try {
    return this?.state?.timestamp?.canceled?.at > 0 || false;
  } catch (_) {
    return false;
  }
}
