/** @format */

import { Functional } from "unit";
const unit = Functional.unit("Manuscript");

export function setMode(mode, from = "~6") {
  unit.report({
    method: "setMode",
    analyze: true,
    from: from,
    action: true,
    test: "The user should be able to toggle between draft and layout versions.",
    steps: ["From manuscript", "Click the mode button", "Draft & layouts versions should toggle"],
  });
  this.setState({ mode: mode });
}

export function toggleMode(from = "~18") {
  unit.report({
    method: "toggleMode",
    analyze: true,
    action: true,
    test: "The user should be able to toggle between draft and layout versions.",
    from: from,
    steps: ["From manuscript", "Click the mode button", "Draft & layouts versions should toggle"],
  });
  let mode = this.state.mode.value == "draft" ? "layout" : "draft";
  this.setState({ mode: mode });
}

export function getMode(mode = null, from = "~31") {
  unit.report({
    method: "getMode",
    test: "If the mode of the manuscript editor is correct (draft then layout) is should be reflected on the screen.",
    message: "Getting the currently selected mode (draft/layout).",
    from: from,
    passed: true,
  });
  return mode ? this.state.mode == mode : this.state.mode;
}
