/** @format */
import { withCms } from "cms";
import FlexSearch from "flexsearch";
import { Component as ReactComponent, createContext, useContext } from "react";

import { filter } from "./helpers/Filter";
import { query } from "./helpers/Query";

const SearchContext = createContext({});

class Search extends ReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      filter: {},
      results: [],
      revised: null,
    };

    this.document = new FlexSearch.Index({
      charset: "latin:advanced",
      tokenize: "reverse",
      cache: true,
    });

    this.addedPages = new Set(); // Keep track of added pages

    this.query = query.bind(this);
    this.filter = filter.bind(this);
  }

  mergePages = (pages) => {
    try {
      pages.forEach((page) => {
        // Skip if already added
        if (this.addedPages.has(page.slug)) return;

        // Add the search stuff here
        this.document.add(page.slug, page.search);

        // Mark as added
        this.addedPages.add(page.slug);
      });
    } catch (error) {
      console.error(error);
    }
  };

  results = () => {
    return this.state.results;
  };

  componentDidUpdate() {
    const { cms } = this.props;
    if (cms.pages.length > this.addedPages.size) this.mergePages(cms.pages);
  }

  render() {
    return (
      <SearchContext.Provider value={{ query: this.query, filter: this.filter }}>
        {this.props.children}
      </SearchContext.Provider>
    );
  }
}

const useSearch = () => {
  return useContext(SearchContext);
};

const withSearch = (Component) => {
  return function ContextualComponent(props) {
    return <SearchContext.Consumer>{(state) => <Component {...props} search={state} />}</SearchContext.Consumer>;
  };
};

export default withCms(Search);
export { useSearch, withSearch };
