/** @format */
import { Alert, Box, Button, ButtonGroup, Card, Divider, Stack } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { TinyError } from "errors";
import { useCallback } from "react";

import { useWorkspace } from "@Workspace/Context";
import { useCms } from "cms";
import { useLocales } from "locales";
import LinesEllipsis from "react-lines-ellipsis";
import TimeRange from "./libraries/TimeRange";

export default function Step(props) {
  const { t, locale } = useLocales();
  const { pages } = useCms();

  const {
    checklist: {
      step: { actions, set },
    },
  } = useWorkspace();

  const { step } = props;

  if (step == undefined) return <></>;

  const { kb, manual, optional, completed } = step;

  // Get the excerpt page
  let excerpt = step.excerpt
    ? pages
        .filter((page) => page.section == "checklist")
        .find((page) => page.slug.replace("checklist/", "") == step.excerpt)
    : null;

  /**
   * Calculates the time range based on the given time.
   *
   * @param {number} time - The time value.
   * @returns {JSX.Element} - The JSX element representing the time range.
   */
  const takes = (time) => {
    const { t } = useLocales();
    let range = TimeRange(time);

    if (range == "") return <>{t("takesWhatItTakes")}.</>;

    return (
      <>
        {range.includes("&") ? t("takesBetween") : t("takesAbout")} {range}.
      </>
    );
  };

  /**
   * Opens a new browser window with the specified URL.
   *
   * @param {string} kb - The knowledge base URL to open. If not provided, it defaults to "test".
   * @returns {void}
   */
  const handleOpenKb = useCallback(() => {
    window.open(
      `https://made.live/${locale.get()}/kb/${step.kb || "brainstorming-conceptualizing-childrens-book-ideas"}`,
      "_blank"
    );
  }, [locale, step.kb]);

  /**
   * Handles the manual step for the checklist.
   */
  const handleManualStep = useCallback(() => {
    const { slug, completed, checklist: feature } = step;
    set({
      feature,
      step: slug,
      state: !completed,
    });
  }, [step, set]);

  if (!step.visible) return <></>;

  if (!excerpt?.title)
    return (
      <Alert severity="info" sx={{ marginBottom: "20px" }}>
        {t("missingKBAlert")} detailed information on <strong>{step.excerpt}</strong> We're working hard to fix this.
      </Alert>
    );

  return (
    <TinyError>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "transparent",
          margin: "5px",
          padding: "10px",
          opacity: step.enabled ? 1 : 0.4,
        }}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <h4 style={{ margin: 0 }}>
            {excerpt.title} {optional && <small>({t("optional")})</small>}
          </h4>
          <Box>
            {!optional ? (
              <>
                {completed ? (
                  <Box
                    sx={{
                      backgroundColor: "success.main",
                      display: "flex",
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                    }}
                  >
                    <DynamicIcon icon={"fa-check"} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "warning.main",
                      display: "flex",
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                    }}
                  >
                    <DynamicIcon icon={"fa-circle-exclamation"} />
                  </Box>
                )}
              </>
            ) : (
              <Box
                sx={{
                  backgroundColor: "info.main",
                  display: "flex",
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "10px",
                }}
              >
                <DynamicIcon icon={"fa-info"} />
              </Box>
            )}
          </Box>
        </Stack>
        <TinyError>
          {excerpt ? (
            <Box sx={{ fontSize: "14px", margin: "20px 0px 20px 0px" }}>
              <Box sx={{ position: "relative" }}>
                <LinesEllipsis text={excerpt.excerpt} maxLine="8" ellipsis="..." trimRight basedOn="letters" />
              </Box>
              <Box sx={{ margin: "20px 0" }}>
                <Divider />
              </Box>
            </Box>
          ) : (
            <Box sx={{ padding: "30px 0px" }}>
              {/* Add these back in */}
              <Alert severity="info" sx={{ width: "90%" }}>
                {t("missingKBAlert")} <strong>{step.exceprt}</strong>.
              </Alert>
            </Box>
          )}
        </TinyError>
        <Stack direction="row" sx={{ padding: "0px 0px 10px 0", opacity: 0.5 }} justifyContent="space-between">
          <Box sx={{ textAlign: "left" }}>
            <TinyError>
              <Box sx={{ margin: "10px 0", fontSize: "12px" }}>
                <DynamicIcon icon={"fa-clock"} />
                <Box component="span" sx={{ marginLeft: "10px", "& img": { maxWidth: "100%" } }}>
                  {takes(step.time)}
                </Box>
              </Box>
            </TinyError>
          </Box>
          <ButtonGroup variant="outlined" size="small">
            {kb && (
              <Button onClick={() => handleOpenKb()}>
                <DynamicIcon icon={"fa-graduation-cap"} />
              </Button>
            )}
            {manual && !optional && (
              <Button onClick={() => handleManualStep()}>{t(completed ? "markAsIncomplete" : "markAsComplete")}</Button>
            )}
            {step.actions && Array.isArray(step.actions) && step.actions.length > 0 && (
              <Button onClick={() => actions(step.actions)} disabled={step.enabled.enabled == false}>
                {step.complete ? t("go") : t("do")}
              </Button>
            )}
          </ButtonGroup>
        </Stack>
      </Card>
      <Box sx={{ padding: "20px", opacity: 0.1 }}>
        <Divider />
      </Box>
    </TinyError>
  );
}
