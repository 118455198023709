/** @format */
import cookies from "cookies";

export function tabValue(payload) {
  let cookie = null;
  // In the event we've passed junk into the function
  try {
    cookie = parseInt(cookies.get(`ui_tab_${payload.replace(/[^a-zA-Z0-9]/g, "_")}`)) || 0;
  } catch (_) {}

  // Return either the saved or recent state
  return this.state.settings.tabs[payload] || cookie ? (cookie ? cookie : this.state.settings.tabs[payload]) : 0;
}

export function tabVisible(payload) {
  return (
    Object.values(payload)[0] ==
    (this.state.settings.tabs[Object.keys(payload)[0]] ? this.state.settings.tabs[Object.keys(payload)[0]] : 0)
  );
}

export function setTab(payload) {
  const tabs = { ...this.state.settings.tabs, ...payload };
  const [slug, index] = Object.entries(payload)[0];

  // Clone the existing history and add the new payload
  const history = [...this.state.history, { [slug]: index }];

  // In the event we've passed junk into the function
  try {
    // Set the cookie
    cookies.set(`ui_tab_${slug.replace(/[^a-zA-Z0-9]/g, "_")}`, index);
  } catch (e) {}

  // Set the state
  this.setState({
    settings: { ...this.state.settings, tabs: tabs },
    history: history,
  });
}
