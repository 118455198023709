/** @format */

// This is for enabling billing logic when we're ready to start billing
import { enabled } from "../../../config/index.json";

// Get billing enabled yet
export function getEnabled() {
  try {
    const [year, month, day] = enabled.split("-").map(Number);
    return Date.now() > new Date(Date.UTC(year, month - 1, day)).getTime();
  } catch (e) {
    return true;
  }
}
