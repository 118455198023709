/** @format */

import { ListItemButton, ListItemIcon, ListItemText, MenuList } from "@mui/material";
import { Box, styled } from "@mui/system";
import DynamicIcon from "dynamicicon";
import key from "key";
import { useLocales } from "locales";
import LinesEllipsis from "react-lines-ellipsis";
import { useRouter } from "router";
import { useUI } from "ui";
import { useNotifications } from "../../../../";

const StyledBox = styled(Box)(() => ({
  "&": {
    fontSize: "12px",
    minWidth: "300px",
  },
  "& .MuiListItemText-primary": {
    fontSize: "1.2em",
  },
  "& .MuiListItemText-secondary": {
    fontSize: "1.0em",
  },
}));

export default function () {
  // Get the notifications
  const { notifications, mark } = useNotifications();

  // Get the locales
  const { t, url } = useLocales();

  // Navigate
  const { navigate } = useRouter();

  // Close the menus
  const { menu } = useUI();

  // Handles the clicker
  const onClick = ({ id, link, action }) => {
    // Close the menu
    menu.close();

    // Let the menu close
    setTimeout(() => {
      // Set that we followed this link
      mark.followed(id);

      // Link to it
      if (link) navigate(url(link));

      // #TODO - come back to adding this (by moving actions to the app)
      if (!link && action) console.warn("No link or action provided for this notification.");
    }, 100);
  };

  return (
    <StyledBox>
      <MenuList style={{ paddingTop: 0, paddingBottom: 0, maxWidth: "300px" }}>
        {notifications.slice(0, 10).map((notification) => (
          <ListItemButton
            {...key("notification", notification.id)}
            selected={notification.followed ? false : true}
            onClick={() => onClick(notification)}
          >
            <ListItemIcon>
              <DynamicIcon icon={notification.icon || "fa-user"} />
            </ListItemIcon>
            <ListItemText
              primary={t(notification.title)}
              secondary={
                <LinesEllipsis text={t(notification.message)} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
              }
            />
          </ListItemButton>
        ))}
      </MenuList>
    </StyledBox>
  );
}
