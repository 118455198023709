/** @format */
import { Box } from "@mui/material";
import { MissingError } from "errors";
import { useEffect } from "react";
import { useUI } from "../../context";

import DialogPanel from "./components/Dialog";
import PagePanel from "./components/Page";
import TabPanel from "./components/Tab";

export default function Panels(props) {
  const ui = useUI();
  const { slug, component, children, history = false } = props;
  const tabs = slug || component;
  if (!tabs || !children)
    return (
      <Box sx={{ margin: "10% 0 20%" }}>
        <MissingError />
      </Box>
    );

  useEffect(() => {
    try {
      if (history) ui.tab.history.set(slug);
    } catch (_) {}
  }, [tabs]);

  // Ensure children is always an array
  const childrenArray = [].concat(children);

  return (
    <>
      {childrenArray.map((child, index) => (
        <Box key={index} sx={{ display: ui.tab.value(tabs) === index ? "block" : "none" }}>
          {child}
        </Box>
      ))}
    </>
  );
}

export { DialogPanel, PagePanel, TabPanel };
