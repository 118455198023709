/** @format */
import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { withConstraints } from "constraints";
import { withLocales } from "locales";
import flatten from "lodash/flatten";
import values from "lodash/values";
import { withPerforming } from "performing";
import { Component as ReactComponent } from "react";
import { withSnackbar } from "snackbar";
import { withUI } from "ui";
import { withUnit } from "unit";
import { withUser } from "../../context";
import AuthProviders from "../AuthProviders";

const initialState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  password: "",
  displayName: "",
};

class SignUp extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.unit = this.props.unit.new("User");
  }

  setFirstName = (value) => {
    let tmp = { ...this.state };
    tmp.firstName = value;
    this.setState(tmp);
  };

  setLastName = (value) => {
    let tmp = { ...this.state };
    tmp.lastName = value;
    this.setState(tmp);
  };

  setEmailAddress = (value) => {
    let tmp = { ...this.state };
    tmp.emailAddress = value;
    this.setState(tmp);
  };

  setPassword = (value) => {
    let tmp = { ...this.state };
    tmp.password = value;
    this.setState(tmp);
  };

  signUp = () => {
    const { ui, snackbar, performing, t, user } = this.props;
    const { emailAddress, firstName, lastName, password, passwordConfirmation } = this.state;
    const { constraints, validate } = this.props.constraints;
    const errors = validate(
      {
        emailAddress: emailAddress,
        firstName: firstName,
        lastName: lastName,
        password: password,
        passwordConfirmation: passwordConfirmation,
      },
      {
        emailAddress: constraints.emailAddress,
        firstName: constraints.firstName,
        password: constraints.password,
        lastName: constraints.lastName,
      }
    );

    if (errors) {
      const reason = flatten(values(errors));
      snackbar.open(reason[0], "error");
      return;
    } else {
      performing.set.updating("updating", "~79");
      user.authentication
        .signUpWithEmail({ ...this.state, displayName: `${firstName} ${lastName}` })
        .then((_user) => {
          snackbar.open(t("allSignedUp"), "success");
          if (!this.props.locked) performing.set.working(false, "~84");
          ui.dialog.close("signup");
        })
        .catch((reason) => {
          snackbar.open(t(reason), "error");
        })
        .finally(() => {
          performing.set.updating(null, "~102");
        });
    }
  };

  signInWithAuthProvider = (provider) => {
    const { ui, snackbar, performing, user, t } = this.props;

    performing.set.updating("updating", "~115");

    user.authentication
      .signInWithAuthProvider(provider)
      .then((user) => {
        console.log(user, "~120");
        snackbar.open(t("signedIn"));
        ui.dialog.close("signup");
      })
      .catch((reason) => {
        snackbar.open(t(reason), "error");
        ui.dialog.close("signup");
      });
  };

  render() {
    const { t, disabled = false, user } = this.props;
    const { emailAddress, firstName, password, lastName } = this.state;

    if (user.authenticated) return t("alreadySignedIn");
    return (
      <Box sx={{ marginTop: "10px" }}>
        <Grid container direction="row" spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs>
            <TextField
              autoComplete="firstName"
              disabled={disabled}
              fullWidth
              label={t("firstName")}
              required
              value={firstName}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={({ target }) => this.setFirstName(target.value)}
            />
          </Grid>
          <Grid item xs>
            <TextField
              autoComplete="lastName"
              disabled={disabled}
              fullWidth
              label={t("lastName")}
              required
              value={lastName}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={({ target }) => this.setLastName(target.value)}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <TextField
              autoComplete="email"
              disabled={disabled}
              fullWidth
              label={t("email")}
              required
              type="email"
              value={emailAddress}
              variant="outlined"
              InputLabelProps={{ required: false }}
              sx={{ minWidth: 400 }}
              onChange={({ target }) => this.setEmailAddress(target.value)}
            />
          </Grid>

          <Grid item xs>
            <TextField
              autoComplete="new-password"
              disabled={disabled}
              fullWidth
              label={t("password")}
              required
              type="password"
              value={password}
              variant="outlined"
              InputLabelProps={{ required: false }}
              onChange={({ target }) => this.setPassword(target.value)}
            />
          </Grid>
        </Grid>

        <Box style={{ marginTop: "20px" }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
            <Box>
              <AuthProviders
                gutterBottom
                performingAction={disabled}
                onAuthProviderClick={this.signInWithAuthProvider}
                tooltip="Register"
                small={true}
              />
            </Box>
            <Button
              color="success"
              disabled={!emailAddress || !firstName || !password || !lastName || disabled}
              variant="contained"
              onClick={this.signUp}
            >
              {t("signUp")}
            </Button>
          </Stack>
        </Box>
      </Box>
    );
  }
}

export default withPerforming(withConstraints(withLocales(withSnackbar(withUser(withUnit(withUI(SignUp)))))));
