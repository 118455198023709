/** @format */
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import Cookies from "cookies";
import { withErrors } from "errors";
import { Keypress } from "keypress";
import { PureComponent, createContext, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { dark, light } from "../components/Theme";

import isSSR from "isssr";

import { license } from "../config";

// Global
import { close, opened, reset } from "./helpers/Global";

// All dialogs wrapper
import Dialogs from "../components/Dialogs";

// Mode controller
import { getMode, setMode, toggleMode } from "./helpers/Mode";

// Dialog controller
import { addDialog, addDialogs, closeDialogs, openDialog } from "./helpers/Dialog";

// Confirm controller
import { closeConfirm, openConfirm } from "./helpers/Confirm";

// Alert controller
import { closeAlert, openAlert } from "./helpers/Alert";

// Menu controller
import { closeMenus, menuOpened, openMenu, toggleMenu } from "./helpers/Menu";

// History controller
import { getHistory, setHistory } from "./helpers/History";

// Drawer controller
import { closeDrawer, openDrawer, toggleDrawer } from "./helpers/Drawer";

// Tab controller
import { setTab, tabValue, tabVisible } from "./helpers/Tab";

// Set the MUI license key
LicenseInfo.setLicenseKey(license);

const UIContext = createContext({});

let drawers = {
  left: false,
  top: false,
  bottom: false,
  right: false,
};

let confirm = {
  open: false,
  title: "",
  text: "",
  confirm: {},
  dismiss: {},
  acknowledge: {},
  params: {},
};

let alert = {
  open: false,
  title: "",
  text: "",
  button: {},
  params: {},
};

let dialog = {
  confirm: confirm,
  alert: alert,
};

class UI extends PureComponent {
  constructor(props) {
    super(props);

    this.defaultSettings = {
      drawers: drawers,
      menus: {},
      tabs: {},
      dialogs: dialog,
      mode: Cookies.get("mode") || "light",
    };

    this.state = { settings: this.defaultSettings, history: [] };

    this.opened = opened.bind(this);

    this.close = close.bind(this);

    this.reset = reset.bind(this);

    this.mode = {
      set: setMode.bind(this),
      get: getMode.bind(this),
      toggle: toggleMode.bind(this),
    };

    this.dialogs = this.state.settings.dialogs;

    this.add = {
      dialog: addDialog.bind(this),
      dialogs: addDialogs.bind(this),
    };

    this.dialog = {
      open: openDialog.bind(this),
      close: closeDialogs.bind(this),
    };

    this.confirm = {
      open: openConfirm.bind(this),
      close: closeConfirm.bind(this),
    };

    this.alert = {
      open: openAlert.bind(this),
      close: closeAlert.bind(this),
    };

    this.menu = {
      open: openMenu.bind(this),
      close: closeMenus.bind(this),
      toggle: toggleMenu.bind(this),
      opened: menuOpened.bind(this),
    };

    this.drawer = {
      toggle: toggleDrawer.bind(this),
      close: closeDrawer.bind(this),
      open: openDrawer.bind(this),
    };

    this.tab = {
      set: setTab.bind(this),
      visible: tabVisible.bind(this),
      value: tabValue.bind(this),
      get: tabValue.bind(this),
      history: { set: setHistory.bind(this), get: getHistory.bind(this) },
    };

    this.history = {
      get: getHistory.bind(this),
      set: setHistory.bind(this),
    };
  }

  // History

  popstate = () => {
    let history = [...this.state.history];

    // Remove the current state from the history
    history.pop();

    if (history.length > 0) {
      const previousTab = history[history.length - 1];
      const [slug, index] = Object.entries(previousTab)[0];

      let tabs = { ...this.state.settings.tabs, [slug]: index };

      this.setState({
        settings: { ...this.state.settings, tabs: tabs },
        history: history,
      });
    }
  };

  componentDidMount() {
    Keypress.add("esc", this.close);
    window.addEventListener("popstate", this.popstate);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.popstate);
  }

  render() {
    return (
      <UIContext.Provider
        value={{
          ...this.state,
          dialogs: this.state.settings.dialogs,
          opened: this.opened,
          close: this.close,
          reset: this.reset,
          mode: this.mode,
          add: this.add,
          dialog: this.dialog,
          confirm: this.confirm,
          alert: this.alert,
          menu: this.menu,
          drawer: this.drawer,
          tab: this.tab,
          history: this.history,
        }}
      >
        <ThemeProvider theme={this.mode.get("light") ? light : dark}>
          {this.props.children}
          <Box>
            {!isSSR && (
              <BrowserRouter>
                <Dialogs
                  ui={{
                    ...this.state,
                    dialogs: this.state.settings.dialogs,
                    opened: this.opened,
                    close: this.close,
                    reset: this.reset,
                    mode: this.mode,
                    add: this.add,
                    dialog: this.dialog,
                    confirm: this.confirm,
                    alert: this.alert,
                    menu: this.menu,
                    drawer: this.drawer,
                    tab: this.tab,
                    history: this.history,
                  }}
                />
              </BrowserRouter>
            )}
          </Box>
        </ThemeProvider>
      </UIContext.Provider>
    );
  }
}

const withUI = (Component) => {
  return function ContextualComponent(props) {
    return (
      <UIContext.Consumer>
        {(state) => (
          <Component {...props} ui={state} dialogs={state.dialogs} theme={state.mode.get("light") ? light : dark} />
        )}
      </UIContext.Consumer>
    );
  };
};

const useUI = () => {
  return useContext(UIContext);
};

export default withErrors(UI);
export { useUI, withUI };
