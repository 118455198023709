/**
 * Safely evaluates the given code string and returns the result.
 *
 * @format
 * @param {string} code - The code string to evaluate.
 * @param {string} from - The source of the code string.
 * @returns {*} - The result of the evaluated code, or null if an error occurred.
 */

/** @format */

export default function SafeEval(code, from) {
	try {
		const func = new Function(code);
		return func();
	} catch (err) {
		console.error(err, from);
		return null;
	}
}
