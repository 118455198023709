/** @format */
import { collection, getDocs, query, where } from "firebase/firestore";

import { getMissing } from "./Site";

import { setMeta } from "./Meta";

/**
 * Handles navigation by preventing the default event behavior and calling the navigate function.
 * @param {Event} event - The event object.
 * @param {string} path - The path to navigate to.
 */
export function link(event, path) {
  event.preventDefault();
  navigate.call(this, path);
}

/**
 * Navigates to a specific page.
 * @param {string} path - The path of the page to navigate to.
 * @param {boolean} [localize=true] - Indicates whether to localize the path or not. Default is true.
 * @returns {Promise<boolean>} - A promise that resolves to true if the navigation is successful, or rejects with an error message if there is an error or the page is missing.
 */
export function navigate(path, localize = true) {
  const { url } = this.props.locales;

  return new Promise(async (resolve, reject) => {
    try {
      // Get the page
      let page = null;

      // We assume we found it
      let found = true;

      if (localize) path = url(path);

      // Get any pages that might match
      let pages = this.state.pages.filter((page) => page.path == path);

      if (pages.length > 0) {
        // get the selected page
        page = pages[0];
      } else {
        // Get the current document
        let q = query(collection(db, "pages"), where("path", "==", path));

        // Get the docs
        let qs = await getDocs(q);

        qs.forEach((doc) => pages.push(doc.data()));

        page = pages[0] || null;
      }

      // try and get the missing page
      if (!page) {
        page = getMissing.call(this);

        // we did not find the page
        found = false;
      }

      // We can't even find the missing page
      if (!page) {
        this.error();

        reject("missing page");
      }

      // Set that we have meta that should be noindexed
      setMeta.call(this, page, found);

      // Set the page
      this.setState({ page });

      // Can tell the click handler, but an error will have been thrown
      return resolve(true);
    } catch (error) {}
  });
}
