/** @format */

import Image from "./components/Image";
import Text from "./components/Text";
import Video from "./components/Video";

export default function Block(props) {
  if (props.type == "Text") return <Text {...props} />;
  if (props.type == "Image") return <Image {...props} />;
  if (props.type == "Video") return <Video {...props} />;
}
