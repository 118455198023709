/** @format */
import { Box } from "@mui/material";
import Text from "@Web/Blocks/Text";

export default function LeftSide(props) {
  return (
    <Box>
      <Text {...props} number={3} />
    </Box>
  );
}
