/** @format */

import axios from "axios";

import { Frame } from "scenejs";
import { Functional } from "unit";
import background from "../../config/templates/background.json";
import Trims from "../../config/trims/sizes.json";

// This is a fallback function to handle api errors
import { Dimensions, Uncalculated, getSavedDimensions, setSavedDimensions } from "./Dimensions";

const unit = Functional.unit("designer/editor");

// Setup
export function setupEditor(from = "~16") {
  // There are two places to calculate dimensions. The api is master. The client is a backup
  unit.report({
    method: "setupEditor",
    from: from,
    message: "Selected the editor we wish to work on.",
  });
  const { errors, t, workspace } = this.props;
  const token = workspace.token();
  return new Promise(async (resolve, reject) => {
    try {
      // Get the feature we're working with
      const feature = workspace.feature.selected();

      // See if we have cached dimensions
      let cached = await getSavedDimensions.call(this, feature);

      // We already did the caclucations
      if (cached) {
        this.dimensions = cached;
        return resolve(true);
      }

      // Otherwise we'll calculate them from the endpoint (which will cache them)
      const { data: dimensions } = await axios.post("/api/workspace/usable/designer/dimensions", {
        token: token,
        feature: feature,
      });

      // There was a problem, which means we'll use the fallback calcs
      if (!dimensions.interior || !dimensions.exterior) throw "Did not retrieve dimensions for the editor.";

      // Set the dimensionss
      this.dimensions = { ...this.dimensions, ...dimensions };

      // Save the dimensions as cached for this project
      setSavedDimensions.call(this, dimensions);

      // Tell the world
      return resolve(true);

      // This fallback will do a client side calculation (if not saved and not calculated from the backend)
    } catch (error) {
      // Try the fallback next
      try {
        const data = this.data("~61");
        const trim = Trims.find((trim) => trim.slug == data.trim);

        if (!trim) throw "No trim found.";

        const dimensions = Dimensions(trim, Math.ceil(data.spreads.length / 2));

        this.dimensions = { ...this.dimensions, ...dimensions };

        // Log these to save time in the future
        setSavedDimensions.call(this, dimensions);

        return resolve(true);
      } catch (error) {
        errors.error(t("errorSetupEditor"), error, "~72");
        return reject(error);
      }
    }
  });
}

/* Register the editor complete with refs */

export function registerEditor(number = 0, refs, from = "~81") {
  unit.report({
    method: "registerEditor",
    from: from,
    message: "Selected the editor we wish to work on.",
  });
  const { errors, t } = this.props;
  return new Promise(async (resolve, reject) => {
    try {
      if (this.editors[number]) return resolve(true);
      this.editors[number] = {
        ...{ sized: false, refs: refs, updated: Date.now() },
      };
      return resolve(true);
    } catch (error) {
      errors.error(t("errorSetupEditor"), error, "~96");
      return reject(error);
    }
  });
}

/* Sizing the editor and the guides */

export function sizeEditor(number, spread = this.state?.spread?.number || 0, from = "~104") {
  return new Promise((resolve, reject) => {
    try {
      number = getEditorNumber.call(this, number, "~107");

      let editor = this.editors[number];

      if (!editor?.refs) throw "Editor not setup.";

      // let trim = Trims.filter((t) => t.slug == data.trim)[0];
      let space = spread == 0 ? "exterior" : "interior";

      // Copy the base calculations from the book for the desk
      let { scale, dimensions, guides, snaps, mattes, bounds } = this.state;

      // A baseline to work with
      let calculated = { ...Uncalculated };

      calculated = this.dimensions[space];

      bounds = this.dimensions[space].bounds;
      guides = this.dimensions[space].guides;
      snaps = this.dimensions[space].snaps;
      mattes = this.dimensions[space].mattes;

      // # TODO unsure of this being don seperately. It's a bit of a mess
      mattes = calculated.mattes;
      bounds = calculated.bounds;

      // The spread size
      dimensions.spread = new Frame({
        width: calculated.width,
        minWidth: calculated.width,
        maxWidth: calculated.width,
        height: calculated.height,
        minHeight: calculated.height,
        maxHeight: calculated.height,
      }).toCSSObject();

      // The droppable target size
      dimensions.droppable = new Frame({
        width: calculated.width - 5,
        minWidth: calculated.width - 5,
        maxWidth: calculated.width - 5,
        height: calculated.height - 5,
        minHeight: calculated.height - 5,
        maxHeight: calculated.height - 5,
      }).toCSSObject();

      // Setup the rulers/guides
      dimensions.guides = new Frame({
        width: calculated.width,
        minWidth: calculated.width,
        maxWidth: calculated.width,
        height: calculated.height,
        minHeight: calculated.height,
        maxHeight: calculated.height,
        offset: calculated.offset,
      }).toCSSObject();

      scale = Math.max(window.innerWidth || 0) / calculated.width;

      unit.report({
        method: "sizeEditor",
        message: "All calulations done.",
        from: from,
        payload: {
          dimensions: dimensions,
          bounds: bounds,
          guides: guides,
          snaps: snaps,
          mattes: mattes,
          scale: scale,
          sized: true,
        },
      });

      this.editors[number].sized = true;
      this.editors[number].updated = Date.now();

      // Update all the guides and whatnot
      this.setState({
        dimensions: dimensions,
        bounds: calculated.bounds,
        guides: calculated.guides,
        snaps: calculated.snaps,
        mattes: calculated.mattes,
        rulers: calculated.rulers,
        scale: scale,
        sized: true,
      });

      unit.report({
        method: "sizeEditor",
        from: from,
        message: "Guides sized.",
      });

      return resolve(true);
    } catch (error) {
      const { errors, t } = this.props;
      errors.error(t("errorDesignerSizing"), error, "~203");
      return reject(error);
    }
  });
}

// Size the guides

export function sizeGuides(number = null, from = "~211") {
  unit.report({
    method: "sizeGuides",
    from: from,
    message: "Sizes guides editor.",
  });
  const { errors, t } = this.props;
  return new Promise((resolve, reject) => {
    try {
      number = getEditorNumber.call(this, number, "~220");
      this.editors[number].refs.moveable.current.updateRect();
      this.editors[number].refs.guides1.current.resize();
      this.editors[number].refs.guides2.current.resize();
      return resolve();
    } catch (error) {
      errors.warning(t("warningDesignerSizing"), error, "~226");
      return reject(error);
    }
  });
}

// Resizing the editor and the guides

export function resizeEditor(from = "~234") {
  unit.report({
    method: "sizeMonitor",
    from: from,
    message: "Sizes monitor (no idea what this does honestly).",
  });
  const { errors, t } = this.props;
  return new Promise(async (resolve, reject) => {
    try {
      this.editors.forEach((_, i) => {
        this.editors[i].sized = false;
      });
      this.editors.forEach((_, i) => {
        sizeEditor(i, "~247");
      });
      this.editors.forEach((_, i) => {
        sizeGuides(i, "~250");
      });
      this.editors.forEach((_, i) => {
        this.editors[i].sized = true;
      });
      return resolve();
    } catch (error) {
      errors.error(t("errorDesignerSizing"), error, "~257");
      return reject(error);
    }
  });
}

// For multiple spread layout (future consideration)

export function selectEditor(spread, from = "~265") {
  unit.report({
    method: "selectEditor",
    from: from,
    message: "Selected the editor we wish to work on.",
  });
  try {
    // This looks like a problem
    this.setState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        spread: {
          ...prevState.settings.spread,
          number: spread,
        },
      },
    }));
  } catch (error) {
    errors.error(t("unexpectedError"), error, "~284");
  }
}

// When refreshing editor

export function refreshEditor(from = "~290") {
  unit.report({
    method: "refreshEditor",
    from: from,
    message: "Refresh the editor (mostly for keypresses).",
  });
  const { errors, t } = this.props;
  try {
    this.editors.forEach((_, i) => {
      this.editors[i].refs.moveable.current.updateRect();
      this.editors[i].refs.moveable.current.request("draggable", { deltaX: 0, deltaY: 0 }, true);
    });
  } catch (error) {
    errors.error(t("unexpectedError"), error, "~303");
  }
}

// Editor style for use on the desk

export function getEditorStyle(number, from = "~309") {
  unit.report({
    method: "getEditorStyle",
    message: "Returns the editor style",
    from: from,
  });
  const { errors } = this.props;
  const { dimensions } = this.state;

  const exit = () => {
    return background;
  };

  try {
    number = getEditorNumber.call(this, number, "~323");
    const spread = this.data("~324")?.spreads[number];

    if (!this.editors[number]) return exit();

    if (spread?.background != undefined && spread?.background?.image != "") {
      return dimensions.spread;
    } else {
      return exit();
    }
  } catch (error) {
    errors.warning(true, error, "~334");
    return exit();
  }
}

// When the editor has come online

export function editorReady(number = null, from = "~341") {
  unit.report({
    method: "editorReady",
    from: from,
    message: "Check if the editor is ready.",
  });
  try {
    number = getEditorNumber.call(this, number, "~348");
    return designer?.editors[number]?.sized || false;
  } catch (_) {
    return false;
  }
}

// If there are changes to the editor

export function editorUpdated(number = null, from = "~357") {
  unit.report({
    method: "editorUpdated",
    from: from,
    message: "Set that the editor has been updated.",
  });
  try {
    number = getEditorNumber.call(this, number, "~364");
    return designer?.editors[number]?.updated || null;
  } catch (_) {
    return null;
  }
}

// When leaving, unegister the editor

export function unregisterEditor(number, from = "~373") {
  unit.report({
    method: "unregisterEditor",
    from: from,
    message: "Unregister the number.",
  });
  try {
    number = getEditorNumber.call(this, number, "~380");
    delete this.editors[number];
  } catch (_) {}
}

// Consideration for multiple spreads/editors (grid view)

export function getEditorNumber(number, from = "~387") {
  unit.report({
    method: "getEditorNumber",
    from: from,
    message: "Get the editor number.",
  });
  const { errors } = this.props;
  try {
    return 0;
  } catch (error) {
    errors.warning(true, error, "~397");
    return 0;
  }
}
