/** @format */
import { withWorkspace } from "@Workspace/Context";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { withMedia } from "media";
import React, { Component as ReactComponent, createContext } from "react";
import { withUnit } from "unit";
import { withUser } from "user";
import Missing from "./assets/images/missing.png";
import config from "./config/index.json";

const ThumbContext = createContext({});

const StyledPreview = styled(Box)(() => ({
  "&": {
    padding: 0,
    margin: 0,
    zIndex: 100000,
    position: "fixed",
    bottom: "70px",
    right: "20px",
    backgroundColor: "transparent",
    border: "1px solid rgba(12,12,12,.05)",
    opacity: "1",
    borderRadius: "3px",
    display: "block",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
}));

class Thumb extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new(this.constructor.name);
    this.unit.report({
      method: "constructor",
      message: "Thumbnails will be collected.",
      from: "~41",
    });

    this.state = {
      preview: null,
      revised: 0,
      ready: false,
      size: { width: 0, height: 0 },
      thumbs: {},
      saving: false,
      dimensions: { width: 0, height: 0 }, // # TODO can use the demensions endpoint here to save time
    };

    this.preview = config.preview;
  }

  // Setups all thumbs for a particular feature

  setup = async ({ spreads }) => {
    // In case we need to regenerate them
    this.setState({ ready: false });
    return new Promise(async (resolve) => {
      try {
        resolve(true);
        for (let index = 0; index < spreads.length; index++) {
          await this.snap(index);
        }
        this.setState({ ready: true });
        return true;
      } catch (error) {
        throw error;
      }
    });
  };

  snap = async (index) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { workspace } = this.props;

        const token = workspace.token();
        const locale = workspace.language.get();
        const feature = workspace.feature.selected();

        const response = await axios.post(
          "/api/workspace/usable/designer/render/image/spread",
          {
            token: token,
            frame: 0,
            spread: index,
            locale: locale,
            feature: feature,
          },
          {
            responseType: "blob", // handle binary files as Blob
          }
        );

        const url = window.URL.createObjectURL(response.data);

        // Create a new image object
        const img = new Image();
        img.src = url;

        // Add an event listener to wait for the image to load
        img.onload = () => {
          // Get the dimensions once the image has loaded
          const originalWidth = img.width;
          const originalHeight = img.height;

          const maxWidth = 200;
          let width, height;

          if (originalWidth > maxWidth) {
            // Calculate new height to maintain aspect ratio
            width = maxWidth;
            height = (originalHeight / originalWidth) * maxWidth;
          } else {
            // Use original dimensions if the width is already less than or equal to maxWidth
            width = originalWidth;
            height = originalHeight;
          }

          this.setState((prevState) => ({
            preview: url,
            revised: Date.now(),
            thumbs: {
              ...prevState.thumbs,
              [index]: url,
            },
            size: {
              width: width,
              height: height,
            },
          }));

          resolve(response.data);
        };
      } catch (error) {
        console.warn("An error occurred:", error.message);
        reject(error);
      }
    });
  };

  // Adds null to the thumbs array at a specific index
  add = (index, from = "~147") => {
    const { thumbs } = this.state;
    // Insert null at the specified index
    thumbs.splice(index, 0, null);

    this.setState({ ready: false, thumbs }, () => {
      // Logging or other actions can go here, using 'from' as needed
      setTimeout(() => {
        this.setState({ ready: true });
      }, 1000);
    });
  };

  // Removes an item from the thumbs array at a specific index
  remove = (index, from = "~161") => {
    const { thumbs } = this.state;
    // Remove the item at the specified index
    thumbs.splice(index, 1);

    this.setState({ ready: false, thumbs }, () => {
      // Logging or other actions can go here, using 'from' as needed
      setTimeout(() => {
        this.setState({ ready: true });
      }, 1000);
    });
  };

  // Reorder that arry
  reorder = (start, end) => {
    const { thumbs } = this.state;

    let startThumb = thumbs[start];
    let endThumb = thumbs[end];

    thumbs[start] = endThumb;
    thumbs[end] = startThumb;

    this.setState({ ready: false, revised: Date.now(), thumbs: thumbs }, () =>
      setTimeout(() => {
        this.setState({ ready: true });
      }, 1000)
    );
  };

  /* Get individual thumb files */

  get = (index) => {
    try {
      return this.state.thumbs?.[index] || Missing;
    } catch (error) {
      console.warn("An error occurred:", error.message);
      return Missing;
    }
  };

  render() {
    return (
      <>
        <ThumbContext.Provider
          value={{
            ...this.state,
            get: this.get,
            snap: this.snap,
            add: this.add,
            remove: this.remove,
            reorder: this.reorder,
            setup: this.setup,
          }}
        >
          {this.props.children}
        </ThumbContext.Provider>
        {this.preview && this.state.preview && (
          <StyledPreview
            sx={{
              backgroundImage: `url(${this.state.preview})`,
              width: this.state.size.width || "200px",
              height: this.state.size.height || "200px",
            }}
          />
        )}
      </>
    );
  }
}

const withThumb = (Component) => {
  return function ContextualComponent(props) {
    return <ThumbContext.Consumer>{(state) => <Component {...props} thumb={state} />}</ThumbContext.Consumer>;
  };
};

const useThumb = () => React.useContext(ThumbContext);

export default withUnit(withMedia(withWorkspace(withUser(Thumb))));
export { useThumb, withThumb };
