/** @format */
import { Box } from "@mui/material";
import { Comments } from "discussion";
import { useLocales } from "locales";
import { useRouter } from "router";

function Category({ category, thread }) {
  // Wrap the url in localization
  const { url } = useLocales();

  // Get the navigation (use router to skip page checking...)
  const { navigate } = useRouter();

  // Do stuff with this comment
  const callback = (id) => {
    navigate(url(`/community/${category}/${id}`));
  };

  if (!category) return null;

  return (
    <Box>
      <Comments
        slug={`discussion-${category}`}
        enabled={true}
        placeholder="tellUsWhatYouThink"
        moderate={true}
        analyze={true}
        comment={!thread}
        replies={false}
        callback={callback}
        onClick={callback}
        showTitle={true}
        showControls={false}
        showFlagged={false}
        showTopical={false}
        showApproved={false}
        hover={true}
        link={`/community/${category}`}
      />
    </Box>
  );
}

export default Category;
