/** @format */

const modifier = (a, b) => {
  return [
    {
      name: "offset",
      options: {
        offset: [a, b],
      },
    },
  ];
};

export default modifier;
