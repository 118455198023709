/**
 * The Type
 *
 * @format
 */

export function getType(type) {
  this.unit.report({
    method: "getType",
  });
  try {
    if (!type) return this.state.type;
    return type == this.state.type;
  } catch (e) {
    return false;
  }
}

export function setType(type = "image", from = "~19") {
  this.unit.report({
    method: "setType",
    from: from,
  });
  this.setState({ type: type });
}
