/** @format */
import { Hidden } from "@mui/material";
import Text from "@Web/Blocks/Text";
import Container from "@Web/Container";

const FreeTrial = (props) => {
  return (
    <Hidden smDown>
      <Container style={{ padding: "5% 10%" }}>
        <Text {...props} number={2} />
      </Container>
    </Hidden>
  );
};

export default FreeTrial;
