/** @format */

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { app } from "firemade";
import { Functional } from "unit";
import { environment } from "version";

import buckets from "../../config/buckets.json";

const unit = Functional.unit("thumbs");

export async function setupThumbs(from = "~12") {
  return new Promise(async (resolve, reject) => {
    let testId = unit.report({
      method: "setupThumbs",
      test: "Make sure thumbs load when project/workspace is first loaded.",
      message: "Setup thumbs for project",
      from: from,
    });
    try {
      const { thumb } = this.props;
      const { spreads } = this.data("~22");

      thumb
        .setup({ spreads }) // # TODO as object for future consideration (like dimensions)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      reject(error);
    }
  });
}

// Saves the thumnail for reader and preview

export function saveSpreadThumb(number, blob, from = "~42") {
  // Existing reporting code
  unit.report({
    method: "setupPaste",
    message: "Setting up pasting.",
    tests: "Pasting should be available in the this.",
    from: from,
  });

  const { workspace } = this.props;
  const feature = workspace.feature.selected();
  const image = `${workspace.id}/${feature}/${number}.png`;

  return new Promise((resolve, reject) => {
    try {
      // Storage reference to the publishing bucket
      const storage = getStorage(app, buckets[environment]);

      // Firebase storage reference
      const storageRef = ref(storage, image);

      // Set custom metadata, including cache control
      const metadata = {
        cacheControl: "no-cache, no-store, must-revalidate",
      };

      // Upload blob to Firebase storage
      uploadBytes(storageRef, blob, metadata).then((snapshot) => {
        // Get download URL
        getDownloadURL(snapshot.ref).then((url) => {
          let data = this.data("~72");

          data.spreads[number] = { ...this.data("~74")?.spreads[number], thumb: url };

          // Update the state rather than the project directly
          // because the user might be dragging elements. It will
          // be updated on the next (throttled) update.
          this.setState({ data: data }, () => resolve(url));
        });
      });
    } catch (error) {
      console.error(error, "~83");
      reject(error);
    }
  });
}
