/** @format */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import capitalize from "capitalize";
import DynamicIcon from "dynamicicon";
import { useLocales } from "locales";
import { useUI } from "ui";

// Access dialogs, special components
import MissingProject from "./components/MissingProject";

export default function Access(props) {
  const ui = useUI();
  const { t } = useLocales();
  const { cancel, confirm, reason, disabled = false, component } = ui.dialogs?.access?.props || {};

  // Might not be ready yet
  if (!reason) return <></>;

  // Components
  let Component;

  if (component) {
    switch (component) {
      case "MissingProject":
        Component = MissingProject;
        break;
    }
  }

  return (
    <Dialog
      open={ui.opened("access")}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={ui.dialog.close}
    >
      <DialogTitle>
        <span>{capitalize.words(t("accessDialogTitle"))}</span>
        <IconButton variant="close" disabled={disabled} onClick={ui.dialog.close} size="small">
          <DynamicIcon icon="fa-times" />
        </IconButton>
      </DialogTitle>
      {component ? (
        <Component {...props} {...ui.dialogs.access.props} />
      ) : (
        <>
          <DialogContent sx={{ padding: "20px", minWidth: "300px" }}>{t(reason)}</DialogContent>
          {(cancel || confirm) && (
            <DialogActions>
              {cancel && (
                <Button onClick={cancel} disabled={disabled}>
                  {t("cancel")}
                </Button>
              )}
              {confirm && (
                <Button color="warning" variant="contained" disabled={disabled} onClick={confirm}>
                  {t("continue")}
                </Button>
              )}
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
}
