/** @format */
import { Box, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

import Container from "@Web/Container";
import { useLocales } from "locales";

const Hero = () => {
  const theme = useTheme();
  const { t } = useLocales();

  return (
    <Box
      height={300}
      position={"relative"}
      sx={{
        backgroundColor: theme.palette.secondary.main,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginTop: -12,
        paddingTop: 12,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha(theme.palette.info.main, 0.5),
          zIndex: 1,
        }}
      />
      <Container position={"relative"} zIndex={2}>
        <Box>
          <Box marginBottom={4} data-aos="fade-up">
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontWeight: 900,
                color: "common.white",
                textAlign: "center",
                marginTop: "50px",
              }}
            >
              {t("discussAway")}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
