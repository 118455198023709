/** @format */
import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { Box, Button, Card, Stack, styled } from "@mui/material";
import { useLocales } from "locales";
import React, { useMemo } from "react";
import { useRouter } from "router";
import { useUI } from "ui";
import { useUser } from "user";

// Lazy load components with React.lazy
import Categories from "./components/Categories";
import Category from "./components/Category";
import Hero from "./components/Hero";
import Thread from "./components/Thread";

const Features = {
  Project: { slug: "project", icon: "fa-bars-progress", priority: 0, released: true },
  Manuscript: { slug: "manuscript", icon: "fa-typewriter", priority: 1, released: true },
  Planning: { slug: "planning", icon: "fa-code-branch", priority: 2, released: true },
  Characters: { slug: "characters", icon: "fa-people", priority: 3, released: true },
  Design: { slug: "design", icon: "fa-paintbrush", priority: 4, released: true },
  Registrations: { slug: "registrations", icon: "fa-copyright", priority: 5, released: true },
  Target: { slug: "target", icon: "fa-bullseye", priority: 6, released: true },
  Onboarding: { slug: "onboarding", icon: "fa-signature", priority: 7, released: true },
  ISBN: { slug: "isbn", icon: "fa-barcode-read", priority: 8, released: true },
  PCIP: { slug: "pcip", icon: "fa-scanner-touchscreen", priority: 9, released: true },
  PCN: { slug: "pcn", icon: "fa-barcode-read", priority: 10, released: true },
  Queries: { slug: "queries", icon: "fa-envelope", priority: 11, released: true },

  Animation: { slug: "animation", icon: "fa-clapperboard", priority: 0, released: false },
  Audiobooks: { slug: "audiobooks", icon: "fa-waveform", priority: 1, released: false },
  Betareads: { slug: "betareads", icon: "fa-input-text", priority: 2, released: false },
  Distribution: { slug: "distribution", icon: "fa-chart-network", priority: 3, released: false },
  Illustration: { slug: "illustration", icon: "fa-paintbrush-pencil", priority: 4, released: false },
  Interactive: { slug: "interactive", icon: "fa-tablet-button", priority: 5, released: false },
  Marketing: { slug: "marketing", icon: "fa-signal-stream", priority: 6, released: false },
  Storyboard: { slug: "storyboard", icon: "fa-person-chalkboard", priority: 0, released: false },
  Summary: { slug: "summary", icon: "fa-cloud-word", priority: 7, released: false },
  Trim: { slug: "trim", icon: "fa-ruler-combined", priority: 8, released: false },
};

// Animated something
const AnimatedBox = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const CommunityMain = () => {
  const { dialog } = useUI();
  const { params } = useRouter();
  const { category, thread } = params;
  const { authenticated, meta } = useUser();
  const { t } = useLocales();

  // Determine the widths based on the presence of parameters
  const leftBox = !category ? "100%" : !thread ? "33%" : "10%";
  const centerBoxWidth = !category ? "0%" : !thread ? "66%" : "45%";
  const rightBoxWidth = !category ? "0%" : !thread ? "0%" : "45%";

  // Memoize sortedFeatures logic to avoid recalculations on every render
  const sortedFeatures = useMemo(() => {
    return Object.entries(Features)
      .map(([title, data]) => ({ title, ...data }))
      .sort((a, b) => a.priority - b.priority || b.released - a.released)
      .filter((feature) => feature.released);
  }, []);

  return (
    <Main colorInvert={true}>
      <Hero />
      {authenticated && meta.get("beta") ? (
        <Container>
          <Stack direction="row" spacing={0}>
            <Box sx={{ width: leftBox, marginRight: "10px" }}>
              <Card sx={{ paddingBottom: "20px" }}>
                <Categories features={sortedFeatures} category={category} thread={thread} />
              </Card>
            </Box>
            <AnimatedBox sx={{ width: centerBoxWidth }}>
              <Category category={category} thread={thread} />
            </AnimatedBox>
            <AnimatedBox sx={{ width: rightBoxWidth, backgroundColor: "transparent" }}>
              <Thread category={category} thread={thread} />
            </AnimatedBox>
          </Stack>
        </Container>
      ) : (
        <Box sx={{ padding: "10%", textAlign: "center" }}>
          <Box component="h3" sx={{ padding: "0 20% 5%" }}>
            {t("comingSoonCommunity")}
          </Box>
          <Box>
            <Button size="large" variant="contained" color="success" onClick={() => dialog.open("signin")}>
              {t("signIn")}
            </Button>
          </Box>
        </Box>
      )}
    </Main>
  );
};

export default CommunityMain;
