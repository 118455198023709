/** @format */

import config from "../../config" assert { type: "json" };

import { getSection } from "./Section";

import isSSR from "isssr";

/**
 * Handles the popstate event and navigates to the appropriate path.
 */
export function popstate() {
  if (isSSR) return;
  window.onpopstate = (pop) => {
    if (getSection.call(this, "app")) return;
    try {
      let path = config.home.includes(pop.target.location.pathname) ? config.home[0] : pop.target.location.pathname;
      this.navigate(path);
    } catch (error) {}
  };
}
