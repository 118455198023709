/** @format */
import Cookies from "cookies";

import isSSR from "isssr";

export function setMode(mode) {
  if (!mode || mode == this.state.settings.mode) return;

  Cookies.set("mode", mode);

  if (!isSSR) document.body.setAttribute("data-mode", mode);

  this.setState((prevState) => ({
    ...prevState,
    settings: {
      ...prevState.settings,
      mode,
    },
  }));
}

export function getMode(mode = null) {
  return mode ? mode == this.state.settings.mode : this.state.settings.mode;
}

export function toggleMode() {
  try {
    setMode.call(this, this.state.settings.mode == "light" ? "dark" : "light");
  } catch (_) {}
}
