/** @format */

import { Frame } from "scenejs";

// Default values for the properties
const defaultTransform = {
  rotate: 0,
  scale: [1, 1],
  matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
};

/**
 * Normalizes the given style object by ensuring certain properties have appropriate units and formats.
 *
 * @param {Object} style - The style object to normalize.
 * @param {Object} style.transform - The transform properties of the style object.
 * @param {string|number} [style.transform.rotate] - The rotation value, which will be normalized to a string with "deg".
 * @param {Array<number>} [style.transform.matrix3d] - The matrix3d transformation values.
 * @param {Array<number>} [style.transform.scale] - The scale transformation values.
 * @param {string|number} [style.width] - The width value, which will be normalized to a string with "px".
 * @param {string|number} [style.height] - The height value, which will be normalized to a string with "px".
 * @param {string|number} [style.top] - The top value, which will be normalized to a string with "px".
 * @param {string|number} [style.left] - The left value, which will be normalized to a string with "px".
 * @returns {Object} The normalized style object.
 */
export function getNormalizedStyle(style) {
  const isValidDegree = (value) => {
    const regex = /^-?\d+(\.\d+)?$/;
    return regex.test(value);
  };

  const newStyle = { ...style };

  // Normalize transform properties for CSS
  if (style.transform) {
    const transformStrings = [];

    // Rotate needs to be a string with "deg"
    if (style.transform.rotate !== undefined) {
      if (!isValidDegree(style.transform.rotate.toString().replace("deg", ""))) {
        style.transform.rotate = 0;
      }

      if (!style.transform.rotate.toString().endsWith("deg")) {
        style.transform.rotate += "deg";
      }

      transformStrings.push(`rotate(${style.transform.rotate})`);
    }

    if (style.transform.matrix3d) {
      transformStrings.push(`matrix3d(${style.transform.matrix3d.join(",")})`);
    }

    if (style.transform.scale) {
      transformStrings.push(`scale(${style.transform.scale.join(",")})`);
    }

    newStyle.transform = transformStrings.join(" ");
  }

  // Add "px" to width, height if they are valid numbers
  ["width", "height"].forEach((property) => {
    if (style[property] !== undefined) {
      const isPxNumber = typeof style[property] === "string" && style[property].endsWith("px");
      const isPlainNumber = typeof style[property] === "number";
      let pixelValue = isPlainNumber ? style[property] : parseFloat(style[property]);

      if (isPxNumber || isPlainNumber) {
        newStyle[property] = pixelValue + "px";
      } else {
        newStyle[property] = "auto";
      }
    }
  });

  // Add "px" to width, height, top, and left if they are valid numbers
  ["width", "height", "top", "left"].forEach((property) => {
    if (style[property] !== undefined) {
      const isPxNumber = typeof style[property] === "string" && style[property].endsWith("px");
      const isPlainNumber = typeof style[property] === "number";
      let pixelValue = isPlainNumber ? style[property] : parseFloat(style[property]);

      if (isPxNumber || isPlainNumber) {
        newStyle[property] = pixelValue + "px";
      } else {
        newStyle[property] = "auto";
      }
    }
  });

  return newStyle;
}

/**
 * Extracts and structures specific style properties from the given style object.
 * Ensures that width and height are at least 40px, and adjusts 'top' and 'left' properties
 * to ensure elements are within bounds.
 *
 * @param {Object} style - The style object containing CSS properties.
 * @param {string|number} [style.top] - The top position of the element.
 * @param {string|number} [style.left] - The left position of the element.
 * @param {string|number} [style.width] - The width of the element.
 * @param {string|number} [style.height] - The height of the element.
 * @param {string} [style.transform] - The transform property of the element.
 * @returns {Object} The structured style object with adjusted properties.
 */
export function getStructuredStyle(style) {
  let structuredStyle = {};

  // Extract only the properties we want
  ["top", "left", "width", "height", "transform"].forEach((prop) => {
    if (style[prop] !== undefined) {
      structuredStyle[prop] = style[prop];
    }
  });

  if (typeof structuredStyle.transform === "string") {
    structuredStyle.transform = getStructuredTransform.call(this, structuredStyle.transform);
  }

  ["width", "height"].forEach((property) => {
    if (structuredStyle[property] !== undefined) {
      if (typeof structuredStyle[property] === "string") {
        const isPxNumber = structuredStyle[property].endsWith("px");
        const isAuto = structuredStyle[property] === "auto";
        if (isPxNumber) {
          let parsedValue = parseFloat(structuredStyle[property]);
          parsedValue = parsedValue < 40 ? 40 : parsedValue;
          structuredStyle[property] = parsedValue;
        } else if (isAuto) {
          structuredStyle[property] = 40;
        } else {
          const parsed = parseFloat(structuredStyle[property]);
          structuredStyle[property] = isNaN(parsed) || parsed < 40 ? 40 : parsed;
        }
      } else if (typeof structuredStyle[property] === "number") {
        structuredStyle[property] = structuredStyle[property] < 40 ? 40 : structuredStyle[property];
      }
    }
  });

  // Adjust 'top' and 'left' to ensure the elements are within the bounds
  ["top", "left"].forEach((property) => {
    if (structuredStyle[property] !== undefined) {
      if (typeof structuredStyle[property] === "string") {
        const isPxNumber = structuredStyle[property].endsWith("px");
        const isAuto = structuredStyle[property] === "auto";
        let pixelValue = parseFloat(structuredStyle[property]);

        if (isPxNumber || isAuto) {
          structuredStyle[property] = pixelValue;
        } else {
          const parsed = parseFloat(structuredStyle[property]);
          structuredStyle[property] = isNaN(parsed) ? pixelValue : parsed;
        }
      }
    }
  });

  return structuredStyle;
}

/**
 * Parses a CSS transform string and returns an object with structured transform properties.
 *
 * @param {string} transform - The CSS transform string to be parsed.
 * @returns {Object} An object containing the structured transform properties.
 * @property {number} [rotate] - The rotation value in degrees.
 * @property {number[]} [scale] - The scale values as an array of numbers.
 * @property {number[]} [matrix3d] - The matrix3d values as an array of numbers.
 */
export function getStructuredTransform(transform) {
  const structuredTransform = {};

  try {
    // Split string into components
    const components = transform.match(/(\w+\([^\)]+\))/g);

    components.forEach((component) => {
      // Each transform component should start with its name, followed by parentheses
      const match = component.match(/^(\w+)\((.*?)\)$/);

      // A mismatch transform, we do nothing
      if (!match) return;

      const [, name, value] = match;

      switch (name) {
        case "rotate":
          structuredTransform.rotate = parseFloat(value.replaceAll("deg", ""));
          break;
        case "scale":
          structuredTransform.scale = value.split(",").map(Number);
          break;
        case "matrix3d":
          structuredTransform.matrix3d = value.split(",").map(Number);
          break;
        default:
          //console.error("Unknown transform component:", name);
          break;
      }
    });

    return structuredTransform;
  } catch (e) {
    return defaultTransform;
  }
}

/**
 * Normalizes a transform object into a CSS transform string.
 *
 * @param {Object} transform - The transform object to normalize.
 * @param {number} [transform.rotate] - The rotation in degrees.
 * @param {number[]} [transform.scale] - The scale factors as an array of two numbers.
 * @param {number[]} [transform.matrix3d] - The 3D transformation matrix as an array of 16 numbers.
 * @returns {string} The normalized CSS transform string.
 */
export function getNormalizedTransform(transform) {
  if (!transform) return "";

  const transformParts = [];

  if (transform.rotate !== undefined) {
    transformParts.push(`rotate(${transform.rotate}deg)`);
  }

  if (transform.scale && Array.isArray(transform.scale) && transform.scale.length === 2) {
    transformParts.push(`scale(${transform.scale.join(",")})`);
  }

  if (transform.matrix3d && Array.isArray(transform.matrix3d) && transform.matrix3d.length === 16) {
    transformParts.push(`matrix3d(${transform.matrix3d.join(",")})`);
  }

  return transformParts.join(" ");
}

/**
 * Validates and ensures the necessary structure of the style data.
 *
 * @param {Object} data - The data object containing style information.
 * @param {number} spread - The index of the spread to validate.
 * @param {number} index - The index of the element within the spread.
 * @param {number} frame - The frame index within the element's style array.
 * @returns {Object} - The updated data object with validated style structure.
 */
export function validateStyle(data, spread, index, frame) {
  // Ensuring the necessary structure of data exists
  if (!data) {
    data = {};
  }
  if (!data.spreads) {
    data.spreads = [];
  }
  if (!data.spreads[spread]) {
    data.spreads[spread] = { elements: [] };
  }
  if (!data.spreads[spread].elements) {
    data.spreads[spread].elements = [];
  }
  if (!data.spreads[spread].elements[index]) {
    data.spreads[spread].elements[index] = { style: [] };
  }
  if (!data.spreads[spread].elements[index].style) {
    data.spreads[spread].elements[index].style = [];
  }

  let style = data.spreads[spread].elements[index].style[frame];

  if (!style) {
    data.spreads[spread].elements[index].style[frame] = {};
    style = data.spreads[spread].elements[index].style[frame];
  }

  if (!style.width) {
    style.width = 200;
  }

  if (!style.height) {
    style.width = 200;
  }

  let transform = style.transform;

  if (!transform) {
    style.transform = { ...defaultTransform };
    transform = style.transform;
  } else {
    if (transform.rotate === undefined) {
      transform.rotate = defaultTransform.rotate;
    }

    if (!transform.scale || !Array.isArray(transform.scale) || transform.scale.length !== 2) {
      transform.scale = defaultTransform.scale;
    }

    if (!transform.matrix3d || !Array.isArray(transform.matrix3d) || transform.matrix3d.length !== 16) {
      transform.matrix3d = defaultTransform.matrix3d;
    }
  }

  // Return updated data
  return data;
}

/**
 * Retrieves the style for a given element based on its properties and the current state.
 *
 * @param {Object} props - The properties of the element.
 * @param {string} [from="~230"] - The source identifier for logging purposes.
 * @returns {Object} The CSS style object for the element.
 *
 * @throws Will throw an error if the element style cannot be retrieved.
 */
export function getElementStyle(props) {
  let { index, spread = this.state?.spread?.number || 0, frame = this.state.frame.number || 0 } = props;

  const exit = (_) => {
    return new Frame({}).toCSSObject();
  };

  try {
    const data = this.data("~246");

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) return exit("Missing element or element style.", "~248");

    let style = getStructuredStyle.call(this, data?.spreads[spread]?.elements[index]?.style[frame], "~250");

    style.zIndex = 2500 + index;
    style.whiteSpace = "pre";

    // Deal with any images that might be off the spread (background, lowest layer)
    if (props.index === 0) {
      // Allowed margin: up to 90% of the element can be outside on all sides
      const allowedOutsideMargin = 0.9; // 10% inside

      // Deconstruct for clarity
      const { width: elementWidth, height: elementHeight, left: elementLeft, top: elementTop } = style;
      const { top: boundsTop, right: boundsRight, bottom: boundsBottom, left: boundsLeft } = this.state.bounds;

      // Calculate the visible portion of the element
      const visibleWidthLeft = Math.max(0, boundsLeft - elementLeft);
      const visibleWidthRight = Math.max(0, elementLeft + elementWidth - boundsRight);
      const visibleHeightTop = Math.max(0, boundsTop - elementTop);
      const visibleHeightBottom = Math.max(0, elementTop + elementHeight - boundsBottom);

      // Check if the element is outside the bounds
      const isOutsideToLeft = visibleWidthLeft >= elementWidth * allowedOutsideMargin;
      const isOutsideToRight = visibleWidthRight >= elementWidth * allowedOutsideMargin;
      const isOutsideToTop = visibleHeightTop >= elementHeight * allowedOutsideMargin;
      const isOutsideToBottom = visibleHeightBottom >= elementHeight * allowedOutsideMargin;

      // Reposition the element if it is outside the bounds
      if (isOutsideToLeft) {
        style.left = boundsLeft;
      }
      if (isOutsideToRight) {
        style.left = boundsRight - elementWidth;
      }
      if (isOutsideToTop) {
        style.top = boundsTop;
      }
      if (isOutsideToBottom) {
        style.top = boundsBottom - elementHeight;
      }
    }

    return getNormalizedStyle(style);
  } catch (error) {
    return exit(error, "~294");
  }
}

/**
 * Retrieves the rotation of an element based on the provided properties.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.id - The ID of the element.
 * @param {number} props.index - The index of the element.
 * @param {number} [props.spread] - The spread number, defaults to the state's spread number.
 * @param {number} [props.frame] - The frame number, defaults to the state's frame number.
 * @returns {number} The rotation value of the element.
 */
export function getElementRotation(props) {
  let { index, spread = this.state?.spread?.number || 0, frame = this.state.frame.number || 0 } = props;

  const exit = (_) => {
    return 0;
  };

  try {
    const data = this.data("~314");

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) return exit("Missing element or element style.", "~316");

    return getStructuredStyle.call(this, data.spreads[spread].elements[index].style[frame], "~318").transform.rotate;
  } catch (error) {
    return exit(error, "~320");
  }
}

/**
 * Sets the style of an element based on the provided properties.
 *
 * @param {Object} props - The properties for setting the element style.
 * @param {Object} props.event - The event object containing the target element.
 * @param {Object} props.style - The style object to be applied to the element.
 *
 * @throws Will throw an error if there is an issue normalizing or applying the style.
 */
export function setElementStyle(props) {
  let { event, style } = props;

  if (!event) return;

  // Normalize the style before applying
  try {
    const normalizedStyle = getNormalizedStyle(style);

    const data = this.data();
    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected().index;
    const frame = this.state?.frame?.number || 0;

    // Apply only specific styles to DOM
    ["top", "left", "height", "width", "transform"].forEach((key) => {
      if (normalizedStyle[key] !== undefined && normalizedStyle[key] !== null) {
        event.target.style[key] = normalizedStyle[key];
      } else {
        const value = data.spreads?.[spread]?.elements?.[index]?.style?.[frame][key];
        if (value) {
          if (key === "transform") {
            const normalizedTransform = getNormalizedTransform(value);
            event.target.style[key] = normalizedTransform;
          } else {
            event.target.style[key] = value + "px";
          }
        }
      }
    });
  } catch (e) {}
}

/**
 * Saves the style of an element in the current state.
 *
 * @param {Object} style - The style object to be saved.
 * @throws Will throw an error if the style cannot be saved.
 */
export function saveElementStyle(target, style) {
  try {
    const data = this.data();
    const spread = this.state?.spread?.number || 0;
    const index = parseInt(target.getAttribute("data-index"));
    const frame = this.state?.frame?.number || 0;

    if (!data?.spreads?.[spread]?.elements?.[index]?.style?.[frame]) return;

    const currentStateStyle = data.spreads[spread].elements[index].style[frame];

    // Ensure we're working with structured styles
    const structuredStyle = getStructuredStyle.call(this, style);
    const mergedStyle = {
      ...currentStateStyle,
      ...structuredStyle,
      transform: {
        ...currentStateStyle.transform,
        ...structuredStyle.transform,
      },
    };

    this.setState(
      (prevState) => {
        const prevData = prevState.data;
        if (!prevData?.spreads?.[spread]?.elements?.[index]?.style?.[frame]) return prevState;

        const updatedData = {
          ...prevData,
          spreads: prevData.spreads.map((s, i) => {
            if (i !== spread) return s;
            return {
              ...s,
              elements: s.elements.map((e, j) => {
                if (j !== index) return e;
                return {
                  ...e,
                  style: e.style.map((st, k) => {
                    if (k !== frame) return st;
                    return mergedStyle;
                  }),
                };
              }),
            };
          }),
        };

        return { data: validateStyle.call(this, updatedData, spread, index, frame) };
      },
      () => {
        this.debounceCreateUndo();
        this.debounceUpdate(this.state.data);
      }
    );
  } catch (e) {
    console.error("Error in saveElementStyle:", e);
  }
}

/**
 * Resets the style of an element to its default dimensions and orientation.
 *
 * @param {boolean} [confirm=true] - Whether to confirm the reset action.
 * @param {string} [from="~411"] - The source identifier for the reset action.
 * @returns {void}
 */
export function resetElementStyle(confirm = true) {
  const { errors, t, workspace } = this.props;

  const exit = (error = null) => {
    if (!error) return;
    return errors.error(t("errorResetElement"), error, "~422");
  };

  if (!confirm) {
    workspace.confirm.open({
      message: "confirmResetStyle",
      confirm: () => resetElementStyle.call(this, true, "~428"),
    });
    return;
  }

  try {
    let data = this.data("~434");

    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected().index;
    const frame = this.state?.frame?.number || 0;

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) exit();

    data.spreads[spread].elements[index].style[frame].transform = {
      ...data.spreads[spread].elements[index].style[frame].transform,
      ...defaultTransform,
    };

    this.project
      .update(
        {
          data: data,
          note: "Reset element style.",
          ai: 0,
        },
        "~454"
      )
      .then(() => {
        this.element.refresh();
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    return exit(error);
  }
}

/**
 * Determines if the current element can be reset to its default state.
 *
 * This function checks if any of the transform properties of the current element
 * do not match the default transform properties. If any property differs, the
 * element is considered resettable.
 *
 * @returns {boolean} - Returns `true` if the element can be reset, otherwise `false`.
 */
export function getElementResetable() {
  try {
    const data = this.data("~475");
    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected().index;
    const frame = this.state.frame.number || 0;

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) return false;

    // Check if any of the transform properties are non-default
    const transform = getStructuredStyle.call(
      this,
      data.spreads[spread].elements[index].style[frame],
      "~486"
    ).transform;

    // Check if any properties don't match the default
    for (let property in defaultTransform) {
      if (JSON.stringify(transform[property]) !== JSON.stringify(defaultTransform[property])) {
        return true; // The element can be reset
      }
    }

    return false; // The element cannot be reset (it is already in default state)
  } catch (_) {
    return false;
  }
}

/**
 * Merges the current style of a target element with its current state style.
 *
 * @param {HTMLElement} target - The target element whose styles are to be merged.
 * @param {boolean} [on=true] - A flag indicating whether to normalize or structure the transform.
 * @returns {Object} The merged style object.
 */
export function mergeStyles(target, on = true) {
  const index = parseInt(target.getAttribute("data-index"));
  const spread = this.state?.spread?.number || 0;
  const frame = this.state?.frame?.number || 0;

  const extractNumber = (value) => {
    if (typeof value === "number") return value;
    const match = value?.match(/^(-?\d*\.?\d+)(?:px|%|em|rem|vh|vw)?$/);
    return match ? parseFloat(match[1]) : 0;
  };

  const getStyleValue = (property) => {
    const value = extractNumber(target.style[property] || currentStateStyle[property] || 0);
    return `${value}px`;
  };

  // Capture the current state
  const currentStateStyle = this.state.data?.spreads?.[spread]?.elements?.[index]?.style?.[frame] || {};

  // Merge current state with current style
  const mergedStyle = {
    ...currentStateStyle,
    top: getStyleValue("top"),
    left: getStyleValue("left"),
    width: getStyleValue("width"),
    height: getStyleValue("height"),
    transform: (on ? getNormalizedTransform : getStructuredTransform).call(
      this,
      target.style.transform || currentStateStyle.transform || {}
    ),
  };

  return mergedStyle;
}

/**
 * Resets the transform properties of an element.
 * @deprecated
 *
 * @param {Object} _event - The event object containing the target element.
 * @param {Object} _event.target - The target element of the event.
 * @param {Object} _event.target.style - The style object of the target element.
 * @param {string} _event.target.style.transform - The transform property of the target element.
 */
export function resetElementTransform(_event) {
  try {
    // event.setFixedDirection([0, 0]);
    // event.setTransform(event.target.style.transform, 0);
  } catch (error) {
    console.error(error);
  }
}
