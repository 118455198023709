/** @format */
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useWorkspace } from "@Workspace/Context";
import DynamicIcon from "dynamicicon";
import { useLocales } from "locales";
import React from "react";

import ChecklistComponent from "@Workspace/components/Checklist";

const Checklist = () => {
  const { t } = useLocales();
  const {
    checklist: {
      open: { toggle, get },
    },
  } = useWorkspace();

  const open = get() || false;

  return (
    <Dialog open={open} onClose={toggle} aria-labelledby="checklist-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle>
        <span>{t("checklist")}</span>
        <IconButton variant="close" size="small" onClick={toggle}>
          <DynamicIcon icon="fa-times" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ChecklistComponent />
      </DialogContent>
    </Dialog>
  );
};

export default Checklist;
