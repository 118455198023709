/** @format */
import { Stack } from "@mui/material";
import Logo from "logo";

export default function Loader(props) {
  const { title, label, message, workmark, from, direction } = props;
  return (
    <Stack
      direction={direction || "column"}
      justifyContent="center"
      alignItems="center"
      spacing={2}
      data-place="loader"
    >
      <Logo {...props} wordmark={workmark || false} from={from || "loader"} />
      {(label || title || message) && <h4>{label || title || message}</h4>}
    </Stack>
  );
}
