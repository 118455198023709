/**
 * This file is the entry point for the client-side of the MadeLive app.
 * It imports necessary dependencies, initializes Sentry error tracking,
 * and renders the main App component.
 *
 * @format
 */

import "sentry";

import ReactDOM from "react-dom/client";

import { Setup } from "unit";

import { Spa } from "app";

// Bind unit reports to unit
// import { ConsoleReport, HotJarReport, MixPanelReport, TagsReport, TikTokReport } from "./config";
import { ConsoleReport, TagsReport } from "./config";

// Create the root once
const app = document.getElementById("app");

// Try this to get around googlebot errors
Setup.units({
  units: [ConsoleReport, TagsReport],
}).then(() => {
  // Hydrate it i already populated
  if (app.hasChildNodes()) {
    // ReactDOM.hydrateRoot(app, <Spa />);
    ReactDOM.createRoot(app).render(<Spa />);
    // Otherwise create it (mostly for dev)
  } else {
    ReactDOM.createRoot(app).render(<Spa />);
  }
});
