/** @format */
import { Tab } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { usePerforming } from "performing";
import { useUI } from "../../context";

export default function TabComponent(props) {
  const ui = useUI();
  const { disabled = false } = usePerforming();
  const {
    slug,
    component,
    variant = "button",
    size = "small",
    label,
    index,
    icon,
    rest,
    onClick = null,
    lottie = null,
  } = props;
  const tabs = slug || component;
  if (!tabs) return <></>;

  return (
    <Tab
      variant={variant}
      size={size}
      icon={lottie == null ? <DynamicIcon icon={icon} /> : <DynamicIcon icon="fa-sparkles" />}
      label={<label>{label}</label>}
      disabled={props.disabled == true || disabled == true}
      onClick={() => {
        ui.tab.set({ [tabs]: index });
        if (onClick) onClick();
      }}
      className={ui.tab.value(tabs) === index ? "Mui-selected" : ""}
      sx={{ "& > .MuiTab-iconWrapper": { marginBottom: !lottie ? "auto" : "-2.5px" } }}
      {...rest}
    />
  );
}
