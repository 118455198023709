/** @format */
import { Tabs } from "@mui/material";
import { MissingError } from "errors";
import { useUI } from "../../context";

export default function TabsComponent(props) {
  const ui = useUI();
  const { slug, component, children, centered } = props;
  const tabs = slug || component;

  if (!tabs || !children) return <MissingError />;

  const variant = centered ? undefined : "scrollable"; // Check if centered prop is true

  return (
    <Tabs
      style={{ overflow: "initial", minHeight: "initial" }}
      value={ui.tab.value(tabs)}
      onChange={(_, f) => {
        ui.tab.set({ [tabs]: f });
      }}
      variant={variant}
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
      centered={centered}
    >
      {children}
    </Tabs>
  );
}
