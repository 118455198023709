/** @format */

import { teardownAi } from "./AI";
import { teardownProject } from "./Project";

export default function Teardown() {
  try {
    this.save();
  } catch (e) {}
  try {
    teardownProject.call(this);
  } catch (e) {}
  try {
    teardownAi.call(this);
  } catch (e) {}
}
