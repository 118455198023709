/** @format */
import { collection } from "firebase/firestore";
import { db } from "firemade";
import { Component as ReactComponent, createContext, useContext } from "react";
import { withUnit } from "unit";
import config from "../config";

import { collectionCount, getCollections, hasCollection, hasCollections } from "./helpers/Collections";

import { deleteMedia } from "./helpers/DeleteMedia";

import { clearFilter, getFilter, setFilter } from "./helpers/Filter";

import { getFiltered } from "./helpers/Filtered";

import { hasMedia } from "./helpers/HasMedia";

import { hasQuery } from "./helpers/HasQuery";

import { mediaCount } from "./helpers/MediaCount";

import { page } from "./helpers/Page";

import { process } from "./helpers/Process";

import { getMedia, hasQueries } from "./helpers/Queries";

import { query } from "./helpers/Query";

import { save } from "./helpers/Save";

import { getTags, hasTags, tagCount } from "./helpers/Tags";

import { update } from "./helpers/Update";

import { upload } from "./helpers/Upload";

const MediaContext = createContext({});

class Media extends ReactComponent {
  constructor(props) {
    super(props);
    this.unit = this.props.unit.new(this.constructor.name);
    this.connection = null;
    this.state = {
      ready: false,
      queried: false, // checks if we've actually queried yet.
      queries: {},
      filters: {
        name: null,
        userId: null,
        search: null,
        collections: [],
        tags: [],
        attachedTo: [],
        meta: {},
      },
    };
    this.lastVisible = {};
    this.queryStates = {};

    this.collectionCount = collectionCount.bind(this);
    this.getCollections = getCollections.bind(this);
    this.hasCollection = hasCollection.bind(this);
    this.hasCollections = hasCollections.bind(this);
    this.deleteMedia = deleteMedia.bind(this);
    this.clearFilter = clearFilter.bind(this);
    this.getFilter = getFilter.bind(this);
    this.setFilter = setFilter.bind(this);
    this.getFiltered = getFiltered.bind(this);
    this.hasMedia = hasMedia.bind(this);
    this.hasQuery = hasQuery.bind(this);
    this.mediaCount = mediaCount.bind(this);
    this.page = page.bind(this);
    this.process = process.bind(this);
    this.getMedia = getMedia.bind(this);
    this.hasQueries = hasQueries.bind(this);
    this.query = query.bind(this);
    this.save = save.bind(this);
    this.getTags = getTags.bind(this);
    this.hasTags = hasTags.bind(this);
    this.tagCount = tagCount.bind(this);
    this.update = update.bind(this);
    this.upload = upload.bind(this);
  }

  /* Connection */

  connect = () => {
    return new Promise(async (resolve, reject) => {
      try {
        this.connection = collection(db, "media");
        this.setState({ ready: true });
        return resolve(this.connection);
      } catch (error) {
        this.onError(error);
        return reject(error);
      }
    });
  };

  /* Cdn stuff */
  transformUrl(url) {
    const path = new URL(url).pathname;
    const fileName = path.substring(path.lastIndexOf("/") + 1);
    const newPath = `https://${config.cdn[import.meta.env.MODE]}/${fileName}`;
    return newPath;
  }

  replaceUrlsInObject = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object") {
          // Recur for nested objects
          this.replaceUrlsInObject(obj[key]);
        } else if (key === "url") {
          // Transform and replace the url
          obj[key] = this.transformUrl(obj[key]);
        }
      }
    }
  };

  // Get without snapshot listener
  select = async ({ field, value }) => {
    console.log("missing");
  };

  onError = (error) => {
    const { errors } = this.props;
    if (errors) errors.error(this.props.t("unexpectedError"), error, "~151");
  };

  componentDidMount = () => {
    this.connect();
  };

  render() {
    return (
      <MediaContext.Provider
        value={{
          ...this.state,
          query: this.query,
          get: this.getMedia,

          page: this.page, // next page of resultss

          delete: this.deleteMedia,
          update: this.update,

          save: this.save,
          upload: this.upload,
          process: this.process, // Adds post-processing to media
          queried: this.hasQuery,

          count: this.mediaCount,
          has: this.hasMedia,

          queries: {
            has: this.hasQueries,
            get: this.getMedia,
          },

          collection: {
            has: this.hasCollection,
            get: this.getCollections,
            count: this.collectionCount,
          },

          collections: {
            has: this.hasCollections,
            get: this.getCollections,
            count: this.collectionCount,
          },

          tag: {
            has: this.hasTags,
            get: this.getTags,
          },

          tags: {
            has: this.hasTags,
            get: this.getTags,
            count: this.tagCount,
          },

          filter: {
            set: this.setFilter,
            toggle: this.setFilter,
            get: this.getFilter,
            clear: this.clearFilter,
          },

          filtered: {
            get: this.getFiltered,
          },

          document: {
            get: this.getMedia,
          },
        }}
      >
        {this.props.children}
      </MediaContext.Provider>
    );
  }
}

const withMedia = (Component) => (props) => {
  return <MediaContext.Consumer>{(state) => <Component {...props} media={state} />}</MediaContext.Consumer>;
};

const useMedia = () => {
  const context = useContext(MediaContext);
  return context;
};

export default withUnit(Media);
export { useMedia, withMedia };
