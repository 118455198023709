/** @format */

import { Functional } from "unit";

const unit = Functional.unit("workspace/drag");

export function onDragStart() {
  unit.report({
    method: "onDragStart",
    analyze: true,
    action: true,
    test: "A user should be able to drag draggable media.",
  });
  this.setState({ dragging: true });
}

export function onDragEnd() {
  unit.report({
    method: "onDragEnd",
    analyze: true,
    action: true,
    test: "When dragging has stopped, any routines related to executing should execute.",
  });
  try {
    this.setState({ dragging: false });
  } catch (error) {
    console.error(error, "~27");
  }
}

export function onDrop(props, from = "~31") {
  unit.report({
    method: "onDrop",
    analyze: true,
    action: true,
    from: from,
    test: "When dragging has stopped, any features relied upon it such as the storyboard should respond.",
  });
  this.props.broadcast.send({
    to: "All",
    method: "onDropped",
    props: props,
    from: this.constructor.name,
  });
}

export function getDragging(from = "~47") {
  unit.report({
    method: "getDragging",
    analyze: true,
    action: true,
    from: from,
    test: "When dragging has stopped, any features relied upon it such as the storyboard should respond.",
  });
  return this.state.dragging;
}
