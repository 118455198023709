/** @format */

import Cookies from "cookies";
import { nanoid } from "nanoid";
import { Functional } from "unit";

import spreadTemplate from "../../config/templates/spread.json";
import Trims from "../../config/trims/sizes.json";

const unit = Functional.unit("/spread");

// Setup

export async function setupSpreads(from = "~14") {
  const { t } = this.props;
  return new Promise(async (resolve, reject) => {
    let testId = unit.report({
      method: "setupThumbs",
      test: "Make sure thumbs load when project/workspace is first loaded.",
      message: "Setup thumbs for project",
      from: from,
    });
    const { errors } = this.props;
    try {
      const data = this.data("~25");
      const { spreads } = data;

      try {
        // Going to calculate if we have enough spreads

        const data = this.data("~31");

        // Get the current amount of spreads
        let spreadCount = spreads.length;

        // Retrieve the minimum spreads required for the selected trim
        const minimumSpreads = Trims.find((trim) => trim.slug === this.state.data.trim).range[0];

        // Calculate the number of spreads that need to be added
        const spreadsToAdd = minimumSpreads - spreadCount;

        if (spreadsToAdd > 0) {
          // Add the spreads directly to the state to avoid any issues with undo/redo
          Array.from({ length: spreadsToAdd }, () => {
            const id = nanoid();
            data.spreads.push({
              ...spreadTemplate,
              ...{
                id: id,
              },
            });
          });

          // Update the project
          this.project.update(
            {
              data: data,
              note: "Added spread.",
              ai: 0,
              undo: false,
            },
            "~62"
          );
        }
      } catch (error) {
        errors.error(t("errorMinimumSpreads"), error, "~66");
      }

      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, message: error });
      reject(error);
    }
  });
}

// Check if the spreads are ready

export function getSpreadReady(number = null, from = "~79") {
  unit.report({
    method: "getSpreadReady",
    from: from,
    test: "A spread is completely setup and should look the same on a number of different sized screens.",
  });
  const { editors } = this;
  const { errors } = this.props;
  try {
    const { spreads } = this.data("~88");
    if (!number) number = this.state.spread.number;
    // This is a flaw for grid view
    return spreads[number] && editors[0]?.sized === true;
  } catch (error) {
    errors.warning(true, error, "~93");
    return false;
  }
}

// Get all the spreads

export function getSpreads(from = "~100") {
  unit.report({
    method: "listSpreads",
    from: from,
    message: "Listing spreads in menu",
    test: "A list of spreads available with thumbnail should be accessible from the spreads menu button at the bottom.",
  });
  try {
    return this.data("~108")?.spreads || [];
  } catch (e) {
    return [];
  }
}

// Add, delete and reorder spreads

export function addSpread(from = "~116") {
  unit.report({
    method: "addSpread",
    message: "Adding spread.",
    payload: this.state.plugin,
    analyze: true,
    action: true,
    from: from,
    test: "When a new spread is added it shows up at the bottom as the selected spread",
  });
  const { performing, errors, t, ui, workspace } = this.props;
  return new Promise(async (resolve, reject) => {
    try {
      const id = nanoid();
      const data = this.data("~130");
      // ... existing code to add a spread
      const { dos } = this.state;

      performing.set.updating("updating", "~134");

      let currentIndex = this.state.spread.number;
      let newPosition;

      if (currentIndex <= 1) {
        // Can't add after the first index (0) and second index (1)
        newPosition = 2; // Insert after the second spread.
      } else if (currentIndex === data.spreads.length - 1) {
        // If we're at the last index
        newPosition = currentIndex; // Insert before the last index
      } else {
        newPosition = currentIndex + 1; // Insert after the current index
      }

      // This is the new spread we're adding to the project and to the dos
      const newSpread = {
        ...spreadTemplate,
        ...{
          id: id,
        },
      };

      // Add new spread at the determined position
      data.spreads.splice(newPosition, 0, newSpread);

      // Use newPosition as the newPageNumber
      const newPageNumber = newPosition;

      // Add the spread to the undos
      dos[newPosition] = { number: 0, data: [newSpread] };

      // Delete the old dimensions
      await this.editor.dimensions.delete(workspace.id);

      // Restart the editor setup
      await this.editor.setup().then(() => {
        // resize the new editor with the new setup information
        this.editor.resize();
      });

      // Remove the undos first
      this.setState({ dos }, () => {
        this.project
          .update(
            {
              data: data,
              note: "Added spread.",
              ai: 0,
              undo: false,
            },
            "~176"
          )
          .then(async () => {
            // Set the newly added spread as a the selected spread
            return setSpreadByNumber
              .call(this, newPageNumber, "~181")
              .then(() => {
                ui.close();

                // Positive feedback or user
                performing.set.updating("success", "~186");

                // Show the spreads menu if it closed
                ui.menu.open("bottom/spreads");

                // Tell the world we're ok
                return resolve({ number: newPageNumber, id: id });
              })
              .catch((error) => {
                throw error;
              });
          })
          .catch((error) => {
            throw error;
          });
      });
    } catch (error) {
      performing.set.updating("error", "~203");
      errors.error(t("errorAddSpread"), error, "~204");
      reject(error);
    }
  });
}

export function deleteSpread(number = null, confirm = false, from = "~210") {
  unit.report({
    method: "deleteSpread",
    message: "Deleting spread.",
    payload: this.state.plugin,
    analyze: true,
    action: true,
    from: from,
    test: "When a spread is deleted it is reflected immediately locally and remotely.",
  });
  const { t, performing, errors, workspace, ui } = this.props;

  if (!number) number = this.state.spread.number;

  try {
    if (!confirm) {
      workspace.confirm.open({
        message: "confirmDeleteSpread",
        confirm: () => deleteSpread.call(this, number, true, "~228"),
      });
      return;
    }

    return new Promise(async (resolve, reject) => {
      try {
        // We have to remove undos as well
        const { dos } = this.state;
        const data = this.data("~237");

        if (data.spreads.length == 1) return reject("You cannot delete the exterior.");
        if (!data.spreads[number]) return reject("The spread you're trying to delete doesn't exist.");

        performing.set.updating("updating", "~242");

        data.spreads.splice(number, 1);

        // Remove the corresponding undo state when a spread is removed
        dos.splice(number, 1);

        // Remove the undos first
        this.setState({ dos }, () => {
          // Then update the project
          this.project
            .update(
              {
                data: data,
                note: "Deleted spread.",
                ai: 0,
                undo: false,
              },
              "~260"
            )
            .then(() => {
              performing.set.updating("success", "~263");
              setTimeout(() => ui.menu.open("bottom/spreads"), 1000);
              return resolve(true);
            })
            .catch((error) => {
              throw error;
            });
        });

        // Delete the old dimensions
        await this.editor.dimensions.delete(workspace.id);

        // Restart the editor setup
        await this.editor.setup().then(() => {
          // resize the new editor with the new setup information
          this.editor.resize();
        });
      } catch (error) {
        performing.set.updating(null, "~272");
        errors.error(t("errorDeleteSpread"), error);
        return reject(error);
      }
    });
  } catch (error) {
    errors.error(true, error);
  }
}

export function setSpreadOrder(start, end, from = "~282") {
  unit.report({
    method: "orderSpread",
    message: "Adding spread.",
    payload: this.props.desk,
    from: from,
    test: "When reordering a spread, it should do so without error and be reflected locally/remotely. Changes can be confirmed with a reload.",
  });
  const { errors, performing, thumb } = this.props;
  return new Promise(async (resolve, reject) => {
    try {
      performing.set.updating("updating", "~293");
      let { dos, spread } = this.state;
      const data = this.data("~295");
      const { spreads } = data;
      const { number } = spread;

      if (!start || !end) throw "Order information missing.";

      // Swap spreads using destructuring assignment
      [spreads[start], spreads[end]] = [spreads[end], spreads[start]];
      data.spreads = spreads;

      // Determine the new position of the current spread
      let newSpreadNumber = number;
      if (number === start) {
        newSpreadNumber = end;
      } else if (number === end) {
        newSpreadNumber = start;
      }

      // Swap undos using destructuring assignment
      [dos[start], dos[end]] = [dos[end], dos[start]];

      // Update the state ( to skip any problems with undo/redo)
      this.setState(
        { data: data, dos: dos, elements: [], spread: { ...spread, number: newSpreadNumber } },
        async () => {
          thumb.reorder(start, end);
          resolve(true);
        }
      );
    } catch (error) {
      errors.warning(true, error, "~325");
      performing.set.updating("error", "~326");
      reject(error);
    }
  });
}

// Spread information
export function getSpreadName(number = this.state.spread.number, from = "~333") {
  unit.report({
    method: "spreadName",
    message: "Generated spread name",
    from: from,
  });
  const { t } = this.props;
  const data = this.data("~340");
  try {
    if (number == 0) {
      return t("outsideCover");
    } else if (number == 1) {
      return t("insideCoverFront");
    } else if (number == data.spreads.length - 1) {
      return t("insideCoverBack");
    } else {
      return t("spread") + "  #" + (number + 1);
    }
  } catch (e) {
    return "";
  }
}

export function getSpreadNumber(number = null, from = "~356") {
  unit.report({
    method: "getSpreadNumber",
    message: "Generated spread name",
    from: "~360",
  });

  try {
    if (number !== null) return number == this.state.spread.number;
    return this.state.spread.number;
  } catch (error) {
    return 0;
  }
}

// Set the spread number
export function setSpreadByNumber(spread = this.state.spread.number || 0, from = "~372") {
  unit.report({
    method: "selectSpread",
    from: from,
    payload: { spread: spread },
    test: "Selecting a spread goes to the spread expected.",
  });

  const { errors, performing } = this.props;

  return new Promise((resolve, reject) => {
    try {
      const spreads = this.data("~384")?.spreads;

      if (spread == null) return reject("No spread number provided.");
      if (spread < 0) return reject("Too low.");
      if (spread > spreads.length - 1) return reject("Too high.");
      if (spread == this.state.spread.number) return resolve("Already there!");

      // Set that we're working on something
      performing.set.working(true);

      // Calculate if we're going between
      const resize = this.state.spread.number === 0 || spread === 0;

      // Update the state
      const setState = () => {
        return new Promise((resolve) => {
          this.setState(
            (prevState) => {
              return {
                ...prevState,
                elements: [],
                revised: Date.now(),
                spread: {
                  ...prevState.spread,
                  number: spread,
                },
              };
            },
            () => {
              performing.set.working(false);
              Cookies.set("workspace-designer-spread", this.state.spread.number);
              return resolve(this.state.spread.number);
            }
          );
        });
      };

      if (!resize) {
        return setState().then((response) => resolve(response));
      } else {
        // Get the number of the editor
        const number = this.editor.number(0, "~425");

        // Then resize the editor based on this
        this.editor
          .size(number, spread)
          .then(() => {
            return setState().then((response) => resolve(response));
          })
          .catch((error) => {
            throw error;
          });
      }
    } catch (error) {
      errors.warn(true, error, "~438");
      reject(error);
    }
  });
}

// Using the left/right buttons

export function setSpreadByDirection(direction, from = "~446") {
  unit.report({
    method: "setSpread",
    from: from,
    test: "Selecting a spread using forward backward buttonw goes to the spread expected.",
  });

  const { errors } = this.props;

  try {
    const { spreads } = this.data("~456");
    const { spread } = this.state;
    if ((direction == "next" && spread.number == spreads.length - 1) || (direction == "previous" && spread.number == 0))
      return;

    if (direction == "next") setSpreadByNumber.call(this, spread.number + 1);
    if (direction == "previous") setSpreadByNumber.call(this, spread.number - 1);
  } catch (error) {
    errors.warning(true, error, "~464");
  }
}
