/** @format */
import { collection, doc, getDocs, query, updateDoc, where, writeBatch } from "firebase/firestore";
import { db } from "firemade";
export function markSeen() {
  const { user } = this.props;
  return new Promise(async (resolve, reject) => {
    try {
      // Reference to the user's notifications
      const querySnapshot = await getDocs(query(collection(db, "notifications"), where("userId", "==", user.id)));

      // Batch update to mark all as seen
      const batch = writeBatch(db);

      querySnapshot.forEach((d) => {
        const docRef = doc(db, "notifications", d.id);
        batch.update(docRef, { seen: true });
      });

      await batch.commit();
      resolve(true);
    } catch (error) {
      this.onError({ message: error.message });
      reject(error);
    }
  });
}

export function markFollowed(notificationId) {
  return new Promise(async (resolve, reject) => {
    if (!notificationId) {
      return reject(new Error("No notification ID provided."));
    }

    try {
      const docRef = doc(db, "notifications", notificationId);
      await updateDoc(docRef, { followed: true });
      resolve(true);
    } catch (error) {
      this.onError({ message: error.message });
      reject(error);
    }
  });
}
