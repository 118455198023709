/** @format */

import { Editor } from "slate";

// -- Check if the document is empty --
export function isDocumentEmpty() {
  try {
    const editor = this.editors[this.state.spread.number];
    return (
      editor.children.length === 1 &&
      editor.children[0].type === "paragraph" &&
      editor.children[0].children.length === 1 &&
      editor.children[0].children[0].text === ""
    );
  } catch (_) {
    return true;
  }
}

// -- Use siblings to determine our location --

// Checks for the next node type
export function getSiblingType(sibling) {
  try {
    const editor = this.editors[this.state.spread.number];
    const { selection } = editor;

    if (!selection) {
      // console.log("No selection found");
      return;
    }

    // Get the current block type
    const [currentBlockEntry] = Editor.above(editor, {
      at: selection,
      match: (n) => Editor.isBlock(editor, n),
    });

    const currentBlockType = currentBlockEntry ? currentBlockEntry.type : null;
    // console.log("Current Block Type:", currentBlockType);

    // Get the previous block type
    const previousPath = Editor.before(editor, selection, { unit: "block" });
    const [previousBlockEntry] = previousPath
      ? Editor.above(editor, {
          at: previousPath,
          match: (n) => Editor.isBlock(editor, n),
        })
      : [undefined];

    const previousBlockType = previousBlockEntry ? previousBlockEntry.type : null;
    // console.log("Previous Block Type:", previousBlockType);

    // Get the next block type
    const nextPath = Editor.after(editor, selection, { unit: "block" });
    const [nextBlockEntry] = nextPath
      ? Editor.above(editor, {
          at: nextPath,
          match: (n) => Editor.isBlock(editor, n),
        })
      : [undefined];

    const nextBlockType = nextBlockEntry ? nextBlockEntry.type : null;
    // console.log("Next Block Type:", nextBlockType);

    const response = { previous: previousBlockType, current: currentBlockType, next: nextBlockType };

    // Return it all
    if (!sibling) return response;

    // Return the just one we wnt
    return response[sibling];
  } catch (_) {
    return { previous: null, current: null, next: null };
  }
}

// -- Get the location based on siblings --

// Check if we're at the start of the document
export function isFirst() {
  return getPreviousType.call(this) === null;
}

// Checks for the previous node type
export function getPreviousType(type) {
  try {
    // get the previous type
    const previousBlockType = getSiblingType.call(this).previous;

    // match or type
    return type ? type == previousBlockType : previousBlockType;
  } catch (_) {
    // match or null
    return type ? false : null;
  }
}

// Checks for the previous node type
export function getCurrentType(type) {
  try {
    // get the current block type
    const currentBlockType = getSiblingType.call(this).current;

    // return it
    return type ? type == currentBlockType : currentBlockType;
  } catch (_) {
    // match or null
    return type ? false : null;
  }
}

// Checks for the next node type
export function getNextType(type) {
  try {
    // get the next sibling type
    const nextBlockType = getSiblingType.call(this).next;

    // return it
    return type ? type == nextBlockType : nextBlockType;
  } catch (_) {
    // match or null
    return type ? false : null;
  }
}

// Check i we're at the start of the document
export function isLast() {
  return getNextType.call(this) === null;
}

// -- Get the path for new insertions --

export function getInsertionPath() {
  try {
    const editor = this.editors[this.state.spread.number];
    const { selection } = editor;

    if (!selection) return null;

    // Get the current line
    const [lineNode, linePath] = Editor.node(editor, selection.anchor.path);

    // Check if the line is a paragraph or another valid block element
    if (lineNode.type === "paragraph" /* or other valid types */) {
      return [...linePath, 0];
    } else {
      return selection.anchor.path;
    }
  } catch (e) {
    console.log(e, "~151");
    return null;
  }
}
