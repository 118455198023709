/** @format */

export function toggleDrawer(drawer) {
  let opened = this.opened();
  if (opened) {
    this.close();
    opened == "drawer" ? this.closeDrawer(drawer) : this.openDrawer(drawer);
  } else {
    this.openDrawer(drawer);
  }
}

export function closeDrawer(drawer) {
  let { drawers } = this.state.settings;
  if (drawers[drawer]) drawers[drawer] = false;
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, drawers: drawers },
    },
  });
}

export function openDrawer(drawer) {
  let { drawers } = this.state.settings;
  if (drawers[drawer]) drawers[drawer] = true;
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, drawers: drawers },
    },
  });
}

export function closeDrawers() {
  let { drawers } = this.state.settings;
  Object.keys(drawers).forEach((i) => (drawers[i] = false));
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, drawers: drawers },
    },
  });
}
