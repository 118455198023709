/** @format */
import axios from "axios";

/**
 * Fetches geolocation data based on the client's IP address.
 * @returns {Promise<Object>} A promise that resolves to the geolocation data.
 */
export default function geoml() {
  return new Promise((resolve, reject) => {
    // Fetch client's IP using axios and ipify
    axios.get("https://api.ipify.org?format=json").then((response) => {
      // Make Axios POST request and pass in the client IP
      return axios
        .post("/api/geo", { ip: response.data.ip })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
