/** @format */
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
  reauthenticateWithPopup,
} from "firebase/auth";
import { auth } from "firemade";

export default function reauthenticateWithProvider() {
  return new Promise(async (resolve, reject) => {
    if (!auth.currentUser) {
      return reject("auth/user-not-found");
    }

    // Get the auth provider id
    const providerId = auth.currentUser.providerData[0]?.providerId;

    // Make sure we have one
    if (!providerId) {
      return reject("auth/user-not-found");
    }

    // A place to store the authProvider
    let authProvider = null;

    // Assign the authProvider based on the providerId
    if (providerId === "google.com") authProvider = new GoogleAuthProvider();
    if (providerId === "facebook.com") authProvider = new FacebookAuthProvider();
    if (providerId === "twitter.com") authProvider = new TwitterAuthProvider();
    if (providerId === "apple.com") authProvider = new OAuthProvider("apple.com");

    // If we don't have an authProvider, reject
    if (!authProvider) {
      return reject("unexpectedError");
    }

    // Do the the reauth
    reauthenticateWithPopup(auth.currentUser, authProvider)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
