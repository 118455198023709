/** @format */
import { Box } from "@mui/material";
import Text from "@Web/Blocks/Text";

const Time = (props) => {
  return (
    <Box style={{ textAlign: "center", padding: "1% 10% 5% 10%" }}>
      <Text {...props} number={1} />
    </Box>
  );
};

export default Time;
