/** @format */
import { lazy } from "react";
import Reporter from "./components/Reporter";
import Capture, { useCapture, withCapture } from "./context";

const CaptureControls = lazy(() => import("./components/Controls"));
const CapturePlugin = lazy(() => import("./components/Plugin"));

export default Capture;
export { CaptureControls, CapturePlugin, Reporter, useCapture, withCapture };
