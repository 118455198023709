/** @format */
import { useEffect } from "react";
import { useRouter } from "router";

// This is an empty file, only in the event
// we want to add something here later.
// It's a template file.
export default function Complete({ projectId }) {
  // use router rather than cms because cms looks for pages (workspace isn't page)
  const { navigate } = useRouter();
  useEffect(() => {
    if (projectId) {
      navigate("/en-us/app/workspace/" + projectId);
    }
  }, [projectId]);
}
