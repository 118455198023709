/** @format */
import { Box, Grid, IconButton, MenuList, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import DynamicIcon from "dynamicicon";
import { Component as ReactComponent } from "react";
import MediaGrid from "../../context";
import ActionsBar from "../ActionsBar";

const StyledBox = styled(Box)(() => ({
  "& .media": {
    borderRadius: "3px",
  },
}));

export default class CapturePlugin extends ReactComponent {
  componentDidMount() {
    if (this.props.capture.queued.length > 0) this.continueWithMedia();
  }

  render() {
    const { disabled = false } = this.props;
    return (
      <StyledBox>
        <MenuList style={{ padding: "5px" }}>
          <Grid container spacing={1}>
            {MediaGrid(this.props.capture.queued).map((media, i) => (
              <Grid item={"capture-media-" + media.id} sm={media.cols}>
                <div className="relative">
                  {media.type == "video" ? (
                    <video
                      src={media.url}
                      controls="false"
                      autoPlay
                      loop
                      style={{ maxWidth: "100%" }}
                      className="media"
                    />
                  ) : (
                    <Image
                      errorIcon={<DynamicIcon icon={"fa-x-mark"} />}
                      src={media.url}
                      className="media"
                      disableSpinner
                      alt={media.title}
                      cover={true}
                      aspectRatio={1.5}
                    />
                  )}
                  <ActionsBar>
                    <Tooltip title="Delete Media">
                      <IconButton
                        style={{
                          color: "#FFFFFF",
                        }}
                        size="small"
                        disabled={disabled}
                        color="primary"
                        sx={{
                          fontSize: 10,
                        }}
                        onClick={() => this.props.capture.delete(media.id)}
                      >
                        <DynamicIcon icon="fa-trash" />
                      </IconButton>
                    </Tooltip>
                  </ActionsBar>
                </div>
              </Grid>
            ))}
          </Grid>
        </MenuList>
      </StyledBox>
    );
  }
}
