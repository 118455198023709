/** @format */

export function is(type = "updating") {
  return this.state[type];
}

export function isPerforming(payload = null) {
  if (payload !== null) return is.call(this, payload) === true;
  return (
    isLoading.call(this, "loading") === true ||
    isUpdating.call(this, "updating") === true ||
    isWorking.call(this, "working") === true
  );
}

export function isLoading(payload = true) {
  return is.call(this, "loading", payload);
}

export function isWorking(payload = true) {
  return is.call(this, "working", payload);
}

export function isUpdating(type = null) {
  if (type === null) return this.state.updating != null;
  return this.state.updating == type;
}
