/** @format */
import { Avatar } from "@mui/material";

import { TinyError } from "errors";
export default function (props) {
  return (
    <TinyError>
      <Image {...props} />
    </TinyError>
  );
}

export function Image(props) {
  if (!props?.author) return null;

  const { image, name } = props.author;

  return (
    <Avatar src={image} alt={name} variant="rounded" sx={{ width: props?.width || 56, height: props?.height || 56 }} />
  );
}
