/** @format */
import { teardownKeypress } from "./Keypress";
import { teardownPaste } from "./Paste";

function teardown() {
  const { workspace } = this.props;

  this.reset();

  // remove the keypress listern
  teardownKeypress(this, "~11");

  // remove the paste listener
  teardownPaste(this, "~14");

  // remove the resize listener
  window.removeEventListener("resize", this.debouncedResize);

  // Close the boardss
  workspace.board.close("media");

  // Remove the syncing interval
  if (this.syncInterval) {
    clearInterval(this.syncInterval);
    this.syncInterval = null;
  }
  return null;
}

export { teardown };
