/** @format */
import { Box, Button, Link } from "@mui/material";
import { useCms } from "cms";
import { useLocales } from "locales";
import Action from "../../../../libraries/Action";

import { TinyError } from "errors";

export default function (props) {
  return (
    <TinyError>
      <TextAction {...props} />
    </TinyError>
  );
}

export function TextAction(props) {
  const { action } = props;
  const { t } = useLocales();

  // this is really gross, but it's a good stopgap
  const description = props?.text?.props?.children || props.text[0]?.props?.children || t("more");

  let navigate = useCms();
  return (
    <>
      {action && action.enabled && (
        <Box
          sx={{
            textAlign: {
              xs: "center",
              sm: "center",
              md: action.type === "Button" ? "right" : "center",
            },
          }}
          data-testid="TestAction"
        >
          {action.type == "Button" && (
            <Button
              color={action.color.toLowerCase() || "success"}
              variant="contained"
              size="large"
              onClick={() => Action(navigate, action.action)}
            >
              {action.text}
            </Button>
          )}
          {action.type == "Link" && (
            <Link onClick={() => Action(navigate, action.action)} href={action.action} aria-label={description}>
              {action.text}
            </Link>
          )}
        </Box>
      )}
    </>
  );
}
