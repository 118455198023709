/** @format */

export function openAlert(payload) {
  if (this.opened("alert")) return;
  let { dialogs } = this.state.settings;
  dialogs.alert = payload;
  dialogs.alert.open = true;
  dialogs.confirm = confirm;
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, dialogs: dialogs },
    },
  });
}

export function closeAlert() {
  if (!this.opened("alert")) return;
  let { dialogs } = this.state.settings;
  dialogs.alert.open = false;
  this.setState({
    ...this.state,
    ...{
      settings: { ...this.state.settings, dialogs: dialogs },
    },
  });
}
