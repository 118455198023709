/** @format */
import { Box, Button, ClickAwayListener, Grow, Popper, styled } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { TinyError } from "errors";
import { useLocales } from "locales";
import modifier from "modifier";
import { useEffect, useRef } from "react";
import { useUI } from "ui";
import Window from "./components/Window";

const StyledBox = styled(Box)(({ top, bottom, left, right }) => ({
  "&": {
    position: "absolute",
    top: top || "auto",
    left: left || "auto",
    bottom: bottom || "auto",
    right: right || "auto",
    zIndex: 2000,
    width: "60px",
  },
}));

/**
 * Renders a component that controls the UI and displays a chat window.
 * @returns {JSX.Element} The rendered component.
 */
export default function () {
  // Control the ui
  const { menu, opened } = useUI();

  // Get the languages
  const { t } = useLocales();

  // Create a ref for the popper
  const tagRef = useRef(null);

  // Modify the popper
  const modifiers = modifier(-10, 20);

  // Render the component
  useEffect(() => {
    // Open the chat window
    setTimeout(() => {
      menu.open("chat/window");
    }, 500);
  }, []);

  return (
    <TinyError>
      <StyledBox right={400} bottom={20}>
        <Button variant="tab" onClick={() => menu.open("chat/window")} ref={tagRef}>
          <DynamicIcon icon={"fa-message-lines"} className="icon" />
          <label className="label">{t("chat")}</label>
        </Button>
      </StyledBox>
      <Popper
        open={opened("chat/window")}
        anchorEl={tagRef.current}
        placement="top-end"
        modifiers={modifiers}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => menu.close("chat/window")}>
            <Grow {...TransitionProps}>
              <Box>
                <Window />
              </Box>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </TinyError>
  );
}
