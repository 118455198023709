/** @format */
import { Box, Button } from "@mui/material";
import { useEffect } from "react";

import { useLocales } from "locales";
import { usePerforming } from "performing";
import { useUser } from "user";

export default function Resume(props) {
  const { set } = usePerforming();
  const { t, url } = useLocales();
  const { authentication } = useUser();
  useEffect(() => {
    set.loading(false, "~14");
  }, []);
  return (
    <Box>
      <Box>{t("duplicatedProject")}</Box>
      <Box sx={{ marginTop: "100px", textAlign: "right" }}>
        <Button
          variant="primary"
          size="large"
          onClick={() => {
            authentication.signOut();
          }}
        >
          {t("signout")}
        </Button>
        <Button
          variant="contained"
          color="success"
          size="large"
          sx={{ marginLeft: "20px" }}
          onClick={() => (document.location.href = url("app/workspace/" + props.projectId))}
        >
          {t("continue")}
        </Button>
      </Box>
    </Box>
  );
}
