/** @format */

import { collection, query as fQuery, getDocs, where } from "firebase/firestore";

/* Queries */

function hasQueries() {
  return Object.keys(this.state.queries).length || false;
}
async function getMedia(id) {
  if (!id) return null;

  // Iterate through each key (query) in the queries object
  for (const query in this.state.queries) {
    // Check if the current query has a matching id
    const mediaArray = this.state.queries[query];
    const matchedMedia = mediaArray.find((media) => media.id === id);

    // If a match is found, return the matched media object
    if (matchedMedia) {
      return matchedMedia;
    }
  }

  // If not found in queries, search for it in the DB
  try {
    // Query the media from the database using a where clause
    const queryInstance = fQuery(this.connection, where("id", "==", id));
    const querySnapshot = await getDocs(queryInstance);

    // Check if any documents were found
    if (!querySnapshot.empty) {
      const media = querySnapshot.docs[0].data();
      const { collections } = media;

      // Find a query that matches based on collection then tags, and add the media to it
      if (collections && collections.some((collection) => media.collections.includes(collection))) {
        try {
          this.setState((prevState) => ({
            queries: {
              ...prevState.queries,
              [collection]: [...(prevState.queries[collection] || []), media],
            },
          }));
        } catch (_) {}
        return media;
      } else if (tags && tags.some((tag) => media.tags.includes(tag))) {
        try {
          this.setState((prevState) => ({
            queries: {
              ...prevState.queries,
              [tag]: [...(prevState.queries[tag] || []), media],
            },
          }));
        } catch (_) {}
        return media;
      } else {
        return media;
      }
    }
  } catch (_) {
    console.error(_, "~73");
  }

  // Return null if no match is found in queries or DB
  return null;
}

export { getMedia, hasQueries };
