/** @format */

/**
 * Retrieves the selected elements from the component's state.
 *
 * @returns {Array} The selected elements. If an error occurs, returns an empty array.
 */
export function getSelected() {
  try {
    return this.state.elements;
  } catch (_) {
    return [];
  }
}

/**
 * Retrieves the selected data elements and maps them to an array of objects containing
 * their type, id, index, and frame attributes.
 *
 * @returns {Array<Object>} An array of objects representing the selected elements.
 * Each object contains the following properties:
 * - {string} type - The type of the element.
 * - {string} id - The ID of the element.
 * - {number} index - The index of the element, parsed as an integer.
 * - {string} frame - The frame attribute of the element, or the current frame number from the state if not present.
 */
export function getSelectedData() {
  try {
    return getSelected.call(this).map((element) => ({
      type: element.getAttribute("data-type"),
      id: element.getAttribute("data-id"),
      index: parseInt(element.getAttribute("data-index"), 10),
      frame: element.getAttribute("data-frame") || this.state.frame.number,
    }));
  } catch (_) {
    return [];
  }
}

/**
 * Retrieves the selected element based on the provided response type.
 *
 * @param {string} [respond="data"] - The type of response to determine the selection method.
 *                                     If "data", it calls `getSelectedData`. Otherwise, it calls `getSelected`.
 * @returns {Object|null} - The first selected element if any, otherwise null.
 */
export function getSelectedElement(respond = "data") {
  try {
    let selected;
    if (respond == "data") {
      selected = getSelectedData.call(this);
    } else {
      selected = getSelected(this);
    }
    return selected.length > 0 ? selected[0] : null;
  } catch (_) {
    return null;
  }
}

/**
 * Retrieves the selected index of a layer.
 *
 * @param {number} [layer] - The layer to compare with the selected index.
 * @returns {number|null} - The selected index if no layer is provided,
 *                          true if the layer matches the selected index,
 *                          false if it does not match,
 *                          or null if an error occurs.
 */
export function getSelectedIndex(layer) {
  try {
    const { index } = this.element.selected();
    return layer !== undefined ? layer == index : index;
  } catch (_) {
    return null;
  }
}

/**
 * Retrieves the selected elements based on the provided response type.
 *
 * @param {string} [respond="data"] - The type of response to retrieve.
 *                                     If "data", it calls `getSelectedData`.
 *                                     Otherwise, it calls `getSelected`.
 * @returns {Array} The selected elements. Returns an empty array if an error occurs.
 */
export function getSelectedElements(respond = "data") {
  try {
    let selected;
    if (respond == "data") {
      selected = getSelectedData.call(this);
    } else {
      selected = getSelected.call(this);
    }
    return selected;
  } catch (_) {
    return [];
  }
}

/**
 * Checks if there are any selected items.
 *
 * @returns {boolean} True if there are selected items, false otherwise.
 */
export function hasSelected() {
  try {
    return getSelected.call(this).length > 0;
  } catch (e) {
    return false;
  }
}

/**
 * Retrieves the type of the selected element.
 *
 * @param {string|null} [type=null] - The type to check against the selected element's type.
 * @returns {string|boolean|null} - Returns the type of the selected element if no type argument is passed.
 *                                  Returns `false` if the selected element's type does not match the provided type.
 *                                  Returns `null` if no element is selected and no type argument is passed.
 *                                  Returns `false` if an error occurs and a type argument is passed.
 */
export function getSelectedElementType(type = null) {
  // This is pretty hacky
  const group = (type) => {
    if (["text", "copyright"].includes(type)) return "text";
    return type;
  };
  try {
    // get the selected data
    const selected = getSelectedData.call(this);

    // if nothing is selected, return false if we've passed an argument, otherwise null
    if (selected.length != 1) return type ? false : null;

    // We have a selected element, so return the type if we've passed an argument, otherwise the type or null
    return type ? group(selected[0].type) == type : selected[0].type || null;
  } catch (_) {
    // if there's an error, return false if we've passed an argument, otherwise null
    return type ? false : null;
  }
}

/**
 * Deselects all elements and updates the state with an empty elements array and the current timestamp.
 * Closes the menu controls.
 * If an error occurs, it sets the performing state to "error" with a specific code.
 *
 * @function
 * @name deslectElements
 * @throws Will set the performing state to "error" with code "~197" if an error occurs.
 */
export function deslectElements() {
  const { performing } = this.props;
  try {
    this.setState({ elements: [], revised: Date.now() });

    this.controls.menu.close();
  } catch (error) {
    performing.set.updating("error", "~197");
  }
}

/**
 * Refreshes the elements by clearing the current elements and then re-selecting them based on the selected element's data-id.
 *
 * @function
 * @name refreshElements
 * @memberof context.helpers.Edit
 * @this {Object} - The context in which the function is called, expected to have `props` and `element` properties.
 * @throws Will catch and handle any errors that occur during the process.
 */
export function refreshElements() {
  const { performing } = this.props;
  try {
    const id = this.element.selected("data")?.id;
    if (id) {
      this.setState({ elements: [], revised: Date.now() }, () => {
        const elements = document.querySelectorAll(`[data-id="${id}"]`);
        this.setState({ elements: [...elements], revised: Date.now() });
      });
    }
  } catch (_) {
    performing.set.updating("error", "~218");
  }
}

/**
 * Retrieves the attributes of the selected element in the workspace.
 *
 * @param {string} [type="array"] - The format in which to return the attributes.
 *                                   If "array", returns an array of attributes.
 *                                   Otherwise, returns an object of attributes.
 * @returns {Array|Object|boolean} - Returns an array or object of attributes based on the type parameter.
 *                                   Returns false if an error occurs.
 *
 * @property {Object} this.props.workspace - The workspace object containing the feature.
 * @property {Object} this.state - The state object containing spread and frame information.
 * @property {Object} this.element - The element object containing selected element information.
 */
export function getSelectedElementAttributes(type = "array") {
  try {
    const { workspace } = this.props;
    const feature = workspace.feature.selected();
    const spread = this.state?.spread?.number || 0;
    const element = this.element.selected();
    const frame = this.state.frame.number || 0;
    if (type == "array") {
      return [feature, spread, element.index, element.id, frame, element.type];
    } else {
      return { feature: feature, spread, id: element.id, index: element.index, frame, type: element.type };
    }
  } catch (_) {
    return false;
  }
}

/**
 * Helper function to find an element by its id in the current state or data structure.
 * This is a placeholder and should be implemented according to your data structure.
 *
 * @param {string} id - The id of the element to find.
 * @returns {Object|null} The found element or null if not found.
 */
function findElementById(id) {
  // Use querySelector to select the element by data-text-id
  return document.querySelector(`[data-id="${id}"]`);
}

/**
 * Sets the selected element by updating the component's state.
 * This function no longer directly manipulates the DOM.
 *
 * @param {Object} params - The parameters for setting the selected element.
 * @param {string} [params.id] - The id of the element to be selected.
 * @param {Object} [params.element] - The element object to be selected.
 */
export function setSelectedElement({ id, element }) {
  try {
    let selectedElement;

    if (element) {
      selectedElement = element;
    } else if (id) {
      // Find the element in the current state or data structure
      selectedElement = findElementById.call(this, id);
    }

    if (!selectedElement) return;

    // Set the selected element
    this.setState({ elements: [selectedElement], revised: Date.now() });
  } catch (error) {
    if (this.props.performing && this.props.performing.set.updating) {
      this.props.performing.set.updating("error", "setSelectedElement");
    }
  }
}

/**
 * Handles the selection of elements based on the event and whether the shift key is pressed.
 *
 * @param {Object} event - The event object containing the selected and added elements.
 * @param {Array} event.selected - The array of currently selected elements.
 * @param {Array} event.added - The array of newly added elements when shift is pressed.
 * @param {boolean} [shiftPressed=false] - Indicates if the shift key is pressed during selection.
 * @throws Will throw an error if the selection process fails.
 */
export function setSelectedElements(event, shiftPressed = false) {
  const { performing } = this.props;

  if (this.state.controls) return;

  try {
    let newElements;

    if (shiftPressed) {
      // Create a Set from existing elements for uniqueness
      const currentElements = new Set(this.state.elements.map((el) => el.dataset.id));

      // Add newly selected elements to the set
      event.added.forEach((el) => currentElements.add(el.dataset.id));

      newElements = Array.from(currentElements)
        .map((id) => document.querySelector(`[data-id='${id}']`))
        .filter(Boolean);
    } else {
      // Replace the array if shift is not pressed
      newElements = event.selected;
    }

    // Deselect if nothing is selected
    if (event.selected.length === 0) {
      newElements = [];
    }

    // Check for changes in selection and update state
    if (
      newElements.length !== this.state.elements.length ||
      !newElements.every((el, index) => el.dataset.id === this.state.elements[index].dataset.id)
    ) {
      this.setState({ elements: newElements, revised: Date.now() });
    }

    this.controls.menu.close();
  } catch (_) {
    performing.set.updating("error", "~180");
  }
}
