/** @format */
import { Suspense, lazy } from "react";
import { Listener } from "router";

/* Company */

// import Home from "../components/Home";
const Home = lazy(() => import("../components/Home"));
const About = lazy(() => import("../components/Company/components/About"));
const Partners = lazy(() => import("../components/Company/components/Partners"));
const ContactPage = lazy(() => import("../components/Company/components/Contact"));
const Customers = lazy(() => import("../components/Company/components/Customers"));
const CompanyTerms = lazy(() => import("../components/Company/components/Terms"));
const CompanyPrivacy = lazy(() => import("../components/Company/components/Privacy"));
const CompanyCookies = lazy(() => import("../components/Company/components/Cookies"));

const CareerListing = lazy(() => import("../components/Company/components/Careers/components/Main"));

/* Glossary */
const Glossary = lazy(() => import("../components/Glossary"));

/* Reject Cookies */
const Reject = lazy(() => import("../components/Reject"));

/* Product */
const Features = lazy(() => import("../components/Product/components/Features"));
const Pricing = lazy(() => import("../components/Product/components/Pricing"));
const TrimGuide = lazy(() => import("../components/Product/components/TrimGuide"));

/* KB */

const KBMain = lazy(() => import("../components/KB/components/KBMain"));
const KBArticle = lazy(() => import("../components/KB/components/KBArticle"));

/* Community */

const Community = lazy(() => import("../components/Community/components"));

/* Profiles */

const Profiles = lazy(() => import("../components/Profiles/components"));

/* Help */

const HelpMain = lazy(() => import("../components/Help/components/HelpMain"));
const HelpArticle = lazy(() => import("../components/Help/components/HelpArticle"));

/* Blog */
const BlogNewsroom = lazy(() => import("../components/Blog/components/Newsroom"));
const BlogArticle = lazy(() => import("../components/Blog/components/Article"));

/* RN */
const RN = lazy(() => import("../components/RN"));

/* Press */
const Press = lazy(() => import("../components/Press"));

/* FAQ */

const Faq = lazy(() => import("../components/Faq"));

/* Onboarding  */

const Onboarding = lazy(() => import("../components/Onboarding"));

/* Coming Soon */

const ComingSoon = lazy(() => import("../components/ComingSoon"));

/* Errors */
const NotFoundCover = lazy(() => import("../components/Errors/components/NotFoundCover"));

/* Application */

const Application = lazy(() => import("../components/Application"));

/* Monitor */

const Status = lazy(() => import("../components/Status"));

/* Sitemap */

const Sitemap = lazy(() => import("../components/Sitemap"));

/* HumanMade */

const HumanMade = lazy(() => import("../components/ComingSoon"));

const Container = ({ children }) => (
  <Suspense fallback={null}>
    {children}
    <Listener />
  </Suspense>
);

const routes = [
  /* Home */
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "pages/home",
    element: (
      <Container>
        <Home />
      </Container>
    ),
  },
  /* Community */
  {
    path: "discussions",
    element: (
      <Container>
        <Community />
      </Container>
    ),
  },
  {
    path: "discussions/:category",
    element: (
      <Container>
        <Community />
      </Container>
    ),
  },
  {
    path: "community/:category/:thread",
    element: (
      <Container>
        <Community />
      </Container>
    ),
  },
  /* Blog */
  {
    path: "pages/blog",
    element: (
      <Container>
        <BlogNewsroom />
      </Container>
    ),
  },
  {
    path: "blog/:slug",
    element: (
      <Container>
        <BlogArticle />
      </Container>
    ),
  },
  {
    path: "blog/:slug/:messageId",
    element: (
      <Container>
        <BlogArticle />
      </Container>
    ),
  },
  /* Release Notes */
  {
    path: "pages/rn",
    element: (
      <Container>
        <RN />
      </Container>
    ),
  },
  {
    path: "rn/:release",
    element: (
      <Container>
        <NotFoundCover />
      </Container>
    ),
  },
  /* Press */
  {
    path: "pages/press",
    element: (
      <Container>
        <Press />
      </Container>
    ),
  },
  /* Company */
  {
    path: "pages/company/about",
    element: (
      <Container>
        <About />
      </Container>
    ),
  },
  {
    path: "pages/company/terms",
    element: (
      <Container>
        <CompanyTerms />
      </Container>
    ),
  },
  {
    path: "pages/company/privacy",
    element: (
      <Container>
        <CompanyPrivacy />
      </Container>
    ),
  },
  {
    path: "pages/company/cookies",
    element: (
      <Container>
        <CompanyCookies />
      </Container>
    ),
  },
  {
    path: "pages/company/contact",
    element: (
      <Container>
        <ContactPage />
      </Container>
    ),
  },
  {
    path: "pages/company/careers",
    element: (
      <Container>
        <CareerListing />
      </Container>
    ),
  },
  {
    path: "pages/company/partners",
    element: (
      <Container>
        <Partners />
      </Container>
    ),
  },
  {
    path: "pages/customers",
    element: (
      <Container>
        <Customers />
      </Container>
    ),
  },

  /* FAQ */

  {
    path: "pages/faq",
    element: (
      <Container>
        <Faq />
      </Container>
    ),
  },

  /* KB */
  {
    path: "pages/kb",
    element: (
      <Container>
        <KBMain />
      </Container>
    ),
  },
  {
    path: "kb/:slug1",
    element: (
      <Container>
        <KBArticle />
      </Container>
    ),
    children: [
      {
        path: ":slug2",
        element: (
          <Container>
            <NotFoundCover />
          </Container>
        ),
        children: [
          {
            path: ":slug3",
            element: (
              <Container>
                <NotFoundCover />
              </Container>
            ),
          },
        ],
      },
    ],
  },

  /* Help */
  {
    path: "pages/help",
    element: (
      <Container>
        <HelpMain />
      </Container>
    ),
  },
  {
    path: "help/:slug1/:slug2",
    element: (
      <Container>
        <HelpArticle />
      </Container>
    ),
    children: [
      {
        path: ":slug3",
        element: (
          <Container>
            <HelpArticle />
          </Container>
        ),
      },
    ],
  },

  /* Profiles */
  {
    path: "people",
    element: (
      <Container>
        <Profiles />
      </Container>
    ),
  },
  {
    path: "people/:profileId",
    element: (
      <Container>
        <Profiles />
      </Container>
    ),
  },

  /* Glossary */
  {
    path: "pages/glossary",
    element: (
      <Container>
        <Glossary />
      </Container>
    ),
  },

  /* Product */

  {
    path: "pages/product/features",
    element: (
      <Container>
        <Features />
      </Container>
    ),
  },
  {
    path: "pages/product/pricing",
    element: (
      <Container>
        <Pricing />
      </Container>
    ),
  },
  {
    path: "pages/product/trim-guide",
    element: (
      <Container>
        <TrimGuide />
      </Container>
    ),
  },

  {
    path: "pages/onboarding",
    element: (
      <Container>
        <Onboarding />
      </Container>
    ),
    children: [
      {
        path: ":version",
        element: (
          <Container>
            <Onboarding />
          </Container>
        ),
        children: [
          {
            path: ":opt",
            element: (
              <Container>
                <Onboarding />
              </Container>
            ),
            children: [
              {
                path: ":trial",
                element: (
                  <Container>
                    <Onboarding />
                  </Container>
                ),
              },
            ],
          },
        ],
      },
    ],
  },

  /* HumanMade */
  {
    path: "pages/coming-soon",
    element: (
      <Container>
        <HumanMade />
      </Container>
    ),
  },

  /* Status */
  {
    path: "pages/status",
    element: (
      <Container>
        <Status />
      </Container>
    ),
  },

  /* Sitemap */
  {
    path: "pages/sitemap",
    element: (
      <Container>
        <Sitemap />
      </Container>
    ),
  },

  /* Coming Soon */
  {
    path: "pages/coming-soon",
    element: (
      <Container>
        <ComingSoon />
      </Container>
    ),
  },

  /* Application */
  {
    path: "pages/application",
    element: (
      <Container>
        <Application header={false} footer={false} />
      </Container>
    ),
  },

  /* Reject Cookies */
  {
    path: "pages/reject-agreement",
    element: (
      <Container>
        <Reject />
      </Container>
    ),
  },

  /* Not founds */
  {
    path: "pages/not-found",
    element: (
      <Container>
        <NotFoundCover />
      </Container>
    ),
  },
  {
    path: "*",
    element: (
      <Container>
        <NotFoundCover />
      </Container>
    ),
  },
];

export default routes;
