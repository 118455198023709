/** @format */
import Container from "@Web/Container";
import { Box, Grid } from "@mui/material";

import RightSide from "./components/LeftSide";
import LeftSide from "./components/RightSide";

const Features = (props) => {
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item container alignItems={"center"} xs={12} md={6}>
          <Box>
            <LeftSide {...props} />
          </Box>
        </Grid>
        <Grid item container alignItems={"center"} xs={12} md={6}>
          <RightSide {...props} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Features;
