/** @format */
import SmartButtons from "@Workspace/Components/SmartButtons";
import { withPerforming } from "performing";
import { Component as ReactComponent, createContext, useContext } from "react";
import { withUI } from "ui";

// Setup the simply
import { setupButtons, setupFeature } from "./helpers/Setup";

// Wait for certain things to be ready
import { getReady, setReady } from "./helpers/Ready";

// Updating components (if needs, not used at all ATM)
import { updateSimple } from "./helpers/Update";

// Create the context provider for a simple desk
const SimpleContext = createContext({});

class Simple extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new(this.constructor.name);

    this.state = {
      ready: [],
      config: {
        buttons: () => {
          return {};
        },
      },
    };

    // Update
    this.simple = {
      update: updateSimple.bind(this),
    };

    // Setup
    this.setup = {
      buttons: setupButtons.bind(this),
      feature: setupFeature.bind(this),
    };

    // Ready
    this.ready = {
      get: getReady.bind(this),
      set: setReady.bind(this),
    };
  }

  // HIde the loading

  componentDidMount = () => {
    this.unit.report({
      method: "componentDidMount",
      steps: [],
      test: "Simple usable component such as planning, should be accessible and work without issue.",
    });
    this.props.performing.set.loading(false, "~60");
  };

  render() {
    return (
      <SimpleContext.Provider
        {...this.props}
        value={{
          ...this.state,
          ...{
            data: this.props.data,
            simple: this.simple,
            setup: this.setup,
            ready: this.ready,
          },
          buttons: SmartButtons(this.state.config.buttons, this),
        }}
      >
        <>{this.props.children}</>
      </SimpleContext.Provider>
    );
  }
}

const withSimple = (Component) => {
  return function ContextualComponent(props) {
    return <SimpleContext.Consumer>{(state) => <Component {...props} simple={state} />}</SimpleContext.Consumer>;
  };
};

const useSimple = () => {
  const context = useContext(SimpleContext);
  return context;
};

export default withPerforming(withUI(Simple));
export { useSimple, withSimple };
