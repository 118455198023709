/** @format */
import { List, ListItem, ListItemText } from "@mui/material";
import { useCms } from "cms";
import parse from "html-react-parser";
import key from "key";
import { useMemo } from "react";

import { TinyError } from "errors";

import TextAction from "../TextAction";
import TextIcon from "../TextIcon";

export default function (props) {
  return (
    <TinyError>
      <TextList {...props} />
    </TinyError>
  );
}

export function TextList(props) {
  const {
    page: { blocks },
  } = useCms();

  // Memoize the calculation of Text and tmp based on props.number
  const { TextList } = useMemo(() => {
    if (!blocks?.length) return { TextList: null };
    const TextList = blocks.filter((block) => block.type == "TextList")[props.number - 1];

    return { TextList };
  }, [props.number, blocks]);

  // Early return if there are no articles
  if (!TextList?.text) return null;

  return (
    <List disablePadding dense sx={props.sx} role="article">
      {TextList.text.map((text, index) => (
        <ListItem key={index} disableGutters {...key("cms", "TextList", props.number, index)}>
          <TextIcon {...text} />
          <ListItemText primary={parse(text.text)} style={{ margin: "10px" }} />
          <TextAction {...text} />
        </ListItem>
      ))}
    </List>
  );
}
