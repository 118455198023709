/** @format */

import { Functional } from "unit";

const unit = Functional.unit("designer/do");

// Setup undos

export function setupUndos(from = "~9") {
  unit.report({
    method: "setupUndos",
    message: "Setting up undos.",
    from: from,
    tests: "Undo should be created.",
    from: from,
  });
  return new Promise((resolve, reject) => {
    try {
      let dos = [];

      const data = this.data("23");

      data.spreads.forEach((data, spread) => {
        if (!dos[spread]) dos[spread] = { number: 0, data: [data] };
      });

      this.setState({ dos: dos }, () => resolve(true));
    } catch (error) {
      const { errors, t } = this.props;
      errors.error(t("unexpectedError"), error, "~30");
      reject(error);
    }
  });
}

// Create

export function createUndo(from = "~38") {
  try {
    unit.report({
      method: "createUndo",
      message: "Adding undo record.",
      from: from,
      tests: "Undo should be created.",
    });

    const spread = this.state.spread.number || 0;
    const data = this.data("~48");

    let { dos } = this.state;

    if (!dos[spread]) dos[spread] = { number: 0, data: [] };

    // If maximum number of undos is reached, remove the oldest
    if (dos[spread].data.length >= 100) {
      dos[spread].data.shift();
    }

    // Remove all redo records in advance
    dos[spread].data = dos[spread].data.slice(0, dos[spread].number + 1);

    // Add the new state
    dos[spread].data.push(data.spreads[spread]);

    // Move the pointer to the last index
    dos[spread].number = dos[spread].data.length - 1;

    this.setState({ dos: dos });
  } catch (e) {}
}

// Check if available

export function canDo(type, from = "~74") {
  unit.report({
    method: "Redoiing.",
    message: "Changing history",
    test: "Redoing something should be reflected immediately locally/remotely.",
    from: from,
  });

  try {
    let { dos } = this.state;

    if (dos.length == 0) return false;

    const spread = this.state.spread.number;

    if (type === "undo") {
      return dos[spread].number > 0;
    } else if (type === "redo") {
      return dos[spread].number < dos[spread].data.length - 1;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

// Action

export function dos(type, from = "~103") {
  unit.report({
    method: "do",
    message: "Doing.",
    from: from,
    payload: {
      which: type,
    },
    test: "An undo or redo operation should operate properly.",
  });

  try {
    const spread = this.state.spread.number;

    const newNumber = this.state.dos[spread].number + (type == "undo" ? -1 : 1);
    if (newNumber >= 0 && newNumber < this.state.dos[spread]?.data.length) {
      const restoreFrom = this.state.dos[spread].data[newNumber];
      const newProjectState = this.data("~120");

      newProjectState.spreads[spread] = restoreFrom;

      this.setState(
        {
          data: newProjectState,
          dos: {
            ...this.state.dos,
            [spread]: {
              ...this.state.dos[spread],
              number: newNumber,
            },
          },
        },
        () => {
          this.elements.refresh();
          // this.editor.refresh("~137");
        }
      );
    }
  } catch (_) {}
}

export function undo() {
  unit.report({
    method: "undo",
    message: "Undoing.",
    from: "Click",
    analyze: true,
    action: true,
    test: "Undoing something should be reflected immediately locally/remotely.",
  });
  dos.call(this, "undo");
}

export function redo() {
  unit.report({
    method: "Redoing.",
    message: "Changing history",
    from: "Click",
    analyze: true,
    action: true,
    test: "Redoing something should be reflected immediately locally/remotely.",
  });
  dos.call(this, "redo");
}

// Reorder undos (works in tandem with re-order spreads - artifact)
export function orderUndos(start, end, from = "~169") {
  try {
    unit.report({
      method: "orderUndo",
      message: "Reordering undo records.",
      from: from,
      tests: "Undo records should be reordered.",
    });

    let { dos } = this.state;

    // Swap undos using destructuring assignment
    [dos[spread].data[start], dos[spread].data[end]] = [dos[spread].data[end], dos[spread].data[start]];

    // Update the state
    this.setState({ dos: dos });
  } catch (e) {
    console.error("Failed to reorder undos", e);
  }
}
