/** @format */
import { Box } from "@mui/material";
import Cdn from "cdn";

const mock = [
  "https://us-central.cdn.made.live/thumbnail_kast_transparency_53ce4c42e2/thumbnail_kast_transparency_53ce4c42e2.png?width=1400&height=486",
  "https://us-central.cdn.made.live/Lawson_Lundell_b6c4d928ef/Lawson_Lundell_b6c4d928ef.svg?width=612&height=176",
  "https://us-central.cdn.made.live/thumbnail_Footer_nrc_irap_12497b04fb/thumbnail_Footer_nrc_irap_12497b04fb.png?width=1015&height=160",
  "https://us-central.cdn.made.live/thumbnail_195553067_733dbbccf5/thumbnail_195553067_733dbbccf5.png?width=1060&height=264",
  "https://us-central.cdn.made.live/thumbnail_2018_AO_Logo_horizontal_1bc3c7ce83/thumbnail_2018_AO_Logo_horizontal_1bc3c7ce83.png?width=1200&height=537",
  "https://us-central.cdn.made.live/thumbnail_BDO_logo_svg_870d8d3fb5/thumbnail_BDO_logo_svg_870d8d3fb5.png?width=1200&height=470",
  "https://us-central.cdn.made.live/thumbnail_cropped_spring_logo_1_319db5e22a/thumbnail_cropped_spring_logo_1_319db5e22a.png?width=2140&height=1762",
  "https://us-central.cdn.made.live/thumbnail_Volition_transparent_logo_da14eecc42/thumbnail_Volition_transparent_logo_da14eecc42.png?width=1000&height=218",
];

const Partners = () => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent={"center"}>
      {mock.map((item, i) => (
        <Box
          maxWidth={{ xs: 60, sm: 60, md: 120, lg: 120 }}
          marginTop={{ xs: 1 }}
          marginRight={{ xs: 3, sm: 6, md: 12 }}
          key={i}
        >
          <Box
            component="img"
            loading="lazy"
            height={1}
            width={1}
            src={Cdn(item)}
            alt="Made Live Partner Logo"
            sx={{
              objectFit: "contain", // Maintain aspect ratio
              filter: "contrast(0)",
              padding: "10%",
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Partners;
