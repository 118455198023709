/** @format */
import { Box, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { useChat } from "chat";
import DynamicIcon from "dynamicicon";
import isHotkey from "is-hotkey";
import Paste from "paste";
import { Working, usePerforming } from "performing";
import { useState } from "react";

import Attachments from "./components/Attachments";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .typing": {
    margin: "10px 20px",
    fontSize: "22px",
    left: "12.5px",
    position: "absolute",
    bottom: "60px",
    color: theme.palette.info.main,
    // Keyframes for pulsating effect
    animation: `${keyframes`0%, 100% { opacity: 1;} 50% { opacity: 0.5; }`} 2s infinite ease-in-out`,
  },
}));

export default function ({ disabled = false }) {
  const [message, setMessage] = useState("");
  const chat = useChat();

  const { set, is } = usePerforming();

  const onChange = (event) => {
    if (!event.target.value || event.target.value.trim()) {
      setMessage(event.target.value);
    }
  };

  /**
   * Handles the key down event for the input field.
   * @param {Event} event - The key down event.
   */
  const onKeyDown = (event) => {
    try {
      if (isHotkey("mod+v", event)) {
        // event.preventDefault();
        Paste({ onComplete: chat.attachments.paste, types: ["image/png", "image/jpeg"] });
      }
      if (event.key === "Enter") {
        event.preventDefault();
        send();
      }
      chat.typing.set();
    } catch (_) {
      console.log(_);
    }
  };

  /**
   * Sends the message to the chat.
   */
  const send = async () => {
    set.updating(true);

    try {
      // Make sure we've got the message
      if (message.trim() === "" && !chat.attachments.has()) return;

      // Send the message
      await chat.message.send({ message });

      // Set the message to empty
      setMessage("");
    } catch (_) {
      console.log(_);
    }

    set.updating(null);
  };

  return (
    <StyledBox sx={{ padding: "5px", position: "fixed", bottom: 0, width: "100%" }}>
      {is.updating() && <Working />}
      <Attachments chat={chat} />
      {chat.typing.get() && (
        <Box className="typing">
          <DynamicIcon icon={"fa-message-dots"} />
        </Box>
      )}
      <TextField
        fullWidth
        variant="outlined"
        onChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
        value={message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ marginLeft: "20px", cursor: "pointer" }}>
              <IconButton color="primary" sx={{ p: "0px 10px", borderRadius: "5px" }} disabled={is.updating()}>
                {!chat.uploading ? (
                  <Box component="label" sx={{ padding: "5px 0" }} htmlFor="chat-upload-button" className="label">
                    <DynamicIcon icon="fa-paperclip" />
                  </Box>
                ) : (
                  <IconButton>
                    <CircularProgress size="16px" />
                  </IconButton>
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <input
        accept="image/*, video/*"
        style={{ display: "none" }}
        id="chat-upload-button"
        multiple
        type="file"
        onChange={(e) => chat.attachments.upload(e.target.files)}
      />
    </StyledBox>
  );
}
