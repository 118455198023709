/** @format */
import { Box } from "@mui/material";

export default function Image({ url }) {
  return (
    <Box
      component="img"
      loading="lazy"
      height={1}
      width={1}
      src={url}
      style={{ border: "0px solid transparent" }}
      sx={{
        objectFit: "cover",
        borderRadius: 3,
        border: "0px",
      }}
    />
  );
}
