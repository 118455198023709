/** @format */
import { Box, Divider, IconButton, Stack } from "@mui/material";
import DynamicIcon from "dynamicicon";
import key from "key";
import { Likes } from "likesml";
import { useLocales } from "locales";
import { Icon } from "logo";
import { Avatar, Name } from "profiles";
import TimeAgo from "timeagoml";
import { useUser } from "user";

export default function ({ id, messages, onDelete, replyAvatar = 30 }) {
  // Get the user
  const user = useUser();

  // Get the locales
  const { t } = useLocales();

  // Get the replies
  const replies = messages.filter((message) => message.replyId == id);

  // if we don't have replies, show nothing
  if (replies.length == 0) return null;

  return (
    <Box>
      {replies.map((message) => (
        <Box {...key("discussion", "replies", message.id)}>
          <Box sx={{ margin: "5% 0", opacity: 0.5 }}>
            <Divider />
          </Box>
          <Stack direction="row" spacing={3} sx={{ margin: "10px 0 0" }}>
            <Box sx={{ width: `${replyAvatar}px` }}>
              {message?.generated ? <Icon size={replyAvatar} /> : <Avatar size={replyAvatar} id={message.userId} />}
            </Box>
            <Box sx={{ display: "flex", flexGrow: 1, fontSize: "0.8em", whiteSpace: "pre-line" }}>
              <Stack direction="column">
                <Box>
                  <Stack direction="row" sx={{ fontWeight: "bold" }} alignItems="center" spacing={2}>
                    {message.generated ? <Box component="span">{t("generatedWithAI")} </Box> : <Name {...message} />}

                    <Box sx={{ opacity: 0.5, fontWeight: "normal", fontSize: ".5em" }}>
                      <TimeAgo timestamp={message.timestamp} />

                      {message.edited && (
                        <Box component="span" sx={{ marginLeft: "5px" }}>
                          - {t("edited")}
                        </Box>
                      )}
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{ fontSize: "1em", margin: "20px 0" }}>{message.message}</Box>
              </Stack>
            </Box>
            <Box sx={{ width: "30px" }}>
              {user.authenticated && message.userId == user.id && (
                <IconButton onClick={() => onDelete(message.id)} sx={{ fontSize: "1em" }}>
                  <DynamicIcon icon={"fa-trash"} size="xs" />
                </IconButton>
              )}
            </Box>
          </Stack>

          {message.generated && (
            <Box sx={{ padding: "10px 60px 0" }}>
              <Likes
                slug={`discussion-replies-messageId-${message.id}`}
                enabled={true}
                variant="text"
                color="secondary"
              />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
