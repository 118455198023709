/** @format */

import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import axios from "axios";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "snackbar";

const Coupon = ({ setDiscount, discount, setWorking }) => {
  const { t } = useLocales();
  const { open } = useSnackbar();

  const [promoCode, setPromoCode] = useState("");

  const handleApplyCoupon = () => {
    // Apply coupon code logic here
    setWorking(true);
    axios
      .post("/api/billing/subscriptions/coupon", {
        promoCode,
      })
      .then((response) => {
        const { data } = response.data;
        if (data.error_code) {
          open(t("couponErrorMessage"), "warning");
        } else {
          if (data.id !== null) open(t("couponSuccessMessage"), "success");
          setDiscount({ id: data.id, discount: data.discount / 100 });
        }
      })
      .catch(() => {
        open(t("couponErrorMessage"), "error");
      })
      .finally(() => setWorking(false));
  };

  useEffect(() => {
    handleApplyCoupon();
  }, []);

  return (
    <Stack direction="row" spacing={2} alignItems={"center"}>
      <TextField
        label={t("couponCode")}
        variant={discount == null ? "outlined" : "outlined"}
        value={promoCode}
        onChange={(e) => setPromoCode(e.target.value)}
        placeholder={t("enterCouponCode")}
        fullWidth
        disabled={discount !== null}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {discount == null ? (
                <Button
                  size="small"
                  color="success"
                  variant="contained"
                  onClick={handleApplyCoupon}
                  disabled={promoCode?.length == 0}
                >
                  {t("apply")}
                </Button>
              ) : (
                <Button size="small" color="primary" variant="outlined" onClick={() => setDiscount(null)}>
                  {t("change")}
                </Button>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default Coupon;
