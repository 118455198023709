/** @format */
import { Box, Button, CardActions, Checkbox, FormControlLabel, Link, Stack } from "@mui/material";
import { useLocales } from "locales";
import { useState } from "react";

export default function Terms({ next }) {
  const { t, url } = useLocales();
  const [isAgreed, setIsAgreed] = useState(false);
  const [isInformed, setIsInformed] = useState(false);

  return (
    <Stack direction="row" spacing={2}>
      <Box>
        <h4 style={{ margin: "0 0 20px" }}>{t("importantTermsTitle")}</h4>
        <ul>
          <li>{t("importantTermsNotes1")}</li>
          {/* <li>{t("importantTermsNotes2")}</li> */}
          <li>{t("importantTermsNotes3")}</li>
        </ul>
        <Box sx={{ margin: "20px 0 20px" }}>
          {t("termsDescription")} {t("acceptingTermsMessage")}
        </Box>
        <Box>
          <Link target="blank" href={url("pages/company/terms")} sx={{ margin: "0 3px" }}>
            {t("termsOfUse")}
          </Link>
          <Box component="span" sx={{ margin: "0 3px" }}>
            {t("andOur")}
          </Box>
          <Link target="blank" href={url("pages/company/privacy")} sx={{ marginLet: "3px" }}>
            {t("privacyPolicy")}
          </Link>
          .
          <Box component="span" sx={{ marginLeft: "10px" }}>
            <FormControlLabel
              control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} color="primary" />}
              label={t("iAgree")}
            />
          </Box>
        </Box>
        <Box sx={{ margin: "20px 0" }}>
          {t("keepMeInformed")}.
          <Box component="span" sx={{ marginLeft: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox checked={isInformed} onChange={(e) => setIsInformed(e.target.checked)} color="primary" />
              }
              label={t("yes")}
            />
          </Box>
        </Box>
        <CardActions style={{ textAlign: "right" }}>
          <Button size="large" variant="contained" color="success" disabled={!isAgreed} onClick={next}>
            {t("continue")}
          </Button>
        </CardActions>
      </Box>
    </Stack>
  );
}
