/** @format */
import { Button, Stack } from "@mui/material";
import key from "key";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";
import { withUnit } from "unit";

import authProviders from "./components/Providers";

class AuthProviders extends ReactComponent {
  constructor(props) {
    super(props);

    this.small = props.small || false;

    this.unit = this.props.unit.new(this.constructor.name);
  }

  render() {
    const { onAuthProviderClick, t } = this.props;
    return (
      <Stack
        direction="row"
        justifyContent={this.small ? "flex-start" : "center"}
        alignItems="center"
        spacing={2}
        role="list"
      >
        {!this.small && (
          <label style={{ margin: 0, padding: "0 10px 0 0", fontWeight: "700" }}>{t("signinWith")}</label>
        )}
        {authProviders.map((authProvider) => {
          return (
            <Button
              size="large"
              variant="outlined"
              disabled={!authProvider.enabled}
              sx={{
                color: "#031926", //authProvider.color,
                margin: "5px",
                borderRadius: "5px",
                height: 35,
                maxWidth: 35,
                borderColor: "#DDD",
                ":hover": {
                  backgroundColor: "#2c3f4e", //authProvider.color,
                  borderColor: "#2c3f4e", //authProvider.color,
                  color: "#FFF",
                },
              }}
              onClick={() => onAuthProviderClick(authProvider)}
              {...key("AuthProviders", authProvider)}
            >
              {authProvider.icon}
            </Button>
          );
        })}
      </Stack>
    );
  }
}

export default withUnit(withLocales(AuthProviders));
