/** @format */
import { Box, Button, Link, Stack, TextField } from "@mui/material";
import { useLocales } from "locales";

export default function Glossary({ setEmail, disabled, send, working }) {
  const { t, url } = useLocales();

  return (
    <Stack direction="column" spacing={5}>
      <h5 style={{ margin: 0, padding: 0, textAlign: "center" }}>{t("getStoryDelivered")}</h5>
      <TextField
        sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
        disabled={disabled}
        placeholder={t("enterYourEmailAddress")}
        onKeyUp={({ target }) => setEmail(target.value)}
        multiline={true}
        fullWidth={true}
      />
      <Stack direction="row" spacing={5}>
        <Box sx={{ fontSize: "12px" }}>
          {t("unsubscribeDisclaimer")}
          <Link href={url("pages/company/privacy")} target="_blank" sx={{ marginLeft: "4px" }}>
            {t("privacyPolicy")}
          </Link>
          .
        </Box>
        <Box sx={{ textAlign: "right" }}>
          {!working && (
            <Button
              variant="contained"
              disabled={false && value.length < 10}
              onClick={() => send(["interested_in_newsletter"])}
              color="success"
              size="large"
            >
              {t("subscribe")}
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
