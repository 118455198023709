/** @format */
import axios from "axios";
import parser from "html-react-parser";
import { cookiePolicyId, siteId } from "../../config";

import isSSR from "isssr";

const submitConsent = async () => {
  return new Promise(async (resolve) => {
    try {
      // Define the browser information you want to submit
      const browserInfo = {
        userAgent: navigator.userAgent, // User agent string
        language: navigator.language, // Browser language
        // Add more browser information fields as needed
      };

      // Create a POST request with the browser information
      await axios.post("/api/consent", { browser: browserInfo });

      resolve();
    } catch (error) {
      resolve();
    }
  });
};

const js = () => {
  if (isSSR) return;
  window._iub = window._iub || [];

  window._iub.csConfiguration = {
    siteId: siteId,
    cookiePolicyId: cookiePolicyId,
    lang: "en",
    skipSaveConsent: false,
    banner: {
      position: "bottom",
      backgroundColor: "#9DBEBB",
      textColor: "#121212",
      fontSize: "16px",
      fontWeight: "bold",
      acceptButtonDisplay: true,
      customizeButtonDisplay: true,
      rejectButtonDisplay: true,
    },
    preferenceCookie: {
      expireAfter: 180,
    },
    callback: {
      onConsentFirstGiven: async () => {
        await submitConsent();
        if (document.location.pathname.includes("reject-agreement")) document.location.href = "/";
      },
      onConsentRejected: function () {
        if (document.location.pathname.includes("reject-agreement")) {
        } else {
          // # TODO, this should be localized
          // document.location.href = "/en-us/pages/reject-agreement";
        }
      },
      onReady: function () {
        try {
          const h1Tags = document.querySelectorAll("h1");
          if (h1Tags.length >= 2) {
            h1Tags[1].remove();
          }
        } catch (_) {}
      },
    },
    // logLevel: "info",
  };
};

const getIubenda = (url) => {
  if (isSSR) return;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(({ data }) => {
        resolve(parser(data.content));
      })
      .catch((error) => {
        // Handle the error here
        reject(error);
      });
  });
};

const getCookiePolicy = () => {
  return getIubenda(`https://www.iubenda.com/api/privacy-policy/${cookiePolicyId}/cookie-policy/no-markup`);
};

const getPrivacyPolicy = () => {
  return getIubenda(`https://www.iubenda.com/api/privacy-policy/${cookiePolicyId}/no-markup`);
};

const getTerms = () => {
  return getIubenda(`https://www.iubenda.com/api/terms-and-conditions/${cookiePolicyId}/no-markup`);
};

export { getCookiePolicy, getPrivacyPolicy, getTerms, js };
