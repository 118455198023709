/** @format */

export function getHistory(slug = null) {
  if (slug == null) {
    this.setState({ history: [] });
  } else {
    this.setState({
      history: [{ [slug]: 0 }],
    });
  }
}

export function setHistory(slug) {
  // Push the new state into the browser history
  const index = this.tabValue(slug);
  window.history.pushState({ slug, index }, "", `?${slug}/${index}`);
}
