/** @format */

export function getBeta() {
  const { user } = this.props;
  try {
    return user.meta.get("beta");
  } catch (_) {
    console.log(_);
    return false;
  }
}
