/**  @format */
import { Box, Card, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Cdn from "cdn";
import { useLocales } from "locales";
import { SignIn, useUser } from "user";

import { TinyError } from "errors";

export default function () {
  return (
    <TinyError>
      <SidebarNewsletter />
    </TinyError>
  );
}

export function SidebarNewsletter() {
  const theme = useTheme();
  const { authenticated } = useUser();
  const { t } = useLocales();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  // Authenticated
  if (authenticated) return null;

  return (
    <Box component={Card} variant={"outlined"} padding={2} bgcolor={"transparent"}>
      <Grid container spacing={4}>
        {isMd ? (
          <Grid item container justifyContent={"center"} xs={12}>
            <Box height={1} width={1} maxWidth={"80%"}>
              <Box
                component="img"
                loading="lazy"
                src={Cdn(
                  "https://us-central.cdn.made.live//thumbnail_blue_octopus_laptop_little_fish_4b7f9dca74/thumbnail_blue_octopus_laptop_little_fish_4b7f9dca74.png?updated_at=2023-02-20T03:04:23.862Z"
                )}
                width={1}
                height={1}
                sx={{
                  filter: theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                }}
              />
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              marginBottom: 2,
            }}
          >
            {t("loginToComment")}
          </Typography>
          <Divider />
          <SignIn small={true} />
        </Grid>
      </Grid>
    </Box>
  );
}
