/** @format */
import { Box, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCms } from "cms";
import parse from "html-react-parser";
import key from "key";
import { useMemo } from "react";

import { TinyError } from "errors";

import TextAction from "../TextAction";
import TextIcon from "../TextIcon";

export default function (props) {
  return (
    <TinyError>
      <TextGrid {...props} />
    </TinyError>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "100%",
}));

export function TextGrid(props) {
  const {
    page: { blocks },
  } = useCms();

  // Memoize the calculation of Text and tmp based on props.number
  const { TextGrid } = useMemo(() => {
    if (!blocks?.length) return { TextGrid: null };
    const TextGrid = blocks.filter((block) => block.type == "TextGrid")[props.number - 1];

    return { TextGrid };
  }, [props.number, blocks]);

  // Early return if there are no articles
  if (!TextGrid?.text) return null;

  return (
    <Grid container spacing={props.spacing || 2} role="article">
      {TextGrid.text.slice(0, props.length || 4).map((text, index) => (
        <Grid item xs={12} md={props.width || 6} {...key("cms", "TextGrid", props.number, index)}>
          <Item style={{ textAlign: props.align || "center" }}>
            <TextIcon {...text} />
            <Box data-testid="TextGridText">{parse(text.text)}</Box>
            <TextAction {...text} />
          </Item>
        </Grid>
      ))}
    </Grid>
  );
}
