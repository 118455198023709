/** @format */

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "firemade";

export default function resetPassword(emailAddress) {
  return new Promise((resolve, reject) => {
    if (!emailAddress) return reject("noEmailAddress");
    if (auth.currentUser) return reject("notLoggedIn");
    sendPasswordResetEmail(getAuth(), emailAddress, {
      url: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}${window.location.search}${window.location.hash}`,
    })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
