/** @format */

import { Box, Divider } from "@mui/material";
import { DialogError, TinyError } from "errors";
import key from "key";
import { withLocales } from "locales";
import { Component as ReactComponent, Suspense, lazy } from "react";
import { Panels, Tab, Tabs, withUI } from "ui";
import { withUser } from "../../context";

// These don't need the tab part in the name (artifcact)
const Account = lazy(() => import("./components/Account"));
const Preferences = lazy(() => import("./components/Preferences"));
const Links = lazy(() => import("./components/Links"));
const Security = lazy(() => import("./components/Security"));
const Share = lazy(() => import("./components/Share"));

const tabs = [
  {
    key: "account",
    icon: "fa-user-circle",
    disabled: false,
    component: <Account />,
  },
  {
    key: "preferences",
    icon: "fa-cog",
    disabled: false,
    component: <Preferences />,
  },
  {
    key: "links",
    icon: "fa-link",
    disabled: false,
    component: <Links />,
  },
  {
    key: "security",
    icon: "fa-shield-check",
    disabled: false,
    component: <Security />,
  },
  {
    key: "share",
    icon: "fa-qrcode",
    disabled: false,
    component: <Share />,
  },
];

class Settings extends ReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, t } = this.props;

    return (
      <Box>
        {!user.authenticated ? (
          <Box style={{ minWidth: "400px", minHeight: "100px", padding: "0 20px" }}>{t("pleaseLogin")}</Box>
        ) : (
          <Box data-testid="user-settings">
            <TinyError>
              <Tabs slug="user/settings">
                {tabs.map((tab, index) => (
                  <Tab
                    {...key("user", "settings", "tab", index)}
                    slug="user/settings"
                    variant="button"
                    size="small"
                    key={tab.key}
                    icon={tab.icon}
                    label={<label>{t(tab.key)}</label>}
                    disabled={tab.disabled}
                    index={index}
                  />
                ))}
              </Tabs>
            </TinyError>
            <Divider />
            <DialogError>
              <Box sx={{ minWidth: "500px" }}>
                <Panels slug="user/settings">
                  {tabs.map((tab, index) => (
                    <TinyError {...key("user", "settings", "panel", index)}>
                      <Suspense fallback={null}>{tab.component}</Suspense>
                    </TinyError>
                  ))}
                </Panels>
              </Box>
            </DialogError>
          </Box>
        )}
      </Box>
    );
  }
}

export default withUser(withUI(withLocales(Settings)));
