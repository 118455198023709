/** @format */
import Config from "@Workspace/config";
import capitalize from "capitalize";
import { Functional } from "unit";

const unit = Functional.unit("workspace/intro");

export default async function setupIntro(from = "~8") {
  let testId = unit.report({
    method: "setupIntro",
    message: "Sets up intros for each desktop feature.",
    from: from,
  });

  return new Promise(async (resolve, reject) => {
    try {
      // Create an array of import promises
      const importPromises = Config.ui.intro.map((feature) =>
        import(`../../components/Features/components/${capitalize(feature)}/intro/index.jsx`)
      );

      // Wait for all dynamic imports to resolve
      const importedModules = await Promise.all(importPromises);

      // Assign the imported modules to workspace.tours
      importedModules.forEach((module, index) => {
        this.intros[Config.ui.intro[index]] = module.default;
      });

      unit.passed({ testId: testId });
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error });
      reject(error);
    }
  });
}

export function openIntro(feature, from = "~39") {
  let testId = unit.report({
    method: "openIntro",
    payload: { feature: feature },
    from: from,
    test: "A info dialog expected to open, should do so correctly and present the appropriate content. A visual inspection should be made.",
    steps: [
      "From workspace",
      "From features menu",
      "Select project",
      "Dialog window should open, and",
      "Pass visual inspection",
    ],
  });
  const { errors } = this.props;
  if (!feature) return;
  // try {
  //   const { ui } = workspace.props;
  //   ui.dialog.open("intro", { feature: feature });
  //   unit.passed({ testId: testId }, from);
  // } catch (error) {
  //   unit.failed({ testId: testId, error: error }, from);
  //   errors.error(true, error, "~61");
  // }
}

export function getIntros() {
  return this.intros;
}
