/** @format */
import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Cdn from "cdn";
import Form from "getresponse";
import { withLocales } from "locales";

const ComingSoon = (props) => {
  const theme = useTheme();
  const { t } = props.locales;
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      >
        <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: "95%" }}>
          <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }} position={"relative"}>
            <Box width={1} order={{ xs: 2, md: 1 }} display={"flex"} alignItems={"center"}>
              <Container>
                <Box>
                  <Typography
                    variant="h5"
                    role="heading"
                    component={"h5"}
                    align={isMd ? "left" : "center"}
                    sx={{ fontWeight: 700 }}
                  >
                    {t("comingSoon")}
                  </Typography>
                  <Box role="form" marginTop={4} display={"flex"} justifyContent={{ xs: "center", md: "flex-start" }}>
                    <Form />
                  </Box>
                </Box>
              </Container>
            </Box>
            <Box
              sx={{
                flex: { xs: "0 0 100%", md: "0 0 50%" },
                position: "relative",
                maxWidth: { xs: "100%", md: "50%" },
                order: { xs: 1, md: 2 },
                minHeight: { xs: "auto", md: "calc(100vh - 58px)" },
              }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: "50vw" },
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      overflow: "hidden",
                      left: "0%",
                      width: 1,
                      height: 1,
                      position: { xs: "relative", md: "absolute" },
                      clipPath: {
                        xs: "none",
                        md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                      },
                      shapeOutside: {
                        xs: "none",
                        md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: { xs: "auto", md: 1 },
                        "& img": {
                          objectFit: "cover",
                        },
                      }}
                    >
                      <Box
                        component="img"
                        loading="lazy"
                        src={Cdn(
                          "https://us-central.cdn.made.live//thumbnail_crab1_faa5e9133e/thumbnail_crab1_faa5e9133e.png?width=1024&height=1024"
                        )}
                        height={{ xs: "auto", md: 1 }}
                        maxHeight={{ xs: 300, md: 1 }}
                        width={1}
                        maxWidth={1}
                        sx={{
                          filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default withLocales(ComingSoon);
