/** @format */
import uniq from "lodash/uniq";

/**
 * Opens tips with the provided text and optional props.
 * @param {Object} options - The options for opening tips.
 * @param {string} options.text - The text to display in the tips.
 * @param {Object} [options.props] - The optional props to pass to the tips component.
 * @param {boolean} [options.merge=false] - Whether to merge the provided props with the existing props or replace them.
 */
export function openTips({ text, props = {}, merge = false }) {
  this.setState((prevState) => ({
    tips: {
      ...prevState.tips,
      text: text || prevState.tips.text || null,
      props: !props ? prevState.tips.props : !merge ? props : { ...prevState.tips.props, ...props },
    },
  }));
}

/**
 * Closes the tips by updating the state.
 */
export function closeTips() {
  this.setState((prevState) => ({
    tips: {
      ...prevState.tips,
      text: null,
    },
  }));
}

/**
 * Checks if tips are opened.
 * @returns {boolean} Returns true if tips are opened, false otherwise.
 */
export function tipsOpened() {
  const { user } = this.props;
  if (user.meta.get("assistMute")) return false;
  return this.state.tips.text !== null || this.state.tips.working;
}

/**
 * Sets the props for tips.
 * @param {Object} props - The props to set.
 * @param {boolean} [merge=false] - Whether to merge the props with the existing props or replace them.
 */
export function setTipsProps(props, merge = false) {
  this.setState((prevState) => ({
    props: {
      ...prevState.tips,
      props: merge ? { ...prevState.tips.props, ...props } : props,
    },
  }));
}

/**
 * Retrieves the props of the tips from the component's state.
 * @returns {Object} The props of the tips.
 */
export function getTipsProps() {
  return this.state.tips.props;
}

/**
 * Toggles the mute state of the tips.
 */
export function toggleMute() {
  this.setState((prevState) => ({
    tips: {
      ...prevState.tips,
      muted: !this.state.tips.muted,
    },
  }));
}

/**
 * Sets the working state of tips.
 * @param {boolean} working - The new working state.
 */
export function setWorking(working) {
  this.setState((prevState) => ({
    tips: {
      ...prevState.tips,
      working: working,
    },
  }));
}

/**
 * Retrieves the value of the 'working' property from the 'tips' state.
 * @returns {boolean} The value of the 'working' property.
 */
export function getWorking() {
  return this.state.tips.working;
}

/**
 * Retrieves the value of the 'muted' property from the state object.
 * @returns {boolean} The value of the 'muted' property.
 */
export function getMuted() {
  return this.state.tips.muted;
}

/**
 * Retrieves the tips text from the state.
 * @returns {string} The tips text.
 */
export function getTipsText() {
  return this.state.tips.text;
}

/**
 * Sets the text for tips.
 *
 * @param {string} text - The text to set for tips.
 */
export function setTipsText(text) {
  if (text == this.state.tips.text) return;
  this.setState((prevState) => ({
    tips: {
      ...prevState.tips,
      text: text,
    },
  }));
}

/**
 * Dismisses the tips list text.
 *
 * @param {string} text - The text to dismiss.
 */
export function dismissTipsListText(text) {
  try {
    // Get the currently selected feature
    const feature = this.feature.selected();

    // Get the project
    let project = { ...this.state.project };

    // Make sure we have the feature to return
    if (!project.features[feature]) return false;

    // Make sure we have tips saved in this feature. Add if not
    if (!project.features[feature].tips) project.features[feature].tips = [];

    // Now add this hash to the dismissed tips
    project.features[feature].tips.push(text);

    // Make sure we have only unique tips
    project.features[feature].tips = uniq(project.features[feature].tips);

    // Update the project with the dismissed tip
    this.update
      .project({
        project: project,
        from: "~158",
        ai: null,
      })
      .then(() => {
        this.forceUpdate();
      });
  } catch (_) {
    console.error(_);
  }
}

/**
 * Filters the list of tips based on dismissal status.
 * @param {Array} tips - The array of tips to be filtered.
 * @returns {Array} - The filtered array of tips.
 */
export function tipsListFiltered(tips = []) {
  try {
    let filteredTips = [];

    // Get the currently selected feature
    const feature = this.feature.selected();

    // Get the project
    let project = { ...this.state.project };

    // Make sure we have the feature to return, otherwise return the tips
    if (!project.features[feature] || !project.features[feature]?.tips) return tips;

    // Get the existing tips
    const existingTips = project.features[feature].tips;

    // Loop through the tips
    tips.forEach((tip) => {
      // If the tip is not dismissed, add it to the filtered tips
      if (!existingTips.includes(tip)) {
        filteredTips.push(tip);
      }
    });

    return filteredTips;
  } catch (_) {
    return tips || [];
  }
}
