/** @format */
import { nanoid } from "nanoid";

export function getToken() {
  return this.state.token || nanoid();
}

export function setToken(token) {
  this.setState({ token: token });
}
