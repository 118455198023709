/** @format */
import { Backdrop, Box, CssBaseline, Hidden, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Component as ReactComponent } from "react";
import { fallbackErrorMessage } from "../../config/index.json";
import Loader from "../Loader";

import { exception } from "sentry";

const StyledApp = styled("div")(() => ({
  "&.backdrop": {
    zIndex: 2000,
    backgroundColor: "#FFF",
    opacity: "1",
  },
  "& .wordmark": { textAlign: "left", display: "block", margin: "0px" },
  "& .wordmark .made": { color: "#468189" },
  "& .wordmark .live": { color: "#9dbebb" },
  "& .body": { margin: "10px 0px", fontSize: "11px" },
}));

class AppErrorComponent extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error) {
    this.setState({ error: true });
    try {
      exception(error);
    } catch (_) {}
  }

  componentDidMount() {
    if (this.props.error && this.props.from) {
      exception(this.props.error);
      this.setState({ error: true });
    }
  }

  render() {
    const { children } = this.props;

    if (this.state.error) {
      return (
        <>
          <CssBaseline />
          <StyledApp data-element="StyleApp">
            <Backdrop open={true} className="backdrop" sx={{ backgroundColor: "#FFF" }}>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ width: "50%" }}>
                <Hidden smDown>
                  <Loader width={200} from="~61" />
                </Hidden>
                <Box>
                  <h1 className="wordmark">
                    <span className="made">made</span>
                    <span className="live">live</span>
                  </h1>
                  <Box className="body">{fallbackErrorMessage} thrown to APP.</Box>
                </Box>
              </Stack>
            </Backdrop>
          </StyledApp>
        </>
      );
    }

    return children;
  }
}

export default AppErrorComponent;
