/** @format */
import { updateProfile } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "firemade";
import { nanoid } from "nanoid";
import defaults from "../../../../../config/defaults.json";

let lastUserUpdate = 0;

export default function setUser(d) {
  return new Promise(async (resolve, reject) => {
    let tmp = {};

    if (lastUserUpdate + 1000 > Date.now()) {
      return;
    }
    lastUserUpdate = Date.now();

    if (!auth.currentUser) reject("User not logged in.");

    try {
      const userDocRef = doc(db, "users", d.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        tmp = userDocSnap.data();
      } else {
        Object.keys(defaults).forEach((k) => {
          try {
            if (k != "token") {
              tmp[k] = defaults[k];
            } else {
              tmp[k] = nanoid();
            }
          } catch (e) {}
        });
      }

      Object.keys(d).forEach((k) => {
        if (tmp[k] !== undefined) {
          try {
            tmp[k] = d[k];
          } catch (e) {}
        }
      });

      // Preserve and update meta fields in existing data
      if (tmp.meta) {
        tmp.meta = {
          ...tmp.meta,
          ...d.meta,
        };
      }

      let sorted = tmp;
      try {
        sorted = Object.keys(tmp)
          .sort()
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: tmp[key],
            }),
            {}
          );
      } catch (e) {}

      setDoc(
        userDocRef,
        {
          ...sorted,
          timestamp: {
            ...sorted.timestamp,
            ...{
              created: parseInt(auth.currentUser.metadata.createdAt),
              updated: lastUserUpdate,
              lastLogin: parseInt(auth.currentUser.metadata.lastLoginAt),
            },
          },
        },
        { merge: true }
      );
      updateProfile(auth.currentUser, {
        ...sorted,
        timestamp: {
          updated: lastUserUpdate,
        },
      })
        .then(() => {
          return resolve({ ...tmp, ready: true });
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return reject(error);
    }
  });
}
