/**
 * To - where to upload
 *
 * @format
 */

export function getTo(to) {
  this.unit.report({
    method: "isTo",
  });
  try {
    if (!to) return this.state.to;
    return to == this.state.to;
  } catch (e) {
    return false;
  }
}

export function setTo(to = "public", from = "~19") {
  this.unit.report({
    method: "setFrom",
    from: from,
  });
  this.setState({ to: to });
}

export function toggleTo(from = "~27") {
  this.unit.report({
    method: "setFrom",
    from: from,
  });
  this.setState({ to: this.state.to == "workspace" ? "public" : "workspace" });
}
