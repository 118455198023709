/** @format */

import { Box, Card, Divider, Stack } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import cdn from "cdn";
import geoml from "geoml";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";
import { environment } from "version";
import { key } from "../../../../config/index.json";

import { Working } from "performing";

// Checkout components
import Address from "./components/Address";
import Coupon from "./components/Coupon";
import Stripe from "./components/Stripe";
import Totals from "./components/Totals";

// Initialize Stripe
const stripePromise = loadStripe(key[environment]);

const CheckoutForm = (props) => {
  // Performing object
  const [working, setWorking] = useState(false);

  // Pass the address through components
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // Passing the discount through components
  const [discount, setDiscount] = useState(null);
  const [geo, setGeo] = useState(null);
  const [tax, setTax] = useState(null);

  const { t } = useLocales();

  const { selectedPackage, onComplete, optIn, children } = props;

  useEffect(() => {
    // Fetch client's IP using axios and ipify
    geoml()
      .then((response) => {
        setGeo(response);
      })
      .catch((_) => {});

    return () => {
      setWorking(false);
    };
  }, []);

  return (
    <Card sx={{ marginTop: "10px", padding: "20px", minHeight: "100%" }}>
      <Stack direction="row" spacing={0} alignItems={"center"}>
        <Box fontWeight="bold" sx={{ marginTop: "5px" }}>
          {optIn ? t("savedByStripe") : t("proudlyStripe")}
        </Box>
        <Box
          component="img"
          src={cdn(
            "https://us-central.cdn.made.live//thumbnail_1024px_Stripe_Logo_revised_2016_svg_2435dff6e0/thumbnail_1024px_Stripe_Logo_revised_2016_svg_2435dff6e0.png?2023-09-20T21:19:58.510Z"
          )}
          alt="stripe"
          sx={{ width: "100px" }}
        />
      </Stack>
      {children}
      <Box sx={{ padding: "20px 0" }}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Stack direction="column" spacing={2}>
          {!optIn && <Coupon discount={discount} setDiscount={setDiscount} setWorking={setWorking} />}
          <Address
            setTax={setTax}
            setWorking={setWorking}
            discount={discount}
            selectedPackage={selectedPackage}
            geo={geo}
            country={country}
            postalCode={postalCode}
            setCountry={setCountry}
            setPostalCode={setPostalCode}
            {...props}
          />
        </Stack>
      </Box>
      {tax !== null && (
        <Stripe
          working={working}
          setWorking={setWorking}
          discount={discount}
          onComplete={onComplete}
          selectedPackage={selectedPackage}
          tax={tax}
          country={country}
          postalCode={postalCode}
          {...props}
        />
      )}
      {optIn ? (
        <Box sx={{ padding: "20px 0" }}>{working && <Working />}</Box>
      ) : (
        <>
          <Box sx={{ padding: "20px 0" }}>
            {working && <Working />}
            <Divider />
          </Box>
          <Totals selectedPackage={selectedPackage} discount={discount} tax={tax} />
        </>
      )}
    </Card>
  );
};

const PaymentForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
};

export default PaymentForm;
