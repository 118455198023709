/** @format */

import { Box, Button, Card, CardContent, Hidden, Stack, Typography } from "@mui/material";
import { useLocales } from "locales";
import { useRouter } from "router";

function CTA() {
  const { t, url } = useLocales();
  const { navigate } = useRouter();
  return (
    <Hidden smDown>
      <Card sx={{ padding: "2.5% 5%" }}>
        <CardContent>
          <Stack direction="row" spacing={10} justifyContent={"center"}>
            <Stack>
              <Typography variant="h3" gutterBottom>
                {t("featuresWebCTATitle")}
              </Typography>
              <Typography variant="h4" gutterBottom>
                {t("featuresWebCTASubtitle")}
              </Typography>
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="success"
                sx={{ ml: "auto" }}
                size="large"
                onClick={() => navigate(url("/pages/onboarding"))}
              >
                {t("featuresWebButton")}
              </Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Hidden>
  );
}

export default CTA;
