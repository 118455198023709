/** @format */

import Config from "@Workspace/config";
import capitalize from "capitalize";
import { lazy } from "react";
import { Functional } from "unit";

const unit = Functional.unit("workspace/widget");

export default async function setupWidgets(from = "~10") {
  let testId = unit.report({
    method: "setupWidgets",
    message: "Widgets should load without error",
    from: "12",
  });
  return new Promise(async (resolve, reject) => {
    try {
      Object.keys(Config.ui.widgets).forEach((feature) => {
        if (!this.widgets[feature]) this.widgets[feature] = {};
        Config.ui.widgets[feature].forEach((component) => {
          this.widgets[feature][component] = lazy(() =>
            import(
              `../../components/Features/components/${capitalize(feature)}/widgets/${capitalize(component)}/index.jsx`
            )
          );
        });
      });
      unit.passed({ testId: testId }, from);
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error }, from);
      reject(error);
    }
  });
}

export function setWidget(params, from = "~37") {
  let testId = unit.report({
    method: "setWidget",
    message: "Widgets should load without error",
    from: from,
  });
  try {
    const { feature, component = feature, ...rest } = params;
    let { widgets } = this.state;

    // Ensure feature exists
    if (!widgets[feature]) {
      widgets[feature] = {};
    }

    // Replace component data
    widgets[feature][component] = rest;

    this.setState({
      widgets,
    });

    unit.passed({ testId: testId }, from);
  } catch (error) {
    console.error(error, "~61");
    unit.failed({ testId: testId, error: error }, from);
  }
}

export function getWidget(params, from = "~66") {
  let testId = unit.report({
    method: "setWidget",
    message: "Widgets should load without error",
    from: from,
  });

  try {
    let { feature, component } = params || {};
    if (!component) component = feature;
    const { widgets } = this.state;

    if (!feature && !component) {
      unit.passed({ testId: testId }, from);
      return widgets;
    }

    let filteredWidgets = {};
    if (feature && widgets[feature]) {
      filteredWidgets = { [feature]: widgets[feature] };
    }

    if (component) {
      Object.keys(filteredWidgets).forEach((key) => {
        filteredWidgets[key] = { [component]: filteredWidgets[key][component] };
      });
    }

    unit.passed({ testId: testId }, from);
    return filteredWidgets;
  } catch (error) {
    console.error(error, "~97");
    unit.failed({ testId: testId, error: error }, from);
    return {};
  }
}

// Function to set the props for a widget
export function setWidgetProps(params, from = "~104") {
  let testId = unit.report({
    method: "setProps",
    message: "Props should be set without error",
    from: from,
  });

  try {
    const { feature, component, props, merge = false } = params;
    let { widgets } = this.state;

    // Ensure feature and component exists
    if (!widgets[feature]) {
      widgets[feature] = {};
    }

    if (!widgets[feature][component]) {
      widgets[feature][component] = {};
    }

    // Merge or replace the props
    if (merge) {
      widgets[feature][component].props = {
        ...widgets[feature][component].props,
        ...props,
      };
    } else {
      widgets[feature][component].props = props;
    }

    this.setState({
      widgets,
    });

    unit.passed({ testId: testId }, from);
  } catch (error) {
    console.error(error, "~140");
    unit.failed({ testId: testId, error: error }, from);
  }
}

// Function to get the props for a widget
export function getWidgetProps(params, from = "~146") {
  let testId = unit.report({
    method: "getProps",
    message: "Props should be retrieved without error",
    from: from,
  });

  try {
    const { feature, component } = params;
    const { widgets } = this.state;

    // Validate that feature and component exist in state
    if (widgets[feature] && widgets[feature][component]) {
      unit.passed({ testId: testId }, from);
      return widgets[feature][component].props || {};
    } else {
      unit.failed({ testId: testId, error: new Error("Feature or component not found") }, from);
      return {};
    }
  } catch (error) {
    console.error(error, "~166");
    unit.failed({ testId: testId, error: error }, from);
    return {};
  }
}
