/** @format */
import { Box, Card } from "@mui/material";
import { useCms } from "cms";
import { TinyError } from "errors";
import key from "key";
import React, { useEffect, useState } from "react";

// Get the block
import Block from "../../../Block";

export default function ({ text, slugs, meta }) {
  // get the page from the cms
  const { pages } = useCms();

  // A place to store the block
  const [blocks, setBlocks] = useState([]);

  // Load the blocks into the window
  const loadBlocks = (slug) => {
    // Merge all the block into a single block
    const page = pages.filter((page) => page.slug == slug);

    if (!page[0]?.blocks) return;
    setBlocks(page[0].blocks);
  };

  useEffect(() => {
    if (meta.type == "help") loadBlocks(slugs[0]);
  }, [pages]);

  return (
    <>
      {text}
      <Box data-component="assist-single">
        {blocks.length > 0 && (
          <Card className="blocks" variant="outlined">
            {blocks.map((block) => (
              <Box className="block" {...key("assist2", "template", "multi", "more", "block", block.id)}>
                <TinyError>
                  <Block {...block} />
                </TinyError>
              </Box>
            ))}
          </Card>
        )}
      </Box>
    </>
  );
}
