/** @format */
import { Box, Button } from "@mui/material";
import { useCms } from "cms";
import DynamicIcon from "dynamicicon";
import { useLocales } from "locales";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";

// import { TinyError } from "errors";

// export default function (props) {
//   return (
//     <TinyError>
//       <Video {...props} />
//     </TinyError>
//   );
// }

export default function Video({ number, autoplay, onEnded, thumb, buttonText = false, button = {} }) {
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef(null);

  const {
    page: { blocks },
  } = useCms();
  const { t } = useLocales();

  useEffect(() => {
    if (autoplay) {
      setPlaying(true);
    }
  });

  // Memoize the calculation of Text and tmp based on props.number
  const { Video } = useMemo(() => {
    if (!blocks?.length) return { TextList: null };
    const Video = blocks.filter((block) => block.type == "Video")[number - 1];

    return { Video };
  }, [number, blocks]);

  if (!Video) {
    return null;
  }

  const handlePlay = () => {
    setPlaying(true);
  };

  const onVideoEnded = () => {
    setPlaying(false);

    // Reset timestamp to 0
    playerRef.current.seekTo(0);

    if (onEnded) onEnded();
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ opacity: !playing && !thumb ? 0.25 : 1, borderRadius: "3px", overflow: "hidden" }}>
        <ReactPlayer
          ref={playerRef}
          url={Video.url}
          playing={playing}
          controls={false}
          light={false}
          width="100%"
          height="100%"
          onEnded={onVideoEnded}
        />
        {!playing && thumb && (
          <Box
            component="img"
            // loading="lazy"
            src={thumb}
            alt="Made Live Movie Thumbnail"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: 1,
              opacity: 1,
            }}
          />
        )}
      </Box>
      {!playing && (
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        >
          <Button variant="contained" size="large" color="success" onClick={handlePlay} {...button}>
            {buttonText ? t(buttonText) : <DynamicIcon icon="fa-play" />}
          </Button>
        </Box>
      )}
    </Box>
  );
}
