/** @format */

export default function createMasterFunctions(unitFunctions) {
  const masterFunctions = {
    report: [],
    passed: [],
    failed: [],
  };

  // Iterate through the array of unit functions
  unitFunctions.forEach((unit) => {
    const funcName = unit.name;
    if (masterFunctions.hasOwnProperty(funcName)) {
      masterFunctions[funcName].push(unit);
    }
  });

  // Wrap each array of functions in a master function that calls them all
  for (const key in masterFunctions) {
    const functions = masterFunctions[key];
    masterFunctions[key] = function (...args) {
      try {
        functions.forEach((fn) => fn(...args));
        return true;
      } catch (e) {
        return false;
      }
    };
  }

  return masterFunctions;
}
