/** @format */

import { Functional } from "unit";

const unit = Functional.unit("Designer/Layer");

export function setElementLayer(direction = "up", from = "~7") {
  unit.report({ method: "setElementLayer", from: from });
  const { performing } = this.props;
  try {
    const spread = this.state?.spread?.number || 0;
    const data = this.data("~12");

    const { spreads } = data;
    const { index, id } = this.element.selected();
    const { elements } = spreads[spread];

    if (elements && index >= 0 && index < elements.length) {
      if (direction === "up" && index < elements.length - 1) {
        // Move the element up by swapping it with the next element
        [elements[index], elements[index + 1]] = [elements[index + 1], elements[index]];
      } else if (direction === "down" && index > 0) {
        // Move the element down by swapping it with the previous element
        [elements[index], elements[index - 1]] = [elements[index - 1], elements[index]];
      }
      // Reassign the elements array after modifying
      spreads[spread].elements = elements;
    }

    // Close the layer menu
    this.controls.menu.close();

    // Select the new elements
    this.element.deselect();

    this.project
      .update(
        {
          data: data,
          note: "Changed the element layer.",
          ai: 0,
        },
        "~43"
      )
      .then(() => {
        this.setState({ elements: [document.getElementById(id)], revised: Date.now() });
        this.controls.menu.open();
      });
  } catch (error) {
    console.error(error, "~50");
    performing.set.updating("error", "~51");
  }
}

export function getElementLayer(layer, from = "~55") {
  unit.report({ method: "getElementLayer", from: from });
  try {
    const { index } = this.element.selected();
    return layer !== undefined ? layer == index : index;
  } catch (_) {
    return null;
  }
}

export function getElementLayers(from = "~65") {
  unit.report({ method: "getElementLayers", from: from });
  try {
    const { spreads } = this.data("~68");
    const spread = this.state?.spread?.number || 0;
    if (!spreads[spread]?.elements) {
      return 0;
    }
    return spreads[spread]?.elements.length || 0;
  } catch (_) {
    return 0;
  }
}
