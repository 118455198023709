/** @format */

import { Functional } from "unit";

const unit = Functional.unit("designer/element");

/* Ready */

export function getElementsReady(spread = 0, from = "~9") {
  unit.report({
    method: "getElementsReady",
    message: "Checks if element is ready for editing.",
    test: "~this needs more description",
    from: from,
  });
  try {
    return Array.isArray(this.state?.spreads[spread]?.elements);
  } catch (_) {
    return false;
  }
}

/* Basic */

export function getElement(index = 0, from = "~25") {
  unit.report({
    method: "getElements",
    from: from,
    message: "Lists elements on selected spread.",
  });
  const spread = this.state.spread.number;
  try {
    const { spreads } = this.data("~33");
    return spreads[spread] ? spreads[spread]?.elements[index] : null;
  } catch (_) {
    return null;
  }
}

export function getElements(spread = null, from = "~40") {
  unit.report({
    method: "getElements",
    from: from,
    message: "Lists elements on selected spread.",
  });
  try {
    if (!spread) spread = this.state?.spread?.number || 0;
    const { spreads } = this.data("~48");
    return spreads[spread] ? spreads[spread].elements : [];
  } catch (error) {
    return [];
  }
}

export function getElementProps(props, prop = null, from = "~55") {
  unit.report({ method: "getElementProps", payload: props, from: from });

  try {
    const data = this.data();
    const spread = this.state?.spread?.number || 0;
    const elements = data.spreads[spread] ? data.spreads[spread].elements : [];

    let searchKey = null;
    if (typeof props === "number" || typeof props === "string") {
      searchKey = "id";
      props = { id: props }; // Convert props to an object for the search
    } else {
      // Determine the search key based on availability and importance
      if ("id" in props) {
        searchKey = "id";
      } else if ("index" in props) {
        searchKey = "index";
      } else if ("number" in props) {
        searchKey = "number";
      }
    }

    if (searchKey) {
      const index = elements.findIndex((el) => el[searchKey] === props[searchKey]);
      const element = getElement.call(this, index); // Get the element using your function

      if (element) {
        if (prop === null) {
          return element; // Return the whole element if prop is null
        } else if (prop in element) {
          return element[prop]; // Return the property of the element if it exists
        }
      }
    }

    return null;
  } catch (_) {
    return null;
  }
}

/* Adding */

export function addElement(element, from = "~99") {
  unit.report({
    method: "addTextElement",
    payload: element,
    action: true,
    analyze: true,
    test: "Creating text should be available in double-click or drag.",
    from: from,
  });
  const { t, errors } = this.props;
  return new Promise((resolve, reject) => {
    try {
      const data = this.data("~111");
      const spread = this.state?.spread?.number || 0;

      if (!data?.spreads[spread]?.elements) return null;

      data.spreads[spread].elements.push(element);

      this.project
        .update(
          {
            data: data,
            note: "Added text element.",
            ai: 0,
          },
          "~125"
        )
        .then(() => {
          this.tool.set("select");
          this.setState({ elements: [...document.querySelectorAll(`[data-id="${element.id}"]`)] });
          resolve(element);
        });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~133");
      reject(error);
    }
  });
}

/* Delete, Removes */

export function deleteElement(confirm = false, from = "~141") {
  unit.report({
    method: "deleteElement",
    action: true,
    analyze: true,
    from: from,
  });

  const { errors, t, workspace, performing } = this.props;

  try {
    // if (!this.element.selected("~152").length > 0) return;

    const data = this.data("~154");
    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected("data", "~156")?.index;

    if (!data.spreads[spread]?.elements[index]) {
      errors.warning(t("warningDeleteElement"));
      return;
    }

    if (!confirm) {
      workspace.confirm.open({
        message: "confirmDeleteElement",
        // cancel: () => console.log("cancelled"),
        confirm: () => deleteElement.call(this, true, "~167"),
      });
      return;
    }

    performing.set.updating("updating", "~172");

    return new Promise((resolve) => {
      data.spreads[spread].elements.splice(index, 1);

      this.element.deselect();

      this.project
        .update(
          {
            data: data,
            note: "Deleted element.",
            ai: 0,
          },
          "~186"
        )
        .then(() => {
          performing.set.updating("success", "~189");
          resolve();
        })
        .catch((error) => {
          performing.set.updating("error", "~193");
          throw error;
        });
    });
  } catch (error) {
    performing.set.updating("error", "~198");
    errors.error(t("errorDeleteElement"), error, "~199");
  }
}

/* Frame */

export function getElementFrameNumber(from = "~205") {
  unit.report({ method: "getElementFrameNumber", from: from });
  try {
    return this.state.frame.number || 0;
  } catch (_) {
    return 0;
  }
}

/* Warnings */

export function getElementWarnings(props, from = "~216") {
  unit.report({ method: "getElementFrameNumber", from: from, payload: props });

  function isOverlap(element, matte) {
    const shrink = 2; // Shrink the element by 1 pixel on each side

    const shrunkElement = {
      left: element.left + shrink,
      top: element.top + shrink,
      width: element.width - 2 * shrink,
      height: element.height - 2 * shrink,
    };

    const conditions = [
      { name: "left overlap", value: shrunkElement.left < matte.left + matte.width },
      { name: "right overlap", value: shrunkElement.left + shrunkElement.width > matte.left },
      { name: "top overlap", value: shrunkElement.top < matte.top + matte.height },
      { name: "bottom overlap", value: shrunkElement.top + shrunkElement.height > matte.top },
    ];

    const isOverlapping = conditions.every((c) => c.value);

    return isOverlapping;
  }

  try {
    const { mattes } = this.state;
    const frame = this.state?.frame?.number || 0;
    const { style, index } = props;

    if (index == 0) return false;

    for (const bleed of mattes.bleeds) {
      if (bleed && Object.keys(bleed).length > 0 && isOverlap(style[frame], bleed)) {
        return "error";
      }
    }

    for (const margin of mattes.margins) {
      if (margin && Object.keys(margin).length > 0 && isOverlap(style[frame], margin)) {
        return "warning";
      }
    }

    return false;
  } catch (_) {
    console.log(_);
    return null;
  }
}

// Check if the element is ready
export function getElementReady(target) {
  const { offsetWidth, offsetHeight } = target;
  return offsetWidth > 0 && offsetHeight > 0;
}
