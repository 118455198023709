/** @format */
import { nanoid } from "nanoid";
import { Editor, Node, Path, Transforms } from "slate";
import { Functional } from "unit";

const unit = Functional.unit("Manuscript");

// Open the insert bubble
export function openAnnotations(type) {
  try {
    // Ensure the editor is loaded
    if (!this.editor.loaded()) return;

    const { workspace } = this.props;
    const { spread } = this.state;

    // Get the editor
    const zoom = workspace.zoom.get();

    const domSelection = window.getSelection();
    if (!domSelection || domSelection.rangeCount < 1) return null;

    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();

    // Assuming 'editorContainer' is the ref to your editor's container
    const containerRect = document.getElementById(`manuscript-${spread.number}`).getBoundingClientRect();

    // Consider zoom level, assuming 'zoomLevel' is the current zoom scale
    const zoomLevel = zoom || 1;

    // Adjusting the position according to the container and zoom level
    const top = (rect.top - containerRect.top) / zoomLevel;
    const left = (rect.left - containerRect.left) / zoomLevel;

    this.setState({ annotations: { top: top + 80, left: left + 160, type } });
  } catch (e) {
    performing.set.updating("error", "~40");
  }
}

// Close the insert bubble
export function closeAnnotations() {
  this.setState({ annotations: null });
}

// Global insert (used from annotations popup)
export function insertAnnotation(type, value) {
  return new Promise((resolve, reject) => {
    try {
      // Shortcut it
      if (type == "character") insertCharacter.call(this, value);
      if (type == "imagery") insertImagery.call(this, value);
      if (type == "comment") insertComment.call(this, value);

      // Tell the annotation popup it went well
      resolve(true);
    } catch (e) {
      // Tell the annotation popup it went wrong
      reject(e);
    }
  });
}

// Update annotations
export function updateAnnotation(value, path) {
  return new Promise((resolve, reject) => {
    try {
      // get the current editor
      const editor = this.editors[this.state.spread.number];

      // We haven't passed it from the observer
      if (!path) path = this.state.annotator.path;

      // We don't what we need to survice
      if (!editor || !path) return reject("Missing editor or path.");

      // Assuming the text is in the first child node of the specified node
      const textNodePath = path.concat([0]); // Path to the text node

      // Updating doesn't seem to work, so pathced with remove/insert
      // Transforms.setNodes(editor, { text: "12345" }, { at: textNodePath });

      // Remove the existing text node
      Transforms.removeNodes(editor, { at: textNodePath });

      // Insert new text at the same location
      Transforms.insertText(editor, value, { at: textNodePath });

      // Give it a moment to move the carat to the right place
      setTimeout(() => {
        // Restore the carat to the appropriate position if saved
        try {
          // Try to move to the node after the current path
          const nextPath = Path.next(path);
          Node.get(editor, nextPath); // Check if node exists

          Transforms.select(editor, Editor.start(editor, nextPath));
        } catch (error) {
          // If there's no next node, try to move to the node before
          try {
            const prevPath = Path.previous(path);
            Node.get(editor, prevPath); // Check if node exists
            Transforms.select(editor, Editor.end(editor, prevPath));
          } catch (_) {}
        }
      }, 100);

      // Tell the observer we updated something
      this.setState({ observer: Date.now() });

      // Tell the world it went well
      resolve(true);
    } catch (e) {
      console.error(e, "~117");
      reject(e);
    }
  });
}

// Characters

export function insertCharacter(value) {
  let testId = unit.report({
    method: "insertTag",
    analyze: true,
    action: true,
    test: "Inserting a tag should show the icon and retain the note info based on who entered it.",
    steps: [
      "From manuscript",
      "Right click spread",
      "Select Insert Note",
      "Type Note",
      "Submit note",
      "Confirm note is entered correctly",
      "Confirm note is saved with refresh",
    ],
  });

  // Make sure we have an editor
  try {
    // Get the editor
    const editor = this.editors[this.state.spread.number];

    // Ensure there's a selection to insert into
    if (!editor.selection) return;

    // Define the inline character node
    const characterNode = {
      type: "character",
      annotation: true,
      isSelected: false,
      meta: {},
      id: nanoid(),
      children: [{ text: value }],
    };

    // Insert the character node at the current selection's focus point
    Transforms.insertNodes(editor, characterNode, { at: editor.selection.focus });
  } catch (error) {
    // tell the user there was an issue
    this.props.performing.set.updating("error", "~174");
  }
}

export function deleteCharacter(element) {
  try {
    const editor = this.editors[this.state.spread.number];

    // Retrieve the path of the target element
    const path = element.path;
    if (!path) return;
    const parentPath = path.slice(0, -1);
    const parent = Node.get(editor, parentPath);

    if (parent.children.length <= 1) {
      Transforms.removeNodes(editor, { at: path });
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        { at: parentPath.concat(0) }
      );
    } else {
      Transforms.removeNodes(editor, { at: path });
    }
  } catch (error) {
    console.error(error);
  }
}

export function canInsertCharacter() {
  const { ui } = this.props;
  return this.focus.get("~208") || ui.menu.opened("manuscript/annotations");
}

// Get the character that is focused
export function getCharacterFocused() {
  return false;
}

// Set the character is focused
export function setCharacterFocused({ path }) {
  try {
    // Get the editor
    const editor = this.editors[this.state.spread.number];

    // set that this is selected
    Transforms.setNodes(editor, { isSelected: true }, { at: path });
  } catch (e) {
    console.log(e);
  }
}

// Imagery

export function canInsertImagery() {
  const { ui } = this.props;
  return this.focus.get("~233") || ui.menu.opened("manuscript/annotations");
}

export function insertImagery(value) {
  let testId = unit.report({
    method: "insertImagery",
    analyze: true,
    action: true,
    test: "Inserting a tag should show the icon and retain the note info based on who entered it.",
    steps: [
      "From manuscript",
      "Right click spread",
      "Select Insert Note",
      "Type Note",
      "Submit note",
      "Confirm note is entered correctly",
      "Confirm note is saved with refresh",
    ],
  });

  // Make sure we have an editor
  try {
    // Get the editor
    const editor = this.editors[this.state.spread.number];

    // Ensure there's a selection to insert into
    if (!editor.selection) return;

    // Define the inline character node
    const characterNode = {
      type: "imagery",
      annotation: true,
      isSelected: false,
      meta: {},
      id: nanoid(),
      children: [{ text: value }],
    };

    // Insert the character node at the current selection's focus point
    Transforms.insertNodes(editor, characterNode, { at: editor.selection.focus });
  } catch (error) {
    // tell the user there was an issue
    this.props.performing.set.updating("error", "~285");
  }
}

export function deleteImagery(element) {
  try {
    const editor = this.editors[this.state.spread.number];

    // Retrieve the path of the target element
    const path = element.path;
    if (!path) return;
    const parentPath = path.slice(0, -1);
    const parent = Node.get(editor, parentPath);

    if (parent.children.length <= 1) {
      Transforms.removeNodes(editor, { at: path });
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        { at: parentPath.concat(0) }
      );
    } else {
      Transforms.removeNodes(editor, { at: path });
    }
  } catch (error) {
    console.error(error);
  }
}

// Get the character that is focused
export function getImageryFocused() {
  return false;
}

// Set the character is focused
export function setImageryFocused({ path }) {
  try {
    // Get the editor
    const editor = this.editors[this.state.spread.number];

    // set that this is selected
    Transforms.setNodes(editor, { isSelected: true }, { at: path });
  } catch (e) {
    console.log(e);
  }
}

// Comments

export function canInsertComment() {
  const { ui } = this.props;
  return this.focus.get("~339") || ui.menu.opened("manuscript/annotations");
}

export function insertComment(value) {
  let testId = unit.report({
    method: "insertComment",
    analyze: true,
    action: true,
    test: "Inserting a tag should show the icon and retain the note info based on who entered it.",
    steps: [
      "From manuscript",
      "Right click spread",
      "Select Insert Note",
      "Type Note",
      "Submit note",
      "Confirm note is entered correctly",
      "Confirm note is saved with refresh",
    ],
  });

  const { user } = this.props;

  // Make sure we have an editor
  try {
    // Get the editor
    const editor = this.editors[this.state.spread.number];

    // Ensure there's a selection to insert into
    if (!editor.selection) return;

    // Define the inline character node
    const characterNode = {
      type: "comment",
      annotation: true,
      isSelected: false,
      meta: {},
      userId: user.id,
      id: nanoid(),
      children: [{ text: value }],
    };

    // Insert the character node at the current selection's focus point
    Transforms.insertNodes(editor, characterNode, { at: editor.selection.focus });

    // Tell the observer we updated something
    this.setState({ observer: Date.now() });

    // Set that the unit report passed
    unit.passed({ testId: testId });
  } catch (error) {
    console.error(error, "~389");

    // unit report failed
    unit.failed({
      testId: testId,
    });

    // tell the user there was an issue
    this.props.performing.set.updating("error", "~397");
  }
}

export function deleteComment(path) {
  try {
    const editor = this.editors[this.state.spread.number];

    // Retrieve the path of the target element
    if (!path) return;

    // Delete it
    const parentPath = path.slice(0, -1);
    const parent = Node.get(editor, parentPath);

    if (parent.children.length <= 1) {
      Transforms.removeNodes(editor, { at: path });
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        { at: parentPath.concat(0) }
      );
    } else {
      Transforms.removeNodes(editor, { at: path });
    }

    // Tell the observer we updated something
    this.setState({ observer: Date.now() });
  } catch (error) {
    // Tell the observer we updated something
    this.setState({ observer: Date.now() });
  }
}

// Get the character that is focused
export function getCommentFocused() {
  return false;
}

// Set the character is focused
export function setCommentFocused({ path }) {
  try {
    // Get the editor
    const editor = this.editors[this.state.spread.number];

    // set that this is selected
    Transforms.setNodes(editor, { isSelected: true }, { at: path });
  } catch (e) {
    console.log(e);
  }
}
