/**
 * Returns an array of all the method names of an object and its prototypes.
 *
 * @format
 * @param {Object} obj - The object to get the methods from.
 * @returns {Array} An array of method names.
 */

/** @format */

const Methods = (obj) => {
	let params = [];
	do {
		const l = Object.getOwnPropertyNames(obj)
			.concat(Object.getOwnPropertySymbols(obj).map((s) => s.toString()))
			.sort()
			.filter(
				(p, i, arr) =>
					typeof obj[p] === "function" &&
					p !== "constructor" &&
					(i == 0 || p !== arr[i - 1]) &&
					params.indexOf(p) === -1
			);
		params = params.concat(l);
	} while ((obj = Object.getPrototypeOf(obj)) && Object.getPrototypeOf(obj));

	return params;
};

export default Methods;
