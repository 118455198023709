/** @format */
import Config from "@Workspace/config";
import capitalize from "capitalize";
import Cookies from "cookies";
import Datattrib from "datattrib";
import { lazy } from "react";
import { Functional } from "unit";

const unit = Functional.unit("workspace/desk");

export default async function setupDesks(from = "~11") {
  let testId = unit.report({
    method: "setupDesks",
    from: "~14",
    message: "All desks for all features should be setup",
    test: "Desks should load without error",
    steps: [
      "From workspace",
      "Select illustration",
      "Select storyboard",
      "Select storyboard",
      "Assure all load without error",
    ],
  });
  return await new Promise((resolve, reject) => {
    try {
      Config.ui.desks.forEach((desk) => {
        let feature = Object.keys(desk)[0];
        let context = desk[feature];
        this.ui.desks[feature] = {
          config: {
            ...Config.usable.config[context],
            icon: Config.features[feature].icon,
            enabled: Config.features[feature].enabled,
            title: Config.features[feature].title,
            feature: feature,
          },
          path: "@Workspace/Features/" + capitalize(feature) + "/desk/",
          component: lazy(() => import(`../../components/Features/components/${capitalize(feature)}/desk/index.jsx`)),
        };
      });
      document.body.setAttribute("data-route", "workspace");
      document.body.setAttribute("data-desk", this.state.settings.desk);
      this.setState({
        desk: this.state.settings.desk,
        feature: this.state.settings.feature,
      });
      unit.passed({ testId: testId });
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error });
      reject(error, "~52");
    }
  });
}

export function getDesk(desk = null, from = "~57") {
  unit.report(
    {
      method: "getDesk",
      from: from,
      message: "Retrieved the desk for the selected feature.",
      test: "The desk on the feature button in the bottom left should match the feature selected.",
      passed: false,
      payload: this.state.settings,
    },
    from
  );
  return desk == null ? this.state.settings.desk : this.state.settings.desk == desk;
}

export function setDesk(desk, from = "~72") {
  const { meta } = this.props.cms;

  let testId = unit.report(
    {
      method: "setDesk",
      payload: { desk: desk },
      from: from,
      test: "After selecting a new feature that has a desk component, the appropriate desk is shown.",
      steps: [],
    },
    from
  );
  try {
    if (this.state.settings.desk == desk) return;
    if (this.ui.desks[desk]) {
      Datattrib.set({ name: "feature", value: desk });
      Datattrib.set({ name: "desk", value: desk });

      Cookies.set("workspace-desk-" + this.id, desk);
      Cookies.set("workspace-feature-" + this.id, desk);

      this.setState((prevState) => ({ settings: { ...prevState.settings, desk: desk, feature: desk } }));

      meta.set({ title: this.ui.desks[desk].config.title, description: this.ui.desks[desk].config.description });

      unit.passed({ testId: testId }, from);
    } else {
      console.error("The desk selected does not exist.", desk);
      unit.failed({ testId: testId }, from);
    }
  } catch (error) {
    unit.passed({ testId: testId }, from);
    console.error(error, "~101");
  }
}

export function getDeskCss(from = "~105") {
  unit.report({
    method: "getDeskCss",
    message: "Returns the desk css.",
    test: "The desktop should look proper at load without resizing.",
    from: from,
  });

  let featureCss = {};

  try {
    featureCss = this.ui.desks[getDesk.call(this)].config.style.desk;
  } catch (error) {
    return;
  }

  let deskCss = {
    ...{
      transform: this.state.settings.zoom == "none" ? "none" : "scale(" + this.state.settings.zoom + ")",
    },
    ...featureCss,
  };

  return deskCss;
}
