/** @format */
import { Box, MenuList } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useWorkspace } from "@Workspace/Context";
import { ComponentError } from "errors";
import key from "key";
import { useLocales } from "locales";
import { useMemo } from "react";
import Step from "./components/Step";

const StyledBox = styled(Box)(() => ({
  "&": {
    padding: "0px",
    width: "100%",
    overflow: "scroll",
  },

  "& .grid": {
    minWidth: "420px",
    maxWidth: "420px",
    width: "420px",
    padding: "40px 20px 20px 20px",
    marginBottom: "20px",
  },

  "& .checklistButton": {
    marginLeft: "0px !important",
    borderRadiusTopLeft: "0px !important",
    borderRadiusBottomLeft: "0px !important",
    borderLeft: "1px solid rgba(12,12,12,.5)",
  },

  "& .waiting": {
    fontStyle: "italic",
  },
  "& .row": {
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  "& .progress": {
    width: "100%",
    position: "absolute",
    bottom: "0px",
    padding: "20px",
    left: "0px",
    backgroundColor: "#121212",
    borderTop: "1px solid rgba(255,255,255,.12)",
  },
  "& .list": {
    marginLeft: "-0px",
  },
}));

function Steps(props) {
  const workspace = useWorkspace();
  const { t } = useLocales();
  const { feature } = props;

  const steps = useMemo(() => {
    return workspace.checklist.get(feature.slug).enabled
      ? workspace.checklist
          .steps(feature.slug)
          .sort((a, b) => a.number - b.number)
          .filter((step) => step.enabled)
      : [];
  }, [workspace, feature.slug]);

  const isEnabled = workspace.checklist.get(feature.slug).enabled;

  return (
    <StyledBox>
      <MenuList sx={{ width: "100%" }}>
        <ComponentError>
          {isEnabled ? (
            steps.map((step, j) => (
              <ComponentError
                {...key("workspace", "menu", "bottom", "features", "checklist", "item", j)}
                message={step.slug}
              >
                <Step {...props} step={step} />
              </ComponentError>
            ))
          ) : (
            <Box className="row waiting" sx={{ textAlign: "center", fontSize: "11px" }}>
              {t("waitingOtherStepsComplete")}
            </Box>
          )}
        </ComponentError>
      </MenuList>
    </StyledBox>
  );
}

export default Steps;
