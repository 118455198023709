/** @format */

export function getPackage(planId) {
  const { user } = this.props;

  if (!user) return null;

  try {
    return planId ? user.billing("planId") == planId : user.billing("planId");
  } catch (e) {
    return null;
  }
}

export function getPackageName() {
  const { user } = this.props;

  if (!user) return null;

  try {
    return getPackage.call(this).split("_")[0];
  } catch (e) {
    return null;
  }
}

export function getPackagePeriod() {
  const { user } = this.props;

  if (!user) return null;

  try {
    return getPackage.call(this).split("_")[1];
  } catch (e) {
    return null;
  }
}

// # TODO - we don't need this anymore
export function setPackage(planId) {}
