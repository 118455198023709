/** @format */

import { auth } from "firemade";

export default function authProviderData(providerId) {
  if (!auth.currentUser) return;

  const providerData = auth.currentUser.providerData;

  if (!providerData) return {};

  if (providerId) return providerData.find((authProvider) => authProvider.providerId === providerId);

  return providerData;
}
