/** @format */
import { Box, Hidden, Stack, styled } from "@mui/material";
import { withLocales } from "locales";
import { Component as ReactComponent } from "react";
import Loader from "../Loader";

import { exception } from "sentry";

const StyledApp = styled("div")(() => ({
  "& .wordmark": { textAlign: "left", display: "block", margin: "0px" },
  "& .wordmark .made": { color: "#468189" },
  "& .wordmark .live": { color: "#9dbebb" },
  "& .body": { margin: "10px 0px", fontSize: "11px" },
}));

class Page extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error) {
    this.setState({ error: true });
    try {
      exception(error);
    } catch (_) {}
  }

  componentDidMount() {
    if (this.props.error && this.props.from) {
      this.setState({ error: true });
    }
  }

  render() {
    const { children, sx = {} } = this.props;
    const { t } = this.props;

    if (this.state.error) {
      return (
        <StyledApp sx={{ margin: "10%", ...sx }}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Hidden smDown>
              <Loader width={200} from="~49" />
            </Hidden>
            <Box>
              <h1 className="wordmark">
                <span className="made">made</span>
                <span className="live">live</span>
              </h1>
              <Box className="body">{t("pageError")}</Box>
            </Box>
          </Stack>
        </StyledApp>
      );
    }

    return children;
  }
}

export default withLocales(Page);
