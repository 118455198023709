/** @format */

export function getAvatar() {
  return this.state.photoURL || null;
}

export function setAvatar(avatar) {
  return new Promise((resolve, reject) => {
    this.authentication
      .setAvatar(avatar)
      .then((url) => {
        this.setState({
          photoURL: url,
        });
        resolve(url);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeAvatar() {
  return new Promise(async (resolve, reject) => {
    try {
      await this.authentication.removeAvatar();
      this.setState({
        photoURL: null,
      });
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
}
