/** @format */
import { Functional } from "unit";

const unit = Functional.unit("manuscript/buttons");

export function ButtonConfig(from = "~6") {
  unit.report({
    method: "buttonConfig",
    test: "Configures all the buttons for the manuscript workspace.",
    message: "Buttons should load and look correct and should work accordingly",
    from: from,
  });
  const { ui, workspace, billing } = this.props;

  return {
    /* Undo */
    undo: {
      enabled: () => {
        try {
          return this.canDo("undo");
        } catch (error) {
          return false;
        }
      },
      onClick: () => {
        this.undo();
      },
    },

    /* redo */
    redo: {
      enabled: () => {
        try {
          return this.canDo("redo");
        } catch (error) {
          return false;
        }
      },
      onClick: () => {
        this.redo();
      },
    },

    /* share */
    share: {
      enabled: () => {
        return true;
      },
      onClick: () => {
        ui.dialog.open("share", { from: "manuscript" });
      },
    },

    /* zoomin */
    zoomIn: {
      enabled: () => {
        return this.props.workspace.zoom.can("in");
      },
      onClick: () => {
        this.props.workspace.zoom.set("in");
      },
    },

    /* zoom out */
    zoomOut: {
      enabled: () => {
        return this.props.workspace.zoom.can("out");
      },
      onClick: () => {
        this.props.workspace.zoom.set("out");
      },
    },

    /* heading */
    heading: {
      enabled: ({ block }) => {
        return this.focus.get("~77") && this.block.available(block);
      },
      onClick: (event, { block }) => {
        try {
          event.preventDefault();
          unit.report({
            method: "heading",
            event: block + "Button",
            analyze: true,
            action: true,
          });
          this.block.toggle(block);
        } catch (_) {
          console.error(_);
        }
      },
      selected: ({ block }) => {
        return this.block.active(block);
      },
    },

    /* bold */
    bold: {
      enabled: ({ mark }) => {
        return this.focus.get("~101") && this.mark.available(mark);
      },
      onClick: (event, { mark }) => {
        event.preventDefault();
        unit.report({
          method: "bold",
          event: mark + "Button",
          analyze: true,
          action: true,
        });
        this.mark.toggle(mark);
      },
      selected: ({ mark }) => {
        return this.mark.active(mark);
      },
    },

    /* italic */
    italic: {
      enabled: ({ mark }) => {
        return this.focus.get("~121") && this.mark.available(mark);
      },
      onClick: (event, { mark }) => {
        event.preventDefault();
        unit.report({
          method: "italic",
          event: mark + "Button",
          analyze: true,
          action: true,
        });
        this.mark.toggle(mark);
      },
      selected: ({ mark }) => {
        return this.mark.active(mark);
      },
    },

    /* underline */
    underline: {
      enabled: ({ mark }) => {
        return this.focus.get("~141") && this.mark.available(mark);
      },
      onClick: (event, { mark }) => {
        event.preventDefault();
        unit.report({
          method: "underline",
          event: mark + "Button",
          analyze: true,
          action: true,
        });
        this.mark.toggle(mark);
      },
      selected: () => {
        return this.mark.active(mark);
      },
    },

    /* transfer */
    storyboard: {
      enabled: () => {
        try {
          const { billed } = billing;
          const { breaks } = this.transfer.review();
          return billed.feature("storyboard") && breaks > 3;
        } catch (e) {
          console.error(e);
        }
      },
      onClick: () => {
        workspace.feature.select({ feature: "manuscript", element: "dialog", component: "transfer" });
      },
    },

    /* Spread Break */
    break: {
      enabled: () => {
        // Move the focus check here, because focus is lost on butto click
        return this.focus.get("~178") && this.markers.break.can();
      },
      onClick: () => {
        //  Insert the break
        this.markers.break.insert("~182");
      },
    },

    /* Bookmark */
    bookmark: {
      enabled: () => {
        // Move the focus check here, because focus is lost on butto click
        return this.focus.get("~190") && this.markers.bookmark.can();
      },
      onClick: () => {
        this.markers.bookmark.insert("~193");
      },
    },

    /* Annotations/Tags */
    annotations: {
      enabled: () => {
        return this.focus.get("~200") || ui.menu.opened("manuscript/annotations");
      },
      onClick: () => {
        const { ui } = this.props;
        ui.menu.toggle("manuscript/annotations");
      },
    },

    // Characters
    character: {
      enabled: () => {
        return this.focus.get("~211") || ui.menu.opened("manuscript/annotations");
      },
      onClick: () => {
        const { ui } = this.props;
        this.annotations.open("character");
        ui.menu.toggle("manuscript/annotations");
      },
    },

    // Imagery
    imagery: {
      enabled: () => {
        return this.annotations.imagery.can();
      },
      onClick: () => {
        const { ui } = this.props;
        this.annotations.open("imagery");
        ui.menu.toggle("manuscript/annotations");
      },
    },

    // Comment
    comment: {
      enabled: () => {
        return this.focus.get("~235") || ui.menu.opened("manuscript/annotations");
      },
      onClick: () => {
        const { ui } = this.props;
        this.annotations.open("comment");
        ui.menu.toggle("manuscript/annotations");
      },
    },

    /* Marker */
    markers: {
      enabled: () => {
        return false;
        // return this.focus.get("~248");
      },
      onClick: () => {
        this.inserts.open("markers");
      },
    },
  };
}
