/**
 * Broadcast component that allows components to send and receive messages to one another.
 *
 * function MyComponent(props) {
 *   const { send, listen } = props.broadcast;
 *   useEffect(() => {
 *     listen();
 *   }, []);
 *   return (
 *     <button onClick={() => send({ message: 'Hello World!' })}>
 *       Send Message
 *     </button>
 *   );
 * }
 *
 * export default withBroadcast(MyComponent);
 *
 * @format
 * @module Broadcast
 * @example import Broadcast, { withBroadcast } from './Broadcast';
 */

/** @format */
import { Component as ReactComponent, createContext } from "react";

import { listen, send, setup } from "./helpers/All";

const BroadcastContext = createContext({});

class Broadcast extends ReactComponent {
  constructor(props) {
    super(props);
    this.timestamp = Date.now();
    this.timeout = 200000;
    this.sessions = {
      check: [],
      active: [],
    };

    this.state = { timestamp: this.timestamp };
    this.log = [];

    this.setup = setup.bind(this);
    this.send = send.bind(this);
    this.listen = listen.bind(this);

    // Hold the function for the class hooks
    this.functions = {};
  }

  render() {
    return (
      <>
        <BroadcastContext.Provider value={{ ...this.state, setup: this.setup, send: this.send, listen: this.listen }}>
          {this.props.children}
        </BroadcastContext.Provider>
      </>
    );
  }
}

const withBroadcast = (Component) => {
  return function ContextualComponent(props) {
    return (
      <BroadcastContext.Consumer>{(state) => <Component {...props} broadcast={state} />}</BroadcastContext.Consumer>
    );
  };
};

export default Broadcast;
export { withBroadcast };
