/** @format */

import { isbot } from "isbot";
import { Offline, Online } from "react-detect-offline";
import OfflineComponent from "./components/Offline";

export default function ({ children }) {
  if (isbot(navigator.userAgent)) {
    console.log("Bot detected. Skipping offline detection.");
    return children;
  }
  return (
    <>
      <Online>{children}</Online>
      <Offline>
        <OfflineComponent />
      </Offline>
    </>
  );
}
