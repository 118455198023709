/** @format */

import { TinyError } from "errors";
import Timeago from "timeagoml";

export default function (props) {
  return (
    <TinyError>
      <BlogDate {...props} />
    </TinyError>
  );
}

export function BlogDate(props) {
  let { updatedAt } = props;

  return updatedAt ? <Timeago data-testid="blog date" timestamp={updatedAt} /> : null;
}
