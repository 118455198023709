/** @format */
import { unlink } from "firebase/auth";
import { auth } from "firemade";

export default function unlinkAuthProvider(providerId) {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser; // Get current user

    if (!providerId) return reject(new Error("No provider ID"));

    if (!currentUser) return reject(new Error("No current user"));

    unlink(currentUser, providerId)
      .then((value) => {
        resolve(value);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
