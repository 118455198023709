/**
 * Replaces a CDN URL with a new URL and removes any query parameters and "thumbnail_" prefixes.
 *
 * @format
 * @param {string} url - The original CDN URL to be replaced.
 * @returns {string} The new URL with replaced CDN and removed query parameters and "thumbnail_" prefixes.
 */

import config from "./config";

// Deals with cdn urls
export default function cdn(url, s = null) {
  try {
    if (url.includes("?")) url = url.split("?")[0];

    // get the image
    let image = url.replace(config.from, config.to).replace(/([^:]\/)\/+/g, "$1");

    image = image.replaceAll("thumbnail_", "");

    if (s == null) return image;

    return size(image, s);
  } catch (_) {
    return url;
  }
}

// Dynamically size images from the cdn
export function size(url, size = null) {
  try {
    // If size is null, return the original URL
    if (size === null) return url;

    // Ensure the URL does not have query parameters
    url = url.split("?")[0];

    // Replace single // not part of the protocol with /
    url = url.replace(/([^:]\/)\/+/g, "$1");

    // Add size information behind each / except the protocol
    const parts = url.split("/");
    for (let i = 3; i < parts.length; i++) {
      // Start from 3 to skip protocol and domain
      parts[i] = size + "_" + parts[i];
    }
    url = parts.join("/");

    return url;
  } catch (_) {
    // In case of any error, return an empty string or log the error as needed
    return url;
  }
}

/**
 * Generates fallback alt text from a URL by stripping out non-letter characters,
 * domain, subdomain, protocol, and file extension.
 *
 * @function alt
 * @param {string} url - The URL to generate the alt text from.
 * @returns {string} - The generated fallback alt text.
 */
export function alt(url) {
  try {
    // Extract the last segment of the path from the URL
    const pathSegments = new URL(url).pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    // Remove file extension
    const segmentWithoutExtension = lastSegment.replace(/\.[^/.]+$/, "");

    // Replace non-letter characters with space
    const cleanedSegment = segmentWithoutExtension.replace(/[^a-zA-Z]/g, " ");

    // Remove words less than three characters
    const longWordsOnly = cleanedSegment
      .split(" ")
      .filter((word) => word.length >= 3)
      .join(" ");

    // Convert multiple spaces to a single space and trim leading/trailing spaces
    const altText = longWordsOnly.replace(/\s+/g, " ").trim();

    return altText;
  } catch (_) {
    return "";
  }
}
