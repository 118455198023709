/** @format */
import Cookies from "cookies";
import i18n from "i18next";
import { PureComponent, createContext, useContext } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getLocale, getLocales, setLocale } from "./helpers/Locale";
import { getDefault, getSupported } from "./helpers/Ssr";
import { getLocalizedUrl, getParamizedUrl } from "./helpers/Url";

import config from "../config/index.json";

import isSSR from "isssr";

// THIS COMPONENT NEEDS TO BE REFACTORED FOR SSR

const LocalesContext = createContext({});

/**
 * Represents a component for managing locales and internationalization.
 */
class Locales extends PureComponent {
  constructor(props) {
    super(props);

    this.supported = [];

    // Set the default initial locale
    let initialLocale = isSSR ? props.locale : config.default;

    // Boil down the config to only the enabled locales
    const supportedLocales = config.supported.filter((locale) => locale.enabled).map((locale) => locale.code); // Extracts codes of enabled locales

    // Extract the first part of the path as potential locale
    const pathLocale = isSSR ? config.default : document.location.pathname.split("/")[1];

    if (!isSSR) {
      // Check if the pathLocale is in the supported list
      if (supportedLocales.includes(pathLocale)) {
        initialLocale = pathLocale;
        Cookies.set("locale", pathLocale);
        // If pathLocale is not valid, check the cookie
      } else if (this.supported.includes(Cookies.get("locale"))) {
        initialLocale = Cookies.get("locale");
      }
    }

    this.state = { ready: isSSR, locale: initialLocale };

    this.set = setLocale.bind(this);

    this.locale = {
      get: getLocale.bind(this),
    };

    this.locales = {
      get: getLocales.bind(this),
    };

    this.url = getLocalizedUrl.bind(this);

    this.paramize = getParamizedUrl.bind(this);

    // Manually run this in ssr
    if (isSSR) this.setup();
  }

  async setup() {
    try {
      let files;

      const enabledLanguages = config.supported.filter((lang) => lang.enabled);

      if (!isSSR) {
        files = await Promise.all(
          enabledLanguages
            .filter((locale) => locale.code == this.state.locale) // load only the selected locale
            .map(async (lang) => {
              const { code } = lang;
              const content = await import(`../config/${code}.json`);
              return { [code]: { translation: content.default } };
            })
        );
      } else {
        files = [{ [this.props.locale]: { translation: this.props.config } }];

        // This is a fallback -
        this.t = (slug) => {
          return this?.props?.config[slug] || slug;
        };
      }

      // Transform the array into an object for i18next
      const resources = Object.assign({}, ...files);

      // Get the enabled languages
      this.supported = enabledLanguages.map((lang) => lang.code);

      i18n
        .use(initReactI18next)
        .init({
          fallbackLng: config.default,
          lng: this.state.locale,
          debug: false,
          lowerCaseLng: true,
          resources,
        })
        .then(() => {
          i18n.languages = Object.keys(this.supported);
          this.t = i18n.t;
          this.setState({
            ready: true,
            locale: this.state.locale || Cookies.get("locale"),
          });
        });
    } catch (error) {
      throw new Error(error);
    }
  }

  componentDidMount() {
    this.setup();
  }

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <LocalesContext.Provider
          value={{
            ...this.state,
            t: this.t || ((slug) => `${slug}`), // THERES A PROBLEM IN HERE
            set: this.set,
            locale: this.locale,
            locales: this.locales,
            url: this.url,
            paramize: this.paramize,
          }}
        >
          {this.state.ready && this.props.children}
        </LocalesContext.Provider>
      </I18nextProvider>
    );
  }
}

/**
 * Higher-order component that provides the locales context to the wrapped component.
 *
 * @param {React.Component} Component - The component to be wrapped.
 * @returns {React.Component} - The wrapped component with locales context.
 */
const withLocales = (Component) => (props) => {
  return (
    <LocalesContext.Consumer>{(state) => <Component {...props} locales={state} t={state.t} />}</LocalesContext.Consumer>
  );
};

/**
 * Custom hook that returns the LocalesContext value.
 * @returns {Object} The LocalesContext value.
 */
const useLocales = () => {
  return useContext(LocalesContext);
};

export default Locales;
export { getDefault, getSupported, useLocales, withLocales };
