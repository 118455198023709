/** @format */

/**
 * Retrieves the first name of a user based on their ID.
 * @param {string} id - The ID of the user.
 * @returns {string} The first name of the user.
 */
export function getFirstName(id) {
  let displayName = this.getField(id, "displayName");
  return displayName.split(" ")[0];
}

/**
 * Retrieves the last name from a user's display name.
 *
 * @param {string} id - The ID of the user.
 * @returns {string} The last name of the user.
 */
export function getLastName(id) {
  let displayName = this.getField(id, "displayName");
  let names = displayName.split(" ");
  return names.length > 1 ? names[names.length - 1] : "";
}

/**
 * Retrieves the display name for a given ID.
 *
 * @param {string} id - The ID of the entity.
 * @returns {string} The display name associated with the ID.
 */
export function getDisplayName(id) {
  return this.getField(id, "displayName");
}

/**
 * Retrieves the initials of a profile based on the provided ID.
 * @param {string} id - The ID of the profile.
 * @returns {string} - The initials of the profile.
 */
export function getInitials(id) {
  try {
    return this.getFirstName(id).charAt(0) + this.getLastName(id).charAt(0);
  } catch (error) {
    return "";
  }
}
