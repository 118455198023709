/** @format */

export function onWarp(event) {
  const { t } = this.props;

  if (!event?.target) return;

  const transform = this.element.style.transform(event.transform, "~19");

  try {
    this.element.style.set(
      {
        event: event,
        label: t("spooky"),
        style: {
          transform: { matrix3d: transform.matrix3d },
        },
      },
      "~30"
    );
  } catch (error) {
    console.error(error, "~33");
  }
}
