/** @format */

import { Box, IconButton, Stack, TextField } from "@mui/material";
import axios from "axios";
import { MD5 } from "crypto-js";
import { stamp } from "dateml";
import DynamicIcon from "dynamicicon";
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore"; // Ensure you're importing the correct methods
import { db } from "firemade";
import key from "key";
import { useLocales } from "locales";
import { nanoid } from "nanoid";
import { useNotifications } from "notifications";
import { Working, usePerforming } from "performing";
import Profiles from "profiles";
import { useEffect, useState } from "react";
import { useUI } from "ui";
import { Avatar, useUser } from "user";

import Message from "./components/Message";

export default function ({
  enabled,
  placeholder,
  assist = true,
  moderate = true,
  slug,
  replies,
  run = true,
  comment = true,
  callback = () => {},
  onClick = null,
  showTitle = false,
  showControls = true,
  showFlagged = true,
  showTopical = true,
  showApproved = true,
  messageId = null,
  hover = false,
  messageAvatar = 50,
  replyAvatar = 40,
  link,
}) {
  // This should move to the config
  const collection = "discussion";

  // State for the messages array
  const [messages, setMessages] = useState([]);

  // State for the message input
  const [messageInput, setMessageInput] = useState("");

  // When we're ready to go
  const [ready, setReady] = useState(false);

  // Update the docId
  const [docId, setDocId] = useState(MD5(slug).toString());

  // Get the locales
  const { t } = useLocales();

  // Get the user
  const { authenticated, id: userId } = useUser();

  // Get the notification
  const { notify } = useNotifications();

  // Get the ui
  const ui = useUI();

  // Get the performing
  const { set, is } = usePerforming();

  // Working
  const working = is.updating();

  // Assign the link if the slug is no good
  if (!link) link = slug;

  // This will assist with analyzing/automating
  const onAssist = async ({ id, messageId, message }) => {
    try {
      // Set made live assist on it
      await axios.post("/api/discussion", {
        id,
        userId,
        messageId,
        message,
        slug,
        assist,
        run,
      });
    } catch (e) {}
  };

  // Authenticate and open UI for login
  const onSelect = () => {
    if (!authenticated) {
      ui.confirm.open({
        title: t("commentsLoginTitle"),
        text: t("commentsLoginText"),
        dismiss: {
          onClick: () => {
            ui.confirm.close();
          },
          text: t("cancel"),
        },
        confirm: {
          onClick: () => {
            ui.dialog.open("signin");
          },
          text: t("continue"),
        },
      });
    }
  };

  // Handle the submit
  const onCreate = async () => {
    if (messageInput && authenticated) {
      // Set that we're working
      set.updating("true");

      // Create a messageId
      const messageId = nanoid();

      try {
        const newMessage = stamp({
          id: messageId,
          title: "",
          message: messageInput,
          userId: userId,
          replyId: null,
          approved: moderate ? false : true,
          flagged: false,
          edited: false,
          generated: false,
        });

        const docRef = doc(db, collection, docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Document exists, append the new message
          await updateDoc(
            docRef,
            stamp({
              messages: [...docSnap.data().messages, newMessage],
            })
          );
        } else {
          // Document doesn't exist, create a new one
          await setDoc(
            docRef,
            stamp({
              link: link + "/" + docId,
              messages: [newMessage],
              slug,
              open: true,
            })
          );
        }

        // Clear the message input field after successful submission
        setMessageInput("");

        // Set that we're working
        set.updating("success");

        try {
          callback(docId);
        } catch (e) {}

        // Assist with analyzing/automating
        if (assist)
          await onAssist({
            id: docId,
            messageId,
            message: messageInput,
          });
      } catch (error) {
        // Set that we're working
        set.updating("failed");

        try {
          callback(null);
        } catch (e) {}
      }
    }
  };

  // Handle message editing
  const onEdit = async (id, message) => {
    const docRef = doc(db, "discussion", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const messages = docSnap.data().messages;
      const messageToEdit = messages.find((msg) => msg.id === id);

      if (messageToEdit) {
        set.updating("true"); // Indicate that an update is in progress

        try {
          // Update the message text and the updated timestamp
          const updatedMessages = messages.map((msg) =>
            msg.id === id
              ? stamp({
                  ...msg,
                  message: message,
                  edited: true,
                })
              : msg
          );

          // Update the document with the updated messages
          await updateDoc(
            docRef,
            // Timestamp the update
            stamp({
              messages: updatedMessages,
            })
          );

          // Set the state to reflect the updated messages
          setMessages(updatedMessages);

          set.updating("success"); // Update was successful
        } catch (error) {
          console.error("Failed to edit message:", error);
          set.updating("failed"); // Indicate that the update failed
        }
      }
    }
  };

  // Authenticate and open UI for login
  const onDelete = (id) => {
    if (!id) return;

    ui.confirm.open({
      title: t("commentsDeleteTitle"),
      text: t("commentsDeleteText"),
      dismiss: {
        onClick: () => {
          ui.confirm.close();
        },
        text: t("cancel"),
      },
      confirm: {
        color: "warning",
        onClick: async () => {
          set.updating("true"); // Indicate that an update is in progress

          try {
            const docRef = doc(db, collection, docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              // Filter out the message to delete
              const updatedMessages = docSnap.data().messages.filter((msg) => msg.id !== id);

              // Update the document with the filtered messages
              await updateDoc(
                docRef,
                stamp({
                  messages: updatedMessages,
                })
              );

              // Set the state to reflect the updated messages
              setMessages(updatedMessages);

              set.updating("success"); // Update was successful
            }
          } catch (error) {
            console.error("Failed to delete message:", error);
            set.updating("failed"); // Indicate that the update failed
          }

          ui.confirm.close();
        },
        text: t("continue"),
      },
    });
  };

  // Handle the submit
  const onReply = async (id, message) => {
    if (message && authenticated) {
      // Set that we're working
      set.updating("true");

      const replyTo = messages.find((message) => message.userId == userId);

      try {
        const newMessage = stamp({
          id: nanoid(),
          title: "",
          message: message,
          userId: userId,
          replyId: id,
          approved: moderate ? false : true,
          sentiment: null,
          flagged: false,
          edited: false,
          generated: false,
          topical: false,
        });

        const docRef = doc(db, collection, docId);
        const docSnap = await getDoc(docRef);

        // Document exists, append the new message
        await updateDoc(
          docRef,
          // timestamp the document
          stamp({
            messages: [...docSnap.data().messages, newMessage],
          })
        );

        // Set that we're working
        set.updating("success");

        // Assist with analyzing/automating
        if (assist)
          await onAssist({
            id: docId,
            messageId: id,
            message: messageInput,
          });

        // Send a notification
        if (replyTo) {
          // Get the replyTo user id
          const { userId: replyToUserId } = replyTo;

          await notify({
            slug: docId,
            userId: userId,
            title: "weHaveAnAnswer",
            message: message.trim(),
            action: null,
            link: `${link}`,
            icon: "fa-comment-alt-lines",
            formats: ["web"],
            read: false,
            followed: false,
            sent: false,
            meta: {},
            attachments: [],
          });

          // Notify
          console.log(userId, replyToUserId);
        }
      } catch (error) {
        console.log(error, "~359");
        // Set that we're working
        set.updating("failed");
      }
    }
  };

  // When the slug changes, update the doc id
  useEffect(() => {
    // Set the latest docId
    setDocId(MD5(slug).toString());
  }, [slug]);

  // Subscription to the messages for a specific slug
  useEffect(() => {
    if (docId) {
      const docRef = doc(db, collection, docId);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setMessages(data.messages || []);
        } else {
          setMessages([]);
        }
        setReady(true);
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [docId]);

  // This is enabled and we've loaded it
  if (!enabled || !docId) return null;

  // Filter the messages
  const filteredMessages = messages.filter((message) =>
    messageId ? message.id == messageId : message.replyId === null
  );

  return (
    <>
      {comment && (
        <Stack direction="row" spacing={3} sx={{ margin: "10px 0 0" }}>
          <Box sx={{ minWidth: "60px" }}>
            <Avatar size={50} />
          </Box>
          <TextField
            name="message"
            multiline
            rows={4}
            variant="outlined"
            placeholder={t(placeholder || "writeAComment")}
            fullWidth
            onClick={onSelect}
            onChange={(e) => setMessageInput(e.target.value)} // Update the state on change
            value={messageInput} // Set the value to the messageInput state
            disabled={working}
            InputProps={{
              endAdornment: (
                <IconButton
                  color="success"
                  disabled={!authenticated || messageInput.length < 4 || working}
                  onClick={onCreate}
                >
                  <DynamicIcon icon={"fa-paper-plane"} />
                </IconButton>
              ),
            }}
          />
        </Stack>
      )}
      <Box sx={{ margin: "20px 0" }}>{working && <Working />}</Box>
      <Profiles>
        {filteredMessages.map((message) => (
          <Message
            {...key("discussion", "message", message.id)}
            {...message}
            docId={docId}
            messages={messages}
            onDelete={onDelete}
            onEdit={onEdit}
            onReply={onReply}
            replies={replies}
            onClick={onClick}
            showControls={showControls}
            showFlagged={showFlagged}
            showTopical={showTopical}
            showTitle={showTitle}
            showApproved={showApproved}
            messageAvatar={messageAvatar}
            replyAvatar={replyAvatar}
            hover={hover}
          />
        ))}
      </Profiles>
      {!ready ? (
        <Box sx={{ padding: "10%", textAlign: "center" }}>
          <Working type="circular" />
        </Box>
      ) : (
        filteredMessages.length == 0 && <Box sx={{ textAlign: "center", padding: "5%" }}>{t("noCommentsYet")}</Box>
      )}
    </>
  );
}
