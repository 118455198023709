/** @format */
import Container from "@Web/Container";
import Main from "@Web/Layouts/Main";
import { Box, Grid, Hidden } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { withCms } from "cms";
import { ContactCard, Content } from "./components";

const CompanyTerms = (props) => {
  const theme = useTheme();
  return (
    <Main>
      <Container sx={{ marginTop: "120px" }}>
        <Box boxShadow={4} borderRadius={2}>
          <Container
            style={{ backgroundColor: "#FFF" }}
            paddingTop={"0 !important"}
            paddingX={{ xs: 2, sm: 4 }}
            position={"relative"}
            top={0}
          >
            <Box component={Grid} container spacing={4} flexDirection={{ xs: "column-reverse", md: "row" }}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Hidden mdUp>
                  <ContactCard />
                </Hidden>
                <Content {...props} />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box position={"sticky"} top={theme.spacing(10)} className={"sticky"}>
                    <ContactCard />
                  </Box>
                </Grid>
              </Hidden>
            </Box>
          </Container>
        </Box>
      </Container>
    </Main>
  );
};

export default withCms(CompanyTerms);
