/** @format */
import Container from "@Web/Container";
import { AppBar, Box, Divider } from "@mui/material";
import { Component as ReactComponent } from "react";

import { withCms } from "cms";
import Datattrib from "datattrib";
import { PageError, TinyError } from "errors";
import { Chat as GetResponseChat } from "getresponse";
import LazyBg from "lazybg";
import { withLocales } from "locales";
import { withPerforming } from "performing";
import { withMediaQuery, withUI } from "ui";

import pages from "../../../navigation";

import Footer from "../../Footer";
import Sidebar from "../../Sidebar";
import Topbar from "../../Topbar";

class Main extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
    };
    this.theme = props.theme;
    this.isMd = withMediaQuery(props.theme.breakpoints.up("md"), {
      defaultMatches: true,
    });
  }

  open = () => {
    this.setState({ sidebar: true });
  };

  close = () => {
    this.setState({ sidebar: false });
  };

  componentDidMount() {
    Datattrib.set({ name: "layout", value: "main" });
  }

  render() {
    const { theme, children, colorInvert, bgcolor, footer = true } = this.props;
    return (
      <>
        <AppBar
          position={"sticky"}
          sx={{
            top: 0,
            minWidth: "100%",
          }}
          elevation={this.state.elevate ? 1 : 0}
        >
          <TinyError>
            <Topbar {...this.props} onSidebarOpen={this.open} pages={pages} colorInvert={colorInvert} />
          </TinyError>
        </AppBar>
        <TinyError>
          <Sidebar onClose={this.close} open={this.state.sidebar} variant="temporary" pages={pages} />
        </TinyError>
        <Box component="main">
          <PageError sx={{ margin: "200px" }}>{children}</PageError>
          <Divider />
          <LazyBg />
        </Box>
        {footer && (
          <Container
            paddingY={0}
            style={{
              backgroundColor: !bgcolor ? theme.palette.common.white : bgcolor,
              minWidth: "100%",
            }}
          >
            <TinyError>
              <Footer {...this.props} pages={pages} />
            </TinyError>
          </Container>
        )}

        <GetResponseChat />
      </>
    );
  }
}

export default withUI(withCms(withPerforming(withLocales(Main))));
