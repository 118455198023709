/** @format */
import { lazy } from "react";

const TestBoard1 = lazy(() => import("../components/TestBoard1/index.jsx"));
const TestBoard2 = lazy(() => import("../components/TestBoard2/index.jsx"));
const TestBoard3 = lazy(() => import("../components/TestBoard3/index.jsx"));

const routes = [
  {
    path: "testboard",
    element: <TestBoard1 />,
    children: [
      {
        path: ":param1",
        element: <TestBoard1 />,
        children: [
          {
            path: ":param2",
            element: <TestBoard1 />,
          },
        ],
      },
    ],
  },
  {
    path: "testboard1",
    element: <TestBoard1 />,
    children: [
      {
        path: ":param1",
        element: <TestBoard1 />,
        children: [
          {
            path: ":param2",
            element: <TestBoard1 />,
          },
        ],
      },
    ],
  },
  {
    path: "testboard2",
    element: <TestBoard2 />,
    children: [
      {
        path: ":param1",
        element: <TestBoard2 />,
        children: [
          {
            path: ":param2",
            element: <TestBoard2 />,
          },
        ],
      },
    ],
  },
  {
    path: "testboard3",
    element: <TestBoard3 />,
    children: [
      {
        path: ":param1",
        element: <TestBoard3 />,
        children: [
          {
            path: ":param2",
            element: <TestBoard3 />,
          },
        ],
      },
    ],
  },
];

export default routes;
