/** @format */
import Container from "@Web/Container";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Cdn from "cdn";
import { useLocales } from "locales";

const Hero = () => {
  const theme = useTheme();
  const { t } = useLocales();
  return (
    <Box
      position={"relative"}
      data-lazybg={Cdn(
        "https://us-central.cdn.made.live//thumbnail_newsroom_blog_59d41de203/thumbnail_newsroom_blog_59d41de203.png?width=1728&height=864"
      )}
      data-testid="hero-box"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: -13,
        paddingTop: 13,
        "&:after": {
          position: "absolute",
          content: '" "',
          width: "100%",
          height: "100%",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          background: "#161c2d",
          opacity: 0.6,
        },
      }}
    >
      <Container
        zIndex={3}
        position={"relative"}
        minHeight={{ xs: 300, sm: 400, md: 600 }}
        maxHeight={600}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box width={1}>
          <Box marginBottom={2}>
            <Typography
              variant="h1"
              align={"center"}
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              {t("newsroom")}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              align={"center"}
              sx={{
                color: theme.palette.common.white,
              }}
            >
              {t("latestUpdates")}
            </Typography>
          </Box>
          {/* <Box padding={2} width={1} component={Card} boxShadow={4} marginTop={4}>
            <form noValidate autoComplete="off">
              <Box display="flex" alignItems={"center"}>
                <Box width={1} marginRight={1}>
                  <TextField
                    sx={{
                      height: 54,
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "0 !important",
                      },
                    }}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    placeholder={locales.t("searchArticles")}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            component={"svg"}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                            color={"primary.main"}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    sx={{ height: 54, minWidth: 100, whiteSpace: "nowrap" }}
                    variant="contained"
                    color="primary"
                    size="medium"
                    fullWidth
                  >
                    Search
                  </Button>
                </Box>
              </Box>
            </form>
          </Box> */}
        </Box>
      </Container>
      <Box
        component={"svg"}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        width={1}
        maxHeight={120}
        bottom={0}
        position={"absolute"}
        zIndex={2}
      >
        <path fill={theme.palette.background.paper} d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
      </Box>
    </Box>
  );
};

export default Hero;
