/** @format */

import Config from "@Workspace/config";
import capitalize from "capitalize";
import { lazy } from "react";
import { Functional } from "unit";

const unit = Functional.unit("workspace/dialogs");

export default async function setupDialogs(from = "~10") {
  let testId = unit.report({
    method: "setupDesks",
    message: "Dialogs should load without error",
    from: "12",
  });
  return new Promise(async (resolve, reject) => {
    this.props.ui.add
      .dialogs(["share", "intro", "feature"])
      .then((response) => {
        unit.passed({ testId: testId, response: response });
        resolve(true);
      })
      .catch((error) => {
        unit.failed({ testId: testId, error: error });
        reject(error);
      });
    Object.keys(Config.ui.dialogs).forEach((feature) => {
      if (!this.ui.dialogs[feature]) this.ui.dialogs[feature] = {};
      Config.ui.dialogs[feature].forEach((dialog) => {
        this.ui.dialogs[feature][dialog] = lazy(() =>
          import(`../../components/Features/components/${capitalize(feature)}/dialogs/${capitalize(dialog)}/index.jsx`)
        );
      });
    });
  });
}

export function openDialog(dialog, props = {}, from = "~38") {
  let testId = unit.report({
    method: "setDialog",
    payload: { dialog: dialog },
    test: "A dialog expected to open, should do so correctly and present the appropriate content. A visual inspection should be made.",
    steps: [
      "From workspace",
      "From features menu",
      "Select project",
      "Dialog window should open, and",
      "Pass visual inspection",
    ],
  });
  const { errors } = this.props;
  try {
    const { ui } = this.props;
    ui.dialog.open(dialog, props);
    unit.passed({ testId: testId }, from);
  } catch (error) {
    unit.failed({ testId: testId, error: error }, from);
    errors.error(true, error, "~58");
  }
}

export function getDialog(dialog = null, from = "~62") {
  try {
    unit.report({
      method: "getDialog",
    });
    let { ui } = this.props;
    return dialog == null ? ui.dialog.get() : ui.dialog.get() == dialog;
  } catch (error) {
    this.props.errors.error(this.props.t("unexpectedError"), error, "~70");
  }
}

export function closeDialog(from = "~74") {
  let testId = unit.report({
    method: "closeDialog",
    steps: [
      "From workspace",
      "From features menu",
      "Select project",
      "Dialog window should open, and",
      "Closable from top right X",
      "Closable from cancel",
    ],
    test: "When a dialog is expected to close, it does so without issue and all menu options are available.",
  });
  try {
    unit.passed({ testId: testId }, from);
    this.props.ui.dialog.close();
  } catch (error) {
    const { errors, t } = this.props;
    unit.failed({ testId: testId, error: error }, from);
    errors.error(t("unexpectedError"), error, "~93");
  }
}
