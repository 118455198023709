/** @format */
import { Box, Button, Hidden, Stack, TextField } from "@mui/material";
import { useLocales } from "locales";

export default function Contact({ setEmail, setFirstName, setLastName, send, disabled, email, setMessage, working }) {
  const { t } = useLocales();

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <Hidden smDown>
          <Box sx={{ width: "50%" }}>
            <Box sx={{ fontWeight: "bold" }}>{t("firstName")}</Box>
            <TextField
              sx={{ padding: 0, margin: 0 }}
              disabled={disabled}
              placeholder={t("enterYourFirstName")}
              onKeyUp={({ target }) => setFirstName(target.value)}
              multiline={false}
              fullWidth={true}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <Box sx={{ fontWeight: "bold" }}>{t("lastName")}</Box>
            <TextField
              sx={{ padding: 0, margin: 0 }}
              disabled={disabled}
              placeholder={t("enterYourLastName")}
              onKeyUp={({ target }) => setLastName(target.value)}
              multiline={false}
              fullWidth={true}
            />
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ fontWeight: "bold" }}>{t("firstName")}</Box>
            <TextField
              sx={{ padding: 0, margin: 0 }}
              disabled={disabled}
              placeholder={t("enterYourFirstName")}
              onKeyUp={({ target }) => setFirstName(target.value)}
              multiline={false}
              fullWidth={true}
            />
          </Box>
        </Hidden>
      </Stack>
      <Box>
        <Box>
          <Box sx={{ fontWeight: "bold" }}>{t("emailAddress")}</Box>
        </Box>
        <TextField
          sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
          disabled={disabled}
          placeholder={t("enterYourEmailAddress")}
          onKeyUp={({ target }) => setEmail(target.value)}
          multiline={false}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Box>
          <Box sx={{ fontWeight: "bold" }}>{t("message")}</Box>
        </Box>
        <TextField
          sx={{ ml: 1, flex: 1, padding: 0, margin: 0 }}
          disabled={disabled}
          placeholder={t("enterYourMessage")}
          onKeyUp={({ target }) => setMessage(target.value)}
          multiline={true}
          fullWidth={true}
          rows={8}
        />
      </Box>
      <Stack direction="row" spacing={5} justifyContent="space-between" alignItems="center">
        <Box sx={{ fontSize: "12px" }}></Box>
        <Box sx={{ textAlign: "right" }}>
          {!working && (
            <Button
              variant="contained"
              disabled={!email}
              onClick={() => send(["contact_inquiry"])}
              color="success"
              size="large"
            >
              {t("send")}
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
