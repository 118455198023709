/** @format */

export function setReady(stage) {
  // Use functional form of setState to ensure up-to-date state
  this.setState((prevState) => ({
    ...prevState,
    ready: [...prevState.ready, stage],
  }));
}

export function getReady(stage = "all") {
  return this.state.ready.includes(stage);
}
