/** @format */

import { readingTime } from "reading-time-estimator";
import { Functional } from "unit";

const unit = Functional.unit("Manuscript");

export function countWords(from = "~8") {
  unit.report({
    method: "calculateWordcount",
    message: "Capturing from Manuscript.",
    test: "Check if the wordcount matches what's on the screen.",
    from: from,
    steps: ["From Manuscript", "Confirm word count calculates correctly"],
  });
  try {
    if (!this.editor.loaded) return 0;

    let nodes = [];

    this.props.data.spreads.forEach((spread) => {
      nodes.push(spread);
    });

    const filtered = nodes.filter(function (node) {
      return ["paragraph", "heading-one", "heading-two", "heading-three"].includes(node.type);
    });

    return filtered
      .map((n) => Node.string(n))
      .join("\n")
      .split(" ").length;
  } catch (error) {}

  return 0;
}

export function countSpreads(from = "~38") {
  unit.report({
    method: "countSpreads",
    message: "Capturing from Manuscript.",
    test: "Confirm spreadcount calculates correctly",
    steps: ["From Manuscript", "Confirm spread count calculates correctly"],
    from: from,
  });

  if (!this.editor.loaded()) return 0;

  return this.props.data.spreads.length || 0;
}

export function countPages(from = "~52") {
  unit.report({
    method: "countPages",
    message: "Capturing from countPages.",
    test: "Confirm spreadcount calculates correctly",
    steps: ["From Manuscript", "Confirm page count calculates correctly"],
    from: from,
  });

  if (!this.editor.loaded()) return 0;

  return this.props.data.spreads.length / 2 || 0;
}

export function countReadtime(from = "~66") {
  unit.report({
    method: "countReadtime",
    message: "Calculated the read time of the book.",
    test: "Do a manual check to see if the read time aligns with expectation.",
    steps: ["From Manuscript", "Confirm read time calculates correctly"],
    from: from,
  });
  return readingTime("Reading time");
}
