/** @format */

import { Box, Divider, Tab, Tabs, styled } from "@mui/material";
import DynamicIcon from "dynamicicon";
import { useState } from "react";
import { useUser } from "user";
import { useNotifications } from "../../";

import Notifications from "./components/Notifications";

const StyledPanel = styled(Box)(() => ({
  "& .MuiBadge-badge": {
    borderRadius: "3px",
    marginTop: "3px",
  },
}));

const StyledTab = styled(Tab)(() => ({
  "&": {
    fontSize: "10px",
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

export default function ({ children }) {
  const [value, setValue] = useState(0);

  // Change the tab
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  // Make sure we're logged in
  const { authenticated } = useUser();

  // Get the notifications
  const { notifications } = useNotifications();

  // if not logged in, we don't need a badge
  if (!authenticated || notifications.length == 0) return children;

  return (
    <StyledPanel sx={{ justifyContent: "center" }}>
      {value == 0 ? <Notifications /> : <Box>{children}</Box>}
      <Divider />
      <Tabs value={value} onChange={handleChange} centered>
        <StyledTab label={<DynamicIcon icon="fa-envelope" />} {...a11yProps(0)} />
        <StyledTab label={<DynamicIcon icon="fa-user" />} {...a11yProps(1)} />
      </Tabs>
    </StyledPanel>
  );
}
