/** @format */
import { truncate } from "lodash";
import { nanoid } from "nanoid";
import { Functional } from "unit";

const unit = Functional.unit("Designer");

// Setup & teardown

export function setupPaste(from = "~10") {
  unit.report({
    method: "setupPaste",
    message: "Setting up pasting.",
    tests: "Pasting should be available in the this.",
    from: from,
  });
  return new Promise((resolve, reject) => {
    try {
      document.addEventListener("paste", (event) => handlePaste.call(this, event));
      resolve(truncate);
    } catch (error) {
      console.error(error, "~22");
      reject(error);
    }
  });
}

export function teardownPaste(from = "~28") {
  unit.report({
    method: "teardownPaste",
    message: "Tearing down pasting.",
    tests: "Pasting should be unregistered now.",
    from: from,
  });
  return new Promise((resolve, reject) => {
    try {
      document.removeEventListener("paste", (event) => handlePaste.call(this, event));
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

export function handlePaste(event) {
  unit.report({
    method: "paste",
    test: "When pasting image data into the page it is uploaded and shown on the screen immediately.",
  });

  const { errors, performing, t } = this.props;

  try {
    // Convert clipboard items to an array
    const items = Array.from(event.clipboardData.items);

    // Check if there are any image items in the clipboard
    const imageItem = items.find((item) => item.type.startsWith("image"));

    if (!imageItem) {
      // No images found, exit the function without calling performing.set.updating
      return;
    }

    // Start the updating process since an image is found
    performing.set.updating("updating", "~54");

    // Process the image
    const blob = imageItem.getAsFile();
    readBlobAsDataURL(blob)
      .then((base64data) => handleImageBlob(blob, base64data))
      .then(() => {
        performing.set.updating("success", "~67");
      })
      .catch((error) => {
        performing.set.updating("error", "~75");
        errors.error(t("unexpectedError"), error, "~72");
      });
  } catch (error) {
    errors.error(t("unexpectedError"), error, "~72");
  }
}

// This function takes a Blob and returns a Promise that resolves to a data URL
function readBlobAsDataURL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.onerror = function () {
      reject(new Error("Error reading file"));
    };
    reader.readAsDataURL(blob);
  });
}

// This function takes a blob and a data URL, uploads the blob, and adds it to the designer
async function handleImageBlob(blob, base64data) {
  try {
    const { user, workspace, media, performing } = this.props;

    let img = new Image();

    img.onload = async function () {
      const id = nanoid();

      let width = this.width;
      let height = this.height;

      let file = {
        blob: blob,
        url: base64data,
        extension: blob.type.split("/")[1],
        filename: id + "." + blob.type.split("/")[1],
        directory: "/",
      };

      let response = await media.upload({
        userId: user.id,
        attachedTo: [workspace.id],
        collections: ["pasted", "uploads"],
        files: [file],
        tags: [],
        meta: { width: width, height: height },
        shared: false,
        returns: "object",
      });

      designer
        .addImageFromPaste({
          id: response.id,
          url: response.url,
          top: 200,
          left: 200,
          width: width,
          height: height,
        })
        .then(() => {
          performing.set.updating("success", "~132");
        })
        .catch((error) => {
          throw error;
        });
    };
    img.src = base64data;
  } catch (error) {
    errors.error(t("unexpectedError"), error, "~140");
  }
}
