/** @format */
import { logEvent as logTag, setUserId } from "firemade";
import throttle from "lodash/throttle";
import { environment } from "version";
import { debug, environments, time } from "./config";

import Report from "./units/report";

// The batch to upload
let batch = [];

// Works in conjuntion with the report function in units/report.js
const flush = async () => {
  // We don't need to send anything
  if (batch.length === 0) {
    if (debug) console.log("GTAGS:", "No new events to send.");
    return;
  }

  try {
    if (environments.includes(environment)) {
      // Loop through the events
      batch.forEach((e) => {
        // Check for specific properties
        if (e.event == "pageView") {
          // Helpful loggings
          if (debug) console.log("GTAGS:", "Sent pageview.");

          // Log the page view
          logTag("page_view", e.properties);

          // We're going to set the user here
        } else if (e.event == "setUser") {
          // Helpful logging
          if (debug) console.log("GTAGS:", "Setting user.");

          const { userId } = e.properties;

          // Set user id
          if (userId) setUserId(userId);

          // Otherwise just log the event
        } else {
          // Track the event
          logTag(e.event, e?.properties || {});
        }
      });
      // batch.forEach((event) => );
      if (debug) console.log("GTAGS:", "Events sent.");
    } else {
      if (debug) console.log("GTAGS:", `Skipped. In ${environment} mode.`);
    }
  } catch (e) {
    if (debug) console.log("GTAGS:", "Error sending events", e);
  }
  batch.length = 0; // Clear the batch
};

// Send this throttled
const throttledFlush = throttle(flush, time, { trailing: true });

// Create a throttled logger
export function logEvent(event) {
  try {
    // Add to the batch
    batch.push(event);
    // Throttle the submission.
    throttledFlush();
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

// Add the report to the unit functions
export { Report };
