/** @format */

import { faGrid2, faGrid3, faGrid4, faLeftRight, faPage } from "@fortawesome/pro-regular-svg-icons";

import { Functional } from "unit";

const unit = Functional.unit("workspace/grid");

export function setGrid(size, from = "~9") {
  let testId = unit.report({
    method: "setGrid",
    test: "Setting grid should change the view grid.",
    steps: ["From workspace", "From illustrator or storyboard", "Change the grid", "Grid change as expected"],
  });
  let zoom = gridSizes()[size].zoom;
  try {
    this.setState({
      settings: {
        ...this.state.settings,
        ...{ grid: size, zoom: zoom / 100 },
      },
    });
    unit.passed({ testId: testId }, from);
  } catch (error) {
    unit.failed({ testId: testId, error: error }, from);
    this.props.errors.error(this.props.t("unexpectedError"));
  }
}

export function getGrid(size = null, from = "~30") {
  unit.report({ method: "getGrid" });
  try {
    return !size ? this.state.settings.grid : size == this.state.settings.grid;
  } catch (error) {
    this.props.errors.error(true, error, from);
  }
}

export function gridSizes() {
  return {
    page: { width: 12, zoom: 50, icon: faPage, enabled: true },
    half: { width: 6, zoom: 60, icon: faGrid2, enabled: true },
    small: { width: 4, zoom: 70, icon: faGrid3, enabled: true },
    tiny: { width: 3, zoom: 80, icon: faGrid4, enabled: true },
    scroll: { width: 1, zoom: 80, icon: faLeftRight, enabled: true },
  };
}
