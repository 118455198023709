/** @format */
import { Box } from "@mui/material";
import { TinyError } from "errors";

export default function (props) {
  return (
    <TinyError>
      <Bio {...props} />
    </TinyError>
  );
}

export function Bio(props) {
  if (!props?.author) return null;
  const { bio } = props.author;
  return <Box data-testid="block-bio">{bio}</Box>;
}
