/** @format */

import { formatDuration } from "date-fns";

function roundToNearestFullMeasure(duration) {
  if (duration.years >= 0.5) {
    return { years: Math.round(duration.years) };
  } else if (duration.months >= 0.5) {
    return { months: Math.round(duration.months) };
  } else if (duration.weeks >= 0.5) {
    return { weeks: Math.round(duration.weeks) };
  } else if (duration.days >= 0.5) {
    return { days: Math.round(duration.days) };
  } else if (duration.hours >= 0.5) {
    return { hours: Math.round(duration.hours) };
  } else {
    return { minutes: Math.round(duration.minutes) };
  }
}

function minutesToDuration(minutes) {
  const duration = {
    minutes: minutes % 60,
    hours: (minutes / 60) % 24,
    days: (minutes / (60 * 24)) % 7,
    weeks: (minutes / (60 * 24 * 7)) % 4,
    months: (minutes / (60 * 24 * 30)) % 12,
    years: minutes / (60 * 24 * 365),
  };

  return roundToNearestFullMeasure(duration);
}

function humanReadableTimeRange(timeArray) {
  const [minTime, maxTime] = timeArray;
  const minDuration = formatDuration(minutesToDuration(minTime));
  const maxDuration = formatDuration(minutesToDuration(maxTime));

  if (minDuration === "" || minDuration == maxDuration) return maxDuration;
  return minDuration + " & " + maxDuration;
  //return [minDuration, maxDuration];
}

export default humanReadableTimeRange;
