/** @format */
import { Alert, Box, Button, DialogActions, Divider } from "@mui/material";
import EmailPassword from "./components/EmailPassword";

export default function Reauthentication(props) {
  const { t, user, errors, ui } = props;

  let callback;

  const provider = user.reauthentication.provider();

  // See if we passed a callback
  try {
    callback = props.dialogs.confirm.props.callback;
  } catch (_) {}

  return (
    <Box sx={{ width: "500px" }}>
      <>
        <Alert severity="info">{t("reauthenticationBody")}</Alert>
        <Divider />
        {provider == "password" ? (
          <EmailPassword {...props} callback={callback} />
        ) : (
          <DialogActions>
            <Button
              size="small"
              color="success"
              variant="contained"
              onClick={() =>
                user.reauthenticate
                  .provider()
                  .then(() => {
                    ui.confirm.close();
                    if (callback) callback(true);
                  })
                  .catch((error) => {
                    errors.error(t(error), error, "~38");
                    ui.confirm.close();
                    if (callback) callback(false);
                  })
              }
            >
              {t("continue")}
            </Button>
          </DialogActions>
        )}
      </>
    </Box>
  );
}
