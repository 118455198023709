/** @format */
import { packages } from "../../../../../config/index.json";

// Check if the feature is available
export function getBilledFeature(feature) {
  const { user } = this.props;
  try {
    // Check to make sure the plan is active
    let planId = user.billing("planId");

    // # TODO - remove this
    if (planId == null) planId = "premium_annual";

    // If not, they don't have access to this feature
    if (!planId) return false;

    // get the plan, time period
    let [plan, _] = planId.split("_");

    // Get the features
    const { features } = packages[plan];

    // Didn't pass a feature, so return all the features billed
    if (!feature) return features;

    // Return that this feature is billed
    return features.includes(feature);
  } catch (_) {
    return false;
  }
}

// Rarely used check if project is active
export function getBilledProject() {
  try {
    // just return the billing method
    return this.state.status == "active" || this.state.status == "inactive";
  } catch (e) {
    return "inactive";
  }
}
