/** @format */
import { Functional } from "unit";

const unit = Functional.unit("designer/background");

// Set the background to a pre-determined aspect
export function setBackground(style, from = "~7") {
  unit.report({
    method: "setBackground",
    message: "Sets the background based on props.",
    from: from,
    tests: "Change the background when first (lowest) layer is selected.",
    from: from,
  });

  // Get the spread we're on
  const spread = this.state?.spread?.number || 0;

  // Get the frame we're working on
  const frame = this.state.frame.number || 0;

  // Get the data we want to work with
  const data = this.data("~23");

  // We're on the first layer, so we know that's the one we want to work on
  const index = 0;

  if (!data?.spreads[spread]?.elements[index]?.style[frame]) return;

  // Figure out the dimesions based on the spread
  const place = this.state.spread.number == 0 ? "exterior" : "interior";

  // Get the dimensions based off the place
  const { width: spreadWidth, height: spreadHeight } = this.dimensions[place];

  // Get the element we're working on
  const selected = this.element.selected();

  // get the styles
  let { height: elementHeight, width: elementWidth } = this.element.style.get(selected);

  elementWidth = parseInt(elementWidth.replace("px", ""));
  elementHeight = parseInt(elementHeight.replace("px", ""));

  // console.log(style, spreadWidth, spreadHeight, elementWidth, elementHeight, "~45");

  // Calculate aspect ratios for both spread and element
  const spreadAspectRatio = spreadWidth / spreadHeight;
  const elementAspectRatio = elementWidth / elementHeight;

  let calculatedWidth, calculatedHeight, calculatedLeft, calculatedTop;

  // Determine what to do based on the style: 'cover' or 'contain'
  if (style === "cover") {
    // For 'cover', we need to ensure the entire container is covered.
    if (spreadAspectRatio > elementAspectRatio) {
      // Spread is wider, so match the width and adjust the height
      calculatedWidth = spreadWidth;
      calculatedHeight = spreadWidth / elementAspectRatio;
    } else {
      // Spread is taller, so match the height and adjust the width
      calculatedHeight = spreadHeight;
      calculatedWidth = spreadHeight * elementAspectRatio;
    }

    // Calculate left and top to possibly position the element partially outside the spread for 'cover'
    calculatedLeft = (spreadWidth - calculatedWidth) / 2;
    calculatedTop = (spreadHeight - calculatedHeight) / 2;
  } else if (style === "contain") {
    // For 'contain', make the image as large as possible within the spread.
    if (elementAspectRatio > spreadAspectRatio) {
      // Element is wider, so match the width and adjust the height
      calculatedWidth = spreadWidth;
      calculatedHeight = spreadWidth / elementAspectRatio;
    } else {
      // Element is taller, so match the height and adjust the width
      calculatedHeight = spreadHeight;
      calculatedWidth = spreadHeight * elementAspectRatio;
    }

    // Calculate left and top to center the element in the spread
    calculatedLeft = (spreadWidth - calculatedWidth) / 2;
    calculatedTop = (spreadHeight - calculatedHeight) / 2;
  }

  // Log the calculated dimensions
  //   console.log(`Calculated dimensions: Width = ${calculatedWidth}, Height = ${calculatedHeight}`, "~87");
  //   console.log(`Calculated position: Left = ${calculatedLeft}, Top = ${calculatedTop}`, "~88");

  data.spreads[spread].elements[index].style[frame] = {
    ...data.spreads[spread].elements[index].style[frame],
    width: calculatedWidth,
    height: calculatedHeight,
    left: calculatedLeft,
    top: calculatedTop,
  };

  // Close down the flyout menu
  this.controls.menu.close();

  // Update the project
  this.project
    .update(
      {
        data: data,
        note: "Set backgound.",
        ai: 0,
      },
      "~109"
    )
    .then(() => {
      // this.refreshElements();
    })
    .catch((error) => {
      throw error;
    });
}

// Check if the current layer is the background layer
export function getBackground() {
  try {
    return this.element.selected().index == 0 && this.element.type("image");
  } catch (_) {
    return false;
  }
}
