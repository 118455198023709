/** @format */

export function onDrag(event) {
  if (!event) return;

  const { top, left } = event;

  try {
    this.element.style.set(
      {
        event: event,
        label: left.toFixed(1) + " x " + top.toFixed(1),
        style: {
          left,
          top,
        },
      },
      "~29"
    );
  } catch (error) {
    console.error(error, "~32");
  }
}

export function onDragGroup(event) {
  // This doesn't look right
  event.events.forEach((event, i) => {
    onDrag.call(this, event);
  });
}
