/** @format */

import { useEffect } from "react";
import "./assets/css/iubenda.css";
import { getCookiePolicy, getPrivacyPolicy, getTerms, js } from "./assets/js/iubenda.js";
import Link from "./components/Link";
import Reject from "./components/Reject";
import config from "./config";

const Iubenda = () => {
  useEffect(() => {
    // Setup the iubenda options
    js();
  }, []);

  useEffect(() => {
    // Ensure that the Iubenda script loads asynchronously
    const iubendaScript = document.createElement("script");
    iubendaScript.src = config.url;
    iubendaScript.type = "text/javascript";
    iubendaScript.async = true;
    document.body.appendChild(iubendaScript);

    return () => {
      document.body.removeChild(iubendaScript);
    };
  }, []); // The empty dependency array ensures this effect runs only once

  return null; // This component doesn't render anything in the DOM
};

export default Iubenda;
export { Link, Reject, getCookiePolicy, getPrivacyPolicy, getTerms };
