/** @format */
import { signInWithEmailLink } from "firebase/auth";
import { auth } from "firemade";

export default function signInWithEmailLinkComplete(emailAddress, emailLink) {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !emailLink) {
      reject(new Error("No e-mail address or e-mail link provided."));
      return;
    }

    if (auth.currentUser) {
      var error = "Unknown error.";

      reject(new Error(error));
      return;
    }

    signInWithEmailLink(auth, emailAddress, emailLink)
      .then((_value) => {
        authentication
          .getUserData({ loginMethod: "Email Link" })
          .then((response) => {
            Cookies.remove("emailAddress");
            resolve(response);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}
