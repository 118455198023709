/** @format */

export function getMeta(key) {
  try {
    return this.state.meta[key];
  } catch (e) {
    return null;
  }
}

export function setMeta(field, value) {
  this.setState(
    (prevState) => ({
      meta: {
        ...prevState.meta,
        [field]: value,
      },
    }),
    () => this.user.set(this.state, true)
  );
}

export function toggleMeta(key) {
  setMeta.call(this, key, !this.state.meta[key]);
}
