/** @format */
import { withWorkspace } from "@Workspace/Context";
import { TinyError } from "errors";
import { Suspense } from "react";
import { withTour } from "tour";
import { withUI } from "ui";
import { withUnit } from "unit";

import BottomMenu from "./Bottom";
import ContextMenu from "./Context";
import LeftMenu from "./Left";
import MenuComponent from "./Menu/";
import RightMenu from "./Right";
import TopMenu from "./Top";

// This is bad code - buttons should be retrieved dynamically.
import { useDesigner } from "@Workspace/Usable/Features/Designer/context";
import { useManuscript } from "@Workspace/Usable/Features/Manuscript/context";
import { useSimple } from "@Workspace/Usable/Features/Simple/context";

function buttonsShim({ desk }) {
  let buttons = {};
  if (desk.get("manuscript")) buttons = useManuscript().buttons || {};
  if (desk.get("planning")) buttons = useSimple().buttons || {};
  if (["storyboard", "illustrate", "animate"].includes(desk.get())) buttons = useDesigner().buttons || {};
  return buttons;
}
// Refactor above

function Menus(props) {
  // This is not good code
  const { workspace } = props;
  const { desk } = workspace.settings;
  const { menus } = workspace.ui;

  // # TODO remove this
  const buttons = buttonsShim(workspace);

  return (
    <Suspense>
      <TinyError>
        <TopMenu {...props} buttons={buttons}>
          <MenuComponent {...props} desk={desk} menus={menus} buttons={buttons} position="top" />
        </TopMenu>
      </TinyError>
      <TinyError>
        <RightMenu {...props} buttons={buttons}>
          <MenuComponent {...props} desk={desk} menus={menus} buttons={buttons} position="right" />
        </RightMenu>
      </TinyError>
      <TinyError>
        <BottomMenu {...props} buttons={buttons}>
          <MenuComponent {...props} desk={desk} menus={menus} buttons={buttons} position="bottom" />
        </BottomMenu>
      </TinyError>
      <TinyError>
        <LeftMenu {...props} buttons={buttons}>
          <MenuComponent {...props} desk={desk} menus={menus} buttons={buttons} position="left" />
        </LeftMenu>
      </TinyError>
      <TinyError>
        <ContextMenu {...props} desk={desk} menus={menus} buttons={buttons} position="context" />
      </TinyError>
    </Suspense>
  );
}

export default withUI(withTour(withWorkspace(withUnit(Menus))));
