/** @format */

import { Functional } from "unit";

const unit = Functional.unit("workspace/menu");

export function getMenuFeaturesAll(from = "~7") {
  unit.report({
    method: "getMenuFeaturesAll",
    test: "All features that are enabled by Made Live should be visible.",
    from: from,
  });
  return Object.keys(this.ui.menus.features);
}

export function getMenuFeaturesAvailable(from = "~16") {
  unit.report({
    method: "getMenuFeaturesAvailable",
    test: "All features that are available to the user should be visible. Use should not be able to see features that they're not paying for.",
    from: from,
  });
  return Object.keys(this.ui.menus.features)
    .filter((feature) => Object.keys(this.features).indexOf(feature) !== -1)
    .map((feature) => this.getFeature(feature))
    .sort((a, b) => a.priority - b.priority);
}

export function getMenuFeaturesUnavailable(from = "~28") {
  unit.report({
    method: "getMenuFeaturesUnavailable",
    test: "All features that are unavailable to the user should be visible..",
    from: from,
  });
  return Object.keys(this.ui.menus.features)
    .filter((feature) => Object.keys(this.features).indexOf(feature) !== -1)
    .map((feature) => this.getFeature(feature))
    .sort((a, b) => a.priority - b.priority);
}

export function getMenuFeaturesSelected(feature, from = "~40") {
  unit.report({
    method: "getMenuFeaturesSelected",
    test: "The feature that's currently selected should be visible in the features meu.",
    message: "Get which feature is selected to display on the feature menu (bottom right)",
    from: from,
  });
  return this.features[feature];
}

export function getMenuFeaturesUnselected(feature, from = "~50") {
  unit.report({
    method: "getMenuFeaturesUnselected",
    from: from,
    test: "All menus items excluding the menu that's currenct selected should be visible to the user.",
  });
  return this.features[feature];
}
