/** @format */

import { defaultStatus } from "../../../config/index.json";

// This will get statuses and apply additional logic to them, like grace, expires dates, etc.
// Written verbatim for readability and to allow for easier upgrades.

export function getStatus(status) {
  const { user } = this.props;

  if (!user) return defaultStatus;

  // Missing: Custom status for making sure billing is updated before continuing
  // Circustance: When a user was on beta testing before billing was setup with stripe

  // Active: The subscription is active, and the customer is being billed regularly (no check needed)
  // Circumstance: This is the normal state for a subscription where payments are being made on time and there are no issues.

  // Trialing: The subscription is currently in a trial period (so no security check needed)
  // Circumstance: When you offer a free trial period before the actual billing starts, and the customer has signed up during this period.

  // Inactive: The subscription is still in beta mode and hasn't had updated payment information
  // Circumstance: User was in initial beta and needs to now select a plan

  // Incomplete: The subscription creation attempt has started, but there was a problem setting up payment and the first invoice is still pending.
  // Circumstance: If the initial payment attempt fails, for example, due to insufficient funds or an expired card, the subscription will be in this state.

  // Incomplete Expired: The subscription creation attempt has failed, and Stripe has stopped trying to collect a payment.
  // Circumstance: After the initial payment attempt fails and after retrying for a configured period without success, the subscription moves to this state. It typically indicates that the card was declined multiple times.

  // Past Due: The payment for the subscription is overdue, but Stripe is still trying to collect it.
  // Circumstance: When a recurring payment fails and Stripe's automatic retries are still ongoing, but it hasn't been able to collect the payment yet.

  // Cancelled: The subscription has been terminated by the merchant or the customer.
  // Circumstance: Either the customer decided to cancel their subscription, or the merchant manually canceled it in the Stripe dashboard or via API.

  // Unpaid: Payments for the subscription have failed after all retry attempts, and the subscription has been marked as unpaid. It's different from incomplete_expired because it refers to payments after the first one.
  // Circumstance: When Stripe has exhausted all retry attempts for a recurring payment and couldn't collect the amount due. Depending on your settings in Stripe, this can lead to the suspension of service or access for the customer.

  try {
    // Get the status from collect (which can be considered raw data)
    const currentStatus = user.billing("status");

    if (!status) {
      // If it's missnig
      if (!currentStatus) return "missing";

      // Active needs no additional logic
      if (currentStatus == "active") return currentStatus;

      // Inactive needs no additional logic
      if (currentStatus == "inactive") return currentStatus;

      // Trialing needs no additional logic
      if (currentStatus == "trialing") return currentStatus;

      // Incomplete needs no additional logic
      if (currentStatus == "incomplete") return currentStatus;

      // Incomplete expired needs no additional logic
      if (currentStatus == "incomplete_expired") return currentStatus;

      // Past due expired needs no additional logic
      if (currentStatus == "past_due") return currentStatus;

      // Past due expired needs no additional logic
      if (currentStatus == "cancelled") return currentStatus;

      // Unpaid expired needs no additional logic
      if (currentStatus == "unpaid") return currentStatus;

      // Check if we're matching with additional logic for dates handling
    } else {
      // Match missing (no additional logic on match)
      if (!currentStatus && status == "missing") return true;

      // Matchactive (no additional logic on match)
      if (currentStatus == "active" && status == "active") return true;

      // Match inactive (no additional logic on match)
      if (currentStatus == "inactive" && status == "inactive") return true;

      // Match trialing (no additional logic on match)
      if (currentStatus == "trialing" && status == "trialing") return true;

      // Match incomplete (no additional logic on match)
      if (currentStatus == "incomplete" && status == "incomplete") return true;

      // Match incomplete expired (no additional logic on match)
      if (currentStatus == "incomplete_expired" && status == "incomplete_expired") return true;

      // Match past due (no additional logic on match)
      if (currentStatus == "past_due" && status == "past_due") return true;

      // Match past due (no additional logic on match)
      if (currentStatus == "cancelled" && status == "cancelled") return true;

      // Match past due (no additional logic on match)
      if (currentStatus == "unpaid" && status == "unpaid") return true;

      // There was no match
      return false;
    }

    // The default is inactive
    return defaultStatus;

    // Catch an error and default to inactive
  } catch (e) {
    return defaultStatus;
  }
}

// # TODO - This is an artifact
export function setStatus(status) {}
