/** @format */

import Cookies from "cookies";
import { sendSignInLinkToEmail } from "firebase/auth";
import { auth } from "firemade";

export default function signInWithEmailLink(emailAddress, authentication) {
  return new Promise(async (resolve, reject) => {
    if (!emailAddress) return reject("noEmailAddress");
    if (auth.currentUser) return reject("unknownError");

    const actionCodeSettings = {
      url: "",
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings)
      .then((value) => {
        Cookies.set("emailAddress", emailAddress);
        resolve(value);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
