/** @format */

import { Functional } from "unit";

const unit = Functional.unit("designer/font");

// # TODO - madelive-fonts should handle this now
export function setupFonts(from = "~8") {
  unit.report({
    method: "setupFonts",
    message: "Loads fonts from Google Fonts",
    test: "Fonts should be downloaded and made available to users.",
    from: from,
  });
  return new Promise((resolve, reject) => {
    try {
      const { workspace } = this.props;
      const fontFamilies = workspace.data.feature(workspace.feature.selected()).fonts.join("|");
      const link = document.createElement("link");

      link.href = `/api/fonts?fonts=${encodeURIComponent(fontFamilies)}`;
      link.rel = "stylesheet";
      link.onload = function () {
        return resolve(true);
      };
      link.onerror = function (error) {
        return reject(error);
      };

      document.head.appendChild(link);

      resolve(true);
    } catch (error) {
      console.error(error, "~34");
      return reject(error);
    }
  });
}

/* Project */

export function getFamilies(from = "~42") {
  unit.report({
    method: "getFonts",
    test: "Gets a list of fonts for the project.",
    from: from,
  });
  const { errors, t } = this.props;
  try {
    const data = this.data("~50");
    return data.fonts;
  } catch (error) {
    errors.error(t("errorFontLoading"), error, "~53");
    return [];
  }
}

export function setFamilies(font, from = "~58") {
  unit.report({
    method: "getFonts",
    test: "Gets a list of fonts for the project.",
  });
  const { snackbar, t } = this.props;
  return new Promise((resolve, reject) => {
    try {
      const data = this.data("~66");
      const index = data.fonts.indexOf(font);

      if (index !== -1) {
        data.fonts.splice(index, 1);
        snackbar.open(t("removedFontFromProject"), "info");
      } else {
        data.fonts.push(font);
        snackbar.open(t("addedFontToProject"), "info");
      }

      this.project
        .update(
          {
            data: data,
            note: "Updated fonts.",
            ai: 0,
          },
          "~84"
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      const { errors, t } = this.props;
      errors.error(t("unexpectedError"), error);
      reject();
    }
  });
}

export function getColors(from = "~97") {
  unit.report({
    method: "getFonts",
    test: "Gets a list of fonts for the project.",
  });
  const { performing } = this.props;
  try {
    const data = this.data("~104");
    return data.colors || [];
  } catch (_) {
    performing.set.updating("error", "~107");
    return [];
  }
}

export function setColors(color, operation = "remove", from = "~112") {
  unit.report({
    method: "getFonts",
    test: "Gets a list of fonts for the project.",
  });
  return new Promise((resolve, reject) => {
    try {
      let data = this.data("~119");

      if (operation == "remove") {
        const index = data.colors.indexOf(color);

        if (index !== -1) data.colors.splice(index, 1);
      } else if (operation == "add") {
        if (!data.colors.includes(color)) data.colors.push(color);
      }

      this.project
        .update(
          {
            data: data,
            note: "Updated fonts.",
            ai: 0,
          },
          "~136"
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      const { errors, t } = this.props;
      errors.error(t("unexpectedError"), error);
      reject();
    }
  });
}

/* Selected Font */

export function getSelectedFont(from = "~151") {
  unit.report({ method: "getSelectedFont", from: from });
  try {
    const data = this.data("~154");
    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected("data")?.index;

    if (!data?.spreads[spread]?.elements[index]?.font) return null;

    return data.spreads[spread].elements[index].font || null;
  } catch (_) {
    return null;
  }
}

export function setSelectedFont(style, from = "~166") {
  unit.report({
    method: "setSelectedFont",
    test: "Merge the current font with the selected data.",
  });
  return new Promise((resolve, reject) => {
    try {
      const data = this.data("~173");
      const spread = this.state?.spread?.number || 0;
      const index = this.element.selected("data").index;

      if (!data?.spreads[spread]?.elements[index]?.font) {
        return null;
      }

      data.spreads[spread].elements[index].font = { ...data.spreads[spread].elements[index].font, ...style };

      this.project.update(
        {
          data: data,
          note: "Changed font style",
          ai: 0,
        },
        "~189"
      );

      resolve(true);
    } catch (error) {
      const { errors, t } = this.props;
      errors.error(t("unexpectedError"), error, "~195");
      reject();
    }
  });
}

/* Align */

export function getFontAlign(align, from = "~203") {
  unit.report({
    method: "getFontAlign",
    test: "Changes or compares the alginment of text.",
    from: from,
  });
  try {
    const selected = getSelectedFont.call(this, "~210");
    if (!align) return selected?.align || "center";
    if (selected?.align) return selected?.align == align;
    return null;
  } catch (_) {
    return "center";
  }
}

export function setFontAlign(align, from = "~219") {
  unit.report({
    method: "setFontAlign",
    analyze: true,
    action: true,
    from: from,
    test: "Changing the alignment of a font should be reflected immediately locally/remotely.",
  });
  const { performing } = this.props;
  try {
    setSelectedFont.call(this, { align: align }, "~229");
  } catch (error) {
    performing.set.updating("error", "~231");
  }
}

/* Size */

export function getFontSize(from = "~237") {
  unit.report({ method: "getFontSize", from: from });
  try {
    const selected = getSelectedFont.call(this, "~240");
    return selected?.size || null;
  } catch (_) {
    return null;
  }
}

export function setFontSize(size, from = "~247") {
  unit.report({
    method: "setFontSize",
    payload: size,
    analyze: true,
    action: true,
    from: from,
    test: "Font size changes should be reflected immediately locally/remotely.",
  });
  const { performing } = this.props;
  try {
    if (!size || size < 10 || size > 100) {
      return;
    }
    setSelectedFont.call(this, { size: size }, "~261");
  } catch (error) {
    performing.set.updating("error", "~263");
  }
}

/* Color */

export function getFontColor() {
  unit.report({ method: "getFontColor" });
  try {
    const selected = getSelectedFont.call(this, "~272");
    return selected?.color || null;
  } catch (_) {
    return null;
  }
}

export function setFontColor(color, from = "~279") {
  unit.report({
    method: "setFontColor",
    from: from,
    payload: color,
    analyze: true,
    action: true,
    test: "Changes to font color are reflected locally/remotely immediately. Page reload should be complete to confirm the changes saved correctly.",
  });
  function isValidHexColor(hex) {
    const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return hexRegex.test(hex);
  }
  try {
    if (isValidHexColor(color)) {
      setSelectedFont.call(this, { color: color }, "~294");
      return true;
    }
    return null;
  } catch (_) {
    return "center";
  }
}

/* Family */

export function getFontFamily(from = "~305") {
  unit.report({ method: "getFontFamily", from: from });
  try {
    const selected = getSelectedFont.call(this, "~308");
    return selected?.family || null;
  } catch (_) {
    return null;
  }
}

export function setFontFamily(family, from = "~315") {
  unit.report({
    method: "setFontFamily",
    payload: family,
    analyze: true,
    action: true,
    from: from,
    test: "Changes to font family are reflected locally/remotely immediately. Page reload should be complete to confirm the changes saved correctly.",
  });
  const { performing } = this.props;
  try {
    setSelectedFont.call(this, { family: family }, "~326");
  } catch (error) {
    performing.set.updating("error", "~328");
  }
}
