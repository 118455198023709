/**
 * @format
 * Updates the user profile with the specified field and value.
 * @param {string} field - The field to update.
 * @param {any} value - The new value for the field.
 * @returns {any} - The updated user object.
 */
import { doc, setDoc } from "firebase/firestore";
import { db } from "firemade";

export function updateProfile(values) {
  const { id } = this.props.user;

  return new Promise(async (resolve, reject) => {
    if (!id) reject(false);

    try {
      // Update the document in Firestore
      const docRef = doc(db, "profiles", id);
      await setDoc(docRef, values, { merge: true });

      // Update the state
      this.setState((prevState) => {
        const updatedUsers = prevState.users.map((user) => {
          if (user.id === id) {
            // This is the user we're interested in, update its profile
            return { ...user, profile: { ...user.profile, ...values } };
          } else {
            // This is not the user we're interested in, don't change it
            return user;
          }
        });

        return { users: updatedUsers };
      });

      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}
