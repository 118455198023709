/** @format */
import { withWorkspace } from "@Workspace/Context";
import capitalize from "capitalize";
import { FatalError } from "errors";
import { Component as ReactComponent, Suspense, lazy } from "react";
import { withSnackbar } from "snackbar";
import { withUnit } from "unit";

class Usable extends ReactComponent {
  constructor(props) {
    super(props);
    this.contexts = [];
    this.unit = props.unit.new(this.constructor.name);
    Object.keys(props.workspace.usable).forEach((usable) => {
      this.contexts[usable] = lazy(() =>
        import(`../components/Features/components/${capitalize(usable)}/context/index.jsx`)
      );
    });
  }

  Context = (props) => {
    this.unit.report({
      method: "Context",
      message: "Workspace consutructed from usable /context/index.js.",
      from: "~25",
    });
    const { workspace } = this.props;
    const feature = workspace.settings.feature;
    const context = workspace.ui.features[feature].context;
    if (this.contexts[context]) {
      let Component = this.contexts[context];
      return (
        <Suspense fallback={null}>
          <Component {...this.props} feature={feature} data={workspace.data.feature(feature)} key="usable">
            {props.children}
          </Component>
        </Suspense>
      );
    } else {
      return <FatalError error="Missing Context." from="~40" />;
    }
  };

  render() {
    return <this.Context {...this.props}>{this.props.children}</this.Context>;
  }
}

export default withSnackbar(withWorkspace(withUnit(Usable)));
