/** @format */
import { Menu } from "@mui/material";
import { ComponentError } from "errors";
import { Component as ReactComponent, Suspense } from "react";

import isHotkey from "is-hotkey";

const Content = (props) => {
  const { menus, position, desk } = props;
  if (menus[position] && menus[position][desk]) {
    let Component = menus[position][desk];
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );
  } else {
    return <></>;
  }
};

class Context extends ReactComponent {
  constructor(props) {
    super(props);
    this.unit = this.props.unit.new(this.constructor.name);
  }

  keydown = (event) => {
    if (isHotkey("escape", event)) this.props.workspace.menu.context.close();
  };

  render() {
    const { workspace, feature, menus, position, desk } = this.props;
    if (!menus?.[position] && menus?.[position]?.[desk]) return <></>;
    return (
      <ComponentError>
        <Menu
          open={workspace.menu.context.opened() || false}
          onClose={workspace.menu.context.close}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: workspace.settings.context.mouseY || 0,
            left: workspace.settings.context.mouseX || 0,
          }}
        >
          <Content
            {...this.props}
            feature={feature}
            data={workspace.data.feature(feature)}
            context={this.props.workspace.settings.context.props}
          />
        </Menu>
      </ComponentError>
    );
  }
}

export default Context;
