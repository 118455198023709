/** @format */

import { removeStopwords } from "stopword";
import stopwords from "../../config/stopwords.json";
/* Tags */

function getTags(name = null) {
  let result = [];

  if (!name) {
    for (const name in this.state.queries) {
      this.state.queries[name].forEach((item) => {
        try {
          if (Array.isArray(item.tags)) {
            for (const element of item.tags) {
              if (element) result.push(element);
            }
          }
        } catch (e) {}
      });
    }
  } else if (this.state.queries[name]) {
    this.state.queries[name].forEach((item) => {
      try {
        if (Array.isArray(item.tags)) {
          for (const element of item.tags) {
            if (element) result.push(element);
          }
        }
      } catch (e) {}
    });
  }

  // Remove stop words
  result = removeStopwords(removeStopwords(result), stopwords);

  // Count the occurrences of each tag
  let tagCounts = result.reduce((counts, tag) => {
    counts[tag] = (counts[tag] || 0) + 1;
    return counts;
  }, {});

  // Sort tags by popularity and alphabetically
  result.sort((a, b) => {
    let countDiff = tagCounts[b] - tagCounts[a];
    if (countDiff === 0) {
      // If tags have the same count, sort them alphabetically
      return a.localeCompare(b);
    } else {
      // Otherwise, sort by popularity
      return countDiff;
    }
  });

  // Remove duplicates
  result = [...new Set(result)];

  return result;
}

function tagCount(name = null) {
  try {
    return this.getTags(name).length;
  } catch (_) {
    return 0;
  }
}

function hasTags(name = null) {
  try {
    return this.getTags(name).length > 0;
  } catch (_) {
    return false;
  }
}

export { getTags, hasTags, tagCount };
