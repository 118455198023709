/** @format */
import { Component as ReactComponent, createContext, useContext } from "react";
import { withUnit } from "unit";
import { withUser } from "user";
import config from "../config/index.json";

import { withUI } from "ui";

// Helpers

// When this component is ready
import { getReady, setReady } from "./helpers/Ready";

// Shim the migration from from beta to paid
import { getEnabled } from "./helpers/Enabled";

// The currently selected package
import { getPackage, getPackageName, getPackagePeriod, setPackage } from "./helpers/Package";

// The packages available
import { getPackages, setPackages } from "./helpers/Packages";

// Get the billing status (from the user context)
import { getStatus, setStatus } from "./helpers/Status";

// The dialog for billing
import SettingsDialog from "../dialogs/components/Settings";

// Check the status of billing
import { getBilledFeature, getBilledProject } from "./helpers/Billed";

// Get pending cancellations
import { getCancelPending } from "./helpers/Cancel";

const BillingContext = createContext({});

class Billing extends ReactComponent {
  constructor(props) {
    super(props);
    this.unit = this.props.unit.new(this.constructor.name);

    // Setup our state
    this.state = {
      ready: false,
      revised: null,
      package: null,
      packages: [],
      status: config.defaultStatus,
      enabled: config.enabled,
    };

    // bind our methods
    this.ready = {
      set: setReady.bind(this),
      get: getReady.bind(this),
    };

    // Account status
    this.status = {
      set: setStatus.bind(this),
      get: getStatus.bind(this),
    };

    // Package selected
    this.package = {
      set: setPackage.bind(this),
      get: getPackage.bind(this),
      name: getPackageName.bind(this),
      period: getPackagePeriod.bind(this),
    };

    // Packages available
    this.packages = {
      set: setPackages.bind(this),
      get: getPackages.bind(this),
    };

    this.billed = {
      feature: getBilledFeature.bind(this),
      project: getBilledProject.bind(this),
    };

    // We need an additional step for cancel pending
    this.cancel = {
      pending: getCancelPending.bind(this),
    };

    // This is a migrating function - it can come out after a certain date
    this.enabled = getEnabled.bind(this);
  }

  refresh = () => {
    this.setState({ revised: Date.now() });
  };

  componentDidMount() {
    if (!this.state.ready) this.ready.set(true);
  }

  componentWillUnmount() {
    // unmount stuff here
    // this.ready.set(false);
  }

  render() {
    return (
      <BillingContext.Provider
        value={{
          ...this.state,
          refresh: this.refresh,
          ready: this.ready,
          package: this.package,
          packages: this.packages,
          cancel: this.cancel,
          status: this.status,
          enabled: this.enabled,
          billed: this.billed,
        }}
      >
        {this.state.ready && (
          <>
            {this.props.children}
            <SettingsDialog {...this.props} from="billing" />
          </>
        )}
      </BillingContext.Provider>
    );
  }
}

const withBilling = (Component) => {
  return function ContextualComponent(props) {
    return <BillingContext.Consumer>{(state) => <Component {...props} billing={state} />}</BillingContext.Consumer>;
  };
};

const useBilling = () => {
  return useContext(BillingContext);
};

export default withUnit(withUser(withUI(Billing)));
export { useBilling, withBilling };
