/** @format */

export function setUser(thisData, save = false, from) {
  try {
    if (save == false) {
      this.setState({ ...thisData });
      return;
    }
    return this.connection.setUser(thisData, from).then((d) => {
      this.setState({ ...d });
    });
  } catch (e) {
    this.setState({ ...this.defaultUserState, ready: true });
    try {
      this.signOut();
    } catch (e) {}
  }
}

export function getUser() {
  return this.state;
}
