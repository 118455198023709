/**
 * Checks if a user is currently typing.
 *
 * @format
 * @param {string} from - The user ID to check for typing status. Default value is "~5".
 * @returns {boolean} - Returns true if the user is typing, false otherwise.
 */
import { stamp } from "dateml";
import { doc, setDoc } from "firebase/firestore";
import { db } from "firemade";

/**
 * Retrieves the typing status of the current chat.
 *
 * @returns {boolean} True if typing was within the last 5 seconds, otherwise false.
 */
export function getTyping() {
  try {
    const { user } = this.props;
    const index = this.chats.index();
    if (index === -1) return false;

    const chat = this.state.chats[index];
    const typingUsers = chat?.typing || [];
    const fiveSecondsAgo = new Date(new Date().getTime() - 5000);

    return typingUsers.some((typingInfo) => {
      if (typingInfo.userId === user.id) return false; // Ignore current user
      const lastTypingTime = new Date(typingInfo.timestamp);
      return lastTypingTime > fiveSecondsAgo;
    });
  } catch (_) {
    return false;
  }
}

/**
 * Sets the typing status for the current user in the chat.
 * @param {boolean} typing - If true, update the typing timestamp.
 */
export function setTyping(typing = true) {
  const { user } = this.props;
  const chatIndex = this.chats.index();
  if (chatIndex === -1) return;

  // Clone the current chat object to avoid direct state mutation
  let chat = { ...this.state.chats[chatIndex] };
  let typingUsers = chat.typing || [];

  if (typing) {
    // Update the typing timestamp for the current user
    const userIndex = typingUsers.findIndex((info) => info.userId === user.id);
    const typingTimestamp = new Date().toISOString();
    if (userIndex >= 0) {
      typingUsers[userIndex].timestamp = typingTimestamp;
    } else {
      typingUsers.push({ userId: user.id, timestamp: typingTimestamp });
    }
  } else {
    // Remove the current user's typing status
    typingUsers = typingUsers.filter((info) => info.userId !== user.id);
  }

  // Update Firestore after state is updated
  setDoc(doc(db, "chat", this.state.id), stamp({ typing: typingUsers }), { merge: true });
}
