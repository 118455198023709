/** @format */
import { deleteDoc, doc } from "firebase/firestore";
function deleteMedia(id) {
  try {
    return new Promise(async (resolve) => {
      // Iterate through each key (query) in the queries object
      for (const query in this.state.queries) {
        const mediaArray = this.state.queries[query];
        const mediaIndex = mediaArray.findIndex((media) => media.id === id);

        // If a match is found, delete the matched media object and update the state
        if (mediaIndex > -1) {
          // Update the media in the state
          this.setState((prevState) => ({
            queries: {
              ...prevState.queries,
              [query]: [
                ...prevState.queries[query].slice(0, mediaIndex),
                ...prevState.queries[query].slice(mediaIndex + 1),
              ],
            },
          }));

          // Delete the media from the database
          const docRef = doc(this.connection, id);
          await deleteDoc(docRef); // Assuming deleteDoc is the correct method for deleting the document
        }
      }
      resolve(true);
    });
  } catch (error) {
    this.onError(error);
    return false;
  }
}

export { deleteMedia };
