/** @format */

export function Warning(message = null, error = null) {
  const { snackbar } = this.props;
  try {
    if (error !== null) console.error(error);
    if (!message) return;
    if (message === true) message = this.unexpectedWarning;
    snackbar.open(message, "warning");
  } catch (_) {}
}
