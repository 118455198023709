/** @format */
import { Frame } from "scenejs";
import { Functional } from "unit";

const unit = Functional.unit("designer/controls");

export function getControlStyle(from = "~7") {
  unit.report({
    method: "getControlStyle",
    message: "Gets the control styles.",
    test: "Clicking on any element should show styles.",
    from: from,
  });

  function exit() {
    return new Frame({ display: "none" }).toCSSObject();
  }

  try {
    const data = this.data("~20");
    const spread = this.state?.spread?.number || 0;
    const index = this.element.selected()?.index;
    const frame = this.state?.frame?.number || 0;

    if (!data?.spreads[spread]?.elements[index]?.style[frame]) exit();

    const { top, left, width, height } = data.spreads[spread].elements[index].style[frame];

    let controls = document.getElementById("controls");

    const controlsHeight = controls.offsetHeight;
    const buffer = 100; // Adding a buffer of 100

    let newTop =
      top + height + controlsHeight + buffer < this.state.bounds.bottom ? top + height + 40 : top - controlsHeight - 40;

    // Check if controls will be entirely off-screen; include the height of the element itself in the check
    if (newTop < 0) {
      newTop = top + 40;

      if (newTop < 0) newTop = 40;
    }

    let newLeft = left + width / 2;

    if (newLeft < 0) newLeft = 40;

    return new Frame({
      display: "block",
      position: "absolute",
      top: `${newTop}px`,
      left: `${newLeft}px`,
      transform: "translateX(-50%)",
      zIndex: 50000,
    }).toCSSObject();
  } catch (e) {
    return exit();
  }
}

export function getControlVisible(from = "~56") {
  unit.report({
    method: "getControlVisible",
    message: "Gets the control visibility.",
    test: "Clicking on any element should show styles.",
    from: from,
  });
  try {
    // const selectedElements = this.state.elements;
    return (this.state.elements.length === 1 && this.state.label === null) || true;
  } catch (_) {
    return false;
  }
}

export function getHover() {
  return this.state.controls || false;
}

export function setHover(state) {
  this.setState({ controls: state });
}

export function setControlsMenuOpen() {
  const { ui } = this.props;
  ui.menu.open("controls/more");
}

export function setControlsMenuClose() {
  const { ui } = this.props;
  ui.menu.close("controls/more");
}

export function setControlsMenuToggle() {
  const { ui } = this.props;
  ui.menu.toggle("controls/more");
}
