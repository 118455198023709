/** @format */
import { Box, Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Cdn, { alt } from "cdn";
import { useCms } from "cms";
import { useMemo } from "react";
import LinesEllipsis from "react-lines-ellipsis";

import { TinyError } from "errors";

import { Link } from "react-router-dom";

import Author from "../Author";
import BlogDate from "../BlogDate";

let placeholder = Cdn(
  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_out_of_head_into_hands_f41a4d9269/thumbnail_out_of_head_into_hands_f41a4d9269.png?width=1536&height=1536"
);

export default function BlogListSmall({ start = 1, end = 4, width, orderBy = "createdAt.desc" }) {
  const theme = useTheme();
  const {
    pages,
    navigate,
    url,
    ready: { site },
  } = useCms();

  // Adjust start/end to 0-based index and extract orderField and direction
  const [orderField, ascDesc] = orderBy.split(".");

  // Memoize the filtered and sorted articles to avoid recalculating on every render
  const articles = useMemo(() => {
    return pages
      .filter((page) => page.section.toLowerCase() === "blog")
      .sort((a, b) =>
        ascDesc === "asc"
          ? new Date(a[orderField]) - new Date(b[orderField])
          : new Date(b[orderField]) - new Date(a[orderField])
      )
      .slice(start - 1, end - 1);
  }, [pages, start, end, orderField, ascDesc]);

  // Early return if there are no articles
  if (articles.length === 0) return null;

  return (
    <Grid container spacing={4} data-block="blog-list-medium">
      {articles.map((item, i) => (
        <Grid item xs={12} key={i}>
          <TinyError>
            {!site ? (
              <Skeleton sx={{ minHeight: "200px" }} />
            ) : (
              <Link
                to={url(item.slug)}
                onClick={() => navigate(item.slug)}
                style={{ textDecoration: "none", color: "#12545c" }}
                aria-label={item.title}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  borderRadius={0}
                  boxShadow={0}
                  display={"flex"}
                  flexDirection={{ xs: "column", md: "row" }}
                  sx={{ backgroundImage: "none", bgcolor: "transparent", cursor: "pointer" }}
                >
                  <Box
                    sx={{
                      width: { xs: 1, md: "30%" },
                    }}
                  >
                    <Box
                      component="img"
                      loading="lazy"
                      height={1}
                      width={1}
                      src={Cdn(item.meta.image, "small") || placeholder}
                      alt={alt(item.meta.image || placeholder)}
                      sx={{
                        objectFit: "cover",
                        maxHeight: 200,
                        borderRadius: 2,
                        filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                      }}
                    />
                  </Box>
                  <CardContent
                    sx={{
                      width: { xs: 1, md: "70%", borderRadius: "3px" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography fontWeight={700}>{item.title}</Typography>
                    <LinesEllipsis text={item.excerpt} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
                    <Box marginY={1}>
                      <Typography variant={"caption"} color={"text.secondary"}>
                        <Author {...item} /> - <BlogDate {...item} />
                      </Typography>
                    </Box>
                    <Typography color="text.secondary">{item.description}</Typography>
                  </CardContent>
                </Box>
              </Link>
            )}
          </TinyError>
        </Grid>
      ))}
    </Grid>
  );
}
