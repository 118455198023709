/** @format */
import { withLocales } from "locales";
import objectScan from "object-scan";
import { PureComponent, createContext, useContext } from "react";
import validate from "validate.js";

let config = {};

const Setup = (ConstraintConfig) => {
  config = ConstraintConfig;
};

const ConstraintsContext = createContext({});

class Constraints extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { ready: false };
    this.translated = config;
  }

  setup = () => {
    try {
      this.translate(config);
      this.setState({ ready: true });
    } catch (error) {
      throw new Error("Error loading locales.");
    }
  };

  translate = () => {
    let paths = objectScan(["*.*.message"], { joined: true })(config);
    paths.forEach((path) => {
      let keys = path.split(".");
      try {
        this.translated[keys[0]][keys[1]][keys[2]] = this.props.t(this.translated[keys[0]][keys[1]][keys[2]]);
      } catch (e) {}
    });
  };

  componentDidMount() {
    this.setup();
  }

  render() {
    return (
      <ConstraintsContext.Provider value={{ ...this.state, constraints: this.translated, validate: validate }}>
        {this.props.children}
      </ConstraintsContext.Provider>
    );
  }
}

const withConstraints = (Component) => (props) => {
  return (
    <ConstraintsContext.Consumer>{(state) => <Component {...props} constraints={state} />}</ConstraintsContext.Consumer>
  );
};

function useConstraints() {
  const value = useContext(ConstraintsContext);
  return value;
}

export default withLocales(Constraints);
export { Setup, useConstraints, withConstraints };
