/** @format */
import Container from "@Web/Container";
import {
  Box,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useCms } from "cms";
import DynamicIcon from "dynamicicon";
import Form from "getresponse";
import Iubenda, { Link as IubendaLink } from "iubenda";
import { withLocales } from "locales";
import Logo from "logo";

import isSSR from "isssr";

const StyleLink = styled(Link)(() => ({
  fontSize: ".75em",
}));

const Footer = (props) => {
  const { t } = props;
  const { link, localized } = useCms();
  const { palette } = useTheme();

  return (
    <Container
      data-testid="footer"
      sx={{
        zIndex: 1,
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: palette.common.white,
        padding: 0,
        marginTop: "0px !important",
        paddingTop: "0px !important",
      }}
      data-footer-id="container"
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        data-footer-id="section"
        style={{ marginTop: 0, paddingTop: 0 }}
      >
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              p: 1,
              m: 1,
              width: "100%",
            }}
          >
            <Box sx={{ flexGrow: 1 }} margin={0.5} padding={1}>
              <Stack flexDirection={{ xs: "column", sm: "row" }}>
                <Box sx={{ flexGrow: 1, width: { xs: "100%", sm: "50%" } }} marginTop={2}>
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton href="https://facebook.com/gomadelive" target="_blank">
                        <ListItemIcon>
                          <DynamicIcon icon="fa-facebook" />
                        </ListItemIcon>
                        <ListItemText primary="Facebook" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton href="https://instagram.com/gomadelive" target="_blank" rel="nofollow">
                        <ListItemIcon>
                          <DynamicIcon icon={"fa-instagram"} />
                        </ListItemIcon>
                        <ListItemText primary="Instagram" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton href="https://www.threads.net/@gomadelive" target="_blank" rel="nofollow">
                        <ListItemIcon>
                          <DynamicIcon icon={"fa-threads"} />
                        </ListItemIcon>
                        <ListItemText primary="Threads" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        href="https://www.youtube.com/channel/UCW-vn9fyDXeRIfczsyzZsAw"
                        target="_blank"
                        rel="nofollow"
                      >
                        <ListItemIcon>
                          <DynamicIcon icon={"fa-youtube"} />
                        </ListItemIcon>
                        <ListItemText primary="Youtube" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton href="tel:+1 888 921 6233">
                        <ListItemIcon>
                          <DynamicIcon icon={"fa-phone"} />
                        </ListItemIcon>
                        <ListItemText primary={t("footerPhone")} secondary="+1 888 921 6233" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton href="mailto:hello@made.live">
                        <ListItemIcon>
                          <DynamicIcon icon={"fa-envelope"} />
                        </ListItemIcon>
                        <ListItemText primary={t("footerEmail")} secondary="hello@made.live" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <DynamicIcon icon={"fa-map"} />
                        </ListItemIcon>
                        <ListItemText primary={t("footerAddress")} secondary="2933 30th Ave, Vernon, BC V1T-2B8" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ flexGrow: 1 }} margin={0.5} padding={1}>
              {t("footerTips")}
              <Form from="footer" />
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Divider />
          </Box>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
              <h5 style={{ margin: "5 0" }}>{t("footerFeatures")}</h5>
              <Stack direction="column" spacing={0.5}>
                <Link
                  color="primary"
                  href={localized("/pages/product/features")}
                  onClick={(event) => link(event, "pages/product/features")}
                  aria-label={t("footerOverview")}
                >
                  {t("footerOverview")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/product/pricing")}
                  onClick={(event) => link(event, "pages/product/pricing")}
                  aria-label={t("footerPricing")}
                >
                  {t("footerPricing")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/kb")}
                  onClick={(event) => link(event, "pages/kb")}
                  aria-label={t("footerKB")}
                >
                  {t("footerKB")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/rn")}
                  onClick={(event) => link(event, "pages/rn")}
                  aria-label={t("footerRN")}
                >
                  {t("footerRN")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/faq")}
                  onClick={(event) => link(event, "pages/faq")}
                  aria-label={t("footerFAQ")}
                >
                  {t("footerFAQ")}
                </Link>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
              <h5 style={{ margin: "5 0" }}>{t("footerHelpfulLinks")}</h5>
              <Stack direction="column" spacing={0.5}>
                <Link
                  color="primary"
                  href={localized("/pages/product/trim-guide")}
                  onClick={(event) => link(event, "/pages/product/trim-guide")}
                  aria-label={t("footerTrimGuide")}
                >
                  {t("footerTrimGuide")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/help")}
                  onClick={(event) => link(event, "pages/help")}
                  aria-label={t("footerHelp")}
                >
                  {t("footerHelp")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/glossary")}
                  onClick={(event) => link(event, "pages/glossary")}
                  aria-label={t("footerGlossary")}
                >
                  {t("footerGlossary")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/blog")}
                  onClick={(event) => link(event, "pages/blog")}
                  aria-label={t("footerBlog")}
                >
                  {t("footerBlog")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/status")}
                  onClick={(event) => link(event, "pages/status")}
                  aria-label={t("footerStatus")}
                >
                  {t("footerStatus")}
                </Link>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
              <h5 style={{ margin: "5 0" }}>{t("footerAboutMadelive")}</h5>
              <Stack direction="column" spacing={0.5}>
                <Link
                  color="primary"
                  href={localized("/pages/company/about")}
                  onClick={(event) => link(event, "pages/company/about")}
                  aria-label={t("footerAbout")}
                >
                  {t("footerAbout")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/company/contact")}
                  onClick={(event) => link(event, "pages/company/contact")}
                  aria-label={t("footerContact")}
                >
                  {t("footerContact")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/company/careers")}
                  onClick={(event) => link(event, "pages/company/careers")}
                  aria-label={t("footerCareers")}
                >
                  {t("footerCareers")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/press")}
                  onClick={(event) => link(event, "pages/press")}
                  aria-label={t("footerPress")}
                >
                  {t("footerPress")}
                </Link>
                <Link
                  color="primary"
                  href={localized("/pages/company/partners")}
                  onClick={(event) => link(event, "pages/company/partners")}
                  aria-label={t("footerPartners")}
                >
                  {t("footerPartners")}
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </>
        <Divider />
        <Box
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          style={{ padding: "2.5% 25%", fontSize: ".8em" }}
        >
          <Box component="span">{t("footerDisclaimer")}</Box>
          <Box component="span" id="iubenda-container" sx={{ marginLeft: "5px", a: { color: palette.success.main } }}>
            {!isSSR ? <IubendaLink /> : <Box />}
          </Box>
        </Box>
        <Divider />
        <Stack direction="row">
          <Box padding={2}>
            <StyleLink
              color="primary"
              href={localized("/pages/sitemap")}
              onClick={(event) => link(event, "pages/sitemap")}
              // onClick={() => navigate("pages/company/terms")}
              aria-label={t("sitemap")}
            >
              {t("sitemap")}
            </StyleLink>
          </Box>
          <Box padding={2}>
            <StyleLink
              color="primary"
              href={localized("/pages/company/terms")}
              onClick={(event) => link(event, "pages/company/terms")}
              // onClick={() => navigate("pages/company/terms")}
              aria-label={t("footerTerms")}
            >
              {t("footerTerms")}
            </StyleLink>
          </Box>
          <Box padding={2}>
            <StyleLink
              color="primary"
              href={localized("/pages/company/privacy")}
              onClick={(event) => link(event, "pages/company/privacy")}
              aria-label={t("footerPrivacy")}
            >
              {t("footerPrivacy")}
            </StyleLink>
          </Box>
          <Box padding={2}>
            <StyleLink
              color="primary"
              href={localized("/pages/company/privacy")}
              onClick={(event) => link(event, "pages/company/cookies")}
              aria-label={t("footerCookie")}
            >
              {t("footerCookie")}
            </StyleLink>
          </Box>
        </Stack>
        <Divider />
        <Box sx={{ margin: "20px 0" }}>
          <Logo zoom={1} wordmark={true} link="/" />
        </Box>
        <Box display="flex" flexWrap={"wrap"} alignItems={"center"}>
          <Typography
            style={{ fontSize: ".65em" }}
            align={"center"}
            variant={"subtitle2"}
            color="text.secondary"
            gutterBottom
            aria-label={t("footerCopyright")}
          >
            {t("footerCopyright")}
          </Typography>
        </Box>
      </Stack>
      <Iubenda />
    </Container>
  );
};

export default withLocales(Footer);
