/** @format */
import { FatalError } from "errors";
import { useLocales, withLocales } from "locales";
import { PureComponent, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Get the ssr state
import isSSR from "isssr";

// Get the page
import { getPage } from "./helpers/Site";

// Buttons, links helper
import { link, navigate } from "./helpers/Navigate";

// Handles back/forwards
import { popstate } from "./helpers/PopState";

// Gets the section (to hide/show stuff)
import { getSection } from "./helpers/Section";

import { setMeta } from "./helpers/Meta";

// Change localization
import { localize } from "./helpers/Localize";

const CmsContext = createContext({});

class Cms extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ready: {
        page: false,
        site: false,
        custom: false,
      },

      // Catastrophic errors
      error: null,

      // All the pages on the site
      pages: [],

      // the current page
      page: {},

      // All custom content
      custom: {},

      // No idea what this does
      single: "loading",
    };

    // Check if this is ssr
    if (isSSR) {
      // Get the page
      const { page, pages } = this.props;

      this.state.ready.page = true;
      this.state.ready.site = true;
      this.state.ready.custom = true;

      this.state.page = page;
      this.state.pages = pages;
    }

    // Navigations
    this.popstate = popstate.bind(this);

    // Navigates (onclick)
    this.navigate = navigate.bind(this);

    // link assists with crawlable internal links
    this.link = link.bind(this);

    // Localization
    this.locale = this.props.locales.locale.get();
    this.localize = localize.bind(this);

    // url
    this.url = this.props.locales.url;

    // Get the section
    this.section = getSection.bind(this);

    // Set the meta
    this.meta = {
      set: setMeta.bind(this),
    };

    // Throw an error
    this.error = (message) => {
      this.setState({ error: message });
    };

    this.localized = (url) => {
      return "/" + this.locale + url;
    };

    // Listen to the popstate event
    this.popstate();
  }

  componentDidMount() {
    // Get the page (which will load the rest)
    getPage.call(this);
  }

  render() {
    if (this.state.error) return <FatalError error={this.state.error || "Cms Error."} from="~132" />;

    // return
    return (
      <CmsContext.Provider
        value={{
          // Helps for things to know when readay
          ready: this.state.ready,

          // Page is loaded
          page: this.state.page,

          // Additional pages are loaded
          pages: this.state.pages,

          // Gets custom contents
          custom: this.state.custom,

          // Gets the section we're in
          section: this.section,

          // Navigate (wraps router navigate)
          navigate: this.navigate,

          // Link (wraps router link remains crawlable)
          link: this.link,

          // Get the current url
          url: this.url,

          // Pass down error handling to the children
          error: this.error,

          // Change the localization of the site
          localize: this.localize,

          // The meta
          meta: this.meta,

          // localise the url
          localized: this.localized,
        }}
      >
        {this.state.ready.page && this.props.children}
      </CmsContext.Provider>
    );
  }
}

/**
 * Custom hook that provides CMS functionality.
 *
 * @returns {Object} The CMS object with additional methods.
 */
const useCms = () => {
  let navigate = useNavigate();
  let { url } = useLocales();
  let Cms = useContext(CmsContext);
  return {
    ...Cms,
    link: (event, path) => {
      Cms.navigate(path)
        .then(() => {
          event.preventDefault();
          navigate(url(path));
          !isSSR && window && window.scroll(0, 0);
        })
        .catch((error) => {
          if (error == "error") Cms.error(true, "~185");
        });
    },
    navigate: (path) => {
      Cms.navigate(path)
        .then(() => {
          navigate(url(path));
          window && window.scroll(0, 0);
        })
        .catch((error) => {
          if (error == "error") Cms.error(true, "~195");
        });
    },
  };
};

/**
 * Enhances a component by providing the CMS context as a prop.
 *
 * @param {React.Component} Component - The component to be enhanced.
 * @returns {React.Component} - The enhanced component.
 */
const withCms = (Component) => {
  return function ContextualComponent(props) {
    return <CmsContext.Consumer>{(state) => <Component {...props} cms={state} />}</CmsContext.Consumer>;
  };
};

export default withLocales(Cms);
export { useCms, withCms };
