/** @format */

/**
 * Checks if any UI component is open.
 *
 * @param {string|null} payload - The payload to check for open UI components.
 * @param {string} from - The source of the check.
 * @returns {boolean} - Returns true if any UI component is open, otherwise false.
 */
export function opened(payload = null) {
  if (payload == null) {
    let open = false;
    Object.keys(this.defaultSettings).forEach((type) => {
      Object.keys(this.state.settings[type]).forEach((d) => {
        try {
          if (!this.state.settings?.[type]?.[d]) return;
          if (this.state.settings[type][d] === true || this.state.settings[type][d].open === true) open = true;
        } catch (_) {}
      });
    });
    return open;
  } else {
    try {
      return (
        (this.state.settings.dialogs[payload] &&
          this.state.settings.dialogs[payload].open &&
          this.state.settings.dialogs[payload].open === true) ||
        (this.state.settings.drawers[payload] && this.state.settings.drawers[payload] === true) ||
        (this.state.settings.menus[payload] && this.state.settings.menus[payload] === true) ||
        false
      );
    } catch (_) {
      return false;
    }
  }
}

export function close() {
  try {
    this.drawer.close();
  } catch (e) {
    console.warn(e);
  }
  try {
    this.dialog.close();
  } catch (e) {
    console.warn(e);
  }
  try {
    this.confirm.close();
  } catch (e) {
    console.warn(e);
  }
  try {
    this.alert.close();
  } catch (e) {
    console.warn(e);
  }
  try {
    this.menu.close();
  } catch (e) {
    console.warn(e);
  }
}

export function reset() {
  this.setState({ settings: this.defaultSettings });
}
