/** @format */
import { Box, Button } from "@mui/material";
import classnames from "classnames";
import DynamicIcon from "dynamicicon";
import { TinyError, useErrors } from "errors";
import { useLocales } from "locales";
import React from "react";

const SmartButton = React.memo((props) => {
  const { t } = useLocales();
  const { warn } = useErrors();
  const {
    variant = "tab",
    buttons,
    enabled,
    icon,
    sx = {},
    disabled,
    visible,
    color,
    size,
    className,
    id,
    master,
    props: buttonProps,
    stop = true,
    preventDefault = true,
    iconProps = {},
    labelProps = {},
    selected = false,
  } = props;
  let { onClick, label, slug } = props;
  if (!buttons || (!slug && !master)) return <></>;
  let params = { sx: sx };

  if (variant) params.variant = variant;
  if (color) params.varcoloriant = color;
  if (size) params.variant = size;
  if (id) params.variant = id;

  if (master && typeof master == "string") {
    slug = master;
    label = master;
  }

  /*Enabled/Disabled*/

  if (disabled == null) {
    params.enabled = enabled || buttons[slug]?.enabled || false;
    if (typeof params.enabled === "function") {
      try {
        params.enabled = params.enabled(buttonProps);
      } catch (e) {
        params.enabled = false;
      }
    } else if (typeof params.enabled !== "boolean") {
      params.enabled = false;
    }
    params.disabled = !params.enabled;
  } else {
    params.disabled = disabled;
  }
  delete params.enabled;

  /* Onclick */
  onClick = onClick || buttons[slug]?.onClick || null;
  if (onClick == null) {
    params.disabled = true;
  } else {
    params.onClick = (event) => {
      if (stop) event.stopPropagation();
      if (preventDefault) event.preventDefault();
      try {
        onClick(event, buttonProps);
      } catch (e) {
        console.error(e, "~76");
      }
    };
  }

  /*Visible*/
  params.visible = visible || buttons[slug]?.visible || true;
  if (typeof params.visible === "function") {
    try {
      params.visible = params.visible(buttonProps);
    } catch (e) {
      params.visible = true;
    }
  }
  if (!params.visible) {
    params.disabled;
    params.sx = { ...params.sx, display: "none" };
  }
  delete params.visible;

  /*Selected*/
  params.selected = selected || buttons[slug]?.selected || false;
  if (typeof params.selected === "function") {
    try {
      params.selected = params.selected(buttonProps);
    } catch (e) {
      params.selected = false;
    }
  } else if (typeof params.selected !== "boolean") {
    params.selected = false;
  }

  params.className = classnames(className && className, params.selected && "Mui-selected");

  return (
    <TinyError>
      <Button {...params} data-slug={slug}>
        {icon && <DynamicIcon icon={icon} {...iconProps} />}
        <Box component="label" {...labelProps} style={{ cursor: "pointer" }}>
          {label && t(label)}
        </Box>
      </Button>
    </TinyError>
  );
});

export default SmartButton;
