/** @format */
import { nanoid } from "nanoid";
import { Editor, Node, Path, Transforms } from "slate";
import { Functional } from "unit";
import { getInsertionPath } from "./Inserts";

const unit = Functional.unit("Manuscript");

/* Breaks */

// Make sure we can insert the break
export function canInsertBreak() {
  try {
    return !this.inserts.first() && !this.inserts.previous("break") && !this.inserts.next("break");
  } catch (e) {
    return false;
  }
}

// Insert the break
export function insertBreak(from = "~21") {
  let testId = unit.report({
    method: "insertSpreadBreak",
    message: "Checking if a spreadbreak can be entered.",
    analyze: true,
    action: true,
    from: from,
    test: "Page break should be reflective when attempting to add one.",
    steps: ["From manuscript", "Press Command+Enter", "Confirm new spread break entered"],
  });
  try {
    // Check if we're at the end to insert  paragraph ater the fact
    const isLast = this.inserts.last();

    let editor = this.editors[this.state.spread.number];

    if (!canInsertBreak.call(this)) return;

    const { selection } = editor;
    if (!selection) return; // No valid selection to insert after

    const breakNode = {
      type: "break",
      marker: true,
      id: nanoid(),
      children: [{ text: "" }],
    };

    // Get the start and end of the current block
    const startOfBlock = Editor.start(editor, selection.anchor.path);
    const endOfBlock = Editor.end(editor, selection.anchor.path);

    // Check if the cursor is at the start or end of the block
    const isAtStart = Editor.isStart(editor, selection.anchor, selection.anchor.path);
    const isAtEnd = Editor.isEnd(editor, selection.anchor, selection.anchor.path);

    let insertAt;

    if (isAtStart) {
      insertAt = startOfBlock;
    } else if (isAtEnd) {
      insertAt = Editor.after(editor, endOfBlock);
    } else {
      insertAt = Editor.after(editor, selection.anchor);
    }

    // We have to insert the break with a following p tag so withManuscript doesn't remove it
    if (isLast) {
      let editor = this.editors[this.state.spread.number];

      if (!canInsertBreak.call(this)) return;

      const { selection } = editor;
      if (!selection) return; // No valid selection to insert after

      const breakNode = { type: "break", children: [{ text: "" }] };
      let insertAt = selection.anchor;

      // Check if the selection is at the end of the editor
      const isAtEnd = Editor.isEnd(editor, insertAt, []);

      if (isAtEnd) {
        // Define a new paragraph node
        const paragraphNode = { type: "paragraph", children: [{ text: "" }] };

        // Insert a new paragraph at the end of the editor
        Transforms.insertNodes(editor, paragraphNode, { at: Editor.end(editor, []) });

        // Find the location where the new paragraph starts
        const newParagraphStart = Editor.before(editor, Editor.end(editor, []));

        // Insert the break node right before the new paragraph
        Transforms.insertNodes(editor, breakNode, { at: newParagraphStart });

        // Get the path to the last node in the editor
        const lastNodePath = [editor.children.length - 1];

        // Set the cursor at the start of the last node (which should be the new paragraph)
        Transforms.select(editor, Editor.start(editor, lastNodePath));
      } else {
        // Insert just the break node
        Transforms.insertNodes(editor, breakNode, { at: selection.anchor });
      }

      unit.passed({ testId });
    } else {
      // Insert the break node
      Transforms.insertNodes(editor, breakNode, { at: insertAt });

      // Find the point right after the inserted break node
      const pointAfterBreak = Editor.after(editor, insertAt);

      if (pointAfterBreak) {
        // Move the cursor to the point right after the inserted break node
        Transforms.select(editor, pointAfterBreak);
      } else {
        console.error("Failed to find a point after the break node.");
      }
    }

    unit.passed({
      testId: testId,
    });
  } catch (error) {
    console.log(error);
    unit.failed({
      testId: testId,
    });
  }
}

// Delete the break
export function deleteBreak() {
  console.log("Does nothing.");
}

/* Insert bookmark */

export function insertBookmark() {
  let testId = unit.report({
    method: "insertBookmark",
    message: "Inserting bookmark into manuscript",
    analyze: true,
    action: true,
    test: "Inserting a bookmark should show the icon and retain its location based on a reload.",
    steps: ["From manuscript", "Right click spread", "Select Insert Bookmark", "Confirm bookmark is entered correctly"],
  });

  try {
    const editor = this.editors[this.state.spread.number];
    const insertionPath = getInsertionPath.call(this);
    const paragraphPath = Path.parent(insertionPath);

    // Double check that we're good to go here
    if (this.markers.bookmark.can()) return false;

    // Find and remove all existing bookmark nodes
    const bookmarkNodes = Editor.nodes(editor, {
      at: [], // Search the entire document
      match: (n) => n.type === "bookmark",
    });

    for (const [_, path] of bookmarkNodes) {
      Transforms.removeNodes(editor, { at: path });
    }

    // Insert the new bookmark node
    Transforms.insertNodes(
      editor,
      {
        type: "bookmark",
        children: [{ text: "" }],
        marker: true,
        id: nanoid(),
      },
      { at: paragraphPath }
    );

    unit.passed({ testId: testId });
  } catch (error) {
    unit.failed({
      testId: testId,
    });
    // this.props.performing.set.updating("error", "~184");
  }
}

// Check if we can insert it
export function canInsertBookmark() {
  try {
    return this.focus.get("~191");
  } catch (e) {
    return false;
  }
}

// Delete the bookmark
export function deleteBookmark(element) {
  try {
    const editor = this.editors[this.state.spread.number];

    // Retrieve the path of the target element
    const path = element.path;
    if (!path) return;

    const parentPath = path.slice(0, -1);
    const parent = Node.get(editor, parentPath);
    if (parent.children.length <= 1) return;

    // Remove the target element
    Transforms.removeNodes(editor, { at: path });
  } catch (error) {
    this.props.performing.set.updating("error", "~213");
  }
}
