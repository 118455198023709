/** @format */
import { Box } from "@mui/material";
import TextGrid from "@Web/Blocks/TextGrid";
import Container from "@Web/Container";
import { useLocales } from "locales";

const Resources = (props) => {
  const { t } = useLocales();
  return (
    <Container className="gradient invert" style={{ textAlign: "center", padding: "0 2% 2% 0" }}>
      <h2 style={{ padding: "2% 0" }}>{t("homeResources")}</h2>
      <Box sx={{ width: "100%" }}>
        <TextGrid {...props} number={2} length={6} width={4} />
      </Box>
    </Container>
  );
};

export default Resources;
