/** @format */
import { Box, CircularProgress, Typography } from "@mui/material";

export default function progress(props) {
  let value = Math.round(props.value);
  let color = "secondary";
  let size = 40;

  if (value < 33) color = "error";
  if (value > 32 && value < 66) color = "warning";
  if (value > 65 && value < 90) color = "info";
  if (value > 89) color = "success";

  if (props.size) size = props.size;

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} color={color} size={size} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        size={size}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          style={{ fontSize: "9px", paddingTop: "3px" }}
        >
          {`${value}%`}
        </Typography>
      </Box>
    </Box>
  );
}
