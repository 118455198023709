/** @format */
import TextGrid from "@Web/Blocks/TextGrid";
import { Box, Typography } from "@mui/material";
import { useLocales } from "locales";

const About = (props) => {
  const { t } = useLocales();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          align={"center"}
          color={"text.secondary"}
          sx={{ textTransform: "uppercase" }}
          variant={"h3"}
          fontWeight={600}
          marginBottom={1}
        >
          {t("aboutUs")}
        </Typography>
      </Box>
      <TextGrid number={2} width={6} {...props} align="left" />
    </Box>
  );
};

export default About;
