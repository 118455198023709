/** @format */
import Image from "@Web/Blocks/Image";
import { Box } from "@mui/material";

const LeftSide = () => {
  return (
    <Box width={1}>
      <Box
        sx={{
          position: "relative",
          marginX: "auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            marginX: "auto",
          }}
        >
          <Image number={1} />
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSide;
