/** @format */

import { features } from "../../../../config/index.json";

export default function NestedPricingTable({
  duplicates = false,
  packages: packagesToInclude = ["lite", "premium", "pro"],
}) {
  // Initialize an object to store features by package
  const featuresByPackage = {};

  // Loop through packages
  packagesToInclude.forEach((p) => {
    featuresByPackage[p] = [];

    // Loop through features
    Object.keys(features).forEach((featureKey) => {
      const feature = features[featureKey];

      // Check if the current feature includes the package
      if (feature.billing.packages.includes(p)) {
        // Add the feature to the corresponding package array
        featuresByPackage[p].push(feature);
      }
    });

    // Sort features within the package by priority ascending
    featuresByPackage[p].sort((a, b) => a.priority - b.priority);

    // Optionally, prevent features from being added to the next package if already added
    if (duplicates) {
      if (p !== packagesToInclude[0]) {
        packagesToInclude.slice(0, packagesToInclude.indexOf(p)).forEach((prevPackage) => {
          featuresByPackage[p] = featuresByPackage[p].filter(
            (feature) => !featuresByPackage[prevPackage].some((prevFeature) => prevFeature.name === feature.name)
          );
        });
      }
    }
  });

  // Now we'll move the parent features if the parents don't have any children
  Object.keys(featuresByPackage).forEach((p) => {
    featuresByPackage[p]
      // .filter((c) => c?.children.legnth > 0)
      .forEach((f) => {
        if (f.children.length > 0) {
          f.children.forEach((c, i) => {
            const feature = features[c];
            const parent = features[feature.parent];
            if (feature.enabled === false) {
              f.children.slice(i, 1); // remove the child from the array
            }
            if (feature.billing.packages.length !== parent.billing.packages.length) {
              const moveTo = feature.billing.packages[0];
              f.children.slice(i, 1);
              const newFeature = { ...feature, parent: null };
              if (!featuresByPackage[moveTo].some((f) => f.slug === newFeature.slug)) {
                featuresByPackage[moveTo].push(newFeature);
              }
            }
          });
        }
      });
  });

  return featuresByPackage;
}
