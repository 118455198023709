/** @format */

import { Functional } from "unit";

const unit = Functional.unit("workspace/language");

export default async function setupLanguage(from = "~7") {
  let testId = unit.report({
    method: "setupLanguage",
    message: "Languages should load without error",
    from: "~11",
  });
  return await new Promise((resolve, reject) => {
    try {
      this.setState({ language: this.state.project.languages[0] });
      unit.passed({ testId: testId });
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error });
      reject(error);
    }
  });
}

export function getLanguage(language = null) {
  unit.report({ method: "getLanguage" });
  return language ? this.state.settings.language == language : this.state.settings.language;
}

export function getLanguages() {
  unit.report({
    method: "listLanguages",
    test: "All languages Made Live supports (english, spanish, french) should be shown.",
  });
  return this.state.project.languages;
}

export function setLanguage(language) {
  unit.report({
    method: "setLanguage",
    payload: language,
    test: "When a language has been set to change, the appropriate language is shown on the desktop.",
  });
  try {
    this.setState({ settings: { language: language } });
  } catch (error) {
    console.error(error, "~47");
  }
}
