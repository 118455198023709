/** @format */

import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useLocales } from "locales";
import React from "react";

function OfflineDialog() {
  const { t } = useLocales();
  return (
    <Dialog open={true} aria-labelledby="offline-dialog-title">
      <DialogTitle id="offline-dialog-title" data-testid="offline-dialog-title">
        {t("offlineTitle")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" data-testid="offline-dialog-message">
          {t("offlineMessage")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default OfflineDialog;
