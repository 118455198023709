/** @format */
import Cookies from "cookies";

export function setUnits() {
  try {
    this.setState(
      (prevState) => ({
        units: {
          ...prevState.units,
          value: this.state.units.value == "metric" ? "imperial" : "metric",
        },
      }),
      () => {
        Cookies.get("workspace-designer-units-value", this.state.units.value);
      }
    );
  } catch (error) {
    const { errors, t } = this.props;
    errors.open(t("unexpectedError"), error, "~19");
    reject(error);
  }
}

export function setSegment() {
  try {
    this.setState(
      (prevState) => ({
        units: {
          ...prevState.units,
          segment: this.state.units.segment == "cm" ? "mm" : "cm",
        },
      }),
      () => {
        Cookies.get("workspace-designer-units-segment", this.state.units.segment);
      }
    );
  } catch (error) {
    const { errors, t } = this.props;
    errors.open(t("unexpectedError"), error, "~39");
    reject(error);
  }
}

export function getSegment(segment) {
  return segment ? this.state.units.segment == segment : this.state.units.segment;
}
