/** @format */

import { nanoid } from "nanoid";
import { Functional } from "unit";
import ShapeTemplate from "../../config/templates/shape.json";

const unit = Functional.unit("designer/shape");

export function addShapeFromElements(props, from = "~9") {
  unit.report({
    method: "addShapeFromElements",
    payload: props,
    action: true,
    analyze: true,
    test: "Drags a shape from elements to spread.",
    from: from,
  });
  const { t, errors } = this.props;
  return new Promise((resolve, reject) => {
    try {
      const id = nanoid();
      const { top, left, url } = props;
      const element = {
        ...ShapeTemplate,
        id: id,
        url: url,
        style: [
          {
            ...ShapeTemplate.style,
            left: left,
            top: top,
          },
        ],
      };

      this.element.add(element).then(() => {
        resolve();
      });
    } catch (error) {
      errors.error(t("unexpectedError"), error, "~40");
      reject(error);
    }
  });
}
