/** @format */
import authProviderData from "./helpers/authProviderData";
import authProviderLink from "./helpers/authProviderLink";
import authProviderUnlink from "./helpers/authProviderUnlink";
import forget from "./helpers/forget";
import getAnonymous from "./helpers/getAnonymous";
import getAuthenticated from "./helpers/getAuthenticated";
import getSecurityRating from "./helpers/getSecurityRating";
import observe from "./helpers/observe";
import reauthenticateWithEmail from "./helpers/reauthenticateWithEmail";
import reauthenticateWithProvider from "./helpers/reauthenticateWithProvider";
import reauthenticationProvider from "./helpers/reauthenticationProvider";
import reauthenticationRequired from "./helpers/reauthenticationRequired";
import removeAccount from "./helpers/removeAccount";
import removeAvatar from "./helpers/removeAvatar";
import resetPassword from "./helpers/resetPassword";
import sendVerificationEmail from "./helpers/sendVerificationEmail";
import setAvatar from "./helpers/setAvatar";
import setEmail from "./helpers/setEmail";
import setPassword from "./helpers/setPassword";
import signInAnonymously from "./helpers/signInAnonymously";
import signInWithAuthProvider from "./helpers/signInWithAuthProvider";
import signInWithEmail from "./helpers/signInWithEmail";
import signInWithLink from "./helpers/signInWithLink";
import signInWithLinkComplete from "./helpers/signInWithLinkComplete";
import signOut from "./helpers/signOut";
import signUpWithEmail from "./helpers/signUpWithEmail";
import signUpWithEmailComplete from "./helpers/signUpWithEmailComplete";

export default class Authentication {
  constructor({ onAuthenticated, onDeauthenticated }) {
    this.onAuthenticated = onAuthenticated;
    this.onDeauthenticated = onDeauthenticated;
    return this;
  }
  observe = () => {
    this.observer = observe({
      onAuthenticated: this.onAuthenticated,
      onDeauthenticated: this.onDeauthenticated,
    });
    return this;
  };
  forget = () => {
    forget(this.observer);
    return this;
  };

  authProviderData = authProviderData;
  authProviderLink = authProviderLink;
  authProviderUnlink = authProviderUnlink;
  getAnonymous = getAnonymous;
  getAuthenticated = getAuthenticated;
  getSecurityRating = getSecurityRating;
  removeAccount = removeAccount;
  removeAvatar = removeAvatar;
  resetPassword = resetPassword;
  setAvatar = setAvatar;
  setEmail = setEmail;
  setPassword = setPassword;
  signInAnonymously = signInAnonymously;
  signInWithAuthProvider = signInWithAuthProvider;
  signInWithEmail = signInWithEmail;
  signInWithLink = signInWithLink;
  signInWithLinkComplete = signInWithLinkComplete;
  signOut = signOut;
  signUpWithEmail = signUpWithEmail;
  signUpWithEmailComplete = signUpWithEmailComplete;
  sendVerificationEmail = sendVerificationEmail;
  reauthenticationRequired = reauthenticationRequired;
  reauthenticationProvider = reauthenticationProvider;
  reauthenticateWithProvider = reauthenticateWithProvider;
  reauthenticateWithEmail = reauthenticateWithEmail;
}
