/** @format */

export function canDo(type) {
  try {
    if (this.editor.loaded() == 0) return false;
    if (type == "undo") {
      return (
        this.editors[this.state.spread.number] &&
        this.editors[this.state.spread.number].history &&
        this.editors[this.state.spread.number].history.undos &&
        this.editors[this.state.spread.number].history.undos.length > 0
      );
    } else {
      return (
        this.editors[this.state.spread.number] &&
        this.editors[this.state.spread.number].history &&
        this.editors[this.state.spread.number].history.redos &&
        this.editors[this.state.spread.number].history.redos.length > 0
      );
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function undo() {
  try {
    if (this.editor.loaded() == 0) return () => {};
    this.editors[this.state.spread.number].undo();
  } catch (error) {
    return () => {};
  }
}

export function redo() {
  try {
    if (this.editor.loaded() == 0) return () => {};
    this.editors[this.state.spread.number].redo();
  } catch (error) {
    return () => {};
  }
}
