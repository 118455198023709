/** @format */

import { Box, Typography } from "@mui/material";
import { useCms } from "cms";
import { Comments } from "discussion";
import { useLocales } from "locales";

import { TinyError } from "errors";

export default function () {
  // Get the page to see if enabled
  const { page } = useCms();

  // Get the locales
  const { t } = useLocales();

  // Do this to remove the formatting
  if (!page.comments) return null;

  return (
    <TinyError>
      <Box>
        <Typography variant="h4" component="h4" sx={{ padding: "2.5% 0 5%" }}>
          {t("comments")}
        </Typography>
        <Comments
          slug={page.path}
          enabled={page.comments}
          placeholder="tellUsWhatYouThink"
          moderate={true}
          analyze={true}
          replies={true}
        />
      </Box>
    </TinyError>
  );
}
