/** @format */
import React, { useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import { debug, elapsedTime, idleTime } from "./config";

const MadeIdle = ({ onActive = [], onIdle = [], children }) => {
  const [activeTimestamp, setActiveTimestamp] = useState(Date.now());
  const [idleTimestamp, setIdleTimestamp] = useState(null);
  const intervalRef = useRef(null);

  // Native functions
  const onActiveNative = () => {
    if (debug) console.log("MadeIdle:", "User is active.");
  };
  const onIdleNative = () => {
    if (debug) console.log("MadeIdle:", "User is idle.");
  };

  const handleOnActive = () => {
    setActiveTimestamp(Date.now());
    setIdleTimestamp(null);
    [onActiveNative, ...onActive].forEach((fn) => fn());
  };

  const handleOnIdle = () => {
    setIdleTimestamp(Date.now());
    [onIdleNative, ...onIdle].forEach((fn) => fn());
  };

  const { isIdle } = useIdleTimer({
    timeout: idleTime,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    events: ["mousemove", "keydown", "mousedown"],
  });

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const elapsedTime = isIdle() ? Date.now() - idleTimestamp : Date.now() - activeTimestamp;

      if (isIdle()) {
        if (debug) console.log("MadeIdle:", `User has been idle for ${elapsedTime} ms`);
      } else {
        if (debug) console.log("MadeIdle:", `User has been active for ${elapsedTime} ms`);
      }
    }, elapsedTime); // Every 30 seconds

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [activeTimestamp, idleTimestamp, isIdle]);

  return <>{children}</>;
};

export default MadeIdle;
