/** @format */
import { Box, Button, Card, InputAdornment, Stack, TextField } from "@mui/material";
import { useLocales } from "locales";
import { useEffect, useRef, useState } from "react";
import { adjectives, animals, uniqueNamesGenerator } from "unique-names-generator";

export default function Start({ setValue, next, values }) {
  const { t } = useLocales();

  const [credits, setCredits] = useState("");
  const [title, setTitle] = useState("");

  const [isCreditsFocused, setIsCreditsFocused] = useState(false);

  const titleRef = useRef(null);
  const creditsRef = useRef(null);

  const handleCreditsChange = (event) => {
    setCredits(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContinue = () => {
    setValue("credits", credits);
    setValue("title", title);
    next();
  };

  const generateName = () => {
    const randomName = uniqueNamesGenerator({
      dictionaries: [adjectives, animals],
      style: "capital",
      separator: " ",
      length: 2,
    });
    setTitle("The " + randomName);

    // Focus the credits TextField after generating the name
    creditsRef.current.focus();
  };

  useEffect(() => {
    if (values?.title && values.title != title) {
      setTitle(values.title);
    }
    if (values?.credits && values.credits != credits) {
      setCredits(values.credits);
    }
  }, [values?.title, values?.credits]);

  return (
    <Card elevation={0}>
      <h3 style={{ textAlign: "center" }}>
        {t("letsGetStarted")}{" "}
        <Box sx={{  fontSize: "12px", textAlign: "center" }}>{t("onboardSetupNotes")}</Box>
      </h3>
      <Stack direction="row" spacing={5} justifyContent="center" alignItems="center" sx={{ marginBottom: "10px" }}>
        <Box
          sx={{
            width: 420,
            padding: "20px",
            minHeight: "345px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <TextField
              label={t("title")}
              value={title}
              onChange={handleTitleChange}
              fullWidth
              style={{ marginBottom: "20px" }}
              inputRef={titleRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!isCreditsFocused && (
                      <Button variant="contained" size="small" onClick={generateName}>
                        {t("generateName")}
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={t("authorName")}
              value={credits}
              onChange={handleCreditsChange}
              fullWidth
              style={{ marginBottom: "20px" }}
              inputRef={creditsRef}
              onFocus={() => setIsCreditsFocused(true)}
              onBlur={() => setIsCreditsFocused(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && title && credits) {
                  handleContinue();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {credits && title && (
                      <Button variant="contained" color="success" size="small" onClick={handleContinue}>
                        {t("continue")}
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Card>
  );
}
