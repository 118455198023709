/** @format */
import Container from "@Web/Container";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Cdn, { alt } from "cdn";
import { useLocales } from "locales";

const Hero = () => {
  const theme = useTheme();
  const { t } = useLocales();
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: theme.palette.info.main,
        backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.success.dark} 100%)`,
        marginTop: -13,
        paddingTop: 13,
      }}
    >
      <Container>
        <Box>
          <Box marginBottom={{ xs: 0, sm: 4 }} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography
              variant="h1"
              gutterBottom
              align={"center"}
              sx={{
                fontWeight: 900,
              }}
            >
              {t("workWithUs")}
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.primary"
              align={"center"}
              sx={{ marginBottom: 2, margin: "2% 10%" }}
            >
              {t("careersSubtitle")}
            </Typography>
            <Button variant="contained" color="success" size="large" href="#jobs">
              {t("careersHeroButton")}
            </Button>
          </Box>
          <Grid container spacing={2} sx={{ marginTop: "100px", display: { xs: "none", sm: "flex" } }}>
            <Grid item container justifyContent={"flex-end"} alignItems={"flex-end"} xs={4}>
              <Box
                component="img"
                loading="lazy"
                height={1}
                width={1}
                borderRadius={2}
                role="img"
                src={Cdn(
                  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_about_us_8a242c32a1/thumbnail_about_us_8a242c32a1.png?updated_at=2023-02-16T22:29:00.318Z"
                )}
                alt={alt(
                  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_about_us_8a242c32a1/thumbnail_about_us_8a242c32a1.png?updated_at=2023-02-16T22:29:00.318Z"
                )}
                sx={{
                  objectFit: "cover",
                  filter: theme.palette.mode === "dark" ? "brightness(0.6)" : "none",
                }}
              />
            </Grid>
            <Grid item container justifyContent={"flex-start"} alignItems={"flex-end"} xs={8}>
              <Box
                component="img"
                loading="lazy"
                height={1}
                width={1}
                borderRadius={2}
                src={Cdn(
                  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_careers_hero_image_6c1ac3ffef/thumbnail_careers_hero_image_6c1ac3ffef.png?updated_at=2023-02-16T22:23:00.532Z"
                )}
                alt={alt(
                  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_careers_hero_image_6c1ac3ffef/thumbnail_careers_hero_image_6c1ac3ffef.png?updated_at=2023-02-16T22:23:00.532Z"
                )}
                sx={{
                  objectFit: "cover",
                  filter: theme.palette.mode === "dark" ? "brightness(0.6)" : "none",
                }}
              />
            </Grid>
            <Grid item container justifyContent={"flex-end"} alignItems={"flex-start"} xs={8}>
              <Box
                component="img"
                loading="lazy"
                height={1}
                width={1}
                borderRadius={2}
                src={Cdn(
                  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_empowerment_ca97c94456/thumbnail_empowerment_ca97c94456.png?updated_at=2023-02-16T22:25:03.766Z"
                )}
                alt={alt(
                  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_empowerment_ca97c94456/thumbnail_empowerment_ca97c94456.png?updated_at=2023-02-16T22:25:03.766Z"
                )}
                sx={{
                  objectFit: "cover",
                  filter: theme.palette.mode === "dark" ? "brightness(0.6)" : "none",
                }}
              />
            </Grid>
            <Grid item container justifyContent={"flex-start"} alignItems={"flex-start"} xs={4}>
              <Box
                component="img"
                loading="lazy"
                height={1}
                width={1}
                borderRadius={2}
                src={Cdn(
                  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_Contact_us_f17dc7a071/thumbnail_Contact_us_f17dc7a071.png?updated_at=2023-02-16T22:22:22.122Z"
                )}
                alt={alt(
                  "https://storage.googleapis.com/made-live-application-strapi/thumbnail_Contact_us_f17dc7a071/thumbnail_Contact_us_f17dc7a071.png?updated_at=2023-02-16T22:22:22.122Z"
                )}
                sx={{
                  objectFit: "cover",
                  filter: theme.palette.mode === "dark" ? "brightness(0.6)" : "none",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
