/** @format */

export function setReady(state) {
  // Get the user context
  const { user } = this.props;

  // Check i the user is ready
  if (!user) return;

  // Set the state
  this.setState({ ready: state });
}

// Probably an artifact
export function getReady() {
  // Check if it's ready
  return this.state?.ready || false;
}
