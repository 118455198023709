/** @format */

import { OAuthProvider, linkWithPopup } from "firebase/auth";
import { auth } from "firemade";

export default function linkAuthProvider(provider) {
  return new Promise((resolve, reject) => {
    if (!provider) {
      reject(new Error("No provider"));
      return;
    }

    // Create the OAuth provider instance
    const authProvider = new OAuthProvider(provider.id);
    const scopes = provider.scopes;

    // Add scopes if available
    if (scopes) {
      scopes.forEach((scope) => {
        authProvider.addScope(scope);
      });
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    // Link the provider using popup
    linkWithPopup(currentUser, authProvider)
      .then((value) => {
        resolve(value);
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
