/** @format */
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const mock = [
  {
    title: "Front-End Developer",
    location: "Madrid",
    type: "Remote",
    team: "Consumer",
    subtitle: "Responsible for design systems and brand management.",
  },
  {
    title: "Community Manager",
    location: "Paris",
    type: "Full time",
    team: "Consulting",
    subtitle: "Responsible for creating life in our apps.",
  },
  {
    title: "UX/UI Designer",
    location: "Yerevan",
    type: "Part time",
    team: "Internal tools",
    subtitle: "Help us make the best decisions with qualitative experiments.",
  },
  {
    title: "Front-End Developer",
    location: "Madrid",
    type: "Remote",
    team: "Internal tools",
    subtitle: "Responsible for design systems and brand management.",
  },
  {
    title: "Community Manager",
    location: "Paris",
    type: "Full time",
    team: "Consulting",
    subtitle: "Responsible for creating life in our apps.",
  },
  {
    title: "UX/UI Designer",
    location: "Yerevan",
    type: "Part time",
    team: "Consumer",
    subtitle: "Help us make the best decisions with qualitative experiments.",
  },
  {
    title: "Front-End Developer",
    location: "Madrid",
    type: "Remote",
    team: "Consumer",
    subtitle: "Responsible for design systems and brand management.",
  },
  {
    title: "Community Manager",
    location: "Paris",
    type: "Full time",
    team: "Consulting",
    subtitle: "Responsible for creating life in our apps.",
  },
];

const Jobs = () => {
  const theme = useTheme();
  return (
    <Box>
      <a name="jobs"></a>
      {/* 
      <Box marginBottom={4}>
        <Typography
          align={"center"}
          color={"white"}
          sx={{ textTransform: "uppercase" }}
          variant={"subtitle2"}
          fontWeight={600}
        >
          Open positions
        </Typography>
        <Typography fontWeight={700} variant={"h4"} align={"center"} color={"white"}>
          Current job openings
        </Typography>
      </Box>
      <Grid
        container
        spacing={4}
        sx={{
          ".MuiOutlinedInput-root": {
            background: theme.palette.background.paper,
          },
        }}
      >
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ minWidth: 1 }}>
            <InputLabel id="career-listing__jobs-role--label"></InputLabel>
            <Select labelId="career-listing__jobs-role--label" label="Roles">
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value={"design"}></MenuItem>
              <MenuItem value={"engineering"}></MenuItem>
              <MenuItem value={"product"}></MenuItem>
              <MenuItem value={"support"}></MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ minWidth: 1 }}>
            <InputLabel id="career-listing__jobs-role--label"></InputLabel>
            <Select labelId="career-listing__jobs-role--label" label="Teams">
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value={"consumer"}></MenuItem>
              <MenuItem value={"consulting"}></MenuItem>
              <MenuItem value={"internal-tools"}></MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" sx={{ minWidth: 1 }}>
            <InputLabel id="career-listing__jobs-role--label"></InputLabel>
            <Select labelId="career-listing__jobs-role--label" label="Locations">
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value={"milan"}></MenuItem>
              <MenuItem value={"yerevan"}></MenuItem>
              <MenuItem value={"paris"}></MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        flex={"1 1 100%"}
        justifyContent={{ sm: "space-between" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        marginY={4}
      >
        <Box marginBottom={{ xs: 1, sm: 0 }}>
          <Typography variant={"h6"} fontWeight={700} color="white">
          </Typography>
          <Typography color={"white"}></Typography>
        </Box>
        <Box paddingY={1 / 2} paddingX={1} bgcolor={"secondary.main"} borderRadius={2} marginRight={1}>
          <Typography variant={"caption"} fontWeight={700} sx={{ color: "common.black" }}>
            {mock.length}
          </Typography>
        </Box>
      </Box> */}
      <h2 style={{ padding: 20, textAlign: "center", color: "white" }}>There are currently no job openings.</h2>
      <Grid
        container
        sx={{
          background: theme.palette.background.paper,
          borderRadius: 2,
        }}
      >
        {[].map((item, i) => (
          <Grid
            item
            xs={12}
            key={i}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              "&:last-child": {
                borderBottom: 0,
              },
            }}
          >
            <Box padding={2} display={"flex"} alignItems={"center"}>
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", sm: "row" }}
                flex={"1 1 100%"}
                justifyContent={{ sm: "space-between" }}
                alignItems={{ sm: "center" }}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  <Typography variant={"subtitle1"} fontWeight={700}>
                    {item.title}
                  </Typography>
                  <Typography color={"text.secondary"}>{item.subtitle}</Typography>
                </Box>
                <Typography color={"text.secondary"}>{`${item.team} / ${item.location}`}</Typography>
              </Box>
              <Box marginLeft={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={
                    <Box
                      component={"svg"}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      width={12}
                      height={12}
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </Box>
                  }
                ></Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Jobs;
