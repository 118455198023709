/** @format */

import { Functional } from "unit";

const unit = Functional.unit("workspace/context");

export function openContext(event, props = {}, from = "~7") {
  let testId = unit.report({
    method: "openContext",
    steps: ["From manuscript", "Right click spread", "Context menu should display"],
    test: "Context Menu (right click menu) should open as expected and display in the correct position.",
    action: true,
    analyze: true,
    from: from,
  });
  try {
    event.preventDefault();
    this.setState({
      settings: {
        ...this.state.settings,
        ...{
          context: {
            open: true,
            mouseX: event.clientX + 2,
            mouseY: event.clientY,
            props: props,
          },
        },
      },
    });
    unit.passed({ testId: testId });
  } catch (error) {
    unit.failed({ testId: testId, error: error });
  }
}

export function closeContext(from = "~37") {
  let testId = unit.report({
    method: "closeContext",
    test: "Context Menu (right click menu) should close as expected without issue.",
    action: true,
    analyze: true,
  });
  try {
    this.setState({
      settings: { ...this.state.settings, ...{ context: false } },
    });
    unit.passed({ testId: testId }, from);
  } catch (error) {
    console.error(33, error);
    unit.failed({ testId: testId, error: error }, from);
  }
}

export function isOpenContext(from = "~55") {
  let testId = unit.report(
    { method: "isOpenContext", from: from, message: "Checks if the context menu is open." },
    from
  );
  try {
    unit.passed({ testId: testId });
    return this.state.settings.context.open;
  } catch (error) {
    unit.failed({ testId: testId, error: error });
  }
}
