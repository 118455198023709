/** @format */
import { Box } from "@mui/material";
import { PageError } from "errors";

export default function (props) {
  return (
    <PageError>
      <Container {...props} />
    </PageError>
  );
}

export function Container({ children, ...rest }) {
  return (
    <Box
      maxWidth={{ sm: 720, md: 1236 }}
      width={1}
      margin={"0 auto"}
      paddingX={2}
      paddingY={{ xs: 4, sm: 6, md: 8 }}
      data-testid="container"
      {...rest}
    >
      {children}
    </Box>
  );
}
