/** @format */
import { Component as ReactComponent, createContext, useContext } from "react";

import { withBilling } from "@Workspace/Billing";
import { withActions } from "actions";
import { withBroadcast } from "broadcast";
import { withCms } from "cms";
import { withErrors } from "errors";
import { withLocales } from "locales";
import { withMedia } from "media";
import { withPerforming } from "performing";
import { withSnackbar } from "snackbar";
import { withTour } from "tour";
import { withUI } from "ui";
import { withUnit } from "unit";
import { withUser } from "user";

import Cookies from "cookies";
import { withRouter } from "router";

import Access from "../dialogs/components/Access";

/* -------- HELPERS -------- */

// Setup
import setupWorkspace from "./helpers/Setup";

// Ready helper
import { getReady, setReady } from "./helpers/Ready";

// Saving
import {
  getProjectData,
  getProjectId,
  getProjectToken,
  restoreProjectRevision,
  saveProject,
  saveProjectRevision,
  updateFeature,
  updateProject,
  updateStep,
} from "./helpers/Project";

// Revisions
import { getRevisionId } from "./helpers/Revision";

// Boards
import { closeBoard, getBoards, minimizeBoard, openBoard } from "./helpers/Board";

// Users
import { getUser, isUser } from "./helpers/User";

// Assist
import { askAssist, assistOpened, closeAssist, openAssist, toggleAssist } from "./helpers/Assist";

// Tips
import {
  closeTips,
  dismissTipsListText,
  getMuted,
  getTipsProps,
  getTipsText,
  getWorking,
  openTips,
  setTipsProps,
  setTipsText,
  setWorking,
  tipsListFiltered,
  tipsOpened,
  toggleMute,
} from "./helpers/Tips";

// Checklist
import {
  getChecklist,
  getChecklistFlat,
  getChecklistOpen,
  getChecklistTime,
  getProgress,
  recalculateChecklist,
  setChecklistOpen,
  toggleChecklistOpen,
} from "./helpers/Checklist";

// Checklist/Steps
import { getStep, getStepCompleted, getSteps, setStep, stepActions } from "./helpers/Step";

// Context Providers
import { closeContext, isOpenContext, openContext } from "./helpers/Context";

// Desks
import { getDesk, getDeskCss, setDesk } from "./helpers/Desk";

// Dialogs
import { closeDialog, openDialog } from "./helpers/Dialog";

// Widget
import { getWidget, getWidgetProps, setWidget, setWidgetProps } from "./helpers/Widget";

// INtroe
import { openIntro } from "./helpers/Intro";

// DnD
import { getDragging, onDragEnd, onDragStart, onDrop } from "./helpers/Drag";

// Resposition
import {
  getReposition,
  getRepositionAvailable,
  resetReposition,
  setReposition,
  setRepositionAvailable,
} from "./helpers/Reposition";

// Features
import { getFeature, getFeatureAvailable, getFeatureData, getFeatureSelected, selectFeature } from "./helpers/Feature";

// Grid
import { getGrid, gridSizes, setGrid } from "./helpers/Grid";

// Languages
import { getLanguage, getLanguages, setLanguage } from "./helpers/Language";

// Menus
import {
  getMenuFeaturesAll,
  getMenuFeaturesAvailable,
  getMenuFeaturesSelected,
  getMenuFeaturesUnselected,
} from "./helpers/Menu";

// Sharing
import { closeShare, openShare } from "./helpers/Share";

// Tour
import { getTours, startTour } from "./helpers/Tour";

// Intro
import { getIntros } from "./helpers/Intro";

// Zooming
import { canZoom, getZoom, setZoom } from "./helpers/Zoom";

// Confirm
import { closeConfirm, openConfirm } from "./helpers/Confirm";

// HumanMade ID
import { getHmID, getHmUrl } from "./helpers/Hm";

// HumanMade ID
import { getQrUrl } from "./helpers/Qr";

// Billing

// Access (security) control
import { closeAccess, openAccess } from "./helpers/Access";

// Ai/generation logs
import { logAi } from "./helpers/AI";

// Get the beta status
import { getBeta } from "./helpers/Beta";

// Teardown
import Teardown from "./helpers/Teardown";

const WorkspaceContext = createContext({});

class Workspace extends ReactComponent {
  constructor(props) {
    super(props);

    this.unit = this.props.unit.new(this.constructor.name);
    this.unit.report({
      method: "constructor",
      message: "Workspace consutructed from context.",
      test: "Workspace should load without errors.",
      from: "~176",
    });

    const { router } = this.props;

    this.id = router.params.workspaceId || null;

    const defaultDesk = "planning"; // Cookies.get("workspace-desk-" + this.id) || "planning"; // storyboard

    if (!Cookies.get("workspace-desk-" + this.id)) Cookies.set("workspace-desk-" + this.id, defaultDesk, "~185");
    if (!Cookies.get("workspace-feature-" + this.id)) Cookies.set("workspace-desk-" + this.id, defaultDesk, "~186");

    this.desk = router.params.desk || Cookies.get("workspace-desk-" + this.id, "~188") || defaultDesk;
    this.feature = router.params.feature || Cookies.get("workspace-feature-" + this.id, "~189") || defaultDesk;

    this.connection = null;

    this.state = {
      onboarding: false,
      loaded: false,
      ready: [],
      intro: false,
      settings: {
        feature: this.desk,
        desk: this.desk,
        // The next three should move out of settings
        dialog: null,
        info: null,
        boards: {},
        zoom: 0.7,
        language: "en-us",
        grid: "half",
        context: {
          open: false,
          mouseX: 0,
          mouseY: 0,
        },
      },

      project: {
        id: this.id,
        userId: null,
        token: null,
        features: {},
        languages: ["en-us"],
      },
      checklist: {
        open: false,
        progress: {
          total: 0,
          complete: 0,
          remaining: 0,
          percent: 0,
        },
        steps: {},
      },
      ai: [],
      dragging: false,
      reposition: false,

      widgets: {},
      share: false,

      // Assist
      assist: false,

      // Tips
      tips: {
        text: null,
        opened: false,
        props: {},
        muted: false,
        working: false,
      },
    };

    this.features = {};
    this.contexts = {};
    this.ui = {};
    this.widgets = {};
    this.workers = {};
    this.intros = {};
    this.tours = {};

    ["desks", "dialogs", "menus", "panels", "boards", "assist", "share"].forEach((ui) => {
      this[ui] = {};
    });

    this.backup = -1;

    // ----- We'll "memoize" these and pass them to context value

    // Global id
    this.id = getProjectId.call(this);
    this.revisionId = getRevisionId.call(this); // # TODO necessary?

    // token for accessing api
    this.token = getProjectToken.bind(this);

    // Access (security) control
    this.access = {
      open: openAccess.bind(this),
      close: closeAccess.bind(this),
    };

    // # TODO This might be unneeded now
    this.project = this.state.project;

    // When ready
    this.ready = {
      get: getReady.bind(this),
      set: setReady.bind(this),
    };

    // Zoom
    this.zoom = {
      set: setZoom.bind(this),
      can: canZoom.bind(this),
      get: getZoom.bind(this),
      zooms: [0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2],
    };

    // Project data
    this.data = {
      project: getProjectData.bind(this),
      feature: getFeatureData.bind(this),
      features: getFeatureData.bind(this),
    };

    // Unthrottled saves (to collection)
    this.save = saveProject.bind(this);

    // Project backups
    this.backup = saveProjectRevision.bind(this);
    this.restore = restoreProjectRevision.bind(this);

    // Project data updates (to state)
    this.update = {
      project: updateProject.bind(this),
      feature: updateFeature.bind(this),
      step: updateStep.bind(this),
    };

    // Assist
    this.assist = {
      opened: assistOpened.bind(this),
      toggle: toggleAssist.bind(this),
      open: openAssist.bind(this),
      close: closeAssist.bind(this),
    };

    // Ask for help
    this.ask = askAssist.bind(this);

    // Tips
    this.tips = {
      open: openTips.bind(this),
      close: closeTips.bind(this),
      opened: tipsOpened.bind(this),
      text: {
        get: getTipsText.bind(this),
        set: setTipsText.bind(this),
      },
      working: { set: setWorking.bind(this), get: getWorking.bind(this) },
      mute: {
        set: toggleMute.bind(this),
        get: getMuted.bind(this),
      },
      props: {
        get: getTipsProps.bind(this),
        set: setTipsProps.bind(this),
      },
      list: {
        dismiss: dismissTipsListText.bind(this),
        filtered: tipsListFiltered.bind(this),
      },
    };

    // Checklist
    this.checklist = {
      open: {
        get: getChecklistOpen.bind(this),
        set: setChecklistOpen.bind(this),
        toggle: toggleChecklistOpen.bind(this),
      },
      get: getChecklist.bind(this),
      progress: getProgress.bind(this),
      steps: getSteps.bind(this),
      step: {
        get: getStep.bind(this),
        set: setStep.bind(this),
        actions: stepActions.bind(this),
        completed: getStepCompleted.bind(this),
      },
      flat: getChecklistFlat.bind(this),
      time: getChecklistTime.bind(this),
      recalculate: recalculateChecklist.bind(this),
    };

    // Saves ai stats
    this.ai = {
      log: logAi.bind(this),
    };

    // Project progress
    this.progress = getProgress.bind(this);

    // Features
    this.feature = {
      select: selectFeature.bind(this),
      available: getFeatureAvailable.bind(this),
      selected: getFeatureSelected.bind(this),
      get: getFeature.bind(this),
    };

    // Languages
    this.language = {
      get: getLanguage.bind(this),
      set: setLanguage.bind(this),
    };

    this.languages = {
      get: getLanguages.bind(this),
    };

    // Usable context providers
    this.usable = this.contexts;

    this.widget = {
      set: setWidget.bind(this),
      get: getWidget.bind(this),
      props: {
        get: getWidgetProps.bind(this),
        set: setWidgetProps.bind(this),
      },
    };

    // Desks
    this.desk = {
      get: getDesk.bind(this),
      set: setDesk.bind(this),
      css: getDeskCss.bind(this),
      drag: onDragStart.bind(this),
    };

    // Workspace dialogs
    this.dialog = {
      open: openDialog.bind(this),
      close: closeDialog.bind(this),
    };

    // Confirm windows
    this.confirm = {
      open: openConfirm.bind(this),
      close: closeConfirm.bind(this),
    };

    // Boards
    this.board = {
      open: openBoard.bind(this),
      close: closeBoard.bind(this),
      minimize: minimizeBoard.bind(this),
      get: getBoards.bind(this),
    };

    // Menus (needs love)
    this.menu = {
      features: {
        all: getMenuFeaturesAll.bind(this),
        available: getMenuFeaturesAvailable.bind(this),
        enabled: getMenuFeaturesAvailable.bind(this),
        selected: getMenuFeaturesSelected.bind(this),
        unselected: getMenuFeaturesUnselected.bind(this),
      },
      context: {
        open: openContext.bind(this),
        close: closeContext.bind(this),
        opened: isOpenContext.bind(this),
        mouseX: 0,
        mouseY: 0,
      },
    };

    // Grid view (on desk)
    this.grid = {
      set: setGrid.bind(this),
      get: getGrid.bind(this),
      size: getGrid.bind(this),
      sizes: gridSizes.bind(this),
    };

    // DnD
    this.drag = {
      drop: onDrop.bind(this),
      end: onDragEnd.bind(this),
      start: onDragStart.bind(this),
      dragging: getDragging.bind(this),
    };

    // Sharing
    this.share = {
      open: openShare.bind(this),
      close: closeShare.bind(this),
    };

    // Tour
    this.tour = {
      start: startTour.bind(this),
    };

    // The tours available
    this.tours = getTours.bind(this);

    // Intro
    this.intro = {
      open: openIntro.bind(this),
    };

    this.intros = getIntros.bind(this);

    // Reposition Drag
    this.reposition = {
      available: {
        get: getRepositionAvailable.bind(this),
        set: setRepositionAvailable.bind(this),
      },
      set: setReposition.bind(this),
      get: getReposition.bind(this),
      reset: resetReposition.bind(this),
    };

    // Should be reduced
    this.hm = {
      id: getHmID.call(this),
      url: getHmUrl.call(this),
    };

    // Good
    this.qr = getQrUrl.call(this);

    // Users, needs some love
    this.user = {
      id: getUser.bind(this),
    };

    // The users (for team)
    this.users = [{ role: "owner", id: getUser.bind(this) }];

    // Check who the owner is
    this.owner = {
      id: getUser.bind(this),
      is: isUser.bind(this),
    };

    // Ai logging
    this.aiInterval = null;

    // beta status
    this.beta = getBeta.bind(this);
  }

  /* --------------- REACT ---------------- */

  componentDidUpdate() {
    try {
      this.props.broadcast.listen(this, "~534");
    } catch (_) {}
  }

  componentDidMount() {
    let testId = this.unit.report({
      method: "componentDidMount",
      message: "Component mounted from context.",
      test: "Workspace should mount without errors and be visually inspected.",
      from: "~543",
    });
    try {
      setupWorkspace
        .call(this, "~547")
        .then(() => {
          this.unit.report({
            method: "componentDidMount",
            message: "Successfully mounted workspace.",
            from: "~552",
            testId: testId,
            passed: true,
          });
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error(error, "~561");
      throw error;
    }
  }

  componentWillUnmount() {
    let testId = this.unit.report({
      method: "componentWillUnmount",
      test: "Broadcast listener should allow components to listen to one another. A way to test this is to move images to the designer.",
      message: "Component will unmount from component context.",
      from: "~571",
    });
    try {
      Teardown.call(this, "~574");
    } catch (e) {}
    try {
      this.connection();
      this.unit.passed({ testId: testId });
    } catch (error) {
      this.unit.report({
        method: "componentWillUnmount",
        message: "Error unmounting workspace.",
        from: "~583",
        error: error,
        testId: testId,
        passed: false,
      });
    }
    try {
      this.unit.passed({ testId: testId });
    } catch (error) {
      this.unit.report({
        method: "componentWillUnmount",
        message: "Error unmounting workspace.",
        from: "~595",
        error: error,
        testId: testId,
        passed: false,
      });
    }
  }

  /* ----------- FUNCTIONS --------------- */

  render() {
    // If nothing is ready, assume we may not have access
    if (!this.ready.get("all")) return <Access {...this.props} />;

    return (
      <WorkspaceContext.Provider
        {...this.props}
        value={{
          ...this.state,
          id: this.id,
          token: this.token,
          project: this.state.project,
          data: this.data,
          ready: this.ready,
          save: this.save,
          update: this.update,
          backup: this.backup,
          restore: this.restore,
          checklist: this.checklist,
          ai: this.ai,
          progress: this.progress,
          feature: this.feature,
          features: this.features,
          language: this.language,
          languages: this.languages,
          ui: this.ui,
          usable: this.usable,
          widget: this.widget,
          widgets: this.widgets,
          workers: this.workers,
          desk: this.desk,
          dialog: this.dialog,
          confirm: this.confirm,
          board: this.board,
          menu: this.menu,
          zoom: this.zoom,
          grid: this.grid,
          drag: this.drag,
          share: this.share,
          tour: this.tour,
          tours: this.tours,
          intro: this.intro,
          intros: this.intros,
          reposition: this.reposition,
          hm: this.hm,
          qr: this.qr,
          user: this.user,
          users: this.users,
          owner: this.owner,
          beta: this.beta,
          assist: this.assist,
          tips: this.tips,
        }}
      >
        {this.props.children}
        {/* If access changes at any time we may need to show this */}
        <Access {...this.props} />
      </WorkspaceContext.Provider>
    );
  }
}

const withWorkspace = (Component) => {
  return function ContextualComponent(props) {
    return (
      <WorkspaceContext.Consumer>{(state) => <Component {...props} workspace={state} />}</WorkspaceContext.Consumer>
    );
  };
};

const useWorkspace = () => {
  return useContext(WorkspaceContext);
};

export default withUnit(
  withUser(
    withActions(
      withTour(
        withErrors(
          withPerforming(
            withUI(
              withTour(withRouter(withLocales(withBroadcast(withBilling(withSnackbar(withMedia(withCms(Workspace))))))))
            )
          )
        )
      )
    )
  )
);
export { useWorkspace, withWorkspace };
