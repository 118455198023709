/** @format */
import throttle from "lodash/throttle";
import { nanoid } from "nanoid";
import { Component as ReactComponent, createContext, useContext } from "react";

import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "firemade";

import { withErrors } from "errors";
import { withLocales } from "locales";
import { withMedia } from "media";
import { withNotifications } from "notifications";
import { withProfiles } from "profiles";
import { withUnit } from "unit";
import { withUser } from "user";

// Setting that we're typing
import { getTyping, setTyping } from "./helpers/Typing";

// Setting that we're typing
import {
  exitChat,
  getActiveChat,
  getChatIndex,
  getChatList,
  getChatName,
  getChatSeen,
  getChatUsers,
  selectChat,
  setChatName,
  setChatSeen,
  startChart,
} from "./helpers/Chats";

// Message management (send, status)
import { getMessages, sendMessage } from "./helpers/Messages";

// Attachment management (delete, paste, upload)
import {
  deleteAttachment,
  getAttachmentList,
  hasAttachments,
  pasteAttachment,
  saveAttachments,
  uploadAttachment,
} from "./helpers/Attachments";

// The context
const ChatContext = createContext({});

class Chat extends ReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      // when we're ready to go
      ready: true,

      // any reason we might want to revise the state
      revised: null,

      // The id of the selected chat
      id: null,

      // A list of the chats available
      chats: [],

      // This looks like it should be combined
      attachments: [],

      // When we're uploading
      uploading: false,

      // Don't know what this does yet
      typing: false,
    };

    // Don't need to do this twice
    this.connected = false;

    // The subscription
    this.unsubscribe = () => {};

    // Throttle
    this.setTypingThrottled = throttle(setTyping.bind(this), 1000);

    // Chats
    this.chats = {
      index: getChatIndex.bind(this),
      select: selectChat.bind(this),
      exit: exitChat.bind(this),
      active: getActiveChat.bind(this),
      start: startChart.bind(this),
      users: getChatUsers.bind(this),
      name: {
        set: setChatName.bind(this),
        get: getChatName.bind(this),
      },
      messages: getMessages.bind(this),
      list: getChatList.bind(this),
      seen: {
        get: getChatSeen.bind(this),
        set: setChatSeen.bind(this),
      },
    };

    // Send a message
    this.message = {
      send: sendMessage.bind(this),
    };

    // Send a message
    this.messages = getMessages.bind(this);

    // Typing
    this.typing = {
      set: this.setTypingThrottled,
      get: getTyping.bind(this),
    };

    // Attachments
    this.attachments = {
      delete: deleteAttachment.bind(this),
      paste: pasteAttachment.bind(this),
      upload: uploadAttachment.bind(this),
      has: hasAttachments.bind(this),
      list: getAttachmentList.bind(this),
      save: saveAttachments.bind(this),
    };
  }

  /**
   * Updates the state with a new value for the "revised" property.
   */
  revised = () => {
    this.setState({ revised: nanoid() });
  };

  /**
   * Connects to the chat.
   * @returns {Promise} A promise that resolves with the connection object.
   */
  connect = () => {
    return new Promise((resolve, reject) => {
      try {
        // Get the query
        const q = query(collection(db, "chat"), where("users", "array-contains", this.props.user.id));

        // Get the snapshot listened attached to an unsubscribe
        this.unsubscribe = onSnapshot(q, (querySnapshot) => {
          const updatedChats = [];

          // loop through the snapshot
          querySnapshot.forEach((doc) => {
            updatedChats.push({ id: doc.id, ...doc.data() });
          });

          // Set the chats
          this.setState({ chats: updatedChats });

          // Fire onConnect if we need to do so
          this.onConnect("Connected.");
        });

        // resolve all good in the hood
        resolve();
      } catch (error) {
        // fire an on error if there is one
        this.onError(error);

        // reject it
        reject(error);
      }
    });
  };

  /**
   * Disconnects the chat.
   */
  disconnect = () => {
    try {
      this.unsubscribe();
    } catch (_) {}
  };

  /**
   * Handles the connection event for the chat if we've passed in an onConnect prop, use it
   *
   * @param {string} message - The connection message.
   * @returns {void}
   */
  onConnect = (message) => {
    try {
      if (this.props.onConnect) this.props.onConnect(message);
    } catch (_) {}
  };

  /**
   * Handles the error that occurred if we've passed in an onError prop, use it
   *
   * @param {Error} error - The error that occurred.
   */
  onError = (error) => {
    try {
      if (this.props.onError) this.props.onError(error);
    } catch (_) {}
  };

  /**
   * Component lifecycle method called after the component has been mounted.
   */
  componentDidMount() {
    try {
      this.connect("~214");
    } catch (_) {}
  }

  /**
   * @function componentWillUnmount
   * @description Lifecycle method called right before the component is unmounted and destroyed.
   * @memberof ComponentName
   * @instance
   */
  componentWillUnmount() {
    try {
      this.disconnect("~226");
    } catch (_) {}
  }

  render() {
    return (
      <ChatContext.Provider
        value={{
          ...this.state,
          revised: this.revised,
          message: this.message,
          messages: this.messages,
          chats: this.chats,
          typing: this.typing,
          attachments: this.attachments,
        }}
      >
        {this.props.children}
      </ChatContext.Provider>
    );
  }
}

const withChat = (Component) => {
  return function ContextualComponent(props) {
    return <ChatContext.Consumer>{(state) => <Component {...props} chat={state} />}</ChatContext.Consumer>;
  };
};

const useChat = () => {
  return useContext(ChatContext);
};

export default withLocales(withUnit(withUser(withProfiles(withNotifications(withMedia(withErrors(Chat)))))));
export { useChat, withChat };
