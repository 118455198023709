/** @format */
import axios from "axios";

export function assistOpened(assist) {
  return assist != null && assist != undefined ? this.state.assist == assist : this.state.assist;
}

export function toggleAssist() {
  this.setState({ assist: !this.state.assist });
}

export function openAssist() {
  this.setState({ assist: true });
}

export function closeAssist() {
  this.setState({ assist: false });
}

export async function askAssist(slug) {
  const { cms, performing, user, errors, t } = this.props;

  try {
    // Get the title of the selected page
    const { title } = cms.pages.find((page) => page.slug.includes(slug));

    // Get the prompt
    const prompt = `Tell me about ${title.toLowerCase()}.`;

    // Set that we're working
    performing.set.updating(true);

    // A payload to be submitted to assist
    const payload = {
      prompt: prompt,
      userId: user.id,
      token: this.token(),
      run: true,
    };

    // open the assist
    this.assist.open();

    // Post to the assist endpoint
    await axios.post("/api/workspace/assist", payload, {
      timeout: 60000,
    });
  } catch (e) {
    errors.warning(t("lostAssistArticle"));
    this.assist.open();
  }
}
