/** @format */
import Config from "@Workspace/config";
import capitalize from "capitalize";
import { lazy } from "react";
import { Functional } from "unit";

const unit = Functional.unit("workspace/share");

export default async function setupShare(from = "~9") {
  let testId = unit.report({
    method: "setupShare",
    message: "Sharing should load without error",
    from: from,
  });
  if (!this.ui.share) this.ui.share = {};

  return new Promise(async (resolve, reject) => {
    try {
      const prioritizeFeatures = (featureArray) => {
        return featureArray.sort((a, b) => Config.features[a].priority - Config.features[b].priority);
      };

      const relationChildren = Config.ui.relations.children;

      // Create a set of all children for easy lookup
      const allChildren = new Set(Object.keys(relationChildren));

      // Initialize all features from Config.ui.share as parents first that are not children
      prioritizeFeatures(Config.ui.share).forEach((feature) => {
        if (!allChildren.has(feature)) {
          this.ui.share[feature] = {
            slug: feature,
            component: lazy(() =>
              import(`../../components/Features/components/${capitalize(feature)}/share/index.jsx`)
            ),
            children: [],
          };
        }
      });

      Object.entries(relationChildren).forEach(([childFeature, parentFeatures]) => {
        if (Config.ui.share.includes(childFeature)) {
          parentFeatures.forEach((parentFeature) => {
            if (this.ui.share[parentFeature]) {
              this.ui.share[parentFeature].children.push({
                slug: childFeature,
                component: lazy(() =>
                  import(`../../components/Features/components/${capitalize(childFeature)}/share/index.jsx`)
                ),
                children: [],
              });

              // After all children are pushed to their parents, sort the children array based on priority
              this.ui.share[parentFeature].children.sort(
                (a, b) => Config.features[a.slug].priority - Config.features[b.slug].priority
              );
            }
          });
        }
      });

      resolve(true);
    } catch (error) {
      unit.report({
        method: "setupShare",
        message: "Sharing should load without error",
        from: "12",
        test: "Sharing should load without error",
        error: error,
        testId: testId,
      });
      reject(error);
    }
  });
}

export function openShare(feature) {
  const { ui } = this.props;
  ui.dialog.open("share", { from: feature });
}

export function closeShare(feature) {
  const { ui } = this.props;
  ui.dialog.close("share");
}
