/** @format */

import { Box, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Component as ReactComponent } from "react";

import { withLocales } from "locales";
import { withSnackbar } from "snackbar";
import { withUI } from "ui";

import { withUser } from "../../context";

const StyledBox = styled(Box)(() => ({}));

class SignOut extends ReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { ui, t, user, snackbar, disabled = false } = this.props;

    return (
      <StyledBox>
        <Box>{t("signOutMessage")}</Box>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} style={{ marginTop: "20px" }}>
          <Button onClick={ui.dialog.close} variant="outlined">
            {t("cancel")}
          </Button>
          <Button
            color="success"
            disabled={disabled}
            variant="contained"
            onClick={() =>
              user.authentication
                .signOut()
                .then(() => {
                  ui.dialog.close();
                  snackbar.open(t("signedOut"));
                })
                .catch((error) => {
                  snackbar.open(t(error), "error");
                })
            }
          >
            {t("signOut")}
          </Button>
        </Stack>
      </StyledBox>
    );
  }
}

export default withSnackbar(withLocales(withUser(withSnackbar(withUI(SignOut)))));
