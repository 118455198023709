/** @format */
import { lazy } from "react";
import { Functional } from "unit";

const unit = Functional.unit("workspace/workers");

export default async function setupWorkers(from = "~7") {
  let testId = unit.report({
    method: "setupAssist",
    from: from,
    message: "All assist templates should be loaded for all features should be setup",
    test: "Desks should load without error",
    steps: [
      "From workspace",
      "Select illustration",
      "Select storyboard",
      "Select storyboard",
      "Assure all load without error",
    ],
  });
  return await new Promise(async (resolve, reject) => {
    try {
      // This is hacky and needs to be fixed to make it dynamic
      this.workers["workers"] = lazy(() => import("@Workspace/workers/index.jsx"));
      this.workers["components/Features/components/Teams/workers"] = lazy(() =>
        import("@Workspace/Features/Teams/workers/index.jsx")
      );

      // this.workers["components/Assist/workers"] = lazy(() => import("@Workspace/Components/Assist/workers/index.jsx"));
      // Config.workers.forEach(async (worker) => {
      //   console.log("worker", worker, "../../../" + worker + "/index.jsx", "~31");
      //   workspace.workers[worker] = lazy(() => import("../../../" + worker + "/index.jsx"));
      // });
      resolve(true);
    } catch (error) {
      unit.failed({ testId: testId, error: error });
      reject(error, "~37");
    }
  });
}
