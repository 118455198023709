/** @format */
import Methods from "methodize";
import { nanoid } from "nanoid";

export async function setup(payload) {
  return new Promise((resolve, reject) => {
    try {
      if (!payload.broadcast) payload.broadcast = {};
      Object.keys(this.functions).forEach((key) => {
        payload.broadcast[key] = this.functions[key];
      });
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

export function send(payload) {
  return new Promise((resolve, reject) => {
    try {
      this.log.push({
        id: nanoid(),
        ...payload,
        status: "queued",
        time: Date.now(),
      });
      this.setState({
        ...this.state.timestamp,
        ...{ timestamp: { update: Date.now() } },
      });
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
}

export function listen(payload = null) {
  return new Promise((resolve, reject) => {
    try {
      if (!payload) return reject(false);
      if (!this.log.length) return resolve(true);
      const updateLog = (id, status) => {
        this.log
          .filter((log) => log.id === id)
          .forEach((_, i) => {
            this.log[i].status = status;
          });
      };
      let methods = Methods(payload);
      if (!methods.includes("broadcast")) {
        try {
          this.setup(payload);
        } catch (error) {
          return reject(error, "~88");
        }
      }
      this.log
        .filter((log) => log.status === "queued")
        .forEach((entry) => {
          if (
            (entry.to.toLowerCase() == payload.constructor.name.toLowerCase() || entry.to.toLowerCase() == "all") &&
            methods.includes(entry.method)
          ) {
            updateLog(entry.id, "sent");
            if (methods.some((r) => Object.keys(this.functions).indexOf(r) >= 0)) {
              updateLog(entry.id, "skipped");
              return reject(false);
            } else {
              updateLog(entry.id, "recieved");
              try {
                payload[entry.method](entry.props || {});
                updateLog(entry.id, "completed");
                resolve(true, "~107");
              } catch (error) {
                console.error(error, "~109");
                try {
                  updateLog(entry.id, "failed");
                } catch (e) {}
              }
            }
          }
        });
      this.log
        .filter((log) => ["skipped", "failed", "completed"].includes(log.status))
        .forEach((_, i) => {
          this.log.splice(i);
        });
    } catch (error) {
      console.error(error, "~124");
      reject(error);
    }
  });
}
