/** @format */
import { Transforms } from "slate";

// Set the carat so we always know where it is
export function setCarat() {
  try {
    // Get the currently selected editor
    const editor = this.editors[this.state.spread.number];

    // Make sure there is a selection
    if (editor.selection) {
      // Update the state with the current cursor position
      this.setState({ carat: editor.selection.focus });
    }
  } catch (_) {}
}

// Get the current location of the carat
export function getCarat() {
  try {
    return this.state?.carat || null;
  } catch (_) {
    return null;
  }
}

// Restore it to where it was
export function restoreCarat() {
  try {
    // get the current editor
    const editor = this.editors[this.state.spread.number];

    // Get the carat
    const carat = getCarat.call(this);

    // Reset the carat unless we lost it
    if (carat) {
      // Apply the new selection to the editor
      Transforms.select(editor, {
        anchor: carat,
        focus: carat,
      });
    }
  } catch (_) {}
}
