/** @format */
import { useLocales } from "locales";
import { useEffect } from "react";
import { useSnackbar } from "snackbar";

/**
 * Renders the Language component.
 *
 * @returns {null} Returns null.
 */
export default function () {
  const { locale, t } = useLocales();
  const { open } = useSnackbar();

  const selectedLocale = locale.get();

  useEffect(() => {
    if (selectedLocale && selectedLocale !== "en-us") open(t("supportedLanguageWarning"), "warning");
  }, [selectedLocale]);

  return null;
}
