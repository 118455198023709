/** @format */

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "firemade";

export default async function observe({ onAuthenticated, onDeauthenticated }) {
  await new Promise(async (resolve, reject) => {
    return onAuthStateChanged(
      auth,
      async (user) => {
        try {
          if (!user || !user.uid) {
            onDeauthenticated("onAuthStateChangedObserver");
          } else {
            onAuthenticated({ user: user, from: "~15" });
          }
          return resolve(true);
        } catch (error) {
          return reject(error.code);
        }
      },
      (error) => {
        return reject(error.code);
      }
    );
  });
}
