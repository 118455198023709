/** @format */
import DynamicIcon from "dynamicicon";

const authProviders = [
  {
    id: "google.com",
    color: "#ea4335",
    icon: <DynamicIcon icon="fa-google" />,
    name: "Google",
    scopes: ["https://www.googleapis.com/auth/userinfo.email", "https://www.googleapis.com/auth/userinfo.profile"],
    enabled: true,
  },
  {
    id: "facebook.com",
    color: "#3c5a99",
    icon: <DynamicIcon icon="fa-facebook" />,
    name: "Facebook",
    scopes: ["email"],
    enabled: true,
  },
  {
    id: "apple.com",
    color: "#000000",
    icon: <DynamicIcon icon="fa-apple" />,
    name: "Apple",
    scopes: ["email", "name"],
    enabled: true,
  },
  // {
  //   id: "twitter.com",
  //   color: "#1da1f2",
  //   icon: <DynamicIcon icon="fa-twitter" />,
  //   name: "Twitter",
  //   scopes: [],
  //   enabled: false,
  // },
];

export default authProviders;
