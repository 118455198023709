/** @format */

import { Backdrop, Box, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocales } from "locales";
import { CircularProgress as Circular } from "performing";

const StyleBox = styled(Box)(() => ({
  "&.root": {
    minHeight: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "12000",
    backgroundColor: "rgba(12,12,12,0)",
    borderRadius: "0px",
    padding: "50px",
    color: "#121212 !important",
  },
}));

const Controls = (props) => {
  const { tour } = props;
  if (!tour.ready || !tour.running) return <></>;
  const { t } = useLocales();
  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(12,12,12,.25)",
        zIndex: (theme) => theme.zIndex.tour,
      }}
      open={true}
    >
      <StyleBox className="root MuiTooltip-tooltip">
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Button variant="contained" color="warning" size="small" onClick={props.tour.stop}>
            {props.tour.complete() ? t("allDone") : t("exitTour")}
          </Button>
          {!tour.type == "stepper" && !props.tour.complete && (
            <Box style={{ opacity: 0.5 }}>
              <Circular value={props.tour.percent} size={40} />
            </Box>
          )}
        </Stack>
      </StyleBox>
    </Backdrop>
  );
};

export default Controls;
