/** @format */

export function setReady(step) {
  const ready = [...this.state.ready];

  this.setState({ ready: [...ready, step] });
}

export function getReady(step = null) {
  try {
    if (step) {
      return this.state.ready.includes(step);
    } else {
      return this.state.ready.includes(step);
    }
  } catch (e) {
    return false;
  }
}
