/** @format */

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "firemade";

export default function signUpWithEmail({ emailAddress, password, displayName }) {
  return new Promise(async (resolve, reject) => {
    if (!emailAddress || !password) return reject("someMissingFields");

    if (auth.currentUser) return reject("alreadyLoggedIn");

    createUserWithEmailAndPassword(auth, emailAddress, password)
      .then((response) => {
        // Update the user profile with a display name
        return updateProfile(response.user, {
          displayName: displayName || "",
        }).then(() => {
          // Return the updated user
          return resolve(response.user);
        });
      })
      .catch((error) => {
        reject(error.code);
      });
  });
}
