/** @format */
let timer;

export function set(type, payload = true) {
  // Clear any existing timer if payload is null or false
  if (payload === null || payload === false) {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  }

  this.setState({
    [type]: payload,
    updating: null,
    progress: { percent: 0, title: null },
  });

  // Set a timer if payload is neither null nor false
  if (payload !== null && payload !== false) {
    timer = setTimeout(() => {
      this.setState({ [type]: false });
    }, 6000); // 6 seconds
  }
}

export function setLoading(payload = true) {
  set.call(this, "loading", payload);
}

export function setWorking(payload = true) {
  set.call(this, "working", payload);
}

export function setLocked(payload = true) {
  set.call(this, "locked", payload);
}

export function setUpdating(payload = "updating") {
  if (payload === true) payload = "success";
  if (payload === false) payload = "error";

  if (payload != null) {
    this.setState({ [payload]: true });
  } else {
    this.setState({ updating: null, success: null, warning: null, error: null });
  }

  if (timer) clearTimeout(timer);

  // Clear this up success
  if (payload == "success") {
    timer = setTimeout(() => {
      setUpdating.call(this, null);
    }, 2000);

    // Clear this up no matter what
  } else if (payload == "warning" || payload == "error") {
    timer = setTimeout(() => {
      setUpdating.call(this, null);
    }, 4000);

    // if we get stuck on updating
  } else if (payload == "updating") {
    timer = setTimeout(() => {
      setUpdating.call(this, null);
    }, 6000);
  }

  // Update payload
  this.setState({ updating: payload });
}

export function setProgress(payload = 0) {
  if (typeof payload == "number") {
    payload = { percent: payload, title: null };
  }
  this.setState({ progress: payload });
}
