/** @format */
import { Box, Button, Card, Divider, Stack } from "@mui/material";
import Cdn from "cdn";
import { useLocales } from "locales";
import { useState } from "react";
import { SignIn, SignUp, useUser } from "user";

import ProjectListener from "projectlistener";
import Resume from "./components/Resume";
import Terms from "./components/Terms";

export default function Register(props) {
  const { t } = useLocales();
  const user = useUser();
  const { trim, theme, title, credits } = props;
  const [width, height] = trim?.interior?.dimensions || [203, 203];
  const font = theme?.fonts[0] || "Comfortaa";
  const thisTitle = title || t(title);
  const thisCredits = credits || t("authorName");
  const [type, setType] = useState("signUp");

  return (
    <Card sx={{ paddingBottom: "20px" }} elevation={0}>
      <h3 style={{ textAlign: "center" }}>{t("signInOrRegister")}</h3>
      <Stack direction="row" spacing={5} justifyContent="center" alignItems="center">
        <Card
          elevation={10}
          sx={{
            width: width * 2,
            position: "relative",
            minHeight: height * 2,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${Cdn(
              "https://us-central.cdn.made.live//thumbnail_children_s_book_characters_4717739092/thumbnail_children_s_book_characters_4717739092.png?width=1728&height=864"
            )})`,
          }}
        >
          <Box
            sx={{
              fontSize: "32px",
              textAlign: "center",
              position: "absolute",
              top: "10px",
              width: "100%",
              fontWeight: "bold",
              fontFamily: font,
              color: "#ad3f3b",
              textShadow: "0px 0px 15px #7d8a7f, 0px 0px 20px #7d8a7f, 0px 0px 25px #7d8a7f",
            }}
            component="span"
          >
            {thisTitle}
          </Box>
          <Box
            sx={{
              fontSize: "18px",
              textAlign: "center",
              position: "absolute",
              bottom: "40px",
              width: "100%",
              fontWeight: "bold",
              fontFamily: font,
              color: "#ad3f3b",
              textShadow: "0px 0px 15px white, 0px 0px 20px white, 0px 0px 25px white",
            }}
            component="span"
          >
            {thisCredits}
          </Box>
        </Card>
        <Box sx={{ width: 690, padding: "20px", minHeight: "345px" }}>
          {!user.authenticated ? (
            <>
              {type == "signUp" && <SignUp {...props} from="onboard" />}
              {type == "signIn" && <SignIn {...props} from="onboard" />}
              <Box sx={{ textAlign: "center" }}>
                <Divider sx={{ margin: "20px 0" }} />
                <Button onClick={() => setType(type == "signIn" ? "signUp" : "signIn")}>
                  {t(type != "signIn" ? "alreadyHaveAccount" : "dontHaveAccount")}
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              <ProjectListener location="onboard" fallback={Terms} active={Resume} {...props} />
            </Box>
          )}
        </Box>
      </Stack>
    </Card>
  );
}
