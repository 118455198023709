/** @format */
import { DialogActions } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDialogActions = styled(DialogActions)(({ theme, position }) => ({
  "&": {
    position: "absolute",
    top: position === "top" ? 0 : "auto",
    bottom: position === "bottom" ? 0 : "auto",
    zIndex: 2000,
    width: "100%",
    color: theme.palette.mode == "light" ? "rgba(12,12,12)" : "rgba(250,250,250)",
    backgroundColor: theme.palette.mode == "light" ? "rgba(250,250,250,.8)" : "rgba(12,12,12,.8)",
    borderTop:
      position === "bottom" && (theme.palette.mode == "light" ? "1px solid rgba(12,12,12,.2)" : "0px solid #FFF"),
    borderBottom:
      position === "top" && (theme.palette.mode == "light" ? "1px solid rgba(12,12,12,.2)" : "0px solid #FFF"),
    margin: 0,
    padding: "5px",
    textAlign: "left",
  },
}));

export default function ActionsBar({ children, position = "bottom", sx = {}, ...rest }) {
  return children ? (
    <StyledDialogActions sx={sx} position={position} {...rest}>
      {children}
    </StyledDialogActions>
  ) : null;
}
